import React from 'react';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { LoadingLayout } from '../../../../core/layouts/loading-layout/loading-layout';
import { authApi } from '../../../api/auth.api';

export function CallbackLayout() {
  const query = useLocation().search;

  useEffect(() => {
    const oauthCallback = async () => {
      const {
        data: { urlToRedirect },
      } = await authApi.authCallback(query);
      const shop = new URLSearchParams(query).get('shop');
      if (!shop) throw new Error('No shop found in query');

      // redirect to shop main
      window.location = urlToRedirect;
    };
    if (query) {
      oauthCallback();
    }
  }, [query]);

  return <LoadingLayout />;
}
