import { AppModal } from '../../overlays/modal/modal';
import { AppTextStyle } from '../../text/text-style/TextStyle';

interface IInventoryRemoveModalProps {
  open: boolean;
  close: () => void;
  removeFromStore: () => void;
}

export const InventoryRemoveModal = (props: IInventoryRemoveModalProps) => {
  const { open, close, removeFromStore } = props;

  if (!open) return null;

  return (
    <AppModal
      open={open}
      onClose={close}
      title="Delete product(s)"
      primaryAction={{
        content: 'Delete',
        destructive: true,
        onAction: () => {
          removeFromStore();
          close();
        },
      }}
      secondaryActions={[
        {
          content: 'Cancel',
          onAction: close,
        },
      ]}
    >
      <AppTextStyle>
        Warning: Your product(s) will be deleted in Crowdship and Shopify. This action can not be
        undone. Are you sure?
      </AppTextStyle>
    </AppModal>
  );
};
