import { AxiosResponse } from 'axios';
import { all, put, takeLatest } from 'redux-saga/effects';
import { ActionType } from 'typesafe-actions';
import { accountApi, IAccountWithNesting } from '../../../../core/api/account.api';
import { getStatusAction } from '../../../../shopify-supplier/redux/modules/status/status.actions';
import {
  getAccountInfoAction,
  postAccountInfoAction,
  setAccountInfoFromApiAction,
  getManagePaymentSourcesURLAction,
  setManagePaymentSourcesURLAction,
  showSuccessUpdateToastAction,
  connectAccountAction,
  disconnectAccountAction,
} from './account.actions';

function* getAccountInfoSaga(action: ActionType<typeof getAccountInfoAction>) {
  try {
    const res: AxiosResponse<IAccountWithNesting> = yield accountApi.getAccountInfo();
    yield put(setAccountInfoFromApiAction(res.data));
  } catch (e) {
    console.error(e);
  }
}

function* postAccountInfoSaga(action: ActionType<typeof postAccountInfoAction>) {
  try {
    const res: AxiosResponse<IAccountWithNesting> = yield accountApi.postAccountInfo(
      action.payload,
    );

    yield put(setAccountInfoFromApiAction(res.data));

    yield put(showSuccessUpdateToastAction());
  } catch (e) {
    console.error(e);
  }
}

function* getManagePaymentSourcesURLSaga(
  action: ActionType<typeof getManagePaymentSourcesURLAction>,
) {
  try {
    const res = yield accountApi.getManagePaymentSourcesURL();
    yield put(setManagePaymentSourcesURLAction(res.data));
  } catch (e) {
    console.error(e);
  }
}

function* connectAccountSaga(action: ActionType<typeof connectAccountAction>) {
  try {
    yield accountApi.connectAccount();
    yield put(getAccountInfoAction());
    yield put(getStatusAction());
  } catch (e) {
    console.error(e);
  }
}

function* disconnectAccountSaga(action: ActionType<typeof disconnectAccountAction>) {
  try {
    yield accountApi.disconnectAccount();
    yield put(getAccountInfoAction());
    yield put(getStatusAction());
  } catch (e) {
    console.error(e);
  }
}

export function* accountSaga() {
  yield all([
    takeLatest(getAccountInfoAction, getAccountInfoSaga),
    takeLatest(postAccountInfoAction, postAccountInfoSaga),
    takeLatest(getManagePaymentSourcesURLAction, getManagePaymentSourcesURLSaga),
    takeLatest(connectAccountAction, connectAccountSaga),
    takeLatest(disconnectAccountAction, disconnectAccountSaga),
  ]);
}
