import { AxiosResponse } from 'axios';
import { axiosInstance } from '../../core/api/base.api';
import { IDashboardSalesSummary, IDashboardOrdersSummary } from '../interfaces/IDashboard';

class DashboardApi {
  private backend = process.env.REACT_APP_BACK_END_URL as string;
  private rootPath = '/api/supplier/shopify/dashboard';

  async getSalesSummary(): Promise<AxiosResponse<IDashboardSalesSummary>> {
    return await axiosInstance.get(`${this.backend}${this.rootPath}/sales-summary`);
  }

  async getOrdersSummary(): Promise<AxiosResponse<IDashboardOrdersSummary>> {
    return await axiosInstance.get(`${this.backend}${this.rootPath}/orders-summary`);
  }
}

export const dashboardApi = new DashboardApi();
