import { useCallback, useEffect, useMemo, useState } from 'react';
import { AppButton } from '../../../../../core/components/button/Button';
import { AppChoiceList } from '../../../../../core/components/choice-list/ChoiceList';
import { AppDropZone } from '../../../../../core/components/drop-zone/drop-zone';
import { AppBanner } from '../../../../../core/components/feedback-indicators/banner/banner';
import { AppControlledCheckbox } from '../../../../../core/components/forms/controlled-checkbox/controlled-checkbox';
import { AppControlledSelect } from '../../../../../core/components/forms/controlled-select/controlled-select';
import { AppControlledTextField } from '../../../../../core/components/forms/controlled-text-field/controlled-text-field';
import { AppThumbnail } from '../../../../../core/components/image-containers/thumbnail/Thumbnail';
import { AppPopoverWithSection } from '../../../../../core/components/overlays/popover/popover-with-section';
import { AppCard } from '../../../../../core/components/structure/card/card';
import { AppLayoutAnnotatedSection } from '../../../../../core/components/structure/layout/layout-annotated-section';
import { AppTextStyle } from '../../../../../core/components/text/text-style/TextStyle';
import { PRODUCT_CATEGORY } from '../../../../../core/constants/product.constants';
import { validation } from '../../../../../core/helpers/validations.helper';
import { getFileType } from '../../../../../core/helpers/get-file-type.helper';

import { BUSINESS_TYPE, IPreferences } from '../../../../interfaces/IPreferences';

type ProfileSectionProps = {
  values: IPreferences;
  setFieldValue: (field: string, value: any) => void;
};

export const ProfileSection = ({ values, setFieldValue }: ProfileSectionProps) => {
  const [category, setCategory] = useState<PRODUCT_CATEGORY[]>([values.profile.productCategory]);
  const [logoExists, setLogoExists] = useState(false);
  const [licenseFileExists, setLicenseFileExists] = useState(false);

  const [rejectedFiles, setRejectedFiles] = useState<any[]>([]);

  useEffect(() => {
    setCategory([values.profile.productCategory]);
  }, [values.profile.productCategory]);

  useEffect(() => {
    if (!values.profile.cannabisLicensed) {
      setFieldValue('profile.licenseName', '');
      setFieldValue('profile.licenseNumber', '');
      setFieldValue('profile.businessType', BUSINESS_TYPE.NONE);
      setFieldValue('profile.phone', '');
      setFieldValue('profile.licenseFile', undefined);
    }
  }, [values.profile.cannabisLicensed, setFieldValue]);

  const categoryOptions = useMemo(
    () =>
      Object.values(PRODUCT_CATEGORY)
        .filter((c) => c !== 'NONE')
        .map((c) => ({
          label: c,
          value: c,
        })),
    [],
  );

  const businessTypeOptions = useMemo(
    () =>
      Object.values(BUSINESS_TYPE).map((type) => ({
        label: type === 'NONE' ? 'Select an Option' : type,
        value: type,
        disabled: type === 'NONE',
      })),
    [],
  );

  const handleDropLogo = useCallback(
    (_droppedFiles, acceptedFiles, rejectedFiles) => {
      setRejectedFiles([]);

      if (values.profile.logo) {
        setLogoExists(true);
        setLicenseFileExists(false);
        return;
      }

      if (acceptedFiles.length !== 0) {
        setLogoExists(false);
        setFieldValue('profile.logo', acceptedFiles[0]);
      }
      setRejectedFiles(rejectedFiles);
    },
    [setFieldValue, values.profile.logo],
  );

  const handleDropLicense = useCallback(
    (_droppedFiles, acceptedFiles, rejectedFiles) => {
      setRejectedFiles([]);
      if (values.profile.licenseFile) {
        setLicenseFileExists(true);
        setLogoExists(false);
        return;
      }

      if (acceptedFiles.length !== 0) {
        setLicenseFileExists(false);
        setFieldValue('profile.licenseFile', acceptedFiles[0]);
      }
      setRejectedFiles(rejectedFiles);
    },
    [setFieldValue, values.profile.licenseFile],
  );

  const validateSelectHandler = (value: string) =>
    value === BUSINESS_TYPE.NONE ? 'Select business type' : undefined;

  return (
    <div className="profile-section">
      {rejectedFiles.length > 0 && (
        <AppBanner title="The following file couldn’t be uploaded:" status="critical">
          <p>{rejectedFiles[0].name} is not supported. File type must be .png or .jpeg.</p>
        </AppBanner>
      )}
      {logoExists && <AppBanner title="Please delete existing logo" status="critical" />}
      {licenseFileExists && (
        <AppBanner title="Please delete existing license file" status="critical" />
      )}
      <div className="profile-section__image-block">
        <AppLayoutAnnotatedSection
          title="Marketplace Profile Image"
          description="Upload a Profile Image. We recommend using your company logo."
        >
          <AppCard sectioned>
            <div className="profile-section__image-block__description">
              <AppTextStyle variation="strong">Profile Image</AppTextStyle>
              <p>Use .png or .jpeg</p>
            </div>
            <AppDropZone
              accept="image/png, image/jpeg"
              type="image"
              onDrop={handleDropLogo}
              outline={false}
              variableHeight
              allowMultiple={false}
            >
              <AppButton>Upload File</AppButton>
            </AppDropZone>
          </AppCard>
          {values.profile.logo && (
            <div className="profile-section__image-block__logo">
              <AppThumbnail size="large" alt={'logo'} source={getFileType(values.profile.logo)} />
              <AppButton plain destructive onClick={() => setFieldValue('profile.logo', undefined)}>
                Delete Image
              </AppButton>
            </div>
          )}
        </AppLayoutAnnotatedSection>
      </div>
      <AppLayoutAnnotatedSection title="Company Bio">
        <AppCard sectioned>
          <p className="profile-section__bio-desription">Tell retailers about your company</p>
          <AppControlledTextField multiline={4} autoComplete="off" name={'profile.bio'} />
        </AppCard>
      </AppLayoutAnnotatedSection>
      <AppLayoutAnnotatedSection
        title="Help Retailers Find Your Products"
        description="Select an industry to help Crowdship Retailers quickly find, import, and start selling your products."
      >
        <AppCard title="Select an Industry" sectioned>
          <AppPopoverWithSection
            title={category[0] === PRODUCT_CATEGORY.NONE ? 'Select an Option' : category[0]}
            preferredAlignment="left"
          >
            <AppChoiceList
              title="Product category"
              titleHidden
              choices={categoryOptions}
              selected={category}
              onChange={(category: PRODUCT_CATEGORY[]) => {
                setCategory(category);
                setFieldValue('profile.productCategory', category[0]);
              }}
            />
          </AppPopoverWithSection>
        </AppCard>
      </AppLayoutAnnotatedSection>

      <div className="profile-section__license-block">
        <AppLayoutAnnotatedSection
          title="Regulatory Info"
          description="Confirm your License information."
        >
          <AppControlledCheckbox
            label="I am a Licensed Cannabis Supplier"
            name="profile.cannabisLicensed"
          ></AppControlledCheckbox>
          {values.profile.cannabisLicensed && (
            <>
              <AppCard sectioned>
                <div className="profile-section__license-block__form">
                  <div className="profile-section__license-block__form__fields">
                    <AppControlledTextField
                      label="License Holder Name"
                      name="profile.licenseName"
                      validate={validation.isNotEmpty}
                    />
                    <AppControlledTextField
                      label="License Number"
                      name="profile.licenseNumber"
                      validate={validation.isNotEmpty}
                    />
                  </div>
                  <AppControlledSelect
                    label="Business Type"
                    options={businessTypeOptions}
                    name="profile.businessType"
                    validate={validateSelectHandler}
                  />
                  <AppControlledTextField
                    label="Contact phone"
                    name="profile.phone"
                    validate={validation.isNotEmpty}
                  />
                </div>
              </AppCard>
              <div className="profile-section__license-block__file">
                <AppCard sectioned>
                  <div className="profile-section__license-block__file__description">
                    <AppTextStyle variation="strong">Proof of License</AppTextStyle>
                    <p>Use .png or .jpeg</p>
                  </div>
                  <AppDropZone
                    accept="image/png, image/jpeg"
                    type="image"
                    onDrop={handleDropLicense}
                    outline={false}
                    variableHeight
                    allowMultiple={false}
                  >
                    <AppButton>Upload File</AppButton>
                  </AppDropZone>
                </AppCard>
                {values.profile.licenseFile && (
                  <div className="profile-section__license-block__file__view">
                    <AppThumbnail
                      size="large"
                      alt={'license'}
                      source={getFileType(values.profile.licenseFile)}
                    />
                    <AppButton
                      plain
                      destructive
                      onClick={() => setFieldValue('profile.licenseFile', undefined)}
                    >
                      Delete Image
                    </AppButton>
                  </div>
                )}
              </div>
            </>
          )}
        </AppLayoutAnnotatedSection>
      </div>
    </div>
  );
};
