import React from 'react';

interface IEmptyLayout {
  empty: boolean;
  emptyComponent: React.ReactNode;
}

export const EmptyLayout = ({
  empty,
  emptyComponent,
  children,
}: React.PropsWithChildren<IEmptyLayout>) => {
  if (empty) return <>{emptyComponent}</>;

  return <>{children}</>;
};
