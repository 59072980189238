import { combineReducers } from 'redux';
import { StateType } from 'typesafe-actions';
import { coreReducers } from '../../core/redux/reducers';
import { dashboardReducer } from './modules/dashboard/dashboard.reducer';
import { importListReducer } from './modules/import-list/import-list.reducer';
import { inventoryReducer } from './modules/inventory/inventory.reducer';
import { marketplaceReducer } from './modules/marketplace/marketplace.reducer';
import { orderReducer } from './modules/orders/orders.reducer';
import { plansReducer } from './modules/plans/plans.reducer';
import { preferencesReducer } from './modules/preferences/preferences.reducer';
import { statusReducer } from './modules/status/status.reducer';
import { productSyncReducer } from './modules/sync/sync.reducer';
import { variantSyncReducer } from './modules/variant-sync/variant-sync.reducer';

export const reducers = combineReducers({
  ...coreReducers,
  variantSync: variantSyncReducer,
  importList: importListReducer,
  marketplace: marketplaceReducer,
  inventory: inventoryReducer,
  orders: orderReducer,
  dashboard: dashboardReducer,
  preferences: preferencesReducer,
  productSync: productSyncReducer,
  limits: statusReducer,
  plans: plansReducer,
});

export type RootState = StateType<typeof reducers>;
