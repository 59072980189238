import { EmptySearchResult } from '@shopify/polaris';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { FetchingBanner } from '../../../../core/components/banners/fetching-banner/fetching-banner';
import { CardWithDatePicker } from '../../../../core/components/card-with-date-picker/card-with-date-picker';
import { PageAwarePagination } from '../../../../core/components/pagination/page-aware-pagination';
import { INVOICES_PER_PAGE } from '../../../../core/constants/invoices.constants';
import { ordersApi } from '../../../api/orders.api';
import { IRetailerPurchaseOrderWithUnpaidFeeForList } from '../../../interfaces/IOrder';
import { PendingFeeList } from '../../containers/invoice-list/pending-fee-list';

export const PendingTransactionFeeLayout = () => {
  const [dateRange, setDateRange] = useState<{ start: string; end: string } | undefined>();
  const [orders, setOrders] = useState<IRetailerPurchaseOrderWithUnpaidFeeForList[]>([]);
  const [fetching, setFetching] = useState(false);
  const [total, setTotal] = useState(0);

  const totalPageCount = useMemo(() => Math.ceil(total / INVOICES_PER_PAGE), [total]);

  const getOrders = useCallback((page: number, range?: { start: string; end: string }) => {
    setFetching(true);
    ordersApi
      .getPurchaseOrdersWithUnpaidFee({ limit: INVOICES_PER_PAGE, page, dateRange: range })
      .then(({ data }) => {
        setOrders(data.orders);
        setTotal(data.total);
      })
      .catch(console.error)
      .finally(() => {
        setFetching(false);
      });
  }, []);

  useEffect(() => {
    getOrders(0);
  }, [getOrders]);

  return (
    <CardWithDatePicker
      title="Pending transaction fees"
      onChange={(selected) => {
        setDateRange(selected);
        getOrders(0, selected);
      }}
      hideFilter={!orders.length}
    >
      <div className="wrapper">
        {fetching && (
          <FetchingBanner bodyText="Loading purchase orders with pending transaction fees" />
        )}
        {!!orders.length && <PendingFeeList orders={orders} />}
        {!orders.length && (
          <EmptySearchResult
            title={'No records found'}
            description={"If you're using search or a filter, try changing it"}
            withIllustration
          />
        )}
      </div>
      <PageAwarePagination
        totalPageCount={totalPageCount}
        onPageChange={(page) => getOrders(page, dateRange)}
      />
    </CardWithDatePicker>
  );
};
