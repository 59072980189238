import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppToast } from '../../../../core/components/feedback-indicators/toast/toast';
import { AppDisplayText } from '../../../../core/components/text/display-text/display-text';
import { plansApi } from '../../../api/plans.api';
import { getPlansAction } from '../../../redux/modules/plans/plans.actions';
import {
  getPlansFetchingSelector,
  getPlansSelector,
} from '../../../redux/modules/plans/plans.selectors';
import { getStatusSelector } from '../../../redux/modules/status/status.selectors';
import { InsufficientInfoBanner } from '../../banners/insufficient-info-banner/insufficient-info-banner';
import { PlanProductLimitExceededErrorBanner } from '../../banners/plan-product-limit-exceeded-error-banner/plan-product-limit-exceeded-error-banner';
import { PlanList } from '../../containers/plan-list/plan-list';
import { PlanListSkeleton } from '../../containers/plan-list/plan-list-skeleton';
import './plans-layout.scss';

type PlansLayoutProps = {
  setSelectedTab: React.Dispatch<React.SetStateAction<number>>;
};

export function PlansLayout({ setSelectedTab }: PlansLayoutProps) {
  const dispatch = useDispatch();
  const status = useSelector(getStatusSelector);
  const plans = useSelector(getPlansSelector);
  const fetching = useSelector(getPlansFetchingSelector);
  const [limitExceeded, setLimitExceeded] = useState(false);
  const [successfullyUpdated, setSuccessfullyUpdated] = useState(false);
  const [requestingPlanChange, setRequestingPlanChange] = useState(false);
  const [redirecting, setRedirecting] = useState(false);

  useEffect(() => {
    dispatch(getPlansAction());
  }, [dispatch]);

  const canChangePlan = useMemo(
    () => status && status.installationSteps.accountInfoSetUp && status.hasCard,
    [status],
  );

  const requestPlanChange = useCallback(
    (planId: string) => {
      setRequestingPlanChange(true);
      plansApi
        .requestPlanChange(planId)
        .then(({ data }) => {
          if ('changed' in data && data.changed) {
            setSuccessfullyUpdated(true);
          } else if ('requested' in data && data.requested) {
            setRedirecting(true);
            window.open(data.confirmationUrl, '_blank');
          } else if ('limitExceeded' in data && data.limitExceeded) {
            setLimitExceeded(true);
          }
        })
        .catch(console.error)
        .finally(() => {
          setRequestingPlanChange(false);
          dispatch(getPlansAction());
        });
    },
    [dispatch],
  );

  const pageMarkup = useMemo(
    () =>
      fetching ? (
        <PlanListSkeleton />
      ) : (
        <>
          <div className="page-title">
            <AppDisplayText size="large">Subscription management </AppDisplayText>
          </div>
          <PlanList
            disable={!canChangePlan}
            plans={plans}
            productsAdded={status?.productsCount}
            requestPlanChange={requestPlanChange}
            requestingChange={requestingPlanChange}
            setSelectedTab={setSelectedTab}
          />
        </>
      ),
    [
      fetching,
      canChangePlan,
      plans,
      status?.productsCount,
      requestPlanChange,
      requestingPlanChange,
      setSelectedTab,
    ],
  );

  const updatedToastMarkup = useMemo(
    () =>
      successfullyUpdated && (
        <AppToast
          onDismiss={() => {
            setSuccessfullyUpdated(false);
          }}
          content="Plan changed"
        />
      ),
    [successfullyUpdated],
  );
  const updatingToastMarkup = useMemo(
    () =>
      requestingPlanChange && (
        <AppToast
          onDismiss={() => setRequestingPlanChange(false)}
          content="Requesting plan change..."
        />
      ),
    [requestingPlanChange],
  );
  const redirectingToastMarkup = useMemo(
    () =>
      redirecting && (
        <AppToast
          onDismiss={() => setRedirecting(false)}
          content="Redirecting to charge confirmation page..."
        />
      ),
    [redirecting],
  );

  return (
    <div className="plans-layout">
      {status && !canChangePlan && <InsufficientInfoBanner />}
      {limitExceeded && (
        <PlanProductLimitExceededErrorBanner onDismiss={() => setLimitExceeded(false)} />
      )}
      {pageMarkup}
      {updatedToastMarkup}
      {updatingToastMarkup}
      {redirectingToastMarkup}
    </div>
  );
}
