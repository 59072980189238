import { useSelector } from 'react-redux';
import { AppButton } from '../button/Button';
import { getViewAsClientSettingSelector } from '../../redux/modules/admin/admin.selector';
import './view-as-client-notification.scss';

export const ViewAsClientNotification = () => {
  const viewAsClient = useSelector(getViewAsClientSettingSelector);

  if (!viewAsClient || viewAsClient.self) return null;

  return (
    <div className="view-as-client-wrapper">
      <AppButton destructive url="/admin-tools">
        Viewing as client: {viewAsClient.name}
      </AppButton>
    </div>
  );
};
