import React from 'react';
import { AppBanner } from '../../../../core/components/feedback-indicators/banner/banner';
import './plan-product-limit-exceeded-error-banner.scss';

type IPlanProductLimitExceededErrorBannerProps = {
  onDismiss: () => void;
};

export function PlanProductLimitExceededErrorBanner({
  onDismiss,
}: IPlanProductLimitExceededErrorBannerProps) {
  return (
    <div className="banner">
      <AppBanner status="critical" title="Could not change plan" onDismiss={onDismiss}>
        <p>
          We cannot transfer you to the selected plan due to non-compliance with the SKU limit
          specified in that plan
        </p>
      </AppBanner>
    </div>
  );
}
