import React from 'react';
import { Card } from '@shopify/polaris';

type ICardHeaderProps = {
  title?: string;
  children: React.ReactNode;
};

export function AppCardHeader({ children, title }: ICardHeaderProps) {
  return <Card.Header title={title}>{children}</Card.Header>;
}
