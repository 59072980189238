import React, { ReactNode, useCallback, useMemo, useState } from 'react';
import { useHistory, useLocation } from 'react-router';
import queryString from 'query-string';
import { toLower } from 'lodash';
import { useSelector } from 'react-redux';
import { AppCard } from '../../../../core/components/structure/card/card';
import { AppPage } from '../../../../core/components/structure/page/page';
import { AppTabs } from '../../../../core/components/tabs/tabs';
import { getStatusSelector } from '../../../redux/modules/status/status.selectors';

import { PayoutList } from '../../containers/payout-list/payout-list';
import { UnpaidOrderList } from '../../containers/unpaid-orders-list/unpaid-orders-list';
import './payouts-layout.scss';

const tabs = [
  { id: 'orders-view', content: 'Unpaid Orders' },
  { id: 'payouts-view', content: 'Invoice History' },
];

const findTabIndexByQuery = (tab?: string): number => {
  if (!tab) return 0;
  const index = tabs.findIndex((t) => toLower(t.content).replace(' ', '-') === tab);
  if (index === -1) return 0;
  return index;
};

export const PayoutsLayout = () => {
  const { search: query } = useLocation();
  const history = useHistory();
  const parsedQuery = useMemo(() => new URLSearchParams(query), [query]);
  const tab = parsedQuery.get('tab') as string;

  const searchStringState = useState(query);
  const [selectedTab, setSelectedTab] = useState<number>(findTabIndexByQuery(tab));
  const { currency } = useSelector(getStatusSelector);

  const changeUrlParams = useCallback(
    (tabNumber: number, params?) => {
      const search = `?tab=${toLower(tabs[tabNumber].content).replace(' ', '-')}${
        params
          ? Object.keys(params)
              .map((p) => {
                const value = params[`${p}`];
                if (value === undefined) return '';
                if (p === 'dateRange') return '';
                if (Array.isArray(value))
                  return value.length
                    ? `${queryString.stringify(
                        { [p]: value.map((v) => v.replace(' ', '-')) },
                        { arrayFormat: 'separator', arrayFormatSeparator: '.' },
                      )}`
                    : '';
                return `&${p}=${value}`;
              })
              .join('&')
          : ''
      }`;
      history.push({
        search,
      });
      searchStringState[1](search);
    },
    [history, searchStringState],
  );

  const tabContent: { [key: number]: ReactNode } = useMemo(
    () => ({
      0: <UnpaidOrderList changeUrlParams={changeUrlParams} />,
      1: (
        <AppCard title="Invoice History" sectioned>
          <PayoutList currency={currency} />
        </AppCard>
      ),
    }),
    [currency, changeUrlParams],
  );

  return (
    <React.Fragment>
      <div className="accounting-wrapper">
        <AppPage title="Accounting">
          <AppTabs
            tabs={tabs}
            selected={selectedTab}
            onSelect={(i) => {
              setSelectedTab(i);
              history.push({ search: `?tab=${toLower(tabs[i].content).replace(' ', '-')}` });
            }}
          >
            {tabContent[selectedTab]}
          </AppTabs>
        </AppPage>
      </div>
    </React.Fragment>
  );
};
