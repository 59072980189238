import { createAction } from 'typesafe-actions';
import { IAccountWithNesting } from '../../../api/account.api';
import { IAccount } from '../../../interfaces/IAccount';
import { CORE_ACTIONS } from '../../action-types';

export const getAccountInfoAction = createAction(CORE_ACTIONS.GET_ACCOUNT_INFO)();

export const setAccountInfoFromApiAction = createAction(
  CORE_ACTIONS.SET_ACCOUNT_INFO_FROM_API,
  (info: IAccountWithNesting) => info,
)();

export const setAccountInfoAction = createAction(
  CORE_ACTIONS.SET_ACCOUNT_INFO,
  (info: IAccount) => info,
)();

export const postAccountInfoAction = createAction(
  CORE_ACTIONS.POST_ACCOUNT_INFO,
  (info: IAccount) => info,
)();

export const getManagePaymentSourcesURLAction = createAction(
  CORE_ACTIONS.GET_MANAGE_PAYMENT_SOURCES_URL,
)();

export const setManagePaymentSourcesURLAction = createAction(
  CORE_ACTIONS.SET_MANAGE_PAYMENT_SOURCES_URL,
  (url: string) => url,
)();

export const showSuccessUpdateToastAction = createAction(
  CORE_ACTIONS.SHOW_SUCCESS_ACCOUNT_INFO_UPDATE_TOAST,
)();

export const hideSuccessUpdateToastAction = createAction(
  CORE_ACTIONS.HIDE_SUCCESS_ACCOUNT_INFO_UPDATE_TOAST,
)();

export const connectAccountAction = createAction(CORE_ACTIONS.CONNECT_ACCOUNT)();

export const disconnectAccountAction = createAction(CORE_ACTIONS.DISCONNECT_ACCOUNT)();
