import React, { useState } from 'react';
import { AppBanner } from '../../../../core/components/feedback-indicators/banner/banner';
import { AppLink } from '../../../../core/components/link/link';
import './products-limit-error-banner.scss';

interface IProductsLimitErrorBannerProps {
  limit: number;
  added: number;
  onDismiss: () => void;
  error: boolean;
}

export function ProductsLimitErrorBanner({
  onDismiss,
  limit,
  added,
  error,
}: IProductsLimitErrorBannerProps) {
  const [show, setShow] = useState(true);

  // useEffect(() => {
  //   window.scrollTo({
  //     top: 0,
  //     left: 0,
  //     behavior: 'smooth',
  //   });
  // }, []);

  const hideBanner = () => {
    setShow(false);
    onDismiss();
  };

  if (!show) return null;

  const status = error ? 'critical' : 'warning';
  const title = error ? 'Products limit exceeded' : 'Products limit reached';

  return (
    <div className="banner">
      <AppBanner status={status} title={title} onDismiss={error ? hideBanner : undefined}>
        <p>
          Cannot add more than {limit.toLocaleString()} SKUs to inventory. Already added{' '}
          {added.toLocaleString()} SKUs.{' '}
          <AppLink url="/account?tab=plans">Upgrade your plan</AppLink> to add more SKUs
        </p>
      </AppBanner>
    </div>
  );
}
