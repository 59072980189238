import { createSelector } from 'reselect';
import { RootState } from '../../reducers';

export const getPlansSelector = createSelector(
  [(state: RootState) => state.plans],
  (plansReducer) => plansReducer.plans,
);

export const getPlansFetchingSelector = createSelector(
  [(state: RootState) => state.plans],
  (plansReducer) => plansReducer.fetching,
);
