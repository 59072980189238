import React, { useCallback, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { AppEmptyState } from '../../../../core/components/structure/empty-state/empty-state';
import { AppModal } from '../../../../core/components/overlays/modal/modal';
import { AppAccountConnection } from '../../../../core/components/account-details/account-connection';
import { AppLink } from '../../../../core/components/link/link';
import emptyStateImage from '../../../../core/images/Shopify/Empty States/Ad.svg';
import CrowdshipLogo from '../../../../core/images/logo-crowdship-navy.png';
import { connectAccountAction } from '../../../../core/redux/modules/account/account.actions';
import './onboarding-layout.scss';

export function OnboardingLayout() {
  const dispatch = useDispatch();
  const history = useHistory();
  const [openModal, setOpenModal] = useState(false);

  const connect = useCallback(() => {
    dispatch(connectAccountAction());
    history.push('/account');
  }, [dispatch, history]);

  const modalMarkup = useMemo(
    () =>
      openModal ? (
        <AppModal title="Connect your account" open={openModal} onClose={() => setOpenModal(false)}>
          <div className="onboarding-modal">
            <img src={CrowdshipLogo} alt="Crowdship logo" />
            <AppAccountConnection
              accountName={''}
              connected={false}
              title="Crowdship account"
              action={{
                content: 'Connect',
                onAction: connect,
              }}
              details={'No account connected'}
              termsOfService={
                <p>
                  By clicking <strong>Connect</strong>, you agree to accept Crowdship's {''}
                  <AppLink
                    onClick={() => window.open('https://crowdship.io/terms-conditions/', '_blank')}
                    external
                  >
                    terms and conditions
                  </AppLink>
                  .
                </p>
              }
            />
          </div>
        </AppModal>
      ) : null,
    [openModal, connect],
  );

  return (
    <div className="onboarding">
      <AppEmptyState
        heading="Sell your products on Crowdship"
        action={{
          content: 'Connect account',
          onAction: () => {
            setOpenModal(true);
          },
        }}
        image={emptyStateImage}
      >
        <p>
          List your products to thousands of online retailers and start generating additional
          revenue.
        </p>
      </AppEmptyState>
      {modalMarkup}
    </div>
  );
}
