import { Form, Formik, FormikErrors, FormikTouched } from 'formik';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { uniq } from 'lodash';
import { AppBanner } from '../../../../core/components/feedback-indicators/banner/banner';
import { AppToast } from '../../../../core/components/feedback-indicators/toast/toast';
import { AppModal } from '../../../../core/components/overlays/modal/modal';
import { SupplierProductList } from '../../../../core/components/product-list/supplier-product-list';
import { AppPage } from '../../../../core/components/structure/page/page';
import { PRODUCTS_PER_PAGE } from '../../../../core/constants/product.constants';
import { getNumberOfPages } from '../../../../core/helpers/item-list.helper';
import { IVariantMapping } from '../../../../core/interfaces/IProduct';
import { DROP_SHIP_COST_MAPPING, MSRP_MAPPING } from '../../../constants/preferences.constants';
import { ISupplierVariant } from '../../../interfaces/IProduct';
import { getPreferencesAction } from '../../../redux/modules/preferences/preferences.actions';
import { getPreferencesSelector } from '../../../redux/modules/preferences/preferences.selectors';
import {
  changeAvailableGroupsAction,
  changeAvailableGroupsForAllProductsAction,
  addProductsToMarketplaceAction,
  addAllProductsToMarketplaceAction,
  getFilterOptionsAction,
  getVariantsAction,
  hideSuccessVariantPriceUpdateToastAction,
  removeLastAvailabilityUpdateAction,
  setLimitFilterAction,
  setPageFilterAction,
  getCSVInventoryAction,
  getAllCSVInventoryAction,
} from '../../../redux/modules/products/products.actions';
import {
  getAllSuppliersProductVariantsSelector,
  getIsExportingCSVSelector,
  getLastAvailabilityUpdateSelector,
  getProductFetchingSelector,
  getProductFilterSelector,
  getProductGroupsSelector,
  getProductsCountSelector,
  getProductsSelector,
  showSuccessVariantPriceUpdateToastSelector,
} from '../../../redux/modules/products/products.selectors';
import { getStatusSelector } from '../../../redux/modules/status/status.selectors';
import { MovingProductsResultBanner } from '../../banners/moving-products-result-banner/moving-products-result-banner';
import { BulkPriceUpdate } from '../../containers/bulk-price-update/bulk-price-update';
import { VariantList } from '../../containers/variant-list/variant-list';
import { AvailabilityModal } from '../../modals/availability-modal/availability-modal';
import './products-layout.scss';
import { MARKETPLACE_APPROVAL_STATUS } from '../../../api/status.api';

export function ProductsLayout() {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getFilterOptionsAction());
    dispatch(setLimitFilterAction({ limit: PRODUCTS_PER_PAGE }));
    // ui elements look different for manual/non-manual mapping preferences
    dispatch(getPreferencesAction());
  }, [dispatch]);

  const { connected, updatingPriceMappingPreference, marketplaceApprovalStatus } =
    useSelector(getStatusSelector);
  const products = useSelector(getProductsSelector);
  const { page: currentPage } = useSelector(getProductFilterSelector);
  const total = useSelector(getProductsCountSelector);
  const fetching = useSelector(getProductFetchingSelector);

  const allVariants = useSelector(getAllSuppliersProductVariantsSelector);
  const lastMoveOpResult = useSelector(getLastAvailabilityUpdateSelector);
  const preferences = useSelector(getPreferencesSelector);
  const priceChangesSaved = useSelector(showSuccessVariantPriceUpdateToastSelector);
  const retailerGroups = useSelector(getProductGroupsSelector);
  const isExportingCSV = useSelector(getIsExportingCSVSelector);

  // variants and touched can be reinitialized when we fetch additional variants
  // overlapping values will be overridden by existing data
  const [initialVariants, setInitialVariants] =
    useState<IVariantMapping<ISupplierVariant>>(allVariants);
  const [initialTouched, setInitialTouched] = useState<
    FormikTouched<IVariantMapping<ISupplierVariant>>
  >({});

  const [selectedProducts, setSelectedProducts] = useState<string[]>([]);
  const [pendingProducts, setPendingProducts] = useState<string[]>([]);
  const [selectedAllProducts, setSelectedAllProducts] = useState<boolean>(false);
  const [openAvailabilityModal, setOpenAvailabilityModal] = useState(false);
  const [errors, setErrors] = useState<FormikErrors<IVariantMapping<ISupplierVariant>>>();
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    setInitialVariants(allVariants);
  }, [allVariants]);

  useEffect(() => {
    if (!fetching) {
      setOpenAvailabilityModal(false);
    }
  }, [fetching]);

  const hideLastAvailabilityUpdateResult = useCallback(() => {
    dispatch(removeLastAvailabilityUpdateAction());
  }, [dispatch]);

  const availabilities = useMemo(
    () => ({
      marketplace: products.some((p) => pendingProducts.includes(p.id) && p.availableForRetailers),
      groups: uniq(
        products
          .filter((p) => pendingProducts.includes(p.id))
          .map((fP) => fP.retailerGroups)
          .flat(),
      ),
    }),
    [products, pendingProducts],
  );

  const handleAddToMarketplace = useCallback(() => {
    hideLastAvailabilityUpdateResult();
    if (pendingProducts.some((id) => errors && errors[id])) return;
    // dispatch(toggleFetching(true));

    if (selectedAllProducts) {
      dispatch(addAllProductsToMarketplaceAction());
    } else {
      dispatch(
        addProductsToMarketplaceAction(
          pendingProducts.filter((id) => !products.some((op) => op.processing && op.id === id)),
        ),
      );
    }

    setSelectedAllProducts(false);
    setSelectedProducts([]);
  }, [
    hideLastAvailabilityUpdateResult,
    pendingProducts,
    selectedAllProducts,
    errors,
    dispatch,
    products,
  ]);

  const handleUpdateAvailableGroups = useCallback(
    (retailerGroups: string[]) => {
      hideLastAvailabilityUpdateResult();

      if (pendingProducts.some((id) => errors && errors[id])) return;

      if (selectedAllProducts) {
        dispatch(changeAvailableGroupsForAllProductsAction(retailerGroups));
      } else {
        dispatch(
          changeAvailableGroupsAction({
            retailerGroups,
            productIds: pendingProducts.filter(
              (id) => !products.some((op) => op.processing && op.id === id),
            ),
          }),
        );
      }

      setSelectedAllProducts(false);
      setSelectedProducts([]);
    },
    [
      hideLastAvailabilityUpdateResult,
      pendingProducts,
      selectedAllProducts,
      errors,
      dispatch,
      products,
    ],
  );

  const handleExportCSVInventory = useCallback(() => {
    if (selectedAllProducts) {
      dispatch(getAllCSVInventoryAction());
    } else {
      dispatch(getCSVInventoryAction(selectedProducts));
    }
  }, [dispatch, selectedAllProducts, selectedProducts]);

  const storeValues = useCallback((touched, values) => {
    // values and touched fields need to be saved on variants expand
    // because variant expand causes changes in allVariants object
    // which causes loss of form data
    setInitialVariants((initial) => ({
      ...initial,
      ...values,
    }));
    setInitialTouched(touched);
  }, []);

  const renderProductVariantList = useCallback(
    (productId: string, values: IVariantMapping<ISupplierVariant>) => {
      if (!preferences) return null;
      return (
        <VariantList
          productId={productId}
          manualMSRP={preferences.product.MSRP.mapping === MSRP_MAPPING.MANUAL}
          manualPriceForRetailers={
            preferences.product.dropShipCost.mapping === DROP_SHIP_COST_MAPPING.MANUAL
          }
          values={values[productId]}
        />
      );
    },
    [preferences],
  );

  const handleVariantExpand = useCallback(
    (productId: string) => {
      dispatch(getVariantsAction({ productId }));
    },
    [dispatch],
  );

  const hideSuccessVariantPriceUpdateToast = useCallback(
    () => dispatch(hideSuccessVariantPriceUpdateToastAction()),
    [dispatch],
  );

  const modalMarkup = useMemo(
    () =>
      showModal ? (
        <AppModal
          open={showModal}
          onClose={() => setShowModal(false)}
          title="Bulk price update via CSV"
          secondaryActions={[
            {
              content: 'Cancel',
              onAction: () => setShowModal(false),
            },
          ]}
        >
          <BulkPriceUpdate onFileUploaded={() => setShowModal(false)} />
        </AppModal>
      ) : null,
    [showModal],
  );

  return (
    <React.Fragment>
      <div className="products-layout">
        <AppPage
          title="Products"
          secondaryActions={[
            {
              content: 'Import Prices',
              onAction: () => {
                setShowModal(true);
              },
              loading: fetching,
            },
          ]}
        >
          {/** After an operation of moving products if some products were actually moved show banner */}
          {lastMoveOpResult &&
            (!!lastMoveOpResult.successTitles.length || !!lastMoveOpResult.failure.length) && (
              <MovingProductsResultBanner
                {...lastMoveOpResult}
                onDismiss={hideLastAvailabilityUpdateResult}
                isNeedToUp={false}
              />
            )}
          {updatingPriceMappingPreference && (
            <AppBanner status="info" title="Prices are being adjusted to new mapping preferences">
              At the moment, your products' prices are being adjusted to new price mapping
              preferences. You will be able to make your products available for retailers as soon as
              all products are updated.
            </AppBanner>
          )}
          <Formik
            initialValues={initialVariants}
            initialTouched={initialTouched}
            onSubmit={() => {}}
            enableReinitialize
          >
            {({ touched, values, errors }) => (
              <Form name={'products'}>
                <SupplierProductList
                  products={products}
                  listActions={[
                    {
                      content: 'Update availability',
                      onAction: () => {
                        setErrors(errors);
                        setOpenAvailabilityModal(true);
                        setPendingProducts(selectedProducts);
                      },
                      disabled: !connected || updatingPriceMappingPreference,
                    },
                    {
                      content: 'Export products',
                      onAction: handleExportCSVInventory,
                      disabled: isExportingCSV,
                    },
                  ]}
                  onSelectionChange={setSelectedProducts}
                  allSelectedToggle={setSelectedAllProducts}
                  onProductVariantsExpand={(productId) => {
                    storeValues(touched, values);
                    handleVariantExpand(productId);
                  }}
                  totalPageCount={getNumberOfPages(total)}
                  onPageChange={(page) => dispatch(setPageFilterAction({ page }))}
                  renderVariantList={(id) => renderProductVariantList(id, values)}
                  fetching={fetching}
                  currentPage={currentPage}
                />
              </Form>
            )}
          </Formik>
          {modalMarkup}
        </AppPage>
      </div>
      {priceChangesSaved && (
        <AppToast onDismiss={hideSuccessVariantPriceUpdateToast} content="Changes saved" />
      )}
      {openAvailabilityModal && (
        <AvailabilityModal
          open={openAvailabilityModal}
          groups={retailerGroups}
          availabilities={availabilities}
          hasMarketplaceApproval={
            marketplaceApprovalStatus === MARKETPLACE_APPROVAL_STATUS.APPROVED
          }
          onClose={() => setOpenAvailabilityModal(false)}
          handleAddToMarketplace={handleAddToMarketplace}
          handleUpdateAvailableGroups={handleUpdateAvailableGroups}
        />
      )}
    </React.Fragment>
  );
}
