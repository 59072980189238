import { all, takeLatest, put } from 'redux-saga/effects';
import {
  getSalesSummaryAction,
  getOrdersSummaryAction,
  setFetchingAction,
  setSalesSummaryAction,
  setOrdersSummaryAction,
} from './dashboard-actions';
import { ActionType } from 'typesafe-actions';
import { dashboardApi } from '../../../api/dashboard.api';
import { IDashboardSalesSummary, IDashboardOrdersSummary } from '../../../interfaces/IDashboard';
import { AxiosResponse } from 'axios';
import { showActionError } from '../../../../core/helpers/saga-error-catcher.helper';

function* getSalesSummarySaga(action: ActionType<typeof getSalesSummaryAction>) {
  yield put(setFetchingAction(true));
  try {
    const res: AxiosResponse<IDashboardSalesSummary> = yield dashboardApi.getSalesSummary();
    yield put(setSalesSummaryAction(res.data));
    yield put(setFetchingAction(false));
  } catch (e) {
    yield put(setFetchingAction(false));
    yield showActionError(action.type, e);
  }
}

function* getOrdersSummarySaga(action: ActionType<typeof getOrdersSummaryAction>) {
  yield put(setFetchingAction(true));
  try {
    const res: AxiosResponse<IDashboardOrdersSummary> = yield dashboardApi.getOrdersSummary();
    yield put(setOrdersSummaryAction(res.data));
    yield put(setFetchingAction(false));
  } catch (e) {
    yield put(setFetchingAction(false));
    yield showActionError(action.type, e);
  }
}

export function* dashboardSagas() {
  yield all([
    takeLatest(getSalesSummaryAction, getSalesSummarySaga),
    takeLatest(getOrdersSummaryAction, getOrdersSummarySaga),
  ]);
}
