import React, { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { Redirect, Route, RouteProps } from 'react-router-dom';
import { ACCOUNT_ROLE } from '../../core/redux/modules/auth/auth.reducer';
import { getRolesSelector, isAuthenticatedSelector } from '../redux/modules/auth/auth.selectors';
import { ROUTES } from './routes';

type GuardedRouteProps = RouteProps &
  ({ onlyAuthenticated: true; requireRoles?: ACCOUNT_ROLE[] } | { onlyUnauthenticated: true });

export const GuardedRoute = ({ component: Component, ...rest }: GuardedRouteProps) => {
  const authenticated = useSelector(isAuthenticatedSelector);
  const roles = useSelector(getRolesSelector);

  const renderComponent = useCallback(
    (props) => {
      if (!Component) return <Redirect to={ROUTES.ROOT} />;
      if ('onlyUnauthenticated' in rest && authenticated) return <Redirect to={ROUTES.ROOT} />;
      if ('onlyAuthenticated' in rest && !authenticated) return <Redirect to={ROUTES.SIGN_IN} />;

      if (
        ('onlyUnauthenticated' in rest && !authenticated) ||
        ('onlyAuthenticated' in rest &&
          (!rest.requireRoles || rest.requireRoles.some((role) => roles.includes(role))) &&
          authenticated)
      )
        return <Component {...props} />;

      return <Redirect to={ROUTES.ROOT} />;
    },
    [Component, authenticated, rest, roles],
  );

  return <Route {...rest} render={renderComponent} />;
};
