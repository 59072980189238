import { createAction } from 'typesafe-actions';
import { PickOptions } from '../../../../../core/helpers/generic.helper';
import { IMarketplaceFilter } from '../../../../../core/interfaces/IProductFilter';
import { ACTIONS_CONSTANTS } from '../../../actions-constants';

export const getMarketplaceFilterOptionsAction = createAction(
  ACTIONS_CONSTANTS.GET_MARKETPLACE_FILTER_OPTIONS,
)();

export const setMarketplaceFilterOptionsAction = createAction(
  ACTIONS_CONSTANTS.SET_MARKETPLACE_FILTER_OPTIONS,
  (options: PickOptions<IMarketplaceFilter>) => options,
)();
