import { createSelector } from 'reselect';
import { RootState } from '../../reducers';

export const getStatusSelector = createSelector(
  [(state: RootState) => state.limits],
  (statusReducer) => (statusReducer.fetching ? null : statusReducer.status),
);

export const getApiStatusSelector = createSelector(
  [(state: RootState) => state.limits],
  ({ status }) => ({ updatedApi: status?.updatedApi, permissionUrl: status?.permissionUrl }),
);
