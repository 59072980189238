import { useEffect, useMemo } from 'react';
import { capitalize } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import {
  resetProductFiltersAction,
  setAvailableGroupsFilterAction,
  setStockFilterAction,
  setQueryFilterAction,
  setTypeFilterAction,
  setVendorFilterAction,
  setShopifyStatusFilterAction,
  setInventoryFilterAction,
} from '../../../../../shopify-supplier/redux/modules/products/products.actions';
import {
  getProductFilterOptionsSelector,
  getProductFilterSelector,
} from '../../../../../shopify-supplier/redux/modules/products/products.selectors';
import '../../product-list.scss';
import { AvailabilityFilter, AVAILABILITY_FILTER_KEY } from './filter-elements/availability-filter';
import { STOCK_FILTER_KEY } from './filter-elements/stock-filter';
import { QueryFilterContainer } from './filter-elements/query-filter-container';
import { TypeFilter, TYPE_FILTER_KEY } from './filter-elements/type-filter';
import { VendorFilter, VENDOR_FILTER_KEY } from './filter-elements/vendor-filter';
import { IAppliedFilter } from '../../../filters/filters';
import { AVAILABILITY } from '../../../../interfaces/IProduct';
import {
  ShopifyStatusFilter,
  SHOPIFY_STATUS_FILTER_KEY,
} from './filter-elements/shopify-status-filter';
import { INVENTORY_FILTER_KEY, InventoryFilter } from './filter-elements/inventory-filter';
import { PRODUCT_INVENTORY } from '../../../../constants/product.constants';

export const SupplierProductFilter = () => {
  const dispatch = useDispatch();
  const filters = useSelector(getProductFilterSelector);
  const { types, vendors, groups, shopifyStatus } = useSelector(getProductFilterOptionsSelector);

  useEffect(() => {
    return () => {
      dispatch(resetProductFiltersAction());
    };
  }, [dispatch]);

  const availabilityOptions = useMemo(
    () => [
      { label: AVAILABILITY.MARKETPLACE, value: AVAILABILITY.MARKETPLACE },
      ...groups,
      { label: AVAILABILITY.UNAVAILABLE, value: AVAILABILITY.UNAVAILABLE },
    ],
    [groups],
  );

  const appliedFilters: IAppliedFilter[] = useMemo(() => {
    const appliedVendors = {
      key: VENDOR_FILTER_KEY,
      label: `Brands: ${filters.vendors.join(', ')}`,
      onRemove: () => dispatch(setVendorFilterAction({ vendors: [] })),
    };
    const appliedTypes = {
      key: TYPE_FILTER_KEY,
      label: `Types: ${filters.types.join(', ')}`,
      onRemove: () => dispatch(setTypeFilterAction({ types: [] })),
    };
    const appliedInStock = {
      key: STOCK_FILTER_KEY,
      label: 'Inventory: Hide out of stock',
      onRemove: () => dispatch(setStockFilterAction({ hasStock: false })),
    };
    const appliedAvailabilities = {
      key: AVAILABILITY_FILTER_KEY,
      label: `Availabilities: ${availabilityOptions
        .filter((g) => filters.groups.includes(g.value))
        .map((g) => g.label)
        .join(', ')}`,
      onRemove: () => dispatch(setAvailableGroupsFilterAction({ groups: [] })),
    };

    const appliedShopifyStatus = {
      key: SHOPIFY_STATUS_FILTER_KEY,
      label: `Shopify status: ${filters.shopifyStatus
        .map((status) => capitalize(status))
        .join(', ')}`,
      onRemove: () => dispatch(setShopifyStatusFilterAction({ shopifyStatus: [] })),
    };

    const appliedInventory = {
      key: INVENTORY_FILTER_KEY,
      label: `Inventory: ${filters.productInventory}`,
      onRemove: () => {
        dispatch(
          setInventoryFilterAction({
            productInventory: PRODUCT_INVENTORY.SHOW_ALL,
          }),
        );
      },
    };

    const chosenVendors = filters.vendors.length ? [appliedVendors] : [];
    const chosenTypes = filters.types.length ? [appliedTypes] : [];
    const chosenHasStock = filters.hasStock ? [appliedInStock] : [];
    const chosenAvailabilitties = filters.groups.length ? [appliedAvailabilities] : [];
    const chosenShopifyStatus = filters.shopifyStatus.length ? [appliedShopifyStatus] : [];
    const chosenInventory = filters.productInventory.includes(PRODUCT_INVENTORY.SHOW_ALL)
      ? []
      : [appliedInventory];

    return [
      ...chosenVendors,
      ...chosenTypes,
      ...chosenHasStock,
      ...chosenAvailabilitties,
      ...chosenShopifyStatus,
      ...chosenInventory,
    ];
  }, [filters, availabilityOptions, dispatch]);

  return (
    <QueryFilterContainer
      value={filters.query}
      onChange={(query) => dispatch(setQueryFilterAction({ query }))}
      filters={[
        VendorFilter({
          value: filters.vendors,
          onChange: (vendors) => dispatch(setVendorFilterAction({ vendors })),
          options: vendors,
        }),
        TypeFilter({
          value: filters.types,
          onChange: (types) => dispatch(setTypeFilterAction({ types })),
          options: types,
        }),
        InventoryFilter({
          value: filters.productInventory,
          onChange: (inventory) => {
            dispatch(
              setInventoryFilterAction({
                productInventory: inventory as PRODUCT_INVENTORY,
              }),
            );
          },
        }),

        AvailabilityFilter({
          value: filters.groups,
          options: availabilityOptions,
          onChange: (groups) => dispatch(setAvailableGroupsFilterAction({ groups })),
        }),
        ShopifyStatusFilter({
          value: filters.shopifyStatus,
          onChange: (shopifyStatus) => dispatch(setShopifyStatusFilterAction({ shopifyStatus })),
          options: shopifyStatus,
        }),
      ]}
      appliedFilters={appliedFilters}
      onClearAll={() => dispatch(resetProductFiltersAction())}
    />
  );
};
