import { AppChoiceList } from '../../../../choice-list/ChoiceList';
import { FilterElementProps } from './filter-elements.interface';

export const AVAILABILITY_FILTER_KEY = 'availableForRetailers';
const AVAILABILITY_FILTER_LABEL = 'Availability';
const AVAILABILITY_FILTER_TITLE = 'Availability options';

const Filter = ({ onChange, value, options }: FilterElementProps<string[]>) => {
  return (
    <AppChoiceList
      title={AVAILABILITY_FILTER_TITLE}
      titleHidden
      choices={options}
      selected={value}
      onChange={onChange}
      allowMultiple
    />
  );
};

export const AvailabilityFilter = ({ value, onChange, options }: FilterElementProps<string[]>) => ({
  key: AVAILABILITY_FILTER_KEY,
  label: AVAILABILITY_FILTER_LABEL,
  shortcut: true,
  filter: <Filter onChange={onChange} options={options} value={value} />,
});
