import React, { useCallback, useEffect } from 'react';
import { Field, useFormikContext } from 'formik';
import { AppRadioButton } from '../radio-button/radio-button';
import { AppStack } from '../../../structure/stack/Stack';

type IControlledRadioGroupProps = {
  optionsDesc: IRadioButtonDescription[];
  name: string;
  onChange?: (value: string, id: string) => void;
};

export function AppControlledRadioGroup({
  optionsDesc,
  name,
  onChange,
}: IControlledRadioGroupProps) {
  const { registerField, unregisterField, getFieldProps, setFieldValue } = useFormikContext();

  const field = getFieldProps({ name });

  useEffect(() => {
    if (name) registerField(name, {});
    return () => {
      if (name) unregisterField(name);
    };
  }, [name, registerField, unregisterField]);

  const handleChange = useCallback(
    (value: boolean, id: string) => {
      const updatedValue = id.split('--')[1];
      if (value) {
        setFieldValue(name, updatedValue, false);
        if (onChange) onChange(updatedValue, id);
      }
    },
    [setFieldValue, name, onChange],
  );

  return (
    <div
      onClick={(e) => {
        e.stopPropagation();
      }}
    >
      <Field name={name}>
        {() => (
          <AppStack vertical>
            {optionsDesc.map((o) => (
              <AppRadioButton
                {...o}
                // setting id as an composite string helps to avoid issues with duplicating values in one form
                id={`${name}--${o.value}`}
                key={o.value}
                name={name}
                onChange={handleChange}
                checked={o.value === field.value}
              />
            ))}
          </AppStack>
        )}
      </Field>
    </div>
  );
}

export interface IRadioButtonDescription {
  value: string;
  label: React.ReactNode;
  helpText?: React.ReactNode;
  disabled?: boolean;
}
