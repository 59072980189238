import React, { useCallback, useMemo, useState } from 'react';
import { AppCheckbox } from '../../../../core/components/checkbox/checkbox';
import { AppOptionList } from '../../../../core/components/option-list/option-list';
import { AppModal } from '../../../../core/components/overlays/modal/modal';
import { IOption } from '../../../../core/interfaces/IOption';

import './availability-modal.scss';
import { useSelector } from 'react-redux';
import { getUpdatingAllProductsSelector } from '../../../redux/modules/products/products.selectors';

interface AvailabilityModalProps {
  open: boolean;
  groups: IOption[];
  availabilities: { marketplace: boolean; groups: string[] };
  hasMarketplaceApproval: boolean;
  onClose: () => void;
  handleAddToMarketplace: () => void;
  handleUpdateAvailableGroups: (retailerGroups: string[]) => void;
}

export function AvailabilityModal({
  open,
  groups,
  availabilities,
  hasMarketplaceApproval,
  handleAddToMarketplace,
  handleUpdateAvailableGroups,
  onClose,
}: AvailabilityModalProps) {
  const isUpdatingAllProducts = useSelector(getUpdatingAllProductsSelector);
  const [selectedGroups, setSelectedGroups] = useState<string[]>(
    availabilities.marketplace
      ? groups.map((gr) => gr.value)
      : groups.filter((g) => availabilities.groups.includes(g.label)).map((fG) => fG.value) || [],
  );
  const [marketplaceAvailability, setMarketplaceAvailability] = useState(
    availabilities.marketplace,
  );

  const handleMarketplaceAvailability = useCallback(
    (newChecked: boolean, id: string) => {
      setMarketplaceAvailability(newChecked);
      setSelectedGroups(groups.map((gr) => gr.value));
    },
    [groups],
  );

  const handleSelectGroup = useCallback((selected: string[]) => {
    setSelectedGroups(selected);
  }, []);

  const groupsOption = useMemo(
    () =>
      groups.map(({ value, label }) => ({
        value,
        label,
        disabled: marketplaceAvailability,
      })),
    [groups, marketplaceAvailability],
  );

  return (
    <AppModal
      open={open}
      onClose={onClose}
      title="Update availability"
      primaryAction={{
        content: 'Update',
        loading: isUpdatingAllProducts,
        onAction: () => {
          if (marketplaceAvailability) {
            handleAddToMarketplace();
            return;
          }

          handleUpdateAvailableGroups(selectedGroups);
        },
      }}
      secondaryActions={[
        {
          content: 'Cancel',
          onAction: onClose,
        },
      ]}
    >
      <div className="availability-modal">
        <p>Available on</p>
        <AppCheckbox
          label="Marketplace"
          checked={marketplaceAvailability}
          onChange={handleMarketplaceAvailability}
          disabled={!hasMarketplaceApproval}
        />
        <AppOptionList
          selected={selectedGroups}
          options={groupsOption}
          onChange={handleSelectGroup}
          allowMultiple
        />
      </div>
    </AppModal>
  );
}
