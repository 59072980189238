import { createSelector } from 'reselect';
import { RootState } from '../../reducers';

export const getProductsSelector = createSelector(
  [(state: RootState) => state.products],
  (productsReducer) => productsReducer.products,
);

export const getProductsCountSelector = createSelector(
  [(state: RootState) => state.products],
  (productsReducer) => productsReducer.count,
);

export const getProductFilterOptionsSelector = createSelector(
  [(state: RootState) => state.products],
  (productsReducer) => productsReducer.options,
);

export const getProductGroupsSelector = createSelector(
  [(state: RootState) => state.products],
  (productsReducer) => productsReducer.options.groups,
);

export const getProductFetchingSelector = createSelector(
  [(state: RootState) => state.products],
  (productsReducer) => productsReducer.fetching,
);

export const getProductVariantsSelector = createSelector(
  [(state: RootState, productId: string) => state.products.variants[productId]],
  (variants) => variants || [],
);

export const getAllSuppliersProductVariantsSelector = createSelector(
  [(state: RootState) => state.products.variants],
  (variants) => variants,
);

export const showSuccessVariantPriceUpdateToastSelector = createSelector(
  [(state: RootState) => state.products],
  (productsReducer) => productsReducer.showSuccessVariantPriceUpdateToast,
);

export const getLastAvailabilityUpdateSelector = createSelector(
  [(state: RootState) => state.products.lastAvailabilityUpdate],
  (op) =>
    op && {
      action: op.action,
      success: !op.failure.length,
      failure: op.failure,
      successTitles: op.success,
    },
);

export const getProductFilterSelector = createSelector(
  [(state: RootState) => state.products.filters],
  (filters) => filters,
);

export const getUpdatingAllProductsSelector = createSelector(
  [(state: RootState) => state.products.isUpdatingAllProducts],
  (isUpdatingAllProducts) => isUpdatingAllProducts,
);

export const getIsExportingCSVSelector = createSelector(
  [(state: RootState) => state.products.isExportingCSV],
  (isExportingCSV) => isExportingCSV,
);
