export enum ROUTES {
  // Unauthenticated
  SIGN_IN = '/sign-in',
  SIGN_UP = '/sign-up',
  CONFIRM = '/confirm',
  CONNECT = '/connect',

  // Authenticated
  ROOT = '/',
  MARKETPLACE = '/marketplace',
  IMPORT_LIST = '/import-list',
  INVENTORY = '/my-products',
  ORDERS = '/orders',
  PURCHASE_ORDER = '/purchase-order',
  ACCOUNTING = '/accounting',
  SETTINGS = '/settings',
  ACCOUNT = '/account',
  SYNC = '/sync',
  INTEGRATIONS = '/integrations',
  ADMIN_TOOLS = '/admin-tools',
}
