import { Form, Formik, FormikHelpers } from 'formik';
import React, { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { TrackedQuantityLabelForVariant } from '../../../../core/components/tracked-quantity-label-for-variant/tracked-quantity-label-for-variant';
import { AppButton } from '../../../../core/components/button/Button';
import { AppDataTable } from '../../../../core/components/data-table/DataTable';
import { AppToast } from '../../../../core/components/feedback-indicators/toast/toast';
import { AppThumbnail } from '../../../../core/components/image-containers/thumbnail/Thumbnail';
import { AppTextStyle } from '../../../../core/components/text/text-style/TextStyle';
import { calculateMargin } from '../../../../core/helpers/calculate-margin.helper';
import { withCurrency } from '../../../../core/helpers/currency.helper';
import { validation } from '../../../../core/helpers/validations.helper';
import { inventoryApi } from '../../../api/inventory.api';
import { IInventoryVariant } from '../../../interfaces/IProduct';
import { RootState } from '../../../redux/reducers';
import { PriceSyncPreferencePopover } from '../product-details/price-sync-preference-popover';
import { marketplaceApi } from '../../../api/marketplace.api';
import { getInventoryProductVariantsSelector } from '../../../redux/modules/inventory/inventory.selectors';
import {
  getInventoryProductListAction,
  getInventoryProductVariantsAction,
} from '../../../redux/modules/inventory/inventory.actions';
import { InventoryConnectModal } from '../../modals/inventory-connect-modal/inventory-connect-modal';
import './variant-list.scss';
import { AppTooltip } from '../../../../core/components/overlays/tooltip/tooltip';
import { AppLink } from '../../../../core/components/link/link';

type IVariantListProps = {
  productId: string;
  isDisconnectDisable: boolean;
};

export const InventoryVariantList = ({ productId, isDisconnectDisable }: IVariantListProps) => {
  const dispatch = useDispatch();
  const [connecting, setConnecting] = useState(false);
  const [error, setError] = useState('');
  const [pendingConnect, setPendingConnect] = useState<string | undefined>();
  const [updating, setUpdating] = useState(false);

  const variants = useSelector((state: RootState) =>
    getInventoryProductVariantsSelector(state, productId),
  );

  const handleDisconnect = useCallback(
    (id: string) => {
      setUpdating(true);
      marketplaceApi
        .disconnectVariant(id)
        .catch(console.error)
        .finally(() => {
          dispatch(getInventoryProductListAction());
          setUpdating(false);
        });
    },
    [dispatch],
  );

  const changedVariants = useCallback(
    (variantsToSave: IInventoryVariant[]) =>
      variantsToSave
        .map((v) => ({
          id: v.id,
          updateManuallySetPrice: v.manuallySetPrice,
          price: +v.price,
          useCompanyDefault: v.useCompanyDefault,
          salePriceOption: v.salePriceOption,
          markup: v.defaultMarkup,
        }))
        .filter((v) =>
          variants.find(
            (initialVariant) =>
              initialVariant.id === v.id &&
              (+initialVariant.price !== v.price ||
                initialVariant.manuallySetPrice !== v.updateManuallySetPrice ||
                initialVariant.useCompanyDefault !== v.useCompanyDefault ||
                initialVariant.salePriceOption !== v.salePriceOption ||
                initialVariant.defaultMarkup !== v.markup),
          ),
        ),
    [variants],
  );

  const handleSave = useCallback(
    (variantsToSave: IInventoryVariant[], { resetForm }: FormikHelpers<IInventoryVariant[]>) => {
      const updatedVariantPrices = changedVariants(variantsToSave);

      // could happen if the user has updated the fields from 100 to 100.00
      if (!updatedVariantPrices.length) {
        resetForm();
        return;
      }

      setUpdating(true);
      inventoryApi
        .updateProductPrices(productId, updatedVariantPrices)
        .catch(console.error)
        .finally(() => {
          dispatch(getInventoryProductVariantsAction(productId));
          setUpdating(false);
        });
    },
    [changedVariants, productId, dispatch],
  );

  const handleConnect = useCallback(
    (crowdshipProduct: string) => {
      if (!pendingConnect) return;
      setConnecting(true);
      marketplaceApi
        .connectVariant(pendingConnect, crowdshipProduct)
        .then(({ data }) => {
          if ('success' in data) {
            dispatch(getInventoryProductListAction());
          } else {
            console.error(data);
            setError('Unexpected error');
          }
        })
        .catch((error) => {
          setError('Unexpected error');
          console.error('Error when connecting variant', error);
        })
        .finally(() => {
          setConnecting(false);
          setPendingConnect(undefined);
        });
    },
    [pendingConnect, dispatch],
  );

  const rows = useCallback(
    (
      values: IInventoryVariant[],
      setFieldValue: (field: string, value: any) => void,
      submitForm: (() => Promise<void>) & (() => Promise<any>),
    ) => [
      ...variants.map((v, i) => {
        const valid = !!(
          values &&
          values[i] !== undefined &&
          validation.isNumber(values[i].price.toString()) === undefined
        );
        const updatedPrice = values && valid ? values[i].price : v.price;
        const margin = calculateMargin(v.cost || 0, updatedPrice);
        return [
          <AppThumbnail alt={v.title} source={v.image} />,
          <div className="variant-title">{v.title}</div>,
          <div className="variant-supplier-price">
            {v.cost ? (
              withCurrency(v.cost)
            ) : (
              <AppTextStyle variation="subdued">not set</AppTextStyle>
            )}
          </div>,
          <div className="variant-supplier-price">
            {v.MSRP ? (
              withCurrency(v.MSRP)
            ) : (
              <AppTextStyle variation="subdued">not set</AppTextStyle>
            )}
          </div>,
          <div>
            <PriceSyncPreferencePopover
              unsavedVariant={{
                ...values[i],
                cost: values[i]?.cost?.toString(),
                MSRP: values[i]?.MSRP?.toString(),
                price: values[i]?.price.toString(),
              }}
              variant={{
                ...v,
                cost: v.cost?.toString(),
                MSRP: v.MSRP?.toString(),
                price: v.price.toString(),
              }}
              position={i}
              setFieldValue={setFieldValue}
              setUpdating={setUpdating}
              productId={productId}
              fixed
            >
              <AppButton
                loading={updating}
                disabled={!changedVariants(values).find((v) => values[i].id === v.id)}
                onClick={submitForm}
              >
                Save
              </AppButton>
            </PriceSyncPreferencePopover>
          </div>,
          <AppTextStyle variation={margin && margin > 0 ? 'positive' : 'negative'}>
            {`${margin !== null ? margin : '--'}%`}
          </AppTextStyle>,
          <div className="variant-sku">{v.sku}</div>,
          <div className="variant-detail">
            <TrackedQuantityLabelForVariant
              quantity={v.qty}
              tracked={v.tracked}
              inventoryPolicy={v.inventoryPolicy}
              adding={v.adding}
            />
          </div>,
          <div className="variant-detail">
            <div>{v.supplierName || 'In-house'}</div>
            {v.supplierName && v.supplierSku !== v.sku && (
              <div className="supplier-sku">SKU: {v.supplierSku}</div>
            )}
          </div>,
          <div className="save-btn">
            {!v.connected ? (
              <AppButton
                onClick={() => {
                  setPendingConnect(v.id);
                }}
              >
                Connect
              </AppButton>
            ) : isDisconnectDisable ? (
              <AppTooltip
                content={
                  <p>
                    To enable disconnecting individual variants, disable variant sync in{' '}
                    <AppLink url="/settings?tab=products">product sync settings</AppLink>
                  </p>
                }
              >
                <AppButton disabled>Disconnect</AppButton>
              </AppTooltip>
            ) : (
              <AppButton
                onClick={() => {
                  handleDisconnect(v.id);
                }}
              >
                Disconnect
              </AppButton>
            )}
          </div>,
        ];
      }),
    ],
    [variants, productId, updating, changedVariants, isDisconnectDisable, handleDisconnect],
  );

  return (
    <>
      <Formik initialValues={variants} onSubmit={handleSave} enableReinitialize>
        {({ submitForm, values, setFieldValue }) => (
          <Form name={`variants-${productId}`}>
            <AppDataTable
              columnContentTypes={[
                'text',
                'text',
                'numeric',
                'numeric',
                'text',
                'numeric',
                'numeric',
                'numeric',
                'numeric',
                'text',
              ]}
              headings={[
                'Picture',
                'Variant',
                'Cost',
                'SRP',
                'Price Sync',
                'Your margin',
                'SKU',
                'Qty Available',
                'Fulfilled by',
                '',
              ]}
              rows={rows(values, setFieldValue, submitForm)}
            />
          </Form>
        )}
      </Formik>
      {pendingConnect && (
        <InventoryConnectModal
          open={!!pendingConnect}
          connecting={connecting}
          onClose={() => setPendingConnect(undefined)}
          onConnect={handleConnect}
        />
      )}
      {connecting && <AppToast content="Connecting..." onDismiss={() => setConnecting(false)} />}
      {error && <AppToast error content={error} onDismiss={() => setError('')} />}
    </>
  );
};
