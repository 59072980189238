import { createSelector } from 'reselect';
import { RootState } from '../../reducers';

export const getDashBoardReducerSelector = createSelector(
  [(state: RootState) => state.dashboard],
  (dashboardReducer) => dashboardReducer,
);

export const getOrdersSummarySelector = createSelector(
  [(state: RootState) => state.dashboard.ordersSummary],
  (summary) => summary,
);

export const getSalesSummarySelector = createSelector(
  [(state: RootState) => state.dashboard.salesSummary],
  (summary) => summary,
);

export const getFetching = createSelector(
  [(state: RootState) => state.dashboard.fetching],
  (status) => status,
);
