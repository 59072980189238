import { Button, ButtonProps } from '@shopify/polaris';
import React from 'react';
import './button.scss';

type IButtonProps = ButtonProps & {
  handleClick?: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
};

// Added a handleClick because onClick on Polaris Button components
// doesn't provide the event:MouseEvent in params
export function AppButton(props: IButtonProps) {
  const { handleClick, disabled } = props;
  return (
    <div onClick={!disabled ? handleClick : undefined} className="app-button">
      <Button {...props} />
    </div>
  );
}
