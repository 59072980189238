import { ReactNode, useCallback, useMemo, useState } from 'react';
import { IMarketplaceBuildVariant } from '../../../shopify-retailer/interfaces/IProduct';
import { ILocation } from '../../../shopify-supplier/interfaces/ILocation';
import {
  AVAILABLE_RATES,
  ISupplierShippingTypesMapping,
  ShippingType,
} from '../../../shopify-supplier/interfaces/IPreferences';
import { withCurrency } from '../../helpers/currency.helper';
import { AppIndexTable } from '../index-table/index-table';
import { AppIndexTableCell } from '../index-table/index-table-cell';
import { AppIndexTableRow } from '../index-table/index-table-row';
import { AppTooltip } from '../overlays/tooltip/tooltip';

type IShippingTooltipProps = {
  shippingTypesMapping: ISupplierShippingTypesMapping[];
  location: ILocation;
  variants: Pick<IMarketplaceBuildVariant, 'weight' | 'grams' | 'weightUnit'>[];
  children: ReactNode;
};

export function ShippingTooltip({
  shippingTypesMapping,
  location,
  variants,
  children,
}: IShippingTooltipProps) {
  const weightParameters = useMemo(
    () =>
      variants.find((variant) => Math.min(...variants.map((v) => v.grams || 0)) === variant.grams),
    [variants],
  );

  const [weight] = useState(
    `${weightParameters?.weight || 0} ${weightParameters?.weightUnit || 'lb'}${
      Math.min(...variants.map((wPP) => wPP.grams || 0)) !==
      Math.max(...variants.map((wPP) => wPP.grams || 0))
        ? ' +'
        : ''
    }`,
  );

  const getDefaultDays = useCallback(
    (shippingType: ShippingType): { startDeliveryTime: number; finishDeliveryTime: number } => {
      switch (shippingType) {
        case 'Standard Domestic':
          return {
            startDeliveryTime: 5,
            finishDeliveryTime: 9,
          };
        case 'Expedited Domestic':
          return {
            startDeliveryTime: 2,
            finishDeliveryTime: 5,
          };
        case 'Standard International':
          return {
            startDeliveryTime: 7,
            finishDeliveryTime: 21,
          };
        case 'Expedited International':
          return {
            startDeliveryTime: 5,
            finishDeliveryTime: 14,
          };
      }
    },
    [],
  );

  const renderShippingTableRows = useMemo(() => {
    return shippingTypesMapping.map(
      (
        { shippingType, startDeliveryTime, finishDeliveryTime, flatRateCost, availableRates },
        index,
      ) => {
        let shippingPrice: string;
        if (flatRateCost && availableRates === AVAILABLE_RATES.FLAT_RATE)
          shippingPrice = withCurrency(flatRateCost);
        else
          shippingPrice =
            availableRates === AVAILABLE_RATES.CARRIER_RATES ? 'Live Rates' : 'Free shipping';
        return (
          <AppIndexTableRow id={index.toString()} key={index} position={index} selected={false}>
            <AppIndexTableCell>{shippingType}</AppIndexTableCell>

            <AppIndexTableCell>{shippingPrice}</AppIndexTableCell>
            <AppIndexTableCell>
              {startDeliveryTime || getDefaultDays(shippingType).startDeliveryTime} -{' '}
              {finishDeliveryTime || getDefaultDays(shippingType).finishDeliveryTime}
            </AppIndexTableCell>
          </AppIndexTableRow>
        );
      },
    );
  }, [shippingTypesMapping, getDefaultDays]);

  return (
    <AppTooltip
      content={
        <div>
          <p>
            Ships From {location.province}, {location.country}
          </p>
          <p>Weight: {weight}</p>
          <AppIndexTable
            selectable={false}
            resourceName={{ singular: 'shipping', plural: 'shipping' }}
            headings={[{ title: 'Shipping' }, { title: 'Cost' }, { title: 'Delivery Time (Days)' }]}
            itemCount={shippingTypesMapping.length}
          >
            {renderShippingTableRows}
          </AppIndexTable>
        </div>
      }
    >
      {children}
    </AppTooltip>
  );
}
