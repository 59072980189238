import Decimal from 'decimal.js';
import React, { useCallback, useMemo } from 'react';
import { AppThumbnail } from '../../../../../core/components/image-containers/thumbnail/Thumbnail';
import { AppCard } from '../../../../../core/components/structure/card/card';
import { CardSubsection } from '../../../../../core/components/structure/card/card-subsection';
import '../../../../../core/styles/original-price.scss';
import { IOrderDetailsLineItem } from '../../../../interfaces/IOrder';
import './order-items-stock-updates.scss';

interface IOrderItemsStockUpdatesProps {
  products: IOrderDetailsLineItem[];
  onAccept: () => void;
  onCancel: () => void;
}

export function OrderItemsStockUpdates({
  products,
  onAccept,
  onCancel,
}: IOrderItemsStockUpdatesProps) {
  const productsWithCancellations = useMemo(
    () =>
      products.filter((p) =>
        p.cancellations.some((c) => c.pending && c.initiatedBy === 'crowdship'),
      ),
    [products],
  );

  const notFullyCancelledProducts = useMemo(
    () =>
      products.filter(
        (p) => p.cancellations.reduce((q, c) => q + c.quantity.requested, 0) < p.totalQty,
      ),
    [products],
  );

  const cancelledCost = useMemo(
    () =>
      productsWithCancellations
        .reduce(
          (cost, product) =>
            cost.plus(
              new Decimal(product.cost).mul(
                product.cancellations.reduce((q, c) => q + c.quantity.requested, 0),
              ),
            ),
          new Decimal(0),
        )
        .toFixed(2),
    [productsWithCancellations],
  );
  const cancelledPrice = useMemo(
    () =>
      productsWithCancellations
        .reduce(
          (price, product) =>
            price.plus(
              new Decimal(product.price).mul(
                product.cancellations.reduce((q, c) => q + c.quantity.requested, 0),
              ),
            ),
          new Decimal(0),
        )
        .toFixed(2),
    [productsWithCancellations],
  );

  const remainingCost = useMemo(
    () =>
      notFullyCancelledProducts
        .reduce(
          (cost, product) =>
            cost.plus(
              new Decimal(product.cost).mul(
                product.totalQty -
                  product.cancellations.reduce((q, c) => q + c.quantity.requested, 0),
              ),
            ),
          new Decimal(0),
        )
        .toFixed(2),
    [notFullyCancelledProducts],
  );
  const remainingPrice = useMemo(
    () =>
      notFullyCancelledProducts
        .reduce(
          (price, product) =>
            price.plus(
              new Decimal(product.price).mul(
                product.totalQty -
                  product.cancellations.reduce((q, c) => q + c.quantity.requested, 0),
              ),
            ),
          new Decimal(0),
        )
        .toFixed(2),
    [notFullyCancelledProducts],
  );

  const calculateTotalVariantPrice = useCallback((price: number, quantity: number) => {
    return new Decimal(price).mul(quantity).toFixed(2);
  }, []);

  const productListHeader = useMemo(
    () => (
      <CardSubsection>
        <div className="line-item-row">
          <div className="image">Image</div>
          <div className="item">Title</div>
          <div className="price">Price</div>
          <div className="cost">Cost</div>
          <div className="qty">Quantity/Total</div>
          <div className="total">Total price</div>
          <div className="total">Total cost</div>
        </div>
      </CardSubsection>
    ),
    [],
  );

  const productListCancelledTotals = useMemo(
    () => (
      // <CardSubsection>
      <div className="line-item-row">
        <div className="spacer"></div>
        <div className="spacer"></div>
        <div className="spacer"></div>
        <div className="spacer"></div>
        <div className="title">Pending cancellation:</div>
        <div className="total cost">${cancelledPrice}</div>
        <div className="total price">${cancelledCost}</div>
      </div>
      //</CardSubsection>
    ),
    [cancelledCost, cancelledPrice],
  );
  const productListRemainingTotals = useMemo(
    () => (
      //<CardSubsection>
      <div className="line-item-row">
        <div className="spacer"></div>
        <div className="spacer"></div>
        <div className="spacer"></div>
        <div className="spacer"></div>
        <div className="title">Total:</div>
        <div className="total cost">${remainingPrice}</div>
        <div className="total price">${remainingCost}</div>
      </div>
      // </CardSubsection>
    ),
    [remainingCost, remainingPrice],
  );

  const renderProductList = useCallback(
    (product: IOrderDetailsLineItem, index: number, cancelled: boolean) => {
      const strikeThrough = cancelled ? 'original-price' : '';
      const quantity = cancelled
        ? product.cancellations
            .filter((c) => c.pending && c.initiatedBy === 'crowdship')
            .reduce((q, c) => q + c.quantity.requested, 0)
        : product.totalQty -
          product.cancellations
            .filter((c) => c.pending && c.initiatedBy === 'crowdship')
            .reduce((q, c) => q + c.quantity.requested, 0);
      return (
        <CardSubsection key={index}>
          <div className={`line-item-row ${strikeThrough}`}>
            <div className="image">
              <AppThumbnail alt={product.productGroupTitle} source={product.image} />
            </div>
            <div className="item">
              <div className="title">{product.productGroupTitle}</div>
              <div className="variant">{product.title}</div>
              <div className="sku">SKU: {product.sku}</div>
            </div>
            <div className="price">${product.price}</div>
            <div className="cost">${product.cost}</div>
            <div className="qty">
              {quantity}/
              {product.totalQty -
                product.cancellations.reduce((q, c) => q + c.quantity.accepted, 0)}
            </div>
            <div className="total price">
              ${calculateTotalVariantPrice(product.price, quantity)}
            </div>
            <div className="total cost">${calculateTotalVariantPrice(product.cost, quantity)}</div>
          </div>
        </CardSubsection>
      );
    },

    [calculateTotalVariantPrice],
  );

  const renderCrowdshipCancellation = useMemo(() => {
    return (
      <div className="order-cancellation-products">
        {productListHeader}
        {productsWithCancellations.map((p, i) => renderProductList(p, i, true))}
        {notFullyCancelledProducts.map((p, i) => renderProductList(p, i, false))}
        <CardSubsection>
          {productListCancelledTotals}
          {productListRemainingTotals}
        </CardSubsection>
      </div>
    );
  }, [
    productListHeader,
    productsWithCancellations,
    notFullyCancelledProducts,
    renderProductList,
    productListCancelledTotals,
    productListRemainingTotals,
  ]);

  if (!notFullyCancelledProducts.length)
    return (
      <div className="crowdship-order-cancellation">
        <AppCard
          sections={[{ content: renderCrowdshipCancellation }]}
          primaryFooterAction={{
            content: 'Cancel order',
            onAction: onAccept,
          }}
        />
      </div>
    );

  return (
    <div className="crowdship-order-cancellation">
      <AppCard
        sections={[{ content: renderCrowdshipCancellation }]}
        primaryFooterAction={{
          content: 'Cancel out-of-stock items and pay',
          onAction: onAccept,
        }}
        secondaryFooterActions={[
          {
            content: 'Cancel order',
            onAction: onCancel,
          },
        ]}
      />
    </div>
  );
}
