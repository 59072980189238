import { ToggleOffIcon, ToggleOnIcon } from '@shopify/polaris-icons';
import React, { useCallback } from 'react';
import { AppButton } from '../../button/Button';
import { ButtonProps, Icon } from '@shopify/polaris';
import { Field, useFormikContext } from 'formik';

import './controlled-switch.scss';

type SwitchProps = ButtonProps & {
  name: string;
  value?: boolean;
};

export function AppSwitch({ value, name, ...props }: SwitchProps) {
  const { setFieldValue } = useFormikContext();

  const handleChange = useCallback(() => setFieldValue(name, !value), [name, setFieldValue, value]);

  return (
    <Field name={name}>
      {() => (
        <div className="app-switch">
          <AppButton
            icon={value ? <Icon source={ToggleOnIcon} color="success" /> : ToggleOffIcon}
            onClick={handleChange}
            {...props}
          />
        </div>
      )}
    </Field>
  );
}
