import React, { useMemo } from 'react';
import { AppModal } from '../../../../core/components/overlays/modal/modal';
import { AppTextStyle } from '../../../../core/components/text/text-style/TextStyle';
import { PRODUCT_REMOVE_FROM_CROWDSHIP_OPTION } from '../../../interfaces/IPreferences';
import { IProductMatch } from '../../../interfaces/IProductsSync';

interface IProductSyncConfirmationModalProps {
  matches: IProductMatch[];
  open: boolean;
  onClose: () => void;
  onConfirm: () => void;
}

export const ProductSyncConfirmationModal = ({
  matches,
  open,
  onClose,
  onConfirm,
}: IProductSyncConfirmationModalProps) => {
  const productTitles = useMemo(
    () => matches.map((m) => m.retailerProduct.title).join(', '),
    [matches],
  );

  const deleteNotice = useMemo(
    () => (
      <p className="delete-notice">
        <AppTextStyle variation="strong"> NOTICE:</AppTextStyle> You have chosen to delete variants
        unsupported by Crowdship. This is the recommended behavior, since if these variants are left
        in your store, they can still be sold but will not be fulfilled. This warning is to make
        sure you understand that the unsupported variants will be deleted from your Shopify store.
        This cannot be reverted.
      </p>
    ),
    [],
  );

  const leaveWarning = useMemo(
    () => (
      <p className="leave-warning">
        <AppTextStyle variation="strong"> WARNING: </AppTextStyle> Please keep in mind that you've
        chosen to leave unsupported variants which Crowdship is not able to fulfill. They cannot be
        hidden due to Shopify restrictions but can still be sold. Consider deleting them instead.
      </p>
    ),
    [],
  );

  const mixedWarning = useMemo(
    () => (
      <div className="mixed-warning">
        <p>
          <AppTextStyle variation="strong"> WARNING: </AppTextStyle> You've chosen to leave some
          unsupported variants, while deleting others. If you don't have any unique information in
          the variants you chose to Leave, we recommend that you also delete them.
        </p>
        <p>
          Please note that if you do not delete the unsupported variants, they will end up being
          shown to your customers as purchasable options. Your customers may end up purchasing them,
          but Crowdship will not be able to fulfill them.
        </p>
      </div>
    ),
    [],
  );

  const message = useMemo(() => {
    if (
      matches.some((m) =>
        m.retailerProduct.variants.some(
          (v) => v.isObsolete && v.deleteOption === PRODUCT_REMOVE_FROM_CROWDSHIP_OPTION.DELETE,
        ),
      )
    ) {
      if (
        matches.some((m) =>
          m.retailerProduct.variants.some(
            (v) => v.isObsolete && v.deleteOption === PRODUCT_REMOVE_FROM_CROWDSHIP_OPTION.LEAVE,
          ),
        )
      ) {
        return mixedWarning;
      }
      return deleteNotice;
    }
    if (
      matches.some((m) =>
        m.retailerProduct.variants.some(
          (v) => v.isObsolete && v.deleteOption === PRODUCT_REMOVE_FROM_CROWDSHIP_OPTION.LEAVE,
        ),
      )
    ) {
      return leaveWarning;
    }
  }, [matches, leaveWarning, deleteNotice, mixedWarning]);

  return (
    <AppModal
      open={open}
      onClose={onClose}
      title="Confirmation"
      primaryAction={{
        content: 'Confirm',
        onAction: onConfirm,
      }}
      secondaryActions={[
        {
          content: 'Cancel',
          onAction: onClose,
        },
      ]}
    >
      <p>
        Product{matches.length > 1 ? 's' : ''} {productTitles} has variants that aren't supported by
        Crowdship.
      </p>
      {message}
    </AppModal>
  );
};
