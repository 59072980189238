import moment from 'moment-timezone';
import React from 'react';
import { AppBanner } from '../../../../core/components/feedback-indicators/banner/banner';
import { IRetailerStatus } from '../../../interfaces/IPlan';
import './account-suspension-banner.scss';

interface IAccountSuspensionBannerProps {
  status: IRetailerStatus | null;
}

export const AccountSuspensionBanner = ({ status }: IAccountSuspensionBannerProps) => {
  if (!status || !status.suspension) return null;
  const { gracePeriodEnd, onHoldDueToSuspensionOrdersCount } = status.suspension;

  const gracePeriodEnded = moment(gracePeriodEnd).isBefore();

  if (gracePeriodEnded)
    return (
      <div className="banner">
        <AppBanner status="critical" title="We could not charge you for transaction fees">
          <p>
            We had issues when trying charge you for transaction fees. Order processing was stopped
            on {moment(gracePeriodEnd).format('MMMM Do')}. Please check if your credit card is valid
            and has sufficient funds.
          </p>
          {!!onHoldDueToSuspensionOrdersCount && (
            <p>
              You have {onHoldDueToSuspensionOrdersCount} order
              {onHoldDueToSuspensionOrdersCount > 1 && 's'} pending.
            </p>
          )}
        </AppBanner>
      </div>
    );

  return (
    <div className="banner">
      <AppBanner status="warning" title="We could not charge you for transaction fees">
        <p>
          We had issues when trying charge you for transaction fees. We will stop processing your
          orders on {moment(gracePeriodEnd).format('MMMM Do')} if this issue will not be resolved.
          Please check if your credit card is valid and has sufficient funds.
        </p>
      </AppBanner>
    </div>
  );
};
