import React, { useState } from 'react';
import { AppBanner } from '../../../../core/components/feedback-indicators/banner/banner';
import { AppLink } from '../../../../core/components/link/link';
import { IRetailerStatus } from '../../../interfaces/IPlan';
import './order-payment-failed-banner.scss';

interface IOrderPaymentFailedBannerProps {
  status: IRetailerStatus | null;
}

export function OrderPaymentFailedBanner({ status }: IOrderPaymentFailedBannerProps) {
  const [show, setShow] = useState<boolean>(true);

  if (!status || !status.unpaidOrdersCount || !show) return null;

  const { unpaidOrdersCount } = status;

  const multipleOrders = unpaidOrdersCount > 1;
  const orderMsg = multipleOrders ? `${unpaidOrdersCount} orders` : 'an order';
  return (
    <div className="banner">
      <AppBanner status="critical" title="You have unpaid orders" onDismiss={() => setShow(false)}>
        <p>
          We had issues when trying charge you for {orderMsg} that include{!multipleOrders && 's'}{' '}
          Crowdship products. These orders will not be shown to suppliers unless they are paid.
          Please check if your credit card is valid and has sufficient funds and try again to pay
          for the order at the <AppLink url="/orders">orders page</AppLink>.
        </p>
      </AppBanner>
    </div>
  );
}
