import { EmptySearchResult } from '@shopify/polaris';
import Decimal from 'decimal.js';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router';
import { AppBadge } from '../../../../core/components/badge/badge';
import { FetchingBanner } from '../../../../core/components/banners/fetching-banner/fetching-banner';
import { AppButton } from '../../../../core/components/button/Button';
import { AppChoiceList } from '../../../../core/components/choice-list/ChoiceList';
import { AppDataTable } from '../../../../core/components/data-table/DataTable';
import { DateTooltip } from '../../../../core/components/date-tooltip/date-tooltip';
import { AppFilters, IAppliedFilter } from '../../../../core/components/filters/filters';
import { AppLink } from '../../../../core/components/link/link';
import { PageAwarePagination } from '../../../../core/components/pagination/page-aware-pagination';
import { AppTextStyle } from '../../../../core/components/text/text-style/TextStyle';
import { PAYOUTS_PER_PAGE } from '../../../../core/constants/orders';
import { withCurrency } from '../../../../core/helpers/currency.helper';
import { getFormattedDate } from '../../../../core/helpers/date.helper';
import { ICurrency } from '../../../../core/interfaces/ICurrency';
import { IPayoutDetails, IPayoutRequest } from '../../../interfaces/IOrder';
import { IPayout } from '../../../interfaces/IPayout';
import { getPayoutsAction, updatePayoutAction } from '../../../redux/modules/orders/orders.actions';
import {
  getPayoutFetchingSelector,
  getPayoutListSelector,
} from '../../../redux/modules/orders/orders.selectors';
import { InvoiceModal } from '../../modals/invoice/invoice-modal';
import { useDebouncedCallback } from 'use-debounce';
import { getQueryString } from '../../../../core/helpers/get-query-string.helper';

const payoutStatuses = ['open', 'paid', 'payment pending'] as const;
const statusOptions = payoutStatuses.map((s) => ({ label: s, value: s }));

interface IPayoutListProps {
  currency?: ICurrency;
}
export const PayoutList = ({ currency = { isoCode: 'USD', symbol: '$' } }: IPayoutListProps) => {
  const dispatch = useDispatch();
  const { search } = useLocation();

  const history = useHistory();
  const parsedQuery = new URLSearchParams(search);
  const status = parsedQuery.get('status') as string;
  const searchQuery = parsedQuery.get('query') as string;
  const initialPage = parsedQuery.get('page');
  const [selectedStatus, setSelectedStatus] = useState<string[]>(
    status ? [status.replace('-', ' ')] : [],
  );
  const [openUpdatePayoutModal, setOpenUpdatePayoutModal] = useState(false);
  const [selectedPayoutDetails, setSelectedPayoutDetails] = useState<IPayoutDetails | null>(null);
  const [page, setPage] = useState(+(initialPage || 0));
  const [searchString, setSearchString] = useState(searchQuery || '');
  const [query, setQuery] = useState(searchQuery || '');

  const { payouts, total } = useSelector(getPayoutListSelector);
  const fetching = useSelector(getPayoutFetchingSelector);

  useEffect(() => {
    dispatch(getPayoutsAction(0, selectedStatus[0], query));
  }, [dispatch, query, selectedStatus]);

  const updatePayoutHandler = useCallback(
    (request: IPayoutRequest) => {
      if (selectedPayoutDetails)
        dispatch(updatePayoutAction(selectedPayoutDetails.payoutId, request, page));
      setOpenUpdatePayoutModal(false);
      setSelectedPayoutDetails(null);
    },
    [dispatch, page, selectedPayoutDetails],
  );

  const changeUrlParams = useCallback(
    (params: { page: number; status: string; query: string }) => {
      const search = getQueryString({ ...params, tab: 'invoice-history' });
      history.push({ search });
    },
    [history],
  );

  const onPageChange = useCallback(
    (page: number) => {
      dispatch(getPayoutsAction(page, selectedStatus[0], query));
      setPage(page);
      changeUrlParams({
        status: selectedStatus[0],
        query,
        page,
      });
    },
    [changeUrlParams, dispatch, query, selectedStatus],
  );
  const totalPayoutsPageCount = useMemo(() => Math.ceil(total / PAYOUTS_PER_PAGE), [total]);

  const renderAdjustment = useCallback(
    (amount?: number, reason?: string) =>
      amount && (
        <div className="adjustment">
          <div className="adj-amount">{withCurrency(amount, currency)}</div>
          {reason && (
            <div className="adj-reason">
              <AppTextStyle variation="subdued"> ({reason})</AppTextStyle>
            </div>
          )}
        </div>
      ),
    [currency],
  );
  const calculateTotal = useCallback(
    (amount: number, adjustment?: number) =>
      adjustment ? +new Decimal(amount).plus(adjustment).toFixed(2) : amount,
    [],
  );

  const renderStatus = useCallback((payout: IPayout) => {
    const {
      _id,
      adjustmentAmount,
      adjustmentReason,
      confirmationId,
      amount,
      status,
      paidAt,
      dueFrom,
      pendingConfirmation,
    } = payout;

    return (
      <div className="status-paid">
        <div className="status">
          <AppBadge
            progress={status === 'open' ? 'incomplete' : 'complete'}
            status={status !== 'open' ? 'success' : pendingConfirmation ? 'warning' : undefined}
          >
            {status === 'open' && pendingConfirmation ? 'payment pending' : status}
          </AppBadge>
        </div>
        {paidAt && <AppTextStyle variation="subdued">at {getFormattedDate(paidAt)}</AppTextStyle>}
        {dueFrom !== 'Crowdship' && !pendingConfirmation && (
          <AppButton
            plain
            onClick={() => {
              setSelectedPayoutDetails({
                payoutId: _id,
                amount: amount,
                adjustmentAmount: adjustmentAmount,
                adjustmentReason: adjustmentReason,
                confirmationId: confirmationId,
                paid: status === 'paid',
                ...(status === 'paid' && paidAt && { paidAt: new Date(paidAt).getTime() }),
              });
              setOpenUpdatePayoutModal(true);
            }}
          >
            Edit
          </AppButton>
        )}
      </div>
    );
  }, []);

  const renderPayoutsTableRows = useMemo(
    () =>
      payouts.map((p) => [
        p.name,
        <DateTooltip date={p.createdAt} />,
        p.dueFrom,
        renderStatus(p),
        p.ordersCount,
        withCurrency(p.amount, currency),
        renderAdjustment(p.adjustmentAmount, p.adjustmentReason),
        withCurrency(calculateTotal(p.amount, p.adjustmentAmount), currency),
        <AppLink url={`/accounting/${p._id}`}>View details</AppLink>,
      ]),
    [payouts, renderAdjustment, calculateTotal, currency, renderStatus],
  );

  const handleStatusUpdate = useCallback(
    (status: string[]) => {
      setSelectedStatus(status);
      changeUrlParams({
        status: status[0],
        query,
        page: 0,
      });
    },
    [changeUrlParams, query],
  );

  const appliedFilters: IAppliedFilter[] = useMemo(() => {
    const appliedStatus = {
      key: 'selectedStatus',
      label: `Status: ${selectedStatus[0] || ''}`,
      onRemove: () => handleStatusUpdate([]),
    };

    if (selectedStatus.length) return [appliedStatus];

    return [];
  }, [selectedStatus, handleStatusUpdate]);

  const onQueryChange = (value: string) => {
    setSearchString(value);
    handleQueryChange(value);
  };

  const handleQueryChange = useDebouncedCallback((value: string) => {
    setQuery(value);
    changeUrlParams({
      status: selectedStatus[0],
      query: value,
      page: 0,
    });
  }, 500);

  const handleClearSearch = useCallback(() => {
    setSearchString('');
    setQuery('');
    changeUrlParams({
      status: selectedStatus[0],
      query: '',
      page: 0,
    });
  }, [changeUrlParams, selectedStatus]);

  return (
    <div>
      <AppFilters
        filters={[
          {
            key: 'selectedStatus',
            label: 'Status',
            shortcut: true,
            filter: (
              <AppChoiceList
                title="Supplier list"
                titleHidden
                choices={statusOptions}
                selected={selectedStatus}
                onChange={handleStatusUpdate}
              />
            ),
          },
        ]}
        appliedFilters={appliedFilters}
        onQueryChange={onQueryChange}
        onQueryClear={handleClearSearch}
        onClearAll={() => handleStatusUpdate([])}
        queryValue={searchString}
      />
      {fetching && <FetchingBanner bodyText="Loading payouts" />}
      {!!payouts.length && (
        <>
          <AppDataTable
            verticalAlign="middle"
            columnContentTypes={[
              'text',
              'text',
              'text',
              'text',
              'numeric',
              'numeric',
              'numeric',
              'numeric',
              'text',
            ]}
            headings={[
              'ID',
              'Created at',
              'Due From',
              'Status',
              'Orders count',
              'Amount',
              'Adjustment',
              'Total',
              '',
            ]}
            rows={renderPayoutsTableRows}
          />
          <PageAwarePagination totalPageCount={totalPayoutsPageCount} onPageChange={onPageChange} />
        </>
      )}
      {!fetching && !payouts.length && (
        <EmptySearchResult title={'No payouts to display'} withIllustration />
      )}
      {selectedPayoutDetails && (
        <InvoiceModal
          title="Update invoice"
          open={openUpdatePayoutModal}
          onClose={() => setOpenUpdatePayoutModal(false)}
          payout={selectedPayoutDetails}
          invoiceHandler={updatePayoutHandler}
          currency={currency}
        />
      )}
    </div>
  );
};
