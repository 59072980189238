import React, { useCallback, useEffect, useState } from 'react';
import { AccountInfoForm } from '../../../../../core/components/account-details/account-info-form';
import { AppButton } from '../../../../../core/components/button/Button';
import { AppDropZone } from '../../../../../core/components/drop-zone/drop-zone';
import { AppBanner } from '../../../../../core/components/feedback-indicators/banner/banner';
import { AppThumbnail } from '../../../../../core/components/image-containers/thumbnail/Thumbnail';
import { AppCard } from '../../../../../core/components/structure/card/card';
import { AppLayout } from '../../../../../core/components/structure/layout/layout';
import { AppLayoutAnnotatedSection } from '../../../../../core/components/structure/layout/layout-annotated-section';
import { AppTextStyle } from '../../../../../core/components/text/text-style/TextStyle';
import { getFileType } from '../../../../../core/helpers/get-file-type.helper';
import { IProfilePreferences } from '../../../../interfaces/IPreferences';

interface CompanySectionProps {
  unsavedPreferences: IProfilePreferences;
  setFieldValue: (field: string, value: any) => void;
}

export function CompanySection({ unsavedPreferences, setFieldValue }: CompanySectionProps) {
  const [rejectedFiles, setRejectedFiles] = useState<any[]>([]);
  const [logoExists, setLogoExists] = useState(false);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  }, []);

  const handleDropLogo = useCallback(
    (_droppedFiles, acceptedFiles, rejectedFiles) => {
      setRejectedFiles([]);

      if (unsavedPreferences.logo) {
        setLogoExists(true);
        return;
      }

      if (acceptedFiles.length !== 0) {
        setLogoExists(false);
        setFieldValue('preferences.profile.logo', acceptedFiles[0]);
      }
      setRejectedFiles(rejectedFiles);
    },
    [setFieldValue, unsavedPreferences.logo],
  );

  return (
    <div className="company-section">
      <AppLayout>
        <div className="company-section__profile">
          {rejectedFiles.length > 0 && (
            <AppBanner title="The following file couldn’t be uploaded:" status="critical">
              <p>{rejectedFiles[0].name} is not supported. File type must be .png or .jpeg.</p>
            </AppBanner>
          )}
          {logoExists && <AppBanner title="Please delete existing logo" status="critical" />}
          <AppLayoutAnnotatedSection
            title="Company Logo"
            description="Upload a Profile Image. We recommend using your company logo."
          >
            <AppCard sectioned>
              <div className="company-section__profile__description">
                <AppTextStyle variation="strong">Profile Image</AppTextStyle>
                <p>Use .png or .jpeg</p>
              </div>
              <AppDropZone
                accept="image/png, image/jpeg"
                type="image"
                onDrop={(files: File[], acceptedFiles: File[], rejectedFiles: File[]) =>
                  handleDropLogo(files, acceptedFiles, rejectedFiles)
                }
                outline={false}
                variableHeight
                allowMultiple={false}
              >
                <AppButton>Upload File</AppButton>
              </AppDropZone>
            </AppCard>
            {unsavedPreferences.logo && (
              <div className="company-section__profile__logo">
                <AppThumbnail
                  size="large"
                  alt={'logo'}
                  source={getFileType(unsavedPreferences.logo)}
                />
                <AppButton
                  plain
                  destructive
                  onClick={() => setFieldValue('preferences.profile.logo', undefined)}
                >
                  Delete Image
                </AppButton>
              </div>
            )}
          </AppLayoutAnnotatedSection>
        </div>
        <AccountInfoForm formFieldPrefix={'account.'} />
      </AppLayout>
    </div>
  );
}
