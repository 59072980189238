import Decimal from 'decimal.js';

type WeightUnit = 'lb' | 'oz' | 'kg';

const POUNDS_IN_GRAM = 0.00220462;
const KILOGRAMS_IN_GRAM = 0.001;
const OUNCES_IN_GRAM = 0.035274;
const OUNCES_IN_POUND = 16;

export const convertWeightFromGrams = (grams: number, targetUnit: WeightUnit = 'lb'): number => {
  switch (targetUnit) {
    case 'lb':
      return +new Decimal(grams).mul(POUNDS_IN_GRAM).toFixed(2);
    case 'kg':
      return +new Decimal(grams).mul(KILOGRAMS_IN_GRAM).toFixed(2);
    case 'oz':
      return +new Decimal(grams).mul(OUNCES_IN_GRAM).toFixed(2);
    default:
      console.error('Unknown weight conversion target unit');
      return 0;
  }
};

export const convertWeightFromGramsToPoundsAndOunces = (
  grams: number,
): { lbs: number; oz: number } => {
  const lbs = new Decimal(convertWeightFromGrams(grams));
  const roundLbs = lbs.floor();
  const oz = lbs.minus(roundLbs).mul(OUNCES_IN_POUND).ceil();
  return { lbs: roundLbs.toNumber(), oz: oz.toNumber() };
};

export const convertPoundsAndOuncesToGrams = (
  lbs: number | string,
  oz: number | string,
): number => {
  const lbsToGrams = new Decimal(lbs).div(POUNDS_IN_GRAM);
  const ozToGrams = new Decimal(oz).div(OUNCES_IN_GRAM);
  return +lbsToGrams.plus(ozToGrams).round();
};
