import { createAction } from 'typesafe-actions';
import {
  IDashboardOrdersSummary,
  IDashboardProductsSummary,
  IDashboardSalesSummary,
} from '../../../interfaces/IDashboard';
import { ACTIONS_CONSTANTS } from '../../actions-constants';

export const getDashboardOrdersSummaryAction = createAction(
  ACTIONS_CONSTANTS.GET_DASHBOARD_ORDERS_SUMMARY,
)();

export const setDashboardOrdersSummaryAction = createAction(
  ACTIONS_CONSTANTS.SET_DASHBOARD_ORDERS_SUMMARY,
  (summary: IDashboardOrdersSummary) => summary,
)();

export const getDashboardProductsSummaryAction = createAction(
  ACTIONS_CONSTANTS.GET_DASHBOARD_PRODUCTS_SUMMARY,
)();

export const setDashboardProductsSummaryAction = createAction(
  ACTIONS_CONSTANTS.SET_DASHBOARD_PRODUCTS_SUMMARY,
  (summary: IDashboardProductsSummary) => summary,
)();

export const getDashboardSalesSummaryAction = createAction(
  ACTIONS_CONSTANTS.GET_DASHBOARD_SALES_SUMMARY,
)();

export const setDashboardSalesSummaryAction = createAction(
  ACTIONS_CONSTANTS.SET_DASHBOARD_SALES_SUMMARY,
  (summary: IDashboardSalesSummary) => summary,
)();
