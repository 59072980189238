import React, { useEffect } from 'react';
import { AppBanner } from '../../../../core/components/feedback-indicators/banner/banner';
import { AppList } from '../../../../core/components/list/list';
import { IFailureProduct } from '../../../api/products.api';
import { getErrorReason } from '../../../constants/moving-product-error-message.constants';
import { MoveOpType } from '../../../redux/modules/products/products.reducer';

type IMovingProductsResultBannerProps = {
  success: boolean;
  action: MoveOpType;
  onDismiss: () => void;
  failure: IFailureProduct[];
  successTitles: string[];
  isNeedToUp?: boolean;
};

export function MovingProductsResultBanner({
  success,
  action,
  onDismiss,
  failure,
  successTitles,
  isNeedToUp = false,
}: IMovingProductsResultBannerProps) {
  useEffect(() => {
    if (isNeedToUp) {
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: 'smooth',
      });
    }
  }, [isNeedToUp]);

  const maxProducts = 4;

  const title = `Products ${success ? '' : 'not'} ${action}`;
  const preposition = action === 'added' ? 'to' : 'from';
  const list = success
    ? successTitles
    : failure.map((f) => `${f.title} (${getErrorReason(f.reason)})`);
  return (
    <AppBanner status={success ? 'success' : 'warning'} title={title} onDismiss={onDismiss}>
      <p>
        The following products were{success || "n't"} {action} {preposition} Crowdship
      </p>
      <AppList children={list.slice(0, maxProducts)} />
      {list.length > maxProducts && <p>and {list.length - maxProducts} more</p>}
    </AppBanner>
  );
}
