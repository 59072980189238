import { OptionDescriptor } from '@shopify/polaris/build/ts/latest/src/types';
import { useEffect, useState } from 'react';
import { useDebounce } from 'use-debounce/lib';
import { AppControlledAutocompleteFieldWithLoading } from '../../../../../core/components/forms/controlled-autocomplete-text-field/controlled-autocomplete-with-loading';
import { retailerApi } from '../../../../api/retailer.api';

type RetailerAutocompleteFieldProps = {
  name: string;
  excludeOptions: OptionDescriptor[];
};

export const ControlledRetailerAutocompleteField = ({
  name,
  excludeOptions,
}: RetailerAutocompleteFieldProps) => {
  const [retailerFilter, setRetailerFilter] = useState('');
  const [loading, setLoading] = useState(false);
  const [debouncedRetailerFilter] = useDebounce(retailerFilter, 500);
  const [retailerOptions, setRetailerOptions] = useState<OptionDescriptor[]>([]);

  useEffect(() => {
    if (debouncedRetailerFilter) {
      setLoading(true);
      retailerApi
        .filterRetailers(debouncedRetailerFilter)
        .then((res) => {
          setRetailerOptions(
            res.data
              .filter((r) => !excludeOptions.some((o) => o.value === r._id))
              .map((r) => ({ value: r._id, label: r.name })),
          );
        })
        .catch((e) => {
          console.error(e);
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      setRetailerOptions([]);
      setLoading(false);
    }
  }, [debouncedRetailerFilter, excludeOptions]);

  return (
    <AppControlledAutocompleteFieldWithLoading
      name={name}
      placeholder="Start typing retailer's name"
      options={retailerOptions}
      loading={loading}
      onChange={(value) => setRetailerFilter(value)}
    />
  );
};
