import { FiltersProps } from '@shopify/polaris';
import { useCallback, useState } from 'react';
import { useDebouncedCallback } from 'use-debounce';
import { AppFilters } from '../../../../filters/filters';

type QueryFilterContainerProps = Omit<
  FiltersProps,
  'onQueryChange' | 'onQueryClear' | 'queryValue'
> & {
  value: string;
  onChange: (query: string) => void;
};

export const QueryFilterContainer = ({
  value,
  onChange,
  onClearAll,
  ...rest
}: QueryFilterContainerProps) => {
  const [query, setQuery] = useState(value);

  const debouncedOnQueryChange = useDebouncedCallback(() => {
    onChange(query);
  }, 500);

  const handleQueryChange = useCallback(
    (updatedQuery: string) => {
      setQuery(updatedQuery);
      debouncedOnQueryChange();
    },
    [debouncedOnQueryChange],
  );

  const handleQueryClear = useCallback(() => {
    setQuery('');
    onChange('');
  }, [onChange]);

  const handleClearAll = useCallback(() => {
    setQuery('');
    onClearAll();
  }, [onClearAll]);

  return (
    <AppFilters
      queryValue={query}
      onQueryChange={handleQueryChange}
      onQueryClear={handleQueryClear}
      onClearAll={handleClearAll}
      {...rest}
    />
  );
};
