import { DatePicker, List } from '@shopify/polaris';
import queryString from 'query-string';
import { useLocation } from 'react-router';
import { SelectionType } from '@shopify/polaris/build/ts/latest/src/utilities/index-provider';
import moment from 'moment';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { AppChoiceList } from '../../../../core/components/choice-list/ChoiceList';
import { AppCollapsible } from '../../../../core/components/collapsible/Collapsible';
import { AppFilters, IAppliedFilter } from '../../../../core/components/filters/filters';
import { AppIndexTable } from '../../../../core/components/index-table/index-table';
import { PageAwarePagination } from '../../../../core/components/pagination/page-aware-pagination';
import { IAction } from '../../../../core/components/resource-list/ResourceItem';
import { TIME_INTERVAL } from '../../../../core/constants/product.constants';
import { ICurrency } from '../../../../core/interfaces/ICurrency';
import { IRetailersForUnpaidOrders } from '../../../api/orders.api';
import { IOrderListFilter, ISupplierOrder } from '../../../interfaces/IOrder';
import { BaseOrderListRows } from '../base-order-list-rows/base-order-list-rows';
import { Paginated } from '../../../../core/helpers/generic.helper';
import { ORDERS_PER_PAGE } from '../../../../core/constants/orders';

import './filtered-order-list.scss';

interface IFilteredOrderListProps {
  orders: (ISupplierOrder & { orderId: string; refundId: string })[];
  fetching: boolean;
  totalPageCount: number;
  onPageChange: (page: number) => void;
  currency: ICurrency;
  onSelectionChange: (values: string[]) => void;
  setFilterParams: (
    retailerId?: string,
    paidViaCrowdship?: boolean,
    dateRange?: {
      start: number;
      end: number;
    },
  ) => void;
  setTotalAmount: (total: number) => void;
  listActions: IAction[];
  retailers: IRetailersForUnpaidOrders[];
  selectedRetailer: string[];
  setSelectedRetailer: (values: string[]) => void;
  setSelectable: (selectable: boolean) => void;
  selectedUnpaidOrders: string[];
  handleSelectionChange: (
    selectionType: SelectionType,
    isSelecting: boolean,
    selection?: any,
  ) => void;
  selectedResources: string[];
  allResourcesSelected: boolean;
  filters: IOrderListFilter;
  changeUrlParams: (
    tabNumber: any,
    params?: Omit<Paginated<IOrderListFilter>, 'dateRange'> & {
      period?: TIME_INTERVAL;
      start?: string;
      end?: string;
    },
  ) => void;
}

export const FilteredOrderList = ({
  orders,
  fetching,
  totalPageCount,
  onPageChange,
  onSelectionChange,
  listActions,
  currency,
  setFilterParams,
  retailers,
  setTotalAmount,
  selectedRetailer,
  setSelectedRetailer,
  setSelectable,
  selectedUnpaidOrders,
  handleSelectionChange,
  selectedResources,
  allResourcesSelected,
  filters,
  changeUrlParams,
}: IFilteredOrderListProps) => {
  const { search: query } = useLocation();
  const {
    start: URLStartDate,
    end: URLEndDate,
    period: URLPeriod,
  } = queryString.parse(query, { arrayFormat: 'separator', arrayFormatSeparator: '.' });

  const [period, setPeriod] = useState<TIME_INTERVAL[]>(
    URLPeriod ? [(URLPeriod as string).replace('-', ' ') as TIME_INTERVAL] : [TIME_INTERVAL.NONE],
  );
  const [{ month, year }, setDate] = useState({
    month: URLStartDate ? moment(URLStartDate as string).month() : moment().month(),
    year: URLStartDate ? moment(URLStartDate as string).year() : moment().year(),
  });
  const [selectedDates, setSelectedDates] = useState({
    start: URLStartDate
      ? moment(URLStartDate as string).toDate()
      : moment().subtract(7, 'd').toDate(),
    end: URLEndDate
      ? moment(URLEndDate as string)
          .endOf('day')
          .toDate()
      : moment().toDate(),
  });

  const retailerOptions = useMemo(
    () => retailers.map((r) => ({ label: r.label, value: r.value })),
    [retailers],
  );

  useEffect(() => {
    return () => setFilterParams();
  }, [setFilterParams]);

  useEffect(() => {
    setSelectable(!!selectedRetailer[0] && selectedRetailer[0] !== 'Crowdship');
  }, [selectedRetailer, setFilterParams, setSelectable]);

  const toTimestamp = useCallback((date: Date) => Math.floor(date.getTime()), []);

  const getChosenDateRange = useCallback(
    (interval: TIME_INTERVAL): { start: number; end: number } | undefined => {
      const todayTimestamp = toTimestamp(moment().toDate());
      switch (interval) {
        case TIME_INTERVAL.NONE:
          return;
        case TIME_INTERVAL.WEEK:
          return {
            start: toTimestamp(moment().subtract(7, 'd').toDate()),
            end: todayTimestamp,
          };
        case TIME_INTERVAL.MONTH:
          return {
            start: toTimestamp(moment().subtract(1, 'M').toDate()),
            end: todayTimestamp,
          };
        case TIME_INTERVAL.SIX_MONTHS:
          return {
            start: toTimestamp(moment().subtract(6, 'M').toDate()),
            end: todayTimestamp,
          };
        case TIME_INTERVAL.CUSTOM:
          return {
            start: toTimestamp(selectedDates.start),
            // we need to grab the end-of-day moment to include invoices created throughout the day
            end: toTimestamp(moment(selectedDates.end).add(1, 'd').toDate()),
          };
      }
    },
    [selectedDates, toTimestamp],
  );

  useEffect(() => {
    onSelectionChange(selectedResources);
    setTotalAmount(
      orders.reduce(
        (total, o) =>
          selectedResources.includes(`${o._id}`)
            ? o.type === 'credit'
              ? +total - +o.total
              : +total + +o.nonRefundedTotal
            : +total,
        0,
      ),
    );
  }, [selectedResources, onSelectionChange, setTotalAmount, orders, selectedUnpaidOrders]);

  const handlePageChange = useCallback(
    (page: number) => {
      changeUrlParams(0, {
        ...(!!filters.dateRange?.start &&
          !!filters.dateRange?.end && {
            start: moment(filters.dateRange.start).format('MM-DD-YYYY'),
            end: moment(filters.dateRange.end).format('MM-DD-YYYY'),
          }),
        ...(filters.retailerId && { retailerId: filters.retailerId }),
        ...(filters.paidViaCrowdship !== undefined && {
          paidViaCrowdship: filters.paidViaCrowdship,
        }),
        period: period[0],
        page,
        limit: ORDERS_PER_PAGE,
      });
      onPageChange(page);
      handleSelectionChange('page' as SelectionType, false);
    },
    [onPageChange, handleSelectionChange, changeUrlParams, filters, period],
  );

  const handleSelectedRetailersChange = useCallback(
    (value: string[]) => {
      changeUrlParams(0, {
        ...(!!filters.dateRange?.start &&
          !!filters.dateRange?.end && {
            start: moment(filters.dateRange.start).format('MM-DD-YYYY'),
            end: moment(filters.dateRange.end).format('MM-DD-YYYY'),
          }),
        ...(value[0] !== 'Crowdship' && { retailerId: value[0] }),
        paidViaCrowdship: value[0] === 'Crowdship',
        period: period[0],
        page: 0,
        limit: ORDERS_PER_PAGE,
      });
      setSelectedRetailer(value);
      setFilterParams(
        value[0] === 'Crowdship' ? undefined : value[0],
        value[0] === 'Crowdship',
        getChosenDateRange(period[0]),
      );
      setSelectable(value[0] !== 'Crowdship');

      handleSelectionChange('page' as SelectionType, false);
    },
    [
      setFilterParams,
      setSelectable,
      setSelectedRetailer,
      getChosenDateRange,
      period,
      handleSelectionChange,
      changeUrlParams,
      filters,
    ],
  );
  const handleSelectedRetailersRemove = useCallback(() => {
    changeUrlParams(0, {
      ...(!!filters.dateRange?.start &&
        !!filters.dateRange?.end && {
          start: moment(filters.dateRange.start).format('MM-DD-YYYY'),
          end: moment(filters.dateRange.end).format('MM-DD-YYYY'),
        }),
      period: period[0],
      page: 0,
      limit: ORDERS_PER_PAGE,
    });
    setSelectedRetailer([]);
    setFilterParams(undefined, undefined, getChosenDateRange(period[0]));
    setSelectable(false);
  }, [
    setFilterParams,
    setSelectable,
    setSelectedRetailer,
    getChosenDateRange,
    period,
    changeUrlParams,
    filters,
  ]);

  const handleFiltersClearAll = useCallback(() => {
    changeUrlParams(0, {
      page: 0,
      limit: ORDERS_PER_PAGE,
    });
    setSelectedRetailer([]);
    setSelectedDates({
      start: moment().subtract(7, 'd').toDate(),
      end: moment().toDate(),
    });
    setFilterParams();
    setSelectable(false);
  }, [setFilterParams, setSelectable, setSelectedRetailer, changeUrlParams]);

  const handleMonthChange = useCallback((month, year) => setDate({ month, year }), []);

  const appliedFilters: IAppliedFilter[] = useMemo(() => {
    const appliedRetailer = {
      key: 'selectedRetailers',
      label: `Retailer: ${retailers.find((r) => r.value === selectedRetailer[0])?.label || ''}`,
      onRemove: handleSelectedRetailersRemove,
    };

    if (selectedRetailer.length) {
      return [appliedRetailer];
    }
    return [];
  }, [handleSelectedRetailersRemove, retailers, selectedRetailer]);

  const handleCustomDateChange = useCallback(
    (date: { start: Date; end: Date }) => {
      changeUrlParams(0, {
        ...(selectedRetailer[0] !== 'Crowdship' && { retailerId: selectedRetailer[0] }),
        ...(selectedRetailer[0] && { paidViaCrowdship: selectedRetailer[0] === 'Crowdship' }),
        start: moment(date.start).format('MM-DD-YYYY'),
        end: moment(date.end).format('MM-DD-YYYY'),
        period: period[0],
        page: 0,
        limit: ORDERS_PER_PAGE,
      });
      setSelectedDates(date);

      setFilterParams(
        selectedRetailer[0] === 'Crowdship' ? undefined : selectedRetailer[0],
        selectedRetailer[0] !== undefined ? selectedRetailer[0] === 'Crowdship' : undefined,
        {
          start: toTimestamp(date.start),
          // we need to grab the end-of-day moment to include invoices created throughout the day
          end: toTimestamp(moment(date.end).add(1, 'd').toDate()),
        },
      );
    },
    [setFilterParams, toTimestamp, selectedRetailer, changeUrlParams, period],
  );

  return (
    <>
      <p className="filter-orders-instructions">
        The below orders are shipped but have not been invoiced yet. To group unpaid orders into a
        batch invoice, follow the steps below:
      </p>
      <List type="number">
        <List.Item>Select a Retailer (required)</List.Item>
        <List.Item>Filter orders by date range (optional)</List.Item>
        <List.Item>Select 1 or more orders to include in a batch invoice (optional)</List.Item>
        <List.Item>
          Click “Create Invoice” to issue an invoice for the selected orders. Use the popup to make
          any adjustments.
        </List.Item>
      </List>
      <AppFilters
        filters={[
          {
            key: 'selectedRetailers',
            label: 'Retailers',
            shortcut: true,
            filter: (
              <AppChoiceList
                title="Retailer list"
                titleHidden
                choices={retailerOptions}
                selected={selectedRetailer}
                onChange={handleSelectedRetailersChange}
              />
            ),
          },
          {
            key: 'period',
            label: 'Date',
            shortcut: true,
            hideClearButton: true,
            filter: (
              <AppChoiceList
                title="Time interval options list"
                titleHidden
                choices={[
                  { label: 'all', value: TIME_INTERVAL.NONE },
                  { label: 'last week', value: TIME_INTERVAL.WEEK },
                  { label: 'last month', value: TIME_INTERVAL.MONTH },
                  { label: 'last six months', value: TIME_INTERVAL.SIX_MONTHS },
                  { label: 'custom', value: TIME_INTERVAL.CUSTOM },
                ]}
                selected={period}
                onChange={(val: TIME_INTERVAL[]) => {
                  setPeriod(val);
                  changeUrlParams(0, {
                    ...filters,
                    ...(!!getChosenDateRange(val[0])?.start && {
                      start: moment(getChosenDateRange(val[0])?.start).format('MM-DD-YYYY'),
                    }),
                    ...(!!getChosenDateRange(val[0])?.end && {
                      end: moment(getChosenDateRange(val[0])?.end).format('MM-DD-YYYY'),
                    }),
                    period: (val[0] as string).replace(' ', '-') as TIME_INTERVAL,
                    page: 0,
                    limit: ORDERS_PER_PAGE,
                  });
                  setFilterParams(
                    selectedRetailer[0] === 'Crowdship' ? undefined : selectedRetailer[0],
                    selectedRetailer[0] !== undefined
                      ? selectedRetailer[0] === 'Crowdship'
                      : undefined,
                    getChosenDateRange(val[0]),
                  );
                }}
              />
            ),
          },
        ]}
        appliedFilters={appliedFilters}
        onQueryChange={() => {}}
        onQueryClear={() => {}}
        onClearAll={handleFiltersClearAll}
        hideQueryField
      />
      <AppCollapsible id={'datePicker'} open={period.includes(TIME_INTERVAL.CUSTOM)}>
        <DatePicker
          month={month}
          year={year}
          onChange={handleCustomDateChange}
          onMonthChange={handleMonthChange}
          selected={selectedDates}
          multiMonth
          allowRange
        />
      </AppCollapsible>
      <AppIndexTable
        selectable={true}
        resourceName={{ singular: 'order', plural: 'orders' }}
        headings={[
          { title: 'Order' },
          { title: 'Date Closed' },
          { title: 'Retailer' },
          { title: 'Bill To' },
          { title: 'My Total' },
          { title: 'Items Count' },
          { title: 'Status' },
        ]}
        itemCount={orders.length}
        hasMoreItems={totalPageCount > 1}
        onSelectionChange={handleSelectionChange}
        selectedItemsCount={allResourcesSelected ? 'All' : selectedResources.length}
        promotedBulkActions={listActions.map((la) => ({
          ...la,
          onAction: () => {
            la.onAction && la.onAction();
          },
        }))}
        loading={fetching}
      >
        <BaseOrderListRows
          orders={orders}
          billTo
          currency={currency}
          selectedResources={selectedResources}
        />
      </AppIndexTable>
      <PageAwarePagination totalPageCount={totalPageCount} onPageChange={handlePageChange} />
    </>
  );
};
