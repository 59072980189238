import React from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import { getStatusSelector } from '../../../redux/modules/status/status.selectors';
import { AppBanner } from '../../../../core/components/feedback-indicators/banner/banner';
import { AppLink } from '../../../../core/components/link/link';
import './account-not-connected-banner.scss';

export function AccountNotConnectedBanner() {
  const { connected } = useSelector(getStatusSelector);
  const location = useLocation();
  const isOnboardingPage = location.pathname.includes('onboarding');

  if (connected || isOnboardingPage) return null;
  return (
    <div className="banner">
      <AppBanner status="critical" title="Connect your account">
        <p>
          Your account is not connected to Crowdship. Retailers cannot see and sell your products
          until you connect to Crowdship on your <AppLink url={'/account'}>account page</AppLink>.
        </p>
      </AppBanner>
    </div>
  );
}
