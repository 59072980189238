import { Form, Formik } from 'formik';
import { useCallback, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { IAccount } from '../../interfaces/IAccount';
import {
  connectAccountAction,
  disconnectAccountAction,
} from '../../redux/modules/account/account.actions';
import { AccountNotSyncedBanner } from '../banners/account-not-synced-banner/account-not-synced-banner';
import { AppContextualSaveBar } from '../forms/contextual-save-button/contextual-save-button';
import { AppLink } from '../link/link';
import { AppModal } from '../overlays/modal/modal';
import { AppCard } from '../structure/card/card';
import { AppLayout } from '../structure/layout/layout';
import { AppLayoutAnnotatedSection } from '../structure/layout/layout-annotated-section';
import { AppAccountConnection } from './account-connection';
import { AccountInfoForm } from './account-info-form';
import './account-details.scss';

interface IAccountDetailsProps {
  accountInfo: IAccount;
  submitAccountInfo: (info: IAccount) => void;
  updating: boolean;
}

export function AccountDetails({ accountInfo, submitAccountInfo, updating }: IAccountDetailsProps) {
  const { connected, synced } = accountInfo;
  const dispatch = useDispatch();
  const [openDisconnectModal, setOpenDisconnectModal] = useState(false);
  const accountName = connected ? accountInfo.businessName : '';

  const handleAction = useCallback(() => {
    if (connected) setOpenDisconnectModal(true);
    else dispatch(connectAccountAction());
  }, [connected, dispatch]);

  const buttonText = connected ? 'Disconnect' : 'Connect';
  const details = connected ? 'Account connected' : 'No account connected';
  const terms = connected ? null : (
    <p>
      By clicking <strong>Connect</strong>, you agree to accept Crowdship's {''}
      <AppLink
        onClick={() => window.open('https://crowdship.io/terms-conditions/', '_blank')}
        external
      >
        terms and conditions
      </AppLink>
      .
    </p>
  );

  const modalMarkup = useMemo(
    () =>
      openDisconnectModal ? (
        <AppModal
          open={openDisconnectModal}
          onClose={() => setOpenDisconnectModal(false)}
          title="Disconnect Crowdship account"
          primaryAction={{
            content: 'Disconnect',
            destructive: true,
            onAction: () => {
              dispatch(disconnectAccountAction());
              setOpenDisconnectModal(false);
            },
          }}
          secondaryActions={[
            {
              content: 'Cancel',
              onAction: () => setOpenDisconnectModal(false),
            },
          ]}
        >
          Disconnecting your Crowdship account will remove your products from Crowdship. You can
          reconnect your account on any time to continue selling on Crowdship.
        </AppModal>
      ) : null,
    [openDisconnectModal, dispatch],
  );

  return (
    <Formik initialValues={accountInfo} onSubmit={submitAccountInfo} enableReinitialize>
      {({ submitForm, resetForm, dirty }) => (
        <Form name={'accountInfo'}>
          <div>
            {!synced && <AccountNotSyncedBanner />}
            <AppLayout>
              {dirty ? (
                <AppContextualSaveBar
                  message="Unsaved changes"
                  saveAction={{
                    onAction: submitForm,
                    loading: updating,
                  }}
                  discardAction={{
                    onAction: resetForm,
                    loading: updating,
                  }}
                />
              ) : null}
              <AppLayoutAnnotatedSection
                title="Crowdship account"
                description={
                  'Connect your Crowdship account so you can manage and sync with Crowdship'
                }
              >
                <AppAccountConnection
                  accountName={accountName}
                  connected={connected}
                  title="Crowdship account"
                  action={{
                    content: buttonText,
                    onAction: handleAction,
                  }}
                  details={details}
                  termsOfService={terms}
                />
              </AppLayoutAnnotatedSection>
              <AccountInfoForm />
              {connected && (
                <AppLayoutAnnotatedSection
                  title="Terms and conditions"
                  description={'The terms and conditions of Crowdship'}
                >
                  <AppCard sectioned>
                    <AppLink
                      onClick={() =>
                        window.open('https://crowdship.io/terms-conditions/', '_blank')
                      }
                      external
                    >
                      Crowdship account Terms and Conditions
                    </AppLink>
                  </AppCard>
                </AppLayoutAnnotatedSection>
              )}
            </AppLayout>
          </div>
          {modalMarkup}
        </Form>
      )}
    </Formik>
  );
}
