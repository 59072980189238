import React from 'react';
import { useHistory } from 'react-router';
import { AppButton } from '../../../../core/components/button/Button';
import { DateTooltip } from '../../../../core/components/date-tooltip/date-tooltip';
import { AppIndexTable } from '../../../../core/components/index-table/index-table';
import { AppIndexTableCell } from '../../../../core/components/index-table/index-table-cell';
import { AppIndexTableRow } from '../../../../core/components/index-table/index-table-row';

import { PageAwarePagination } from '../../../../core/components/pagination/page-aware-pagination';
import { AppTextStyle } from '../../../../core/components/text/text-style/TextStyle';
import { withCurrency } from '../../../../core/helpers/currency.helper';
import '../../../../core/styles/original-price.scss';
import { InvoiceItem } from '../../../interfaces/IInvoice';
import { OrderStatus } from '../order/common/order-status/order-status';

interface IInvoiceDetailsListProps {
  invoiceItems: InvoiceItem[];
  fetching: boolean;
  totalPageCount: number;
  onPageChange: (page: number) => void;
  selectable?: boolean;
  handleExportCSV: () => void;
}

export const InvoiceDetailsList = ({
  invoiceItems,
  fetching,
  totalPageCount,
  onPageChange,
  selectable = false,
  handleExportCSV,
}: IInvoiceDetailsListProps) => {
  const history = useHistory();

  return (
    <>
      <div className="invoice-details-layout__csv-button">
        <AppButton
          handleClick={(e) => {
            e.stopPropagation();
            handleExportCSV();
          }}
        >
          Export CSV
        </AppButton>
      </div>
      {
        <AppIndexTable
          resourceName={{ singular: 'order', plural: 'orders' }}
          headings={[
            { title: 'PO' },
            { title: 'Date' },
            { title: 'Supplier' },
            { title: 'My Total' },
            { title: 'Items Count' },
            { title: 'Status' },
          ]}
          itemCount={invoiceItems.length}
          loading={fetching}
          selectable={selectable}
        >
          {invoiceItems.map(
            (
              {
                purchaseOrderName,
                purchaseOrderId,
                name,
                itemsCount,
                createdAt,
                total,
                nonRefundedTotal,
                supplierShopName,
                status,
                type,
                _id: invoiceItemId,
              },
              index,
            ) => (
              <AppIndexTableRow
                id={invoiceItemId}
                key={invoiceItemId}
                position={index}
                selected={false}
                onNavigation={() =>
                  type === 'credit'
                    ? history.push(`/refunds/${invoiceItemId}`)
                    : history.push(`/purchase-order/${purchaseOrderId}`)
                }
              >
                <AppIndexTableCell>
                  <div data-primary-link>
                    <AppTextStyle variation="strong">
                      {type === 'credit' ? name : purchaseOrderName}
                    </AppTextStyle>
                  </div>
                </AppIndexTableCell>
                <AppIndexTableCell>
                  <DateTooltip date={createdAt} />
                </AppIndexTableCell>
                <AppIndexTableCell>{supplierShopName}</AppIndexTableCell>
                <AppIndexTableCell>
                  {type === 'credit' ? (
                    `-${withCurrency(total)}`
                  ) : +nonRefundedTotal !== +total ? (
                    <>
                      <span className="original-price">{withCurrency(total)}</span>
                      <span>{withCurrency(nonRefundedTotal)}</span>
                    </>
                  ) : (
                    withCurrency(total)
                  )}
                </AppIndexTableCell>
                <AppIndexTableCell>{itemsCount}</AppIndexTableCell>
                <AppIndexTableCell>
                  <OrderStatus status={status} />
                </AppIndexTableCell>
              </AppIndexTableRow>
            ),
          )}
        </AppIndexTable>
      }
      <PageAwarePagination totalPageCount={totalPageCount} onPageChange={onPageChange} />
    </>
  );
};
