import { AxiosResponse } from 'axios';
import { IShopViewDetails } from '../interfaces/IAccount';
import { axiosInstance } from './base.api';

class AdminApi {
  private backend = process.env.REACT_APP_BACK_END_URL as string;
  private rootPath = '/api/account/admin';

  async getViewAsClientSetting(): Promise<AxiosResponse<GetViewAsClientReturnResult>> {
    return await axiosInstance.get<GetViewAsClientReturnResult>(
      `${this.backend}${this.rootPath}/view-as-client`,
    );
  }

  async updateViewAsClientSetting(
    clientId: string,
  ): Promise<AxiosResponse<UpdateViewAsClientReturnResult>> {
    return await axiosInstance.put<UpdateViewAsClientReturnResult>(
      `${this.backend}${this.rootPath}/view-as-client`,
      { clientId },
    );
  }
}

export const adminApi = new AdminApi();

export type GetViewAsClientReturnResult = { viewAsClient?: IShopViewDetails };
export type UpdateViewAsClientReturnResult = GetViewAsClientReturnResult | { notFound: true };
