import { AppDataTable } from '../../../../../core/components/data-table/DataTable';
import { AppControlledCheckbox } from '../../../../../core/components/forms/controlled-checkbox/controlled-checkbox';
import { AppControlledChoiceList } from '../../../../../core/components/forms/controlled-choice-list/controlled-choice-list';
import { AppPopoverWithSection } from '../../../../../core/components/overlays/popover/popover-with-section';
import { AppCard } from '../../../../../core/components/structure/card/card';
import { AppLayoutAnnotatedSection } from '../../../../../core/components/structure/layout/layout-annotated-section';
import { notificationFrequencyOptions } from '../../../../../core/constants/notification-frequency.constants';
import { notificationEventsConstants } from '../../../../constants/preferences.constants';
import { IPreferencesSectionProps } from './IPreferencesSectionProps';

export const NotificationsSection = ({ values }: IPreferencesSectionProps) => (
  <AppLayoutAnnotatedSection title="Notifications">
    <AppCard sectioned title="Get notified when following events occur">
      <AppDataTable
        headings={['Event', 'E-Mail']}
        columnContentTypes={['text', 'text']}
        rows={[
          ...notificationEventsConstants.map((event) => [
            <div className="title">{event.title}</div>,
            <div className="email-select">
              <AppPopoverWithSection
                fixed
                title={values.notifications[event.key].email.length ? 'Notify' : "Don't notify"}
              >
                <AppControlledChoiceList
                  name={`notifications.${event.key}.email`}
                  title="Frequency options"
                  titleHidden
                  choices={notificationFrequencyOptions}
                  allowMultiple
                />
              </AppPopoverWithSection>
            </div>,
          ]),
          ...[
            {
              key: 'orderUnfulfilled',
              title: 'When an order is unfulfilled for more than 48 hours',
            },
            // {
            //   key: 'productPriceUpdateError',
            //   title: 'When my SRP or dropship cost are no longer auto-calculated',
            // },
          ].map((event) => [
            <div className="title">{event.title}</div>,
            <div className="email-select">
              <AppPopoverWithSection
                fixed
                title={values.notifications[event.key] ? 'Notify' : "Don't notify"}
              >
                <AppControlledCheckbox name={`notifications.${event.key}`} label="Daily" />
              </AppPopoverWithSection>
            </div>,
          ]),
        ]}
      />
    </AppCard>
  </AppLayoutAnnotatedSection>
);
