import { createReducer } from 'typesafe-actions';
import { IPlan } from '../../../interfaces/IPlan';
import { RootActions } from '../../root.actions';
import { getPlansAction, setPlansAction } from './plans.actions';

export interface IPlansReducer {
  plans: IPlan[];

  fetching: boolean;
}

const initialState: IPlansReducer = {
  plans: [],
  fetching: false, // show skeleton page
};

export const plansReducer = createReducer<IPlansReducer, RootActions>(initialState)
  .handleAction(getPlansAction, (state) => ({ ...state, fetching: true }))
  .handleAction(setPlansAction, (state, { payload }) => ({
    ...state,
    fetching: false,
    plans: payload,
  }));
