import { ReactNode } from 'react';
import { AppCollapsible } from '../../../collapsible/Collapsible';

type VariantsCollapsibleRowProps = {
  id: string;
  expanded: boolean;
  renderVariantList: () => ReactNode;
  colSpan?: number;
};

/** Creating a new row so the variants can be displayed under the product */
export const VariantsCollapsibleRow = ({
  id,
  expanded,
  colSpan = 8,
  renderVariantList,
}: VariantsCollapsibleRowProps) => (
  <tr id={`${id}-variants`} key={`${id}-variants`}>
    <td colSpan={colSpan}>
      <AppCollapsible id={`${id}-variants`} open={expanded}>
        <div className="product-variants">{renderVariantList()}</div>
      </AppCollapsible>
    </td>
  </tr>
);
