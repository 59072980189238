import { POSTAGE_SETUP } from '../../shopify-supplier/constants/preferences.constants';
import { ILocation } from '../../shopify-supplier/interfaces/ILocation';
import { IShippingPreferences } from '../../shopify-supplier/interfaces/IPreferences';
import { PRODUCT_CATEGORY } from '../constants/product.constants';

export enum PRODUCT_GROUP_CONNECTION_TYPE {
  // The whole supplier product group was added
  ADDED_GROUP = 'added group',
  // Product group was created from selected supplier's products
  LINKED_PRODUCTS = 'linked products',
  // Product group was added to retailer's import list
  ADDED_TO_IMPORT_LIST = 'added to import list',
  // Product has no current connections
  NOT_CONNECTED = 'not connected',
}

export interface IProduct extends Record<string, unknown> {
  id: string;
  title: string;
  vendor: string;
  productType: string;
  image?: string;
  variationsNumber: number;
  quantityAvailable: number;
  averageMargin: number | null;
  price: number;
  MSRP: number;
  isPriceForRetailersVaries: boolean;
  freeShipping: boolean;
  shipping: IShippingPreferences;
  location: ILocation;
  shopifyStatus: string;
  variants: {
    weight: number;
    grams: number;
    weightUnit: 'g' | 'kg' | 'oz' | 'lb';
    tracked: boolean;
    inventoryPolicy: 'deny' | 'continue';
    qty: number;
    manuallySetMSRP: boolean;
    manuallySetPriceForRetailers: boolean;
  }[];
  productCategory: PRODUCT_CATEGORY;
  postageSetup: POSTAGE_SETUP;
}

export interface IRetailerVariantMatch {
  product: string;
  retailer: string;
  shopify: {
    id: number;
    fulfillmentService: string;
  };
  quantity: number;
  vendor: string;
  title: string;
  sku: string;
  price: string;
  image?: string;
  productGroupTitle: string;
  productGroup: string;
  productType: string;
  archived: boolean;
  connected: boolean;
  status: string;
}

export interface ISupplierVariantMatch {
  product: string;
  vendor: string;
  title: string;
  sku: string;
  image?: string;
  productGroupTitle: string;
  priceForRetailers: number;
  supplier: string;
  MSRP: number;
  qty: number;
  tracked: boolean;
  inventoryPolicy: 'deny' | 'continue';
}

export interface IVariantMatch extends Record<string, unknown> {
  id: string;
  retailerProduct: IRetailerVariantMatch;
  supplierProducts: ISupplierVariantMatch[];
}

export interface IProductList<T extends IProduct> {
  productGroups: T[];
  total: number;
}

export interface IInventoryProduct extends IProduct {
  shopifyId?: number;
  processing: boolean;
  connected: boolean;
  connectionType: PRODUCT_GROUP_CONNECTION_TYPE;
  products: {
    cost: number;
    inventoryPolicy?: 'deny' | 'continue';
    price: number;
    priceForRetailers: number;
    qty: number;
    status: string;
    tracked?: boolean;
  }[];
}

export interface IProductMatchesList<T extends IVariantMatch> {
  matches: T[];
  total: number;
}

export interface IMarketplaceProduct extends IProduct {
  supplierName: string;
  connectedProducts: number;
}

export interface ISupplierProduct extends IProduct {
  platformId: number;
  customPriceForRetailerCount: number;
  customMSRPCount: number;
  availableForRetailers: boolean;
  retailerGroups: string[];
  shopifyStatus: string;
}

export interface IVariantMapping<T> {
  [productId: string]: T[];
}

export enum AVAILABILITY {
  MARKETPLACE = 'Marketplace',
  UNAVAILABLE = 'Unavailable',
}
