import { DatePicker, EmptySearchResult } from '@shopify/polaris';
import moment from 'moment';
import React, { useCallback, useMemo, useState } from 'react';
import { AppChoiceList } from '../../../../core/components/choice-list/ChoiceList';
import { AppCollapsible } from '../../../../core/components/collapsible/Collapsible';
import { DateTooltip } from '../../../../core/components/date-tooltip/date-tooltip';
import { AppFilters, IAppliedFilter } from '../../../../core/components/filters/filters';
import { AppIndexTable } from '../../../../core/components/index-table/index-table';
import { AppIndexTableCell } from '../../../../core/components/index-table/index-table-cell';
import { AppIndexTableRow } from '../../../../core/components/index-table/index-table-row';
import { AppLink } from '../../../../core/components/link/link';
import { PageAwarePagination } from '../../../../core/components/pagination/page-aware-pagination';
import { ORDERS_PER_PAGE } from '../../../../core/constants/orders';
import { TIME_INTERVAL } from '../../../../core/constants/product.constants';
import { withCurrency } from '../../../../core/helpers/currency.helper';
import { ICurrency } from '../../../../core/interfaces/ICurrency';
import { IGetUnpaidOrdersQueryParams } from '../../../../shopify-supplier/api/orders.api';
import { IGetUnpaidPayoutsFilters, PayoutDisplayInfo } from '../../../api/invoice.api';

interface IFilteredOrderListProps {
  payouts: PayoutDisplayInfo[];
  fetching: boolean;
  totalPageCount: number;
  onPageChange: (page: number) => void;
  currency?: ICurrency;
  setFilterParams: (filters: IGetUnpaidPayoutsFilters) => void;
  filterParams: IGetUnpaidOrdersQueryParams;
  suppliers: {
    label: string;
    value: string;
  }[];
  selectedSupplier: string[];
  setSelectedSupplier: (values: string[]) => void;
  handleExportCSV: (id: string) => void;
}

export const UnpaidBatchesTable = ({
  payouts,
  fetching,
  totalPageCount,
  onPageChange,
  currency = { isoCode: 'USD', symbol: '$' },
  setFilterParams,
  filterParams,
  suppliers,
  selectedSupplier,
  setSelectedSupplier,
  handleExportCSV,
}: IFilteredOrderListProps) => {
  const [period, setPeriod] = useState<TIME_INTERVAL[]>([TIME_INTERVAL.NONE]);
  const [{ month, year }, setDate] = useState({ month: moment().month(), year: moment().year() });
  const [selectedDates, setSelectedDates] = useState({
    start: moment().subtract(7, 'd').toDate(),
    end: moment().toDate(),
  });

  const supplierOptions = useMemo(
    () => suppliers.map((r) => ({ label: r.label, value: r.value })),
    [suppliers],
  );

  const handleSelectedSuppliersChange = useCallback(
    (value: string[]) => {
      setSelectedSupplier(value);
      setFilterParams({
        ...filterParams,
        page: 0,
        supplierId: value[0],
      });
    },
    [filterParams, setFilterParams, setSelectedSupplier],
  );

  const handleSelectedSuppliersRemove = useCallback(() => {
    setSelectedSupplier([]);
    setFilterParams({
      page: 0,
      limit: ORDERS_PER_PAGE,
      dateRange: filterParams.dateRange,
    });
  }, [filterParams.dateRange, setFilterParams, setSelectedSupplier]);

  const handleFiltersClearAll = useCallback(() => {
    setSelectedSupplier([]);
    setSelectedDates({
      start: moment().subtract(7, 'd').toDate(),
      end: moment().toDate(),
    });
    setFilterParams({
      page: 0,
      limit: ORDERS_PER_PAGE,
    });
  }, [setFilterParams, setSelectedSupplier]);

  const handleMonthChange = useCallback((month, year) => setDate({ month, year }), []);

  const toTimestamp = useCallback((date: Date) => Math.floor(date.getTime()), []);

  const getChosenDateRange = useCallback(
    (interval: TIME_INTERVAL): { start: number; end: number } | undefined => {
      const todayTimestamp = toTimestamp(moment().toDate());
      switch (interval) {
        case TIME_INTERVAL.NONE:
          return;
        case TIME_INTERVAL.WEEK:
          return {
            start: toTimestamp(moment().subtract(7, 'd').toDate()),
            end: todayTimestamp,
          };
        case TIME_INTERVAL.MONTH:
          return {
            start: toTimestamp(moment().subtract(1, 'M').toDate()),
            end: todayTimestamp,
          };
        case TIME_INTERVAL.SIX_MONTHS:
          return {
            start: toTimestamp(moment().subtract(6, 'M').toDate()),
            end: todayTimestamp,
          };
        case TIME_INTERVAL.CUSTOM:
          return {
            start: toTimestamp(selectedDates.start),
            // we need to grab the end-of-day moment to include invoices created throughout the day
            end: toTimestamp(moment(selectedDates.end).add(1, 'd').toDate()),
          };
      }
    },
    [selectedDates, toTimestamp],
  );

  const appliedFilters: IAppliedFilter[] = useMemo(() => {
    const appliedSupplier = {
      key: 'selectedSuppliers',
      label: `Supplier: ${suppliers.find((r) => r.value === selectedSupplier[0])?.label || ''}`,
      onRemove: handleSelectedSuppliersRemove,
    };

    if (selectedSupplier.length) {
      return [appliedSupplier];
    }
    return [];
  }, [handleSelectedSuppliersRemove, suppliers, selectedSupplier]);

  const handleCustomDateChange = useCallback(
    (date: { start: Date; end: Date }) => {
      setSelectedDates(date);

      setFilterParams({
        ...filterParams,
        dateRange: {
          start: toTimestamp(date.start),
          // we need to grab the end-of-day moment to include invoices created throughout the day
          end: toTimestamp(moment(date.end).add(1, 'd').toDate()),
        },
      });
    },
    [filterParams, setFilterParams, toTimestamp],
  );

  const batchesRows = useMemo(
    () =>
      payouts.map(
        (
          {
            _id,
            createdAt,
            supplierName,
            ordersCount,
            lineTotal,
            shippingTotal,
            handlingFeeTotal,
            adjustmentAmount,
            amount,
            name,
          },
          index,
        ) => (
          <AppIndexTableRow id={_id} key={_id} position={index} selected={false}>
            <AppIndexTableCell>{name}</AppIndexTableCell>
            <AppIndexTableCell>
              <DateTooltip date={createdAt} />
            </AppIndexTableCell>
            <AppIndexTableCell>{supplierName}</AppIndexTableCell>
            <AppIndexTableCell>{ordersCount}</AppIndexTableCell>
            <AppIndexTableCell>{withCurrency(lineTotal, currency)}</AppIndexTableCell>
            <AppIndexTableCell>{withCurrency(shippingTotal, currency)}</AppIndexTableCell>
            <AppIndexTableCell>{withCurrency(handlingFeeTotal, currency)}</AppIndexTableCell>
            <AppIndexTableCell>{withCurrency(adjustmentAmount || 0, currency)}</AppIndexTableCell>
            <AppIndexTableCell>{withCurrency(amount, currency)}</AppIndexTableCell>
            <AppIndexTableCell>
              <AppLink url={`/accounting/${_id}`}>View details</AppLink>
            </AppIndexTableCell>
          </AppIndexTableRow>
        ),
      ),
    [currency, payouts],
  );

  return (
    <>
      <AppFilters
        filters={[
          {
            key: 'selectedSuppliers',
            label: 'Suppliers',
            shortcut: true,
            filter: (
              <AppChoiceList
                title="Supplier list"
                titleHidden
                choices={supplierOptions}
                selected={selectedSupplier}
                onChange={handleSelectedSuppliersChange}
              />
            ),
          },
          {
            key: 'period',
            label: 'Date',
            shortcut: true,
            hideClearButton: true,
            filter: (
              <AppChoiceList
                title="Time interval options list"
                titleHidden
                choices={[
                  { label: 'all', value: TIME_INTERVAL.NONE },
                  { label: 'last week', value: TIME_INTERVAL.WEEK },
                  { label: 'last month', value: TIME_INTERVAL.MONTH },
                  { label: 'last six months', value: TIME_INTERVAL.SIX_MONTHS },
                  { label: 'custom', value: TIME_INTERVAL.CUSTOM },
                ]}
                selected={period}
                onChange={(val: TIME_INTERVAL[]) => {
                  setPeriod(val);
                  setFilterParams({ ...filterParams, dateRange: getChosenDateRange(val[0]) });
                }}
              />
            ),
          },
        ]}
        appliedFilters={appliedFilters}
        onQueryChange={() => {}}
        onQueryClear={() => {}}
        onClearAll={handleFiltersClearAll}
        hideQueryField
      />
      <AppCollapsible id={'datePicker'} open={period.includes(TIME_INTERVAL.CUSTOM)}>
        <DatePicker
          month={month}
          year={year}
          onChange={handleCustomDateChange}
          onMonthChange={handleMonthChange}
          selected={selectedDates}
          multiMonth
          allowRange
        />
      </AppCollapsible>
      <AppIndexTable
        selectable={false}
        headings={[
          { title: 'ID' },
          { title: 'Date' },
          { title: 'Supplier' },
          { title: 'Orders' },
          { title: 'Line Total' },
          { title: 'Shipping Total' },
          { title: 'Handling Fee Total' },
          { title: 'Adjustment' },
          { title: 'Amount Due' },
          { title: 'Payout details', hidden: true },
        ]}
        itemCount={payouts.length}
        loading={fetching}
        emptyState={
          <EmptySearchResult
            title={'No records found'}
            description={"If you're using search or a filter, try changing it"}
            withIllustration
          />
        }
      >
        {batchesRows}
      </AppIndexTable>
      <PageAwarePagination totalPageCount={totalPageCount} onPageChange={onPageChange} />
    </>
  );
};
