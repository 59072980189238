import { createReducer } from 'typesafe-actions';
import { RootActions } from '../../root.actions';
import {
  IDashboardOrdersSummary,
  IDashboardProductsSummary,
  IDashboardSalesSummary,
} from '../../../interfaces/IDashboard';
import {
  getDashboardOrdersSummaryAction,
  getDashboardProductsSummaryAction,
  getDashboardSalesSummaryAction,
  setDashboardOrdersSummaryAction,
  setDashboardProductsSummaryAction,
  setDashboardSalesSummaryAction,
} from './dashboard.actions';

export interface IDashboardReducer {
  ordersSummary: IDashboardOrdersSummary;
  productsSummary: IDashboardProductsSummary;
  salesSummary: IDashboardSalesSummary;
}

const initialState: IDashboardReducer = {
  ordersSummary: {
    totalOrdersCount: 0,
    unfulfilledOrdersCount: 0,
  },
  productsSummary: {
    dropshipProductsCount: 0,
    dropshipSKUsCount: 0,
  },
  salesSummary: {
    totalSales: 0,
    dropshipCost: 0,
    dropshipProfit: 0,
    dropshipMargin: 0,
  },
};

export const dashboardReducer = createReducer<IDashboardReducer, RootActions>(initialState)
  .handleAction(getDashboardOrdersSummaryAction, (state) => ({ ...state }))
  .handleAction(setDashboardOrdersSummaryAction, (state, { payload }) => ({
    ...state,
    ordersSummary: payload,
  }))
  .handleAction(getDashboardProductsSummaryAction, (state) => ({ ...state }))
  .handleAction(setDashboardProductsSummaryAction, (state, { payload }) => ({
    ...state,
    productsSummary: payload,
  }))
  .handleAction(getDashboardSalesSummaryAction, (state) => ({ ...state }))
  .handleAction(setDashboardSalesSummaryAction, (state, { payload }) => ({
    ...state,
    salesSummary: payload,
  }));
