import { accountReducer } from './modules/account/account.reducer';
import { authReducer } from './modules/auth/auth.reducer';
import { adminReducer } from './modules/admin/admin.reducer';
import { breadcrumbsReducer } from './modules/breadcrumbs/breadcrumbs.reducer';

export const coreReducers = {
  auth: authReducer,
  breadcrumbs: breadcrumbsReducer,
  account: accountReducer,
  admin: adminReducer,
};
