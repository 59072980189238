import { combineReducers } from 'redux';
import { StateType } from 'typesafe-actions';
import { accountReducer } from '../../core/redux/modules/account/account.reducer';
import { adminReducer } from '../../core/redux/modules/admin/admin.reducer';
import { dashboardReducer } from '../../shopify-retailer/redux/modules/dashboard/dashboard.reducer';
import { orderReducer } from '../../shopify-retailer/redux/modules/orders/orders.reducer';
import { plansReducer } from '../../shopify-retailer/redux/modules/plans/plans.reducer';
import { preferencesReducer } from '../../shopify-retailer/redux/modules/preferences/preferences.reducer';
import { importListReducer } from '../../shopify-retailer/redux/modules/import-list/import-list.reducer';
import { inventoryReducer } from '../../shopify-retailer/redux/modules/inventory/inventory.reducer';
import { marketplaceReducer } from '../../shopify-retailer/redux/modules/marketplace/marketplace.reducer';
import { statusReducer } from '../../shopify-retailer/redux/modules/status/status.reducer';
import { productSyncReducer } from '../../shopify-retailer/redux/modules/sync/sync.reducer';
import { authReducer } from './modules/auth/auth.reducer';
import { variantSyncReducer } from '../../shopify-retailer/redux/modules/variant-sync/variant-sync.reducer';

export const reducers = combineReducers({
  auth: authReducer,

  variantSync: variantSyncReducer,
  importList: importListReducer,
  marketplace: marketplaceReducer,
  inventory: inventoryReducer,
  orders: orderReducer,
  dashboard: dashboardReducer,
  preferences: preferencesReducer,
  productSync: productSyncReducer,
  limits: statusReducer,
  plans: plansReducer,
  account: accountReducer,

  admin: adminReducer,
});

export type RootState = StateType<typeof reducers>;
