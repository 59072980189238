import { ReactNode, useCallback, useState } from 'react';
import { AppPopover } from './popover';
import { AppButton } from '../../button/Button';
import { Popover } from '@shopify/polaris';

interface IPopoverWithSectionProps {
  title: string;
  children: ReactNode;
  preferredAlignment?: 'left' | 'right' | 'center';
  fixed?: boolean;
}

export function AppPopoverWithSection({
  children,
  title,
  preferredAlignment,
  fixed,
}: IPopoverWithSectionProps) {
  const [active, setActive] = useState(false);
  const toggleActive = useCallback(() => setActive((active) => !active), []);
  const activator = (
    <AppButton onClick={toggleActive} disclosure>
      {title}
    </AppButton>
  );
  return (
    <AppPopover
      active={active}
      activator={activator}
      onClose={toggleActive}
      fixed={fixed}
      preferredAlignment={preferredAlignment}
    >
      <Popover.Pane>
        <Popover.Section>{children}</Popover.Section>
      </Popover.Pane>
    </AppPopover>
  );
}
