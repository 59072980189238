import { all, takeLatest, put } from 'redux-saga/effects';
import { ActionType } from 'typesafe-actions';
import { statusApi } from '../../../api/status.api';
import { disablePromotionBannerAction, getStatusAction, setStatusAction } from './status.actions';

function* getStatusSaga(action: ActionType<typeof getStatusAction>) {
  try {
    const res = yield statusApi.getPlanLimitsStatus();
    yield put(setStatusAction(res.data));
  } catch (e) {
    console.error(e);
  }
}

function* disablePromotionBannerSaga(action: ActionType<typeof disablePromotionBannerAction>) {
  try {
    yield statusApi.disablePromotionBanner();
  } catch (e) {
    console.error(e);
  }
}

export function* statusSaga() {
  yield all([
    takeLatest(getStatusAction, getStatusSaga),
    takeLatest(disablePromotionBannerAction, disablePromotionBannerSaga),
  ]);
}
