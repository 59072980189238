import { EmptySearchResult } from '@shopify/polaris';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { FetchingBanner } from '../../../../core/components/banners/fetching-banner/fetching-banner';
import { CardWithDatePicker } from '../../../../core/components/card-with-date-picker/card-with-date-picker';
import { PageAwarePagination } from '../../../../core/components/pagination/page-aware-pagination';
import { INVOICES_PER_PAGE } from '../../../../core/constants/invoices.constants';
import { invoiceApi } from '../../../api/invoice.api';
import { IFeeInvoice } from '../../../interfaces/IInvoice';
import { FeeInvoiceList } from '../../containers/invoice-list/fee-invoice-list';

export function BillingHistoryLayout() {
  const [dateRange, setDateRange] = useState<{ start: string; end: string } | undefined>();
  const [invoices, setInvoices] = useState<IFeeInvoice[]>([]);
  const [fetching, setFetching] = useState(false);
  const [total, setTotal] = useState(0);

  const totalPageCount = useMemo(() => Math.ceil(total / INVOICES_PER_PAGE), [total]);

  const getInvoices = useCallback((page: number, range?: { start: string; end: string }) => {
    setFetching(true);
    invoiceApi
      .getPaidFeeInvoices({ limit: INVOICES_PER_PAGE, page, dateRange: range })
      .then(({ data }) => {
        setInvoices(data.invoices);
        setTotal(data.total);
      })
      .catch(console.error)
      .finally(() => {
        setFetching(false);
      });
  }, []);

  useEffect(() => {
    getInvoices(0);
  }, [getInvoices]);

  return (
    <CardWithDatePicker
      title="Invoice list"
      onChange={(selected) => {
        setDateRange(selected);
        getInvoices(0, selected);
      }}
      hideFilter={!invoices.length}
    >
      <div className="wrapper">
        {fetching && <FetchingBanner bodyText="Loading invoices" />}
        {!!invoices.length && <FeeInvoiceList invoices={invoices} />}
        {!invoices.length && (
          <EmptySearchResult
            title={'No records found'}
            description={"If you're using search or a filter, try changing it"}
            withIllustration
          />
        )}
      </div>
      <PageAwarePagination
        totalPageCount={totalPageCount}
        onPageChange={(page) => getInvoices(page, dateRange)}
      />
    </CardWithDatePicker>
  );
}
