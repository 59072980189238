import { EmptySearchResult } from '@shopify/polaris';
import React, { useCallback, useMemo, useState } from 'react';
import { useDebouncedCallback } from 'use-debounce/lib';
import { AppChoiceList } from '../../../../core/components/choice-list/ChoiceList';
import { AppFilters, IAppliedFilter } from '../../../../core/components/filters/filters';
import { LoadingWrapper } from '../../../../core/components/loading-wrapper/loading-wrapper';
import { PageAwarePagination } from '../../../../core/components/pagination/page-aware-pagination';
import { AppCard } from '../../../../core/components/structure/card/card';
import { AppTextStyle } from '../../../../core/components/text/text-style/TextStyle';
import { PRODUCT_CATEGORY, PRODUCT_VIEW } from '../../../../core/constants/product.constants';
import { ISuppliersDetails } from '../../../api/marketplace.api';
import { SupplierCard } from '../../layouts/marketplace-suppliers-layout/supplier-card/supplier-card';

import './supplier-preview-list.scss';

type ISupplierPreviewListProp = {
  suppliers: ISuppliersDetails[];
  total: number;
  totalPages: number;
  fetching: boolean;
  handlePageChange: (page: number) => void;
  handleFiltersChange: (title: string, productCategory: PRODUCT_CATEGORY) => void;
  handleProductViewChange: (view: PRODUCT_VIEW) => void;
};

export function SupplierPreviewList({
  suppliers,
  totalPages,
  fetching,
  total,
  handlePageChange,
  handleFiltersChange,
  handleProductViewChange,
}: ISupplierPreviewListProp) {
  const [selectedCategory, setSelectedCategory] = useState<PRODUCT_CATEGORY>(PRODUCT_CATEGORY.NONE);
  const [queryValue, setQueryValue] = useState<string>('');

  const categoryOptions = useMemo(
    () =>
      Object.values(PRODUCT_CATEGORY)
        .filter((c) => c !== 'NONE')
        .map((c) => ({
          label: c,
          value: c,
        })),
    [],
  );

  const debouncedOnFilterChange = useDebouncedCallback(() => {
    handleFiltersChange(queryValue, selectedCategory);
  }, 500);

  const handleSelectedCategoryChange = useCallback(
    (value: PRODUCT_CATEGORY[]) => {
      setSelectedCategory(value[0]);
      handleFiltersChange(queryValue, value[0]);
    },
    [queryValue, handleFiltersChange],
  );

  const handleSelectedCategoryRemove = useCallback(() => {
    setSelectedCategory(PRODUCT_CATEGORY.NONE);
    handleFiltersChange(queryValue, PRODUCT_CATEGORY.NONE);
  }, [queryValue, handleFiltersChange]);

  const handleFiltersQueryChange = (value: string) => {
    setQueryValue(value);
    debouncedOnFilterChange();
  };

  const handleQueryValueRemove = useCallback(
    (bulk?: boolean) => {
      setQueryValue('');
      if (!bulk) handleFiltersChange('', selectedCategory);
    },
    [selectedCategory, handleFiltersChange],
  );

  const handleFiltersClearAll = useCallback(() => {
    handleQueryValueRemove(true);
    setSelectedCategory(PRODUCT_CATEGORY.NONE);
    handleFiltersChange('', PRODUCT_CATEGORY.NONE);
  }, [handleQueryValueRemove, handleFiltersChange]);

  const appliedFilters: IAppliedFilter[] = useMemo(() => {
    const appliedCategory = {
      key: 'selectedCategory',
      label: `Category: ${selectedCategory}`,
      onRemove: handleSelectedCategoryRemove,
    };
    const choosenCategory = selectedCategory !== PRODUCT_CATEGORY.NONE ? [appliedCategory] : [];

    return choosenCategory;
  }, [handleSelectedCategoryRemove, selectedCategory]);

  return (
    <div className="suppliers-view">
      <AppCard title="Suppliers">
        <AppFilters
          filters={[
            {
              key: 'selectedCategory',
              label: 'Category',
              shortcut: true,
              filter: (
                <AppChoiceList
                  title="Category list"
                  titleHidden
                  choices={categoryOptions}
                  selected={[selectedCategory]}
                  onChange={handleSelectedCategoryChange}
                />
              ),
            },
          ]}
          appliedFilters={appliedFilters}
          onQueryChange={handleFiltersQueryChange}
          onQueryClear={() => handleQueryValueRemove()}
          onClearAll={handleFiltersClearAll}
          queryValue={queryValue}
        />
      </AppCard>
      <LoadingWrapper loading={fetching} className="suppliers-view__spinner">
        {suppliers.length !== 0 ? (
          <>
            <AppTextStyle variation="subdued">
              Showing 1 - {suppliers.length} of {total} results
            </AppTextStyle>
            <div className="suppliers-list">
              {suppliers.map((supplier) => (
                <SupplierCard {...supplier} handleProductViewChange={handleProductViewChange} />
              ))}
            </div>
          </>
        ) : (
          <EmptySearchResult
            title={'No suppliers found'}
            description={"If you're using search or a filter, try changing it"}
            withIllustration
          />
        )}
      </LoadingWrapper>
      <PageAwarePagination totalPageCount={totalPages} onPageChange={handlePageChange} />
    </div>
  );
}
