import { SpinnerProps } from '@shopify/polaris';
import React, { PropsWithChildren, useMemo } from 'react';
import { AppSpinner } from '../feedback-indicators/spinner/spinner';

interface LoadingWrapperProps {
  loading: boolean;
  className?: string;
  loaderComponent?: React.ReactNode;
}

export const LoadingWrapper = ({
  loading,
  children,
  className,
  loaderComponent,
  ...rest
}: PropsWithChildren<LoadingWrapperProps & SpinnerProps>) => {
  const loader = useMemo(
    () =>
      loaderComponent || (
        <div className={className}>
          <AppSpinner {...rest} />
        </div>
      ),
    [loaderComponent, className, rest],
  );

  if (loading) return <>{loader}</>;

  return <>{children}</>;
};
