import React, { ReactNode, useMemo, useState } from 'react';
import { AppPage } from '../../../../core/components/structure/page/page';
import { AppTabs } from '../../../../core/components/tabs/tabs';
import { PaymentHistoryLayout } from '../payment-history/payment-history-layout';
import { UnpaidBatchesListLayout } from '../unpaid-batches/unpaid-batches';
import { PendingTransactionFeeLayout } from '../pending-transaction-fee-layout/pending-transaction-fee-layout';
import { BillingHistoryLayout } from '../billing-history-layout/billing-history-layout';
import './invoices-layout.scss';
import { useHistory, useLocation } from 'react-router';
import { toLower } from 'lodash';

interface IInvoicesLayout {}

const tabs = [
  { id: 'unpaid-batches', content: 'Open Invoices' },
  { id: 'invoices-view', content: 'Payment History' },
  { id: 'unpaid-fee', content: 'Pending Transaction Fees' },
  { id: 'billing-history', content: 'Billing History' },
];

const findTabIndexByQuery = (tab?: string): number => {
  if (!tab) return 0;
  const index = tabs.findIndex((t) => toLower(t.content).replace(/ /g, '-') === tab);
  if (index === -1) return 0;
  return index;
};

export const InvoicesLayout: React.FunctionComponent<IInvoicesLayout> = () => {
  const { search } = useLocation();
  const history = useHistory();
  const parsedQuery = new URLSearchParams(search);
  const tab = parsedQuery.get('tab') as string;

  const [selectedTab, setSelectedTab] = useState<number>(findTabIndexByQuery(tab));

  const tabContent: { [key: number]: ReactNode } = useMemo(
    () => ({
      0: <UnpaidBatchesListLayout />,
      1: <PaymentHistoryLayout />,
      2: <PendingTransactionFeeLayout />,
      3: <BillingHistoryLayout />,
    }),
    [],
  );

  return (
    <React.Fragment>
      <div className="accounting-wrapper">
        <AppPage title="Accounting">
          <AppTabs
            tabs={tabs}
            selected={selectedTab}
            onSelect={(i) => {
              setSelectedTab(i);
              history.push({ search: `?tab=${toLower(tabs[i].content).replace(/ /g, '-')}` });
            }}
          >
            {tabContent[selectedTab]}
          </AppTabs>
        </AppPage>
      </div>
    </React.Fragment>
  );
};
