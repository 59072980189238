import { useCallback } from 'react';
import { PRODUCT_INVENTORY } from '../../../../../constants/product.constants';
import { AppChoiceList } from '../../../../choice-list/ChoiceList';
import { FilterElementProps } from './filter-elements.interface';

export const INVENTORY_FILTER_KEY = 'selectedInventory';
const INVENTORY_FILTER_LABEL = 'Inventory';
const INVENTORY_FILTER_TITLE = 'Inventory list';

const inventoryOptions = Object.values(PRODUCT_INVENTORY).map((c) => ({ label: c, value: c }));
// the prop "show all" is temporary and should change
const Filter = ({
  value,
  onChange,
  onlyAll,
}: FilterElementProps<string> & { onlyAll?: boolean }) => {
  const handleChange = useCallback(
    ([updated]: PRODUCT_INVENTORY[]) => {
      onChange(updated);
    },
    [onChange],
  );

  return (
    <AppChoiceList
      title={INVENTORY_FILTER_TITLE}
      titleHidden
      choices={onlyAll ? [{ value: 'show all', label: 'Show all' }] : inventoryOptions}
      selected={onlyAll ? ['show all'] : [value]}
      onChange={handleChange}
    />
  );
};

export const InventoryFilter = ({
  value,
  onChange,
  onlyAll = false,
}: FilterElementProps<string> & { onlyAll?: boolean }) => ({
  key: INVENTORY_FILTER_KEY,
  label: INVENTORY_FILTER_LABEL,
  shortcut: true,
  hideClearButton: true,
  filter: <Filter onChange={onChange} value={value} onlyAll={onlyAll} />,
});
