import { useCallback, useEffect } from 'react';
import { SelectionType } from '@shopify/polaris/build/ts/latest/src/utilities/index-provider';
import { AppIndexTable } from '../../../../core/components/index-table/index-table';
import { PageAwarePagination } from '../../../../core/components/pagination/page-aware-pagination';
import { IAction } from '../../../../core/components/resource-list/ResourceItem';
import { ICurrency } from '../../../../core/interfaces/ICurrency';
import { ILabelCandidateDetail } from '../../../api/orders.api';
import { AppIndexTableRow } from '../../../../core/components/index-table/index-table-row';
import { AppIndexTableCell } from '../../../../core/components/index-table/index-table-cell';
import { withCurrency } from '../../../../core/helpers/currency.helper';
import { AppCard } from '../../../../core/components/structure/card/card';
import { DateTooltip } from '../../../../core/components/date-tooltip/date-tooltip';
import { AppTooltip } from '../../../../core/components/overlays/tooltip/tooltip';
import { getPackageInfo } from '../../../../core/helpers/get-package-info.helper';

interface ILabelListProps {
  shipmentDetails: ILabelCandidateDetail[];
  fetching: boolean;
  totalPageCount: number;
  onPageChange: (page: number) => void;
  currency: ICurrency;
  onSelectionChange: (values: string[]) => void;
  listActions: IAction[];
  handleSelectionChange: (
    selectionType: SelectionType,
    isSelecting: boolean,
    selection?: any,
  ) => void;
  selectedResources: string[];
  allResourcesSelected: boolean;
}

export const LabelList = ({
  shipmentDetails,
  fetching,
  currency,
  listActions,
  totalPageCount,
  selectedResources,
  allResourcesSelected,
  onPageChange,
  onSelectionChange,
  handleSelectionChange,
}: ILabelListProps) => {
  useEffect(() => {
    onSelectionChange(selectedResources);
  }, [onSelectionChange, selectedResources]);

  const handlePageChange = useCallback(
    (page: number) => {
      onPageChange(page);
      handleSelectionChange('page' as SelectionType, false);
    },
    [onPageChange, handleSelectionChange],
  );

  return (
    <AppCard>
      <AppIndexTable
        selectable={true}
        resourceName={{ singular: 'label', plural: 'labels' }}
        headings={[
          { title: 'Order number & Date' },
          { title: 'Customer Name & Address' },
          { title: 'Shipping Method & Speed' },
          { title: 'Label Cost' },
          { title: 'Package Weight' },
        ]}
        itemCount={shipmentDetails.length}
        hasMoreItems={totalPageCount > 1}
        onSelectionChange={handleSelectionChange}
        selectedItemsCount={allResourcesSelected ? 'All' : selectedResources.length}
        promotedBulkActions={listActions.map((la) => ({
          ...la,
          onAction: () => {
            la.onAction && la.onAction();
          },
        }))}
        loading={fetching}
      >
        {shipmentDetails.map(
          (
            {
              id,
              supplierOrder,
              shippingMethod,
              totalWeight,
              dimensions,
              destinationAddress,
              amount,
              createdAt,
            },
            index,
          ) => (
            <AppIndexTableRow
              id={id}
              key={id}
              position={index}
              selected={selectedResources.includes(`${id}`)}
            >
              <AppIndexTableCell>
                {supplierOrder.supplierOrderName}
                {createdAt && <DateTooltip date={createdAt} />}
              </AppIndexTableCell>
              <AppIndexTableCell>
                {destinationAddress.name}
                <AppTooltip
                  content={
                    <>{`${destinationAddress.address1}, ${destinationAddress.province} 
                    ${destinationAddress.countryCode} ${destinationAddress.zip}`}</>
                  }
                >
                  <div>{`${destinationAddress.city}, ${destinationAddress.province} ${destinationAddress.countryCode}`}</div>
                </AppTooltip>
              </AppIndexTableCell>
              <AppIndexTableCell>{shippingMethod}</AppIndexTableCell>
              <AppIndexTableCell>{withCurrency(amount, currency)}</AppIndexTableCell>
              <AppIndexTableCell>{getPackageInfo(dimensions, totalWeight)}</AppIndexTableCell>
            </AppIndexTableRow>
          ),
        )}
      </AppIndexTable>
      <PageAwarePagination totalPageCount={totalPageCount} onPageChange={handlePageChange} />
    </AppCard>
  );
};
