import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppToast } from '../../../../core/components/feedback-indicators/toast/toast';
import { InventoryProductList } from '../../../../core/components/product-list/inventory-product-list';
import { AppPage } from '../../../../core/components/structure/page/page';
import { AppTextContainer } from '../../../../core/components/text-container/text-container';
import { PRODUCTS_PER_PAGE } from '../../../../core/constants/product.constants';
import { getNumberOfPages } from '../../../../core/helpers/item-list.helper';
import {
  deleteAllProductsFromInventoryAction,
  deleteProductsFromInventoryAction,
  disconnectAllProductsFromInventoryAction,
  disconnectProductsFromInventoryAction,
  exportCSVAllFromInventoryAction,
  exportCSVFromInventoryAction,
  getInventoryFilterOptionsAction,
  getInventoryProductVariantsAction,
  resetInventoryFiltersAction,
  setInventoryLimitFilterAction,
  setInventoryPageFilterAction,
} from '../../../redux/modules/inventory/inventory.actions';
import {
  fetchingInventoryProductsSelector,
  getInventoryProductsSelector,
  getTotalInventoryProductsCountSelector,
  getInventoryFiltersSelector,
} from '../../../redux/modules/inventory/inventory.selectors';
import { showSuccessLinkToastSelector } from '../../../redux/modules/sync/sync.selectors';
import { hideLinkToastAction } from '../../../redux/modules/variant-sync/variant-sync.actions';
import { InventoryVariantList } from '../../containers/variant-list/inventory-variant-list';
import './inventory-layout.scss';
import { getProductPreferencesAction } from '../../../redux/modules/preferences/preferences.actions';

export function InventoryLayout() {
  const dispatch = useDispatch();
  const products = useSelector(getInventoryProductsSelector);
  const total = useSelector(getTotalInventoryProductsCountSelector);
  const linkStatus = useSelector(showSuccessLinkToastSelector);
  const fetching = useSelector(fetchingInventoryProductsSelector);
  const { page: currentPage } = useSelector(getInventoryFiltersSelector);

  const [selectedProducts, setSelectedProducts] = useState<string[]>([]);
  const [allProductsSelected, setAllProductsSelected] = useState<boolean>(false);

  useEffect(() => {
    dispatch(getInventoryFilterOptionsAction());
    dispatch(setInventoryLimitFilterAction({ limit: PRODUCTS_PER_PAGE }));
    dispatch(getProductPreferencesAction());
    return () => {
      dispatch(resetInventoryFiltersAction());
    };
  }, [dispatch]);

  const disconnectAllProductsFromInventory = useCallback(
    () => dispatch(disconnectAllProductsFromInventoryAction()),
    [dispatch],
  );

  const disconnectProductsFromInventory = useCallback(
    (productIds: string[]) => {
      if (!productIds.length) {
        console.warn('No active products to disconnect');
        return;
      }

      dispatch(disconnectProductsFromInventoryAction(productIds));
    },
    [dispatch],
  );

  const exportCSVFromInventory = useCallback(
    (productIds: string[]) => {
      if (!productIds.length) {
        console.warn('No active products to disconnect');
        return;
      }

      dispatch(exportCSVFromInventoryAction(productIds));
    },
    [dispatch],
  );

  const exportAllCSVProductsFromInventory = useCallback(
    () => dispatch(exportCSVAllFromInventoryAction()),
    [dispatch],
  );

  const handleRemoveFromInventory = useCallback(() => {
    if (allProductsSelected) {
      dispatch(deleteAllProductsFromInventoryAction());
    } else {
      const productIds = selectedProducts.filter(
        (id) => !products.some((op) => op.processing && op.id === id),
      );
      if (productIds.length) {
        dispatch(deleteProductsFromInventoryAction(productIds));
      }
    }
    setSelectedProducts([]);
    setAllProductsSelected(false);
  }, [allProductsSelected, dispatch, selectedProducts, products]);

  const handleDisconnectProducts = useCallback(() => {
    if (allProductsSelected) {
      disconnectAllProductsFromInventory();
    } else {
      // remove processing product ids
      disconnectProductsFromInventory(
        selectedProducts.filter((id) => !products.some((op) => op.processing && op.id === id)),
      );
    }
    setSelectedProducts([]);
    setAllProductsSelected(false);
  }, [
    allProductsSelected,
    disconnectAllProductsFromInventory,
    disconnectProductsFromInventory,
    selectedProducts,
    products,
  ]);

  const handleExportCSV = useCallback(() => {
    if (allProductsSelected) {
      exportAllCSVProductsFromInventory();
    } else {
      exportCSVFromInventory(selectedProducts);
    }

    setSelectedProducts([]);
    setAllProductsSelected(false);
  }, [
    selectedProducts,
    allProductsSelected,
    exportCSVFromInventory,
    exportAllCSVProductsFromInventory,
  ]);

  const hideToast = useCallback(() => {
    dispatch(hideLinkToastAction());
  }, [dispatch]);

  const toastMarkup = useMemo(
    () =>
      linkStatus.show ? (
        <AppToast error={!linkStatus.success} onDismiss={hideToast} content="Products connected" />
      ) : null,
    [linkStatus, hideToast],
  );

  const handleVariantListExpand = useCallback(
    (productId: string) => dispatch(getInventoryProductVariantsAction(productId)),
    [dispatch],
  );

  const renderVariantList = useCallback((productId: string, isDisconnectDisable: boolean) => {
    return <InventoryVariantList productId={productId} isDisconnectDisable={isDisconnectDisable} />;
  }, []);

  return (
    <div className="inventory-wrapper">
      <AppPage title="My Products">
        <AppTextContainer>
          Browse your store products below. Manage pricing and map your products to specific
          Supplier SKUs using the "Show X SKU's" button.
        </AppTextContainer>
        <InventoryProductList
          products={products}
          removeFromStore={handleRemoveFromInventory}
          disconnectProducts={handleDisconnectProducts}
          handleExportCSV={handleExportCSV}
          onSelectionChange={setSelectedProducts}
          allSelectedToggle={setAllProductsSelected}
          onProductVariantsExpand={handleVariantListExpand}
          totalPageCount={getNumberOfPages(total)}
          onPageChange={(page) => dispatch(setInventoryPageFilterAction({ page }))}
          fetching={fetching}
          renderVariantList={renderVariantList}
          selectedProducts={selectedProducts}
          currentPage={currentPage}
        />
      </AppPage>
      {toastMarkup}
    </div>
  );
}
