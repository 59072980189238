import { createAction } from 'typesafe-actions';
import { IRetailerStatus } from '../../../interfaces/IPlan';
import { ACTIONS_CONSTANTS } from '../../actions-constants';

export const getStatusAction = createAction(ACTIONS_CONSTANTS.GET_STATUS)();

export const setStatusAction = createAction(
  ACTIONS_CONSTANTS.SET_STATUS,
  (status: IRetailerStatus) => status,
)();

export const updateProductsLimitStatusAction = createAction(
  ACTIONS_CONSTANTS.UPDATE_PRODUCTS_LIMIT_STATUS,
  (status: Pick<IRetailerStatus, 'productsLimit' | 'productsCount'>) => status,
)();

export const decrementProductsCountAction = createAction(
  ACTIONS_CONSTANTS.DECREMENT_PRODUCTS_COUNT,
)();

export const updateStatusOnAccountLoadedAction = createAction(
  ACTIONS_CONSTANTS.UPDATE_STATUS_ON_ACCOUNT_LOADED,
)();

export const updateStatusOnPreferencesLoadedAction = createAction(
  ACTIONS_CONSTANTS.UPDATE_STATUS_ON_PREFERENCES_LOADED,
)();

export const markAccountInfoAsFilledAction = createAction(
  ACTIONS_CONSTANTS.MARK_ACCOUNT_INFO_AS_FILLED,
)();

export const disablePromotionBannerAction = createAction(
  ACTIONS_CONSTANTS.DISABLE_PROMOTION_BANNER,
)();
