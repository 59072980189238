import { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  resetInventoryFiltersAction,
  setInventoryStockFilterAction,
  setInventoryQueryFilterAction,
  setInventorySupplierFilterAction,
  setInventoryTypeFilterAction,
  setInventoryVendorFilterAction,
} from '../../../../../shopify-retailer/redux/modules/inventory/inventory.actions';
import {
  getInventoryFilterOptionsSelector,
  getInventoryFiltersSelector,
} from '../../../../../shopify-retailer/redux/modules/inventory/inventory.selectors';
import { IInventoryFilter } from '../../../../interfaces/IProductFilter';
import { IAppliedFilter } from '../../../filters/filters';
import '../../product-list.scss';
import { SKULimit } from '../sku-limit';
import { QueryFilterContainer } from './filter-elements/query-filter-container';
import { STOCK_FILTER_KEY } from './filter-elements/stock-filter';
import { SupplierFilter, SUPPLIER_FILTER_KEY } from './filter-elements/supplier-filter';
import { TypeFilter, TYPE_FILTER_KEY } from './filter-elements/type-filter';
import { VendorFilter, VENDOR_FILTER_KEY } from './filter-elements/vendor-filter';
import { INVENTORY_FILTER_KEY, InventoryFilter } from './filter-elements/inventory-filter';
import { PRODUCT_INVENTORY } from '../../../../constants/product.constants';

export const SyncProductFilter = () => {
  const dispatch = useDispatch();
  const filters: IInventoryFilter = useSelector(getInventoryFiltersSelector);
  const { vendors, types, fulfilledBy } = useSelector(getInventoryFilterOptionsSelector);

  const appliedFilters: IAppliedFilter[] = useMemo(() => {
    const appliedVendors = {
      key: VENDOR_FILTER_KEY,
      label: `Brands: ${filters.vendors.join(', ')}`,
      onRemove: () => dispatch(setInventoryVendorFilterAction({ vendors: [] })),
    };
    const appliedSuppliers = {
      key: SUPPLIER_FILTER_KEY,
      label: `Suppliers: ${fulfilledBy
        .filter((fb) => filters.fulfilledBy.find((ffb) => ffb === fb.value))
        .map((fb) => fb.label)
        .join(', ')}`,
      onRemove: () => dispatch(setInventorySupplierFilterAction({ fulfilledBy: [] })),
    };
    const appliedTypes = {
      key: TYPE_FILTER_KEY,
      label: `Types: ${filters.types.join(', ')}`,
      onRemove: () => dispatch(setInventoryTypeFilterAction({ types: [] })),
    };
    const appliedInStock = {
      key: STOCK_FILTER_KEY,
      label: 'Inventory: Hide out of stock',
      onRemove: () => dispatch(setInventoryStockFilterAction({ hasStock: false })),
    };
    // temporary
    const appliedInventory = {
      key: INVENTORY_FILTER_KEY,
      label: `Inventory: ${PRODUCT_INVENTORY.SHOW_ALL}`,
      // temporary
      onRemove: () => {},
    };

    const chosenVendors = filters.vendors.length ? [appliedVendors] : [];
    const chosenSuppliers = filters.fulfilledBy.length ? [appliedSuppliers] : [];
    const chosenTypes = filters.types.length ? [appliedTypes] : [];
    const chosenHasStock = filters.hasStock ? [appliedInStock] : [];
    // temporary
    const chosenInventory = filters.productInventory.includes(PRODUCT_INVENTORY.SHOW_ALL)
      ? []
      : [appliedInventory];

    return [
      ...chosenVendors,
      ...chosenSuppliers,
      ...chosenTypes,
      ...chosenHasStock,
      ...chosenInventory,
    ];
  }, [filters, fulfilledBy, dispatch]);

  return (
    <>
      <SKULimit />
      <QueryFilterContainer
        value={filters.query}
        onChange={(query) => dispatch(setInventoryQueryFilterAction({ query }))}
        filters={[
          VendorFilter({
            value: filters.vendors,
            onChange: (vendors) => dispatch(setInventoryVendorFilterAction({ vendors })),
            options: vendors,
          }),
          SupplierFilter({
            value: filters.fulfilledBy,
            onChange: (fulfilledBy) => dispatch(setInventorySupplierFilterAction({ fulfilledBy })),
            options: fulfilledBy,
          }),
          TypeFilter({
            value: filters.types,
            onChange: (types) => dispatch(setInventoryTypeFilterAction({ types })),
            options: types,
          }),
          InventoryFilter({
            onlyAll: true,
            value: PRODUCT_INVENTORY.SHOW_ALL,
            // temporary
            onChange: (inventory) => {},
          }),
        ]}
        appliedFilters={appliedFilters}
        onClearAll={() => dispatch(resetInventoryFiltersAction())}
      />
    </>
  );
};
