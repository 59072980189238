import { createReducer } from 'typesafe-actions';
import { IShopViewDetails } from '../../../interfaces/IAccount';
import { CoreActions } from '../../actions';
import {
  setViewAsClientSettingAction,
  showAdminSettingsUpdatedToastAction,
  hideAdminSettingsUpdatedToastAction,
} from './admin.actions';

interface IAdminReducer {
  settings: {
    viewAsClient?: IShopViewDetails;
  };
  settingsUpdated: boolean;
}

const initialState: IAdminReducer = {
  settings: {},
  settingsUpdated: false,
};

export const adminReducer = createReducer<IAdminReducer, CoreActions>(initialState)
  .handleAction(setViewAsClientSettingAction, (state, { payload: { viewAsClient } }) => ({
    ...state,
    settings: { viewAsClient },
  }))
  .handleAction(showAdminSettingsUpdatedToastAction, (state) => ({
    ...state,
    settingsUpdated: true,
  }))
  .handleAction(hideAdminSettingsUpdatedToastAction, (state) => ({
    ...state,
    settingsUpdated: false,
  }));
