import { axiosInstance } from '../../core/api/base.api';

class AuthApi {
  private backend = process.env.REACT_APP_BACK_END_URL as string;
  private rootPath = '/api/retailer/shopify';

  async authenticate(query: string) {
    return await axiosInstance.get<IAuthenticationResponse>(
      `${this.backend}${this.rootPath}${query}`,
    );
  }

  async authCallback(query: string) {
    return await axiosInstance.get<IAuthCallbackResponse>(
      `${this.backend}${this.rootPath}/oauth/callback${query}`,
    );
  }

  async getAccountLoginOption() {
    return await axiosInstance.get<{ selectedLoginOption: boolean } | { error: true }>(
      `${this.backend}${this.rootPath}/auth/option`,
    );
  }

  async confirmNewAccount() {
    return await axiosInstance.post<{ success: boolean }>(
      `${this.backend}${this.rootPath}/auth/new`,
    );
  }
  async connectAccount(body: { email: string; password: string }) {
    return await axiosInstance.post<{ token: string } | { error: true }>(
      `${this.backend}${this.rootPath}/auth/existing`,
      body,
    );
  }
}

export const authApi = new AuthApi();

type IAuthenticationResponse =
  | { accessToken: string }
  | { archived: string }
  | { installationUrl: Location };

interface IAuthCallbackResponse {
  urlToRedirect: Location;
}
