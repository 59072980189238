import { Form, Formik } from 'formik';
import React, { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useDebouncedCallback } from 'use-debounce';
import validator from 'validator';
import { setAxiosAuthorization } from '../../../../core/api/base.api';
import { AppToast } from '../../../../core/components/feedback-indicators/toast/toast';
import { AppControlledTextField } from '../../../../core/components/forms/controlled-text-field/controlled-text-field';
import { AppLink } from '../../../../core/components/link/link';
import { AppCard } from '../../../../core/components/structure/card/card';
import { AppPage } from '../../../../core/components/structure/page/page';
import { AppTextContainer } from '../../../../core/components/text-container/text-container';
import { validation } from '../../../../core/helpers/validations.helper';
import { authApi } from '../../../api/auth.api';
import { setAuthenticatedAction } from '../../../redux/modules/auth/auth.actions';
import { ROUTES } from '../../../router/routes';
import './auth.scss';

export const SignUpLayout = () => {
  const dispatch = useDispatch();
  const [signingUp, setSigningUp] = useState(false);
  const [error, setError] = useState('');

  /** Password and confirmation passwords must be equal and at least 6 characters long */
  const passwordValidation = useCallback(
    (value: string, otherValue: string, otherTouched: boolean) => {
      if (!value) return 'Please enter a password';
      if (value.length < 6) return 'Password must be at least 6 characters long';
      if (value !== otherValue && otherTouched) return 'Values must match';
    },
    [],
  );

  const emailValidation = useCallback(
    (value: string) => {
      if (!value) return 'Please enter an email address';
      if (!validator.isEmail(value)) return 'Invalid email address';
      return new Promise<string | undefined>((resolve, reject) => {
        authApi
          .checkEmail(value)
          .then(({ data }) => {
            if (data.exists) return resolve('An account with this email address already exists.');
            return resolve(undefined);
          })
          .catch((error) => {
            console.error(error);
            setError('Unexpected error');
            reject(error);
          });
      });
    },
    [setError],
  );

  const debouncedEmailValidation = useDebouncedCallback(emailValidation, 750);

  const handleRegister = useCallback(
    (body: { businessName: string; email: string; password: string; confirmPassword: string }) => {
      setSigningUp(true);
      authApi
        .register(body)
        .then(({ data }) => {
          localStorage.setItem('token', data.token);
          setAxiosAuthorization(data.token);
          dispatch(setAuthenticatedAction(data.token));
        })
        .catch((e) => {
          console.error(e);
          setError('Unexpected error. Please, try again later');
        })
        .finally(() => {
          setSigningUp(false);
        });
    },
    [dispatch],
  );

  return (
    <AppPage>
      <div className="auth-page">
        <div className="small-card">
          <Formik
            initialValues={{ businessName: '', email: '', password: '', confirmPassword: '' }}
            onSubmit={handleRegister}
            enableReinitialize
          >
            {({ submitForm, getFieldMeta }) => (
              <Form name={'sign-up'}>
                <AppCard
                  title="Sign Up"
                  sectioned
                  primaryFooterAction={{
                    content: 'Sign Up',
                    onAction: submitForm,
                    loading: signingUp,
                  }}
                >
                  <AppControlledTextField
                    autoComplete=""
                    label="Business name"
                    name="businessName"
                    type="text"
                    validate={validation.isNotEmpty}
                  />
                  <AppControlledTextField
                    autoComplete=""
                    label="Email"
                    name="email"
                    type="email"
                    validate={debouncedEmailValidation}
                  />
                  <AppControlledTextField
                    name="password"
                    autoComplete=""
                    label="Password"
                    type="password"
                    validate={(value) =>
                      passwordValidation(
                        value,
                        getFieldMeta('confirmPassword').value as string,
                        getFieldMeta('confirmPassword').touched,
                      )
                    }
                  />
                  <AppControlledTextField
                    name="confirmPassword"
                    autoComplete=""
                    label="Confirm password"
                    type="password"
                    validate={(value) =>
                      passwordValidation(
                        value,
                        getFieldMeta('password').value as string,
                        getFieldMeta('password').touched,
                      )
                    }
                  />
                  <AppTextContainer>
                    Already have an account? <AppLink url={ROUTES.SIGN_IN}>Sign in</AppLink>
                  </AppTextContainer>
                </AppCard>
              </Form>
            )}
          </Formik>
        </div>
        {error && <AppToast content={error} onDismiss={() => setError('')} error />}
      </div>
    </AppPage>
  );
};
