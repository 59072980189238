import { Form, Formik } from 'formik';
import { toLower } from 'lodash';
import React, { ReactNode, useCallback, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router';
import { AppToast } from '../../../../core/components/feedback-indicators/toast/toast';
import { AppContextualSaveBar } from '../../../../core/components/forms/contextual-save-button/contextual-save-button';
import { AppPage } from '../../../../core/components/structure/page/page';
import { AppTabs } from '../../../../core/components/tabs/tabs';
import { IAccount } from '../../../../core/interfaces/IAccount';
import {
  INotificationsPreferences,
  IOrderPreferences,
  IPreferences,
  IProductPreferences,
  IShippingPreferences,
} from '../../../interfaces/IPreferences';
import { setPreferencesAction } from '../../../redux/modules/preferences/preferences.actions';
import './preferences-list.scss';
import { CompanySection } from './sections/company-section';
import { NotificationsSection } from './sections/notifications-section';
import { OrdersSection } from './sections/orders-section';
import { ProductsSection } from './sections/products-section';
import { ShippingSection } from './sections/shipping-section';
import { PaymentsSection } from './sections/payments-section';
import { PlansLayout } from '../../layouts/plans-layout/plans-layout';
import { AppModal } from '../../../../core/components/overlays/modal/modal';
import { AppTextContainer } from '../../../../core/components/text-container/text-container';
import { preferencesApi } from '../../../api/preferences.api';
import { accountApi } from '../../../../core/api/account.api';
import { setAccountInfoAction } from '../../../../core/redux/modules/account/account.actions';
import { DEFAULT_PRODUCT_TAG } from '../../../constants/product.constants';

interface IPreferencesListProps {
  preferences: IPreferences;
  accountInfo: IAccount;
  carrierServices: string[];
}

const defaultErrorMessage = 'Error when updating settings';

const tabs = [
  { id: 'company', content: 'Company' },
  { id: 'products', content: 'Products' },
  { id: 'orders', content: 'Orders' },
  { id: 'shipping', content: 'Shipping' },
  { id: 'notification', content: 'Notifications' },
  { id: 'payments', content: 'Payments' },
  { id: 'plans', content: 'Subscription Plan' },
];

const findTabIndexByQuery = (tab?: string): number => {
  if (!tab) return 0;
  const index = tabs.findIndex((t) => toLower(t.content).replace(' ', '-') === tab);
  if (index === -1) return 0;
  return index;
};

export function PreferencesList({
  preferences,
  accountInfo,
  carrierServices,
}: IPreferencesListProps) {
  const dispatch = useDispatch();
  const { search } = useLocation();
  const history = useHistory();
  const parsedQuery = new URLSearchParams(search);
  const tab = parsedQuery.get('tab') as string;

  const [selectedTab, setSelectedTab] = useState<number>(findTabIndexByQuery(tab));
  const [pendingTab, setPendingTab] = useState<number | undefined>();
  const [openConfirmationModal, setOpenConfirmationModal] = useState(false);
  const [updatingPreferences, setUpdatingPreferences] = useState(false);
  const [updatingProfileLogo, setUpdatingProfileLogo] = useState(false);
  const [updated, setUpdated] = useState(false);
  const [error, setError] = useState<string>('');

  const updateProfilePreference = useCallback(
    (values: { preferences: IPreferences; account: IAccount }) => {
      setUpdatingPreferences(true);

      accountApi
        .postAccountInfo(values.account)
        .then(() => {
          dispatch(setAccountInfoAction(values.account));

          const data = new FormData();
          if (typeof values.preferences.profile.logo === 'object')
            data.append('logo', values.preferences.profile.logo);

          if (typeof values.preferences.profile.logo !== 'string') {
            setUpdatingProfileLogo(true);
            preferencesApi
              .updateProfileLogoPreferences(data)
              .then(({ data }) => {
                dispatch(
                  setPreferencesAction({
                    ...preferences,
                    profile: {
                      logo: data.logo,
                    },
                  }),
                );
              })
              .catch((error) => {
                throw new Error(error);
              })
              .finally(() => {
                setUpdated(true);
                setUpdatingProfileLogo(false);
              });
          }
        })
        .catch((error) => {
          setError(defaultErrorMessage);
          console.error(error);
        })
        .finally(() => {
          setUpdatingPreferences(false);
        });
    },
    [dispatch, preferences],
  );

  const updateProductPreference = useCallback(
    (preference: IProductPreferences) => {
      setUpdatingPreferences(true);

      if (!preference.addTag && !preference.customTag) {
        preference.customTag = DEFAULT_PRODUCT_TAG;
      }

      preferencesApi
        .updateProductPreferences(preference)
        .then(() => {
          dispatch(
            setPreferencesAction({
              ...preferences,
              product: preference,
            }),
          );
          setUpdated(true);
        })
        .catch((error) => {
          setError(defaultErrorMessage);
          console.error(error);
        })
        .finally(() => {
          setUpdatingPreferences(false);
        });
    },
    [dispatch, preferences],
  );

  const updateOrderPreference = useCallback(
    (preference: IOrderPreferences) => {
      setUpdatingPreferences(true);

      preferencesApi
        .updateOrderPreferences(preference)
        .then(() => {
          dispatch(
            setPreferencesAction({
              ...preferences,
              order: preference,
            }),
          );
          setUpdated(true);
        })
        .catch((error) => {
          setError(defaultErrorMessage);
          console.error(error);
        })
        .finally(() => {
          setUpdatingPreferences(false);
        });
    },
    [dispatch, preferences],
  );

  const updateShippingPreference = useCallback(
    (preference: IShippingPreferences) => {
      setUpdatingPreferences(true);

      preferencesApi
        .updateShippingPreferences(preference)
        .then(() => {
          dispatch(
            setPreferencesAction({
              ...preferences,
              shipping: preference,
            }),
          );
          setUpdated(true);
        })
        .catch((error) => {
          setError(defaultErrorMessage);
          console.error(error);
        })
        .finally(() => {
          setUpdatingPreferences(false);
        });
    },
    [dispatch, preferences],
  );

  const updateNotificationPreference = useCallback(
    (preference: INotificationsPreferences) => {
      setUpdatingPreferences(true);

      preferencesApi
        .updateNotificationPreferences(preference)
        .then(() => {
          dispatch(
            setPreferencesAction({
              ...preferences,
              notifications: preference,
            }),
          );
          setUpdated(true);
        })
        .catch((error) => {
          setError(defaultErrorMessage);
          console.error(error);
        })
        .finally(() => {
          setUpdatingPreferences(false);
        });
    },
    [dispatch, preferences],
  );

  const handleTabSubmit = useCallback(
    (values: { preferences: IPreferences; account: IAccount }) => {
      switch (selectedTab) {
        case 0:
          updateProfilePreference(values);
          break;
        case 1:
          updateProductPreference(values.preferences.product);
          break;
        case 2:
          updateOrderPreference(values.preferences.order);
          break;
        case 3:
          updateShippingPreference(values.preferences.shipping);
          break;
        case 4:
          updateNotificationPreference(values.preferences.notifications);
          break;
      }
    },
    [
      updateProfilePreference,
      updateProductPreference,
      updateOrderPreference,
      updateNotificationPreference,
      updateShippingPreference,
      selectedTab,
    ],
  );

  const tabContent: (
    values: { preferences: IPreferences; account: IAccount },
    setFieldValue: (field: string, value: any) => void,
  ) => { [key: number]: ReactNode } = useCallback(
    (
      values: { preferences: IPreferences; account: IAccount },
      setFieldValue: (field: string, value: any) => void,
    ) => ({
      0: (
        <CompanySection
          unsavedPreferences={values.preferences.profile}
          setFieldValue={setFieldValue}
        />
      ),
      1: <ProductsSection unsavedPreferences={values.preferences.product} />,
      2: <OrdersSection />,
      3: (
        <ShippingSection
          carrierServices={carrierServices}
          unsavedPreferences={values.preferences.shipping}
          setFieldValue={setFieldValue}
        />
      ),
      4: <NotificationsSection unsavedPreferences={values.preferences.notifications} />,
      5: <PaymentsSection />,
      6: <PlansLayout setSelectedTab={setSelectedTab} />,
    }),
    [carrierServices],
  );

  const handleModalClose = () => {
    setPendingTab(undefined);
    setOpenConfirmationModal(false);
  };
  const updating = useMemo(
    () => updatingPreferences || updatingProfileLogo,
    [updatingPreferences, updatingProfileLogo],
  );
  return (
    <AppPage title="Settings">
      <Formik
        initialValues={{ preferences, account: accountInfo }}
        onSubmit={handleTabSubmit}
        enableReinitialize
      >
        {({ submitForm, resetForm, dirty, values, setFieldValue }) => (
          <Form name={'settings'} className="settings-layout">
            {dirty ? (
              <AppContextualSaveBar
                message="Unsaved changes"
                saveAction={{
                  onAction: submitForm,
                  loading: updating,
                }}
                discardAction={{
                  onAction: resetForm,
                  loading: updating,
                }}
              />
            ) : null}
            <AppTabs
              tabs={tabs}
              selected={selectedTab}
              onSelect={(i) => {
                if (!dirty) {
                  setSelectedTab(i);
                  history.push({ search: `?tab=${toLower(tabs[i].content).replace(' ', '-')}` });
                  return;
                }
                setPendingTab(i);
                setOpenConfirmationModal(true);
              }}
            >
              {tabContent(values, setFieldValue)[selectedTab]}
            </AppTabs>
            {openConfirmationModal && (
              <AppModal
                open={openConfirmationModal}
                onClose={handleModalClose}
                title="Unsaved changes will be lost"
                primaryAction={{
                  content: 'Stay',
                  onAction: handleModalClose,
                }}
                secondaryActions={[
                  {
                    content: 'Leave tab',
                    onAction: () => {
                      pendingTab !== undefined && setSelectedTab(pendingTab);
                      handleModalClose();
                      resetForm();
                    },
                  },
                ]}
              >
                <AppTextContainer>
                  You've made changes to your preferences. Please save them before leaving, or they
                  will be lost. Are you sure you want to switch the tab?
                </AppTextContainer>
              </AppModal>
            )}
            {updated && <AppToast onDismiss={() => setUpdated(false)} content="Changes saved" />}
            {error && <AppToast onDismiss={() => setError('')} content={error} error />}
          </Form>
        )}
      </Formik>
    </AppPage>
  );
}
