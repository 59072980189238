import React from 'react';
import { ReactComponent as Spinner } from '../../../images/spinner.svg';
import './spinner-replica.scss';

// we need this component to add a shopify-like spinner
// at as point where app provider cannot be yet initialized
// this spinner is absolute and centered by default
export const SpinnerReplica = () => {
  return (
    <span className="spinner-replica">
      <Spinner />
    </span>
  );
};
