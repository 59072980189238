import React, { Fragment, ReactNode, useMemo } from 'react';
import { AppButton } from '../../../../../../core/components/button/Button';
import { AppCollapsible } from '../../../../../../core/components/collapsible/Collapsible';
import { AppThumbnail } from '../../../../../../core/components/image-containers/thumbnail/Thumbnail';
import { AppTextStyle } from '../../../../../../core/components/text/text-style/TextStyle';
import { IProductMatch } from '../../../../../interfaces/IProductsSync';
import { AppTooltip } from '../../../../../../core/components/overlays/tooltip/tooltip';
import { ReactComponent as CrowdshipLogo } from '../../../../../../core/images/crowdship-navy.svg';
import { AppIndexTableCell } from '../../../../../../core/components/index-table/index-table-cell';
import { AppIndexTableRow } from '../../../../../../core/components/index-table/index-table-row';
import './sync-match.scss';
import { TrackedQuantityLabelForProduct } from '../../../../../../core/components/tracked-quantity-label-for-product/tracked-quantity-label-for-product';

type SyncMatchProps = {
  shop: string;
  position: number;
  selected: boolean;
  match: IProductMatch;
  toggleExpansion: (matchId: string) => void;
  variantsExpanded: boolean;
  variants: ReactNode;
};

export const SyncMatch = ({
  shop,
  position,
  selected,
  match,
  toggleExpansion,
  variantsExpanded,
  variants,
}: SyncMatchProps) => {
  const { retailerProduct, supplierProduct } = match;
  const retailerImages = retailerProduct.variants.map((v) => v.image).filter((img) => img);
  const thumbnailSource = retailerProduct.image || retailerImages[0];
  const toggleVariantsLabel = variantsExpanded ? 'Hide variants' : 'Show variants';
  const obsoleteVariantsCount = retailerProduct.variants.filter((v) => v.isObsolete).length;
  const newVariantsCount = supplierProduct.variants.filter((v) => v.isMissing).length;

  const crowdshipMarker = useMemo(
    () => (
      <AppTooltip
        content="Product was supported by Crowdship"
        dismissOnMouseOut
        preferredPosition="above"
      >
        <span className="crowdship-logo">
          <CrowdshipLogo />
        </span>
      </AppTooltip>
    ),
    [],
  );

  return (
    <Fragment key={match.id}>
      <AppIndexTableRow id={match.id} key={match.id} selected={selected} position={position}>
        <AppIndexTableCell>
          <div className={`thumbnail-wrapper ${retailerProduct.crowdship ? 'crowdship' : ''}`}>
            <AppThumbnail source={thumbnailSource} alt={retailerProduct.title} />
            {retailerProduct.crowdship && crowdshipMarker}
          </div>
        </AppIndexTableCell>
        <AppIndexTableCell>
          <div className="product-title">
            <AppTextStyle variation="strong">{retailerProduct.title}</AppTextStyle>
            <a
              className={`link-to-platform${variantsExpanded ? ' visible' : ''}`}
              href={`https://${shop}/admin/products/${retailerProduct.shopifyId}`}
              target="_blank"
              rel="noreferrer"
              onClick={(e) => e.stopPropagation()}
            >
              <AppTextStyle variation="subdued">View on Shopify</AppTextStyle>
            </a>
          </div>
        </AppIndexTableCell>
        <AppIndexTableCell>{retailerProduct.vendor}</AppIndexTableCell>
        <AppIndexTableCell>{retailerProduct.qty}</AppIndexTableCell>
        <AppIndexTableCell>
          <TrackedQuantityLabelForProduct
            quantity={supplierProduct.qty}
            variants={supplierProduct.variants}
          />
        </AppIndexTableCell>
        <AppIndexTableCell>
          <div className="variations">{retailerProduct.variants.length} variations</div>
          {obsoleteVariantsCount ? (
            <div className="obsolete-count">{obsoleteVariantsCount} obsolete</div>
          ) : (
            <div />
          )}
          {newVariantsCount ? <div className="new-count">{newVariantsCount} new</div> : <div />}
        </AppIndexTableCell>
        <AppIndexTableCell>
          <div className="expand-variants-btn undelined-text">
            <AppButton
              handleClick={(e) => {
                e.stopPropagation();
                toggleExpansion(match.id);
              }}
            >
              {toggleVariantsLabel}
            </AppButton>
          </div>
        </AppIndexTableCell>
      </AppIndexTableRow>

      {/** Creating a new row so the variants can be displayed under the product */}
      <tr id={`${match.id}-variants`} key={`${match.id}-variants`}>
        <td colSpan={8}>
          <AppCollapsible id={`${match.id}-variants`} open={variantsExpanded}>
            <div className="product-variants">{variants}</div>
          </AppCollapsible>
        </td>
      </tr>
    </Fragment>
  );
};
