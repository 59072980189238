import { createReducer } from 'typesafe-actions';
import { IImportListProductGroup } from '../../../api/import-list.api';
import { RootActions } from '../../root.actions';
import {
  deleteProductFromImportListAction,
  getImportListProductsAction,
  hideProductRemovedToastAction,
  setImportListProductAction,
  setImportListProductsAction,
  showProductRemovedToastAction,
  addImportListProductToInventoryAction,
  showInventoryProductsAddedToastAction,
  hideInventoryProductsAddedToastAction,
  showLimitExceededBannerAction,
  hideLimitExceededBannerAction,
  showLimitExceededModalAction,
  hideLimitExceededModalAction,
  showImportListFetchingErrorToastAction,
} from './import-list.actions';

interface IImportListReducer {
  productList: {
    productGroups: IImportListProductGroup[];
    total: number;
    page: number;
  };
  fetching: boolean;
  showLimitExceededBanner: boolean;
  showLimitExceededModal: boolean;
  areProductRemoved: boolean;
  areProductsAddedToInventory: boolean;
  importListFetchingError: boolean;
}

const initialState: IImportListReducer = {
  productList: {
    productGroups: [],
    total: 0,
    page: 0,
  },
  fetching: false,
  showLimitExceededBanner: false,
  showLimitExceededModal: false,
  areProductRemoved: false,
  areProductsAddedToInventory: false,
  importListFetchingError: false,
};

export const importListReducer = createReducer<IImportListReducer, RootActions>(initialState)
  .handleAction(getImportListProductsAction, (state) => ({
    ...state,
    fetching: true,
  }))
  .handleAction(setImportListProductsAction, (state, { payload }) => ({
    ...state,
    productList: {
      ...payload,
      productGroups: payload.productGroups,
    },
    fetching: false,
    importListFetchingError: false,
  }))
  .handleAction(setImportListProductAction, (state, { payload }) => ({
    ...state,
    productList: {
      ...state.productList,
      productGroups: state.productList.productGroups.map((pG) =>
        pG.id === payload.id ? payload : pG,
      ),
    },
    fetching: false,
  }))
  .handleAction(addImportListProductToInventoryAction, (state) => ({
    ...state,
    fetching: true,
  }))
  .handleAction(showInventoryProductsAddedToastAction, (state) => ({
    ...state,
    areProductsAddedToInventory: true,
    fetching: false,
  }))
  .handleAction(hideInventoryProductsAddedToastAction, (state) => ({
    ...state,
    areProductsAddedToInventory: false,
  }))
  .handleAction(deleteProductFromImportListAction, (state) => ({
    ...state,
    fetching: true,
  }))
  .handleAction(showLimitExceededBannerAction, (state) => ({
    ...state,
    showLimitExceededBanner: true,
    fetching: false,
  }))
  .handleAction(hideLimitExceededBannerAction, (state) => ({
    ...state,
    showLimitExceededBanner: false,
  }))
  .handleAction(showLimitExceededModalAction, (state) => ({
    ...state,
    showLimitExceededModal: true,
    fetching: false,
  }))
  .handleAction(hideLimitExceededModalAction, (state) => ({
    ...state,
    showLimitExceededModal: false,
  }))
  .handleAction(showProductRemovedToastAction, (state) => ({
    ...state,
    areProductRemoved: true,
  }))
  .handleAction(hideProductRemovedToastAction, (state) => ({
    ...state,
    areProductRemoved: false,
  }))
  .handleAction(showImportListFetchingErrorToastAction, (state) => ({
    ...state,
    importListFetchingError: true,
  }));
