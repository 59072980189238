import { useSelector } from 'react-redux';
import { Route, useHistory } from 'react-router-dom';
import { getInactiveStoreSelector } from '../../core/redux/modules/auth/auth.selectors';

const StoreActivityValidator = ({ children }: React.PropsWithChildren<{}>) => {
  const history = useHistory();
  const inactiveStore = useSelector(getInactiveStoreSelector);

  if (inactiveStore) {
    history.push('/');
  }

  return <>{children}</>;
};

export const GuardedRoute = ({ component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(routeProps) => (
        <StoreActivityValidator>
          <Component {...routeProps} />
        </StoreActivityValidator>
      )}
    />
  );
};
