import React, { useCallback, useEffect, useMemo } from 'react';
import { AppControlledSelect } from '../../../../../core/components/forms/controlled-select/controlled-select';
import { AppDataTable } from '../../../../../core/components/data-table/DataTable';
import { AppThumbnail } from '../../../../../core/components/image-containers/thumbnail/Thumbnail';
import { validation } from '../../../../../core/helpers/validations.helper';
import {
  IRetailerProductMatchVariant,
  ISupplierProductMatchVariant,
} from '../../../../interfaces/IProductsSync';
import './match-variant-list.scss';
import { AppControlledTextField } from '../../../../../core/components/forms/controlled-text-field/controlled-text-field';

export type VariantOwnerType = 'supplier' | 'retailer';

interface IMatchVariantListProps {
  retailersVariants: IRetailerProductMatchVariant[];
  suppliersVariants: ISupplierProductMatchVariant[];
  getInputName: (sku: string, index: number, owner: VariantOwnerType) => string;
  getSelectName: (sku: string, index: number) => string;
  disableInputs: boolean;
}

export function MatchVariantList({
  retailersVariants,
  suppliersVariants,
  getInputName,
  getSelectName,
  disableInputs,
}: IMatchVariantListProps) {
  useEffect(() => {
    const elements = document.getElementsByTagName('th');
    for (let i = 0; i < elements.length; i++) {
      const child = elements[i].firstChild as HTMLElement;
      if (child.nodeName === 'DIV') {
        if (child.classList.contains('obsolete')) {
          elements[i].parentElement?.classList.add('obsolete-row');
        } else if (child.classList.contains('new')) {
          elements[i].parentElement?.classList.add('new-row');
        }
      }
    }
  }, []);

  const renderInput = useCallback(
    (sku: string, index: number, owner: VariantOwnerType) => {
      return (
        <div className="price-field-wrapper" onClick={(e) => e.stopPropagation()}>
          <AppControlledTextField
            name={getInputName(sku, index, owner)}
            validate={validation.isPositiveNumber}
            disabled={disableInputs}
          />
        </div>
      );
    },
    [getInputName, disableInputs],
  );

  const renderSelect = useCallback(
    (sku: string, index: number) => {
      return (
        <div onClick={(e) => e.stopPropagation()}>
          <AppControlledSelect
            label="Delete options"
            options={[
              { label: 'Delete', value: 'delete' },
              { label: 'Leave', value: 'leave' },
            ]}
            name={getSelectName(sku, index)}
          />
        </div>
      );
    },
    [getSelectName],
  );

  const rows = useMemo(() => {
    return [
      ...retailersVariants
        .map((rv, i) => {
          const supplierVariant = suppliersVariants.find((sv) => sv.sku === rv.sku);
          if (rv.isObsolete || !supplierVariant) {
            return [
              <div className="obsolete">{rv.sku}</div>,
              rv.title,
              <AppThumbnail alt={rv.title} source={rv.image} />,
              renderInput(rv.sku, i, 'retailer'),
              '',
              '',
              renderSelect(rv.sku, i),
            ];
          } else {
            return [
              rv.sku,
              rv.title,
              <AppThumbnail alt={rv.title} source={rv.image} />,
              renderInput(rv.sku, i, 'retailer'),
              supplierVariant.title,
              <AppThumbnail alt={supplierVariant.title} source={supplierVariant.image} />,
              supplierVariant.priceForRetailers,
            ];
          }
        })
        .concat(
          suppliersVariants
            .map((sv, i) =>
              sv.isMissing
                ? [
                    <div className="new">{sv.sku}</div>,
                    '',
                    '',
                    renderInput(sv.sku, i, 'supplier'),
                    sv.title,
                    <AppThumbnail alt={sv.title} source={sv.image} />,
                    sv.priceForRetailers,
                  ]
                : undefined,
            )
            .filter((row) => row) as any[],
        ),
    ];
  }, [retailersVariants, suppliersVariants, renderInput, renderSelect]);

  return (
    <div className="match-variant-list">
      <AppDataTable
        columnContentTypes={['text', 'text', 'text', 'text', 'text', 'text', 'numeric']}
        headings={[
          'Variant SKU',
          'Variant',
          'Picture',
          "You're selling at",
          'Variant',
          'Picture',
          'Suppliers price',
        ]}
        rows={rows}
      />
    </div>
  );
}
