import { createReducer } from 'typesafe-actions';
import { PRODUCTS_PER_PAGE, PRODUCT_INVENTORY } from '../../../../core/constants/product.constants';
import { Paginated } from '../../../../core/helpers/generic.helper';
import { IOption } from '../../../../core/interfaces/IOption';
import { IInventoryProduct } from '../../../../core/interfaces/IProduct';
import { IInventoryFilter } from '../../../../core/interfaces/IProductFilter';
import { IInventoryVariant } from '../../../interfaces/IProduct';
import { RootActions } from '../../root.actions';
import { IProductSectionState } from '../marketplace/marketplace.reducer';
import {
  resetInventoryFiltersAction,
  setInventoryConnectionFilterAction,
  setInventoryFetchingAction,
  setInventoryFilterOptionsAction,
  setInventoryFiltersAction,
  setInventoryLimitFilterAction,
  setInventoryPageFilterAction,
  setInventoryProductListAction,
  setInventoryProductVariantsAction,
  setInventoryStockFilterAction,
  setInventoryQueryFilterAction,
  setInventorySupplierFilterAction,
  setInventoryTypeFilterAction,
  setInventoryVendorFilterAction,
  setRemovedInventoryProductListAction,
  setInventoryFilterAction,
} from './inventory.actions';

type InventoryReducer = IProductSectionState<
  IInventoryProduct,
  IInventoryVariant,
  Paginated<IInventoryFilter>
> & {
  options: { vendors: IOption[]; fulfilledBy: IOption[]; types: IOption[] };
};

const defaultFilterValue: Paginated<IInventoryFilter> = {
  query: '',
  vendors: [],
  types: [],
  fulfilledBy: [],
  hasStock: false,
  productInventory: PRODUCT_INVENTORY.SHOW_ALL,
  page: 0,
  limit: PRODUCTS_PER_PAGE,
};

const initialState: InventoryReducer = {
  options: { vendors: [], fulfilledBy: [], types: [] },
  productList: { productGroups: [], total: 0 },
  filter: defaultFilterValue,
  variants: {},
  fetching: false,
};

export const inventoryReducer = createReducer<InventoryReducer, RootActions>(initialState)
  .handleAction(setInventoryProductListAction, (state, { payload }) => ({
    ...state,
    productList: payload,
  }))
  .handleAction(setRemovedInventoryProductListAction, (state, { payload }) => ({
    ...state,
    productList: {
      ...state.productList,
      productGroups: state.productList.productGroups.filter(
        (pg) => !payload.find((p) => pg.id === p),
      ),
    },
  }))
  .handleAction(setInventoryProductVariantsAction, (state, { payload }) => ({
    ...state,
    variants: {
      ...state.variants,
      [payload.productId]: payload.variants,
    },
  }))
  .handleAction(setInventoryFetchingAction, (state, { payload }) => ({
    ...state,
    fetching: payload,
  }))
  .handleAction(setInventoryFilterOptionsAction, (state, { payload }) => ({
    ...state,
    options: payload,
  }))
  .handleAction(setInventoryFiltersAction, (state, { payload }) => ({
    ...state,
    filter: { ...state.filter, ...payload, page: 0 },
  }))
  .handleAction(resetInventoryFiltersAction, (state) => ({
    ...state,
    filter: defaultFilterValue,
  }))
  .handleAction(setInventoryQueryFilterAction, (state, { payload }) => ({
    ...state,
    filter: { ...state.filter, ...payload, page: 0 },
  }))
  .handleAction(setInventoryVendorFilterAction, (state, { payload }) => ({
    ...state,
    filter: { ...state.filter, ...payload, page: 0 },
  }))
  .handleAction(setInventoryTypeFilterAction, (state, { payload }) => ({
    ...state,
    filter: { ...state.filter, ...payload, page: 0 },
  }))
  .handleAction(setInventoryStockFilterAction, (state, { payload }) => ({
    ...state,
    filter: { ...state.filter, ...payload, page: 0 },
  }))
  .handleAction(setInventorySupplierFilterAction, (state, { payload }) => ({
    ...state,
    filter: { ...state.filter, ...payload, page: 0 },
  }))
  .handleAction(setInventoryConnectionFilterAction, (state, { payload }) => ({
    ...state,
    filter: { ...state.filter, ...payload, page: 0 },
  }))
  .handleAction(setInventoryPageFilterAction, (state, { payload }) => ({
    ...state,
    filter: { ...state.filter, ...payload },
  }))
  .handleAction(setInventoryLimitFilterAction, (state, { payload }) => ({
    ...state,
    filter: { ...state.filter, ...payload },
  }))
  .handleAction(setInventoryFilterAction, (state, { payload }) => ({
    ...state,
    filter: { ...state.filter, ...payload, page: 0 },
  }));
