import { createSelector } from 'reselect';
import { RootState } from '../../reducers';

export const getStatusSelector = createSelector(
  [(state: RootState) => state.status],
  (statusReducer) => statusReducer,
);

export const getUpdatedApiStatusSelector = createSelector(
  [(state: RootState) => state.status],
  ({ updatedApi, permissionUrl }) => ({ updatedApi, permissionUrl }),
);
