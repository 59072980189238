import { Card } from '@shopify/polaris';
import { Form, Formik, FormikState, useFormikContext } from 'formik';
import React, { ReactNode, useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { AppButton } from '../../../../core/components/button/Button';
import { AppCheckbox } from '../../../../core/components/checkbox/checkbox';
import { AppToast } from '../../../../core/components/feedback-indicators/toast/toast';
import { AppModal } from '../../../../core/components/overlays/modal/modal';
import { AppCard } from '../../../../core/components/structure/card/card';
import { AppTabs } from '../../../../core/components/tabs/tabs';
import { AppTextContainer } from '../../../../core/components/text-container/text-container';
import { IImportListProductGroup, importListApi } from '../../../api/import-list.api';
import { IVariantFromImportList } from '../../../interfaces/IProduct';
import {
  deleteProductFromImportListAction,
  setImportListProductAction,
} from '../../../redux/modules/import-list/import-list.actions';
import { SelectedProduct } from '../../layouts/import-list-layout/import-list-layout';
import { ProductDescriptionTab } from './tabs/product-description-tab';
import { ProductDetailsTab } from './tabs/product-details-tab';
import { ProductImagesTab } from './tabs/product-images-tab';
import { ProductVariantsTab } from './tabs/product-variants-tab';

import './product-view.scss';

interface IProductSelectCheckboxProps {
  product: IImportListProductGroup;
  disabled: boolean;
  allSelected: boolean;
  selectedProducts: SelectedProduct[];
  onSelectionHandler: (
    product: {
      id: string;
      dirty: boolean;
    },
    isSelectAll?: boolean,
  ) => void;
}

const ProductSelectCheckbox = ({
  product,
  disabled,
  allSelected,
  selectedProducts,
  onSelectionHandler,
}: IProductSelectCheckboxProps) => {
  const { dirty } = useFormikContext();

  useEffect(() => {
    if (selectedProducts.some((p) => p.id === product.id)) {
      onSelectionHandler({ id: product.id, dirty }, allSelected);
    }
    if (allSelected && !disabled) onSelectionHandler({ id: product.id, dirty }, true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allSelected, disabled, dirty]);

  return (
    <AppCheckbox
      label={'Product'}
      labelHidden
      disabled={disabled}
      checked={selectedProducts.some((p) => p.id === product.id)}
      onChange={() => onSelectionHandler({ id: product.id, dirty })}
    />
  );
};

interface ProductViewProps {
  product: IImportListProductGroup;
  disableAdding: boolean;
  selectAll: boolean;
  selectedProducts: SelectedProduct[];
  onSelectionHandler: (product: { id: string; dirty: boolean }, isSelectAll?: boolean) => void;
  handleAddToInventory: (productIds: { id: string; dirty: boolean }[]) => void;
}

const defaultErrorMessage = 'Error when updating product';

export function ProductView({
  product,
  selectAll,
  disableAdding,
  selectedProducts,
  onSelectionHandler,
  handleAddToInventory,
}: ProductViewProps) {
  const dispatch = useDispatch();

  const [updating, setUpdating] = useState(false);
  const [variantsLoading, setVariantsLoading] = useState(false);
  const [variants, setVariants] = useState<IVariantFromImportList[]>([]);
  const [selectedTab, setSelectedTab] = useState(0);
  const [pendingTab, setPendingTab] = useState<number | undefined>();
  const [openConfirmationModal, setOpenConfirmationModal] = useState(false);
  const [error, setError] = useState<string>('');

  const tabs = useMemo(
    () => [
      {
        id: 'product',
        content: 'Product',
      },
      {
        id: 'description',
        content: 'Description',
      },
      {
        id: 'variants',
        content: (
          <span className="variants-tab">
            Variants <div className="variants-tab__count">{product.variationsNumber}</div>
          </span>
        ),
      },
      {
        id: 'images',
        content: 'Images',
      },
    ],
    [product],
  );

  const handleRemoveProduct = useCallback(
    (productId: string) => {
      dispatch(deleteProductFromImportListAction([productId]));
    },
    [dispatch],
  );

  const updateProductDetails = useCallback(
    (productGroup: IImportListProductGroup) => {
      setUpdating(true);
      importListApi
        .updateProductDetails(productGroup.id, { ...productGroup })
        .then(() => {
          dispatch(setImportListProductAction({ ...productGroup }));
        })
        .catch((error) => {
          setError(defaultErrorMessage);
          console.error(error);
        })
        .finally(() => {
          setUpdating(false);
        });
    },
    [dispatch],
  );

  const updateProductDescription = useCallback(
    (id: string, description: string) => {
      setUpdating(true);
      importListApi
        .updateProductDescription(id, description)
        .then(() => {
          dispatch(setImportListProductAction({ ...product, description }));
        })
        .catch((error) => {
          setError(defaultErrorMessage);
          console.error(error);
        })
        .finally(() => {
          setUpdating(false);
        });
    },
    [dispatch, product],
  );

  const updateProductVariants = useCallback(
    (productGroup: IImportListProductGroup) => {
      setUpdating(true);
      importListApi
        .updateProductVariants(
          productGroup.variants?.map(({ id, price, compareAtPrice, manuallySetPrice }) => ({
            id,
            price,
            compareAtPrice,
            manuallySetPrice,
          })) || [],
        )
        .then(() => {
          dispatch(setImportListProductAction({ ...productGroup }));
          setVariants(
            productGroup.variants?.map((v) => ({
              ...v,
              compareAtPrice: v.compareAtPrice || 0,
              MSRP: v.MSRP || 0,
              priceForRetailers: v.priceForRetailers || 0,
            })) || variants,
          );
        })
        .catch((error) => {
          setError(defaultErrorMessage);
          console.error(error);
        })
        .finally(() => {
          setUpdating(false);
        });
    },
    [dispatch, variants],
  );

  const updateProductImages = useCallback(
    (productGroup: IImportListProductGroup) => {
      setUpdating(true);
      importListApi
        .updateProductImages(productGroup.id, productGroup.images)
        .then(() => {
          dispatch(setImportListProductAction({ ...productGroup }));
        })
        .catch((error) => {
          setError(defaultErrorMessage);
          console.error(error);
        })
        .finally(() => {
          setUpdating(false);
        });
    },
    [dispatch],
  );

  const handleTabSubmit = useCallback(
    (productGroup: IImportListProductGroup) => {
      switch (selectedTab) {
        case 0:
          updateProductDetails(productGroup);
          break;
        case 1:
          updateProductDescription(productGroup.id, productGroup.description);
          break;
        case 2:
          updateProductVariants(productGroup);
          break;
        case 3:
          updateProductImages(productGroup);
          break;
      }
    },
    [
      selectedTab,
      updateProductDetails,
      updateProductDescription,
      updateProductVariants,
      updateProductImages,
    ],
  );

  const getVariants = useCallback((productId: string) => {
    setVariantsLoading(true);
    importListApi
      .getImportListVariants(productId)
      .then((res) => {
        setVariants(
          res.data.map((v) => ({
            ...v,
            compareAtPrice: v.compareAtPrice || 0,
            MSRP: v.MSRP || 0,
            priceForRetailers: v.priceForRetailers || 0,
          })),
        );
      })
      .catch((error) => {
        console.error('Error when get import list variants', error);
      })
      .finally(() => {
        setVariantsLoading(false);
      });
  }, []);

  const tabContent: (
    values: IImportListProductGroup,
    setFieldValue: (field: string, value: any) => void,
    resetForm: (nextState?: Partial<FormikState<IImportListProductGroup>> | undefined) => void,
  ) => { [key: number]: ReactNode } = useCallback(
    (values: IImportListProductGroup, setFieldValue: (field: string, value: any) => void) => ({
      0: <ProductDetailsTab title={product.title} values={values} setFieldValue={setFieldValue} />,
      1: <ProductDescriptionTab description={product.description} setFieldValue={setFieldValue} />,
      2: (
        <ProductVariantsTab
          variantsLength={product.variationsNumber}
          variants={variants}
          setFieldValue={setFieldValue}
          loading={variantsLoading}
        />
      ),
      3: <ProductImagesTab images={values.images} setFieldValue={setFieldValue} />,
    }),
    [product.description, product.title, product.variationsNumber, variants, variantsLoading],
  );

  const handleConfirmationModalClose = () => {
    setPendingTab(undefined);
    setOpenConfirmationModal(false);
  };

  return (
    <div className="product-view">
      <AppCard>
        <Formik
          initialValues={{ ...product, ...(variants.length && { variants }) }}
          onSubmit={handleTabSubmit}
          enableReinitialize
        >
          {({ values, setFieldValue, resetForm, dirty }) => (
            <Form name={'product'}>
              <AppTabs
                tabs={tabs}
                selected={selectedTab}
                onSelect={(i) => {
                  if (!dirty) {
                    setSelectedTab(i);
                    if (tabs[i].id === 'variants' && !variants.length) {
                      getVariants(product.id);
                    }
                    return;
                  }
                  setPendingTab(i);
                  setOpenConfirmationModal(true);
                }}
              >
                {tabContent(values, setFieldValue, resetForm)[selectedTab]}
                <Card.Section>
                  <div className="product-view__actions">
                    <AppButton onClick={() => handleRemoveProduct(product.id)}>
                      Remove from Import List
                    </AppButton>
                    <AppButton disabled={!dirty} submit loading={updating}>
                      Save
                    </AppButton>
                    <AppButton
                      disabled={disableAdding}
                      primary
                      onClick={() => {
                        handleAddToInventory([{ id: product.id, dirty }]);
                      }}
                    >
                      Push To Store
                    </AppButton>
                  </div>
                </Card.Section>
              </AppTabs>
              <ProductSelectCheckbox
                product={product}
                disabled={disableAdding}
                allSelected={selectAll}
                selectedProducts={selectedProducts}
                onSelectionHandler={onSelectionHandler}
              />
              {openConfirmationModal && (
                <AppModal
                  open={openConfirmationModal}
                  onClose={handleConfirmationModalClose}
                  title="Unsaved changes will be lost"
                  primaryAction={{
                    content: 'Stay',
                    onAction: handleConfirmationModalClose,
                  }}
                  secondaryActions={[
                    {
                      content: 'Leave tab',
                      onAction: () => {
                        pendingTab !== undefined && setSelectedTab(pendingTab);
                        if (pendingTab && tabs[pendingTab].id === 'variants' && !variants.length) {
                          getVariants(product.id);
                        }
                        handleConfirmationModalClose();
                        resetForm();
                      },
                    },
                  ]}
                >
                  <AppTextContainer>
                    You've made changes to your product. Please save them before leaving, or they
                    will be lost. Are you sure you want to switch the tab?
                  </AppTextContainer>
                </AppModal>
              )}
              {error && <AppToast onDismiss={() => setError('')} content={error} error />}
            </Form>
          )}
        </Formik>
      </AppCard>
    </div>
  );
}
