import React, { ReactText } from 'react';
import { AppCard } from '../card';
import './dashboard-card.scss';
import { DisableableAction } from '@shopify/polaris';
import { AppResourceList } from '../../../resource-list/ResourceList';
import { AppResourceItem } from '../../../resource-list/ResourceItem';

interface IDashboardCard {
  title: string;
  cardAction?: DisableableAction[];
  content: IContentItem[];
  loading?: boolean;
}

export const DashboardCard: React.FunctionComponent<IDashboardCard> = ({
  title,
  cardAction,
  content,
  loading,
}) => {
  return (
    <AppCard
      sectioned
      actions={cardAction}
      title={title}
      children={
        <AppResourceList
          loading={loading}
          items={content}
          renderItem={(item: IContentItem, id, index) => {
            const { value, title } = item;
            return (
              <AppResourceItem noMedia={true} id={id} onClick={() => {}}>
                <div className="dashboard_card_summary_wrapper">
                  <div className="dashboard_card_title">{title}</div>
                  <div className="dashboard_card_value">{value}</div>
                </div>
              </AppResourceItem>
            );
          }}
        />
      }
    />
  );
};

interface IContentItem {
  title: string;
  value: ReactText;
}
