import { createSelector } from 'reselect';
import { RootState } from '../../../../shopify-supplier/redux/reducers';

export const isAuthenticatedSelector = createSelector(
  [(state: RootState) => state.auth.accessToken],
  (accessToken) => !!accessToken,
);

export const isLoggedSelector = createSelector(
  [(state: RootState) => state.auth.isLogged],
  (isLogged) => isLogged,
);

export const getHostSelector = createSelector(
  [(state: RootState) => state.auth.host],
  (host) => host,
);

export const getShopSelector = createSelector(
  [(state: RootState) => state.auth.shop],
  (shop) => shop,
);

export const getRolesSelector = createSelector(
  [(state: RootState) => state.auth.roles],
  (roles) => roles,
);

export const getInactiveStoreSelector = createSelector(
  [(state: RootState) => state.auth.inactiveStore],
  (inactiveStore) => inactiveStore,
);
