import { AppCheckbox } from '../../../../checkbox/checkbox';
import { FilterElementProps } from './filter-elements.interface';

export const STOCK_FILTER_KEY = 'hasStock';
const STOCK_FILTER_LABEL = 'Property';
const STOCK_FILTER_TITLE = 'Hide out of stock';

export const StockFilter = ({ value, onChange }: FilterElementProps<boolean>) => ({
  key: STOCK_FILTER_KEY,
  label: STOCK_FILTER_LABEL,
  shortcut: true,
  hideClearButton: true,
  filter: <AppCheckbox label={STOCK_FILTER_TITLE} checked={value} onChange={onChange} />,
});
