import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AccountDetails } from '../../components/account-details/account-details';
import { AccountDetailsSkeleton } from '../../components/account-details/account-details-skeleton';
import { AppToast } from '../../components/feedback-indicators/toast/toast';
import { AppPage } from '../../components/structure/page/page';
import { IAccount } from '../../interfaces/IAccount';
import {
  getAccountInfoAction,
  getManagePaymentSourcesURLAction,
  hideSuccessUpdateToastAction,
  postAccountInfoAction,
} from '../../redux/modules/account/account.actions';
import {
  getAccountInfoFetchingSelector,
  getAccountInfoSelector,
  showSuccessUpdateToastSelector,
} from '../../redux/modules/account/account.selectors';
import './account-layout.scss';

interface IAccountLayoutProps {}

export function AccountLayout(props: IAccountLayoutProps) {
  const dispatch = useDispatch();
  const accountInfo = useSelector(getAccountInfoSelector);
  const toastActive = useSelector(showSuccessUpdateToastSelector);
  const fetching = useSelector(getAccountInfoFetchingSelector);
  const [updating, setUpdating] = useState(false);

  useEffect(() => {
    if (toastActive) {
      setUpdating(false);
    }
  }, [toastActive]);

  useEffect(() => {
    dispatch(getManagePaymentSourcesURLAction());
    dispatch(getAccountInfoAction());
  }, [dispatch]);

  const submitAccountInfo = useCallback(
    (values: IAccount) => {
      setUpdating(true);
      dispatch(postAccountInfoAction(values));
    },
    [dispatch],
  );

  const hideToast = useCallback(() => {
    dispatch(hideSuccessUpdateToastAction());
  }, [dispatch]);

  const toastMarkup = useMemo(
    () => (toastActive ? <AppToast onDismiss={hideToast} content="Changes saved" /> : null),
    [toastActive, hideToast],
  );

  return (
    <React.Fragment>
      <div className="account-layout">
        {fetching ? (
          <AccountDetailsSkeleton />
        ) : (
          <AppPage title="Account">
            <AccountDetails
              accountInfo={accountInfo}
              submitAccountInfo={submitAccountInfo}
              updating={updating}
            />
          </AppPage>
        )}
        {toastMarkup}
      </div>
    </React.Fragment>
  );
}
