import { all, fork } from 'redux-saga/effects';
import { coreSaga } from '../../core/redux/sagas';
import { variantSyncSaga } from './modules/variant-sync/variant-sync.sagas';
import { importListSaga } from './modules/import-list/import-list.sagas';
import { marketplaceSaga } from './modules/marketplace/marketplace.sagas';
import { inventorySaga } from './modules/inventory/inventory.sagas';
import { orderSaga } from './modules/orders/orders.saga';
import { dashboardSaga } from './modules/dashboard/dashboard.saga';
import { preferencesSaga } from './modules/preferences/preferences.saga';
import { productSyncSaga } from './modules/sync/sync.saga';
import { statusSaga } from './modules/status/status.saga';
import { plansSaga } from './modules/plans/plans.saga';

export function* rootSaga() {
  yield all([
    fork(coreSaga),
    fork(variantSyncSaga),
    fork(importListSaga),
    fork(marketplaceSaga),
    fork(inventorySaga),
    fork(orderSaga),
    fork(dashboardSaga),
    fork(preferencesSaga),
    fork(productSyncSaga),
    fork(statusSaga),
    fork(plansSaga),
  ]);
}
