import { EmptySearchResult, useIndexResourceState } from '@shopify/polaris';
import { IndexTableHeading } from '@shopify/polaris/build/ts/latest/src/components/IndexTable';
import { NonEmptyArray } from '@shopify/polaris/build/ts/latest/src/types';
import { SelectionType } from '@shopify/polaris/build/ts/latest/src/utilities/index-provider/types';
import { FC, Fragment, useCallback, useEffect, useState } from 'react';

import { IProduct, PRODUCT_GROUP_CONNECTION_TYPE } from '../../interfaces/IProduct';
import { AppIndexTable } from '../index-table/index-table';
import { PagePagination } from '../pagination/redux-pagination/page-pagination';
import { IAction } from '../resource-list/ResourceItem';
import { ProductListBaseProps, ProductRowBaseProps } from './product-list.interfaces';
import './product-list.scss';
import { VariantsCollapsibleRow } from './table-elements/rows/variants-collapsible-row';

type IProductsListProps<T extends IProduct> = ProductListBaseProps<T> & {
  headings: NonEmptyArray<IndexTableHeading>;
  listActions: IAction[];
  row: FC<ProductRowBaseProps<T>>;
  disableAdding?: boolean;
  filter: FC;
  colSpan?: number;
  isVariantsSyncEnable?: boolean;
};

export function ProductList<T extends IProduct>({
  headings,
  colSpan,
  products,
  fetching,
  onProductVariantsExpand,
  renderVariantList,
  listActions,
  allSelectedToggle,
  onSelectionChange,
  disableAdding,
  totalPageCount,
  onPageChange,
  row: ProductListRow,
  filter: ProductListFilter,
  currentPage,
  isVariantsSyncEnable,
}: IProductsListProps<T>) {
  const [expandedVariants, setExpandedVariants] = useState<string[]>([]);

  useEffect(() => {
    // Collapse variants on products change/on form submit
    setExpandedVariants([]);
  }, [products]);

  const { selectedResources, allResourcesSelected, handleSelectionChange } =
    useIndexResourceState(products);

  const toggleExpansion = useCallback(
    (productId: string) => {
      if (expandedVariants.includes(productId))
        setExpandedVariants((items) => items.filter((i) => i !== productId));
      else {
        onProductVariantsExpand(productId);
        setExpandedVariants((items) => items.concat([productId]));
      }
    },
    [expandedVariants, onProductVariantsExpand],
  );

  const handlePageChange = useCallback(
    (page: number) => {
      onPageChange(page);
      handleSelectionChange('page' as SelectionType, false);
    },
    [handleSelectionChange, onPageChange],
  );

  useEffect(() => {
    onSelectionChange(selectedResources);
  }, [selectedResources, onSelectionChange]);

  const selectionChanged = (
    selectionType: SelectionType,
    isSelecting: boolean,
    selection?: any,
  ) => {
    // when selecting a single item, ignore if limit is reached (disableAdding) or if the item is processing
    if (
      selectionType === 'single' &&
      isSelecting &&
      (disableAdding || products.some((p) => p.id === selection && p.processing))
    ) {
      return;
    }
    if (selectionType === 'all') allSelectedToggle(true);
    // if all items are selected, any other selection operation will toggle this flag
    else if (allResourcesSelected) allSelectedToggle(false);
    handleSelectionChange(selectionType, isSelecting, selection);
  };

  // handleSelectionChange in deps cause side effect
  useEffect(() => {
    handleSelectionChange('page' as SelectionType, false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetching]);

  return (
    <>
      <ProductListFilter />
      {products.length ? (
        <AppIndexTable
          resourceName={{ singular: 'product', plural: 'products' }}
          headings={headings}
          itemCount={products.length}
          onSelectionChange={selectionChanged}
          selectedItemsCount={allResourcesSelected ? 'All' : selectedResources.length}
          promotedBulkActions={listActions.map((la) => ({
            ...la,
            onAction: () => {
              la.onAction && la.onAction();
            },
          }))}
          hasMoreItems={totalPageCount > 1}
          loading={fetching}
        >
          {products.map((product: T, index: number) => (
            <Fragment key={product.id}>
              <ProductListRow
                product={product}
                index={index}
                selected={selectedResources.includes(product.id)}
                expandedVariants={expandedVariants.includes(product.id)}
                toggleExpansion={() => toggleExpansion(product.id)}
              />
              <VariantsCollapsibleRow
                id={product.id}
                expanded={expandedVariants.includes(product.id)}
                renderVariantList={() =>
                  renderVariantList(
                    product.id,
                    !!isVariantsSyncEnable &&
                      product?.connectionType === PRODUCT_GROUP_CONNECTION_TYPE.ADDED_GROUP,
                  )
                }
                colSpan={colSpan}
              />
            </Fragment>
          ))}
        </AppIndexTable>
      ) : (
        <EmptySearchResult
          title={'No products found'}
          description={"If you're using search or a filter, try changing it"}
          withIllustration
        />
      )}

      <PagePagination
        totalPageCount={totalPageCount}
        onPageChange={handlePageChange}
        currentPage={currentPage}
      />
    </>
  );
}
