import { Form, Formik, FormikState } from 'formik';
import { toLower } from 'lodash';
import { ReactNode, useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { AppToast } from '../../../../core/components/feedback-indicators/toast/toast';
import { AppContextualSaveBar } from '../../../../core/components/forms/contextual-save-button/contextual-save-button';
import { AppModal } from '../../../../core/components/overlays/modal/modal';
import { AppPage } from '../../../../core/components/structure/page/page';
import { AppTabs } from '../../../../core/components/tabs/tabs';
import { AppTextContainer } from '../../../../core/components/text-container/text-container';
import { ICurrency } from '../../../../core/interfaces/ICurrency';
import { preferencesApi } from '../../../api/preferences.api';
import { DEFAULT_CANCELLATION_ACCEPT } from '../../../constants/preferences.constants';
import {
  ADJUSTMENT_TYPE,
  INotificationsPreferences,
  IOrderPreferences,
  IPayoutPreferences,
  IPreferences,
  IProductPreferences,
  IProfilePreferences,
  IShippingPreferences,
  IVisibilityPreferences,
  PAYOUT_METHOD,
} from '../../../interfaces/IPreferences';
import {
  hideAddressValidationFailedToastAction,
  hideAddressValidationSuccessoastAction,
  setPreferencesAction,
  showAddressValidationFailedToastAction,
} from '../../../redux/modules/preferences/preferences.actions';
import { addressValidationToastsSelector } from '../../../redux/modules/preferences/preferences.selectors';
import { getStatusAction } from '../../../redux/modules/status/status.actions';
import './preferences-list.scss';
import { MarketplaceSection } from './sections/marketplace-section/marketplace-section';
import { NotificationsSection } from './sections/notifications-section';
import { OrdersSection } from './sections/orders-section';
import { PayoutSection } from './sections/payout-section';
import { PrivateNetworkSection } from './sections/private-network-section';
import { ProductsSection } from './sections/products-section';
import { ProfileSection } from './sections/profile-section';
import { ShippingSection } from './sections/shipping-section/shipping-section';

interface IPreferencesListProps {
  preferences: IPreferences;
  currency: ICurrency;
}

const defaultErrorMessage = 'Error when updating settings';
const licenseRequiredMessage = 'Proof of license required';

const tabs = [
  { id: 'profile', content: 'Profile' },
  { id: 'marketplace', content: 'Marketplace' },
  { id: 'private-network', content: 'Retailers' },
  { id: 'products', content: 'Products' },
  { id: 'shipping', content: 'Shipping' },
  { id: 'order', content: 'Orders' },
  { id: 'notification', content: 'Notifications' },
  { id: 'payout', content: 'Payouts' },
];

const findTabIndexByQuery = (tab?: string): number => {
  if (!tab) return 0;
  const index = tabs.findIndex((t) => toLower(t.content) === tab);
  if (index === -1) return 0;
  return index;
};

const mapProductPreferencesOnUpdate = (preference: IProductPreferences) => ({
  dropShipCost: {
    ...preference.dropShipCost,
    adjustment: preference.dropShipCost.adjustment?.amount
      ? {
          amount: preference.dropShipCost.adjustment.amount,
          type: preference.dropShipCost.adjustment.type || ADJUSTMENT_TYPE.PERCENT,
        }
      : undefined,
    salePriceAdjustment:
      preference.dropShipCost.useSalePrice && preference.dropShipCost.salePriceAdjustment?.amount
        ? {
            amount: preference.dropShipCost.salePriceAdjustment.amount,
            type: preference.dropShipCost.salePriceAdjustment.type || ADJUSTMENT_TYPE.PERCENT,
          }
        : undefined,
  },
  MSRP: {
    ...preference.MSRP,
    adjustment: preference.MSRP.adjustment?.amount
      ? {
          amount: preference.MSRP.adjustment.amount,
          type: preference.MSRP.adjustment.type || ADJUSTMENT_TYPE.PERCENT,
        }
      : undefined,
    salePriceAdjustment:
      preference.MSRP.useSalePrice && preference.MSRP.salePriceAdjustment?.amount
        ? {
            amount: preference.MSRP.salePriceAdjustment.amount,
            type: preference.MSRP.salePriceAdjustment.type || ADJUSTMENT_TYPE.PERCENT,
          }
        : undefined,
  },
});

export function PreferencesList({ preferences, currency }: IPreferencesListProps) {
  const dispatch = useDispatch();
  const { search } = useLocation();
  const history = useHistory();
  const parsedQuery = new URLSearchParams(search);
  const tab = parsedQuery.get('tab') as string;

  const [selectedTab, setSelectedTab] = useState<number>(findTabIndexByQuery(tab));
  const [pendingTab, setPendingTab] = useState<number | undefined>();
  const [openConfirmationModal, setOpenConfirmationModal] = useState(false);
  const [updating, setUpdating] = useState(false);
  const [updated, setUpdated] = useState(false);
  const [error, setError] = useState<string>('');

  useEffect(() => {
    setSelectedTab(findTabIndexByQuery(tab));
  }, [tab]);

  const [hasAutoCancellationAcceptThreshold, setHasAutoCancellationAcceptThreshold] = useState(
    preferences.order.cancellationAcceptThreshold !== undefined,
  );

  const { failedValidation, successValidation } = useSelector(addressValidationToastsSelector);

  const hideAddressValidationFailedToast = useCallback(
    () => dispatch(hideAddressValidationFailedToastAction()),
    [dispatch],
  );

  const hideAddressValidationSuccessToast = useCallback(
    () => dispatch(hideAddressValidationSuccessoastAction()),
    [dispatch],
  );

  const addressValidationFailedToastMarkup = useMemo(
    () =>
      failedValidation && (
        <AppToast
          onDismiss={hideAddressValidationFailedToast}
          content="Address failed validation - please try another address."
          error
        />
      ),
    [hideAddressValidationFailedToast, failedValidation],
  );

  const addressValidationSuccessToastMarkup = useMemo(
    () =>
      successValidation && (
        <AppToast onDismiss={hideAddressValidationSuccessToast} content="Validation passed" />
      ),
    [hideAddressValidationSuccessToast, successValidation],
  );

  const updateProfilePreference = useCallback(
    (preference: IProfilePreferences) => {
      if (preference.cannabisLicensed && !preference.licenseFile) {
        setError(licenseRequiredMessage);
        return;
      }

      setUpdating(true);
      preferencesApi
        .updateProfilePreferences({
          ...preference,
          logo: typeof preference.logo !== 'object' ? preference.logo : undefined,
          licenseFile: preferences.profile.licenseFile,
        })
        .then(() => {
          const data = new FormData();

          if (typeof preference.logo === 'object') data.append('logo', preference.logo);
          if (typeof preference.licenseFile === 'object')
            data.append('licenseFile', preference.licenseFile);

          preferencesApi
            .updateProfileFilesPreferences(data)
            .then(() => {
              dispatch(
                setPreferencesAction({
                  ...preferences,
                  profile: preference,
                }),
              );
            })
            .catch((error) => {
              throw new Error(error);
            })
            .finally(() => {
              setUpdated(true);
              setUpdating(false);
            });
        })
        .catch((error) => {
          setError(defaultErrorMessage);
          console.error(error);
        });
    },
    [dispatch, preferences],
  );

  const updateMarketplacePreference = useCallback(
    (preference: IVisibilityPreferences) => {
      setUpdating(true);
      preferencesApi
        .updateMarketplacePreferences(preference.marketplace)
        .then(() => {
          dispatch(setPreferencesAction({ ...preferences, visibility: preference }));
          setUpdated(true);
        })
        .catch((error) => {
          setError(defaultErrorMessage);
          console.error(error);
        })
        .finally(() => {
          setUpdating(false);
        });
    },
    [dispatch, preferences],
  );

  const updatePrivateNetworkPreference = useCallback(
    (preference: IVisibilityPreferences) => {
      setUpdating(true);
      preferencesApi
        .updatePrivateNetworkPreferences(preference)
        .then(() => {
          dispatch(setPreferencesAction({ ...preferences, visibility: preference }));
          setUpdated(true);
        })
        .catch((error) => {
          setError(error.error);
          console.error(error.error);
        })
        .finally(() => {
          setUpdating(false);
        });
    },
    [dispatch, preferences],
  );

  const updateProductPreference = useCallback(
    (preference: IProductPreferences) => {
      setUpdating(true);
      preferencesApi
        .updateProductPreferences(mapProductPreferencesOnUpdate(preference))
        .then(() => {
          dispatch(setPreferencesAction({ ...preferences, product: preference }));
          setUpdated(true);
          dispatch(getStatusAction());
        })
        .catch((error) => {
          setError(defaultErrorMessage);
          console.error(error);
        })
        .finally(() => {
          setUpdating(false);
        });
    },
    [dispatch, preferences],
  );

  const updateShippingPreference = useCallback(
    (preference: IShippingPreferences) => {
      setUpdating(true);
      const useCustomWarehouseAddress = preference.useCustomWarehouseAddress === 'use custom';
      const useCustomFallbackAddress = preference.useCustomFallbackAddress === 'use custom';
      preferencesApi
        .updateShippingPreferences({
          ...preference,
          useCustomWarehouseAddress,
          warehouseAddressValid: useCustomWarehouseAddress,
          warehouseAddress: useCustomWarehouseAddress
            ? {
                ...preference.warehouseAddress,
                address2: preference.warehouseAddress.address2 || undefined,
                province: preference.warehouseAddress.province || undefined,
                provinceCode: preference.warehouseAddress.provinceCode || undefined,
              }
            : undefined,
          useCustomFallbackAddress,
          fallbackAddressValid: useCustomFallbackAddress,
          fallbackAddress: useCustomFallbackAddress
            ? {
                ...preference.fallbackAddress,
                address2: preference.fallbackAddress.address2 || undefined,
                province: preference.fallbackAddress.province || undefined,
                provinceCode: preference.fallbackAddress.provinceCode || undefined,
              }
            : undefined,
          handlingFee: preference.handlingFee ? +preference.handlingFee : undefined,
          shippingTypesMapping: preference.shippingTypesMapping.map((stm) => ({
            ...stm,
            startDeliveryTime: stm.startDeliveryTime ? +stm.startDeliveryTime : undefined,
            finishDeliveryTime: stm.finishDeliveryTime ? +stm.finishDeliveryTime : undefined,
          })),
        })
        .then(({ data }) => {
          dispatch(setPreferencesAction({ ...preferences, shipping: preference }));
          if (data.isValid) {
            setUpdated(true);
          } else {
            dispatch(showAddressValidationFailedToastAction());
          }
        })
        .catch((error) => {
          setError(defaultErrorMessage);
          console.error(error);
        })
        .finally(() => {
          setUpdating(false);
        });
    },
    [dispatch, preferences],
  );

  const updateOrderPreference = useCallback(
    (preference: IOrderPreferences) => {
      setUpdating(true);
      preferencesApi
        .updateOrderPreferences({
          ...preference,
          cancellationAcceptThreshold:
            preference.defaultCancellationAccept === DEFAULT_CANCELLATION_ACCEPT.ACCEPT &&
            hasAutoCancellationAcceptThreshold
              ? preference.cancellationAcceptThreshold
              : undefined,
        })
        .then(() => {
          dispatch(setPreferencesAction({ ...preferences, order: preference }));
          setUpdated(true);
        })
        .catch((error) => {
          setError(defaultErrorMessage);
          console.error(error);
        })
        .finally(() => {
          setUpdating(false);
        });
    },
    [dispatch, hasAutoCancellationAcceptThreshold, preferences],
  );

  const updateNotificationPreference = useCallback(
    (preference: INotificationsPreferences) => {
      setUpdating(true);
      preferencesApi
        .updateNotificationPreferences(preference)
        .then(() => {
          dispatch(setPreferencesAction({ ...preferences, notifications: preference }));
          setUpdated(true);
        })
        .catch((error) => {
          setError(defaultErrorMessage);
          console.error(error);
        })
        .finally(() => {
          setUpdating(false);
        });
    },
    [dispatch, preferences],
  );

  const updatePayoutPreference = useCallback(
    async (preference: IPayoutPreferences) => {
      if (
        preference.payoutMethod === PAYOUT_METHOD.BANK_ACH_TRANSFER &&
        !preference?.achDetails?.readTermsOfService
      )
        return;

      setUpdating(true);
      const data = new FormData();

      if (preference.achDetails?.w9Form && typeof preference?.achDetails?.w9Form.src === 'object')
        data.append('w9Form', preference.achDetails.w9Form.src);
      if (
        preference.achDetails?.voidBankCheck &&
        typeof preference?.achDetails?.voidBankCheck.src === 'object'
      )
        data.append('voidBankCheck', preference.achDetails.voidBankCheck.src);

      const createdFiles =
        data?.entries()?.next()?.value && (await preferencesApi.updatePayoutPreferencesFiles(data));

      const preferencesToUpdate = {
        ...preference,
        ...(preference?.achDetails && {
          achDetails: {
            ...preference.achDetails,
            ...(createdFiles?.data?.w9Form && {
              w9Form: { ...preference.achDetails.w9Form, src: createdFiles.data.w9Form },
            }),
            ...(createdFiles?.data?.voidBankCheck && {
              voidBankCheck: {
                ...preference.achDetails.voidBankCheck,
                src: createdFiles.data.voidBankCheck,
              },
            }),
          },
        }),
      };

      preferencesApi
        .updatePayoutPreferences(preferencesToUpdate)
        .then(() => {
          dispatch(setPreferencesAction({ ...preferences, payout: preferencesToUpdate }));
          setUpdated(true);
        })
        .catch((error) => {
          setError(defaultErrorMessage);
          console.error(error);
        })
        .finally(() => {
          setUpdating(false);
        });
    },
    [dispatch, preferences],
  );

  const handleTabSubmit = useCallback(
    (preferences: IPreferences) => {
      switch (selectedTab) {
        case 0:
          updateProfilePreference(preferences.profile);
          break;
        case 1:
          updateMarketplacePreference(preferences.visibility);
          break;
        case 2:
          updatePrivateNetworkPreference(preferences.visibility);
          break;
        case 3:
          updateProductPreference(preferences.product);
          break;
        case 4:
          updateShippingPreference(preferences.shipping);
          break;
        case 5:
          updateOrderPreference(preferences.order);
          break;
        case 6:
          updateNotificationPreference(preferences.notifications);
          break;
        case 7:
          updatePayoutPreference(preferences.payout);
          break;
      }
    },
    [
      updateProfilePreference,
      updateMarketplacePreference,
      updatePrivateNetworkPreference,
      updateNotificationPreference,
      updateOrderPreference,
      updateProductPreference,
      updateShippingPreference,
      updatePayoutPreference,
      selectedTab,
    ],
  );

  const tabContent: (
    values: IPreferences,
    setFieldValue: (field: string, value: any) => void,
    resetForm: (nextState?: Partial<FormikState<IPreferences>> | undefined) => void,
  ) => { [key: number]: ReactNode } = useCallback(
    (
      values: IPreferences,
      setFieldValue: (field: string, value: any) => void,
      resetForm: (nextState?: Partial<FormikState<IPreferences>> | undefined) => void,
    ) => ({
      0: <ProfileSection values={values} setFieldValue={setFieldValue} />,
      1: <MarketplaceSection values={values} />,
      2: <PrivateNetworkSection values={values} setFieldValue={setFieldValue} />,
      3: <ProductsSection values={values} updating={updating} currency={currency} />,
      4: <ShippingSection values={values} setFieldValue={setFieldValue} />,
      5: (
        <OrdersSection
          values={values}
          hasThreshold={hasAutoCancellationAcceptThreshold}
          onThresholdChange={setHasAutoCancellationAcceptThreshold}
          setFieldValue={setFieldValue}
        />
      ),
      6: <NotificationsSection values={values} updating={updating} currency={currency} />,
      7: (
        <PayoutSection
          initialValues={preferences}
          values={values}
          updating={updating}
          currency={currency}
          setFieldValue={setFieldValue}
          resetForm={resetForm}
        />
      ),
    }),
    [preferences, currency, hasAutoCancellationAcceptThreshold, updating],
  );

  const handleModalClose = () => {
    setPendingTab(undefined);
    setOpenConfirmationModal(false);
  };

  return (
    <AppPage title="Settings">
      <Formik
        initialValues={preferences}
        onSubmit={(values) => handleTabSubmit(values)}
        enableReinitialize
      >
        {({ submitForm, resetForm, dirty, values, setFieldValue }) => (
          <Form name={'settings'} className="settings-layout">
            {(dirty || updating) && (
              <AppContextualSaveBar
                message="Unsaved changes"
                saveAction={{
                  onAction: submitForm,
                  loading: updating,
                }}
                discardAction={{
                  onAction: resetForm,
                  loading: updating,
                }}
              />
            )}
            <AppTabs
              tabs={tabs}
              selected={selectedTab}
              onSelect={(i) => {
                if (!dirty) {
                  setSelectedTab(i);
                  history.push({ search: `?tab=${toLower(tabs[i].content)}` });
                  return;
                }
                setPendingTab(i);
                setOpenConfirmationModal(true);
              }}
            >
              <div className="setup-tab-content">
                {tabContent(values, setFieldValue, resetForm as any)[selectedTab]}
              </div>
            </AppTabs>
            {openConfirmationModal && (
              <AppModal
                open={openConfirmationModal}
                onClose={handleModalClose}
                title="Unsaved changes will be lost"
                primaryAction={{
                  content: 'Stay',
                  onAction: handleModalClose,
                }}
                secondaryActions={[
                  {
                    content: 'Leave tab',
                    onAction: () => {
                      pendingTab !== undefined && setSelectedTab(pendingTab);
                      handleModalClose();
                      resetForm();
                    },
                  },
                ]}
              >
                <AppTextContainer>
                  You've made changes to your preferences. Please save them before leaving, or they
                  will be lost. Are you sure you want to switch the tab?
                </AppTextContainer>
              </AppModal>
            )}
          </Form>
        )}
      </Formik>
      {updated && <AppToast onDismiss={() => setUpdated(false)} content="Changes saved" />}
      {error && <AppToast onDismiss={() => setError('')} content={error} error />}
      {addressValidationFailedToastMarkup}
      {addressValidationSuccessToastMarkup}
    </AppPage>
  );
}
