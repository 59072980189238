import { Modal, ModalProps } from '@shopify/polaris';
import React from 'react';

type IModalProps = ModalProps;

export function AppModal({ children, ...props }: IModalProps) {
  return (
    <Modal {...props}>
      <Modal.Section children={children} />
    </Modal>
  );
}
