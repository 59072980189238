import { TextField, TextFieldProps } from '@shopify/polaris';
import { Field, useFormikContext } from 'formik';
import React, { useCallback, useEffect, useMemo } from 'react';

type ITextFieldProps = Omit<TextFieldProps, 'id' | 'name' | 'value' | 'label' | 'autoComplete'> & {
  name: string;
  label?: React.ReactNode;
  autoComplete?: string;
  validate?: (value: string) => string | undefined | Promise<string | undefined>;
};

export function AppControlledTextField({
  name,
  validate,
  label = '',
  autoComplete = 'off',
  onBlur,
  onChange,
  ...props
}: ITextFieldProps) {
  const {
    registerField,
    unregisterField,
    getFieldProps,
    getFieldMeta,
    setFieldValue,
    setFieldError,
  } = useFormikContext();

  useEffect(() => {
    if (name) registerField(name, { validate });
    return () => {
      if (name) unregisterField(name);
    };
  }, [name, validate, registerField, unregisterField]);

  const field = getFieldProps({ name });
  const meta = getFieldMeta(name);

  const handleChange = useCallback(
    (value, id) => {
      setFieldValue(id, value, true);
      if (onChange) onChange(value, id);
    },
    [onChange, setFieldValue],
  );

  const handleBlur = useCallback(() => {
    field.onBlur({ target: { name } });
    if (onBlur) onBlur();
  }, [field, name, onBlur]);

  const handleFocus = useCallback(() => {
    setFieldError(name, '');
  }, [name, setFieldError]);

  const error = useMemo(() => {
    if (meta.error && meta.touched) {
      return meta.error;
    }
  }, [meta.error, meta.touched]);

  return (
    <Field name={name} validate={validate} type={props.type}>
      {({ value }) => (
        <TextField
          {...props}
          label={label}
          autoComplete={autoComplete}
          id={name}
          value={field.value}
          onChange={handleChange}
          onBlur={handleBlur}
          onFocus={handleFocus}
          error={error}
        />
      )}
    </Field>
  );
}
