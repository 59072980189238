import { AxiosResponse } from 'axios';
import { axiosInstance } from '../../core/api/base.api';
import { IRetailerStatus } from '../interfaces/IPlan';

class StatusApi {
  private backend = process.env.REACT_APP_BACK_END_URL as string;
  private rootPath = '/api/retailer/shopify/status';

  async getPlanLimitsStatus(): Promise<AxiosResponse<IStatusResponse>> {
    return await axiosInstance.get(`${this.backend}${this.rootPath}`);
  }

  async disablePromotionBanner(): Promise<AxiosResponse<IStatusResponse>> {
    return await axiosInstance.patch(`${this.backend}${this.rootPath}/disable-promotion-banner`);
  }
}

export const statusApi = new StatusApi();

type IStatusResponse = { status: IRetailerStatus };
