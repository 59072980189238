import React, { useCallback, useMemo } from 'react';
import { useHistory } from 'react-router';
import { useIntercom } from 'react-use-intercom';
import { AppBanner } from '../../../../../core/components/feedback-indicators/banner/banner';
import { AppThumbnail } from '../../../../../core/components/image-containers/thumbnail/Thumbnail';
import { AppLink } from '../../../../../core/components/link/link';
import { ShippingAddress } from '../../../../../core/components/order-details/shipping-address/ShippingAddress';
import { AppTooltip } from '../../../../../core/components/overlays/tooltip/tooltip';
import { AppCard } from '../../../../../core/components/structure/card/card';
import { CardSubsection } from '../../../../../core/components/structure/card/card-subsection';
import { AppTextStyle } from '../../../../../core/components/text/text-style/TextStyle';
import { withCurrency } from '../../../../../core/helpers/currency.helper';
import { ICurrency } from '../../../../../core/interfaces/ICurrency';
import {
  CANCELLATION_REASON,
  CancellationInitiator,
  ILineItemCancellation,
} from '../../../../../core/interfaces/ILineItemCancellation';
import { IAddress, IOriginalAddress } from '../../../../../shopify-supplier/interfaces/IOrder';
import {
  IOrderDetailsLineItem,
  IOrderDetailsTotals,
  IPurchaseOrderDetailsForSalesOrder,
  IRetailerOrderProcessingError,
  RETAILER_ORDER_PROCESSING_ERROR,
} from '../../../../interfaces/IOrder';
import { TrackingNumbers } from '../common/tracking-numbers/tracking-numbers';
import './order-details.scss';

type ProductsGroup =
  | 'active'
  | 'cancelled'
  | 'cancelled by supplier'
  | 'cancelled by crowdship'
  | 'pending cancellation';

interface IOrderDetailsProps {
  shippingAddress?: IAddress;
  originalShippingAddress?: IOriginalAddress;
  status: string;
  purchaseOrders: IPurchaseOrderDetailsForSalesOrder[];
  lineItems: IOrderDetailsLineItem[];
  totals: IOrderDetailsTotals;
  crowdshipItemsCount: number;
  error?: IRetailerOrderProcessingError;
  onCancellationRequestEdit: () => void;
  // TODO: set as required when we'll store retailer's currency info
  currency?: ICurrency;
  editButtonDisabled: boolean;
}

export function OrderDetails({
  shippingAddress,
  originalShippingAddress,
  status,
  purchaseOrders,
  lineItems,
  totals,
  crowdshipItemsCount,
  error,
  onCancellationRequestEdit,
  currency = { isoCode: 'USD', symbol: '$' },
  editButtonDisabled,
}: IOrderDetailsProps) {
  const { show } = useIntercom();
  const history = useHistory();

  const activeProducts = useMemo(
    () =>
      lineItems.filter(
        (p) => p.cancellations.reduce((q, c) => q + c.quantity.accepted, 0) !== p.totalQty,
      ) || [],
    [lineItems],
  );

  const pendingCancellationProducts = useMemo(
    () =>
      lineItems.filter((p) =>
        p.cancellations.some((c) => c.pending && c.initiatedBy === 'retailer'),
      ) || [],
    [lineItems],
  );

  const cancelledProducts = useCallback(
    (initiator: CancellationInitiator) =>
      lineItems.filter((p) =>
        p.cancellations.some(
          (c) => !c.pending && c.initiatedBy === initiator && c.quantity.accepted,
        ),
      ) || [],
    [lineItems],
  );

  const renderProductList = useCallback(
    (products: IOrderDetailsLineItem[], group: ProductsGroup = 'active', isPo?: boolean) => {
      const lessColumns = group === 'active' ? '' : 'no-tracking';
      return (
        <div className={`line-items-list ${lessColumns}`}>
          {!products.length && <CardSubsection>No active products in this order</CardSubsection>}
          {!!products.length && (
            <CardSubsection>
              <div className={'line-item-row header'}>
                <div className="image">Image</div>
                <div className="item">Title</div>
                <div>SKU</div>
                {!isPo && <div>Supplier</div>}
                {!lessColumns && <div className="fulfillment">Tracking</div>}
                {group === 'active' && <div className="quantity">Fulfilled/Total</div>}
                {group.includes('cancelled') && <div className="quantity">Cancelled/Total</div>}
                {group === 'pending cancellation' && <div className="quantity">Pending/Total</div>}
                <div className="price">Sale price</div>
                <div className="cost">Cost</div>
              </div>
            </CardSubsection>
          )}
          {products.map((p, i) => (
            <CardSubsection key={i}>
              <div className="line-item-row">
                <div className="image">
                  <AppThumbnail alt={p.productGroupTitle} source={p.image} />
                </div>
                <div className="item">
                  <div className="title">
                    {p.productGroupTitle}
                    {hasNonDefaultTitle(p.title) && ` - ${p.title}`}
                  </div>
                  {p.supplierProductGroupTitle !== p.productGroupTitle && (
                    <div className="variant">
                      <AppTooltip content="Supplier's title" dismissOnMouseOut>
                        {p.supplierProductGroupTitle}
                        {hasNonDefaultTitle(p.supplierTitle) && `: ${p.supplierTitle}`}
                      </AppTooltip>
                    </div>
                  )}
                </div>
                <div className="item">
                  <div className={`title${p.sku ? '' : ' italic'}`}>
                    <AppTooltip dismissOnMouseOut content={p.sku}>
                      <div className="sku-with-overflow">{p.sku || 'empty SKU'}</div>
                    </AppTooltip>
                  </div>
                  {p.supplierSku && p.supplierSku !== p.sku && (
                    <div className="variant">
                      <AppTooltip content={p.supplierSku} dismissOnMouseOut>
                        <div className="sku-with-overflow">{p.supplierSku}</div>
                      </AppTooltip>
                    </div>
                  )}
                </div>
                {!isPo && (
                  <div>
                    <div>{p.supplierName}</div>
                    {p.supplierOrderName && <div>Order: {p.supplierOrderName} </div>}
                    <AppLink onClick={() => history.push(`/purchase-order/${p.purchaseOrderId}`)}>
                      PO {p.purchaseOrderName} - {p.paidViaCrowdship ? 'via' : 'outside of'} CS
                    </AppLink>
                  </div>
                )}
                {!lessColumns && (
                  <div className="fulfillment">
                    <TrackingNumbers
                      numbers={p.trackingNumbers}
                      urls={p.trackingUrls}
                      companies={p.trackingCompanies}
                      fulfilledQty={!!p.fulfilledQty}
                    />
                  </div>
                )}
                <div className="quantity">
                  {group === 'active' && `${p.fulfilledQty}/${p.totalQty}`}
                  {group === 'cancelled' &&
                    `${p.cancellations
                      .filter((c) => !c.pending && c.initiatedBy === 'retailer')
                      .reduce((q, c) => q + c.quantity.accepted, 0)}/${p.totalQty}`}
                  {group === 'cancelled by supplier' &&
                    `${p.cancellations
                      .filter((c) => !c.pending && c.initiatedBy === 'supplier')
                      .reduce((q, c) => q + c.quantity.accepted, 0)}/${p.totalQty}`}
                  {group === 'cancelled by crowdship' &&
                    `${p.cancellations
                      .filter((c) => !c.pending && c.initiatedBy === 'crowdship')
                      .reduce((q, c) => q + c.quantity.accepted, 0)}/${p.totalQty}`}
                  {group === 'pending cancellation' &&
                    `${p.cancellations
                      .filter((c) => c.pending && c.initiatedBy === 'retailer')
                      .reduce((q, c) => q + c.quantity.requested, 0)}/${p.totalQty}`}
                </div>
                <div className="price">{withCurrency(p.price, currency)}</div>
                <div className="cost">{withCurrency(p.cost, currency)}</div>
              </div>
            </CardSubsection>
          ))}
        </div>
      );
    },
    [currency, history],
  );

  const renderTotalsDesc = useMemo(() => {
    return (
      <>
        <CardSubsection>
          <div className="totals-desc-list">
            <div className="totals-desc-row">
              <div className="title"></div>
              <div className="desc"></div>
              <div className="price">Consumer paid</div>
              <div className="cost">You're charged</div>
            </div>
          </div>
        </CardSubsection>
        <CardSubsection>
          <div className="totals-desc-list">
            <div className="totals-desc-row">
              <div className="title">Subtotal</div>
              <div className="desc">{crowdshipItemsCount}&nbsp;items</div>
              <div className="price">{withCurrency(totals.retailer.subtotal, currency)}</div>
              <div className="cost">{withCurrency(totals.supplier.subtotal, currency)}</div>
            </div>
            <div className="totals-desc-row">
              <div className="title">Shipping</div>
              <div className="desc">{totals.selectedShipping}</div>
              <div className="price">{withCurrency(totals.retailer.shipping, currency)}</div>
              <div className="cost">{withCurrency(totals.supplier.shipping, currency)}</div>
            </div>
            <div className="totals-desc-row bold">
              <div className="title">Total</div>
              <div className="spacer"></div>
              <div className="price">{withCurrency(totals.retailer.total, currency)}</div>
              <div className="cost">{withCurrency(totals.supplier.total, currency)}</div>
            </div>
            {!!totals.retailer.refunded && !!totals.supplier.refunded && (
              <div className="totals-desc-row bold">
                <div className="title">Refunded</div>
                <div className="spacer"></div>
                <div className="price">{withCurrency(totals.retailer.refunded, currency)}</div>
                <div className="cost">{withCurrency(totals.supplier.refunded, currency)}</div>
              </div>
            )}
            {!!totals.manualRefund && !!totals.manualRefund.length
              ? totals.manualRefund.map((mr, i) => (
                  <div className="totals-desc-row bold">
                    <div className="title">Refund {i > 0 ? i : ''}</div>
                    <div className="desc">
                      <AppLink
                        onClick={() => history.push(`/purchase-order/${mr.purchaseOrderId}`)}
                      >
                        PO {mr.purchaseOrderName} - {mr.paidViaCrowdship ? 'via' : 'outside of'} CS
                      </AppLink>
                    </div>
                    <div className="price">{withCurrency(0, currency)}</div>
                    <div className="cost">-{withCurrency(mr.amount, currency)}</div>
                  </div>
                ))
              : null}
          </div>
        </CardSubsection>
        <CardSubsection>
          <div className="totals-desc-list bold">
            <div className="totals-desc-row">
              <div className="spacer"></div>
              <div className="spacer"></div>
              <div className="title">Profit:</div>
              <div className="cost">{withCurrency(totals.profit, currency)}</div>
            </div>
            <div className="totals-desc-row">
              <div className="spacer"></div>
              <div className="spacer"></div>
              <div className="title">Margin:</div>
              <div className="cost">{totals.margin ? `${totals.margin?.toFixed(2)}%` : '-'}</div>
            </div>
          </div>
        </CardSubsection>
      </>
    );
  }, [
    crowdshipItemsCount,
    totals.retailer.subtotal,
    totals.retailer.shipping,
    totals.retailer.total,
    totals.retailer.refunded,
    totals.supplier.subtotal,
    totals.supplier.shipping,
    totals.supplier.total,
    totals.supplier.refunded,
    totals.selectedShipping,
    totals.manualRefund,
    totals.profit,
    totals.margin,
    currency,
    history,
  ]);

  const errorMessage = useMemo(() => {
    if (
      status !== 'error' ||
      !error ||
      !error.message ||
      error.type === RETAILER_ORDER_PROCESSING_ERROR.PENDING_REPROCESSING
    )
      return '';

    const contactCrowdshipMsg = (
      <p className="needs-attention">
        Contact Crowdship at{' '}
        <a href="mailto:support@crowdship.io" target="_blank" rel="noreferrer" className="mailLink">
          support@crowdship.io
        </a>{' '}
        or <AppLink onClick={show}>using the chat</AppLink>
      </p>
    );

    return (
      <>
        - {!editButtonDisabled && 'Please fix the address. If you encounter any further issues: '}{' '}
        {contactCrowdshipMsg}
      </>
    );
  }, [status, editButtonDisabled, error, show]);

  const renderCardTitle = useCallback(
    (pO: IPurchaseOrderDetailsForSalesOrder) => {
      return (
        <>
          <div>
            Status: {pO.status.replace('error', 'Needs attention ')} {errorMessage}
          </div>
          <div>{pO.supplierName || ''}</div>
          <div>
            Order:{' '}
            {pO.supplierOrderName || <AppTextStyle variation="subdued">not created</AppTextStyle>}
          </div>
          <AppLink onClick={() => history.push(`/purchase-order/${pO.id}`)}>
            PO {pO.name} - {pO.paidViaCrowdship ? 'via' : 'outside of'} CS
          </AppLink>
        </>
      );
    },
    [errorMessage, history],
  );

  const formatCancelledProductsSupplier = useMemo(() => {
    const products = cancelledProducts('supplier');
    return Object.values(CANCELLATION_REASON)
      .map((reason) => ({
        products: products
          .map((p) => ({
            ...p,
            cancellations: p.cancellations.filter((c) => c.reason === reason),
          }))
          .filter((i) => i.cancellations.length),
        reason: reason,
      }))
      .filter((section) => section.products.length);
  }, [cancelledProducts]);

  return (
    <div className="order-details-summary">
      <div className="order-details-summary-layout">
        {!!pendingCancellationProducts.length && (
          <AppCard
            title="Pending cancellation"
            sections={[
              {
                content: renderProductList(pendingCancellationProducts, 'pending cancellation'),
              },
            ]}
            primaryFooterAction={{
              content: 'Edit cancellation',
              onAction: onCancellationRequestEdit,
            }}
          />
        )}
        {(status !== 'payment failed' || activeProducts.every((p) => p.paidViaCrowdship)) &&
          purchaseOrders.map((pO) => {
            const activeFilteredProducts = activeProducts.filter(
              (aP) => aP.purchaseOrderId === pO.id,
            );
            return (
              <AppCard
                title={renderCardTitle(pO)}
                sections={[
                  {
                    content: (
                      <>
                        {status === 'payment failed' && (
                          <div className="nested-banner">
                            <AppBanner status="critical">
                              We had issues charging you for these products thus, they will not be
                              shown to the suppliers
                            </AppBanner>
                          </div>
                        )}
                        {renderProductList(activeFilteredProducts, undefined, true)}
                      </>
                    ),
                  },
                ]}
              />
            );
          })}
        {status === 'payment failed' && !activeProducts.every((p) => p.paidViaCrowdship) && (
          <>
            {purchaseOrders
              .filter((pO) => pO.paidViaCrowdship)
              .map((pO) => {
                const activeFilteredProducts = activeProducts.filter(
                  (aP) => aP.purchaseOrderId === pO.id,
                );
                return (
                  <AppCard title={renderCardTitle(pO)} sectioned>
                    <div className="nested-banner">
                      <AppBanner status="critical">
                        We had issues charging you for these products thus, they will not be shown
                        to the suppliers
                      </AppBanner>
                    </div>
                    {renderProductList(activeFilteredProducts, undefined, true)}
                  </AppCard>
                );
              })}
            {purchaseOrders
              .filter((pO) => !pO.paidViaCrowdship)
              .map((pO) => {
                const activeFilteredProducts = activeProducts.filter(
                  (aP) => aP.purchaseOrderId === pO.id,
                );
                return (
                  <AppCard title={renderCardTitle(pO)} sectioned>
                    {renderProductList(activeFilteredProducts, undefined, true)}
                  </AppCard>
                );
              })}
          </>
        )}
        {!!cancelledProducts('retailer').length && (
          <AppCard
            title="Cancelled products"
            sections={[{ content: renderProductList(cancelledProducts('retailer'), 'cancelled') }]}
          />
        )}
        {!!cancelledProducts('supplier').length && (
          <AppCard
            title={'Cancelled by supplier products'}
            sections={formatCancelledProductsSupplier
              .filter((section) => section.products.length > 0)
              .map((section) => {
                return {
                  content: (
                    <>
                      <div className="reason">
                        <AppBanner>
                          Cancellation reason:{' '}
                          {getSupplierCancellationReason(
                            section.products[0].cancellations.find(
                              (i) => i.reason === section.reason,
                            ) || section.products[0].cancellations[0],
                          )}
                        </AppBanner>
                      </div>
                      {renderProductList(section.products, 'cancelled by supplier')}
                    </>
                  ),
                };
              })}
          />
        )}
        {!!cancelledProducts('crowdship').length && (
          <AppCard
            title={'Cancelled by Crowdship products'}
            sections={[
              {
                content: renderProductList(
                  cancelledProducts('crowdship'),
                  'cancelled by crowdship',
                ),
              },
            ]}
          />
        )}
        <AppCard title="Totals" sections={[{ content: renderTotalsDesc }]} />
      </div>
      <div className="main-info">
        <ShippingAddress
          shippingAddress={shippingAddress}
          originalShippingAddress={originalShippingAddress}
        />
      </div>
    </div>
  );
}

const hasNonDefaultTitle = (title: string | undefined) => {
  return title && title.toLowerCase() !== 'default title';
};

const getSupplierCancellationReason = (cancellation: ILineItemCancellation) => {
  switch (cancellation.reason) {
    case CANCELLATION_REASON.CUSTOMER:
      return 'Customer cancelled/changed the order';
    case CANCELLATION_REASON.INVENTORY:
      return 'Products are out of stock';
    case CANCELLATION_REASON.OTHER:
      if (cancellation.comment) return cancellation.comment;
      return 'Other';
    default:
      return 'Not specified';
  }
};
