import { axiosInstance } from './base.api';

/**
 * Api for fetching country info
 * Endpoints -
 * https://documenter.getpostman.com/view/1134062/T1LJjU52#a6eeb719-e591-4b28-ba6c-71e481f71269
 */

class CountryApi {
  private backend = 'https://countriesnow.space';
  private rootPath = '/api/v0.1/countries';

  async getCountries() {
    return await axiosInstance.get<{ error: boolean; msg: string; data: Country[] }>(
      `${this.backend}${this.rootPath}/states`,
    );
  }
}

export const countryApi = new CountryApi();

export interface Country {
  iso2: string;
  iso3: string;
  name: string;
  states: State[];
}

export interface State {
  name: string;
  state_code: string;
}
