import { ICustomerAddress } from '../../../../shopify-retailer/interfaces/IOrder';
import { IAddress, IOriginalAddress } from '../../../../shopify-supplier/interfaces/IOrder';
import { AppCard } from '../../structure/card/card';
import { AppTextStyle } from '../../text/text-style/TextStyle';
import './shipping-address.styles.scss';
import { AppTooltip } from '../../overlays/tooltip/tooltip';
import { getFullFormattedDate } from '../../../helpers/date.helper';
import { ShippingAddressInfo } from '../shipping-address-info/shipping-address-info';

type ShippingProps = {
  shippingAddress?: ICustomerAddress | IAddress;
  originalShippingAddress?: IOriginalAddress;
};

export const ShippingAddress = ({ shippingAddress, originalShippingAddress }: ShippingProps) => {
  const isCompanyInShippingAddress = shippingAddress && 'company' in shippingAddress;
  return (
    <div className="shipping">
      <AppCard sectioned>
        {shippingAddress ? (
          <div>
            <>
              {shippingAddress.name && (
                <>
                  <AppTextStyle variation="strong">{shippingAddress.name}</AppTextStyle>
                  <br />
                </>
              )}
              {isCompanyInShippingAddress && shippingAddress.company && (
                <>
                  <AppTextStyle>{shippingAddress.company}</AppTextStyle>
                  <br />
                </>
              )}
              {!shippingAddress.name &&
                ((isCompanyInShippingAddress && !shippingAddress.company) ||
                  !isCompanyInShippingAddress) && (
                  <>
                    <AppTextStyle variation="strong">not set</AppTextStyle>
                    <br />
                  </>
                )}
            </>
            <ShippingAddressInfo shippingAddress={shippingAddress} />
            <div>{shippingAddress.phone}</div>
          </div>
        ) : (
          'Address not set'
        )}
        {originalShippingAddress && (
          <div className="original_address-wrapper">
            <p className="edited_address">This address has been edited.</p>
            <AppTooltip
              preferredPosition="below"
              content={
                <>
                  <p>Original address:</p>
                  <ShippingAddressInfo shippingAddress={originalShippingAddress} />
                  <div>
                    First edited at: {getFullFormattedDate(originalShippingAddress.editedAt)}
                  </div>
                </>
              }
            >
              <span className="link">Original address</span>
            </AppTooltip>
          </div>
        )}
      </AppCard>
    </div>
  );
};
