import { all, fork } from 'redux-saga/effects';
import { coreSaga } from '../../core/redux/sagas';
import { ordersSaga } from './modules/orders/orders.sagas';
import { dashboardSagas } from './modules/dashboard/dashboard-sagas';
import { preferencesSaga } from './modules/preferences/preferences.saga';
import { productsSaga } from './modules/products/products.saga';
import { statusSaga } from './modules/status/status.saga';

export function* rootSaga() {
  yield all([
    fork(coreSaga),
    fork(productsSaga),
    fork(ordersSaga),
    fork(dashboardSagas),
    fork(preferencesSaga),
    fork(statusSaga),
  ]);
}
