import { createSelector } from 'reselect';
import { RootState } from '../../reducers';
import { PRODUCT_UPDATE_OPTION } from '../../../interfaces/IPreferences';

export const getPreferencesSelector = createSelector(
  [(state: RootState) => state.preferences],
  (preferencesReducer) => preferencesReducer.preferences,
);

export const getCarrierServicesSelector = createSelector(
  [(state: RootState) => state.preferences],
  (preferencesReducer) => preferencesReducer.carrierServiceAutocompleteOptions,
);

export const getPreferencesFetchingSelector = createSelector(
  [(state: RootState) => state.preferences],
  (preferencesReducer) => preferencesReducer.fetching,
);

export const getPriceChanges = createSelector(
  [(state: RootState) => state.preferences],
  (preferencesReducer) => preferencesReducer.priceChanges,
);

export const getIsVariantsSyncEnableSelector = createSelector(
  [(state: RootState) => state.preferences],
  (preferencesReducer) =>
    preferencesReducer.preferences.product.updateOption === PRODUCT_UPDATE_OPTION.UPDATE &&
    (preferencesReducer.preferences.product.updateFields.all ||
      preferencesReducer.preferences.product.updateFields.variants),
);
