import { createAction } from 'typesafe-actions';
import { IPlan } from '../../../interfaces/IPlan';
import { ACTIONS_CONSTANTS } from '../../actions-constants';

export const getPlansAction = createAction(ACTIONS_CONSTANTS.GET_PLANS)();

export const setPlansAction = createAction(
  ACTIONS_CONSTANTS.SET_PLANS,
  (plans: IPlan[]) => plans,
)();
