import React, { useState } from 'react';
import { AppControlledTextField } from '../../../../../../core/components/forms/controlled-text-field/controlled-text-field';
import { AppThumbnail } from '../../../../../../core/components/image-containers/thumbnail/Thumbnail';
import { AppIndexTableCell } from '../../../../../../core/components/index-table/index-table-cell';
import { AppIndexTableRow } from '../../../../../../core/components/index-table/index-table-row';
import { AverageMargin } from '../../../../../../core/components/product-list/table-elements/average-margin';
import { withCurrency } from '../../../../../../core/helpers/currency.helper';
import { validation } from '../../../../../../core/helpers/validations.helper';
import { IVariantFromImportList } from '../../../../../interfaces/IProduct';
import { TrackedQuantityLabelForVariant } from '../../../../../../core/components/tracked-quantity-label-for-variant/tracked-quantity-label-for-variant';
import { calculateMargin } from '../../../../../../core/helpers/calculate-margin.helper';

interface ProductVariantProps {
  variant: IVariantFromImportList;
  index: number;
  setFieldValue: (field: string, value: any) => void;
}

export const ProductVariant = ({ variant, index, setFieldValue }: ProductVariantProps) => {
  const [variantMargin, setVariantMargin] = useState<number | null>(
    calculateMargin(variant.priceForRetailers as number, variant.price) || variant.margin,
  );

  return (
    <AppIndexTableRow id={`${index}`} key={index} selected={false} position={index}>
      <AppIndexTableCell>
        <div className="product-image">
          <AppThumbnail source={variant.image} alt={variant.title} />
        </div>
      </AppIndexTableCell>
      <AppIndexTableCell>{variant.sku}</AppIndexTableCell>
      <AppIndexTableCell>{variant.options.join('/')}</AppIndexTableCell>
      <AppIndexTableCell>
        <TrackedQuantityLabelForVariant
          quantity={variant.quantityAvailable}
          tracked={variant.tracked}
          inventoryPolicy={variant.inventoryPolicy}
        />
      </AppIndexTableCell>
      <AppIndexTableCell>{withCurrency(variant.priceForRetailers as number)}</AppIndexTableCell>
      <AppIndexTableCell>{withCurrency(variant.MSRP as number)}</AppIndexTableCell>
      <AppIndexTableCell>
        <AppControlledTextField
          name={`variants[${index}].price`}
          prefix="$"
          type="number"
          validate={validation.isPositiveNumber}
          onChange={(value) => {
            setVariantMargin(calculateMargin(variant.priceForRetailers as number, value));
            variant.margin = variantMargin as number;
            setFieldValue(`variants[${index}].manuallySetPrice`, true);
          }}
        />
      </AppIndexTableCell>
      <AppIndexTableCell>
        <AverageMargin margin={variantMargin} />
      </AppIndexTableCell>
      <AppIndexTableCell>
        <AppControlledTextField
          name={`variants[${index}].compareAtPrice`}
          prefix="$"
          type="number"
          validate={validation.isOptionalNumber}
        />
      </AppIndexTableCell>
    </AppIndexTableRow>
  );
};
