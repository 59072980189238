import { IndexTable } from '@shopify/polaris';

interface AppRowProps {
  children: React.ReactNode;
  id: string;
  selected: boolean;
  position: number;
  subdued?: boolean;
  onNavigation?(id: string): void;
}

export const AppIndexTableRow = (props: AppRowProps) => <IndexTable.Row {...props} />;
