import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { setAxiosAuthorization } from '../../../../core/api/base.api';
import { AppPage } from '../../../../core/components/structure/page/page';
import { LoadingLayout } from '../../../../core/layouts/loading-layout/loading-layout';
import { authApi } from '../../../api/auth.api';
import { setAuthenticatedAction } from '../../../redux/modules/auth/auth.actions';
import { ROUTES } from '../../../router/routes';

export const ConfirmLayout = () => {
  const dispatch = useDispatch();
  const query = useLocation().search;
  const history = useHistory();

  useEffect(() => {
    if (!query) return history.push(ROUTES.SIGN_IN);

    authApi
      .confirmAuthViaShopify(query)
      .then(({ data }) => {
        localStorage.setItem('token', data.token);
        setAxiosAuthorization(data.token);
        dispatch(setAuthenticatedAction(data.token));
      })
      .catch((error) => {
        console.error(error);
        history.push(ROUTES.SIGN_IN);
      });
  }, [history, query, dispatch]);

  return (
    <AppPage>
      <LoadingLayout />
    </AppPage>
  );
};
