import { AppTooltip } from '../../../../core/components/overlays/tooltip/tooltip';
import { AppTextStyle } from '../../../../core/components/text/text-style/TextStyle';
import { ILocation } from '../../../interfaces/ILocation';

type LocationTooltipProps = {
  location: Omit<ILocation, 'countryName' | 'active' | 'legacy' | 'phone'>;
};

export const LocationTooltip = ({ location }: LocationTooltipProps) => (
  <AppTooltip
    content={
      <div>
        <AppTextStyle variation="strong">{location.name}</AppTextStyle>
        <div>
          {location.address1} {location.address2}
        </div>
        <div>
          {location.city} {location.provinceCode}, {location.country}, {location.zip}
        </div>
      </div>
    }
    dismissOnMouseOut
  >
    <AppTextStyle variation="strong">Location: {location.name}</AppTextStyle>
  </AppTooltip>
);
