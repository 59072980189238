import React from 'react';
import './account-details.scss';

type IPaymentDetailsFrameProps = {
  src: string;
};

export function PaymentDetailsFrame({ src }: IPaymentDetailsFrameProps) {
  return (
    <div
      className="manage-payment"
      dangerouslySetInnerHTML={{
        // lazy load iframe to avoid screen jumps
        __html: `<iframe src=${src} scrolling="no" height="600px" width="100%" loading='lazy' />`,
      }}
    />
  );
}
