import React from 'react';
import { getFormattedDate, getFullDateFormat } from '../../helpers/date.helper';
import { AppTooltip } from '../overlays/tooltip/tooltip';
import './date-tooltip.scss';

type IDateTooltipProps = {
  date: string | number;
};

export function DateTooltip({ date }: IDateTooltipProps) {
  return (
    <AppTooltip content={getFullDateFormat(date)}>
      <div>{getFormattedDate(date)}</div>
    </AppTooltip>
  );
}
