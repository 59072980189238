import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { PRODUCT_CATEGORY, PRODUCT_VIEW } from '../../../../core/constants/product.constants';
import { Paginated } from '../../../../core/helpers/generic.helper';
import { ISuppliersInfoFilters } from '../../../../core/interfaces/IProductFilter';
import { ISuppliersDetails, marketplaceApi } from '../../../api/marketplace.api';
import { SupplierPreviewList } from '../../containers/supplier-preview-list/supplier-preview-list';

const SUPPLIERS_PER_PAGE = 20;

interface IMarketplaceSuppliersListLayout {
  handleProductViewChange: (view: PRODUCT_VIEW) => void;
}

export const MarketplaceSuppliersListLayout = ({
  handleProductViewChange,
}: IMarketplaceSuppliersListLayout) => {
  const [fetching, setFetching] = useState(false);
  const [total, setTotal] = useState(0);
  const [suppliers, setSuppliers] = useState<ISuppliersDetails[]>([]);

  const [filters, setFilters] = useState<Paginated<ISuppliersInfoFilters>>({
    title: '',
    productCategory: PRODUCT_CATEGORY.NONE,
    limit: SUPPLIERS_PER_PAGE,
    page: 0,
  });

  useEffect(() => {
    setFetching(true);
    marketplaceApi
      .getSuppliersList(filters)
      .then(({ data }) => {
        setTotal(data.count);
        setSuppliers(data.result);
      })
      .finally(() => {
        setFetching(false);
      });
  }, [filters]);

  const totalPageCount = useMemo(
    () => (total ? Math.ceil(total / SUPPLIERS_PER_PAGE) : total),
    [total],
  );

  const handlePageChange = useCallback((page: number) => {
    setFilters({
      page,
      limit: SUPPLIERS_PER_PAGE,
      title: '',
      productCategory: PRODUCT_CATEGORY.NONE,
    });
  }, []);

  const handleFiltersChange = useCallback(
    (title: string, productCategory: PRODUCT_CATEGORY) => {
      setFilters({ ...filters, title, productCategory });
    },
    [filters],
  );

  return (
    <SupplierPreviewList
      fetching={fetching}
      suppliers={suppliers}
      total={total}
      totalPages={totalPageCount}
      handlePageChange={handlePageChange}
      handleFiltersChange={handleFiltersChange}
      handleProductViewChange={handleProductViewChange}
    />
  );
};
