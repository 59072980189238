import React from 'react';
import { Card } from '@shopify/polaris';

type ICardSubsectionProps = {
  children: React.ReactNode;
};

export function CardSubsection({ children }: ICardSubsectionProps) {
  return <Card.Subsection>{children}</Card.Subsection>;
}
