import React, { ReactNode, useCallback, useMemo, useState } from 'react';
import { AppButton } from '../../../../../../../core/components/button/Button';
import { AppCollapsible } from '../../../../../../../core/components/collapsible/Collapsible';
import { AppThumbnail } from '../../../../../../../core/components/image-containers/thumbnail/Thumbnail';
import { AppTextStyle } from '../../../../../../../core/components/text/text-style/TextStyle';
import {
  IRetailerProductMatch,
  IRetailerProductMatchVariant,
  ISupplierProductMatch,
  ISupplierProductMatchVariant,
} from '../../../../../../interfaces/IProductsSync';
import { AppTooltip } from '../../../../../../../core/components/overlays/tooltip/tooltip';
import { ReactComponent as CrowdshipLogo } from '../../../../../../../core/images/crowdship-navy.svg';
import './sync-conflict-product.scss';

type SyncConflictProductProps = {
  shop: string;
  product: IRetailerProductMatch | ISupplierProductMatch;
  obsoleteVariantsCount?: number;
  newVariantsCount?: number;
  variants: ReactNode;
};

export const SyncConflictProduct = ({
  shop,
  product,
  obsoleteVariantsCount,
  newVariantsCount,
  variants,
}: SyncConflictProductProps) => {
  const [expanded, setExpanded] = useState(false);
  const isRetailerProduct = useCallback(
    (product: IRetailerProductMatch | ISupplierProductMatch): product is IRetailerProductMatch =>
      'shopifyId' in product,
    [],
  );

  const productId = useMemo(
    () => (isRetailerProduct(product) ? product.shopifyId : product.id),
    [product, isRetailerProduct],
  );
  const images = product.variants
    .map((v: ISupplierProductMatchVariant | IRetailerProductMatchVariant) => v.image)
    .filter((img) => img);
  const thumbnailSource = product.image || images[0];
  const toggleVariantsLabel = expanded ? 'Hide variants' : 'Show variants';

  const crowdshipMarker = useMemo(
    () => (
      <AppTooltip
        content="Product was supported by Crowdship"
        dismissOnMouseOut
        preferredPosition="above"
      >
        <span className="crowdship-logo">
          <CrowdshipLogo />
        </span>
      </AppTooltip>
    ),
    [],
  );

  const productTitle = useMemo(
    () =>
      isRetailerProduct(product) ? (
        <h3 className="product-title">
          <AppTextStyle variation="strong">{product.title}</AppTextStyle>
          <span
            className="link-to-platform"
            onClick={(e) => {
              e.stopPropagation();
              window.open(`https://${shop}/admin/products/${product.shopifyId}`);
            }}
          >
            <AppTextStyle variation="subdued">View on Shopify</AppTextStyle>
          </span>
        </h3>
      ) : (
        <h3 className="product-title">
          <AppTextStyle variation="strong">{product.title}</AppTextStyle>
        </h3>
      ),
    [shop, product, isRetailerProduct],
  );

  const productThumbnail = useMemo(
    () =>
      isRetailerProduct(product) ? (
        <div className={`thumbnail-wrapper ${product.crowdship ? 'crowdship' : ''}`}>
          <AppThumbnail source={thumbnailSource} alt={product.title} />
          {product.crowdship && crowdshipMarker}
        </div>
      ) : (
        <AppThumbnail source={thumbnailSource} alt={product.title} />
      ),
    [product, thumbnailSource, crowdshipMarker, isRetailerProduct],
  );

  return (
    <>
      <div className={`sync-product-container ${expanded ? 'expanded' : ''}`}>
        {productThumbnail}
        {productTitle}
        <div className="product-desc">{product.vendor}</div>
        <div className="product-desc">{product.qty}</div>
        <div className="product-desc">
          <div className="variations">{product.variants.length} variations</div>
          {obsoleteVariantsCount ? (
            <div className="obsolete-count">{obsoleteVariantsCount} obsolete</div>
          ) : (
            <div></div>
          )}
          {newVariantsCount ? <div className="new-count">{newVariantsCount} new</div> : <div></div>}
        </div>
        <AppButton
          handleClick={(e) => {
            e.stopPropagation();
            setExpanded((e) => !e);
          }}
        >
          {toggleVariantsLabel}
        </AppButton>
      </div>
      <AppCollapsible id={productId.toString()} open={expanded}>
        {variants}
      </AppCollapsible>
    </>
  );
};
