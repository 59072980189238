import { AxiosResponse } from 'axios';
import { all, put, takeLatest } from 'redux-saga/effects';
import { ActionType } from 'typesafe-actions';
import { IProductChangesToCompare, preferencesApi } from '../../../api/preferences.api';
import { IPreferences, IProductPreferences } from '../../../interfaces/IPreferences';
import {
  getPreferencesAction,
  setPreferencesFromApiAction,
  calcPriceChangesAction,
  setPriceChangesAction,
  getCarrierServicesAction,
  setCarrierServicesAction,
  setProductPreferencesFromApiAction,
  getProductPreferencesAction,
} from './preferences.actions';

function* getPreferencesSaga(action: ActionType<typeof getPreferencesAction>) {
  try {
    const res: AxiosResponse<IPreferences> = yield preferencesApi.getPreferences();
    yield put(setPreferencesFromApiAction(res.data));
  } catch (e) {
    console.error(e);
  }
}

function* getProductPreferencesSaga(action: ActionType<typeof getProductPreferencesAction>) {
  try {
    const res: AxiosResponse<IProductPreferences> = yield preferencesApi.getProductPreferences();
    yield put(setProductPreferencesFromApiAction(res.data));
  } catch (e) {
    console.error(e);
  }
}

function* getCarrierServicesSaga(action: ActionType<typeof getCarrierServicesAction>) {
  try {
    const res: AxiosResponse<string[]> = yield preferencesApi.getCarrierServices();
    yield put(setCarrierServicesAction(res.data));
  } catch (e) {
    console.error(e);
  }
}

function* calcPriceChangesSaga({ payload }: ActionType<typeof calcPriceChangesAction>) {
  try {
    const { data }: AxiosResponse<IProductChangesToCompare[]> =
      yield preferencesApi.calculatePriceChanges(payload);
    yield put(setPriceChangesAction(data));
  } catch (error) {
    console.error(error);
  }
}

export function* preferencesSaga() {
  yield all([
    takeLatest(getPreferencesAction, getPreferencesSaga),
    takeLatest(getCarrierServicesAction, getCarrierServicesSaga),
    takeLatest(calcPriceChangesAction, calcPriceChangesSaga),
    takeLatest(getProductPreferencesAction, getProductPreferencesSaga),
  ]);
}
