import { AxiosResponse } from 'axios';
import { all, put, takeLatest } from 'redux-saga/effects';
import { ActionType } from 'typesafe-actions';
import { PRODUCTS_PER_PAGE } from '../../../../core/constants/product.constants';
import { syncProductsApi, UpdatedProductsLimitStatusAfterLinking } from '../../../api/sync.api';
import { IProductMatch, IProductSync } from '../../../interfaces/IProductsSync';
import { updateProductsLimitStatusAction } from '../status/status.actions';
import {
  getSyncConflictsAction,
  getSyncMatchesAction,
  getSyncStatusAction,
  getVendorsForMatchesAction,
  linkAllMatchesAction,
  linkMatchesAction,
  resolveConflictAction,
  setSyncConflictsAction,
  setSyncMatchesAction,
  setSyncStatusAction,
  setVendorsForMatchesAction,
  showLimitExceededBannerAction,
  showLinkToastAction,
  startProductSyncAction,
} from './sync.actions';

function* getSyncStatusSaga(action: ActionType<typeof getSyncStatusAction>) {
  try {
    const res: AxiosResponse<Omit<IProductSync, 'matches' | 'conflicts' | 'disconnectedProducts'>> =
      yield syncProductsApi.getProductSyncStatus();
    yield put(setSyncStatusAction(res.data));
  } catch (e) {
    console.error(e);
  }
}

function* getSyncMatchesSaga(action: ActionType<typeof getSyncMatchesAction>) {
  try {
    const res: AxiosResponse<{
      matches: IProductMatch[];
      total: number;
    }> = yield syncProductsApi.getProductSyncMatches(action.payload);
    yield put(setSyncMatchesAction(res.data));
  } catch (e) {
    console.error(e);
  }
}

function* getSyncConflictsSaga(action: ActionType<typeof getSyncConflictsAction>) {
  try {
    const res: AxiosResponse<Pick<IProductSync, 'conflicts'>> =
      yield syncProductsApi.getProductSyncConflicts();
    yield put(setSyncConflictsAction(res.data));
  } catch (e) {
    console.error(e);
  }
}

function* getVendorsForMatchesSaga(action: ActionType<typeof getVendorsForMatchesAction>) {
  try {
    const { data }: AxiosResponse<string[]> = yield syncProductsApi.getVendorsForMatches();
    yield put(setVendorsForMatchesAction(data));
  } catch (e) {
    console.error(e);
  }
}

function* startProductSyncSaga(action: ActionType<typeof startProductSyncAction>) {
  try {
    yield syncProductsApi.startProductSync();
    yield put(getSyncStatusAction());
    yield put(getSyncMatchesAction({ limit: PRODUCTS_PER_PAGE, page: 0 }));
    yield put(getSyncConflictsAction());
  } catch (e) {
    console.error(e);
  }
}

function* linkMatchesSaga(action: ActionType<typeof linkMatchesAction>) {
  try {
    const { data }: AxiosResponse<UpdatedProductsLimitStatusAfterLinking> =
      yield syncProductsApi.linkMatches(action.payload);
    if ('success' in data) {
      yield put(
        showLinkToastAction({
          success: data.success,
          failed: !data.success ? data.failed : undefined,
        }),
      );
    } else if ('limitExceeded' in data && data.limitExceeded) {
      yield put(showLimitExceededBannerAction());
    }
    yield put(
      updateProductsLimitStatusAction({ productsCount: data.added, productsLimit: data.limit }),
    );
    yield put(getSyncMatchesAction({ limit: PRODUCTS_PER_PAGE, page: 0 }));
  } catch (e) {
    console.error(e);
  }
}

function* linkAllMatchesSaga(action: ActionType<typeof linkAllMatchesAction>) {
  try {
    const { data }: AxiosResponse<UpdatedProductsLimitStatusAfterLinking> =
      yield syncProductsApi.linkAllMatches(action.payload);
    if ('success' in data) {
      yield put(
        showLinkToastAction({
          success: data.success,
          failed: !data.success ? data.failed : undefined,
        }),
      );
    } else if ('limitExceeded' in data && data.limitExceeded) {
      yield put(showLimitExceededBannerAction());
    }
    yield put(
      updateProductsLimitStatusAction({ productsCount: data.added, productsLimit: data.limit }),
    );
  } catch (e) {
    console.error(e);
  } finally {
    yield put(getSyncMatchesAction({ limit: PRODUCTS_PER_PAGE, page: 0 }));
  }
}

function* resolveConflictSaga(action: ActionType<typeof resolveConflictAction>) {
  try {
    const { data }: AxiosResponse<UpdatedProductsLimitStatusAfterLinking> =
      yield syncProductsApi.resolveConflict(action.payload);
    if ('success' in data) {
      yield put(
        showLinkToastAction({
          success: data.success,
          failed: !data.success ? data.failed : undefined,
        }),
      );
    } else if ('limitExceeded' in data && data.limitExceeded) {
      yield put(showLimitExceededBannerAction());
    }
    yield put(
      updateProductsLimitStatusAction({ productsCount: data.added, productsLimit: data.limit }),
    );
    yield put(getSyncMatchesAction({ limit: PRODUCTS_PER_PAGE, page: 0 }));
    yield put(getSyncConflictsAction());
  } catch (e) {
    console.error(e);
  }
}

export function* productSyncSaga() {
  yield all([
    takeLatest(getSyncStatusAction, getSyncStatusSaga),
    takeLatest(getSyncMatchesAction, getSyncMatchesSaga),
    takeLatest(getVendorsForMatchesAction, getVendorsForMatchesSaga),
    takeLatest(getSyncConflictsAction, getSyncConflictsSaga),
    takeLatest(startProductSyncAction, startProductSyncSaga),
    takeLatest(linkMatchesAction, linkMatchesSaga),
    takeLatest(linkAllMatchesAction, linkAllMatchesSaga),
    takeLatest(resolveConflictAction, resolveConflictSaga),
  ]);
}
