import React from 'react';
import { capitalize } from 'lodash';
import { AppBadge } from '../../../../../../core/components/badge/badge';
import { RetailerOrderStatus } from '../../../../../interfaces/IOrder';

type OrderStatusProps = {
  status: RetailerOrderStatus;
};

export const OrderStatus = ({ status }: OrderStatusProps) => {
  switch (status) {
    case 'unfulfilled':
      return (
        <AppBadge status="attention" progress="incomplete">
          {capitalize(status)}
        </AppBadge>
      );
    case 'partially unfulfilled':
      return (
        <AppBadge status="attention" progress="partiallyComplete">
          {capitalize(status)}
        </AppBadge>
      );
    case 'partially fulfilled':
      return <AppBadge progress="partiallyComplete">{capitalize(status)}</AppBadge>;
    case 'fulfilled':
      return <AppBadge progress="complete">{capitalize(status)}</AppBadge>;
    case 'cancelled':
      return (
        <AppBadge status="critical" progress="complete">
          {capitalize(status)}
        </AppBadge>
      );
    case 'account suspended':
    case 'payment failed':
    case 'error':
      return (
        <AppBadge status="critical">
          {status === 'error' ? capitalize('needs attention') : capitalize(status)}
        </AppBadge>
      );
    case 'processing':
      return <AppBadge status="new">{capitalize(status)}</AppBadge>;
    default:
      return null;
  }
};
