import React from 'react';
import { useCallback, useEffect, useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { inFrame } from '../../../../core/helpers/frame.helper';
import { LoadingLayout } from '../../../../core/layouts/loading-layout/loading-layout';
import { retailerApi } from '../../../api/retailer.api';

export function ApiUpdateLayout() {
  const query = useLocation().search;

  const params = useMemo(() => new URLSearchParams(query), [query]);

  const checkApiForUpdates = useCallback(async () => {
    if (params.has('shop')) {
      if (inFrame()) {
        return;
      }
      const res = await retailerApi.updateAccount(query);
      const { urlToRedirect } = res.data;

      // @ts-ignore
      window.location = urlToRedirect;
    }
  }, [params, query]);

  useEffect(() => {
    checkApiForUpdates();
  }, [checkApiForUpdates]);

  return <LoadingLayout />;
}
