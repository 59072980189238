import Decimal from 'decimal.js';
import { Form, Formik } from 'formik';
import React, { useCallback, useMemo } from 'react';
import { AppControlledTextField } from '../../../../../core/components/forms/controlled-text-field/controlled-text-field';
import { AppThumbnail } from '../../../../../core/components/image-containers/thumbnail/Thumbnail';
import { AppCard } from '../../../../../core/components/structure/card/card';
import { CardSubsection } from '../../../../../core/components/structure/card/card-subsection';
import { validation } from '../../../../../core/helpers/validations.helper';
import { ICancellationRequestLineItem } from '../../../../api/orders.api';
import { IOrderDetailsLineItem } from '../../../../interfaces/IOrder';
import './order-cancellation.scss';

type IOrderCancellationProps = {
  products: IOrderDetailsLineItem[];
  onSubmit: (products: ICancellationRequestLineItem[]) => void;
};

export function OrderCancellation({ products, onSubmit }: IOrderCancellationProps) {
  const requestExists = useMemo(
    () =>
      products.some(
        (p) => !!p.cancellations.find((c) => c.pending && c.initiatedBy === 'retailer'),
      ),
    [products],
  );

  const initialValues: ICancellationRequestLineItem[] = useMemo(
    () =>
      products.map((p) => ({
        id: p.id,
        quantity: requestExists
          ? p.cancellations
              .find((c) => c.pending && c.initiatedBy === 'retailer')
              ?.quantity.requested.toString() || '0'
          : (
              p.totalQty -
              p.fulfilledQty -
              p.cancellations.filter((c) => !c.pending).reduce((q, c) => q + c.quantity.accepted, 0)
            ).toString(),
      })),
    [products, requestExists],
  );

  const noProductsSelectedToCancel = useCallback((values: ICancellationRequestLineItem[]) => {
    return !values.reduce((acc, cur) => acc + +cur.quantity, 0);
  }, []);

  const isQuantityValid = useCallback(
    (quantity) => validation.isNumber(quantity) === undefined,
    [],
  );

  const calculateTotalVariantPrice = useCallback(
    (price: number, quantity: string) => {
      if (!isQuantityValid(quantity)) return 0;
      return new Decimal(price).mul(quantity).toFixed(2);
    },
    [isQuantityValid],
  );

  const calculateTotalPrice = useCallback(
    (values: ICancellationRequestLineItem[]) =>
      products
        .reduce(
          (acc, cur, index) =>
            acc.plus(
              new Decimal(cur.price).mul(
                isQuantityValid(values[index].quantity) ? values[index].quantity : 0,
              ),
            ),
          new Decimal(0),
        )
        .toFixed(2),
    [products, isQuantityValid],
  );
  const calculateTotalCost = useCallback(
    (values: ICancellationRequestLineItem[]) =>
      products
        .reduce(
          (acc, cur, index) =>
            acc.plus(
              new Decimal(cur.cost).mul(
                isQuantityValid(values[index].quantity) ? values[index].quantity : 0,
              ),
            ),
          new Decimal(0),
        )
        .toFixed(2),
    [products, isQuantityValid],
  );

  const handleSubmit = useCallback(
    (products: ICancellationRequestLineItem[]) => {
      onSubmit(products);
    },
    [onSubmit],
  );

  const renderProductList = useCallback(
    (values: ICancellationRequestLineItem[]) => {
      return (
        <div className="order-cancellation-products">
          <CardSubsection>
            <div className="line-item-row">
              <div className="image">Image</div>
              <div className="item">Title</div>
              <div className="price">Price</div>
              <div className="cost">Cost</div>
              <div className="input">Quantity</div>
              <div className="total">Total price</div>
              <div className="total">Total cost</div>
            </div>
          </CardSubsection>
          {products.map((p, i) => (
            <CardSubsection key={i}>
              <div className="line-item-row">
                <div className="image">
                  <AppThumbnail alt={p.productGroupTitle} source={p.image} />
                </div>
                <div className="item">
                  <div className="title">{p.productGroupTitle}</div>
                  <div className="variant">{p.title}</div>
                  <div className="sku">SKU: {p.sku}</div>
                </div>
                <div className="price">${p.price}</div>
                <div className="cost">${p.cost}</div>
                <div className="input">
                  <AppControlledTextField
                    name={`[${i}].quantity`}
                    type="number"
                    max={
                      p.totalQty -
                      p.fulfilledQty -
                      p.cancellations.reduce((q, c) => q + c.quantity.accepted, 0)
                    }
                    min={0}
                    suffix={`/ ${
                      p.totalQty -
                      p.fulfilledQty -
                      p.cancellations.reduce((q, c) => q + c.quantity.accepted, 0)
                    }`}
                    validate={(val) =>
                      validation.isNumber(val, {
                        min: 0,
                        max:
                          p.totalQty -
                          p.fulfilledQty -
                          p.cancellations.reduce((q, c) => q + c.quantity.accepted, 0),
                      })
                    }
                  />
                </div>
                {/** Calculated totals */}
                <div className="total price">
                  ${calculateTotalVariantPrice(p.price, values[i].quantity)}
                </div>
                <div className="total cost">
                  ${calculateTotalVariantPrice(p.cost, values[i].quantity)}
                </div>
              </div>
            </CardSubsection>
          ))}
          <CardSubsection>
            <div className="line-item-row">
              <div className="spacer"></div>
              <div className="spacer"></div>
              <div className="spacer"></div>
              <div className="spacer"></div>
              <div className="title">Total:</div>
              <div className="total price">${calculateTotalPrice(values)}</div>
              <div className="total cost">${calculateTotalCost(values)}</div>
            </div>
          </CardSubsection>
        </div>
      );
    },
    [calculateTotalPrice, calculateTotalCost, calculateTotalVariantPrice, products],
  );

  return (
    <div className="order-cancellation">
      <Formik initialValues={initialValues} onSubmit={handleSubmit} isInitialValid>
        {({ submitForm, values }) => (
          <Form name={'orderCancellation'}>
            <AppCard
              sections={[{ content: renderProductList(values) }]}
              primaryFooterAction={{
                content: requestExists ? 'Edit cancellation request' : 'Request cancellation',
                onAction: submitForm,
                disabled: noProductsSelectedToCancel(values),
              }}
            />
          </Form>
        )}
      </Formik>
    </div>
  );
}
