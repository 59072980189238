import { AxiosResponse } from 'axios';
import { all, put, takeLatest } from 'redux-saga/effects';
import { ActionType } from 'typesafe-actions';
import { showActionError } from '../../../../core/helpers/saga-error-catcher.helper';
import { IPreferencesFromApi, preferencesApi } from '../../../api/preferences.api';
import { getPreferencesAction, setPreferencesFromApiAction } from './preferences.actions';

function* getPreferencesSaga({ type }: ActionType<typeof getPreferencesAction>) {
  try {
    const res: AxiosResponse<IPreferencesFromApi> = yield preferencesApi.getPreferences();

    yield put(setPreferencesFromApiAction(res.data));
  } catch (e) {
    console.error(e);
    showActionError(type, e);
  }
}

export function* preferencesSaga() {
  yield all([takeLatest(getPreferencesAction, getPreferencesSaga)]);
}
