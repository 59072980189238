import axios from 'axios';
import { Dispatch } from 'redux';
import { setAuthenticatedAction } from '../redux/modules/auth/auth.actions';
import { store } from '../../shopify-supplier/redux/store';
import { refreshTokenApi } from './refresh-token.api';
import { logoutAction } from '../../standalone-retailer/redux/modules/auth/auth.actions';
import { parseJwt } from '../helpers/parse-jwt';

export const getShopifyApiAxiosInstance = (baseURL: string, dispatch: Dispatch) => {
  const instance = axios.create({ baseURL });

  instance.interceptors.response.use(
    (response) => {
      return response;
    },
    async (error) => {
      if (error.response.status === 401) {
        if (process.env.REACT_APP_APP_TYPE === 'STANDALONE_RETAILER') {
          const originalRequest = error.config;
          const token = localStorage.getItem('token');

          if (error.config && !error.config.isRetry && token) {
            try {
              const parsedToken = parseJwt(token);

              const response = await refreshTokenApi.updateAccessToken(parsedToken.id);
              if ('error' in response) {
                localStorage.removeItem('token');
                dispatch(logoutAction());
              }

              setAxiosAuthorization(response.token);
              localStorage.setItem('token', response.token);
              originalRequest.headers['Authorization'] = `Bearer ${response.token}`;

              return axiosInstance.request(originalRequest);
            } catch (error: any) {
              localStorage.removeItem('token');
              dispatch(logoutAction());
            }
          }
        }

        dispatch(setAuthenticatedAction(null));
      }

      return Promise.reject(error.response.data);
    },
  );

  return instance;
};

export const axiosInstance = getShopifyApiAxiosInstance(
  process.env.REACT_APP_BACK_END_URL as string,
  store.dispatch,
);

export const setAxiosAuthorization = (accessToken: string) => {
  axiosInstance.defaults.headers['Authorization'] = `Bearer ${accessToken}`;
};

export const setAxiosViewAsClient = (shopId: string | undefined) => {
  axiosInstance.defaults.headers['Crowdship-ViewAsClient-ShopId'] = shopId || '';
};
