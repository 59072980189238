import { createReducer } from 'typesafe-actions';
import { IProductSync } from '../../../interfaces/IProductsSync';
import { RootActions } from '../../root.actions';
import {
  getSyncConflictsAction,
  getSyncMatchesAction,
  hideLimitExceededBannerAction,
  hideLinkToastAction,
  linkMatchesAction,
  resolveConflictAction,
  setSyncConflictsAction,
  setSyncMatchesAction,
  setSyncStatusAction,
  setVendorsForMatchesAction,
  showLimitExceededBannerAction,
  showLinkToastAction,
} from './sync.actions';

export interface IProductSyncReducer {
  syncResult: IProductSync;
  vendorsForMatches: string[];
  fetchingConflicts: boolean;
  fetchingMatches: boolean;
  fetchingDisconnectedProducts: boolean;
  linkToast: {
    show: boolean;
    success?: boolean;
    failed?: number;
  };
  showLimitExceededBanner: boolean;
}

const initialState: IProductSyncReducer = {
  syncResult: {
    syncInProgress: false,
    matches: [],
    totalMatches: 0,
    conflicts: [],
    totalDisconnectedProducts: 0,
  },
  vendorsForMatches: [],
  fetchingConflicts: false,
  fetchingMatches: false,
  fetchingDisconnectedProducts: false,
  linkToast: { show: false },
  showLimitExceededBanner: false,
};

export const productSyncReducer = createReducer<IProductSyncReducer, RootActions>(initialState)
  .handleAction(getSyncMatchesAction, (state) => ({
    ...state,
    fetchingMatches: true,
  }))
  .handleAction(getSyncConflictsAction, (state) => ({
    ...state,
    fetchingConflicts: true,
  }))
  .handleAction(setSyncStatusAction, (state, { payload }) => ({
    ...state,
    syncResult: { ...state.syncResult, ...payload },
  }))
  .handleAction(setSyncMatchesAction, (state, { payload }) => ({
    ...state,
    syncResult: { ...state.syncResult, totalMatches: payload.total, ...payload },
    fetchingMatches: false,
  }))
  .handleAction(setVendorsForMatchesAction, (state, { payload }) => ({
    ...state,
    vendorsForMatches: payload,
  }))
  .handleAction(setSyncConflictsAction, (state, { payload }) => ({
    ...state,
    syncResult: { ...state.syncResult, ...payload },
    fetchingConflicts: false,
  }))
  .handleAction(linkMatchesAction, (state, { payload }) => ({
    ...state,
    syncResult: {
      ...state.syncResult,
      matches: state.syncResult.matches.filter(
        (pm) =>
          !payload.some(
            (linked) => linked.retailerProduct.shopifyId === pm.retailerProduct.shopifyId,
          ),
      ),
    },
  }))
  .handleAction(resolveConflictAction, (state, { payload }) => ({
    ...state,
    syncResult: {
      ...state.syncResult,
      conflicts: state.syncResult.conflicts.filter((c) => c._id !== payload.conflictId),
    },
  }))
  .handleAction(showLinkToastAction, (state, { payload }) => ({
    ...state,
    linkToast: { show: true, ...payload },
  }))
  .handleAction(hideLinkToastAction, (state) => ({
    ...state,
    linkToast: { show: false },
  }))
  .handleAction(showLimitExceededBannerAction, (state) => ({
    ...state,
    showLimitExceededBanner: true,
  }))
  .handleAction(hideLimitExceededBannerAction, (state) => ({
    ...state,
    showLimitExceededBanner: false,
  }));
