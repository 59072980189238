import React from 'react';
import { AppModal } from '../../../../../core/components/overlays/modal/modal';
import { AppTextContainer } from '../../../../../core/components/text-container/text-container';
import { SelectedProduct } from '../../../layouts/import-list-layout/import-list-layout';

interface IPushingProductModalProps {
  openPushingModal: boolean;
  selectedProducts: SelectedProduct[];
  handleClose: () => void;
  handleAddToInventory: (products: SelectedProduct[]) => void;
}

export const PushingProductModal = ({
  openPushingModal,
  selectedProducts,
  handleClose,
  handleAddToInventory,
}: IPushingProductModalProps) => {
  if (!openPushingModal) return null;

  return (
    <AppModal
      open={openPushingModal}
      onClose={handleClose}
      title="Unsaved changes will be lost"
      primaryAction={{
        content: 'Push To Store',
        onAction: () => {
          handleAddToInventory(selectedProducts.map((p) => ({ ...p, dirty: false })));
        },
      }}
      secondaryActions={[
        {
          content: 'Stay',
          onAction: () => {
            handleClose();
          },
        },
      ]}
    >
      <AppTextContainer>
        You've made changes to your product. Please save them before pushing to store.
      </AppTextContainer>
    </AppModal>
  );
};
