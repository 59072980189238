import { Checkbox, CheckboxProps } from '@shopify/polaris';
import { Field, useFormikContext } from 'formik';
import { ReactNode, useCallback, useEffect } from 'react';

interface IControlledCheckbox
  extends Omit<CheckboxProps, 'label' | 'name' | 'checked' | 'onChange' | 'id'> {
  label?: ReactNode;
  name: string;
}

export function AppControlledCheckbox({ name, label = '', ...props }: IControlledCheckbox) {
  const { registerField, unregisterField, setFieldValue, getFieldProps } = useFormikContext();

  useEffect(() => {
    registerField(name, {});
    return () => unregisterField(name);
  }, [name, registerField, unregisterField]);

  const field = getFieldProps({ name });

  const handleChange = useCallback(
    (value: boolean, id: string) => setFieldValue(id, value, false),
    [setFieldValue],
  );

  return (
    <Field name={name}>
      {({ value }) => (
        <Checkbox
          label={label}
          id={name}
          checked={field.value}
          onChange={handleChange}
          {...props}
        />
      )}
    </Field>
  );
}
