import { AxiosResponse } from 'axios';
import { axiosInstance } from '../../core/api/base.api';
import { IPayoutRequest } from '../interfaces/IOrder';
import { IPayoutWithOrders, IPayoutList, IPendingConfirmation } from '../interfaces/IPayout';

class PayoutsApi {
  private backend = process.env.REACT_APP_BACK_END_URL as string;
  private rootPath = '/api/supplier/shopify/payouts';

  async getPayouts(filter: {
    limit: number;
    page: number;
    status?: string;
    query?: string;
  }): Promise<AxiosResponse<IPayoutList>> {
    return await axiosInstance.get<IPayoutList>(`${this.backend}${this.rootPath}`, {
      params: filter,
    });
  }

  async createNewPayout(
    body: IPayoutRequest & { retailerId: string; ids: string[] },
  ): Promise<AxiosResponse<void>> {
    return await axiosInstance.post(`${this.backend}${this.rootPath}`, body);
  }

  async updatePayout(payoutId: string, body: IPayoutRequest): Promise<AxiosResponse<void>> {
    return await axiosInstance.put(`${this.backend}${this.rootPath}/${payoutId}`, body);
  }

  async getPayoutDetails(payoutId: string): Promise<AxiosResponse<IPayoutWithOrders>> {
    return await axiosInstance.get<IPayoutWithOrders>(
      `${this.backend}${this.rootPath}/${payoutId}`,
    );
  }

  async deletePayout(payoutId: string): Promise<AxiosResponse<any>> {
    return await axiosInstance.delete<any>(`${this.backend}${this.rootPath}/${payoutId}`);
  }

  async confirmPendingPayment(payoutId: string, confirmation: IPendingConfirmation) {
    return await axiosInstance.post<null>(
      `${this.backend}${this.rootPath}/${payoutId}/confirm-payment`,
      confirmation,
    );
  }

  async exportOrdersCSV(payoutId: string) {
    return await axiosInstance.post(
      `${this.backend}${this.rootPath}/${payoutId}`,
      {},
      {
        responseType: 'blob',
      },
    );
  }
}

export const payoutsApi = new PayoutsApi();
