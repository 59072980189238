import { capitalize } from 'lodash';
import { IInventoryProduct } from '../../../../interfaces/IProduct';
import { AppThumbnail } from '../../../image-containers/thumbnail/Thumbnail';
import { AppIndexTableCell } from '../../../index-table/index-table-cell';
import { AppIndexTableRow } from '../../../index-table/index-table-row';
import { AppTextStyle } from '../../../text/text-style/TextStyle';
import { ProductRowBaseProps } from '../../product-list.interfaces';
import { AverageMargin } from '../average-margin';
import { ExpandVariantsBtn } from '../expand-variants-btn';
import { LinkToDetails } from '../link-to-details';
import { LinkToPlatform } from '../link-to-platform';
import { ProcessingTag } from '../processing-tag';
import { TrackedQuantityLabelForProduct } from '../../../tracked-quantity-label-for-product/tracked-quantity-label-for-product';
import { AppBadge } from '../../../badge/badge';
import { useCallback } from 'react';

type InventoryProductRowProps = ProductRowBaseProps<IInventoryProduct> & {};

export const InventoryProductRow = ({
  product,
  selected,
  index,
  expandedVariants,
  toggleExpansion,
}: InventoryProductRowProps) => {
  const {
    id,
    title,
    image,
    productType,
    vendor,
    quantityAvailable,
    variationsNumber,
    averageMargin,
    processing,
    shopifyId,
    connected,
    shopifyStatus,
    products,
  } = product;

  const renderShopifyStatus = useCallback(() => {
    if (!shopifyStatus || shopifyStatus === 'processing') {
      return <AppBadge status="new">Processing</AppBadge>;
    }
    if (shopifyStatus === 'draft') {
      return (
        <AppBadge status="new" size="small">
          Draft
        </AppBadge>
      );
    }
    if (shopifyStatus === 'active') {
      return <AppBadge status="success">Active</AppBadge>;
    }
    return <AppBadge status="info">{capitalize(shopifyStatus)}</AppBadge>;
  }, [shopifyStatus]);

  return (
    <AppIndexTableRow id={id} key={id} selected={selected} position={index} subdued={processing}>
      <AppIndexTableCell>
        <div className="product-image">
          <AppThumbnail source={image} alt={title} />
        </div>
      </AppIndexTableCell>
      <AppIndexTableCell>
        <div className="product-title">
          <AppTextStyle variation="subdued">{vendor}</AppTextStyle>
          <AppTextStyle variation="strong">{title}</AppTextStyle>
          {processing && <ProcessingTag />}
          {!processing && shopifyId && (
            <div className="row-links">
              <LinkToPlatform visible={expandedVariants} platformId={shopifyId} />
              <LinkToDetails id={id} title={title} visible={expandedVariants} />
            </div>
          )}
        </div>
      </AppIndexTableCell>
      <AppIndexTableCell>{productType}</AppIndexTableCell>
      <AppIndexTableCell>
        <TrackedQuantityLabelForProduct quantity={quantityAvailable} variants={products} />
      </AppIndexTableCell>
      <AppIndexTableCell>
        <AverageMargin margin={averageMargin} />
      </AppIndexTableCell>
      <AppIndexTableCell>{renderShopifyStatus()}</AppIndexTableCell>
      <AppIndexTableCell>
        {connected ? (
          <AppBadge status="success">Connected</AppBadge>
        ) : (
          <AppBadge status="new" size="small">
            In-house
          </AppBadge>
        )}
      </AppIndexTableCell>
      <AppIndexTableCell>
        <ExpandVariantsBtn
          variantsCount={variationsNumber}
          expanded={expandedVariants}
          toggleExpansion={toggleExpansion}
        />
      </AppIndexTableCell>
    </AppIndexTableRow>
  );
};
