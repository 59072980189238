import { useCallback, useMemo, useState } from 'react';
import { Country } from '../../../../../../core/api/country.api';
import { AppSpinner } from '../../../../../../core/components/feedback-indicators/spinner/spinner';
import { AppControlledSelect } from '../../../../../../core/components/forms/controlled-select/controlled-select';
import { AppControlledTextField } from '../../../../../../core/components/forms/controlled-text-field/controlled-text-field';
import { validation } from '../../../../../../core/helpers/validations.helper';
import { preferencesApi } from '../../../../../api/preferences.api';
import { IPreferences, ICustomAddress } from '../../../../../interfaces/IPreferences';
import './shipping-section.scss';

type Props = {
  values: IPreferences;
  countries: Country[];
  setFieldValue: (field: string, value: any) => void;
  addressType: 'warehouseAddress' | 'fallbackAddress';
  disableCountrySelect?: boolean;
};

export const CustomAddressForm = ({
  values,
  setFieldValue,
  countries,
  addressType,
  disableCountrySelect = false,
}: Props) => {
  const [selectedCountry, setSelectedCountry] = useState(
    values.shipping[addressType]?.countryCode || '',
  );

  const [validatingAddress, setValidatingAddress] = useState(false);
  const [cachedAddressValidation, setCachedAddressValidation] = useState<
    Promise<string | undefined> | undefined | string
  >(undefined);

  const countriesOption = useMemo(
    () => countries.map((c) => ({ label: c.name, value: c.iso2 })),
    [countries],
  );

  const states = useMemo(
    () =>
      selectedCountry
        ? countries
            .find((c) => c.iso2 === selectedCountry)
            ?.states.map((s) => ({
              label: s.name,
              value: s.state_code,
            })) || []
        : [],
    [selectedCountry, countries],
  );

  const validateAddress = useCallback(
    (customAddress: ICustomAddress) => {
      const addressFilled = customAddress.address1 && customAddress.city && customAddress.zip;

      const useCustomAddress =
        values.shipping[
          `useCustom${addressType.charAt(0).toUpperCase() + addressType.slice(1)}`
        ] === 'use default';

      // ? addressFilled
      if (useCustomAddress || !addressFilled) {
        setCachedAddressValidation(undefined);
        return;
      }

      setValidatingAddress(true);
      let errorMessage: string | undefined;
      const validating = new Promise<string | undefined>((resolve, reject) => {
        preferencesApi
          .validateAddress({
            ...customAddress,
            address2: customAddress.address2 || undefined,
            province: customAddress.province || undefined,
            provinceCode: customAddress.provinceCode || undefined,
          })
          .then(({ data }) => {
            if (!data.isValid) {
              errorMessage = 'Invalid address';
            }
            resolve(errorMessage);
          })
          .catch((error) => {
            console.error(error);
            reject(error);
          })
          .finally(() => {
            setFieldValue(`shipping.warehouseAddress.${addressType}Valid`, true);
            setValidatingAddress(false);
          });
      });
      setCachedAddressValidation(validating);
    },
    [values.shipping, addressType, setFieldValue],
  );

  const nonEmptyAddressFieldValidation = useCallback(
    (value) => validation.isNotEmpty(value) || cachedAddressValidation,
    [cachedAddressValidation],
  );

  const handleChangeProvince = useCallback(
    (country: string) => {
      const provinces = country
        ? countries
            .find((c) => c.iso2 === country)
            ?.states.map((s) => ({
              label: s.name,
              value: s.state_code,
            })) || []
        : [];
      setFieldValue(`shipping.${addressType}.provinceCode`, provinces[0].value || '');
      setFieldValue(`shipping.${addressType}.province`, provinces[0].label || '');
    },
    [addressType, countries, setFieldValue],
  );

  return (
    <>
      <AppControlledSelect
        label="Country"
        name={`shipping.${addressType}.countryCode`}
        options={countriesOption}
        onChange={(value) => {
          const country = countriesOption.find((c) => c.value === value)?.label;
          setSelectedCountry(value);
          setFieldValue(`shipping.${addressType}.country`, country);
          handleChangeProvince(value);
        }}
        disabled={disableCountrySelect}
      />
      <AppControlledSelect
        label="Province"
        name={`shipping.${addressType}.provinceCode`}
        options={states}
        onChange={(value) => {
          const province = states.find((s) => s.value === value)?.label;
          setFieldValue(`shipping.${addressType}.province`, province);
        }}
        onBlur={() => {
          validateAddress(values.shipping[addressType]);
        }}
      />
      <AppControlledTextField
        requiredIndicator
        label="Address"
        name={`shipping.${addressType}.address1`}
        validate={nonEmptyAddressFieldValidation}
        suffix={validatingAddress && <AppSpinner size="small" />}
        onBlur={() => {
          validateAddress(values.shipping[addressType]);
        }}
      />
      <AppControlledTextField
        label="Apartment, suite, etc."
        name={`shipping.${addressType}.address2`}
        onBlur={() => {
          validateAddress(values.shipping[addressType]);
        }}
      />
      <AppControlledTextField
        requiredIndicator
        label="City"
        name={`shipping.${addressType}.city`}
        validate={nonEmptyAddressFieldValidation}
        suffix={validatingAddress && <AppSpinner size="small" />}
        onBlur={() => {
          validateAddress(values.shipping[addressType]);
        }}
      />
      <AppControlledTextField
        requiredIndicator
        label="Zip code"
        name={`shipping.${addressType}.zip`}
        validate={nonEmptyAddressFieldValidation}
        suffix={validatingAddress && <AppSpinner size="small" />}
        onBlur={() => {
          validateAddress(values.shipping[addressType]);
        }}
      />
    </>
  );
};
