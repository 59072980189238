import { axiosInstance } from '../../core/api/base.api';
import { IShopViewDetails } from '../../core/interfaces/IAccount';

class SupplierApi {
  private backend = process.env.REACT_APP_BACK_END_URL as string;
  private rootPath = '/api/supplier';

  async getSuppliers() {
    return await axiosInstance.get<IShopViewDetails[]>(`${this.backend}${this.rootPath}`);
  }

  async updateAccount(query: string) {
    return axiosInstance.get<{ urlToRedirect: string }>(
      `${this.backend}${this.rootPath}/shopify/api-update/callback${query}`,
    );
  }
}

export const supplierApi = new SupplierApi();
