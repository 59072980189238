import React, { useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import { AppButton } from '../../../../core/components/button/Button';
import { AppToast } from '../../../../core/components/feedback-indicators/toast/toast';
import { AppCard } from '../../../../core/components/structure/card/card';
import { AppPage } from '../../../../core/components/structure/page/page';
import { AppTextContainer } from '../../../../core/components/text-container/text-container';
import { AppTextStyle } from '../../../../core/components/text/text-style/TextStyle';
import { authApi } from '../../../api/auth.api';
import { getIntegrationsSelector } from '../../../redux/modules/auth/auth.selectors';
import { ContinueWithShopifyModal } from '../../containers/modals/continue-with-shopify-modal';

export const IntegrationsLayout = () => {
  const [openModal, setOpenModal] = useState(false);
  const [error, setError] = useState('');
  const integrations = useSelector(getIntegrationsSelector);

  const handleShopifyConnect = useCallback((shop: string) => {
    authApi
      .getShopifyAppInstallUrl(shop)
      .then(({ data }) => {
        if ('accountExists' in data) {
          setError(
            'This shop is already connected to a different account. You can sign in using this shop.',
          );
        } else if ('url' in data) {
          window.open(data.url, '_self');
        }
      })
      .catch(() => {
        setError('Unexpected error');
      });
  }, []);

  return (
    <AppPage title="Integrations">
      <AppCard title="Shopify" sectioned>
        {!!integrations.length && (
          <AppTextContainer>
            <p>
              You're integrated with Shopify using store{' '}
              <AppTextStyle variation="positive">{integrations[0].shop}</AppTextStyle>
            </p>
          </AppTextContainer>
        )}
        {!integrations.length && (
          <AppTextContainer>
            <p>You don't have a connected Shopify store yet.</p>
            <AppButton onClick={() => setOpenModal(true)}>Connect store</AppButton>
          </AppTextContainer>
        )}
      </AppCard>
      {openModal && (
        <ContinueWithShopifyModal
          onCancel={() => setOpenModal(false)}
          onSuccess={handleShopifyConnect}
        />
      )}
      {error && <AppToast content={error} onDismiss={() => setError('')} error />}
    </AppPage>
  );
};
