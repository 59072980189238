import { lowerCase } from 'lodash';
import { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getInventoryFilterOptionsAction } from '../../../../../shopify-retailer/redux/modules/inventory/inventory.actions';
import { getInventoryFilterOptionsSelector } from '../../../../../shopify-retailer/redux/modules/inventory/inventory.selectors';
import {
  resetMarketplaceRetailerProductsFiltersAction,
  setMarketplaceRetailerProductsConnectionFilterAction,
  setMarketplaceRetailerProductsQueryFilterAction,
  setMarketplaceRetailerProductsSupplierFilterAction,
  setMarketplaceRetailerProductsTypeFilterAction,
  setMarketplaceRetailerProductsVendorFilterAction,
} from '../../../../../shopify-retailer/redux/modules/marketplace/marketplace.actions';
import { getMarketplaceConnectionFiltersSelector } from '../../../../../shopify-retailer/redux/modules/marketplace/marketplace.selectors';
import { IRetailerProductsFilters } from '../../../../interfaces/IProductFilter';
import { IAppliedFilter } from '../../../filters/filters';
import '../../product-list.scss';
import { SKULimit } from '../sku-limit';
import {
  ConnectionFilter,
  CONNECTION_FILTER_KEY,
  convertToConnectionEnum,
} from './filter-elements/connection-filter';
import { QueryFilterContainer } from './filter-elements/query-filter-container';
import { SupplierFilter, SUPPLIER_FILTER_KEY } from './filter-elements/supplier-filter';
import { TypeFilter, TYPE_FILTER_KEY } from './filter-elements/type-filter';
import { VendorFilter, VENDOR_FILTER_KEY } from './filter-elements/vendor-filter';

export const ConnectionProductFilter = () => {
  const dispatch = useDispatch();
  const filters: IRetailerProductsFilters = useSelector(getMarketplaceConnectionFiltersSelector);
  const { vendors, types, fulfilledBy } = useSelector(getInventoryFilterOptionsSelector);

  useEffect(() => {
    dispatch(getInventoryFilterOptionsAction());
  }, [dispatch]);

  useEffect(() => {
    return () => {
      dispatch(resetMarketplaceRetailerProductsFiltersAction());
    };
  }, [dispatch]);

  const appliedFilters: IAppliedFilter[] = useMemo(() => {
    const appliedVendors = {
      key: VENDOR_FILTER_KEY,
      label: `Chosen brands: ${filters.vendors.join(', ')}`,
      onRemove: () => dispatch(setMarketplaceRetailerProductsVendorFilterAction({ vendors: [] })),
    };
    const appliedSuppliers = {
      key: SUPPLIER_FILTER_KEY,
      label: `Chosen suppliers: ${fulfilledBy
        .filter((fb) => filters.fulfilledBy.find((ffb) => ffb === fb.value))
        .map((fb) => fb.label)
        .join(', ')}`,
      onRemove: () =>
        dispatch(setMarketplaceRetailerProductsSupplierFilterAction({ fulfilledBy: [] })),
    };
    const appliedTypes = {
      key: TYPE_FILTER_KEY,
      label: `Chosen types: ${filters.types.join(', ')}`,
      onRemove: () => dispatch(setMarketplaceRetailerProductsTypeFilterAction({ types: [] })),
    };
    const appliedConnection = {
      key: CONNECTION_FILTER_KEY,
      label: `Showing ${lowerCase(convertToConnectionEnum(filters.isConnected))}`,
      onRemove: () =>
        dispatch(setMarketplaceRetailerProductsConnectionFilterAction({ isConnected: undefined })),
    };

    const chosenVendors = filters.vendors.length ? [appliedVendors] : [];
    const chosenSuppliers = filters.fulfilledBy.length ? [appliedSuppliers] : [];
    const chosenTypes = filters.types.length ? [appliedTypes] : [];
    const chosenConnection = filters.isConnected !== undefined ? [appliedConnection] : [];

    return [...chosenVendors, ...chosenSuppliers, ...chosenTypes, ...chosenConnection];
  }, [
    filters.vendors,
    filters.types,
    filters.isConnected,
    filters.fulfilledBy,
    fulfilledBy,
    dispatch,
  ]);

  return (
    <>
      <SKULimit />
      <QueryFilterContainer
        value={filters.query}
        onChange={(query) => dispatch(setMarketplaceRetailerProductsQueryFilterAction({ query }))}
        filters={[
          VendorFilter({
            value: filters.vendors,
            onChange: (vendors) =>
              dispatch(setMarketplaceRetailerProductsVendorFilterAction({ vendors })),
            options: vendors,
          }),
          SupplierFilter({
            value: filters.fulfilledBy,
            onChange: (fulfilledBy) =>
              dispatch(setMarketplaceRetailerProductsSupplierFilterAction({ fulfilledBy })),
            options: fulfilledBy,
          }),
          TypeFilter({
            value: filters.types,
            onChange: (types) =>
              dispatch(setMarketplaceRetailerProductsTypeFilterAction({ types })),
            options: types,
          }),
          ConnectionFilter({
            value: filters.isConnected,
            onChange: (isConnected) =>
              dispatch(setMarketplaceRetailerProductsConnectionFilterAction({ isConnected })),
          }),
        ]}
        appliedFilters={appliedFilters}
        onClearAll={() => dispatch(resetMarketplaceRetailerProductsFiltersAction())}
      />
    </>
  );
};
