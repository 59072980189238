import React from 'react';
import { Layout } from '@shopify/polaris';

type ILayoutAnnotatedSectionProps = {
  children?: React.ReactNode;
  title?: React.ReactNode;
  description?: React.ReactNode;
};

export function AppLayoutAnnotatedSection(props: ILayoutAnnotatedSectionProps) {
  return <Layout.AnnotatedSection {...props} />;
}
