import React from 'react';
import { Card } from '@shopify/polaris';
import { ArrowRightIcon } from '@shopify/polaris-icons';
import { AppIcon } from '../../../../../core/components/icon/icon';
import { AppThumbnail } from '../../../../../core/components/image-containers/thumbnail/Thumbnail';
import { AppLink } from '../../../../../core/components/link/link';
import { AppTextStyle } from '../../../../../core/components/text/text-style/TextStyle';
import { AppControlledTextField } from '../../../../../core/components/forms/controlled-text-field/controlled-text-field';
import { IImportListProductGroup } from '../../../../api/import-list.api';
import { validation } from '../../../../../core/helpers/validations.helper';
import { AppControlledTagField } from '../../../../../core/components/forms/controlled-tag-field/controlled-tag-field';

interface ProductDetailsTabProps {
  title: string;
  values: IImportListProductGroup;
  setFieldValue: (field: string, value: any) => void;
}

export const ProductDetailsTab = ({ title, values, setFieldValue }: ProductDetailsTabProps) => (
  <Card.Section>
    <div className="product-section">
      <AppThumbnail source={values.image} size="large" alt="Product image" />
      <div className="product-section__info">
        <AppTextStyle variation="strong">{title}</AppTextStyle>
        <p className="product-section__info__links">
          <AppLink url={`marketplace/${values.crowdshipProductGroup}`}>View product</AppLink>{' '}
          <AppIcon source={ArrowRightIcon} color="base" />
        </p>
        <div className="product-section__info__inputs">
          <AppControlledTextField
            label={'Product Name'}
            name={'title'}
            validate={validation.isNotEmpty}
          />
          <AppControlledTextField label={'Brand'} name={'vendor'} />
          <AppControlledTextField label={'Product Type'} name={'productType'} />
          <AppControlledTagField
            label="Product Tags"
            name="tags"
            tags={values.tags}
            setFieldValue={setFieldValue}
          />
        </div>
      </div>
    </div>
  </Card.Section>
);
