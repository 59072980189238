import fileDownload from 'js-file-download';
import moment from 'moment';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { AppToast } from '../../../../core/components/feedback-indicators/toast/toast';
import { AppCard } from '../../../../core/components/structure/card/card';
import { AppPage } from '../../../../core/components/structure/page/page';
import { PayoutSummary } from '../../../../shopify-supplier/components/containers/payout-summary/payout-summary';
import { IPendingConfirmation } from '../../../../shopify-supplier/interfaces/IPayout';
import { invoiceApi } from '../../../api/invoice.api';
import { IInvoiceWithOrders } from '../../../interfaces/IInvoice';
import { InvoiceDetailsList } from '../../containers/invoice-details-list/invoice-details-list';
import { SupplierPayoutInfo } from '../../containers/supplier-payout-info/supplier-payout-info';
import { PaymentConfirmationModal } from '../../modals/payment-confirmation-modal/payment-confirmation-modal';
import './invoice-details-layout.scss';

export const InvoiceDetailsLayout = () => {
  const { id: invoiceId } = useParams<{ id: string }>();
  const [loading, setLoading] = useState<boolean>(false);
  const [details, setDetails] = useState<IInvoiceWithOrders | null>(null);
  const [openPaymentConfirmationModal, setOpenPaymentConfirmationModal] = useState<boolean>(false);
  const [creatingPayment, setCreatingPayment] = useState<boolean>(false);
  const [downloading, setDownloading] = useState<boolean>(false);

  useEffect(() => {
    setLoading(true);
    invoiceApi
      .getInvoiceDetails(invoiceId)
      .then(({ data }) => {
        setDetails(data);
      })
      .catch(console.error)
      .finally(() => setLoading(false));
  }, [invoiceId]);

  const handleExportCSV = useCallback(() => {
    setLoading(true);
    invoiceApi
      .exportUnpaidInvoice(invoiceId)
      .then(({ data }) => {
        const now = moment().format('MMDDYYYY');
        fileDownload(data, `Open Invoice-${now}.csv`);
      })
      .catch(console.error)
      .finally(() => {
        setLoading(false);
      });
  }, [invoiceId]);

  const handleConfirmationCreate = useCallback(
    (pendingConfirmation: IPendingConfirmation) => {
      setOpenPaymentConfirmationModal(false);
      setCreatingPayment(true);
      invoiceApi
        .createPayment(invoiceId, pendingConfirmation)
        .then(() => {
          setDetails((d) => d && { ...d, pendingConfirmation });
        })
        .catch(console.error)
        .finally(() => {
          setCreatingPayment(false);
        });
    },
    [invoiceId],
  );

  const handleDownloadPdf = useCallback(() => {
    setDownloading(true);
    invoiceApi
      .getInvoicePdf(invoiceId)
      .then(({ data }) => {
        fileDownload(data, `Invoice-${invoiceId}.pdf`);
      })
      .catch(console.error)
      .finally(() => {
        setDownloading(false);
      });
  }, [invoiceId]);

  const showMarkAsPaidBtn = useMemo(() => details?.status === 'open', [details]);

  return (
    <div className="invoice-details-layout">
      <AppPage
        title="Invoice details"
        titleBarTitle={details?.name}
        breadcrumbs={[{ content: 'Accounting', url: '/accounting' }]}
        primaryAction={
          showMarkAsPaidBtn
            ? {
                content: details?.pendingConfirmation ? 'Pending confirmation' : 'Mark as paid',
                onAction: () => setOpenPaymentConfirmationModal(true),
                loading: creatingPayment,
                disabled: details?.pendingConfirmation,
              }
            : undefined
        }
        secondaryActions={[
          {
            content: 'Download PDF',
            onAction: handleDownloadPdf,
            loading: downloading,
          },
        ]}
      >
        <div className="invoice-details-layout__addition-info">
          <PayoutSummary payout={details} loading={loading} />
          <SupplierPayoutInfo payoutInfo={details && details.payoutPreference} loading={loading} />
        </div>
        <AppCard title="Orders" sectioned>
          <InvoiceDetailsList
            invoiceItems={details?.invoiceItems || []}
            fetching={loading}
            totalPageCount={1}
            onPageChange={() => {}}
            handleExportCSV={handleExportCSV}
          />
        </AppCard>
      </AppPage>
      {creatingPayment && (
        <AppToast content="Creating payment..." onDismiss={() => setCreatingPayment(false)} />
      )}
      {details && (
        <PaymentConfirmationModal
          open={openPaymentConfirmationModal}
          onClose={() => setOpenPaymentConfirmationModal(false)}
          onConfirm={handleConfirmationCreate}
          payout={details}
          ordersCount={details.invoiceItems.length}
          supplierName={details.invoiceItems[0].supplierShopName}
        />
      )}
    </div>
  );
};
