import { AxiosResponse } from 'axios';
import { all, put, select, takeEvery, takeLatest } from 'redux-saga/effects';
import { ActionType } from 'typesafe-actions';
import { ORDERS_PER_PAGE, PAYOUTS_PER_PAGE } from '../../../../core/constants/orders';
import { showActionError } from '../../../../core/helpers/saga-error-catcher.helper';
import {
  IGetTotalForFilteredInvoiceItemsResponse,
  IRetailersForUnpaidOrders,
  ordersApi,
} from '../../../api/orders.api';
import { payoutsApi } from '../../../api/payouts.api';
import {
  IOrderDetails,
  IOrderList,
  IOrderListFilter,
  IOrdersSummary,
  IUnpaidOrdersSummary,
} from '../../../interfaces/IOrder';
import { IPayoutList } from '../../../interfaces/IPayout';
import { getStatusAction } from '../status/status.actions';
import {
  acceptCancellationRequestAction,
  cancelLineItemsAction,
  createNewPayoutAction,
  declineCancellationRequestAction,
  finishAcceptCancellationRequestAction,
  getOrderDetailsAction,
  getOrdersListAction,
  getOrdersSummaryAction,
  getPayoutsAction,
  getRetailersForUnpaidOrdersAction,
  getUnpaidOrdersListAction,
  getUnpaidOrdersSummaryAction,
  getUnpaidOrdersTotalsAllAction,
  orderListFilterAction,
  refundOrderAction,
  setOrderDetailsAction,
  setOrderListFilterAction,
  setOrdersListAction,
  setOrdersListFetchingAction,
  setOrdersSummaryAction,
  setOrdersSummaryFetchingAction,
  setPayoutFetchingAction,
  setPayoutListAction,
  setRetailersForUnpaidOrdersAction,
  setUnpaidOrdersListAction,
  setUnpaidOrdersListFetchingAction,
  setUnpaidOrdersSummaryAction,
  setUnpaidOrdersTotalDetailsAction,
  updatePayoutAction,
} from './orders.actions';
import { getOrdersFiltersSelector } from './orders.selectors';

function* getSummarySaga(action: ActionType<typeof getOrdersSummaryAction>) {
  yield put(setOrdersSummaryFetchingAction(true));
  try {
    const res: AxiosResponse<IOrdersSummary> = yield ordersApi.getOrdersSummary();
    yield put(setOrdersSummaryAction(res.data));
  } catch (e) {
    showActionError(action.type, e);
  } finally {
    yield put(setOrdersSummaryFetchingAction(false));
  }
}

function* getUnpaidOrdersSummarySaga(action: ActionType<typeof getUnpaidOrdersSummaryAction>) {
  yield put(setOrdersSummaryFetchingAction(true));
  try {
    const res: AxiosResponse<IUnpaidOrdersSummary> = yield ordersApi.getUnpaidOrdersSummary();
    yield put(setUnpaidOrdersSummaryAction(res.data));
  } catch (e) {
    showActionError(action.type, e);
  } finally {
    yield put(setOrdersSummaryFetchingAction(false));
  }
}

function* getOrdersListSaga({ payload, type }: ActionType<typeof getOrdersListAction>) {
  yield put(setOrdersListFetchingAction(true));
  try {
    const res: AxiosResponse<IOrderList> = yield ordersApi.getOrdersList(payload);
    yield put(setOrdersListAction(res.data));
  } catch (e) {
    showActionError(type, e);
  } finally {
    yield put(setOrdersListFetchingAction(false));
  }
}

function* orderListFilterSaga({ payload, type }: ActionType<typeof orderListFilterAction>) {
  try {
    yield put(setOrderListFilterAction(payload));

    yield put(getUnpaidOrdersListAction(0));
    yield put(getUnpaidOrdersTotalsAllAction(payload.retailerId, payload.dateRange));
  } catch (e) {
    showActionError(type, e);
  }
}

function* getUnpaidOrdersListSaga({ payload, type }: ActionType<typeof getUnpaidOrdersListAction>) {
  yield put(setUnpaidOrdersListFetchingAction(true));
  try {
    const filters: IOrderListFilter = yield select(getOrdersFiltersSelector);

    const res: AxiosResponse<IOrderList> = yield ordersApi.getUnpaidOrdersList({
      ...filters,
      page: payload,
      limit: ORDERS_PER_PAGE,
    });

    yield put(setUnpaidOrdersListAction(res.data));
  } catch (e) {
    showActionError(type, e);
  } finally {
    yield put(setUnpaidOrdersListFetchingAction(false));
  }
}

function* getUnpaidOrdersTotalsAllSaga({
  payload,
  type,
}: ActionType<typeof getUnpaidOrdersTotalsAllAction>) {
  yield put(setOrdersListFetchingAction(true));
  try {
    const res: AxiosResponse<IGetTotalForFilteredInvoiceItemsResponse> =
      yield ordersApi.getTotalForFilteredInvoiceItems({
        retailerId: payload.retailerId,
        dateRange: payload.dateRange,
      });

    yield put(setUnpaidOrdersTotalDetailsAction(res.data));
  } catch (e) {
    showActionError(type, e);
  } finally {
    yield put(setOrdersListFetchingAction(false));
  }
}

function* getRetailersForUnpaidOrdersSaga({
  type,
}: ActionType<typeof getRetailersForUnpaidOrdersAction>) {
  yield put(setOrdersListFetchingAction(true));
  try {
    const res: AxiosResponse<IRetailersForUnpaidOrders[]> =
      yield ordersApi.getRetailersForUnpaidOrders();

    yield put(setRetailersForUnpaidOrdersAction(res.data));
  } catch (e) {
    showActionError(type, e);
  } finally {
    yield put(setOrdersListFetchingAction(false));
  }
}

function* getOrderDetailsSaga({ payload, type }: ActionType<typeof getOrderDetailsAction>) {
  try {
    const res: AxiosResponse<IOrderDetails> = yield ordersApi.getOrderDetails(payload);
    yield put(setOrderDetailsAction(payload, res.data));
  } catch (e) {
    showActionError(type, e);
  }
}

function* getPayoutsSaga({ payload, type }: ActionType<typeof getPayoutsAction>) {
  yield put(setPayoutFetchingAction(true));
  try {
    const { data }: AxiosResponse<IPayoutList> = yield payoutsApi.getPayouts({
      ...payload,
      limit: PAYOUTS_PER_PAGE,
    });

    yield put(setPayoutListAction(data));
  } catch (e) {
    showActionError(type, e);
  } finally {
    yield put(setPayoutFetchingAction(false));
  }
}

function* createNewPayoutSaga({ payload, type }: ActionType<typeof createNewPayoutAction>) {
  yield put(setOrdersSummaryFetchingAction(true));
  try {
    const filters: IOrderListFilter = yield select(getOrdersFiltersSelector);

    yield payoutsApi.createNewPayout(payload);

    yield put(getUnpaidOrdersListAction(0));
    yield put(getUnpaidOrdersTotalsAllAction(filters.retailerId, filters.dateRange));
    yield put(getUnpaidOrdersSummaryAction());
    yield put(getRetailersForUnpaidOrdersAction());
  } catch (e) {
    showActionError(type, e);
  } finally {
    yield put(setOrdersSummaryFetchingAction(false));
  }
}

function* updatePayoutSaga({ payload, type }: ActionType<typeof updatePayoutAction>) {
  yield put(setPayoutFetchingAction(true));
  try {
    yield payoutsApi.updatePayout(payload.payoutId, payload.payoutDetails);

    yield put(getPayoutsAction(payload.page));
  } catch (e) {
    yield put(setPayoutFetchingAction(false));
    showActionError(type, e);
  }
}

function* acceptCancellationRequestSaga({
  payload,
  type,
}: ActionType<typeof acceptCancellationRequestAction>) {
  try {
    yield ordersApi.acceptCancellationRequest(payload);
    yield put(getOrderDetailsAction(payload));
    yield put(getStatusAction());
  } catch (e) {
    showActionError(type, e);
  } finally {
    yield put(finishAcceptCancellationRequestAction());
  }
}

function* declineCancellationRequestSaga({
  payload,
  type,
}: ActionType<typeof declineCancellationRequestAction>) {
  try {
    yield ordersApi.declineCancellationRequest(payload);
    yield put(getOrderDetailsAction(payload));
    yield put(getStatusAction());
  } catch (e) {
    showActionError(type, e);
  }
}

function* cancelLineItemsSaga({ payload, type }: ActionType<typeof cancelLineItemsAction>) {
  const { orderId, body } = payload;
  try {
    yield ordersApi.cancelLineItems(orderId, body);
    yield put(getOrderDetailsAction(orderId));
  } catch (e) {
    showActionError(type, e);
  }
}

function* refundOrderSaga({ payload, type }: ActionType<typeof refundOrderAction>) {
  const { orderId, body } = payload;
  try {
    yield ordersApi.refundOrder(orderId, body);
    yield put(getOrderDetailsAction(orderId));
  } catch (e) {
    showActionError(type, e);
  }
}

export function* ordersSaga() {
  yield all([
    takeLatest(getOrdersSummaryAction, getSummarySaga),
    takeLatest(getUnpaidOrdersSummaryAction, getUnpaidOrdersSummarySaga),
    takeLatest(getOrdersListAction, getOrdersListSaga),
    takeLatest(getUnpaidOrdersListAction, getUnpaidOrdersListSaga),
    takeLatest(getUnpaidOrdersTotalsAllAction, getUnpaidOrdersTotalsAllSaga),
    takeLatest(getRetailersForUnpaidOrdersAction, getRetailersForUnpaidOrdersSaga),
    takeLatest(createNewPayoutAction, createNewPayoutSaga),
    takeLatest(orderListFilterAction, orderListFilterSaga),
    takeLatest(getPayoutsAction, getPayoutsSaga),
    takeLatest(updatePayoutAction, updatePayoutSaga),
    takeEvery(getOrderDetailsAction, getOrderDetailsSaga),
    takeEvery(acceptCancellationRequestAction, acceptCancellationRequestSaga),
    takeEvery(declineCancellationRequestAction, declineCancellationRequestSaga),
    takeEvery(cancelLineItemsAction, cancelLineItemsSaga),
    takeEvery(refundOrderAction, refundOrderSaga),
  ]);
}
