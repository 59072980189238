import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { AppToast } from '../../../../core/components/feedback-indicators/toast/toast';
import { IMPORT_LIST_PRODUCTS_PER_PAGE } from '../../../../core/constants/product.constants';
import { IRetailerStatus } from '../../../interfaces/IPlan';
import {
  getImportListProductsAction,
  hideInventoryProductsAddedToastAction,
  hideProductRemovedToastAction,
} from '../../../redux/modules/import-list/import-list.actions';
import {
  fetchingImportListProductsSelector,
  getImportListProductsPageSelector,
  getImportListProductsSelector,
  getTotalImportListProductsCountSelector,
  showProductsAddedToInventoryToastSelector,
  showProductsARemovedToastSelector,
} from '../../../redux/modules/import-list/import-list.selectors';
import { getStatusAction } from '../../../redux/modules/status/status.actions';
import { getStatusSelector } from '../../../redux/modules/status/status.selectors';
import { ImportList } from './import-list';
import { LoadingWrapper } from '../../../../core/components/loading-wrapper/loading-wrapper';
import { ImportListSkeleton } from './import-list-skeleton/import-list-skeleton';

import './import-list-layout.scss';
import { PagePagination } from '../../../../core/components/pagination/redux-pagination/page-pagination';

export type SelectedProduct = { id: string; dirty: boolean };

export function ImportListLayout() {
  const history = useHistory();
  const dispatch = useDispatch();

  const [pendingProducts, setPendingProducts] = useState<SelectedProduct[]>([]);

  const status = useSelector(getStatusSelector);
  const products = useSelector(getImportListProductsSelector);
  const total = useSelector(getTotalImportListProductsCountSelector);
  const page = useSelector(getImportListProductsPageSelector);
  const fetching = useSelector(fetchingImportListProductsSelector);

  const productRemoved = useSelector(showProductsARemovedToastSelector);
  const productsAdded = useSelector(showProductsAddedToInventoryToastSelector);

  useEffect(() => {
    dispatch(getStatusAction());
  }, [dispatch]);

  useEffect(() => {
    dispatch(getImportListProductsAction(0));
  }, [dispatch]);

  const totalPageCount = useMemo(
    () => (total ? Math.ceil(total / IMPORT_LIST_PRODUCTS_PER_PAGE) : total),
    [total],
  );

  const hideToast = useCallback(() => {
    dispatch(hideProductRemovedToastAction());
  }, [dispatch]);

  const productRemovedToast = useMemo(
    () => (productRemoved ? <AppToast onDismiss={hideToast} content="Product removed" /> : null),
    [productRemoved, hideToast],
  );

  const handlePageChange = useCallback(
    (page: number) => {
      dispatch(getImportListProductsAction(page));
    },
    [dispatch],
  );

  const hideProductsAddedToast = useCallback(() => {
    dispatch(hideInventoryProductsAddedToastAction());
  }, [dispatch]);

  const handleSetPendingProducts = useCallback((products: SelectedProduct[]) => {
    setPendingProducts(products);
  }, []);

  return (
    <div className="import-list-wrapper">
      <LoadingWrapper loading={fetching || !status} loaderComponent={<ImportListSkeleton />}>
        <ImportList
          products={products}
          fetching={fetching}
          status={status as IRetailerStatus}
          pendingProducts={pendingProducts}
          setPendingProducts={handleSetPendingProducts}
          totalPageCount={totalPageCount}
        />
      </LoadingWrapper>
      <PagePagination
        currentPage={page}
        totalPageCount={totalPageCount}
        onPageChange={handlePageChange}
      />
      {productRemovedToast}
      {productsAdded && (
        <AppToast
          onDismiss={hideProductsAddedToast}
          content="Products added"
          action={{
            accessibilityLabel: 'Go to My Products',
            content: 'Go to My Products',
            onAction: () => history.push('/my-products'),
          }}
        />
      )}
    </div>
  );
}
