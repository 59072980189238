import { ICurrency } from '../interfaces/ICurrency';

export const withCurrency = (
  amount: number | string,
  currency: ICurrency = { isoCode: 'USD', symbol: '$' },
) => {
  const price = Math.abs(+amount).toLocaleString(undefined, {
    maximumFractionDigits: 2,
    minimumFractionDigits: 2,
  });

  if (!currency.symbol) return `${price} ${currency.isoCode}`;
  if (amount >= 0) return `${currency.symbol}${price}`;

  return `-${currency.symbol}${price}`;
};
