import { DatePicker, DatePickerProps } from '@shopify/polaris';
import { Field, useFormikContext } from 'formik';
import moment from 'moment';
import { useCallback, useEffect, useState } from 'react';

interface IControlledDatePicker extends Omit<DatePickerProps, 'onChange' | 'month' | 'year'> {
  name: string;
}

export function AppControlledDatePicker({ name, allowRange, ...props }: IControlledDatePicker) {
  const { registerField, unregisterField, setFieldValue, getFieldProps } = useFormikContext();

  const field = getFieldProps({ name });

  const [{ month, year }, setDate] = useState({
    month: field.value ? moment(field.value.start).month() : moment().month(),
    year: field.value ? moment(field.value.start).year() : moment().year(),
  });

  const handleMonthChange = useCallback((month, year) => setDate({ month, year }), []);

  useEffect(() => {
    registerField(name, {});
    return () => unregisterField(name);
  }, [name, registerField, unregisterField]);

  const handleChange = useCallback(
    (value: { start: Date; end: Date }) =>
      allowRange ? setFieldValue(name, value) : setFieldValue(name, value.start),
    [name, setFieldValue, allowRange],
  );

  return (
    <Field name={name}>
      {() => (
        <DatePicker
          {...props}
          allowRange={allowRange}
          id={name}
          month={month}
          year={year}
          selected={field.value}
          onChange={handleChange}
          onMonthChange={handleMonthChange}
        />
      )}
    </Field>
  );
}
