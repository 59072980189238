import { DatePicker, EmptySearchResult } from '@shopify/polaris';
import moment from 'moment';
import queryString from 'query-string';
import React, { ReactNode, useCallback, useMemo, useState } from 'react';
import { useHistory, useLocation } from 'react-router';
import { useDebouncedCallback } from 'use-debounce/lib';
import { AppBadge } from '../../../../../core/components/badge/badge';
import { AppButton } from '../../../../../core/components/button/Button';
import { AppChoiceList } from '../../../../../core/components/choice-list/ChoiceList';
import { AppCollapsible } from '../../../../../core/components/collapsible/Collapsible';
import { DateTooltip } from '../../../../../core/components/date-tooltip/date-tooltip';
import { AppFilters, IAppliedFilter } from '../../../../../core/components/filters/filters';
import { AppIndexTable } from '../../../../../core/components/index-table/index-table';
import { AppIndexTableCell } from '../../../../../core/components/index-table/index-table-cell';
import { AppIndexTableRow } from '../../../../../core/components/index-table/index-table-row';
import { PageAwarePagination } from '../../../../../core/components/pagination/page-aware-pagination';
import { AppTextStyle } from '../../../../../core/components/text/text-style/TextStyle';
import { ORDERS_PER_PAGE } from '../../../../../core/constants/orders';
import { TIME_INTERVAL } from '../../../../../core/constants/product.constants';
import { withCurrency } from '../../../../../core/helpers/currency.helper';
import { ICurrency } from '../../../../../core/interfaces/ICurrency';
import { IGetPurchaseOrders } from '../../../../api/orders.api';
import { IRetailerPurchaseOrderForList, RetailerOrderStatus } from '../../../../interfaces/IOrder';
import { getCustomerFullName } from '../../../../../core/helpers/customer-fullname.helper';

type PurchaseOrderListProps = {
  orders: IRetailerPurchaseOrderForList[];
  searchString: string;
  selectedTab: number;
  totalPageCount: number;
  onPageChange: (page: number) => void;
  fetching: boolean;
  currency?: ICurrency;
  filterParams: IGetPurchaseOrders;
  suppliers: {
    label: string;
    value: string;
  }[];
  selectedSuppliers: string[];
  period: TIME_INTERVAL[];
  setPeriod: (period: TIME_INTERVAL[]) => void;
  setSelectedSuppliers: (values: string[]) => void;
  paymentStatus: {
    label: string;
    value: string;
  }[];
  selectedPaymentStatus: string[];
  setSelectedPaymentStatus: (values: string[]) => void;
  fulfillmentStatus: {
    label: string;
    value: string;
  }[];
  selectedFulfillmentStatus: string[];
  setSelectedFulfillmentStatus: (values: string[]) => void;
  setFilterParams: (filters: IGetPurchaseOrders) => void;
  handleExportCSVAll: (filters: Omit<IGetPurchaseOrders, 'limit' | 'page'>) => void;
  changeUrlParams: (
    tabNumber: any,
    params?: Omit<IGetPurchaseOrders, 'dateRange'> & {
      period?: TIME_INTERVAL;
      start?: string;
      end?: string;
    },
  ) => void;
};

export const PurchaseOrderList = ({
  orders,
  totalPageCount,
  onPageChange,
  fetching,
  currency = { isoCode: 'USD', symbol: '$' },
  filterParams,
  suppliers,
  selectedSuppliers,
  setSelectedSuppliers,
  paymentStatus,
  selectedPaymentStatus,
  setSelectedPaymentStatus,
  fulfillmentStatus,
  selectedFulfillmentStatus,
  setSelectedFulfillmentStatus,
  searchString,
  changeUrlParams,
  selectedTab,
  setFilterParams,
  period,
  setPeriod,
  handleExportCSVAll,
}: PurchaseOrderListProps) => {
  const history = useHistory();
  const { search: query } = useLocation();
  const {
    start: URLStartDate,
    end: URLEndDate,
    query: searchQueryString,
  } = queryString.parse(query, { arrayFormat: 'separator', arrayFormatSeparator: '.' });
  const statusBadge: {
    [status in RetailerOrderStatus]: ReactNode;
  } = useMemo(
    () => ({
      unfulfilled: (
        <AppBadge status="attention" progress="incomplete">
          Unfulfilled
        </AppBadge>
      ),
      'partially unfulfilled': (
        <AppBadge status="attention" progress="partiallyComplete">
          Partially unfulfilled
        </AppBadge>
      ),
      'partially fulfilled': <AppBadge progress="partiallyComplete">Partially fulfilled</AppBadge>,
      fulfilled: <AppBadge progress="complete">Fulfilled</AppBadge>,
      cancelled: (
        <AppBadge status="critical" progress="complete">
          Cancelled
        </AppBadge>
      ),
      'payment failed': <AppBadge status="critical">Payment failed</AppBadge>,
      'account suspended': <AppBadge status="critical">Account suspended</AppBadge>,
      processing: <AppBadge status="new">Processing</AppBadge>,
      error: <AppBadge status="critical">Needs Attention</AppBadge>,
    }),
    [],
  );
  const [queryValue, setQueryValue] = useState<string>((searchQueryString as string) || '');
  const [{ month, year }, setDate] = useState({
    month: URLStartDate ? moment(URLStartDate as string).month() : moment().month(),
    year: URLStartDate ? moment(URLStartDate as string).year() : moment().year(),
  });
  const [selectedDates, setSelectedDates] = useState({
    start: URLStartDate
      ? moment(URLStartDate as string).toDate()
      : moment().subtract(7, 'd').toDate(),
    end: URLEndDate
      ? moment(URLEndDate as string)
          .endOf('day')
          .toDate()
      : moment().toDate(),
  });

  const debouncedOnFilterChange = useDebouncedCallback(() => {
    setFilterParams({
      ...filterParams,
      query: queryValue,
    });
    changeUrlParams(selectedTab, {
      ...filterParams,
      ...(!!filterParams.dateRange?.start &&
        !!filterParams.dateRange?.end && {
          start: moment(filterParams.dateRange?.start).format('MM-DD-YYYY'),
          end: moment(filterParams.dateRange?.end).format('MM-DD-YYYY'),
        }),
      period: (period[0] as string).replace(' ', '-') as TIME_INTERVAL,
      query: queryValue,
    });
  }, 500);

  const handleFiltersQueryChange = (value: string) => {
    setQueryValue(value);
    debouncedOnFilterChange();
  };

  const handleQueryValueRemove = useCallback(
    (bulk?: boolean) => {
      setQueryValue('');
      if (!bulk) {
        setFilterParams({
          ...filterParams,
          query: '',
          page: 0,
        });
        changeUrlParams(selectedTab, {
          ...filterParams,
          ...(!!filterParams.dateRange?.start &&
            !!filterParams.dateRange?.end && {
              start: moment(filterParams.dateRange?.start).format('MM-DD-YYYY'),
              end: moment(filterParams.dateRange?.end).format('MM-DD-YYYY'),
            }),
          query: '',
          period: (period[0] as string).replace(' ', '-') as TIME_INTERVAL,
          page: 0,
        });
      }
    },
    [setFilterParams, filterParams, changeUrlParams, selectedTab, period],
  );

  const handleMonthChange = useCallback((month, year) => setDate({ month, year }), []);

  const getChosenDateRange = useCallback(
    (interval: TIME_INTERVAL): { start: string; end: string } | undefined => {
      const todayTimestamp = moment().toISOString();
      switch (interval) {
        case TIME_INTERVAL.NONE:
          return;
        case TIME_INTERVAL.WEEK:
          return {
            start: moment().subtract(7, 'd').toISOString(),
            end: todayTimestamp,
          };
        case TIME_INTERVAL.MONTH:
          return {
            start: moment().subtract(1, 'M').toISOString(),
            end: todayTimestamp,
          };
        case TIME_INTERVAL.SIX_MONTHS:
          return {
            start: moment().subtract(6, 'M').toISOString(),
            end: todayTimestamp,
          };
        case TIME_INTERVAL.CUSTOM:
          return {
            start: selectedDates.start.toISOString(),
            // we need to grab the end-of-day moment to include invoices created throughout the day
            end: moment(selectedDates.end).endOf('day').toISOString(),
          };
      }
    },
    [selectedDates],
  );

  const handleCustomDateChange = useCallback(
    (date: { start: Date; end: Date }) => {
      setSelectedDates(date);

      setFilterParams({
        ...filterParams,
        page: 0,
        dateRange: {
          start: date.start.toISOString(),
          // we need to grab the end-of-day moment to include invoices created throughout the day
          end: moment(date.end).endOf('day').toISOString(),
        },
      });
      changeUrlParams(selectedTab, {
        ...filterParams,
        page: 0,
        period: 'Custom' as TIME_INTERVAL,
        start: moment(date.start).format('MM-DD-YYYY'),
        // we need to grab the end-of-day moment to include invoices created throughout the day
        end: moment(date.end).endOf('day').format('MM-DD-YYYY'),
      });
    },
    [changeUrlParams, filterParams, selectedTab, setFilterParams],
  );

  const handleSelectedSuppliersChange = useCallback(
    (value: string[]) => {
      setSelectedSuppliers(value);
      setFilterParams({
        ...filterParams,
        page: 0,
        suppliers: value,
      });
      changeUrlParams(selectedTab, {
        ...filterParams,
        page: 0,
        suppliers: value,
        period: (period[0] as string).replace(' ', '-') as TIME_INTERVAL,
        ...(!!filterParams.dateRange?.start &&
          !!filterParams.dateRange?.end && {
            start: moment(filterParams.dateRange?.start).format('MM-DD-YYYY'),
            end: moment(filterParams.dateRange?.end).format('MM-DD-YYYY'),
          }),
      });
    },
    [changeUrlParams, filterParams, period, selectedTab, setFilterParams, setSelectedSuppliers],
  );

  const handleSelectedSuppliersRemove = useCallback(() => {
    setSelectedSuppliers([]);
    setFilterParams({
      page: 0,
      limit: ORDERS_PER_PAGE,
      fulfillmentStatus: filterParams.fulfillmentStatus,
      paymentStatus: filterParams.paymentStatus,
      query: queryValue,
    });
    changeUrlParams(selectedTab, {
      page: 0,
      limit: ORDERS_PER_PAGE,
      period: (period[0] as string).replace(' ', '-') as TIME_INTERVAL,
      fulfillmentStatus: filterParams.fulfillmentStatus,
      paymentStatus: filterParams.paymentStatus,
      query: queryValue,
    });
  }, [
    changeUrlParams,
    filterParams.fulfillmentStatus,
    filterParams.paymentStatus,
    period,
    queryValue,
    selectedTab,
    setFilterParams,
    setSelectedSuppliers,
  ]);

  const handleSelectedPaymentStatusChange = useCallback(
    (value: string[]) => {
      setSelectedPaymentStatus(value);
      setFilterParams({
        ...filterParams,
        page: 0,
        paymentStatus: value[0],
      });
      changeUrlParams(selectedTab, {
        ...filterParams,
        page: 0,
        paymentStatus: value[0],
        period: (period[0] as string).replace(' ', '-') as TIME_INTERVAL,
        ...(!!filterParams.dateRange?.start &&
          !!filterParams.dateRange?.end && {
            start: moment(filterParams.dateRange?.start).format('MM-DD-YYYY'),
            end: moment(filterParams.dateRange?.end).format('MM-DD-YYYY'),
          }),
      });
    },
    [changeUrlParams, filterParams, period, selectedTab, setFilterParams, setSelectedPaymentStatus],
  );

  const handleSelectedPaymentStatusRemove = useCallback(() => {
    setSelectedPaymentStatus([]);
    setFilterParams({
      page: 0,
      limit: ORDERS_PER_PAGE,
      fulfillmentStatus: filterParams.fulfillmentStatus,
      query: queryValue,
      suppliers: filterParams.suppliers,
    });
    changeUrlParams(selectedTab, {
      page: 0,
      limit: ORDERS_PER_PAGE,
      fulfillmentStatus: filterParams.fulfillmentStatus,
      query: queryValue,
      period: (period[0] as string).replace(' ', '-') as TIME_INTERVAL,
      suppliers: filterParams.suppliers,
    });
  }, [
    changeUrlParams,
    filterParams.fulfillmentStatus,
    filterParams.suppliers,
    period,
    queryValue,
    selectedTab,
    setFilterParams,
    setSelectedPaymentStatus,
  ]);

  const handleSelectedFulfillmentStatusChange = useCallback(
    (value: string[]) => {
      setSelectedFulfillmentStatus(value);
      setFilterParams({
        ...filterParams,
        page: 0,
        fulfillmentStatus: value.map((v) => v.replace('needs attention', 'error')),
      });
      changeUrlParams(selectedTab, {
        ...filterParams,
        page: 0,
        fulfillmentStatus: value,
        ...(!!filterParams.dateRange?.start &&
          !!filterParams.dateRange?.end && {
            start: moment(filterParams.dateRange?.start).format('MM-DD-YYYY'),
            end: moment(filterParams.dateRange?.end).format('MM-DD-YYYY'),
          }),
        period: (period[0] as string).replace(' ', '-') as TIME_INTERVAL,
      });
    },
    [
      changeUrlParams,
      filterParams,
      period,
      selectedTab,
      setFilterParams,
      setSelectedFulfillmentStatus,
    ],
  );
  const handleSelectedFulfillmentStatusRemove = useCallback(() => {
    setSelectedFulfillmentStatus([]);
    setFilterParams({
      page: 0,
      limit: ORDERS_PER_PAGE,
      paymentStatus: filterParams.paymentStatus,
      suppliers: filterParams.suppliers,
      query: queryValue,
    });
    changeUrlParams(selectedTab, {
      page: 0,
      limit: ORDERS_PER_PAGE,
      paymentStatus: filterParams.paymentStatus,
      suppliers: filterParams.suppliers,
      query: queryValue,
    });
  }, [
    changeUrlParams,
    filterParams.paymentStatus,
    filterParams.suppliers,
    queryValue,
    selectedTab,
    setFilterParams,
    setSelectedFulfillmentStatus,
  ]);

  const handleFiltersClearAll = useCallback(() => {
    setSelectedPaymentStatus([]);
    setSelectedFulfillmentStatus([]);
    handleQueryValueRemove(true);
    setFilterParams({
      page: 0,
      limit: ORDERS_PER_PAGE,
    });
    changeUrlParams(selectedTab, {
      page: 0,
      limit: ORDERS_PER_PAGE,
    });
  }, [
    changeUrlParams,
    handleQueryValueRemove,
    selectedTab,
    setFilterParams,
    setSelectedFulfillmentStatus,
    setSelectedPaymentStatus,
  ]);

  const appliedFilters: IAppliedFilter[] = useMemo(() => {
    const appliedSupplier = {
      key: 'selectedSuppliers',
      label: `Supplier: ${suppliers.find((s) => s.value === selectedSuppliers[0])?.label || ''}`,
      onRemove: handleSelectedSuppliersRemove,
    };

    const appliedPaymentStatus = {
      key: 'selectedPaymentStatus',
      label: `Payment status: ${
        paymentStatus.find((p) => p.value === selectedPaymentStatus[0])?.label || ''
      }`,
      onRemove: handleSelectedPaymentStatusRemove,
    };

    const appliedFulfillmentStatus = {
      key: 'selectedFulfillmentStatus',
      label: `Fulfillment statuses: ${selectedFulfillmentStatus
        .map((selected) => selected[0].toUpperCase() + selected.slice(1))
        .join(', ')}`,
      onRemove: handleSelectedFulfillmentStatusRemove,
    };

    const paymentStatusValues = selectedPaymentStatus.length ? [appliedPaymentStatus] : [];
    const fulfillmentStatusValues = selectedFulfillmentStatus.length
      ? [appliedFulfillmentStatus]
      : [];
    const suppliersValues = selectedSuppliers.length ? [appliedSupplier] : [];

    return [...paymentStatusValues, ...fulfillmentStatusValues, ...suppliersValues];
  }, [
    paymentStatus,
    suppliers,
    handleSelectedPaymentStatusRemove,
    selectedFulfillmentStatus,
    handleSelectedFulfillmentStatusRemove,
    selectedPaymentStatus,
    selectedSuppliers,
    handleSelectedSuppliersRemove,
  ]);

  return (
    <>
      {orders.length ? (
        <p>
          <AppTextStyle>
            View, filter, and export your purchase order history to quickly reconcile with
            suppliers.
          </AppTextStyle>
        </p>
      ) : null}
      <AppFilters
        filters={[
          {
            key: 'period',
            label: 'Date Range',
            shortcut: true,
            hideClearButton: true,
            filter: (
              <AppChoiceList
                title="Time interval options list"
                titleHidden
                choices={[
                  { label: 'all', value: TIME_INTERVAL.NONE },
                  { label: 'last week', value: TIME_INTERVAL.WEEK },
                  { label: 'last month', value: TIME_INTERVAL.MONTH },
                  { label: 'last six months', value: TIME_INTERVAL.SIX_MONTHS },
                  { label: 'custom', value: TIME_INTERVAL.CUSTOM },
                ]}
                selected={period}
                onChange={(val: TIME_INTERVAL[]) => {
                  setPeriod(val);
                  setFilterParams({ ...filterParams, dateRange: getChosenDateRange(val[0]) });
                  changeUrlParams(selectedTab, {
                    ...filterParams,
                    ...(!!getChosenDateRange(val[0])?.start && {
                      start: moment(getChosenDateRange(val[0])?.start).format('MM-DD-YYYY'),
                    }),
                    ...(!!getChosenDateRange(val[0])?.end && {
                      end: moment(getChosenDateRange(val[0])?.end).format('MM-DD-YYYY'),
                    }),
                    period: (val[0] as string).replace(' ', '-') as TIME_INTERVAL,
                  });
                }}
              />
            ),
          },
          {
            key: 'selectedSuppliers',
            label: 'Supplier',
            shortcut: true,
            filter: (
              <AppChoiceList
                title="Supplier"
                titleHidden
                choices={suppliers}
                selected={selectedSuppliers}
                onChange={handleSelectedSuppliersChange}
              />
            ),
          },
          {
            key: 'selectedPaymentStatus',
            label: 'Payment status',
            shortcut: true,
            filter: (
              <AppChoiceList
                title="Payment status"
                titleHidden
                choices={paymentStatus}
                selected={selectedPaymentStatus}
                onChange={handleSelectedPaymentStatusChange}
              />
            ),
          },
          {
            key: 'selectedFulfillmentStatus',
            label: 'Fulfillment status',
            shortcut: true,
            filter: (
              <AppChoiceList
                title="Fulfillment status"
                titleHidden
                choices={fulfillmentStatus}
                selected={selectedFulfillmentStatus.map((v) =>
                  v.replace('error', 'Needs attention'),
                )}
                onChange={handleSelectedFulfillmentStatusChange}
                allowMultiple
              />
            ),
          },
        ]}
        appliedFilters={appliedFilters}
        onQueryChange={handleFiltersQueryChange}
        onQueryClear={() => handleQueryValueRemove()}
        onClearAll={handleFiltersClearAll}
        queryValue={queryValue}
      />
      <div style={{ paddingLeft: '16px', width: 'fit-content' }}>
        <AppButton
          handleClick={(e) => {
            e.stopPropagation();
            handleExportCSVAll(filterParams);
          }}
        >
          Export CSV
        </AppButton>
      </div>
      <AppCollapsible id={'datePicker'} open={period.includes(TIME_INTERVAL.CUSTOM)}>
        <DatePicker
          month={month}
          year={year}
          onChange={handleCustomDateChange}
          onMonthChange={handleMonthChange}
          selected={selectedDates}
          multiMonth
          allowRange
        />
      </AppCollapsible>
      <AppIndexTable
        selectable={false}
        headings={[
          { title: 'PO' },
          { title: 'Sales Order #' },
          { title: 'Date' },
          { title: 'Payable to' },
          { title: 'Ship to' },
          { title: 'My Total' },
          { title: 'Items Count' },
          { title: 'Status' },
          { title: 'Payment Status' },
          { title: 'Invoice / Batch' },
        ]}
        itemCount={orders.length}
        loading={fetching}
        emptyState={
          <EmptySearchResult
            title={'No purchase orders found'}
            description={"If you're using search or a filter, try changing it"}
            withIllustration
          />
        }
      >
        {orders.map(
          (
            {
              id,
              name,
              createdAt,
              total,
              salesOrderName,
              supplierName,
              customerFirstName,
              customerLastName,
              customerCompany,
              itemsCount,
              status,
              paymentStatus,
              invoiceId,
            },
            index,
          ) => (
            <AppIndexTableRow
              id={id}
              key={id}
              position={index}
              selected={false}
              onNavigation={() => history.push(`/purchase-order/${id}${searchString}`)}
            >
              <AppIndexTableCell>
                <div data-primary-link>
                  <AppTextStyle variation="strong">{name}</AppTextStyle>
                </div>
              </AppIndexTableCell>
              <AppIndexTableCell> {salesOrderName} </AppIndexTableCell>
              <AppIndexTableCell>
                <DateTooltip date={createdAt} />
              </AppIndexTableCell>
              <AppIndexTableCell>{supplierName}</AppIndexTableCell>
              <AppIndexTableCell>
                {getCustomerFullName(customerFirstName, customerLastName, customerCompany)}
              </AppIndexTableCell>
              <AppIndexTableCell>{withCurrency(total, currency)}</AppIndexTableCell>
              <AppIndexTableCell>{itemsCount}</AppIndexTableCell>
              <AppIndexTableCell>{statusBadge[status]}</AppIndexTableCell>
              <AppIndexTableCell>{paymentStatus}</AppIndexTableCell>
              <AppIndexTableCell>{invoiceId}</AppIndexTableCell>
            </AppIndexTableRow>
          ),
        )}
      </AppIndexTable>
      <PageAwarePagination totalPageCount={totalPageCount} onPageChange={onPageChange} />
    </>
  );
};
