export interface INotificationSources {
  inApp: boolean;
  sms: boolean;
  email: NOTIFICATION_FREQUENCY[];
}

export enum NOTIFICATION_FREQUENCY {
  INSTANT = 'instant',
  DAILY = 'daily',
  WEEKLY = 'weekly',
  MONTHLY = 'monthly',
}
