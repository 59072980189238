import React from 'react';
import { useCallback, useMemo } from 'react';
import { AppDataTable } from '../../../../../../../../core/components/data-table/DataTable';
import { AppThumbnail } from '../../../../../../../../core/components/image-containers/thumbnail/Thumbnail';
import {
  IRetailerProductMatchVariant,
  ISupplierProductMatchVariant,
} from '../../../../../../../interfaces/IProductsSync';

type SyncConflictVariantListProps = {
  variants: ISupplierProductMatchVariant[] | IRetailerProductMatchVariant[];
};

export const SyncConflictVariantList = ({ variants }: SyncConflictVariantListProps) => {
  const areSupplierVariants = useCallback(
    (
      variants: ISupplierProductMatchVariant[] | IRetailerProductMatchVariant[],
    ): variants is ISupplierProductMatchVariant[] =>
      variants.some(
        (v: ISupplierProductMatchVariant | IRetailerProductMatchVariant) =>
          'priceForRetailers' in v,
      ),
    [],
  );

  const headings = useMemo(
    () =>
      areSupplierVariants(variants)
        ? ['Variant SKU', 'Variant', 'Picture', 'Suppliers price']
        : ['Variant SKU', 'Variant', 'Picture', "You're selling at"],
    [areSupplierVariants, variants],
  );

  const rows = useMemo(
    () =>
      areSupplierVariants(variants)
        ? variants.map((v) => [
            v.sku,
            v.title,
            <AppThumbnail alt={v.title} source={v.image} />,
            v.priceForRetailers,
          ])
        : variants.map((v) => [
            v.sku,
            v.title,
            <AppThumbnail alt={v.title} source={v.image} />,
            v.price,
          ]),
    [areSupplierVariants, variants],
  );

  return (
    <AppDataTable
      columnContentTypes={['text', 'text', 'text', 'numeric']}
      headings={headings}
      rows={rows}
    />
  );
};
