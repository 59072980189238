import { createSelector } from 'reselect';
import { RootState } from '../../../../shopify-supplier/redux/reducers';

export const getAccountInfoSelector = createSelector(
  [(state: RootState) => state.account],
  (accountReducer) => accountReducer.accountInfo,
);

export const getManagePaymentSourcesURLSelector = createSelector(
  [(state: RootState) => state.account],
  (accountReducer) => accountReducer.managePaymentSourcesURL,
);

export const showSuccessUpdateToastSelector = createSelector(
  [(state: RootState) => state.account],
  (accountReducer) => accountReducer.successUpdateToast,
);

export const getAccountInfoFetchingSelector = createSelector(
  [(state: RootState) => state.account],
  (accountReducer) => accountReducer.fetching,
);
