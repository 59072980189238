import { withCurrency } from '../../helpers/currency.helper';
import { IRetailerVariantMatch } from '../../interfaces/IProduct';
import { AppThumbnail } from '../image-containers/thumbnail/Thumbnail';
import { AppTextStyle } from '../text/text-style/TextStyle';

type RetalerSyncComponentProps = {
  retailerProduct: IRetailerVariantMatch;
};

export const RetailerSyncProduct = ({ retailerProduct }: RetalerSyncComponentProps) => {
  return (
    <div className="sync-product-wrapper">
      <div className="product-image">
        <AppThumbnail source={retailerProduct.image} alt={retailerProduct.title} />
      </div>
      <div className="sync-product-description">
        <AppTextStyle variation="subdued">{retailerProduct.vendor}</AppTextStyle>
        <AppTextStyle variation="strong">{retailerProduct.productGroupTitle}</AppTextStyle>
        <AppTextStyle variation="strong">
          Variant Name: <span className="fields-values">{retailerProduct.title}</span>
        </AppTextStyle>
        <AppTextStyle variation="strong">
          SKU: <span className="fields-values">{retailerProduct.sku}</span>
        </AppTextStyle>
        <AppTextStyle variation="strong">
          My qty: <span className="fields-values">{retailerProduct.quantity}</span>
        </AppTextStyle>
        <AppTextStyle variation="strong">
          Sale Price: <span className="fields-values">{withCurrency(retailerProduct.price)}</span>
        </AppTextStyle>
        <AppTextStyle variation="strong">
          Currently fulfilled by:{' '}
          <span className="fields-values">{retailerProduct.shopify.fulfillmentService}</span>
        </AppTextStyle>
      </div>
    </div>
  );
};
