import React from 'react';
import { AppLink } from '../../../../../../core/components/link/link';
import { AppTooltip } from '../../../../../../core/components/overlays/tooltip/tooltip';

type TrackingNumbersProps = {
  numbers: string[];
  urls: string[];
  companies: string[];
  fulfilledQty: boolean;
};

export const TrackingNumbers = ({
  numbers,
  urls,
  companies,
  fulfilledQty,
}: TrackingNumbersProps) => {
  if (!fulfilledQty) return <span>pending</span>;

  if (!numbers.length && !companies.length) return <span>Not provided</span>;

  if (!numbers.length)
    return (
      <>
        {companies.map((company, index) => {
          const companyName = <div className="tracking-number-or-company">{company}</div>;
          return (
            <AppTooltip key={index} content={company}>
              {companyName}
            </AppTooltip>
          );
        })}
      </>
    );

  return (
    <>
      {numbers.map((number, index) => {
        const label = <div className="tracking-number-or-company">{number}</div>;
        return (
          <AppTooltip key={index} content={number}>
            {urls[index] ? (
              <AppLink onClick={() => window.open(urls[index], '_blank')}>{label}</AppLink>
            ) : (
              label
            )}
          </AppTooltip>
        );
      })}
    </>
  );
};
