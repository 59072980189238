export enum CANCELLATION_REASON {
  CUSTOMER = 'customer', // customer changed / cancelled order - retailer
  INVENTORY = 'inventory', // items unavailable - supplier/crowdship
  FRAUD = 'fraud', // fraudulent order - retailer
  DECLINED = 'declined', // payment declined - retailer
  OTHER = 'other', // supplier/retailer
}

export type CancellationInitiator = 'supplier' | 'retailer' | 'crowdship';

interface ILineItemCancellationQuantity {
  requested: number;
  accepted: number;
  rejected: number;
  voided: number;
}

export interface ILineItemCancellation {
  cancellation: string;
  pending: boolean;
  quantity: ILineItemCancellationQuantity;

  initiatedBy: CancellationInitiator;
  reason: CANCELLATION_REASON;
  comment?: string;

  /** true if the cancellation was created before the charge and should not initiate a refund */
  initiallyCancelled: boolean;

  createdAt: string;
  updatedAt: string;
}
