import React, { useCallback, useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { AppBanner } from '../../../../core/components/feedback-indicators/banner/banner';
import { AppLink } from '../../../../core/components/link/link';
import { IRetailerStatus } from '../../../interfaces/IPlan';
import './limits-status-banner.scss';

interface ILimitsStatusBannerProps {
  status: IRetailerStatus | null;
}

export function LimitsStatusBanner({ status }: ILimitsStatusBannerProps) {
  const { pathname, search } = useLocation();

  const isPlansTab = useMemo(() => {
    const parsedQuery = new URLSearchParams(search);
    return pathname.includes('settings') && parsedQuery.get('tab') === 'subscription-plan';
  }, [pathname, search]);

  const productsLimitMessage = useMemo(
    () =>
      status &&
      status.productsLimit !== undefined &&
      status.productsCount >= status.productsLimit ? (
        <p>
          You have reached the limit and cannot add more than{' '}
          {status.productsLimit.toLocaleString()} SKUs
        </p>
      ) : null,
    [status],
  );

  const linkIfNotOnPage = useCallback(
    (text: string) =>
      isPlansTab ? (
        <span>{text}</span>
      ) : (
        <AppLink url="/settings?tab=subscription-plan">{text}</AppLink>
      ),
    [isPlansTab],
  );

  /**
   * Hack for standalone: product limit is equal to 0 to prevent product adding at standalone retailer.
   * This banner should not be displayed in this case, there's a separate banner for this
   */
  if (!status || status.productsLimit === 0) return null;

  const { orderLimit } = status;

  if (orderLimit !== 0) return null;

  return (
    <div className="banner">
      <AppBanner status="warning" title="Your orders aren't processed">
        <p>
          Your plan does not include order processing. Consider{' '}
          {linkIfNotOnPage('upgrading your plan')}.
        </p>
        {productsLimitMessage}
      </AppBanner>
    </div>
  );
}
