import { useSelector } from 'react-redux';
import { getStatusSelector } from '../../../../shopify-retailer/redux/modules/status/status.selectors';
import { getLocaleQuantity } from '../../../helpers/quantity.helper';
import { AppTextStyle } from '../../text/text-style/TextStyle';
import './table-elements.scss';

export const SKULimit = () => {
  const status = useSelector(getStatusSelector);
  if (!status?.productsLimit) return null;

  return (
    <div className="filter-container__sku-limit">
      <AppTextStyle variation="strong">SKU Limit: </AppTextStyle>
      {getLocaleQuantity(status.productsCount)} {'of '}
      {getLocaleQuantity(status.productsLimit)}. To connect more than{' '}
      {getLocaleQuantity(status.productsLimit)} SKU's, please upgrade your plan.
    </div>
  );
};
