export enum CORE_ACTIONS {
  AUTH = 'AUTH',
  SET_IS_LOGGED = 'SET_IS_LOGGED',
  LOGOUT = 'LOGOUT',

  SET_HOST = 'SET_HOST',
  SET_SHOP = 'SET_SHOP',
  //breadcrumbs
  SET_BREADCRUMBS = 'SET_BREADCRUMBS',

  // account
  GET_ACCOUNT_INFO = 'GET_ACCOUNT_INFO',
  SET_ACCOUNT_INFO_FROM_API = 'SET_ACCOUNT_INFO_FROM_API',
  SET_ACCOUNT_INFO = 'SET_ACCOUNT_INFO',
  POST_ACCOUNT_INFO = 'POST_ACCOUNT_INFO',
  SET_MANAGE_PAYMENT_SOURCES_URL = 'SET_MANAGE_PAYMENT_SOURCES_URL',
  GET_MANAGE_PAYMENT_SOURCES_URL = 'GET_MANAGE_PAYMENT_SOURCES_URL',
  SHOW_SUCCESS_ACCOUNT_INFO_UPDATE_TOAST = 'SHOW_SUCCESS_ACCOUNT_INFO_UPDATE_TOAST',
  HIDE_SUCCESS_ACCOUNT_INFO_UPDATE_TOAST = 'HIDE_SUCCESS_ACCOUNT_INFO_UPDATE_TOAST',
  DISCONNECT_ACCOUNT = 'DISCONNECT_ACCOUNT',
  CONNECT_ACCOUNT = 'CONNECT_ACCOUNT',
  SET_ROLES = 'SET_ROLES',
  SET_STORE_ACTIVITY = 'SET_STORE_ACTIVITY',
  GET_VIEW_AS_CLIENT_SETTING = 'GET_VIEW_AS_CLIENT_SETTING',
  SET_VIEW_AS_CLIENT_SETTING = 'SET_VIEW_AS_CLIENT_SETTING',
  UPDATE_VIEW_AS_CLIENT_SETTING = 'UPDATE_VIEW_AS_CLIENT_SETTING',
  HIDE_ADMIN_SETTINGS_UPDATED_TOAST = 'HIDE_ADMIN_SETTINGS_UPDATED_TOAST',
  SHOW_ADMIN_SETTINGS_UPDATED_TOAST = 'SHOW_ADMIN_SETTINGS_UPDATED_TOAST',
}
