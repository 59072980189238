import { AppChoiceList } from '../../../../choice-list/ChoiceList';
import { FilterElementProps } from './filter-elements.interface';

export const SHOPIFY_STATUS_FILTER_KEY = 'selectedStatus';
const SHOPIFY_STATUS_FILTER_LABEL = 'Shopify status';
const SHOPIFY_STATUS_FILTER_TITLE = 'Shopify status list';

function Filter({ onChange, options, value }: FilterElementProps<string[]>) {
  return (
    <AppChoiceList
      title={SHOPIFY_STATUS_FILTER_TITLE}
      titleHidden
      choices={options}
      selected={value}
      onChange={onChange}
      allowMultiple
    />
  );
}

export const ShopifyStatusFilter = ({
  value,
  onChange,
  options,
}: FilterElementProps<string[]>) => ({
  key: SHOPIFY_STATUS_FILTER_KEY,
  label: SHOPIFY_STATUS_FILTER_LABEL,
  shortcut: true,
  filter: <Filter onChange={onChange} options={options} value={value} />,
});
