import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { DashboardCard } from '../../../../core/components/structure/card/dashboard-card/dashboard-card';
import { AppPage } from '../../../../core/components/structure/page/page';
import { AppStack } from '../../../../core/components/structure/stack/Stack';
import { withCurrency } from '../../../../core/helpers/currency.helper';
import { getLocaleQuantity } from '../../../../core/helpers/quantity.helper';
import {
  getDashboardOrdersSummaryAction,
  getDashboardProductsSummaryAction,
  getDashboardSalesSummaryAction,
} from '../../../redux/modules/dashboard/dashboard.actions';
import {
  getDashboardOrdersSummarySelector,
  getDashboardProductsSummarySelector,
  getDashboardSalesSummarySelector,
} from '../../../redux/modules/dashboard/dashboard.selectors';
import './dashboard-layout.scss';

interface IDashboardProps {}

export const DashboardLayout: React.FunctionComponent<IDashboardProps> = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { totalOrdersCount, unfulfilledOrdersCount } = useSelector(
    getDashboardOrdersSummarySelector,
  );
  const { dropshipProductsCount, dropshipSKUsCount } = useSelector(
    getDashboardProductsSummarySelector,
  );
  const { totalSales, dropshipCost, dropshipProfit, dropshipMargin } = useSelector(
    getDashboardSalesSummarySelector,
  );

  useEffect(() => {
    dispatch(getDashboardOrdersSummaryAction());
    dispatch(getDashboardProductsSummaryAction());
    dispatch(getDashboardSalesSummaryAction());
  }, [dispatch]);

  return (
    <div>
      <AppPage title="Dashboard">
        <AppStack vertical={false} distribution={'leading'}>
          <div className="dashboard_layout_card_wrapper">
            <DashboardCard
              content={[
                { title: 'All orders', value: totalOrdersCount },
                { title: 'Unfulfilled orders', value: unfulfilledOrdersCount },
              ]}
              title={'Orders'}
              cardAction={[{ content: 'Manage', onAction: () => history.push('/orders') }]}
              loading={false}
            />
          </div>
          <div className="dashboard_layout_card_wrapper">
            <DashboardCard
              content={[
                { title: 'Dropship products', value: dropshipProductsCount },
                { title: 'Dropship SKUs', value: dropshipSKUsCount },
              ]}
              title={'Products'}
              cardAction={[
                { content: 'Sync', onAction: () => history.push('/sync') },
                { content: 'Manage', onAction: () => history.push('/my-products') },
              ]}
              loading={false}
            />
          </div>
          <div className="dashboard_layout_card_wrapper">
            <DashboardCard
              content={[
                { title: 'Total sales', value: withCurrency(totalSales) },
                { title: 'Dropship cost', value: withCurrency(dropshipCost) },
                { title: 'Dropship profit', value: `+${withCurrency(dropshipProfit)}` },
                { title: 'Dropship markup', value: `+${getLocaleQuantity(dropshipMargin)}%` },
              ]}
              title={'Sales'}
              loading={false}
            />
          </div>
        </AppStack>
      </AppPage>
    </div>
  );
};
