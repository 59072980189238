import { IRadioButtonDescription } from '../components/forms/radio/controlled-radio-group/controlled-radio-group';
import { NOTIFICATION_FREQUENCY } from '../interfaces/INotificationSources';

interface IPreferencesConstants extends IRadioButtonDescription {
  value: NOTIFICATION_FREQUENCY;
}

export const notificationFrequencyOptions: IPreferencesConstants[] = [
  {
    value: NOTIFICATION_FREQUENCY.INSTANT,
    label: 'Instantly',
  },
  {
    value: NOTIFICATION_FREQUENCY.DAILY,
    label: 'Daily',
  },
  {
    value: NOTIFICATION_FREQUENCY.WEEKLY,
    label: 'Weekly',
  },
  {
    value: NOTIFICATION_FREQUENCY.MONTHLY,
    label: 'Monthly',
  },
];
