import { IDashboardReducer } from '../../../interfaces/IDashboard';
import { createReducer } from 'typesafe-actions';
import { RootActions } from '../../actions';
import {
  setFetchingAction,
  setSalesSummaryAction,
  setOrdersSummaryAction,
} from './dashboard-actions';

const initialState: IDashboardReducer = {
  ordersSummary: {
    numberOfAllOrders: 0,
    numberOfUnfulfilledOrders: 0,
  },
  salesSummary: {
    itemsSold: 0,
    supplierTotal: 0,
  },
  fetching: false,
};

export const dashboardReducer = createReducer<IDashboardReducer, RootActions>(initialState)
  .handleAction(setFetchingAction, (state, { payload }) => ({
    ...state,
    fetching: payload,
  }))
  .handleAction(setSalesSummaryAction, (state, { payload }) => ({
    ...state,
    salesSummary: payload,
  }))
  .handleAction(setOrdersSummaryAction, (state, { payload }) => ({
    ...state,
    ordersSummary: payload,
  }));
