import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  getAccountInfoAction,
  getManagePaymentSourcesURLAction,
} from '../../../../core/redux/modules/account/account.actions';
import {
  getAccountInfoFetchingSelector,
  getAccountInfoSelector,
} from '../../../../core/redux/modules/account/account.selectors';
import {
  getCarrierServicesAction,
  getPreferencesAction,
} from '../../../redux/modules/preferences/preferences.actions';
import {
  getCarrierServicesSelector,
  getPreferencesFetchingSelector,
  getPreferencesSelector,
} from '../../../redux/modules/preferences/preferences.selectors';
import {
  updateStatusOnAccountLoadedAction,
  updateStatusOnPreferencesLoadedAction,
} from '../../../redux/modules/status/status.actions';
import { PreferencesList } from '../../containers/preferences-list/preferences-list';
import { PreferencesListSkeleton } from '../../containers/preferences-list/preferences-list-skeleton';

interface IPreferencesLayoutProps {}

export function PreferencesLayout(props: IPreferencesLayoutProps) {
  const dispatch = useDispatch();

  const preferences = useSelector(getPreferencesSelector);
  const accountInfo = useSelector(getAccountInfoSelector);
  const carrierServices = useSelector(getCarrierServicesSelector);

  const accountFetching = useSelector(getAccountInfoFetchingSelector);
  const preferencesFetching = useSelector(getPreferencesFetchingSelector);

  useEffect(() => {
    dispatch(getPreferencesAction());
    dispatch(getCarrierServicesAction());
    dispatch(updateStatusOnPreferencesLoadedAction());
    dispatch(getAccountInfoAction());
    dispatch(getManagePaymentSourcesURLAction());
    dispatch(updateStatusOnAccountLoadedAction());
  }, [dispatch]);

  return (
    <div className="preferences-layout">
      {accountFetching && preferencesFetching ? (
        <PreferencesListSkeleton />
      ) : (
        <PreferencesList
          preferences={preferences}
          accountInfo={accountInfo}
          carrierServices={carrierServices}
        />
      )}
    </div>
  );
}
