import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IShopViewDetails } from '../../interfaces/IAccount';
import { updateViewAsClientSettingAction } from '../../redux/modules/admin/admin.actions';
import { getViewAsClientSettingSelector } from '../../redux/modules/admin/admin.selector';
import { AppResourceItem } from '../resource-list/ResourceItem';
import { AppResourceList } from '../resource-list/ResourceList';
import { AppCard } from '../structure/card/card';
import { AppTextStyle } from '../text/text-style/TextStyle';

export interface IViewAsClientProps {
  items: IShopViewDetails[];
  loading: boolean;
  onChange: () => void;
}

export const ViewAsClientSetting = ({ items, loading, onChange }: IViewAsClientProps) => {
  const dispatch = useDispatch();

  const viewAsClient = useSelector(getViewAsClientSettingSelector);
  const isSelected = useCallback((id: string) => viewAsClient?._id === id, [viewAsClient]);

  useEffect(() => onChange(), [onChange, viewAsClient]);

  const handleSupplierSelected = useCallback(
    (id) => dispatch(updateViewAsClientSettingAction(id)),
    [dispatch],
  );

  return (
    <AppCard title="View as client" sectioned>
      <p style={{ paddingBottom: '1em' }}>Select a shop to view the app as this client:</p>
      <AppResourceList
        items={items}
        loading={loading}
        renderItem={({ _id, name, self }) => (
          <AppResourceItem
            id={_id}
            onClick={handleSupplierSelected}
            accessibilityLabel={`View as client ${name}`}
            verticalAlignment="center"
            name={name}
            avatarCustomer
          >
            <div className="name">
              {name}
              {self && <AppTextStyle variation="subdued"> (you)</AppTextStyle>}
            </div>
            {isSelected(_id) && <AppTextStyle variation="subdued">selected</AppTextStyle>}
          </AppResourceItem>
        )}
      />
    </AppCard>
  );
};
