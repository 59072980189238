import { AxiosResponse } from 'axios';
import { all, put, select, takeEvery, takeLatest } from 'redux-saga/effects';
import { ActionType } from 'typesafe-actions';
import { FIRST_PAGE } from '../../../../core/constants/product.constants';
import { Paginated } from '../../../../core/helpers/generic.helper';
import { getNumberOfPages } from '../../../../core/helpers/item-list.helper';
import { IOption } from '../../../../core/interfaces/IOption';
import { IInventoryProduct, IProductList } from '../../../../core/interfaces/IProduct';
import { IInventoryFilter } from '../../../../core/interfaces/IProductFilter';
import { IDeleteProductsResponse, inventoryApi } from '../../../api/inventory.api';
import { SALE_PRICE_OPTION } from '../../../interfaces/IPreferences';
import { IInventoryVariantFromApi } from '../../../interfaces/IProduct';
import { updateProductsLimitStatusAction } from '../status/status.actions';
import {
  deleteAllProductsFromInventoryAction,
  deleteProductsFromInventoryAction,
  disconnectAllProductsFromInventoryAction,
  disconnectProductsFromInventoryAction,
  getInventoryFilterOptionsAction,
  getInventoryProductListAction,
  getInventoryProductVariantsAction,
  resetInventoryFiltersAction,
  setInventoryConnectionFilterAction,
  setInventoryFetchingAction,
  setInventoryFilterOptionsAction,
  setInventoryFiltersAction,
  setInventoryLimitFilterAction,
  setInventoryPageFilterAction,
  setInventoryProductListAction,
  setInventoryProductVariantsAction,
  setInventoryStockFilterAction,
  setInventoryQueryFilterAction,
  setInventorySupplierFilterAction,
  setInventoryTypeFilterAction,
  setInventoryVendorFilterAction,
  setRemovedInventoryProductListAction,
  setInventoryFilterAction,
  exportCSVFromInventoryAction,
  exportCSVAllFromInventoryAction,
} from './inventory.actions';
import { getInventoryFiltersSelector } from './inventory.selectors';
import fileDownload from 'js-file-download';
import moment from 'moment';

function* getInventoryProductListSaga() {
  try {
    yield put(setInventoryFetchingAction(true));
    const filters: Paginated<IInventoryFilter> = yield select(getInventoryFiltersSelector);
    const { data: productList }: AxiosResponse<IProductList<IInventoryProduct>> =
      yield inventoryApi.getProducts(filters);

    if (filters.page === getNumberOfPages(productList.total) && filters.page !== FIRST_PAGE) {
      yield put(setInventoryPageFilterAction({ page: getNumberOfPages(productList.total) - 1 }));
      return;
    }

    yield put(setInventoryProductListAction(productList));
    yield put(setInventoryFetchingAction(false));
  } catch (e) {
    console.error(e);
    yield put(setInventoryFetchingAction(false));
  }
}

function* getInventoryProductVariantsSaga({
  payload: productId,
}: ActionType<typeof getInventoryProductVariantsAction>) {
  const getSelectedPriceSyncPreferenceOption = (variant: IInventoryVariantFromApi) => {
    if (variant.manuallySetPrice) return 'disable sync';
    if (variant.useCompanyDefault) return 'use company';
    return variant.salePriceOption === SALE_PRICE_OPTION.MSRP ? 'msrp' : 'cost';
  };

  try {
    const { data: variants }: AxiosResponse<IInventoryVariantFromApi[]> =
      yield inventoryApi.getProductVariants(productId);
    yield put(
      setInventoryProductVariantsAction(
        productId,
        variants.map((variant) => ({
          ...variant,
          priceSyncOption: getSelectedPriceSyncPreferenceOption(variant),
        })),
      ),
    );
  } catch (e) {
    console.error(e);
  }
}

function* getInventoryFilterOptionsSaga(
  action: ActionType<typeof getInventoryFilterOptionsAction>,
) {
  try {
    const { data: vendors }: AxiosResponse<IOption[]> = yield inventoryApi.getVendors();
    const { data: fulfilledBy }: AxiosResponse<IOption[]> = yield inventoryApi.getSuppliers();
    const { data: types }: AxiosResponse<IOption[]> = yield inventoryApi.getTypes();
    yield put(
      setInventoryFilterOptionsAction({
        vendors,
        types,
        fulfilledBy,
      }),
    );
  } catch (e) {
    console.error(e);
  }
}

function* deleteProductsFromInventorySaga({
  payload: productIds,
}: ActionType<typeof deleteProductsFromInventoryAction>) {
  try {
    yield put(setInventoryFetchingAction(true));
    const { data }: AxiosResponse<IDeleteProductsResponse> =
      yield inventoryApi.removeProductsFromInventory(productIds);
    if ('success' in data && data.success) {
      yield put(
        updateProductsLimitStatusAction({ productsCount: data.added, productsLimit: data.limit }),
      );
      yield put(getInventoryProductListAction());
    }
    yield put(setRemovedInventoryProductListAction(productIds));
    yield put(setInventoryFetchingAction(false));
  } catch (e) {
    console.error(e);
    yield put(setInventoryFetchingAction(false));
  }
}

function* deleteAllProductsFromInventorySaga(
  action: ActionType<typeof deleteAllProductsFromInventoryAction>,
) {
  try {
    const filters: Paginated<IInventoryFilter> = yield select(getInventoryFiltersSelector);
    const { data }: AxiosResponse<IDeleteProductsResponse> =
      yield inventoryApi.removeAllProductsFromInventory(filters);
    if ('success' in data && data.success) {
      yield put(
        updateProductsLimitStatusAction({ productsCount: data.added, productsLimit: data.limit }),
      );
    }
    yield put(setInventoryProductListAction({ productGroups: [], total: 0 }));
  } catch (e) {
    console.error(e);
  }
}

function* disconnectProductsFromInventorySaga({
  payload,
}: ActionType<typeof disconnectProductsFromInventoryAction>) {
  try {
    yield put(setInventoryFetchingAction(true));
    const { data }: AxiosResponse<IDeleteProductsResponse> =
      yield inventoryApi.disconnectProductsFromInventory(payload);
    if ('success' in data && data.success) {
      yield put(
        updateProductsLimitStatusAction({ productsCount: data.added, productsLimit: data.limit }),
      );
    }
    yield put(setInventoryPageFilterAction({ page: 0 }));
  } catch (e) {
    console.error(e);
  }
}

function* disconnectAllProductsFromInventorySaga(
  action: ActionType<typeof disconnectAllProductsFromInventoryAction>,
) {
  try {
    yield put(setInventoryFetchingAction(true));
    const filters: Paginated<IInventoryFilter> = yield select(getInventoryFiltersSelector);
    const { data }: AxiosResponse<IDeleteProductsResponse> =
      yield inventoryApi.disconnectAllProductsFromInventory(filters);

    if ('success' in data && data.success) {
      yield put(
        updateProductsLimitStatusAction({ productsCount: data.added, productsLimit: data.limit }),
      );
    }
    yield put(setInventoryPageFilterAction({ page: 0 }));
  } catch (e) {
    console.error(e);
  }
}

function* exportCSVFromInventorySaga({ payload }: ActionType<typeof exportCSVFromInventoryAction>) {
  try {
    yield put(setInventoryFetchingAction(true));
    const { data }: AxiosResponse<any> = yield inventoryApi.exportCSVFromInventory(payload);

    const now = moment().format('MMDDYYYY');
    fileDownload(data, `Inventory_${now}.csv`);

    yield put(setInventoryPageFilterAction({ page: 0 }));
  } catch (e) {
    console.error(e);
  }
}

function* exportCSVAllFromInventorySaga(
  action: ActionType<typeof exportCSVAllFromInventoryAction>,
) {
  try {
    yield put(setInventoryFetchingAction(true));
    const filters: Paginated<IInventoryFilter> = yield select(getInventoryFiltersSelector);
    const { data }: AxiosResponse<any> = yield inventoryApi.exportCSVAllFromInventory(filters);

    const now = moment().format('MMDDYYYY');
    fileDownload(data, `Inventory_all_${now}.csv`);

    yield put(setInventoryPageFilterAction({ page: 0 }));
  } catch (e) {
    console.error(e);
  }
}

export function* inventorySaga() {
  yield all([
    takeLatest(
      [
        getInventoryProductListAction,
        setInventoryFiltersAction,
        setInventoryQueryFilterAction,
        setInventorySupplierFilterAction,
        setInventoryTypeFilterAction,
        setInventoryVendorFilterAction,
        setInventoryStockFilterAction,
        setInventoryConnectionFilterAction,
        setInventoryLimitFilterAction,
        setInventoryPageFilterAction,
        resetInventoryFiltersAction,
        setInventoryFilterAction,
      ],
      getInventoryProductListSaga,
    ),
    takeEvery(getInventoryProductVariantsAction, getInventoryProductVariantsSaga),
    takeLatest(getInventoryFilterOptionsAction, getInventoryFilterOptionsSaga),
    takeLatest(deleteProductsFromInventoryAction, deleteProductsFromInventorySaga),
    takeLatest(deleteAllProductsFromInventoryAction, deleteAllProductsFromInventorySaga),
    takeLatest(disconnectProductsFromInventoryAction, disconnectProductsFromInventorySaga),
    takeLatest(disconnectAllProductsFromInventoryAction, disconnectAllProductsFromInventorySaga),
    takeLatest(exportCSVFromInventoryAction, exportCSVFromInventorySaga),
    takeLatest(exportCSVAllFromInventoryAction, exportCSVAllFromInventorySaga),
  ]);
}
