import { createSelector } from 'reselect';
import { RootState } from '../../reducers';

export const getVariantSyncInProgressSelector = createSelector(
  [(state: RootState) => state.variantSync],
  (variantSync) => variantSync.syncInProgress,
);

export const getVariantSyncMatchesSelector = createSelector(
  [(state: RootState) => state.variantSync],
  (variantSync) => variantSync.matches,
);

export const getVariantSyncMatchesTotalSelector = createSelector(
  [(state: RootState) => state.variantSync],
  (variantSync) => variantSync.total,
);

export const getVariantSyncFetchingSelector = createSelector(
  [(state: RootState) => state.variantSync],
  (variantSync) => variantSync.fetching,
);

export const getVariantSyncLinkStatusSelector = createSelector(
  [(state: RootState) => state.variantSync],
  (variantSync) => variantSync.linkStatus,
);
