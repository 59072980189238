import { createAction } from 'typesafe-actions';
import { Paginated } from '../../../../../core/helpers/generic.helper';
import { IMarketplaceBuildFilter } from '../../../../../core/interfaces/IProductFilter';
import { ACTIONS_CONSTANTS } from '../../../actions-constants';

export const setMarketplaceVariantListFiltersAction = createAction(
  ACTIONS_CONSTANTS.SET_MARKETPLACE_VARIANT_LIST_FILTERS,
  (filters: IMarketplaceBuildFilter) => filters,
)();

export const resetMarketplaceVariantListFiltersAction = createAction(
  ACTIONS_CONSTANTS.RESET_MARKETPLACE_VARIANT_LIST_FILTERS,
)();

export const setMarketplaceVariantListQueryFilterAction = createAction(
  ACTIONS_CONSTANTS.SET_MARKETPLACE_VARIANT_LIST_QUERY_FILTER,
  (filters: Pick<IMarketplaceBuildFilter, 'query'>) => filters,
)();

export const setMarketplaceVariantListVendorFilterAction = createAction(
  ACTIONS_CONSTANTS.SET_MARKETPLACE_VARIANT_LIST_VENDOR_FILTER,
  (filters: Pick<IMarketplaceBuildFilter, 'vendors'>) => filters,
)();

export const setMarketplaceVariantListTypeFilterAction = createAction(
  ACTIONS_CONSTANTS.SET_MARKETPLACE_VARIANT_LIST_TYPE_FILTER,
  (filters: Pick<IMarketplaceBuildFilter, 'types'>) => filters,
)();

export const setMarketplaceVariantListStockFilterAction = createAction(
  ACTIONS_CONSTANTS.SET_MARKETPLACE_VARIANT_LIST_STOCK_FILTER,
  (filters: Pick<IMarketplaceBuildFilter, 'hasStock'>) => filters,
)();

export const setMarketplaceVariantListSupplierFilterAction = createAction(
  ACTIONS_CONSTANTS.SET_MARKETPLACE_VARIANT_LIST_SUPPLIER_FILTER,
  (filters: Pick<IMarketplaceBuildFilter, 'fulfilledBy'>) => filters,
)();

export const setMarketplaceVariantListConnectionFilterAction = createAction(
  ACTIONS_CONSTANTS.SET_MARKETPLACE_VARIANT_LIST_CONNECTION_FILTER,
  (filters: Pick<IMarketplaceBuildFilter, 'noConnectedProducts'>) => filters,
)();

export const setMarketplaceVariantListPageFilterAction = createAction(
  ACTIONS_CONSTANTS.SET_MARKETPLACE_VARIANT_LIST_PAGE_FILTER,
  (filters: Pick<Paginated<IMarketplaceBuildFilter>, 'page'>) => filters,
)();

export const setMarketplaceVariantListLimitFilterAction = createAction(
  ACTIONS_CONSTANTS.SET_MARKETPLACE_VARIANT_LIST_LIMIT_FILTER,
  (filters: Pick<Paginated<IMarketplaceBuildFilter>, 'limit'>) => filters,
)();

export const setMarketplaceVariantListInventoryFilterAction = createAction(
  ACTIONS_CONSTANTS.SET_MARKETPLACE_VARIANT_LIST_INVENTORY_FILTER,
  (filters: Pick<Paginated<IMarketplaceBuildFilter>, 'productInventory'>) => filters,
)();
