import React from 'react';
import { AppModal } from '../../../../../core/components/overlays/modal/modal';
import { AppTextContainer } from '../../../../../core/components/text-container/text-container';

type QtyConfirmationModalProps = {
  qty: number;
  open: boolean;
  onConfirm: () => void;
  onClose: () => void;
};

export const QtyConfirmationModal = ({
  qty,
  open,
  onClose,
  onConfirm,
}: QtyConfirmationModalProps) => (
  <AppModal
    open={open}
    onClose={onClose}
    title="Pushing products to your store"
    primaryAction={{ content: 'Confirm', onAction: onConfirm }}
    secondaryActions={[{ content: 'Cancel', onAction: onClose }]}
  >
    <AppTextContainer>Create {qty.toLocaleString()} products in Shopify?</AppTextContainer>
  </AppModal>
);
