import fileDownload from 'js-file-download';
import moment from 'moment';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { AppToast } from '../../../../core/components/feedback-indicators/toast/toast';
import { AppModal } from '../../../../core/components/overlays/modal/modal';
import { AppCard } from '../../../../core/components/structure/card/card';
import { AppPage } from '../../../../core/components/structure/page/page';
import { PaymentConfirmationModal } from '../../../../shopify-retailer/components/modals/payment-confirmation-modal/payment-confirmation-modal';
import { payoutsApi } from '../../../api/payouts.api';
import { IPayoutWithOrders, IPendingConfirmation } from '../../../interfaces/IPayout';
import { getStatusAction } from '../../../redux/modules/status/status.actions';
import { getStatusSelector } from '../../../redux/modules/status/status.selectors';
import { OrderList } from '../../containers/order-list/order-list';
import { PayoutSummary } from '../../containers/payout-summary/payout-summary';
import './payout-details-layout.scss';

export const PayoutDetailsLayout = () => {
  const dispatch = useDispatch();
  const { id: payoutId } = useParams<{ id: string }>();
  const history = useHistory();
  const [loading, setLoading] = useState<boolean>(false);
  const [details, setDetails] = useState<IPayoutWithOrders | null>(null);
  const [openPaymentConfirmationModal, setOpenPaymentConfirmationModal] = useState<boolean>(false);
  const [openRemoveConfirmationModal, setOpenRemoveConfirmationModal] = useState<boolean>(false);
  const [confirmingPayment, setConfirmingPayment] = useState<boolean>(false);
  const { currency } = useSelector(getStatusSelector);

  useEffect(() => {
    setLoading(true);
    payoutsApi
      .getPayoutDetails(payoutId)
      .then(({ data }) => setDetails(data))
      .catch(console.error)
      .finally(() => setLoading(false));
  }, [payoutId]);

  const handleExportCSV = useCallback(() => {
    setLoading(true);
    payoutsApi
      .exportOrdersCSV(payoutId)
      .then(({ data }) => {
        const now = moment().format('MMDDYYYY');
        fileDownload(data, `Orders-Invoice-History-${now}.csv`);
      })
      .catch(console.error)
      .finally(() => {
        setLoading(false);
      });
  }, [payoutId]);

  const handlePaymentConfirmation = useCallback(
    (pendingConfirmation: IPendingConfirmation) => {
      setOpenPaymentConfirmationModal(false);
      setConfirmingPayment(true);
      payoutsApi
        .confirmPendingPayment(payoutId, pendingConfirmation)
        .then(() => {
          setDetails(
            (d) =>
              d && { ...d, ...pendingConfirmation, status: 'paid', pendingConfirmation: undefined },
          );
          dispatch(getStatusAction());
        })
        .catch(console.error)
        .finally(() => {
          setConfirmingPayment(false);
        });
    },
    [payoutId, dispatch],
  );

  const handleDeletePayout = useCallback(() => {
    payoutsApi
      .deletePayout(payoutId)
      .then(({ data }) => {
        setOpenRemoveConfirmationModal(false);

        if ('success' in data) {
          history.replace('/accounting?tab=invoice-history');
        }
      })
      .catch(console.error);
  }, [payoutId, history]);

  const showMarkAsPaidBtn = useMemo(() => {
    return details?.status === 'open' && details.pendingConfirmation;
  }, [details]);

  return (
    <div className="payout-details-layout">
      <AppPage
        title="Invoice details"
        primaryAction={
          showMarkAsPaidBtn
            ? {
                content: 'Confirm Payment Received',
                onAction: () => setOpenPaymentConfirmationModal(true),
              }
            : undefined
        }
        secondaryActions={[
          {
            content: 'Delete',
            disabled: details?.paidViaCrowdship,
            onAction: () => setOpenRemoveConfirmationModal(true),
          },
        ]}
      >
        <PayoutSummary payout={details} loading={loading} />
        <AppCard title="Orders" sectioned>
          <OrderList
            orders={details?.items || []}
            fetching={loading}
            // payout orders are presented on a single page
            totalPageCount={1}
            onPageChange={() => {}}
            currency={currency}
            selectable={false}
            handleExportCSV={handleExportCSV}
          />
        </AppCard>
      </AppPage>
      {confirmingPayment && (
        <AppToast content="Confirming payment..." onDismiss={() => setConfirmingPayment(false)} />
      )}
      {details && (
        <PaymentConfirmationModal
          open={openPaymentConfirmationModal}
          onClose={() => setOpenPaymentConfirmationModal(false)}
          onConfirm={handlePaymentConfirmation}
          payout={details}
          ordersCount={details.items.length}
          supplierName={details.supplierName}
          pendingConfirmation={details.pendingConfirmation}
        />
      )}
      {details && (
        <AppModal
          open={openRemoveConfirmationModal}
          onClose={() => setOpenRemoveConfirmationModal(false)}
          title="Delete payout?"
          primaryAction={{
            content: 'Remove',
            destructive: true,
            onAction: handleDeletePayout,
          }}
          secondaryActions={[
            {
              content: 'Cancel',
              onAction: () => setOpenRemoveConfirmationModal(false),
            },
          ]}
        >
          {`Are you sure you want to delete payout ${details.name}? This action cannot be undone.`}
        </AppModal>
      )}
    </div>
  );
};
