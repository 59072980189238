import { Form, Formik, FormikHelpers } from 'formik';
import { useCallback, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { AppButton } from '../../../../core/components/button/Button';
import { AppCheckbox } from '../../../../core/components/checkbox/checkbox';
import { AppToast } from '../../../../core/components/feedback-indicators/toast/toast';
import { AppControlledTextField } from '../../../../core/components/forms/controlled-text-field/controlled-text-field';
import { AppThumbnail } from '../../../../core/components/image-containers/thumbnail/Thumbnail';
import { AppIndexTable } from '../../../../core/components/index-table/index-table';
import { AppIndexTableCell } from '../../../../core/components/index-table/index-table-cell';
import { AppIndexTableRow } from '../../../../core/components/index-table/index-table-row';
import { AppCard } from '../../../../core/components/structure/card/card';
import { AppTextStyle } from '../../../../core/components/text/text-style/TextStyle';
import { withCurrency } from '../../../../core/helpers/currency.helper';
import { validation } from '../../../../core/helpers/validations.helper';
import {
  convertPoundsAndOuncesToGrams,
  convertWeightFromGrams,
  convertWeightFromGramsToPoundsAndOunces,
} from '../../../../core/helpers/weight.helper';
import { ICurrency } from '../../../../core/interfaces/ICurrency';
import { ordersApi } from '../../../api/orders.api';
import { ILocation } from '../../../interfaces/ILocation';
import { IDimensions, IOrderDetailsProduct, IWeight } from '../../../interfaces/IOrder';
import { getOrderDetailsAction } from '../../../redux/modules/orders/orders.actions';
import { getStatusAction } from '../../../redux/modules/status/status.actions';
import { DimensionsInputGroup } from './inputs/dimensions-input-group';
import { WeightInputGroup } from './inputs/weight-input-group';
import { LocationTooltip } from './location-tooltip';
import { AppBanner } from '../../../../core/components/feedback-indicators/banner/banner';
import { AppLink } from '../../../../core/components/link/link';
import { useIntercom } from 'react-use-intercom';

type Rate = {
  name: string;
  rateId: string;
  cost: number;
};

export type LabelCandidateProductGroup = {
  products: (IOrderDetailsProduct & {
    unfulfilledQty: string;
  })[];
  location: Omit<ILocation, 'countryName' | 'active' | 'legacy' | 'phone'>;
  weight: IWeight;
  dimensions: IDimensions;
};

type LabelCandidateProps = {
  orderId: string;
  group: LabelCandidateProductGroup;
  requestedShipping?: string;
  currency?: ICurrency;
};

export const LabelCandidate = ({
  orderId,
  group,
  requestedShipping,
  currency = { isoCode: 'USD', symbol: '$' },
}: LabelCandidateProps) => {
  const dispatch = useDispatch();
  const [errorToast, setErrorToast] = useState('');
  const [showTooHeavyShipmentToast, setShowTooHeavyShipmentToast] = useState(false);
  const [invalidToken, setInvalidToken] = useState(false);
  const [calculatingCost, setCalculatingCost] = useState(false);
  const [purchasingLabel, setPurchasingLabel] = useState(false);
  const [rate, setRate] = useState<Rate | null>(null);
  const [bypassValidation, setBypassValidation] = useState(true);

  const { show } = useIntercom();

  const handleChangeBypassValidation = useCallback(
    (newChecked) => setBypassValidation(newChecked),
    [],
  );

  const calculateParcelWeight = useCallback(
    (weight: { lbs: string; oz: string }, totalProductsWeight: number) => {
      const { lbs, oz } = convertWeightFromGramsToPoundsAndOunces(totalProductsWeight);

      return lbs === +weight.lbs && oz === +weight.oz
        ? totalProductsWeight
        : convertPoundsAndOuncesToGrams(weight.lbs, weight.oz);
    },
    [],
  );

  const calculateCost = useCallback(
    (grp: LabelCandidateProductGroup, { resetForm }: FormikHelpers<LabelCandidateProductGroup>) => {
      setCalculatingCost(true);
      ordersApi
        .calcShippingCost(
          orderId,
          grp.products
            .map((p) => ({
              id: p.variantId,
              name: p.title,
              price: p.price,
              grams: p.grams,
              qty: +p.unfulfilledQty,
            }))
            .filter((p) => p.qty),
          grp.location._id,
          calculateParcelWeight(
            grp.weight,
            grp.products.reduce((total, p) => total + p.grams * +p.unfulfilledQty, 0),
          ),
          grp.dimensions,
          bypassValidation,
        )
        .then(({ data }) => {
          if ('cannotFitInOneShipment' in data) {
            setShowTooHeavyShipmentToast(true);
          } else if ('rateId' in data) {
            setRate(data);
          } else {
            console.error('Unexpected return result: ', data);
          }
          setInvalidToken('invalidToken' in data);
        })
        .catch((err) => {
          console.error(err);
          setErrorToast('Error: please, re-check the entered data');
        })
        .finally(() => {
          setCalculatingCost(false);
          resetForm({ values: grp });
        });
    },
    [orderId, bypassValidation, calculateParcelWeight],
  );

  const purchaseLabel = useCallback(
    (grp: LabelCandidateProductGroup) => {
      if (!rate) return;
      setPurchasingLabel(true);
      ordersApi
        .purchaseShippingLabel(
          orderId,
          grp.products
            .map((p) => ({ id: p.variantId, grams: p.grams, qty: +p.unfulfilledQty }))
            .filter((p) => p.qty),
          grp.location._id,
          calculateParcelWeight(
            grp.weight,
            grp.products.reduce((total, p) => total + p.grams * +p.unfulfilledQty, 0),
          ),
          grp.dimensions,
          rate.rateId,
          rate.cost,
        )
        .then(() => {
          dispatch(getOrderDetailsAction(orderId));
          dispatch(getStatusAction());
        })
        .catch((err) => {
          console.error(err);
          setErrorToast(err?.msg || 'Error: please, re-check the entered data');
        })
        .finally(() => {
          setRate(null);
          setPurchasingLabel(false);
        });
    },
    [orderId, rate, dispatch, calculateParcelWeight],
  );

  const renderSection = useMemo(
    () => (
      <>
        <div>
          <AppCheckbox
            label="Bypass address validation"
            checked={bypassValidation}
            onChange={handleChangeBypassValidation}
          />
        </div>
        <AppIndexTable
          resourceName={{ singular: 'item', plural: 'items' }}
          headings={[
            { title: 'Image', hidden: true },
            { title: 'Title' },
            { title: 'Weight per item (lbs)' },
            { title: 'Quantity' },
          ]}
          itemCount={group.products.length}
          onSelectionChange={() => {}}
          selectedItemsCount={0}
          hasMoreItems={false}
          loading={false}
          selectable={false}
        >
          {group.products.map((p, i) => (
            <AppIndexTableRow id={p.variantId} key={p.variantId} selected={false} position={i}>
              <AppIndexTableCell>
                <AppThumbnail alt={p.title} source={p.image} />
              </AppIndexTableCell>
              <AppIndexTableCell>
                <div className="item">
                  <div className="title">{p.title}</div>

                  {p.variantTitle?.toLowerCase() !== 'default title' && (
                    <div className="variant">{p.variantTitle}</div>
                  )}

                  <div className="sku">SKU: {p.sku}</div>
                </div>
              </AppIndexTableCell>
              <AppIndexTableCell>
                <div className="weight">{convertWeightFromGrams(p.grams)}</div>
              </AppIndexTableCell>
              <AppIndexTableCell>
                <div className="quantity">
                  <AppControlledTextField
                    name={`products.[${i}].unfulfilledQty`}
                    type="number"
                    suffix={`/${p.unfulfilledQty}`}
                    validate={(val) => validation.isNumber(val, { min: 0, max: +p.unfulfilledQty })}
                    min={0}
                    max={+p.unfulfilledQty}
                  />
                </div>
              </AppIndexTableCell>
            </AppIndexTableRow>
          ))}
        </AppIndexTable>
      </>
    ),
    [group, bypassValidation, handleChangeBypassValidation],
  );

  const renderFooter = useCallback(
    (dirty: boolean, submitForm: () => void) => {
      return (
        <div className="label-candidate-footer">
          <div className="parcel-parameters label-candidate-footer--row">
            <div className="title">
              <AppTextStyle variation="strong">Parcel parameters</AppTextStyle>
            </div>
            <div className="content">
              <DimensionsInputGroup />
              <WeightInputGroup />
            </div>
            <div className="button-space"></div>
          </div>
          <div className="selected-method label-candidate-footer--row">
            <div className="title">
              <AppTextStyle variation="strong">Requested service</AppTextStyle>
            </div>
            <div className="content">
              {requestedShipping || <AppTextStyle variation="subdued">not selected</AppTextStyle>}
            </div>
            <div className="button-space"></div>
          </div>
          <div className="calculated-method label-candidate-footer--row">
            <div className="title">
              <AppTextStyle variation="strong">Calculated Shipping Method</AppTextStyle>
            </div>
            <div className="content">
              {rate && !dirty ? (
                rate.name
              ) : (
                <AppTextStyle variation="subdued">not calculated</AppTextStyle>
              )}
            </div>
            <div className="button-space">
              <AppButton
                onClick={() => {
                  if (rate && dirty) setRate(null);
                  submitForm();
                }}
                loading={calculatingCost || purchasingLabel}
                primary
              >
                {rate && !dirty
                  ? `Purchase label (${withCurrency(rate.cost, currency)})`
                  : 'Calculate label cost'}
              </AppButton>
            </div>
          </div>
          {invalidToken && (
            <AppBanner status="warning">
              The Retailer’s Shippo token has become invalid. Please contact Crowdship support at
              support@crowdship.io or by using <AppLink onClick={show}>the chat</AppLink>.
            </AppBanner>
          )}
        </div>
      );
    },
    [calculatingCost, currency, invalidToken, purchasingLabel, rate, requestedShipping, show],
  );

  return (
    <Formik
      initialValues={group}
      onSubmit={rate ? purchaseLabel : calculateCost}
      enableReinitialize
    >
      {({ submitForm, dirty }) => (
        <Form name={`label-${group.location._id}-${group.products[0].variantId}`}>
          <div className="label-candidate">
            <AppCard
              title={<LocationTooltip location={group.location} />}
              sections={[{ content: renderSection }, { content: renderFooter(dirty, submitForm) }]}
            />
            {showTooHeavyShipmentToast && (
              <AppToast
                error
                content="Shipment is too heavy"
                onDismiss={() => setShowTooHeavyShipmentToast(false)}
              />
            )}
            {errorToast && (
              <AppToast error content={errorToast} onDismiss={() => setErrorToast('')} />
            )}
          </div>
        </Form>
      )}
    </Formik>
  );
};
