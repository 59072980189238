import { createReducer } from 'typesafe-actions';
import { ISupplierStatus, MARKETPLACE_APPROVAL_STATUS } from '../../../api/status.api';
import { RootActions } from '../../actions';
import { setStatusAction, setUpdatingPriceMappingPreferenceAction } from './status.actions';

interface IStatusReducer extends ISupplierStatus {}

const initialState: IStatusReducer = {
  cancellationRequests: 0,
  connected: true,
  activeProductsCount: 1,
  totalProductsCount: 2,
  updatingPriceMappingPreference: false,
  marketplaceApprovalStatus: MARKETPLACE_APPROVAL_STATUS.NOT_ISSUED,
  currency: { isoCode: 'USD', symbol: '$' },
  transactionFee: 0,
  payoutPendingPayoutsCount: 0,
  updatedApi: false,
};

export const statusReducer = createReducer<IStatusReducer, RootActions>(initialState)
  .handleAction(setStatusAction, (state, { payload }) => payload)
  .handleAction(setUpdatingPriceMappingPreferenceAction, (state, { payload }) => ({
    ...state,
    updatingPriceMappingPreference: payload,
  }));
