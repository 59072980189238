import { useCallback, useMemo } from 'react';
import { AppChoiceList } from '../../../../choice-list/ChoiceList';
import { FilterElementProps } from './filter-elements.interface';

export const CONNECTION_FILTER_KEY = 'isConnected';
const CONNECTION_FILTER_LABEL = 'Connection';
const CONNECTION_FILTER_TITLE = 'Connection options';

enum CONNECTION_OPTIONS {
  ALL = 'All products',
  CONNECTED = 'Connected only',
  NOT_CONNECTED = 'Not connected only',
}

export const connectionOptions = Object.values(CONNECTION_OPTIONS).map((v) => ({
  label: v,
  value: v,
}));

export const convertToConnectionEnum = (value: boolean | undefined) =>
  value
    ? CONNECTION_OPTIONS.CONNECTED
    : value === undefined
    ? CONNECTION_OPTIONS.ALL
    : CONNECTION_OPTIONS.NOT_CONNECTED;

/** For boolean values we need to write a separate interface. Boolean gets interpreted as never by typescript. */
type FilterProps = {
  onChange: (value: boolean | undefined) => void;
  value: boolean | undefined;
};

const Filter = ({ onChange, value }: FilterProps) => {
  const selected = useMemo(() => {
    return [convertToConnectionEnum(value)];
  }, [value]);

  const handleChange = useCallback(
    ([updated]: CONNECTION_OPTIONS[]) => {
      const updatedValue =
        updated === CONNECTION_OPTIONS.CONNECTED
          ? true
          : updated === CONNECTION_OPTIONS.NOT_CONNECTED
          ? false
          : undefined;

      onChange(updatedValue);
    },
    [onChange],
  );

  return (
    <AppChoiceList
      title={CONNECTION_FILTER_TITLE}
      titleHidden
      choices={connectionOptions}
      selected={selected}
      onChange={handleChange}
    />
  );
};

export const ConnectionFilter = ({ value, onChange }: FilterElementProps<boolean | undefined>) => ({
  key: CONNECTION_FILTER_KEY,
  label: CONNECTION_FILTER_LABEL,
  shortcut: true,
  hideClearButton: true,
  filter: <Filter onChange={onChange as (value: boolean | undefined) => void} value={value} />,
});
