import { capitalize } from 'lodash';
import { useCallback, useMemo } from 'react';
import { AVAILABILITY, ISupplierProduct } from '../../../../interfaces/IProduct';
import { AppThumbnail } from '../../../image-containers/thumbnail/Thumbnail';
import { AppIndexTableCell } from '../../../index-table/index-table-cell';
import { AppIndexTableRow } from '../../../index-table/index-table-row';
import { AppTextStyle } from '../../../text/text-style/TextStyle';
import { TrackedQuantityLabelForProduct } from '../../../tracked-quantity-label-for-product/tracked-quantity-label-for-product';
import { ProductRowBaseProps } from '../../product-list.interfaces';
import { AverageMargin } from '../average-margin';
import { ExpandVariantsBtn } from '../expand-variants-btn';
import { LinkToPlatform } from '../link-to-platform';
import { AppBadge } from '../../../badge/badge';
import { useSelector } from 'react-redux';
import { getStatusSelector } from '../../../../../shopify-supplier/redux/modules/status/status.selectors';
import { CustomPriceIndicator } from '../custom-price-indicator';

type SupplierProductRowProps = ProductRowBaseProps<ISupplierProduct> & {};

export const SupplierProductRow = ({
  product,
  selected,
  index,
  expandedVariants,
  toggleExpansion,
}: SupplierProductRowProps) => {
  const { updatingPriceMappingPreference } = useSelector(getStatusSelector);

  const getAvailabilty = useCallback((availableForRetailers: boolean, retailerGroups: string[]) => {
    if (availableForRetailers) return AVAILABILITY.MARKETPLACE;

    if (retailerGroups.length) return retailerGroups.join();

    return AVAILABILITY.UNAVAILABLE;
  }, []);

  const {
    id,
    title,
    image,
    productType,
    vendor,
    quantityAvailable,
    variationsNumber,
    averageMargin,
    platformId,
    availableForRetailers,
    retailerGroups,
    customPriceForRetailerCount,
    customMSRPCount,
    shopifyStatus,
    variants,
  } = product;

  const renderRetailerMargin = useMemo(() => {
    const validMargin = averageMargin || averageMargin === 0;
    const isFixed = variants.every((v) => v.manuallySetMSRP && v.manuallySetPriceForRetailers);
    if ((updatingPriceMappingPreference && !isFixed) || !validMargin) {
      return <AppTextStyle variation="negative">--%</AppTextStyle>;
    }
    return <AverageMargin margin={averageMargin} />;
  }, [averageMargin, updatingPriceMappingPreference, variants]);

  return (
    <AppIndexTableRow id={id} key={id} selected={selected} position={index}>
      <AppIndexTableCell>
        <div className="product-image">
          <AppThumbnail source={image} alt={title} />
        </div>
      </AppIndexTableCell>
      <AppIndexTableCell>
        <div className="product-title">
          <AppTextStyle variation="subdued">{vendor}</AppTextStyle>
          <AppTextStyle variation="strong">{title}</AppTextStyle>
          <div className="row-links">
            <LinkToPlatform visible={expandedVariants} platformId={platformId} />
          </div>
        </div>
      </AppIndexTableCell>
      <AppIndexTableCell>{productType}</AppIndexTableCell>
      <AppIndexTableCell>
        {!shopifyStatus || shopifyStatus === 'processing' ? (
          <AppBadge status="new">Processing</AppBadge>
        ) : (
          <AppBadge status={shopifyStatus === 'active' ? 'success' : 'info'}>
            {capitalize(shopifyStatus)}
          </AppBadge>
        )}
      </AppIndexTableCell>
      <AppIndexTableCell>
        <TrackedQuantityLabelForProduct quantity={quantityAvailable} variants={variants} />
      </AppIndexTableCell>
      <AppIndexTableCell>
        <div className="seller-wrapper">
          {renderRetailerMargin}
          &nbsp;
          <CustomPriceIndicator show={!!(customPriceForRetailerCount || customMSRPCount)} />
        </div>
      </AppIndexTableCell>
      <AppIndexTableCell>
        <AppBadge status={availableForRetailers || retailerGroups.length ? 'success' : 'critical'}>
          {getAvailabilty(availableForRetailers, retailerGroups)}
        </AppBadge>
      </AppIndexTableCell>
      <AppIndexTableCell>
        <ExpandVariantsBtn
          variantsCount={variationsNumber}
          expanded={expandedVariants}
          toggleExpansion={toggleExpansion}
        />
      </AppIndexTableCell>
    </AppIndexTableRow>
  );
};
