import React, { useCallback, useMemo } from 'react';
import { AppPagination } from '../pagination';
import { FIRST_PAGE } from '../../../constants/product.constants';

import '../page-aware-pagination.scss';

type PagePaginationProps = {
  currentPage?: number;
  totalPageCount: number;
  onPageChange: (page: number) => void;
};

export const PagePagination = ({
  currentPage = 0,
  totalPageCount,
  onPageChange,
}: PagePaginationProps) => {
  const hasPrevious = useMemo(() => currentPage !== FIRST_PAGE, [currentPage]);
  const hasNext = useMemo(
    () => !!totalPageCount && currentPage !== totalPageCount + FIRST_PAGE - 1,
    [currentPage, totalPageCount],
  );

  const onNext = useCallback(() => {
    const nextPage = currentPage + 1;
    onPageChange(nextPage);
  }, [onPageChange, currentPage]);

  const onPrevious = useCallback(() => {
    const previousPage = currentPage - 1;
    onPageChange(previousPage);
  }, [onPageChange, currentPage]);

  return (
    <div className="pagination-section">
      <AppPagination
        hasPrevious={hasPrevious}
        hasNext={hasNext}
        onPrevious={onPrevious}
        onNext={onNext}
      />
    </div>
  );
};
