import { combineReducers } from 'redux';
import { StateType } from 'typesafe-actions';
import { orderReducer } from './modules/orders/orders.reducer';
import { coreReducers } from '../../core/redux/reducers';
import { dashboardReducer } from './modules/dashboard/dashboard-reducer';
import { preferencesReducer } from './modules/preferences/preferences.reducer';
import { productsReducer } from './modules/products/products.reducer';
import { statusReducer } from './modules/status/status.reducer';

export const reducers = combineReducers({
  ...coreReducers,
  products: productsReducer,
  orders: orderReducer,
  dashboard: dashboardReducer,
  preferences: preferencesReducer,
  status: statusReducer,
});

export type RootState = StateType<typeof reducers>;
