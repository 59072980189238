import axios from 'axios';

class RefreshTokenApi {
  private backend = process.env.REACT_APP_BACK_END_URL as string;
  private rootPath = '/api/retailer/standalone/auth';

  async updateAccessToken(accountId: string): Promise<{ token: string }> {
    const { data } = await axios.post(`${this.backend}${this.rootPath}/update-access-token`, {
      accountId,
    });
    return data;
  }
}

export const refreshTokenApi = new RefreshTokenApi();
