import React from 'react';
import { AppThumbnail } from '../../../../core/components/image-containers/thumbnail/Thumbnail';
import { AppIndexTable } from '../../../../core/components/index-table/index-table';
import { AppCard } from '../../../../core/components/structure/card/card';
import { AppStack } from '../../../../core/components/structure/stack/Stack';
import { AppTextStyle } from '../../../../core/components/text/text-style/TextStyle';
import { IProductDetails } from '../../../interfaces/IProductDetails';
import './product-details.scss';
import { VariantListRow } from './variant-list-row';

type ProductDetailsProps = {
  product: IProductDetails;
  unsavedProduct: IProductDetails;
  setFieldValue: (field: string, value: any) => void;
  setUpdating: (value: boolean) => void;
};

export const ProductDetails = ({
  product,
  unsavedProduct,
  setFieldValue,
  setUpdating,
}: ProductDetailsProps) => {
  return (
    <AppCard
      title="Details"
      sections={[
        {
          content: (
            <div className="product-level-section">
              <div className="product-image">
                <AppThumbnail source={product.image} alt={product.title} size="large" />
              </div>
              <AppStack vertical>
                <div>Status: {product.shopifyStatus}</div>
                <div>
                  Type:{' '}
                  {product.productType || (
                    <AppTextStyle variation="subdued">Not defined</AppTextStyle>
                  )}
                </div>
                <div>Brand: {product.vendor}</div>
              </AppStack>
            </div>
          ),
        },
        {
          title: 'Variants',
          content: (
            <div className="variant-level-section">
              <AppIndexTable
                selectable={false}
                resourceName={{ singular: 'variant', plural: 'variants' }}
                headings={[
                  { title: 'Image', hidden: true },
                  { title: 'Title' },
                  { title: 'SKU' },
                  { title: 'Cost' },
                  { title: 'SRP' },
                  { title: 'Price Sync' },
                  { title: 'Your margin' },
                  { title: 'Qty' },
                ]}
                itemCount={product.variants.length}
              >
                {product.variants.map((variant, index) => (
                  <VariantListRow
                    shopifyProductId={product.shopifyId}
                    productId={product.id}
                    variant={{
                      ...variant,
                      retailerDefaultMarkup: product.retailerDefaultMarkup,
                      retailerSalePriceOption: product.retailerSalePriceOption,
                    }}
                    unsavedVariant={unsavedProduct.variants[index]}
                    position={index}
                    setFieldValue={setFieldValue}
                    setUpdating={setUpdating}
                  />
                ))}
              </AppIndexTable>
            </div>
          ),
        },
      ]}
    />
  );
};
