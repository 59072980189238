import { List } from '@shopify/polaris';
import fileDownload from 'js-file-download';
import moment from 'moment';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { AppCard } from '../../../../core/components/structure/card/card';
import { ORDERS_PER_PAGE } from '../../../../core/constants/orders';
import { withCurrency } from '../../../../core/helpers/currency.helper';
import {
  IGetUnpaidBatchesSummaryResult,
  IGetUnpaidPayoutsFilters,
  invoiceApi,
  PayoutDisplayInfo,
} from '../../../api/invoice.api';
import { UnpaidBatchesTable } from '../unpaid-batches-table/unpaid-batches-table';

export const UnpaidBatchesListLayout = () => {
  const [fetching, setFetching] = useState(false);
  const [payouts, setPayouts] = useState<PayoutDisplayInfo[]>([]);
  const [selectedSupplier, setSelectedSupplier] = useState<string[]>([]);
  const [suppliers, setSuppliers] = useState<
    {
      label: string;
      value: string;
    }[]
  >([]);
  const [total, setTotal] = useState(0);
  const [filterParams, setFilterParams] = useState<IGetUnpaidPayoutsFilters>({
    page: 0,
    limit: ORDERS_PER_PAGE,
  });
  const [summary, setSummary] = useState<IGetUnpaidBatchesSummaryResult>({
    totalBatches: 0,
    totalNotPaidOut: 0,
  });
  const [summaryFetching, setSummaryFetching] = useState(true);

  useEffect(() => {
    setFetching(true);
    invoiceApi
      .getUnpaidBatches(filterParams)
      .then(({ data }) => {
        setPayouts(data.payouts);
        setTotal(data.total);
      })
      .catch(console.error)
      .finally(() => setFetching(false));
  }, [filterParams]);

  useEffect(() => {
    setFetching(true);
    invoiceApi
      .getSuppliersForUnpaidBatches()
      .then(({ data }) => {
        setSuppliers(data);
      })
      .catch(console.error)
      .finally(() => setFetching(false));
  }, []);

  useEffect(() => {
    setSummaryFetching(true);
    invoiceApi
      .getUnpaidOrdersSummary()
      .then(({ data }) => {
        setSummary(data);
      })
      .catch(console.error)
      .finally(() => setSummaryFetching(false));
  }, []);

  const onPageChange = useCallback(
    (page: number) => {
      setFilterParams({ ...filterParams, page });
    },
    [filterParams, setFilterParams],
  );

  const handleExportCSV = useCallback((id: string) => {
    setFetching(true);
    invoiceApi
      .exportUnpaidInvoice(id)
      .then(({ data }) => {
        const now = moment().format('MMDDYYYY');
        fileDownload(data, `Open Invoice-${now}.csv`);
        setFetching(false);
      })
      .catch(console.error)
      .finally(() => {
        setFetching(false);
      });
  }, []);

  const totalPayoutsPageCount = useMemo(() => Math.ceil(total / ORDERS_PER_PAGE), [total]);

  return (
    <>
      <AppCard
        title="Summary"
        sections={[
          {
            content: summaryFetching ? (
              <div>fetching</div>
            ) : (
              <div>
                <List type="bullet">
                  <List.Item>{`Number of open invoices: ${summary.totalBatches}`}</List.Item>

                  <List.Item>{`Total payouts due: ${withCurrency(
                    summary.totalNotPaidOut,
                  )}`}</List.Item>
                </List>
              </div>
            ),
          },
        ]}
      />
      <AppCard
        title="Batch Invoices"
        sections={[
          {
            content: (
              <UnpaidBatchesTable
                payouts={payouts}
                fetching={fetching}
                totalPageCount={totalPayoutsPageCount}
                onPageChange={onPageChange}
                setFilterParams={setFilterParams}
                filterParams={filterParams}
                suppliers={suppliers}
                selectedSupplier={selectedSupplier}
                setSelectedSupplier={setSelectedSupplier}
                handleExportCSV={handleExportCSV}
              />
            ),
          },
        ]}
      ></AppCard>
    </>
  );
};
