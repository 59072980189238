import { capitalize } from 'lodash';
import { AppBadge } from '../../../../core/components/badge/badge';
import { SupplierOrderStatus, SupplierPayoutStatus } from '../../../interfaces/IOrder';

type OrderStatusProps = {
  status: SupplierOrderStatus | SupplierPayoutStatus;
};

export const OrderStatus = ({ status }: OrderStatusProps) => {
  switch (status) {
    case 'unfulfilled':
      return (
        <AppBadge status="attention" progress="incomplete">
          {capitalize(status)}
        </AppBadge>
      );
    case 'partially unfulfilled':
      return (
        <AppBadge status="warning" progress="partiallyComplete">
          {capitalize(status)}
        </AppBadge>
      );
    case 'partially fulfilled':
      return (
        <AppBadge status="warning" progress="partiallyComplete">
          {capitalize(status)}
        </AppBadge>
      );
    case 'fulfilled':
      return <AppBadge progress="complete">{capitalize(status)}</AppBadge>;
    case 'cancelled':
      return (
        <AppBadge status="critical" progress="complete">
          {capitalize(status)}
        </AppBadge>
      );
    case 'payout pending':
      return <AppBadge status="info">{capitalize(status)}</AppBadge>;
    case 'paid':
      return <AppBadge progress="complete">{capitalize(status)}</AppBadge>;
    case 'cancellation requested':
      return (
        <AppBadge status="critical" progress="complete">
          {capitalize(status)}
        </AppBadge>
      );
    default:
      return null;
  }
};
