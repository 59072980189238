import { AppButton } from '../../button/Button';

type ExpandVariantsBtnProps = {
  variantsCount: number;
  expanded: boolean;
  toggleExpansion: () => void;
};

export const ExpandVariantsBtn = ({
  variantsCount,
  expanded,
  toggleExpansion,
}: ExpandVariantsBtnProps) => (
  <div className="expand-variants-btn">
    <AppButton
      handleClick={(e) => {
        e.stopPropagation();
        toggleExpansion();
      }}
    >
      {expanded
        ? `Hide ${variantsCount} ${variantsCount > 1 ? 'SKUs' : 'SKU'}`
        : `Show ${variantsCount} ${variantsCount > 1 ? 'SKUs' : 'SKU'}`}
    </AppButton>
  </div>
);
