import { createAction } from 'typesafe-actions';
import { IPreferencesFromApi } from '../../../api/preferences.api';
import { IPreferences } from '../../../interfaces/IPreferences';
import { ACTIONS_CONSTANTS } from '../../actions-constants';

export const getPreferencesAction = createAction(ACTIONS_CONSTANTS.GET_SETTINGS)();

export const setPreferencesFromApiAction = createAction(
  ACTIONS_CONSTANTS.SET_SETTINGS_FROM_API,
  (info: IPreferencesFromApi) => info,
)();

export const showAddressValidationFailedToastAction = createAction(
  ACTIONS_CONSTANTS.SHOW_ADDRESS_VALIDATION_FAILED_TOAST,
)();
export const hideAddressValidationFailedToastAction = createAction(
  ACTIONS_CONSTANTS.HIDE_ADDRESS_VALIDATION_FAILED_TOAST,
)();

export const hideAddressValidationSuccessoastAction = createAction(
  ACTIONS_CONSTANTS.HIDE_ADDRESS_VALIDATION_SUCCESS_TOAST,
)();

export const setPreferencesAction = createAction(
  ACTIONS_CONSTANTS.SET_SETTINGS,
  (preferences: IPreferences) => preferences,
)();
