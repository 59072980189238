import Decimal from 'decimal.js';
import React, { useCallback, useMemo } from 'react';
import { AppBanner } from '../../../../core/components/feedback-indicators/banner/banner';
import { AppThumbnail } from '../../../../core/components/image-containers/thumbnail/Thumbnail';
import { ShippingAddress } from '../../../../core/components/order-details/shipping-address/ShippingAddress';
import { AppCard } from '../../../../core/components/structure/card/card';
import { CardSubsection } from '../../../../core/components/structure/card/card-subsection';
import { withCurrency } from '../../../../core/helpers/currency.helper';
import { ICurrency } from '../../../../core/interfaces/ICurrency';
import { TrackingNumbers } from '../../../../shopify-retailer/components/containers/order/common/tracking-numbers/tracking-numbers';
import {
  IAddress,
  IOrderDetailsProduct,
  IOrderDetailsTotals,
  IOriginalAddress,
  IRetailerOrderDetails,
} from '../../../interfaces/IOrder';
import './order-details.scss';
import { useSelector } from 'react-redux';
import { acceptingCancellationSelector } from '../../../redux/modules/orders/orders.selectors';

type ProductGroup = 'active' | 'cancelled' | 'pending cancellation';

type IOrderDetailsProps = {
  retailerOrderName: string;
  retailer: IRetailerOrderDetails;
  shippingAddress?: IAddress;
  originalShippingAddress?: IOriginalAddress;
  createdAt: string;
  status: string;
  products: IOrderDetailsProduct[];
  totals: IOrderDetailsTotals;
  onAcceptCancellationRequest: () => void;
  onDeclineCancellationRequest: () => void;
  currency?: ICurrency;
};

export function OrderDetails({
  retailer,
  retailerOrderName,
  status,
  shippingAddress,
  originalShippingAddress,
  products,
  totals,
  onAcceptCancellationRequest,
  onDeclineCancellationRequest,
  currency = { isoCode: 'USD', symbol: '$' },
}: IOrderDetailsProps) {
  const acceptingCancellation = useSelector(acceptingCancellationSelector);

  const activeProducts = useMemo(
    () =>
      products.filter(
        (p) => p.cancellations.reduce((q, c) => q + c.quantity.accepted, 0) !== p.totalQty,
      ),
    [products],
  );
  const pendingCancellationProducts = useMemo(
    () =>
      products.filter((p) =>
        p.cancellations.some((c) => c.pending && c.initiatedBy === 'retailer'),
      ),
    [products],
  );
  const cancelledProducts = useMemo(
    () =>
      products.filter((p) =>
        p.cancellations.some(
          (c) => !c.pending && c.quantity.requested !== c.quantity.rejected + c.quantity.voided,
        ),
      ),
    [products],
  );
  const hasCancelledItems = useMemo(() => !!cancelledProducts.length, [cancelledProducts]);

  const renderTracking = useCallback(
    (
      numbers: string[],
      urls: string[],
      companies: string[],
      fulfilledQty: number,
    ): React.ReactNode => (
      <TrackingNumbers
        companies={companies}
        numbers={numbers}
        urls={urls}
        fulfilledQty={!!fulfilledQty}
      />
    ),
    [],
  );

  const renderProductList = useCallback(
    (products: IOrderDetailsProduct[], group: ProductGroup = 'active') => {
      const lessColumns = group !== 'active' ? 'no-tracking' : '';
      const getQuantity = (product: IOrderDetailsProduct) => {
        switch (group) {
          case 'active':
            return product.fulfilledQty;
          case 'cancelled':
            return product.cancellations.reduce((q, c) => q + c.quantity.accepted, 0);
          case 'pending cancellation':
            return product.cancellations
              .filter((c) => c.pending && c.initiatedBy === 'retailer')
              .reduce((q, c) => q + c.quantity.requested, 0);
          default:
            return 0;
        }
      };
      return (
        <div className={`retail-line-items-list ${lessColumns}`}>
          {group === 'pending cancellation' && (
            <div className="cancellation-banner" style={{ paddingBottom: '2em' }}>
              <AppBanner status="critical">
                The retailer has requested cancellation for this order. To decline this request mark
                this order's line items as fulfilled. To approve click the button below. To approve
                only a few line items, fulfill the rest at Shopify first and then click "Accept
                request". Before you click "Accept request" please make sure that your fulfilled
                line items are marked correctly and include your latest fulfillments. Otherwise,
                refresh this page. Only the line items that are not fulfilled will be removed.
              </AppBanner>
            </div>
          )}
          {!products.length && <CardSubsection>No active products in this order</CardSubsection>}
          {!!products.length && (
            <CardSubsection>
              <div className="line-item-row header">
                <div className="image">Image</div>
                <div className="item">Title</div>
                {!lessColumns && <div className="fulfillment">Tracking</div>}
                {group === 'active' && <div className="quantity">Fulfilled/Total</div>}
                {group === 'cancelled' && <div className="quantity">Cancelled/Total</div>}
                {group === 'pending cancellation' && <div className="quantity">Pending/Total</div>}
                <div className="price">Price</div>
                <div className="price">Total</div>
              </div>
            </CardSubsection>
          )}
          {products.map((p, i) => (
            <CardSubsection key={i}>
              <div className="line-item-row">
                <div className="image">
                  <AppThumbnail alt={p.title} source={p.image} />
                </div>
                <div className="item">
                  <div className="title">{p.title}</div>
                  {p.variantTitle?.toLowerCase() !== 'default title' && (
                    <div className="variant">{p.variantTitle}</div>
                  )}
                  SKU: {p.sku}
                </div>
                {!lessColumns && (
                  <div className="fulfillment">
                    {renderTracking(
                      p.trackingNumbers,
                      p.trackingUrls,
                      p.trackingCompanies,
                      p.fulfilledQty,
                    )}
                  </div>
                )}
                <div>
                  {getQuantity(p)}/{p.totalQty}
                </div>
                <div className="price">{withCurrency(p.price, currency)}</div>
                <div className="price">
                  {withCurrency(
                    new Decimal(p.price)
                      .mul(group === 'active' ? p.totalQty : getQuantity(p))
                      .toNumber(),
                    currency,
                  )}
                </div>
              </div>
            </CardSubsection>
          ))}
        </div>
      );
    },
    [renderTracking, currency],
  );

  const manualRefunded = useMemo(() => {
    return totals.manualRefund?.map((cr, i) =>
      cr.amount ? (
        <div className="totals-desc-row bold">
          <div className="title">Refund {i > 0 ? i : ''}</div>
          <div className="desc">Manual: {cr.reason}</div>
          <div className="cost">-{withCurrency(cr.amount, currency)}</div>
        </div>
      ) : null,
    );
  }, [currency, totals.manualRefund]);

  const renderTotalsDesc = useCallback(() => {
    return (
      <CardSubsection>
        <div className="totals-desc-list">
          <div className="totals-desc-row">
            <div className="title">Subtotal</div>
            <div className="desc">{products.reduce((acc, p) => acc + p.totalQty, 0)} items</div>
            <div className="cost">{withCurrency(totals.subtotal, currency)}</div>
          </div>
          <div className="totals-desc-row">
            <div className="title">Shipping</div>
            <div className="desc">{totals.selectedShipping}</div>
            <div className="cost">{withCurrency(totals.shipping, currency)}</div>
          </div>
          <div className="totals-desc-row">
            <div className="title">Handling fee</div>
            <div className="spacer" />
            <div className="cost">{withCurrency(totals.handlingFee, currency)}</div>
          </div>
          <div className="totals-desc-row bold">
            <div className="title">Total</div>
            <div className="spacer" />
            <div className="cost">{withCurrency(totals.total, currency)}</div>
          </div>
          {!!totals.refunded && (
            <div className="totals-desc-row bold">
              <div className="title">Refunded</div>
              <div className="spacer" />
              <div className="cost">{withCurrency(totals.refunded, currency)}</div>
            </div>
          )}
          {manualRefunded}
        </div>
      </CardSubsection>
    );
  }, [
    products,
    totals.subtotal,
    totals.selectedShipping,
    totals.shipping,
    totals.handlingFee,
    totals.total,
    totals.refunded,
    currency,
    manualRefunded,
  ]);

  return (
    <div className="supplier-order-details">
      <div className="main-info">
        <div className="shipping">
          <AppCard sectioned>
            <div>Retailer: {retailer.name}</div>
            <div>Retailer order name: {retailerOrderName}</div>
          </AppCard>
        </div>
        <ShippingAddress
          shippingAddress={shippingAddress}
          originalShippingAddress={originalShippingAddress}
        />
      </div>
      {!!pendingCancellationProducts.length && (
        <AppCard
          title={'Pending cancellation'}
          sections={[
            { content: renderProductList(pendingCancellationProducts, 'pending cancellation') },
          ]}
          secondaryFooterActions={[
            {
              content: 'Decline cancellation',
              onAction: onDeclineCancellationRequest,
            },
          ]}
          primaryFooterAction={{
            content: 'Accept cancellation',
            onAction: onAcceptCancellationRequest,
            disabled: acceptingCancellation,
          }}
        />
      )}
      <AppCard
        title={`Status: ${
          status === 'unfulfilled' && !activeProducts.length ? 'cancelled' : status
        }`}
        sections={[{ content: renderProductList(activeProducts) }]}
      />
      {hasCancelledItems && (
        <AppCard
          title={'Cancelled items'}
          sections={[{ content: renderProductList(cancelledProducts, 'cancelled') }]}
        />
      )}
      <AppCard title={'Totals'} sections={[{ content: renderTotalsDesc() }]} />
    </div>
  );
}
