import { createAction } from 'typesafe-actions';
import { IInventoryProduct, IProductList } from '../../../../core/interfaces/IProduct';
import { IInventoryFilter } from '../../../../core/interfaces/IProductFilter';
import { Paginated, PickOptions } from '../../../../core/helpers/generic.helper';
import { IInventoryVariant } from '../../../interfaces/IProduct';
import { ACTIONS_CONSTANTS } from '../../actions-constants';

export const getInventoryProductListAction = createAction(
  ACTIONS_CONSTANTS.GET_INVENTORY_PRODUCT_LIST,
)();

export const setInventoryProductListAction = createAction(
  ACTIONS_CONSTANTS.SET_INVENTORY_PRODUCT_LIST,
  (list: IProductList<IInventoryProduct>) => list,
)();

export const setRemovedInventoryProductListAction = createAction(
  ACTIONS_CONSTANTS.SET_REMOVED_INVENTORY_PRODUCT_LIST,
  (productIds: string[]) => productIds,
)();

export const getInventoryProductVariantsAction = createAction(
  ACTIONS_CONSTANTS.GET_INVENTORY_PRODUCT_VARIANTS,
  (productId: string) => productId,
)();

export const setInventoryProductVariantsAction = createAction(
  ACTIONS_CONSTANTS.SET_INVENTORY_PRODUCT_VARIANTS,
  (productId: string, variants: IInventoryVariant[]) => ({ productId, variants }),
)();

export const getInventoryFilterOptionsAction = createAction(
  ACTIONS_CONSTANTS.GET_INVENTORY_FILTER_OPTIONS,
)();

export const setInventoryFilterOptionsAction = createAction(
  ACTIONS_CONSTANTS.SET_INVENTORY_FILTER_OPTIONS,
  (options: PickOptions<IInventoryFilter>) => options,
)();

export const deleteProductsFromInventoryAction = createAction(
  ACTIONS_CONSTANTS.DELETE_PRODUCTS_FROM_INVENTORY,
  (productIds: string[]) => productIds,
)();

export const deleteAllProductsFromInventoryAction = createAction(
  ACTIONS_CONSTANTS.DELETE_All_PRODUCTS_FROM_INVENTORY,
)();

export const disconnectProductsFromInventoryAction = createAction(
  ACTIONS_CONSTANTS.DISCONNECT_PRODUCTS_FROM_INVENTORY,
  (productIds: string[]) => productIds,
)();

export const disconnectAllProductsFromInventoryAction = createAction(
  ACTIONS_CONSTANTS.DISCONNECT_All_PRODUCTS_FROM_INVENTORY,
)();

export const exportCSVFromInventoryAction = createAction(
  ACTIONS_CONSTANTS.EXPORT_CSV,
  (productIds: string[]) => productIds,
)();

export const exportCSVAllFromInventoryAction = createAction(ACTIONS_CONSTANTS.EXPORT_CSV_ALL)();

export const setInventoryFiltersAction = createAction(
  ACTIONS_CONSTANTS.SET_INVENTORY_PRODUCT_LIST_FILTERS,
  (filters: IInventoryFilter) => filters,
)();

export const resetInventoryFiltersAction = createAction(
  ACTIONS_CONSTANTS.RESET_INVENTORY_PRODUCT_LIST_FILTERS,
)();

export const setInventoryQueryFilterAction = createAction(
  ACTIONS_CONSTANTS.SET_INVENTORY_PRODUCT_LIST_QUERY_FILTER,
  (filters: Pick<IInventoryFilter, 'query'>) => filters,
)();

export const setInventoryVendorFilterAction = createAction(
  ACTIONS_CONSTANTS.SET_INVENTORY_PRODUCT_LIST_VENDOR_FILTER,
  (filters: Pick<IInventoryFilter, 'vendors'>) => filters,
)();

export const setInventoryTypeFilterAction = createAction(
  ACTIONS_CONSTANTS.SET_INVENTORY_PRODUCT_LIST_TYPE_FILTER,
  (filters: Pick<IInventoryFilter, 'types'>) => filters,
)();

export const setInventoryStockFilterAction = createAction(
  ACTIONS_CONSTANTS.SET_INVENTORY_PRODUCT_LIST_STOCK_FILTER,
  (filters: Pick<IInventoryFilter, 'hasStock'>) => filters,
)();

export const setInventorySupplierFilterAction = createAction(
  ACTIONS_CONSTANTS.SET_INVENTORY_PRODUCT_LIST_SUPPLIER_FILTER,
  (filters: Pick<IInventoryFilter, 'fulfilledBy'>) => filters,
)();

export const setInventoryConnectionFilterAction = createAction(
  ACTIONS_CONSTANTS.SET_INVENTORY_PRODUCT_LIST_CONNECTION_FILTER,
  (filters: Pick<IInventoryFilter, 'isConnected'>) => filters,
)();

export const setInventoryPageFilterAction = createAction(
  ACTIONS_CONSTANTS.SET_INVENTORY_PRODUCT_LIST_PAGE_FILTER,
  (filters: Pick<Paginated<IInventoryFilter>, 'page'>) => filters,
)();

export const setInventoryLimitFilterAction = createAction(
  ACTIONS_CONSTANTS.SET_INVENTORY_PRODUCT_LIST_LIMIT_FILTER,
  (filters: Pick<Paginated<IInventoryFilter>, 'limit'>) => filters,
)();

export const setInventoryFilterAction = createAction(
  ACTIONS_CONSTANTS.SET_INVENTORY_PRODUCT_LIST_FILTER,
  (filters: Pick<Paginated<IInventoryFilter>, 'productInventory'>) => filters,
)();

export const setInventoryFetchingAction = createAction(
  ACTIONS_CONSTANTS.SET_INVENTORY_FETCHING,
  (fetching: boolean) => fetching,
)();
