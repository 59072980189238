import { AppCheckbox } from '../../../../checkbox/checkbox';
import { FilterElementProps } from './filter-elements.interface';

const STOCK_FILTER_KEY = 'noConnectedProducts';
const STOCK_FILTER_LABEL = 'With connections';
const STOCK_FILTER_SELECTED_LABEL = 'Not connected';
const STOCK_FILTER_TITLE = 'Hide connected products';

export const HideConnectionsFilter = ({ value, onChange }: FilterElementProps<boolean>) => ({
  key: STOCK_FILTER_KEY,
  label: value ? STOCK_FILTER_SELECTED_LABEL : STOCK_FILTER_LABEL,
  shortcut: true,
  hideClearButton: true,
  filter: <AppCheckbox label={STOCK_FILTER_TITLE} checked={value} onChange={onChange} />,
});
