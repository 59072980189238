import React from 'react';
import { AppModal } from '../../../../../core/components/overlays/modal/modal';
import { AppTextContainer } from '../../../../../core/components/text-container/text-container';
import { SelectedProduct } from '../../../layouts/import-list-layout/import-list-layout';
import { AppTextStyle } from '../../../../../core/components/text/text-style/TextStyle';

interface ILimitExceededModalProps {
  open: boolean;
  selectedProducts: SelectedProduct[];
  handleClose: () => void;
  handleAddToInventory: (products: SelectedProduct[], importMax?: boolean) => void;
}

export const LimitExceededModal = ({
  open,
  selectedProducts,
  handleClose,
  handleAddToInventory,
}: ILimitExceededModalProps) => {
  if (!open) return null;

  return (
    <AppModal
      open={open}
      onClose={handleClose}
      title="Product select is over the SKU limit"
      primaryAction={{
        content: 'Cancel import',
        onAction: () => {
          handleClose();
        },
      }}
      secondaryActions={[
        {
          content: 'Import max',
          onAction: () => {
            handleAddToInventory(
              selectedProducts.map((p) => ({ ...p, dirty: false })),
              true,
            );
            handleClose();
          },
        },
      ]}
    >
      <AppTextContainer>
        The number of products selected exceeds your plan limit.{' '}
        <AppTextStyle variation="strong">Upgrade your plan</AppTextStyle> or choose an option below.
      </AppTextContainer>
    </AppModal>
  );
};
