import { List, useIndexResourceState } from '@shopify/polaris';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import queryString from 'query-string';
import { AppCard } from '../../../../core/components/structure/card/card';
import { IOrderListFilter, IPayoutRequest } from '../../../interfaces/IOrder';
import { SelectionType } from '@shopify/polaris/build/ts/latest/src/utilities/index-provider';
import fileDownload from 'js-file-download';
import moment from 'moment';
import { ORDERS_PER_PAGE } from '../../../../core/constants/orders';
import { withCurrency } from '../../../../core/helpers/currency.helper';
import { IGetUnpaidOrdersQueryParams, ordersApi } from '../../../api/orders.api';
import {
  createNewPayoutAction,
  getRetailersForUnpaidOrdersAction,
  getUnpaidOrdersListAction,
  getUnpaidOrdersSummaryAction,
  orderListFilterAction,
  setOrdersListFetchingAction,
} from '../../../redux/modules/orders/orders.actions';
import {
  getOrdersFiltersSelector,
  getOrdersSummaryFetchingSelector,
  getOrdersTotalsDetailsSelector,
  getRetailersSelector,
  getUnpaidOrdersCountSelector,
  getUnpaidOrdersListFetchingSelector,
  getUnpaidOrdersListSelector,
  getUnpaidOrdersSummarySelector,
} from '../../../redux/modules/orders/orders.selectors';
import { getStatusSelector } from '../../../redux/modules/status/status.selectors';
import { InvoiceModal } from '../../modals/invoice/invoice-modal';
import { FilteredOrderList } from '../filtered-order-list/filtered-order-list';
import { TIME_INTERVAL } from '../../../../core/constants/product.constants';
import { Paginated } from '../../../../core/helpers/generic.helper';

type UnpaidOrderListProps = {
  changeUrlParams: (
    tabNumber: any,
    params?: Omit<Paginated<IOrderListFilter>, 'dateRange'> & {
      period?: TIME_INTERVAL;
      start?: string;
      end?: string;
    },
  ) => void;
};

export const UnpaidOrderList = ({ changeUrlParams }: UnpaidOrderListProps) => {
  const dispatch = useDispatch();
  const { search: query } = useLocation();

  const {
    paidViaCrowdship: URLPaidViaCrowdship,
    retailerId: URLRetailers,
    start: URLStartDate,
    end: URLEndDate,
    page,
  } = queryString.parse(query, { arrayFormat: 'separator', arrayFormatSeparator: '.' });

  const [selectedUnpaidOrders, setSelectedUnpaidOrders] = useState<string[]>([]);
  const [selectable, setSelectable] = useState(false);
  const [selectedRetailer, setSelectedRetailer] = useState<string[]>(
    URLRetailers
      ? ([URLRetailers] as string[])
      : URLPaidViaCrowdship === 'true'
      ? ['Crowdship']
      : [],
  );
  const [totalAmount, setTotalAmount] = useState<number>(0);

  const [allProductsSelected, setAllProductsSelected] = useState<boolean>(false);

  const [openCancellationModal, setOpenCancellationModal] = useState(false);

  const { currency } = useSelector(getStatusSelector);
  const orders = useSelector(getUnpaidOrdersListSelector);
  const ordersNumber = useSelector(getUnpaidOrdersCountSelector);
  const fetchingOrders = useSelector(getUnpaidOrdersListFetchingSelector);
  const totalDetails = useSelector(getOrdersTotalsDetailsSelector);
  const summary = useSelector(getUnpaidOrdersSummarySelector);
  const summaryFetching = useSelector(getOrdersSummaryFetchingSelector);
  const retailers = useSelector(getRetailersSelector);
  const filters = useSelector(getOrdersFiltersSelector);

  const mappedOrder = orders.map((o) => ({ ...o, _id: o.id, orderId: o._id, refundId: o.id }));

  const { selectedResources, allResourcesSelected, handleSelectionChange } =
    useIndexResourceState(mappedOrder);

  useEffect(() => {
    dispatch(
      orderListFilterAction({
        ...(URLRetailers && { retailerId: URLRetailers as string }),
        ...(URLPaidViaCrowdship !== undefined && {
          paidViaCrowdship: URLPaidViaCrowdship !== 'false',
        }),
        ...(URLStartDate &&
          URLEndDate && {
            dateRange: {
              start: new Date(URLStartDate as string).getTime(),
              end: moment(URLEndDate as string)
                .add(1, 'd')
                .valueOf(),
            },
          }),
      }),
    );
  }, [dispatch, URLRetailers, URLPaidViaCrowdship, URLStartDate, URLEndDate]);

  const deselectAll = useCallback(() => {
    handleSelectionChange('page' as SelectionType, false);
  }, [handleSelectionChange]);

  const getOrders = useCallback(
    (page: number) => {
      setSelectedUnpaidOrders([]);
      setTotalAmount(0);
      dispatch(getUnpaidOrdersListAction(page));
    },
    [dispatch],
  );

  const handleFilterChange = useCallback(
    (
      retailerId?: string,
      paidViaCrowdship?: boolean,
      dateRange?: {
        start: number;
        end: number;
      },
    ) => {
      dispatch(orderListFilterAction({ retailerId, paidViaCrowdship, dateRange }));
    },
    [dispatch],
  );

  useEffect(() => {
    getOrders(+(page as string) || 0);
  }, [page, getOrders]);

  useEffect(() => {
    dispatch(getRetailersForUnpaidOrdersAction());
  }, [dispatch]);

  useEffect(() => {
    dispatch(getUnpaidOrdersSummaryAction());
  }, [dispatch]);

  const selectionChanged = (
    selectionType: SelectionType,
    isSelecting: boolean,
    selection?: any,
  ) => {
    // when selecting a single item, ignore if limit is reached (disableAdding) or if the item is processing
    if (selectionType === 'all') setAllProductsSelected(true);
    // if all items are selected, any other selection operation will toggle this flag
    else if (allResourcesSelected) setAllProductsSelected(false);
    handleSelectionChange(selectionType, isSelecting, selection);
  };

  const totalOrdersPageCount = useMemo(
    () => Math.ceil(ordersNumber / ORDERS_PER_PAGE),
    [ordersNumber],
  );

  const handleCreateInvoice = useCallback(
    (request: IPayoutRequest) => {
      dispatch(
        createNewPayoutAction({
          ...request,
          retailerId: selectedRetailer[0],
          ids: allProductsSelected ? totalDetails.ids : selectedUnpaidOrders,
        }),
      );

      setOpenCancellationModal(false);
      setSelectedUnpaidOrders([]);
      setTotalAmount(0);
      setAllProductsSelected(false);
      deselectAll();
    },
    [
      allProductsSelected,
      deselectAll,
      selectedRetailer,
      selectedUnpaidOrders,
      totalDetails.ids,
      dispatch,
    ],
  );

  const handleExportCSV = useCallback(
    (request: { ids: string[] }) => {
      setOrdersListFetchingAction(true);
      ordersApi
        .exportUnpaidOrdersCSV(request)
        .then(({ data }) => {
          const now = moment().format('MMDDYYYY');
          fileDownload(data, `Unpaid-orders-${now}.csv`);
          setSelectedUnpaidOrders([]);
          setAllProductsSelected(false);
          deselectAll();
        })
        .catch(console.error)
        .finally(() => {
          dispatch(setOrdersListFetchingAction(false));
        });
    },
    [deselectAll, dispatch],
  );

  const handleExportCSVAll = useCallback(
    (request: Omit<IGetUnpaidOrdersQueryParams, 'limit' | 'page'>) => {
      dispatch(setOrdersListFetchingAction(true));
      ordersApi
        .exportAllUnpaidOrders(request)
        .then(({ data }) => {
          const now = moment().format('MMDDYYYY');
          fileDownload(data, `Unpaid-orders-${now}.csv`);
          setSelectedUnpaidOrders([]);
          setAllProductsSelected(false);
          deselectAll();
        })
        .catch(console.error)
        .finally(() => {
          dispatch(setOrdersListFetchingAction(false));
        });
    },
    [deselectAll, dispatch],
  );

  return (
    <>
      <AppCard
        title="Summary"
        sections={[
          {
            content: summaryFetching ? (
              <div>fetching</div>
            ) : (
              <div>
                <List type="bullet">
                  <List.Item>{`Total: ${summary.totalOrders}`}</List.Item>

                  <List.Item>{`Total not paid out: $${summary.totalNotPaidOut}`}</List.Item>
                </List>
              </div>
            ),
          },
        ]}
      />
      <AppCard
        title="Unpaid Orders"
        sections={[
          {
            content: (
              <FilteredOrderList
                orders={mappedOrder || []}
                fetching={fetchingOrders}
                totalPageCount={totalOrdersPageCount}
                onPageChange={getOrders}
                currency={currency}
                setSelectable={setSelectable}
                onSelectionChange={setSelectedUnpaidOrders}
                setFilterParams={handleFilterChange}
                retailers={retailers}
                setTotalAmount={setTotalAmount}
                setSelectedRetailer={setSelectedRetailer}
                selectedRetailer={selectedRetailer}
                selectedUnpaidOrders={selectedUnpaidOrders}
                selectedResources={selectedResources}
                handleSelectionChange={selectionChanged}
                allResourcesSelected={allResourcesSelected}
                filters={filters}
                changeUrlParams={changeUrlParams}
                listActions={[
                  ...(selectable
                    ? [
                        {
                          content: `Create invoice for ${
                            allProductsSelected
                              ? withCurrency(totalDetails.total, currency)
                              : withCurrency(totalAmount, currency)
                          }`,
                          onAction: () => {
                            setOpenCancellationModal(true);
                          },
                        },
                      ]
                    : []),
                  {
                    content: 'Export to CSV',
                    onAction: () => {
                      if (allProductsSelected) {
                        handleExportCSVAll(filters);
                      } else {
                        handleExportCSV({
                          ids: selectedUnpaidOrders,
                        });
                      }
                    },
                  },
                ]}
              />
            ),
          },
        ]}
      />
      <InvoiceModal
        title="Create invoice"
        open={openCancellationModal}
        totalAmount={allProductsSelected ? totalDetails.total : totalAmount}
        onClose={() => setOpenCancellationModal(false)}
        invoiceHandler={handleCreateInvoice}
        currency={currency}
      />
    </>
  );
};
