import { AxiosResponse } from 'axios';
import { all, call, put, select, takeEvery, takeLatest } from 'redux-saga/effects';
import { ActionType } from 'typesafe-actions';
import { IMPORT_LIST_PRODUCTS_PER_PAGE } from '../../../../core/constants/product.constants';
import { interactiveToastDelay } from '../../../../core/constants/toast.constants';
import { IImportListProductGroupsWithCount, importListApi } from '../../../api/import-list.api';
import { IPostProductsResponse } from '../../../api/inventory.api';
import { updateProductsLimitStatusAction } from '../status/status.actions';
import {
  addAllImportListProductToInventoryAction,
  addImportListProductToInventoryAction,
  deleteProductFromImportListAction,
  getImportListProductsAction,
  hideInventoryProductsAddedToastAction,
  hideProductRemovedToastAction,
  setImportListProductsAction,
  showImportListFetchingErrorToastAction,
  showInventoryProductsAddedToastAction,
  showLimitExceededModalAction,
  showProductRemovedToastAction,
} from './import-list.actions';
import {
  getImportListProductsPageSelector,
  getTotalImportListProductsCountSelector,
} from './import-list.selectors';

function* getImportListProductsSaga({ payload }: ActionType<typeof getImportListProductsAction>) {
  try {
    const { data }: AxiosResponse<IImportListProductGroupsWithCount> =
      yield importListApi.getImportListProducts({
        limit: IMPORT_LIST_PRODUCTS_PER_PAGE,
        page: payload,
      });

    yield put(setImportListProductsAction({ ...data, page: payload }));
  } catch (e) {
    console.error(e);
    yield put(setImportListProductsAction({ productGroups: [], page: 0, total: 0 }));
    yield put(showImportListFetchingErrorToastAction());
  }
}

function* postProductsFromImportListToInventorySaga({
  payload,
}: ActionType<typeof addImportListProductToInventoryAction>) {
  try {
    const { data }: AxiosResponse<IPostProductsResponse> =
      yield importListApi.addImportListProductsToInventory(payload.productIds, payload.importMax);
    if ('success' in data && data.success) {
      const total: number = yield select(getTotalImportListProductsCountSelector);
      const page: number = yield select(getImportListProductsPageSelector);

      const isLastPage = Math.ceil(total / IMPORT_LIST_PRODUCTS_PER_PAGE) - 1 === page && page > 0;

      const requestedPage =
        Math.ceil((total - payload.productIds.length) / IMPORT_LIST_PRODUCTS_PER_PAGE) - 1 !==
          page && isLastPage
          ? page - 1
          : page;

      yield put(
        updateProductsLimitStatusAction({ productsCount: data.added, productsLimit: data.limit }),
      );
      yield put(showInventoryProductsAddedToastAction());
      yield put(getImportListProductsAction(requestedPage));
      yield call(interactiveToastDelay);
      yield put(hideInventoryProductsAddedToastAction());
    } else if ('limitExceeded' in data && data.limitExceeded) {
      yield put(showLimitExceededModalAction());
      yield put(
        updateProductsLimitStatusAction({ productsCount: data.added, productsLimit: data.limit }),
      );
    }
  } catch (e) {
    console.error(e);
  }
}

function* postAllProductsFromImportListToInventorySaga({
  payload,
}: ActionType<typeof addAllImportListProductToInventoryAction>) {
  try {
    const { data }: AxiosResponse<IPostProductsResponse> =
      yield importListApi.addAllImportListProductsToInventory(payload);
    if ('success' in data && data.success) {
      yield put(
        updateProductsLimitStatusAction({ productsCount: data.added, productsLimit: data.limit }),
      );
      yield put(showInventoryProductsAddedToastAction());
      yield put(getImportListProductsAction(0));
      yield call(interactiveToastDelay);
      yield put(hideInventoryProductsAddedToastAction());
    } else if ('limitExceeded' in data && data.limitExceeded) {
      yield put(showLimitExceededModalAction());
      yield put(
        updateProductsLimitStatusAction({ productsCount: data.added, productsLimit: data.limit }),
      );
    }
  } catch (e) {
    console.error(e);
  }
}

function* deleteProductFromImportListSaga({
  payload,
}: ActionType<typeof deleteProductFromImportListAction>) {
  try {
    const { data }: AxiosResponse<{ success: true } | { notFound: true }> =
      yield importListApi.removeProduct(payload);

    const total: number = yield select(getTotalImportListProductsCountSelector);
    const page: number = yield select(getImportListProductsPageSelector);

    const isLastPage = Math.ceil(total / IMPORT_LIST_PRODUCTS_PER_PAGE) - 1 === page && page > 0;

    const requestedPage =
      Math.ceil((total - 1) / IMPORT_LIST_PRODUCTS_PER_PAGE) - 1 !== page && isLastPage
        ? page - 1
        : page;

    if ('success' in data && data.success) {
      yield put(showProductRemovedToastAction());
      yield put(getImportListProductsAction(requestedPage));

      yield call(interactiveToastDelay);
      yield put(hideProductRemovedToastAction());
    } else {
      yield put(getImportListProductsAction(requestedPage));
    }
  } catch (e) {
    console.error(e);
  }
}

export function* importListSaga() {
  yield all([
    takeEvery(getImportListProductsAction, getImportListProductsSaga),
    takeLatest(addImportListProductToInventoryAction, postProductsFromImportListToInventorySaga),
    takeLatest(
      addAllImportListProductToInventoryAction,
      postAllProductsFromImportListToInventorySaga,
    ),
    takeLatest(deleteProductFromImportListAction, deleteProductFromImportListSaga),
  ]);
}
