import { Form, Formik } from 'formik';
import { useCallback, useMemo } from 'react';

import { AppTextField } from '../../../../core/components/forms/text-field/text-field';
import { AppModal } from '../../../../core/components/overlays/modal/modal';
import { AppCard } from '../../../../core/components/structure/card/card';

import { withCurrency } from '../../../../core/helpers/currency.helper';
import { ICurrency } from '../../../../core/interfaces/ICurrency';
import { IPayoutDetails, IPayoutRequest } from '../../../interfaces/IOrder';

import { List } from '@shopify/polaris';
import { AppControlledCheckbox } from '../../../../core/components/forms/controlled-checkbox/controlled-checkbox';
import { AppControlledDatePicker } from '../../../../core/components/forms/controlled-date-picker/controlled-date-picker';
import { AppControlledTextField } from '../../../../core/components/forms/controlled-text-field/controlled-text-field';
import './invoice-modal.scss';

interface UpdateInvoiceModalProps {
  title: string;
  open: boolean;
  onClose: () => void;
  payout?: IPayoutDetails;
  totalAmount?: number;
  invoiceHandler: (request: IPayoutRequest) => void;
  currency: ICurrency;
}

export function InvoiceModal({
  title,
  open,
  totalAmount,
  onClose,
  payout,
  invoiceHandler,
  currency,
}: UpdateInvoiceModalProps) {
  const toTimestamp = useCallback((date: Date) => Math.floor(date.getTime()), []);

  const handleSubmit = useCallback(
    ({ adjustmentAmount, paidAt, paid, ...rest }: any) => {
      invoiceHandler({
        ...rest,
        adjustmentAmount: +adjustmentAmount,
        paid,
        ...(paid && paidAt && { paidAt: toTimestamp(new Date(paidAt)) }),
      });
    },
    [invoiceHandler, toTimestamp],
  );

  const initialValues = useMemo(
    () => ({
      amount: payout?.amount || totalAmount,
      adjustmentAmount: payout?.adjustmentAmount?.toString() || '',
      adjustmentReason: payout?.adjustmentReason || '',
      confirmationId: payout?.confirmationId || '',
      paid: payout ? payout.paid : false,
      ...(payout &&
        payout.paidAt && {
          paidAt: { start: new Date(payout.paidAt), end: new Date(payout.paidAt) },
        }),
    }),
    [payout, totalAmount],
  );

  return (
    <AppModal open={open} onClose={onClose} title={title}>
      <Formik initialValues={initialValues} onSubmit={handleSubmit}>
        {({ submitForm, values }) => (
          <Form name={'updateInvoice'}>
            <List type="number">
              <List.Item>To adjust the invoice total, enter a manual adjustment below.</List.Item>
              <List.Item>
                Only mark the order as paid if you've already received payment from the Retailer.
              </List.Item>
            </List>
            <AppCard
              sections={[
                {
                  content: (
                    <>
                      <AppTextField
                        label="Amount"
                        value={`${withCurrency(payout ? payout.amount : totalAmount!, currency)}`}
                        id="amount"
                        autoComplete="off"
                        readOnly
                      />
                      <AppControlledTextField
                        label="Adjustment amount"
                        type="number"
                        name="adjustmentAmount"
                        autoComplete="off"
                        inputMode="decimal"
                      />
                      <AppControlledTextField
                        label="Adjustment reason"
                        name="adjustmentReason"
                        autoComplete="off"
                      />
                      <AppControlledTextField
                        label="Confirmation id"
                        name="confirmationId"
                        autoComplete="off"
                      />
                      <AppControlledCheckbox label="Mark as paid" name="paid" />
                      {values.paid && (
                        <div className="date-wrapper">
                          <AppControlledDatePicker name="paidAt" />
                        </div>
                      )}
                    </>
                  ),
                },
              ]}
              primaryFooterAction={{
                content: title,
                onAction: submitForm,
              }}
            />
          </Form>
        )}
      </Formik>
    </AppModal>
  );
}
