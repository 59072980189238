import { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  resetMarketplaceVariantListFiltersAction,
  setMarketplaceVariantListConnectionFilterAction,
  setMarketplaceVariantListQueryFilterAction,
  setMarketplaceVariantListSupplierFilterAction,
  setMarketplaceVariantListTypeFilterAction,
  setMarketplaceVariantListVendorFilterAction,
  setMarketplaceVariantListInventoryFilterAction,
} from '../../../../../shopify-retailer/redux/modules/marketplace/marketplace.actions';
import {
  getMarketplaceFilterOptionsSelector,
  getMarketplaceVariantViewFiltersSelector,
} from '../../../../../shopify-retailer/redux/modules/marketplace/marketplace.selectors';
import { IAppliedFilter } from '../../../filters/filters';
import { SKULimit } from '../sku-limit';
import { HideConnectionsFilter } from './filter-elements/hide-connections-filter';
import { QueryFilterContainer } from './filter-elements/query-filter-container';
import { SupplierFilter, SUPPLIER_FILTER_KEY } from './filter-elements/supplier-filter';
import { TypeFilter, TYPE_FILTER_KEY } from './filter-elements/type-filter';
import { VendorFilter, VENDOR_FILTER_KEY } from './filter-elements/vendor-filter';
import { INVENTORY_FILTER_KEY, InventoryFilter } from './filter-elements/inventory-filter';
import { PRODUCT_INVENTORY } from '../../../../constants/product.constants';

export const MarketplaceBuildFilter = () => {
  const dispatch = useDispatch();
  const filters = useSelector(getMarketplaceVariantViewFiltersSelector);
  const options = useSelector(getMarketplaceFilterOptionsSelector);
  const { types, vendors, fulfilledBy } = useSelector(getMarketplaceFilterOptionsSelector);

  useEffect(() => {
    return () => {
      dispatch(resetMarketplaceVariantListFiltersAction());
    };
  }, [dispatch]);

  const appliedFilters: IAppliedFilter[] = useMemo(() => {
    const appliedVendors = {
      key: VENDOR_FILTER_KEY,
      label: `Brands: ${filters.vendors.join(', ')}`,
      onRemove: () => dispatch(setMarketplaceVariantListVendorFilterAction({ vendors: [] })),
    };
    const appliedSuppliers = {
      key: SUPPLIER_FILTER_KEY,
      label: `Suppliers: ${filters.fulfilledBy
        .map((f) => options?.fulfilledBy.find((o) => o.value === f)?.label)
        .join(', ')}`,
      onRemove: () => dispatch(setMarketplaceVariantListSupplierFilterAction({ fulfilledBy: [] })),
    };
    const appliedTypes = {
      key: TYPE_FILTER_KEY,
      label: `Types: ${filters.types.join(', ')}`,
      onRemove: () => dispatch(setMarketplaceVariantListTypeFilterAction({ types: [] })),
    };

    const appliedInventory = {
      key: INVENTORY_FILTER_KEY,
      label: `Inventory: ${filters.productInventory}`,
      onRemove: () => {
        dispatch(
          setMarketplaceVariantListInventoryFilterAction({
            productInventory: PRODUCT_INVENTORY.SHOW_ALL,
          }),
        );
      },
    };

    const chosenVendors = filters.vendors.length ? [appliedVendors] : [];
    const chosenSuppliers = filters.fulfilledBy.length ? [appliedSuppliers] : [];
    const chosenTypes = filters.types.length ? [appliedTypes] : [];
    const chosenInventory = filters.productInventory.includes(PRODUCT_INVENTORY.SHOW_ALL)
      ? []
      : [appliedInventory];

    return [...chosenVendors, ...chosenSuppliers, ...chosenTypes, ...chosenInventory];
  }, [
    filters.vendors,
    filters.fulfilledBy,
    filters.types,
    filters.productInventory,
    dispatch,
    options?.fulfilledBy,
  ]);

  return (
    <>
      <SKULimit />
      <QueryFilterContainer
        value={filters.query}
        onChange={(query) => dispatch(setMarketplaceVariantListQueryFilterAction({ query }))}
        filters={[
          VendorFilter({
            value: filters.vendors,
            onChange: (vendors) =>
              dispatch(setMarketplaceVariantListVendorFilterAction({ vendors })),
            options: vendors,
          }),
          SupplierFilter({
            value: filters.fulfilledBy,
            onChange: (fulfilledBy) =>
              dispatch(setMarketplaceVariantListSupplierFilterAction({ fulfilledBy })),
            options: fulfilledBy,
          }),
          TypeFilter({
            value: filters.types,
            onChange: (types) => dispatch(setMarketplaceVariantListTypeFilterAction({ types })),
            options: types,
          }),

          HideConnectionsFilter({
            value: filters.noConnectedProducts,
            onChange: (noConnectedProducts) =>
              dispatch(setMarketplaceVariantListConnectionFilterAction({ noConnectedProducts })),
          }),
          InventoryFilter({
            value: filters.productInventory,
            onChange: (inventory) =>
              dispatch(
                setMarketplaceVariantListInventoryFilterAction({
                  productInventory: inventory as PRODUCT_INVENTORY,
                }),
              ),
          }),
        ]}
        appliedFilters={appliedFilters}
        onClearAll={() => dispatch(resetMarketplaceVariantListFiltersAction())}
      />
    </>
  );
};
