import { createSelector } from 'reselect';
import { RootState } from '../../reducers';

export const getPreferencesSelector = createSelector(
  [(state: RootState) => state.preferences],
  (preferencesReducer) => preferencesReducer.preferences,
);

export const getPreferencesFetchingSelector = createSelector(
  [(state: RootState) => state.preferences],
  (preferencesReducer) => preferencesReducer.fetching,
);

export const addressValidationToastsSelector = createSelector(
  [(state: RootState) => state.preferences],
  (preferencesReducer) => ({
    failedValidation: preferencesReducer.showAddressValidationFailedToast,
    successValidation: preferencesReducer.showAddressValidationSuccessToast,
  }),
);
