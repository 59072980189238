import { Form, Formik } from 'formik';
import { AppSwitch } from '../../../../../../../core/components/forms/controlled-switch/controlled-switch';
import { AppButton } from '../../../../../../../core/components/button/Button';
import { AppControlledCheckbox } from '../../../../../../../core/components/forms/controlled-checkbox/controlled-checkbox';

import './seller-application.scss';
import { AppLink } from '../../../../../../../core/components/link/link';
import { AppTextContainer } from '../../../../../../../core/components/text-container/text-container';

const descriptions = [
  'I have permission from the trademark holder(s) to sell the products I am offering in my catalog.',
  'I own the physical inventory offered in my catalog, either in my own warehouse or in my 3PL warehouse. I do not dropship through a supplier.',
  'I can ship orders within 48 hours of receiving them.',
  'I can guarantee my will orders arrive to the customers free of defects and  imperfections, and will offer an exchange/return policy for damaged orders.',
  'I can offer returns and exchanges within 30 days of purchase for unused merchandise in its original packaging or condition.',
  'I have not been placed on any MATCH/TMF list, terrorist watchlist or AML warning list.',
];

const initialValues = {
  trademarkPermission: false,
  physicalInventory: false,
  canShipOrder: false,
  canGuarantee: false,
  canOffer: false,
  clearProducts: false,
  readTermAndCondition: false,
};

type SellerApplicationProps = {
  handleRequestMarketplaceApproval: () => void;
};

export const SellerApplication = ({ handleRequestMarketplaceApproval }: SellerApplicationProps) => {
  const formikNames = Object.keys(initialValues);

  return (
    <Formik initialValues={initialValues} onSubmit={handleRequestMarketplaceApproval}>
      {({ submitForm, dirty, values }) => (
        <>
          <AppTextContainer>
            <p>
              To publish your products to Crowdship Marketplace, please complete the seller
              application
            </p>
          </AppTextContainer>
          <Form name={'sellerApplication'}>
            {descriptions.map((desc, index) => (
              <div key={index} className="seller-condition-wrapper">
                <p className="description">
                  <span className="number">{index + 1}.&nbsp;</span>
                  {desc}
                </p>
                <AppSwitch name={formikNames[index]} value={values[formikNames[index]]} />
              </div>
            ))}
            <div className="read-terms-and-conditions">
              <AppControlledCheckbox name={'readTermAndCondition'} />
              <p>
                I have read and agree to Crowdship’s Supplier{' '}
                <AppLink
                  onClick={() => window.open('https://crowdship.io/terms-conditions/', '_blank')}
                  external
                >
                  Terms and Conditions
                </AppLink>{' '}
              </p>
            </div>
            <AppButton
              handleClick={submitForm}
              disabled={!Object.values(values).every((v) => v) || !dirty}
            >
              Submit
            </AppButton>
          </Form>
        </>
      )}
    </Formik>
  );
};
