import React, { useCallback, useEffect } from 'react';
import { Field, useFormikContext } from 'formik';
import { ChoiceList, ChoiceListProps } from '@shopify/polaris';

interface IControlledChoiceList extends Omit<ChoiceListProps, 'selected' | 'onChange'> {
  name: string;
}

export function AppControlledChoiceList({ name, ...props }: IControlledChoiceList) {
  const { registerField, unregisterField, setFieldValue, getFieldProps } = useFormikContext();

  useEffect(() => {
    registerField(name, {});
    return () => unregisterField(name);
  }, [name, registerField, unregisterField]);

  const field = getFieldProps({ name });

  const handleChange = useCallback(
    (selected: string[], name: string) =>
      setFieldValue(name, props.allowMultiple ? selected : selected[0], false),
    [props.allowMultiple, setFieldValue],
  );

  return (
    <Field name={name}>
      {() => <ChoiceList {...props} name={name} selected={field.value} onChange={handleChange} />}
    </Field>
  );
}
