import fileDownload from 'js-file-download';
import { useCallback, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { AppCheckbox } from '../../../../core/components/checkbox/checkbox';
import { AppToast } from '../../../../core/components/feedback-indicators/toast/toast';
import { AppThumbnail } from '../../../../core/components/image-containers/thumbnail/Thumbnail';
import { AppIndexTable } from '../../../../core/components/index-table/index-table';
import { AppIndexTableCell } from '../../../../core/components/index-table/index-table-cell';
import { AppIndexTableRow } from '../../../../core/components/index-table/index-table-row';
import { AppLink } from '../../../../core/components/link/link';
import { AppTooltip } from '../../../../core/components/overlays/tooltip/tooltip';
import { AppCard } from '../../../../core/components/structure/card/card';
import { AppTextStyle } from '../../../../core/components/text/text-style/TextStyle';
import { withCurrency } from '../../../../core/helpers/currency.helper';
import { printPdf } from '../../../../core/helpers/print-js-safari.helper';
import {
  convertWeightFromGrams,
  convertWeightFromGramsToPoundsAndOunces,
} from '../../../../core/helpers/weight.helper';
import { ordersApi } from '../../../api/orders.api';
import { ILocation } from '../../../interfaces/ILocation';
import { IOrderDetailsProduct, IOrderProductLabel } from '../../../interfaces/IOrder';
import { getOrderDetailsAction } from '../../../redux/modules/orders/orders.actions';
import { LocationTooltip } from './location-tooltip';

export type LabeledProductGroup = {
  location: Omit<ILocation, 'countryName' | 'active' | 'legacy' | 'phone'>;
  products: (Omit<IOrderDetailsProduct, 'labels'> & { labelQuantity: number })[];
  label: Pick<
    IOrderProductLabel,
    | 'labelId'
    | 'labelUrl'
    | 'price'
    | 'trackingNumber'
    | 'trackingUrl'
    | 'trackingCompany'
    | 'dimensions'
    | 'totalWeight'
    | 'applicableForVoiding'
  >;
};

type LabelCandidateProps = {
  orderId: string;
  group: LabeledProductGroup;
};

export const LabeledGroup = ({ orderId, group }: LabelCandidateProps) => {
  const dispatch = useDispatch();
  const [showErrorToast, setShowErrorToast] = useState(false);
  const [downloadingPDF, setDownloadingPDF] = useState(false);
  const [voidingLabel, setVoidingLabel] = useState(false);
  const [includePackingSlip, setIncludePackingSlip] = useState(false);
  const handleChange = useCallback((newChecked) => setIncludePackingSlip(newChecked), []);

  const voidLabel = useCallback(
    (labelId: string) => {
      setVoidingLabel(true);
      ordersApi
        .voidLabel(orderId, labelId)
        .catch((error) => {
          console.error(error);
          setShowErrorToast(true);
        })
        .finally(() => {
          setVoidingLabel(false);
          dispatch(getOrderDetailsAction(orderId));
        });
    },
    [orderId, dispatch],
  );

  const printLabelPDF = useCallback((url: string, orderId: string, withPS: boolean) => {
    setDownloadingPDF(true);
    ordersApi
      .getLabel(url, orderId, withPS)
      .then(({ data }) => {
        if (withPS) fileDownload(data, `Shipping-label-${orderId}.pdf`);
        else {
          printPdf(data);
        }
      })
      .catch((error) => {
        console.error(error);
        setShowErrorToast(true);
      })
      .finally(() => {
        setDownloadingPDF(false);
      });
  }, []);

  const renderWeight = useMemo(() => {
    const { lbs, oz } = convertWeightFromGramsToPoundsAndOunces(group.label.totalWeight);
    return `${lbs} lbs ${oz} oz`;
  }, [group.label.totalWeight]);

  const renderSection = useMemo(
    () => (
      <div>
        <div>
          <AppCheckbox
            label="Print packing slip with label"
            checked={includePackingSlip}
            onChange={handleChange}
          />
        </div>
        <div>
          Weight:&nbsp;
          {renderWeight}
        </div>
        <div>
          Dimensions:&nbsp;
          {group.label.dimensions.length +
            'x' +
            group.label.dimensions.width +
            'x' +
            group.label.dimensions.height}{' '}
          in
        </div>
        <div>
          Price:&nbsp;
          {group.label.price ? (
            withCurrency(group.label.price)
          ) : (
            <AppTextStyle variation="subdued">N/A</AppTextStyle>
          )}
        </div>
        <div>
          {group.label.trackingNumber ? (
            <div>
              Tracking number:&nbsp;
              <AppTooltip content={group.label.trackingNumber}>
                <AppLink onClick={() => window.open(group.label.trackingUrl, '_blank')}>
                  {group.label.trackingNumber}
                </AppLink>
              </AppTooltip>
            </div>
          ) : group.label.trackingCompany ? (
            <div>
              Tracking company:&nbsp;
              <AppTooltip content={group.label.trackingCompany}>
                {group.label.trackingCompany}
              </AppTooltip>
            </div>
          ) : (
            <AppTextStyle variation="subdued">N/A</AppTextStyle>
          )}
        </div>

        <AppIndexTable
          resourceName={{ singular: 'item', plural: 'items' }}
          headings={[
            { title: 'Image', hidden: true },
            { title: 'Title' },
            { title: 'Weight per item (lbs)' },
            { title: 'Quantity' },
          ]}
          itemCount={group.products.length}
          onSelectionChange={() => {}}
          selectedItemsCount={0}
          hasMoreItems={false}
          loading={false}
          selectable={false}
        >
          {group.products
            .filter((p) => p.labelQuantity)
            .map((p, i) => (
              <AppIndexTableRow id={p.variantId} key={p.variantId} selected={false} position={i}>
                <AppIndexTableCell>
                  <AppThumbnail alt={p.title} source={p.image} />
                </AppIndexTableCell>
                <AppIndexTableCell>
                  <div className="item">
                    <div className="title">{p.title}</div>

                    {p.variantTitle?.toLowerCase() !== 'default title' && (
                      <div className="variant">{p.variantTitle}</div>
                    )}

                    <div className="sku">SKU: {p.sku}</div>
                  </div>
                </AppIndexTableCell>
                <AppIndexTableCell>
                  <div>{convertWeightFromGrams(p.grams)}</div>
                </AppIndexTableCell>
                <AppIndexTableCell>
                  <div>{p.labelQuantity}</div>
                </AppIndexTableCell>
              </AppIndexTableRow>
            ))}
        </AppIndexTable>
      </div>
    ),
    [
      group.label.dimensions.height,
      group.label.dimensions.length,
      group.label.dimensions.width,
      group.label.price,
      group.label.trackingNumber,
      group.label.trackingUrl,
      group.label.trackingCompany,
      group.products,
      handleChange,
      includePackingSlip,
      renderWeight,
    ],
  );

  return (
    <>
      <div className="labeled-group">
        <AppCard
          title={<LocationTooltip location={group.location} />}
          actions={[
            {
              content: 'Print label',
              onAction: () => printLabelPDF(group.label.labelUrl, orderId, includePackingSlip),
              disabled: downloadingPDF,
            },
            {
              content: 'Void label',
              onAction: () => voidLabel(group.label.labelId),
              disabled: voidingLabel || !group.label.applicableForVoiding,
            },
          ]}
          sections={[{ content: renderSection }]}
        />
      </div>
      {downloadingPDF && (
        <AppToast content="Downloading PDF..." onDismiss={() => setDownloadingPDF(false)} />
      )}
      {voidingLabel && (
        <AppToast content="Voiding label..." onDismiss={() => setVoidingLabel(false)} />
      )}
      {showErrorToast && (
        <AppToast error content="Unexpected error" onDismiss={() => setShowErrorToast(false)} />
      )}
    </>
  );
};
