import { AppControlledSelect } from '../../../../../core/components/forms/controlled-select/controlled-select';
import { AppControlledTextField } from '../../../../../core/components/forms/controlled-text-field/controlled-text-field';
import { validation } from '../../../../../core/helpers/validations.helper';
import { ICurrency } from '../../../../../core/interfaces/ICurrency';
import {
  DROP_SHIP_COST_MAPPING,
  adjustmentTypeOptions,
} from '../../../../constants/preferences.constants';
import { ADJUSTMENT_TYPE } from '../../../../interfaces/IPreferences';

interface IAdjustFieldProps {
  mapping: DROP_SHIP_COST_MAPPING;
  type?: ADJUSTMENT_TYPE;
  amount: string;
  currency: ICurrency;
}

const formatMapping = (mapping) => mapping[0].toUpperCase() + mapping.split('_').join(' ').slice(1);

export const AdjustField = ({ mapping, type, amount, currency }: IAdjustFieldProps) => (
  <AppControlledTextField
    label={`Adjust Shopify ${formatMapping(mapping)} by (+/-)`}
    helpText={`The selection above will be adjusted by this multiplier. Ex. If Shopify ${formatMapping(
      mapping,
    )} = $10.00, Retailers will pay $${type !== 'fixed' ? 10 + 0.1 * +amount : 10 + +amount}`}
    name="product.dropShipCost.adjustment.amount"
    type="number"
    placeholder="0"
    min={0}
    validate={validation.isOptionalNumber}
    connectedRight={
      <AppControlledSelect
        name="product.dropShipCost.adjustment.type"
        options={adjustmentTypeOptions(currency)}
        label="Adjustment type"
        labelHidden
      />
    }
  />
);
