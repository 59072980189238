import React from 'react';
import { AppSkeletonPage } from '../../../../../core/components/feedback-indicators/skeleton/skeleton-page/skeleton-page';
import { AppSkeletonThumbnail } from '../../../../../core/components/feedback-indicators/skeleton/skeleton-thumbnail/skeleton-thumbnail';
import { AppSkeletonBodyText } from '../../../../../core/components/feedback-indicators/skeleton/skeleton-body-text/skeleton-body-text';
import { AppCard } from '../../../../../core/components/structure/card/card';
import { AppSkeletonDisplayText } from '../../../../../core/components/feedback-indicators/skeleton/skeleton-display-text/skeleton-display-text';
import { AppTabs } from '../../../../../core/components/tabs/tabs';

import './import-list-skeleton.scss';

export function ImportListSkeleton(props: {}) {
  return (
    <AppSkeletonPage title="Import list">
      {Array.from({ length: 2 }, (_, i) => (
        <AppCard key={i}>
          <AppTabs
            tabs={Array.from({ length: 4 }, (_, i) => ({
              id: `display-tab-${i + 1}`,
              panelID: `display-panel-${i + 1}`,
              content: (
                <div style={{ width: '7rem' }}>
                  <AppSkeletonDisplayText />
                </div>
              ),
            }))}
            selected={0}
            onSelect={() => {}}
          >
            <div className="import-list-skeleton">
              <AppSkeletonThumbnail />
              <div className="import-list-skeleton__text">
                <AppSkeletonBodyText />
                <AppSkeletonBodyText />
                <AppSkeletonBodyText />
              </div>
            </div>
          </AppTabs>
        </AppCard>
      ))}
    </AppSkeletonPage>
  );
}
