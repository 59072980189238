import { createAction } from 'typesafe-actions';
import { IProductChangesToCompare } from '../../../api/preferences.api';
import { IPreferences, IProductPreferences } from '../../../interfaces/IPreferences';
import { ACTIONS_CONSTANTS } from '../../actions-constants';

export const getPreferencesAction = createAction(ACTIONS_CONSTANTS.GET_SETTINGS)();

export const getProductPreferencesAction = createAction(ACTIONS_CONSTANTS.GET_PRODUCT_SETTINGS)();

export const setPreferencesAction = createAction(
  ACTIONS_CONSTANTS.SET_SETTINGS,
  (preferences: IPreferences) => preferences,
)();

export const setPreferencesFromApiAction = createAction(
  ACTIONS_CONSTANTS.SET_SETTINGS_FROM_API,
  (info: IPreferences) => info,
)();

export const setProductPreferencesFromApiAction = createAction(
  ACTIONS_CONSTANTS.SET_PRODUCT_SETTINGS_FROM_API,
  (preferences: IProductPreferences) => preferences,
)();

export const getCarrierServicesAction = createAction(ACTIONS_CONSTANTS.GET_CARRIER_SERVICES)();

export const setCarrierServicesAction = createAction(
  ACTIONS_CONSTANTS.SET_CARRIER_SERVICES,
  (services: string[]) => services,
)();

export const calcPriceChangesAction = createAction(
  ACTIONS_CONSTANTS.CALC_PRICE_CHANGES,
  (preferences: IProductPreferences) => preferences,
)();

export const setPriceChangesAction = createAction(
  ACTIONS_CONSTANTS.SET_PRICE_CHANGES,
  (changes: IProductChangesToCompare[]) => changes,
)();
