import React, { useState } from 'react';
import { AppCheckbox } from '../../../../core/components/checkbox/checkbox';
import { AppModal } from '../../../../core/components/overlays/modal/modal';
import { withCurrency } from '../../../../core/helpers/currency.helper';

import './purchase-labels-modal.scss';

interface PurchaseLabelsModalProps {
  open: boolean;
  totalSum: number;
  handlePurchaseLabels: (withPackingSlip: boolean) => void;
  onClose: () => void;
}

export function PurchaseLabelsModal({
  open,
  totalSum,
  handlePurchaseLabels,
  onClose,
}: PurchaseLabelsModalProps) {
  const [checked, setChecked] = useState(false);
  return (
    <AppModal
      open={open}
      onClose={onClose}
      title="Labels"
      primaryAction={{
        content: 'Purchase Labels',
        onAction: () => handlePurchaseLabels(checked),
      }}
      secondaryActions={[
        {
          content: 'Cancel',
          onAction: onClose,
        },
      ]}
    >
      <div className="purchase-labels-modal">
        <p>Sum labels cost: {withCurrency(totalSum)}</p>
        <AppCheckbox
          label="Include packing slips"
          checked={checked}
          onChange={(checked) => setChecked(checked)}
        />
      </div>
    </AppModal>
  );
}
