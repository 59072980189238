import { AxiosResponse } from 'axios';
import { all, put, select, takeEvery, takeLatest } from 'redux-saga/effects';
import { ActionType } from 'typesafe-actions';
import { FIRST_PAGE } from '../../../../core/constants/product.constants';
import { Paginated } from '../../../../core/helpers/generic.helper';
import { getNumberOfPages } from '../../../../core/helpers/item-list.helper';
import { showActionError } from '../../../../core/helpers/saga-error-catcher.helper';
import { IOption } from '../../../../core/interfaces/IOption';
import { IProductList, ISupplierProduct } from '../../../../core/interfaces/IProduct';
import { ISupplierProductsListFilter } from '../../../../core/interfaces/IProductFilter';
import {
  IUpdateProductRetailAvailabilityResult,
  IVariantWithProductId,
  productsApi,
} from '../../../api/products.api';
import { ISupplierVariant } from '../../../interfaces/IProduct';
import { getStatusAction, setUpdatingPriceMappingPreferenceAction } from '../status/status.actions';
import {
  changeAvailableGroupsAction,
  changeAvailableGroupsForAllProductsAction,
  addProductsToMarketplaceAction,
  addAllProductsToMarketplaceAction,
  changeVariantsMSRPAction,
  changeVariantsPriceForRetailersAction,
  getFilterOptionsAction,
  getProductsAction,
  getVariantsAction,
  resetProductFiltersAction,
  setAvailableGroupsFilterAction,
  setFilterOptionsAction,
  setLastAvailabilityUpdateAction,
  setLimitFilterAction,
  setPageFilterAction,
  setProductFiltersAction,
  setProductsAction,
  setProductsFetching,
  setQueryFilterAction,
  setTypeFilterAction,
  setVariantsAction,
  setVendorFilterAction,
  showSuccessVariantPriceUpdateToastAction,
  updateVariantAction,
  setStockFilterAction,
  setShopifyStatusFilterAction,
  setInventoryFilterAction,
  setUpdatingAllProductsAction,
  getCSVInventoryAction,
  getAllCSVInventoryAction,
  setIsExportingCSVAction,
} from './products.actions';
import { getProductFilterSelector } from './products.selectors';
import moment from 'moment';
import fileDownload from 'js-file-download';

function* getProductsSaga({ type }: ActionType<typeof getProductsAction>) {
  try {
    yield put(setProductsFetching(true));
    const filter: Paginated<ISupplierProductsListFilter> = yield select(getProductFilterSelector);
    const { data }: AxiosResponse<IProductList<ISupplierProduct>> = yield productsApi.getProducts(
      filter,
    );
    if (filter.page === getNumberOfPages(data.total) && filter.page !== FIRST_PAGE) {
      yield put(setPageFilterAction({ page: getNumberOfPages(data.total) - 1 }));
      return;
    }

    yield put(setProductsFetching(false));
    yield put(setProductsAction(data));
  } catch (e) {
    showActionError(type, e);
  }
}

function* getFilterOptionsSaga({ type }: ActionType<typeof getFilterOptionsAction>) {
  try {
    const { data: vendors }: AxiosResponse<IOption[]> = yield productsApi.getProductsVendor();
    const { data: types }: AxiosResponse<IOption[]> = yield productsApi.getProductsTypes();
    const { data: groups }: AxiosResponse<IOption[]> = yield productsApi.getProductsGroups();
    const shopifyStatus = [
      { label: 'Active', value: 'active' },
      { label: 'Archived', value: 'archived' },
      { label: 'Draft', value: 'draft' },
    ];
    yield put(
      setFilterOptionsAction({
        vendors,
        types,
        groups,
        shopifyStatus,
      }),
    );
  } catch (e) {
    showActionError(type, e);
  }
}

function* getVariantsSaga({ payload, type }: ActionType<typeof getVariantsAction>) {
  const { productId } = payload;
  try {
    const { data }: AxiosResponse<ISupplierVariant[]> = yield productsApi.getProductVariants(
      productId,
    );
    yield put(setVariantsAction(productId, data));
  } catch (e) {
    showActionError(type, e);
  }
}

function* changeAvailableGroupsSaga({
  payload,
  type,
}: ActionType<typeof changeAvailableGroupsAction>) {
  const { retailerGroups, productIds } = payload;
  yield put(setUpdatingAllProductsAction(true));
  try {
    const filter: ISupplierProductsListFilter & { page: number } = yield select(
      getProductFilterSelector,
    );
    const { data }: AxiosResponse<any> = yield productsApi.updateAvailableGroups(
      productIds,
      retailerGroups,
    );

    if ('notFound' in data && data.notFound) throw new Error('Products not found');
    if ('updatingPriceMappingPreference' in data && data.updatingPriceMappingPreference) {
      yield put(setUpdatingPriceMappingPreferenceAction(data.updatingPriceMappingPreference));

      yield put(setPageFilterAction({ page: filter.page }));
    } else if ('success' in data) {
      const action = retailerGroups.length ? 'added' : 'removed';
      yield put(setLastAvailabilityUpdateAction({ ...data, action }));
      yield put(setPageFilterAction({ page: filter.page }));
      yield put(getStatusAction());
    } else if ('availabilityAlreadyModified' in data) {
      yield put(setPageFilterAction({ page: filter.page }));
    } else throw new Error('Unexpected return value');
  } catch (e) {
    showActionError(type, e);
  } finally {
    yield put(setUpdatingAllProductsAction(false));
  }
}

function* changeAvailableGroupsForAllProductsSaga({
  payload: retailerGroups,
  type,
}: ActionType<typeof changeAvailableGroupsForAllProductsAction>) {
  try {
    yield put(setUpdatingAllProductsAction(true));
    const filter: ISupplierProductsListFilter & { page: number } = yield select(
      getProductFilterSelector,
    );

    const { data }: AxiosResponse<IUpdateProductRetailAvailabilityResult> =
      yield productsApi.updateAvailableGroupsForAllProducts(retailerGroups, filter);
    if ('notFound' in data && data.notFound) throw new Error('Products not found');
    if ('updatingPriceMappingPreference' in data && data.updatingPriceMappingPreference) {
      yield put(setUpdatingPriceMappingPreferenceAction(data.updatingPriceMappingPreference));
      yield put(setPageFilterAction({ page: filter.page }));
    } else if ('success' in data) {
      const action = retailerGroups.length ? 'added' : 'removed';
      yield put(setLastAvailabilityUpdateAction({ ...data, action }));
      yield put(setPageFilterAction({ page: filter.page }));
      yield put(getStatusAction());
    } else throw new Error('Unexpected return value');
  } catch (e) {
    showActionError(type, e);
  } finally {
    yield put(setUpdatingAllProductsAction(false));
  }
}

function* addProductsToMarketplaceActionySaga({
  payload,
  type,
}: ActionType<typeof addProductsToMarketplaceAction>) {
  try {
    yield put(setUpdatingAllProductsAction(true));
    const filter: ISupplierProductsListFilter & { page: number } = yield select(
      getProductFilterSelector,
    );
    const { data }: AxiosResponse<IUpdateProductRetailAvailabilityResult> =
      yield productsApi.addProductsToMarketplace(payload);
    if ('notFound' in data && data.notFound) throw new Error('Products not found');
    if ('updatingPriceMappingPreference' in data && data.updatingPriceMappingPreference) {
      yield put(setUpdatingPriceMappingPreferenceAction(data.updatingPriceMappingPreference));
      yield put(setPageFilterAction({ page: filter.page }));
    } else if ('success' in data) {
      const action = 'added';
      yield put(setLastAvailabilityUpdateAction({ ...data, action }));
      yield put(setPageFilterAction({ page: filter.page }));
      yield put(getStatusAction());
    } else if ('availabilityAlreadyModified' in data) {
      yield put(setPageFilterAction({ page: filter.page }));
    } else throw new Error('Unexpected return value');
  } catch (e) {
    showActionError(type, e);
  } finally {
    yield put(setUpdatingAllProductsAction(false));
  }
}

function* addAllProductsToMarketplaceActionSaga({
  type,
}: ActionType<typeof addAllProductsToMarketplaceAction>) {
  try {
    yield put(setUpdatingAllProductsAction(true));
    const filter: ISupplierProductsListFilter & { page: number } = yield select(
      getProductFilterSelector,
    );
    const { data }: AxiosResponse<IUpdateProductRetailAvailabilityResult> =
      yield productsApi.addAllProductsToMarketplace(filter);
    if ('notFound' in data && data.notFound) throw new Error('Products not found');
    if ('updatingPriceMappingPreference' in data && data.updatingPriceMappingPreference) {
      yield put(setUpdatingPriceMappingPreferenceAction(data.updatingPriceMappingPreference));
      yield put(setPageFilterAction({ page: filter.page }));
    } else if ('success' in data) {
      const action = 'added';
      yield put(setLastAvailabilityUpdateAction({ ...data, action }));
      yield put(setPageFilterAction({ page: filter.page }));
      yield put(getStatusAction());
    } else throw new Error('Unexpected return value');
  } catch (e) {
    showActionError(type, e);
  } finally {
    yield put(setUpdatingAllProductsAction(false));
  }
}

function* changeVariantsMSRPSaga({ payload, type }: ActionType<typeof changeVariantsMSRPAction>) {
  try {
    const { data }: AxiosResponse<IVariantWithProductId> = yield productsApi.updateVariantsMSRP(
      payload.productId,
      payload.variantId,
      payload.MSRP,
    );
    yield put(showSuccessVariantPriceUpdateToastAction());
    yield put(updateVariantAction({ ...data, productId: payload.productId }));
  } catch (e) {
    showActionError(type, e);
  }
}

function* getCSVInventorySaga({ payload, type }: ActionType<typeof getCSVInventoryAction>) {
  try {
    yield put(setIsExportingCSVAction(true));
    const { data }: AxiosResponse<File> = yield productsApi.exportCSVProducts(payload);
    const now = moment().format('MMDDYYYY');
    fileDownload(data, `Inventory-${now}.csv`);
    yield put(getProductsAction());
  } catch (e) {
    showActionError(type, e);
  } finally {
    yield put(setIsExportingCSVAction(false));
  }
}

function* getAllCSVInventorySaga({ type }: ActionType<typeof getAllCSVInventoryAction>) {
  try {
    yield put(setIsExportingCSVAction(true));
    const filter: ISupplierProductsListFilter = yield select(getProductFilterSelector);
    const { data }: AxiosResponse<File> = yield productsApi.exportAllCSVProducts(filter);
    const now = moment().format('MMDDYYYY');
    fileDownload(data, `Inventory-All-${now}.csv`);
    yield put(getProductsAction());
  } catch (e) {
    showActionError(type, e);
  } finally {
    yield put(setIsExportingCSVAction(false));
  }
}

function* changeVariantsPriceForRetailersSaga({
  payload,
  type,
}: ActionType<typeof changeVariantsPriceForRetailersAction>) {
  try {
    const { data }: AxiosResponse<IVariantWithProductId> =
      yield productsApi.updateVariantsPriceForRetailers(
        payload.productId,
        payload.variantId,
        payload.priceForRetailers,
      );
    yield put(showSuccessVariantPriceUpdateToastAction());
    yield put(updateVariantAction({ ...data, productId: payload.productId }));
  } catch (e) {
    showActionError(type, e);
  }
}

export function* productsSaga() {
  yield all([
    takeEvery(
      [
        getProductsAction,
        setProductFiltersAction,
        setQueryFilterAction,
        setVendorFilterAction,
        setTypeFilterAction,
        setShopifyStatusFilterAction,
        setStockFilterAction,
        setAvailableGroupsFilterAction,
        setPageFilterAction,
        setLimitFilterAction,
        resetProductFiltersAction,
        setInventoryFilterAction,
      ],
      getProductsSaga,
    ),
    takeEvery(getFilterOptionsAction, getFilterOptionsSaga),
    takeEvery(getVariantsAction, getVariantsSaga),
    takeLatest(changeAvailableGroupsAction, changeAvailableGroupsSaga),
    takeLatest(changeAvailableGroupsForAllProductsAction, changeAvailableGroupsForAllProductsSaga),
    takeLatest(addProductsToMarketplaceAction, addProductsToMarketplaceActionySaga),
    takeLatest(addAllProductsToMarketplaceAction, addAllProductsToMarketplaceActionSaga),
    takeLatest(getCSVInventoryAction, getCSVInventorySaga),
    takeLatest(getAllCSVInventoryAction, getAllCSVInventorySaga),
    takeEvery(changeVariantsMSRPAction, changeVariantsMSRPSaga),
    takeEvery(changeVariantsPriceForRetailersAction, changeVariantsPriceForRetailersSaga),
  ]);
}
