import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppModal } from '../../../../core/components/overlays/modal/modal';
import { ConnectionProductFilter } from '../../../../core/components/product-list/table-elements/product-filter/connection-modal-products-filter';
import { Paginated } from '../../../../core/helpers/generic.helper';
import { getNumberOfPages } from '../../../../core/helpers/item-list.helper';
import { IRetailerProductsFilters } from '../../../../core/interfaces/IProductFilter';
import { marketplaceApi } from '../../../api/marketplace.api';
import { IRetailerVariant } from '../../../interfaces/IProduct';
import { getInventoryFilterOptionsAction } from '../../../redux/modules/inventory/inventory.actions';
import { setMarketplaceRetailerProductsPageFilterAction } from '../../../redux/modules/marketplace/marketplace.actions';
import { getMarketplaceConnectionFiltersSelector } from '../../../redux/modules/marketplace/marketplace.selectors';
import { RetailerVariantList } from '../../containers/variant-list/retailer-variant-list';

type VariantConnectionModalProps = {
  crowdshipProduct: string;
  onClose: () => void;
  onSuccess: (id?: string) => void;
  onError: (error: string) => void;
};

export const VariantConnectionModal = ({
  crowdshipProduct,
  onClose,
  onError,
  onSuccess,
}: VariantConnectionModalProps) => {
  const dispatch = useDispatch();

  const [fetching, setFetching] = useState(false);
  const [total, setTotal] = useState(0);
  const [products, setProducts] = useState<IRetailerVariant[]>([]);
  const filters: Paginated<IRetailerProductsFilters> = useSelector(
    getMarketplaceConnectionFiltersSelector,
  );

  useEffect(() => {
    setFetching(true);
    marketplaceApi
      .getRetailerVariants({ ...filters })
      .then(({ data }) => {
        setTotal(data.count);
        setProducts(data.products);
      })
      .catch((error) => {
        onError('Unexpected error');
      })
      .finally(() => setFetching(false));
  }, [onError, filters]);

  useEffect(() => {
    dispatch(getInventoryFilterOptionsAction());
  }, [dispatch]);

  const handleConnectVariant = useCallback(
    (retailerProduct: string) => {
      setFetching(true);
      marketplaceApi
        .connectVariant(retailerProduct, crowdshipProduct)
        .then(({ data }) => {
          if ('success' in data) {
            onSuccess(crowdshipProduct);
          } else if ('notFound' in data) {
            onError('Variant not found by given IDs');
          } else {
            console.error(data);
            onError('Unexpected error');
          }
        })
        .catch((error) => {
          onError('Unexpected error');
          console.error('Error when connecting variant', error);
        })
        .finally(() => setFetching(false));
    },
    [onSuccess, onError, crowdshipProduct],
  );

  const handlePageChange = useCallback(
    (page: number) => {
      dispatch(setMarketplaceRetailerProductsPageFilterAction({ page }));
    },
    [dispatch],
  );

  return (
    <AppModal open onClose={onClose} title="Choose product to connect variant to" large>
      <ConnectionProductFilter />
      <RetailerVariantList
        variants={products}
        fetching={fetching}
        total={getNumberOfPages(total, filters.limit)}
        handlePageChange={handlePageChange}
        handleConnectVariant={handleConnectVariant}
      />
    </AppModal>
  );
};
