import { AxiosResponse } from 'axios';
import { axiosInstance } from '../../core/api/base.api';
import {
  IDashboardOrdersSummary,
  IDashboardSalesSummary,
  IDashboardProductsSummary,
} from '../interfaces/IDashboard';

class DashboardApi {
  private backend = process.env.REACT_APP_BACK_END_URL as string;
  private rootPath = '/api/retailer/shopify';

  async getOrdersSummary(): Promise<AxiosResponse<IDashboardOrdersSummary>> {
    return await axiosInstance.get(`${this.backend}${this.rootPath}/dashboard/orders`);
  }

  async getProductsSummary(): Promise<AxiosResponse<IDashboardProductsSummary>> {
    return await axiosInstance.get(`${this.backend}${this.rootPath}/dashboard/products`);
  }

  async getSalesSummary(): Promise<AxiosResponse<IDashboardSalesSummary>> {
    return await axiosInstance.get(`${this.backend}${this.rootPath}/dashboard/sales`);
  }
}

export const dashboardApi = new DashboardApi();
