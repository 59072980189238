import { createReducer } from 'typesafe-actions';
import { CoreActions } from '../../actions';
import { setBreadcrumbsAction } from './breadcrumbs.actions';

interface IBreadcrumbsReducer {
  IdsAndTitles: ITitleForId;
}

export interface ITitleForId {
  [id: string]: string;
}

const initialState: IBreadcrumbsReducer = {
  IdsAndTitles: {},
};

export const breadcrumbsReducer = createReducer<IBreadcrumbsReducer, CoreActions>(
  initialState,
).handleAction(setBreadcrumbsAction, (state, { payload }) => ({
  ...state,
  IdsAndTitles: { ...state.IdsAndTitles, ...payload },
}));
