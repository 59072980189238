import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getPreferencesAction } from '../../../redux/modules/preferences/preferences.actions';
import {
  getPreferencesFetchingSelector,
  getPreferencesSelector,
} from '../../../redux/modules/preferences/preferences.selectors';
import { getStatusSelector } from '../../../redux/modules/status/status.selectors';
import { PreferencesList } from '../../containers/preferences-list/preferences-list';
import { PreferencesListSkeleton } from '../../containers/preferences-list/preferences-list-skeleton';

export function PreferencesLayout() {
  const dispatch = useDispatch();
  const { currency } = useSelector(getStatusSelector);
  const preferences = useSelector(getPreferencesSelector);
  const fetching = useSelector(getPreferencesFetchingSelector);

  useEffect(() => {
    dispatch(getPreferencesAction());
  }, [dispatch]);

  return (
    <div className="preferences-layout">
      {fetching || !preferences ? (
        <PreferencesListSkeleton />
      ) : (
        <PreferencesList preferences={preferences} currency={currency} />
      )}
    </div>
  );
}
