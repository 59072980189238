import { IMarketplaceProduct } from '../../interfaces/IProduct';
import { AppCard } from '../structure/card/card';
import { ProductList } from './product-list';
import {
  ACTION_TITLE,
  MARKETPLACE_LIST_TITLE,
  retailerProductListHeadings,
} from './product-list.constants';
import { ProductListBaseProps } from './product-list.interfaces';
import { MarketplaceImportFilter } from './table-elements/product-filter/marketplace-product-filter';
import { MarketplaceProductRow } from './table-elements/rows/marketplace-product-row';

type MarketplaceProductListProps = ProductListBaseProps<IMarketplaceProduct> & {
  addToImportList: () => void;
  disableAdding: boolean;
  disableSubmit: boolean;
};

export const MarketplaceProductList = ({
  addToImportList,
  disableAdding,
  disableSubmit,
  ...props
}: MarketplaceProductListProps) => (
  <div className="marketplace-product-list">
    <div key={MARKETPLACE_LIST_TITLE} className="product-list">
      <AppCard title={MARKETPLACE_LIST_TITLE}>
        <ProductList
          headings={retailerProductListHeadings}
          listActions={[{ content: ACTION_TITLE.ADD_TO_IMPORT_LIST, onAction: addToImportList }]}
          row={MarketplaceProductRow}
          filter={MarketplaceImportFilter}
          disableAdding={disableAdding}
          {...props}
        />
      </AppCard>
    </div>
  </div>
);
