import { ISupplierProduct } from '../../interfaces/IProduct';
import { IAction } from '../resource-list/ResourceItem';
import { AppCard } from '../structure/card/card';
import { ProductList } from './product-list';
import { supplierProductListHeadings, SUPPLIER_LIST_TITLE } from './product-list.constants';
import { ProductListBaseProps } from './product-list.interfaces';
import { SupplierProductFilter } from './table-elements/product-filter/supplier-product-filter';
import { SupplierProductRow } from './table-elements/rows/supplier-product-row';

type SupplierProductListProps = ProductListBaseProps<ISupplierProduct> & {
  listActions: IAction[];
};

export const SupplierProductList = ({ listActions, ...props }: SupplierProductListProps) => {
  return (
    <div key={SUPPLIER_LIST_TITLE} className="product-list">
      <AppCard title={SUPPLIER_LIST_TITLE}>
        <ProductList
          headings={supplierProductListHeadings}
          listActions={listActions}
          row={SupplierProductRow}
          filter={SupplierProductFilter}
          {...props}
        />
      </AppCard>
    </div>
  );
};
