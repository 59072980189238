import { createSelector } from 'reselect';
import { RootState } from '../../reducers';

export const getInventoryProductsSelector = createSelector(
  [(state: RootState) => state.inventory.productList.productGroups],
  (products) => products,
);

export const getTotalInventoryProductsCountSelector = createSelector(
  [(state: RootState) => state.inventory.productList.total],
  (total) => total,
);

export const getInventoryProductVariantsSelector = createSelector(
  [(state: RootState, productId: string) => state.inventory.variants[productId]],
  (variants) => variants || [],
);

export const getInventoryFilterOptionsSelector = createSelector(
  [(state: RootState) => state.inventory.options],
  (options) => options,
);

export const fetchingInventoryProductsSelector = createSelector(
  [(state: RootState) => state.inventory.fetching],
  (fetching) => fetching,
);

export const getInventoryFiltersSelector = createSelector(
  [(state: RootState) => state.inventory.filter],
  (filters) => filters,
);
