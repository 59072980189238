import { axiosInstance } from '../../core/api/base.api';
import {
  IInventoryProduct,
  IProductList,
  IProductMatchesList,
  IVariantMatch,
} from '../../core/interfaces/IProduct';
import { SALE_PRICE_OPTION } from '../interfaces/IPreferences';
import { IInventoryVariantFromApi } from '../interfaces/IProduct';
import { IProductDetailsFromApi } from '../interfaces/IProductDetails';
import { IVariantSync } from '../interfaces/IProductsSync';
import { Paginated } from '../../core/helpers/generic.helper';
import { IInventoryFilter } from '../../core/interfaces/IProductFilter';
import { IOption } from '../../core/interfaces/IOption';

class InventoryApi {
  private backend = process.env.REACT_APP_BACK_END_URL as string;
  private rootPath = '/api/retailer/shopify/products/inventory';
  private rootPathSync = '/api/retailer/shopify/sync-variant';
  private retailerPath = '/api/retailer/products/inventory';

  async getProducts(filter: Paginated<IInventoryFilter>) {
    const { query, vendors, fulfilledBy, types, ...rest } = filter;
    return await axiosInstance.get<IProductList<IInventoryProduct>>(
      `${this.backend}${this.retailerPath}/`,
      {
        params: {
          ...rest,
          title: query || undefined,
          vendors: vendors?.length ? vendors.join(',') : undefined,
          fulfilledBy: fulfilledBy?.length ? fulfilledBy.join(',') : undefined,
          types: types?.length ? types.join(',') : undefined,
        },
      },
    );
  }

  async getMatches(filter: Paginated<IInventoryFilter>) {
    const { query, vendors, types, limit, hasStock, page, fulfilledBy } = filter;
    return await axiosInstance.get<IProductMatchesList<IVariantMatch>>(
      `${this.backend}${this.rootPathSync}/matches`,
      {
        params: {
          title: query || undefined,
          vendors: vendors?.length ? vendors.join(',') : undefined,
          types: types?.length ? types.join(',') : undefined,
          fulfilledBy: fulfilledBy?.length ? fulfilledBy.join(',') : undefined,
          hasStock,
          limit,
          page,
        },
      },
    );
  }

  async startInventorySync() {
    return await axiosInstance.post<void>(`${this.backend}${this.rootPathSync}/start`);
  }

  async getInventorySyncStatus() {
    return await axiosInstance.get<Pick<IVariantSync, 'syncInProgress'>>(
      `${this.backend}${this.rootPathSync}/status`,
    );
  }

  async connectProducts(
    matchesIds: {
      retailerProductId: string;
      supplierProductId: string;
    }[],
  ) {
    return await axiosInstance.post<{ success: true } | { notFound: true }>(
      `${this.backend}${this.rootPathSync}/connect`,
      {
        matchesIds,
      },
    );
  }

  async connectProductsAll(filter: Partial<IInventoryFilter> & { ids: string[] }) {
    return await axiosInstance.post<{ success: true } | { notFound: true }>(
      `${this.backend}${this.rootPathSync}/connect/all`,
      {
        title: filter.query || undefined,
        vendors: filter.vendors?.length ? filter.vendors : undefined,
        fulfilledBy: filter.fulfilledBy?.length ? filter.fulfilledBy : undefined,
        types: filter.types?.length ? filter.types : undefined,
        properties: filter.hasStock,
        supplierProducts: filter.ids,
      },
    );
  }

  async getProductDetails(productId: string) {
    return await axiosInstance.get<IProductDetailsFromApi>(
      `${this.backend}${this.rootPath}/${productId}`,
    );
  }

  async updateProductPrices(
    productId: string,
    variants: {
      id: string;
      price: number;
      updateManuallySetPrice: boolean;
      useCompanyDefault?: boolean;
      salePriceOption?: SALE_PRICE_OPTION;
      markup?: number;
    }[],
  ) {
    return await axiosInstance.put<{}>(`${this.backend}${this.rootPath}/${productId}`, {
      variants,
    });
  }

  async getUpdatedPrice(
    productId: string,
    updateOptions: {
      markup: number;
      useCompanyDefault: boolean;
      salePriceOption: SALE_PRICE_OPTION;
      id: string;
    },
  ) {
    return await axiosInstance.get<
      | {
          id: string;
          price: number;
          defaultMarkup: number;
          salePriceOption: SALE_PRICE_OPTION;
        }
      | { notFound: true }
    >(`${this.backend}${this.rootPath}/${productId}/variants/${updateOptions.id}/price`, {
      params: {
        markup: updateOptions.markup,
        useCompanyDefault: updateOptions.useCompanyDefault,
        salePriceOption: updateOptions.salePriceOption,
      },
    });
  }

  async getProductVariants(productId: string) {
    return await axiosInstance.get<IInventoryVariantFromApi[]>(
      `${this.backend}${this.rootPath}/${productId}/variants`,
    );
  }

  async updateProductVariantSalePrice(productId: string, variantId: string, salePrice: number) {
    return await axiosInstance.put<void>(
      `${this.backend}${this.rootPath}/${productId}/variants/${variantId}/price`,
      { salePrice },
    );
  }

  async removeProductsFromInventory(productIds: string[]) {
    return await axiosInstance.delete<IDeleteProductsResponse>(`${this.backend}${this.rootPath}/`, {
      params: { productIds: productIds.join(',') },
    });
  }

  async removeAllProductsFromInventory(filter: IInventoryFilter) {
    const { query, vendors, types, fulfilledBy, ...rest } = filter;
    return await axiosInstance.delete<IDeleteProductsResponse>(
      `${this.backend}${this.rootPath}/all`,
      {
        data: {
          ...rest,
          title: query || undefined,
          vendors: vendors?.length ? vendors : undefined,
          fulfilledBy: fulfilledBy?.length ? fulfilledBy.join(',') : undefined,
          types: types?.length ? types : undefined,
        },
      },
    );
  }

  async disconnectProductsFromInventory(productIds: string[]) {
    return await axiosInstance.delete<IDeleteProductsResponse>(
      `${this.backend}${this.retailerPath}/disconnect`,
      {
        params: { productIds: productIds.join(',') },
      },
    );
  }

  async disconnectAllProductsFromInventory(filter: IInventoryFilter) {
    const { query, vendors, types, fulfilledBy, productInventory, isConnected } = filter;

    return await axiosInstance.delete<IDeleteProductsResponse>(
      `${this.backend}${this.retailerPath}/disconnect/all`,
      {
        data: {
          title: query || undefined,
          vendors: vendors?.length ? vendors : undefined,
          types: types?.length ? types : undefined,
          fulfilledBy: fulfilledBy?.length ? fulfilledBy.join(',') : undefined,
          productInventory: productInventory || undefined,
          isConnected: isConnected,
        },
      },
    );
  }

  async exportCSVFromInventory(productIds: string[]) {
    return await axiosInstance.post(
      `${this.backend}${this.retailerPath}/export-csv`,
      { productIds },
      { responseType: 'blob' },
    );
  }

  async exportCSVAllFromInventory(filter: IInventoryFilter) {
    const { query, vendors, types, fulfilledBy, productInventory, isConnected } = filter;

    return await axiosInstance.post(
      `${this.backend}${this.retailerPath}/export-csv/all`,
      {
        title: query || undefined,
        vendors: vendors?.length ? vendors : undefined,
        types: types?.length ? types : undefined,
        fulfilledBy: fulfilledBy?.length ? fulfilledBy : undefined,
        productInventory: productInventory || undefined,
        isConnected: isConnected,
      },
      { responseType: 'blob' },
    );
  }

  async getVendors() {
    return await axiosInstance.get<IOption[]>(`${this.backend}${this.retailerPath}/vendors`);
  }

  async getTypes() {
    return await axiosInstance.get<IOption[]>(`${this.backend}${this.retailerPath}/types`);
  }

  async getSuppliers() {
    return await axiosInstance.get<IOption[]>(`${this.backend}${this.retailerPath}/suppliers`);
  }
}

export const inventoryApi = new InventoryApi();

export type IPostProductsResponse =
  | SuccessAddOrRemoveProductsResponse
  | NotFoundResponse
  | ProductLimitExceededResponse;

export type IDeleteProductsResponse = SuccessAddOrRemoveProductsResponse | NotFoundResponse;

type NotFoundResponse = { notFound: true };

type SuccessAddOrRemoveProductsResponse = {
  success: true;
  limit: number | undefined;
  added: number;
};

type ProductLimitExceededResponse = {
  limitExceeded: true;
  limit: number;
  added: number;
  tryingToAdd: number;
};
