import { createAction } from 'typesafe-actions';
import { Paginated } from '../../../../../core/helpers/generic.helper';
import { IConnectionFilters } from '../../../../../core/interfaces/IProductFilter';
import { ACTIONS_CONSTANTS } from '../../../actions-constants';

export const setMarketplaceRetailerProductsFiltersAction = createAction(
  ACTIONS_CONSTANTS.SET_MARKETPLACE_CONNECTION_LIST_FILTERS,
  (filters: IConnectionFilters) => filters,
)();

export const resetMarketplaceRetailerProductsFiltersAction = createAction(
  ACTIONS_CONSTANTS.RESET_MARKETPLACE_CONNECTION_LIST_FILTERS,
)();

export const setMarketplaceRetailerProductsQueryFilterAction = createAction(
  ACTIONS_CONSTANTS.SET_MARKETPLACE_CONNECTION_LIST_QUERY_FILTER,
  (filters: Pick<IConnectionFilters, 'query'>) => filters,
)();

export const setMarketplaceRetailerProductsVendorFilterAction = createAction(
  ACTIONS_CONSTANTS.SET_MARKETPLACE_CONNECTION_LIST_VENDOR_FILTER,
  (filters: Pick<IConnectionFilters, 'vendors'>) => filters,
)();

export const setMarketplaceRetailerProductsTypeFilterAction = createAction(
  ACTIONS_CONSTANTS.SET_MARKETPLACE_CONNECTION_LIST_TYPE_FILTER,
  (filters: Pick<IConnectionFilters, 'types'>) => filters,
)();

export const setMarketplaceRetailerProductsSupplierFilterAction = createAction(
  ACTIONS_CONSTANTS.SET_MARKETPLACE_CONNECTION_LIST_SUPPLIER_FILTER,
  (filters: Pick<IConnectionFilters, 'fulfilledBy'>) => filters,
)();

export const setMarketplaceRetailerProductsConnectionFilterAction = createAction(
  ACTIONS_CONSTANTS.SET_MARKETPLACE_CONNECTION_LIST_CONNECTION_FILTER,
  (filters: Pick<IConnectionFilters, 'isConnected'>) => filters,
)();

export const setMarketplaceRetailerProductsPageFilterAction = createAction(
  ACTIONS_CONSTANTS.SET_MARKETPLACE_CONNECTION_LIST_PAGE_FILTER,
  (filters: Pick<Paginated<IConnectionFilters>, 'page'>) => filters,
)();

export const setMarketplaceRetailerProductsLimitFilterAction = createAction(
  ACTIONS_CONSTANTS.SET_MARKETPLACE_CONNECTION_LIST_LIMIT_FILTER,
  (filters: Pick<Paginated<IConnectionFilters>, 'limit'>) => filters,
)();
