import { Form, Formik } from 'formik';
import moment from 'moment-timezone';
import React, { useCallback, useMemo } from 'react';
import { AppButton } from '../../../../core/components/button/Button';
import { AppControlledDatePicker } from '../../../../core/components/forms/controlled-date-picker/controlled-date-picker';
import { AppControlledTextField } from '../../../../core/components/forms/controlled-text-field/controlled-text-field';
import { AppModal } from '../../../../core/components/overlays/modal/modal';
import { AppTextStyle } from '../../../../core/components/text/text-style/TextStyle';
import { withCurrency } from '../../../../core/helpers/currency.helper';
import { getPayoutMethod } from '../../../../core/helpers/get-payout-method.helper';
import { validation } from '../../../../core/helpers/validations.helper';
import { IPayout, IPendingConfirmation } from '../../../../shopify-supplier/interfaces/IPayout';
import './payment-confirmation-modal.scss';

type Confirmation = {
  paidAt: Date;
  confirmationId: string;
};

type PaymentConfirmationModalProps = {
  open: boolean;
  onClose: () => void;
  onConfirm: (confirmation: IPendingConfirmation) => void;
  payout: IPayout;
  supplierName: string;
  ordersCount: number;
  pendingConfirmation?: IPendingConfirmation;
};

export const PaymentConfirmationModal = ({
  open,
  onClose,
  onConfirm,
  payout,
  supplierName,
  ordersCount,
  pendingConfirmation,
}: PaymentConfirmationModalProps) => {
  const initialValues = useMemo(
    () => ({
      paidAt: pendingConfirmation?.paidAt ? new Date(pendingConfirmation?.paidAt) : new Date(),
      confirmationId: pendingConfirmation?.confirmationId || '',
    }),
    [pendingConfirmation],
  );

  const title = useMemo(
    () => (pendingConfirmation ? 'Confirm payment received' : 'Enter payment details'),
    [pendingConfirmation],
  );
  const primaryActionTitle = useMemo(
    () => (pendingConfirmation ? 'Confirm Payment Received' : 'Confirm Payment'),
    [pendingConfirmation],
  );

  const handleSubmit = useCallback(
    ({ paidAt, confirmationId }: Confirmation) => {
      onConfirm({ paidAt: paidAt.toISOString(), confirmationId });
    },
    [onConfirm],
  );

  return (
    <AppModal title={title} open={open} onClose={onClose}>
      <Formik initialValues={initialValues} onSubmit={handleSubmit}>
        {({ values, submitForm }) => (
          <Form name="confirm-payment">
            <div className="payment-confirmation-modal">
              <div className="payout-summary">
                <div>
                  <AppTextStyle variation="strong">Invoice ID:</AppTextStyle> {payout.name}
                </div>
                <div>
                  <AppTextStyle variation="strong"># of Orders:</AppTextStyle> {ordersCount}
                </div>
                <div>
                  {pendingConfirmation ? (
                    <>
                      <AppTextStyle variation="strong">Paid by:</AppTextStyle> {payout.dueFrom}
                    </>
                  ) : (
                    <>
                      <AppTextStyle variation="strong">Paid to:</AppTextStyle> {supplierName}
                    </>
                  )}
                </div>
                <div>
                  <AppTextStyle variation="strong">Paid via:</AppTextStyle>{' '}
                  {getPayoutMethod(payout.payoutPreference.payoutMethod)}
                </div>
                <div>
                  <AppTextStyle variation="strong">Invoice total:</AppTextStyle>{' '}
                  {withCurrency(payout.totalAmount)}
                </div>
              </div>
              <div className="confirmation-form">
                <div className="date-picker-wrapper">
                  <p className="label">Expected payment delivery date</p>
                  <AppControlledDatePicker name="paidAt" />
                </div>
                <AppControlledTextField
                  label="Confirmation ID"
                  name="confirmationId"
                  placeholder="ABC123"
                  validate={validation.isNotEmpty}
                />
              </div>
              {pendingConfirmation ? (
                <div>
                  By confirming, {ordersCount} orders in Batch {payout.name} will be marked as paid
                </div>
              ) : (
                <div>
                  By pressing "Confirm",{' '}
                  <AppTextStyle variation="strong">{supplierName}</AppTextStyle> will be notified
                  that <AppTextStyle variation="strong">{payout.dueFrom}</AppTextStyle> has sent a
                  payment of {withCurrency(payout.totalAmount)} via{' '}
                  {getPayoutMethod(payout.payoutPreference.payoutMethod)}, with estimated delivery
                  on {moment(values.paidAt).format('M/DD/YYYY')}
                </div>
              )}
              <div className="footer">
                <AppButton primary onClick={submitForm}>
                  {primaryActionTitle}
                </AppButton>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </AppModal>
  );
};
