import Decimal from 'decimal.js';
import { useMemo } from 'react';
import { AppList } from '../../../../core/components/list/list';
import { AppCard } from '../../../../core/components/structure/card/card';
import { getFormattedDate } from '../../../../core/helpers/date.helper';
import { withCurrency } from '../../../../core/helpers/currency.helper';
import { IPayout } from '../../../interfaces/IPayout';
import { ICurrency } from '../../../../core/interfaces/ICurrency';

interface IPayoutSummaryProps {
  payout: IPayout | null;
  loading: boolean;
  currency?: ICurrency;
}

export const PayoutSummary = ({
  payout,
  loading,
  currency = { isoCode: 'USD', symbol: '$' },
}: IPayoutSummaryProps) => {
  const summary = useMemo(
    () =>
      !loading && payout
        ? [
            `Created at: ${getFormattedDate(payout.createdAt)}`,
            `Updated at: ${getFormattedDate(payout.updatedAt)}`,
            `Status: ${
              payout.status === 'open' && payout.pendingConfirmation
                ? 'pending confirmation'
                : payout.status
            }`,
            `Amount: ${withCurrency(payout.amount, currency)}`,
            !!payout.confirmationId && `Confirmation ID: ${payout.confirmationId}`,
            !!payout.adjustmentAmount &&
              `Adjustment amount: ${withCurrency(payout.adjustmentAmount, currency)}`,

            !!payout.adjustmentAmount &&
              !!payout.adjustmentReason &&
              `Adjustment reason: ${payout.adjustmentReason}`,

            `Total: ${
              !!payout.adjustmentAmount
                ? withCurrency(+new Decimal(payout.amount).plus(payout.adjustmentAmount), currency)
                : withCurrency(payout.amount, currency)
            }`,
          ].filter((li) => li)
        : [],
    [payout, loading, currency],
  );

  return (
    <AppCard title="Summary" sectioned>
      <AppList>{summary}</AppList>
    </AppCard>
  );
};
