import { useSelector } from 'react-redux';
import { getInactiveStoreSelector } from '../../../../core/redux/modules/auth/auth.selectors';
import { AccountNotConnectedBanner } from '../account-not-connected-banner/account-not-connected-banner';
import { CancellationRequestedBanner } from '../cancellation-requested-banner/cancellation-requested-banner';
import { PaymentPendingConfirmationBanner } from '../payment-pending-confirmation-banner/payment-pending-confirmation-banner';
import { ApiUpdatesBanner } from '../../../../core/components/banners/api-updates-banner/api-updates-banner';
import { getUpdatedApiStatusSelector } from '../../../redux/modules/status/status.selectors';
import { useLocation } from 'react-router-dom';

export function AppBanners() {
  const { pathname } = useLocation();
  const inactiveStore = useSelector(getInactiveStoreSelector);
  const apiStatus = useSelector(getUpdatedApiStatusSelector);

  if (inactiveStore) return null;

  return (
    <>
      <AccountNotConnectedBanner />
      <CancellationRequestedBanner />
      <PaymentPendingConfirmationBanner />
      {!apiStatus.updatedApi && apiStatus.permissionUrl && !pathname.includes('/api-update') && (
        <ApiUpdatesBanner permissionUrl={apiStatus.permissionUrl} />
      )}
    </>
  );
}
