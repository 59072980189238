type NonEmptyArray<T> = [T, ...T[]];
type Heading = {
  title: string;
  new?: boolean;
  hidden?: boolean;
};

export const retailerProductListHeadings: NonEmptyArray<Heading> = [
  { title: 'Image', hidden: true },
  { title: 'Title' },
  { title: 'Type' },
  { title: 'Fulfilled By' },
  { title: 'Qty' },
  { title: 'Retailer margin' },
  { title: 'Connections' },
  { title: 'SKUs', hidden: true },
];

export const retailerInventoryProductListHeadings: NonEmptyArray<Heading> = [
  { title: 'Image', hidden: true },
  { title: 'Title' },
  { title: 'Type' },
  { title: 'Qty' },
  { title: 'Your margin' },
  { title: 'Shopify status' },
  { title: 'Fulfillment' },
  { title: 'SKUs', hidden: true },
];

export const retailerImportListHeadings: NonEmptyArray<Heading> = [
  { title: 'Image' },
  { title: 'SKU' },
  { title: 'Title' },
  { title: 'Inventory' },
  { title: 'Cost' },
  { title: 'SRP' },
  { title: 'Price' },
  { title: 'Your margin' },
  { title: 'Compare At Price' },
];

export const supplierProductListHeadings: NonEmptyArray<Heading> = [
  { title: 'Image', hidden: true },
  { title: 'Title' },
  { title: 'Type' },
  { title: 'Shopify status' },
  { title: 'Qty' },
  { title: 'Retailer Margin' },
  { title: 'Availability' },
  { title: 'SKUs', hidden: true },
];

export enum ACTION_TITLE {
  EXPORT_PRODUCTS = 'Export Products',
  DELETE_PRODUCT = 'Delete Product',
  DISCONNECT_PRODUCT = 'Disconnect',
  ADD_TO_STORE = 'Add to my store',
  ADD_TO_IMPORT_LIST = 'Add to Import List',
  REMOVE_FROM_CROWDSHIP = 'Remove from Crowdship',
  ADD_TO_CROWDSHIP = 'Add to Crowdship',
}

export const MARKETPLACE_LIST_TITLE = 'Marketplace products';
export const INVENTORY_LIST_TITLE = 'Products in my store';
export const SUPPLIER_LIST_TITLE = 'Products';
