import React from 'react';
import { AppControlledCheckbox } from '../../../../../core/components/forms/controlled-checkbox/controlled-checkbox';
import { AppControlledTextField } from '../../../../../core/components/forms/controlled-text-field/controlled-text-field';
import { AppControlledRadioGroup } from '../../../../../core/components/forms/radio/controlled-radio-group/controlled-radio-group';
import { AppCard } from '../../../../../core/components/structure/card/card';
import { AppLayoutAnnotatedSection } from '../../../../../core/components/structure/layout/layout-annotated-section';
import { AppStack } from '../../../../../core/components/structure/stack/Stack';
import { AppSubheading } from '../../../../../core/components/text/subheading/subheading';
import { validation } from '../../../../../core/helpers/validations.helper';
import {
  importedOrConnectedProductStatusOptionsConstants,
  productCompareAtPriceOptionsConstants,
  productDiscontinuationOptionsConstants,
  productSalePriceOptionsConstants,
  productUpdateOptionsConstants,
} from '../../../../constants/preferences.constants';
import {
  COMPARE_AT_PRICE_OPTION,
  IProductPreferences,
  PRODUCT_UPDATE_OPTION,
  SALE_PRICE_OPTION,
} from '../../../../interfaces/IPreferences';

type ProductsSectionProps = {
  unsavedPreferences: IProductPreferences;
};

export const ProductsSection = ({ unsavedPreferences }: ProductsSectionProps) => {
  return (
    <AppLayoutAnnotatedSection title="Products">
      <AppCard sectioned title="Shopify Product Status">
        <p style={{ padding: '0 0 1.5rem' }}>
          When importing a marketplace product to your Shopify store, set the status to Draft or
          Active.
        </p>
        <AppControlledRadioGroup
          optionsDesc={importedOrConnectedProductStatusOptionsConstants}
          name={'preferences.product.importedOrConnectedProductStatusOption'}
        />
      </AppCard>
      <AppCard sectioned title="Add tag(s) to imported products">
        <AppControlledCheckbox
          label="Add tag(s) to products imported from Crowdship"
          name={'preferences.product.addTag'}
        />
        {unsavedPreferences.addTag && (
          <AppControlledTextField
            label="List tags, separated by comma"
            name="preferences.product.customTag"
            placeholder="Add tag(s)"
            validate={validation.notContainedEmptyTags}
          />
        )}
      </AppCard>
      <AppCard sectioned title="Sync Supplier Product Updates">
        <p style={{ padding: '0 0 1.5rem' }}>
          Select which listing fields should be synced automatically when Suppliers make updates.
          <br />
          NOTE: Supplier Price tracking can be managed via the Pricing section below. Inventory will
          always remain tracked.
        </p>
        <AppControlledRadioGroup
          optionsDesc={productUpdateOptionsConstants.map((o) =>
            o.value === PRODUCT_UPDATE_OPTION.UPDATE
              ? {
                  ...o,
                  ...(unsavedPreferences.updateOption === PRODUCT_UPDATE_OPTION.UPDATE && {
                    helpText: (
                      <div className="update-fields">
                        <AppSubheading>
                          Select which fields to sync when suppliers make updates
                        </AppSubheading>
                        <AppCard
                          sections={[
                            {
                              content: (
                                <AppControlledCheckbox
                                  name="preferences.product.updateFields.all"
                                  label="Sync Supplier updates to all product listing fields"
                                />
                              ),
                            },
                            {
                              title: 'Enable sync for specific fields only',
                              content: (
                                <AppStack vertical>
                                  <AppControlledCheckbox
                                    name="preferences.product.updateFields.title"
                                    label="Product Title"
                                    disabled={unsavedPreferences.updateFields.all}
                                  />
                                  <AppControlledCheckbox
                                    name="preferences.product.updateFields.description"
                                    label="Product Description"
                                    disabled={unsavedPreferences.updateFields.all}
                                  />
                                  <AppControlledCheckbox
                                    name="preferences.product.updateFields.tags"
                                    label="Product Tags"
                                    disabled={unsavedPreferences.updateFields.all}
                                  />
                                  <AppControlledCheckbox
                                    name="preferences.product.updateFields.vendor"
                                    label='Product "Vendor"'
                                    helpText='Elsewhere in Crowdship, this field is called "Brand"'
                                    disabled={unsavedPreferences.updateFields.all}
                                  />
                                  <AppControlledCheckbox
                                    name="preferences.product.updateFields.productType"
                                    label="Product Type"
                                    disabled={unsavedPreferences.updateFields.all}
                                  />
                                  <AppControlledCheckbox
                                    name="preferences.product.updateFields.images"
                                    label="Product Images"
                                    disabled={unsavedPreferences.updateFields.all}
                                  />
                                  <AppControlledCheckbox
                                    name="preferences.product.updateFields.variants"
                                    label="Product Variants"
                                    helpText="If enabled, this will add and delete variants automatically as they become available/unavailable, and manual disconnection of individual variants will be disabled while this option is enabled."
                                    disabled={unsavedPreferences.updateFields.all}
                                  />
                                </AppStack>
                              ),
                            },
                            {
                              title: 'Sync specific updates to product variants',
                              content: (
                                <AppStack vertical>
                                  <AppControlledCheckbox
                                    name="preferences.product.updateFields.sku"
                                    label="SKU"
                                    disabled={unsavedPreferences.updateFields.all}
                                  />
                                  <AppControlledCheckbox
                                    name="preferences.product.updateFields.barcode"
                                    label="Barcode"
                                    disabled={unsavedPreferences.updateFields.all}
                                  />
                                  <AppControlledCheckbox
                                    name="preferences.product.updateFields.weight"
                                    label="Weight"
                                    disabled={unsavedPreferences.updateFields.all}
                                  />
                                  <AppControlledCheckbox
                                    name="preferences.product.updateFields.image"
                                    label="Image"
                                    helpText={
                                      !unsavedPreferences.updateFields.images &&
                                      !unsavedPreferences.updateFields.all
                                        ? 'Variant images will not be updated if the product images are not updated'
                                        : undefined
                                    }
                                    disabled={
                                      unsavedPreferences.updateFields.all ||
                                      !unsavedPreferences.updateFields.images
                                    }
                                  />
                                </AppStack>
                              ),
                            },
                          ]}
                        />
                      </div>
                    ),
                  }),
                }
              : o,
          )}
          name={'preferences.product.updateOption'}
        />
      </AppCard>
      <AppCard sectioned title="Discontinued Products">
        <p style={{ padding: '0 0 1.5rem' }}>
          Automatically update your product listing when a product is discontinued by its' supplier
        </p>
        <AppControlledRadioGroup
          optionsDesc={productDiscontinuationOptionsConstants}
          name={'preferences.product.discontinuationOption'}
        />
      </AppCard>
      <AppCard title="Product Price Logic" sectioned>
        <p style={{ padding: '0 0 1.5rem' }}>
          Set your default pricing logic. Crowdship will pre-calculate Retail Prices when importing
          products using your selection, but you can override this option for each SKU at any time.
        </p>
        <AppControlledRadioGroup
          optionsDesc={productSalePriceOptionsConstants.map((o) =>
            o.value === SALE_PRICE_OPTION.MSRP
              ? {
                  ...o,
                  disabled:
                    unsavedPreferences.compareAtPriceOption === COMPARE_AT_PRICE_OPTION.MSRP,
                }
              : {
                  ...o,
                  helpText: (
                    <>
                      <div className="narrow-text-field">
                        <AppStack vertical>
                          <AppControlledTextField
                            name={'preferences.product.defaultMarkup'}
                            suffix={'%'}
                            helpText={
                              'When importing products to Crowdship, you can choose individual markup for each product, but this will be used as the default.'
                            }
                            validate={validation.isNumber}
                          />
                        </AppStack>
                      </div>
                    </>
                  ),
                },
          )}
          name={'preferences.product.salePriceOption'}
        />
      </AppCard>
      <AppCard sectioned title="My compare at price is set as">
        <AppControlledRadioGroup
          optionsDesc={productCompareAtPriceOptionsConstants.map((o) => {
            switch (o.value) {
              case COMPARE_AT_PRICE_OPTION.NOT_SET:
                return o;
              case COMPARE_AT_PRICE_OPTION.MSRP:
                return {
                  ...o,
                  disabled: unsavedPreferences.salePriceOption === SALE_PRICE_OPTION.MSRP,
                };
              case COMPARE_AT_PRICE_OPTION.MARK_UP_FROM_SALE_PRICE:
                return {
                  ...o,
                  helpText: (
                    <div className="narrow-text-field">
                      <AppControlledTextField
                        name={'preferences.product.compareAtPriceMarkup'}
                        suffix={'%'}
                        type="number"
                        placeholder={'50'}
                        min={0}
                        validate={
                          unsavedPreferences.compareAtPriceOption !==
                          COMPARE_AT_PRICE_OPTION.MARK_UP_FROM_SALE_PRICE
                            ? undefined
                            : validation.isNumber
                        }
                        disabled={
                          unsavedPreferences.compareAtPriceOption !==
                          COMPARE_AT_PRICE_OPTION.MARK_UP_FROM_SALE_PRICE
                        }
                      />
                    </div>
                  ),
                };
              default:
                return o;
            }
          })}
          name={'preferences.product.compareAtPriceOption'}
        />
      </AppCard>
    </AppLayoutAnnotatedSection>
  );
};
