import validator from 'validator';
import { USA_COUNTRY_CODE } from '../constants/carrier-service.constants';

class Validation {
  isNotEmpty = (value: string) => (value ? undefined : 'Required');

  isCountryCode = (value: string) => {
    if (!value) return 'Required';
    if (!value.match(/^[A-Z]{2}$/g)) return 'Not a valid county code';
  };

  isOptionalNumber = (value: string) => {
    if (!value) return;
    if (!validator.isNumeric(value.toString())) return 'Value must be a number';
  };

  isDecimal = (value: string) => {
    if (!value) return;
    if (!value.match('^[0-9]*.[0-9]{2}$'))
      return 'Must be a number with two decimal places, e.g. 0.99, 1.10';
  };

  isNumber = (value: string, options?: { min?: number; max?: number; noSymbols?: boolean }) => {
    if (!value) return 'Required';
    if (!validator.isNumeric(value.toString(), { no_symbols: options?.noSymbols }))
      return 'Value must be a number';

    if (!options) return;
    if (options.min !== undefined && parseFloat(value) < options.min)
      return `Value must not be less than ${options.min}`;

    if (options.max !== undefined && parseFloat(value) > options.max)
      return `Value must not be greater than ${options.max}`;
  };

  isPositiveNumber = (value: string) => {
    if (!value) return 'Required';
    if (!validator.isNumeric(value.toString())) return 'Value must be a number';
    if (parseFloat(value) <= 0) return 'Value must be greater than 0';
  };

  isEmail = (value: string) =>
    !validator.isEmail(value) ? 'Not a valid email address' : undefined;

  isPhoneNumber = (value: string) => {
    if (!value) return 'Required';
    if (!validator.isMobilePhone(value)) return 'Not a valid phone number';
  };

  isOptionalPhoneNumber = (value: string) => {
    if (
      !value ||
      validator.isMobilePhone(`+${USA_COUNTRY_CODE}${value}`, 'any', { strictMode: true }) ||
      validator.isMobilePhone(`+${value}`, 'any', { strictMode: true })
    )
      return;

    return 'Not a valid phone number';
  };

  isOptionalTaxID = (value: string) => {
    if (!value) return;
    if (value.match('^\\d{2}-?\\d{7}$')) return;
    return 'Not a valid Tax ID';
  };

  isUnique = (value: string, values: string[]) =>
    values.filter((v) => v === value).length > 1 ? 'Value must be unique' : undefined;

  isOrderNamePrefix = (value: string) => {
    if (!value) return;
    if (value.match('^[a-zA-Z0-9#-]+$')) return;
    return 'Prefix must include only A-z, 0-9, -, #';
  };

  notContainedEmptyTags = (value: string) => {
    if (!value) return;
    const newTags = value?.slice(-1) === ',' ? value.slice(0, value.length - 2) : value;
    if (newTags?.split(',').some((tag) => !tag.trim()))
      return 'Please provide a tag or list of tags similar to “Tag 1, tag-2, tag3”';
    return;
  };

  isPostalCode = (zip: string, countryCode: validator.PostalCodeLocale) => {
    if (!zip) return 'Required';
    const localeToCheck = validator.isPostalCodeLocales.includes(countryCode) ? countryCode : 'any';
    if (validator.isPostalCode(zip, localeToCheck)) return;
    return 'Zip/Postal code is wrong';
  };
}

export const validation = new Validation();
