import { useState } from 'react';
import { AppTextField } from '../../../../core/components/forms/text-field/text-field';
import { AppModal } from '../../../../core/components/overlays/modal/modal';
import { AppTextStyle } from '../../../../core/components/text/text-style/TextStyle';
import { withCurrency } from '../../../../core/helpers/currency.helper';
import validator from 'validator';

type CreateRefundModalProps = {
  orderName: string;
  availableForRefund: number;
  open: boolean;
  onCreate: (amount: number, reason: string) => void;
  onError: (error: string) => void;
  onClose: () => void;
};

export const CreateRefundModal: React.FC<CreateRefundModalProps> = ({
  orderName,
  availableForRefund,
  open,
  onClose,
  onCreate,
  onError,
}) => {
  const [refundAmount, setRefundAmount] = useState('');
  const [reason, setReason] = useState('');

  return (
    <AppModal
      open={open}
      onClose={onClose}
      title={`Refund Order ${orderName}`}
      primaryAction={{
        content: 'Confirm refund',
        onAction: () => {
          const error = validateRefundAmount(refundAmount, availableForRefund);
          if (error) {
            onError(error);
            return;
          }
          onCreate(+refundAmount, reason);
        },
      }}
    >
      Refund Method:&nbsp;<AppTextStyle variation="strong">Manual</AppTextStyle>
      <div className="comment-input">
        <AppTextField
          label=""
          value={reason}
          onChange={setReason}
          id="refund-reason-comment"
          autoComplete="off"
          placeholder="Enter a reason for the refund"
        />
      </div>
      <div className="refund-input">
        <AppTextField
          label="Refund Amount:"
          value={refundAmount}
          onChange={setRefundAmount}
          id="refund-amount"
          autoComplete="off"
          prefix="$"
        />
      </div>
    </AppModal>
  );
};

const validateRefundAmount = (value: string, availableForRefund: number): string | undefined => {
  const validNumber = validator.isNumeric(value);
  const numValue = Number.parseFloat(value);

  if (!validNumber) {
    return 'Refund amount must be a positive number';
  }
  if (numValue < 0.01) {
    return 'Refund amount must be at least 0.01';
  }
  if (numValue > availableForRefund)
    return `Refund amount must be less than total minus refunds [${withCurrency(
      availableForRefund,
    )}]`;
};
