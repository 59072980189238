import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { MarketplaceProductList } from '../../../../core/components/product-list/marketplace-product-list';
import { PRODUCTS_PER_PAGE } from '../../../../core/constants/product.constants';
import { getNumberOfPages } from '../../../../core/helpers/item-list.helper';
import { marketplaceApi } from '../../../api/marketplace.api';
import {
  addAllMarketplaceProductsToImportListAction,
  addMarketplaceProductsToImportListAction,
  getMarketplaceProductVariantsAction,
  setMarketplaceProductListLimitFilterAction,
  setMarketplaceProductListPageFilterAction,
} from '../../../redux/modules/marketplace/marketplace.actions';
import {
  fetchingMarketplaceProductViewSelector,
  getMarketplaceProductsSelector,
  getMarketplaceProductViewFiltersSelector,
  getTotalMarketplaceProductsCountSelector,
} from '../../../redux/modules/marketplace/marketplace.selectors';
import { getStatusSelector } from '../../../redux/modules/status/status.selectors';
import { MarketplaceVariantList } from '../../containers/variant-list/marketplace-variant-list';
import { DuplicateProductsModal } from '../../modals/duplicate-products-modal/duplicate-products-modal';

export const MarketplaceProductListLayout = () => {
  const dispatch = useDispatch();
  const status = useSelector(getStatusSelector);
  const products = useSelector(getMarketplaceProductsSelector);
  const total = useSelector(getTotalMarketplaceProductsCountSelector);
  const fetching = useSelector(fetchingMarketplaceProductViewSelector);
  const { page: currentPage } = useSelector(getMarketplaceProductViewFiltersSelector);

  const [isOpenDuplicateModal, setIsOpenDuplicateModal] = useState(false);

  const [selectedProducts, setSelectedProducts] = useState<string[]>([]);
  const [pendingSelectedProducts, setPendingSelectedProducts] = useState<string[]>([]);
  const [allProductsSelected, setAllProductsSelected] = useState<boolean>(false);

  const disableAdding = useMemo(
    () =>
      !!status &&
      status.productsLimit !== undefined &&
      status.productsLimit <= status.productsCount, //+ selectedProducts.length,
    [status],
  );

  useEffect(() => {
    if (!fetching) {
      setIsOpenDuplicateModal(false);
      setSelectedProducts([]);
      setAllProductsSelected(false);
    }
  }, [fetching]);

  useEffect(() => {
    dispatch(setMarketplaceProductListLimitFilterAction({ limit: PRODUCTS_PER_PAGE }));
  }, [dispatch]);

  const handlePageChange = useCallback(
    (page) => dispatch(setMarketplaceProductListPageFilterAction({ page })),
    [dispatch],
  );

  const handleAddToImportList = useCallback(() => {
    marketplaceApi
      .checkImportListProducts(selectedProducts)
      .then(({ data }) => {
        if (
          (products.some((p) => selectedProducts.includes(p.id)) && data.isAdded) ||
          allProductsSelected
        ) {
          setPendingSelectedProducts(selectedProducts);
          setIsOpenDuplicateModal(true);
        } else dispatch(addMarketplaceProductsToImportListAction(selectedProducts, false));
      })
      .catch(console.error);
  }, [dispatch, products, selectedProducts, allProductsSelected]);

  const postDuplicatesProductsToImportList = useCallback(
    (skip: boolean) => {
      if (allProductsSelected) {
        dispatch(addAllMarketplaceProductsToImportListAction(skip));
        return;
      }

      dispatch(addMarketplaceProductsToImportListAction(pendingSelectedProducts, skip));
    },
    [dispatch, allProductsSelected, pendingSelectedProducts],
  );

  const renderVariantList = useCallback((productId: string) => {
    return <MarketplaceVariantList productId={productId} />;
  }, []);

  const handleVariantsExpand = useCallback(
    (productId: string) => {
      dispatch(getMarketplaceProductVariantsAction(productId));
    },
    [dispatch],
  );

  return (
    <>
      <MarketplaceProductList
        products={products}
        addToImportList={handleAddToImportList}
        onSelectionChange={setSelectedProducts}
        allSelectedToggle={setAllProductsSelected}
        onProductVariantsExpand={handleVariantsExpand}
        renderVariantList={renderVariantList}
        totalPageCount={getNumberOfPages(total)}
        onPageChange={handlePageChange}
        fetching={fetching}
        disableAdding={disableAdding}
        disableSubmit={disableAdding}
        currentPage={currentPage}
      />
      <DuplicateProductsModal
        open={isOpenDuplicateModal}
        onClose={() => setIsOpenDuplicateModal(false)}
        postDuplicatesProductsToImportList={postDuplicatesProductsToImportList}
      />
    </>
  );
};
