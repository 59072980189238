import { AxiosResponse } from 'axios';
import { axiosInstance } from '../../core/api/base.api';
import { Paginated } from '../../core/helpers/generic.helper';
import { IOption } from '../../core/interfaces/IOption';
import { IProductList, ISupplierProduct } from '../../core/interfaces/IProduct';
import { ISupplierProductsListFilter } from '../../core/interfaces/IProductFilter';
import { ISupplierVariant } from '../interfaces/IProduct';

class ProductsApi {
  path: string = '/api/supplier/shopify/products';

  async getProducts(
    filters: Paginated<ISupplierProductsListFilter>,
  ): Promise<AxiosResponse<IProductList<ISupplierProduct>>> {
    return await axiosInstance.get(`${process.env.REACT_APP_BACK_END_URL}${this.path}`, {
      params: {
        ...filters,
        title: filters.query || undefined,
        retailerGroups: filters.groups?.length ? filters.groups.join() : undefined,
        vendor: filters.vendors?.length ? filters.vendors.join() : undefined,
        types: filters.types?.length ? filters.types.join() : undefined,
        shopifyStatus: filters.shopifyStatus?.length ? filters.shopifyStatus.join() : undefined,
      },
    });
  }

  async addProductsToMarketplace(
    productIds: string[],
  ): Promise<AxiosResponse<IUpdateProductRetailAvailabilityResult>> {
    return await axiosInstance.put(
      `${process.env.REACT_APP_BACK_END_URL}${this.path}/retail-availability`,
      {
        productIds,
      },
    );
  }

  async addAllProductsToMarketplace(
    filters: ISupplierProductsListFilter,
  ): Promise<AxiosResponse<IUpdateProductRetailAvailabilityResult>> {
    const { query, vendors, types, groups } = filters;
    return await axiosInstance.put(
      `${process.env.REACT_APP_BACK_END_URL}${this.path}/retail-availability/all`,
      {
        ...filters,
        title: query || undefined,
        vendors,
        groups,
        types,
      },
    );
  }

  async updateAvailableGroups(
    productIds: string[],
    retailerGroups: string[],
  ): Promise<AxiosResponse<IUpdateProductRetailAvailabilityResult>> {
    return await axiosInstance.put(
      `${process.env.REACT_APP_BACK_END_URL}${this.path}/retailers-groups`,
      {
        productIds,
        retailerGroups,
      },
    );
  }

  async updateAvailableGroupsForAllProducts(
    retailerGroups: string[],
    filters: ISupplierProductsListFilter,
  ): Promise<AxiosResponse<IUpdateProductRetailAvailabilityResult>> {
    return await axiosInstance.put(
      `${process.env.REACT_APP_BACK_END_URL}${this.path}/retailers-groups/all`,
      {
        filters,
        retailerGroups,
      },
    );
  }

  async getProductVariants(id: string): Promise<AxiosResponse<ISupplierVariant[]>> {
    return await axiosInstance.get(
      `${process.env.REACT_APP_BACK_END_URL}${this.path}/${id}/variants`,
    );
  }

  async getProductsVendor(): Promise<string[]> {
    return await axiosInstance.get(`${process.env.REACT_APP_BACK_END_URL}${this.path}/vendors`);
  }

  async getProductsTypes(): Promise<string[]> {
    return await axiosInstance.get(`${process.env.REACT_APP_BACK_END_URL}${this.path}/types`);
  }

  async getProductsGroups(): Promise<IOption[]> {
    return await axiosInstance.get(`${process.env.REACT_APP_BACK_END_URL}${this.path}/groups`);
  }

  async updateVariantsPriceForRetailers(
    productId: string,
    variantId: string,
    priceForRetailers: number,
  ): Promise<AxiosResponse<IVariantWithProductId>> {
    return await axiosInstance.put(
      `${process.env.REACT_APP_BACK_END_URL}${this.path}/${productId}/variants/${variantId}/price-for-retailers`,
      { priceForRetailers },
    );
  }

  async updateVariantsMSRP(
    productId: string,
    variantId: string,
    MSRP: number,
  ): Promise<AxiosResponse<IVariantWithProductId>> {
    return await axiosInstance.put(
      `${process.env.REACT_APP_BACK_END_URL}${this.path}/${productId}/variants/${variantId}/MSRP`,
      { MSRP },
    );
  }

  async uploadCsvForPriceUpdates(body: FormData) {
    return await axiosInstance.post(
      `${process.env.REACT_APP_BACK_END_URL}${this.path}/bulk-update-csv`,
      body,
    );
  }

  async exportCSVProducts(productIds: string[]) {
    return await axiosInstance.post(
      `${process.env.REACT_APP_BACK_END_URL}${this.path}/export-csv`,
      { productIds },
    );
  }

  async exportAllCSVProducts(filters: ISupplierProductsListFilter) {
    return await axiosInstance.post(
      `${process.env.REACT_APP_BACK_END_URL}${this.path}/export-csv/all`,
      {
        ...filters,
        title: filters.query || undefined,
        retailerGroups: filters.groups?.length ? filters.groups.join() : undefined,
        vendor: filters.vendors?.length ? filters.vendors.join() : undefined,
        types: filters.types?.length ? filters.types.join() : undefined,
        shopifyStatus: filters.shopifyStatus?.length ? filters.shopifyStatus.join() : undefined,
      },
    );
  }
}

export const productsApi = new ProductsApi();

export interface IChangedVariantsPrices {
  id: string;
  variantId: string;
  priceForRetailers: number;
}

export type IFoundUpdateProductRetailAvailabilityResult = {
  success: string[];
  failure: IFailureProduct[];
};

export type IUpdateProductRetailAvailabilityResult =
  | { notFound: boolean }
  | { updatingPriceMappingPreference: boolean }
  | IFoundUpdateProductRetailAvailabilityResult;

export interface IFailureProduct {
  id: string;
  title: string;
  reason: FAILURE_REASON[];
}

export enum FAILURE_REASON {
  NO_SKU,
  NO_MSRP,
  NO_PRICE_FOR_RETAILERS,
}

export interface IVariantWithProductId extends ISupplierVariant {
  productId: string;
}
