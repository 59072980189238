import React, { useMemo } from 'react';
import { IRetailerOrdersSummary } from '../../../../interfaces/IOrder';
import { AppList } from '../../../../../core/components/list/list';
import { getLocaleQuantity } from '../../../../../core/helpers/quantity.helper';
import { withCurrency } from '../../../../../core/helpers/currency.helper';

interface IOrdersSummaryProps extends IRetailerOrdersSummary {}

export function OrdersSummary({
  totalOrdersCount,
  unfulfilledOrdersCount,
  totalEarned,
}: IOrdersSummaryProps) {
  const summary = useMemo(
    () => [
      `Total: ${getLocaleQuantity(totalOrdersCount)}`,
      `Unfulfilled: ${getLocaleQuantity(unfulfilledOrdersCount)}`,
      `Total earned: ${withCurrency(totalEarned)}`,
    ],
    [totalOrdersCount, unfulfilledOrdersCount, totalEarned],
  );

  return (
    <>
      <AppList type="bullet">{summary}</AppList>
    </>
  );
}
