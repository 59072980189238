import { Form, Formik } from 'formik';
import React, { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { AppDataTable } from '../../../../core/components/data-table/DataTable';
import { AppThumbnail } from '../../../../core/components/image-containers/thumbnail/Thumbnail';
import { AppTextStyle } from '../../../../core/components/text/text-style/TextStyle';
import { TrackedQuantityLabelForVariant } from '../../../../core/components/tracked-quantity-label-for-variant/tracked-quantity-label-for-variant';
import { withCurrency } from '../../../../core/helpers/currency.helper';
import { IMarketplaceVariant } from '../../../interfaces/IProduct';
import { getMarketplaceProductVariantsSelector } from '../../../redux/modules/marketplace/marketplace.selectors';
import { RootState } from '../../../redux/reducers';
import './variant-list.scss';

type IVariantListProps = {
  productId: string;
};

export function MarketplaceVariantList({ productId }: IVariantListProps) {
  const variants = useSelector((state: RootState) =>
    getMarketplaceProductVariantsSelector(state as any, productId),
  );

  const rows = useCallback(
    (values: IMarketplaceVariant[]) => [
      ...variants.map((v, i) => {
        return [
          <div className="variant-title">{v.title}</div>,
          <AppThumbnail alt={v.title} source={v.image} />,
          <TrackedQuantityLabelForVariant
            quantity={v.qty}
            tracked={v.tracked}
            inventoryPolicy={v.inventoryPolicy}
          />,
          <div className="variant-supplier-price">{withCurrency(v.priceForRetailers)}</div>,
          <div className="variant-supplier-price">{withCurrency(v.MSRP)}</div>,
          <AppTextStyle variation={v.margin && v.margin > 0 ? 'positive' : 'negative'}>
            {(v.margin !== null ? v.margin : '--') + '%'}
          </AppTextStyle>,
          <div className="variant-sku">{v.sku}</div>,
        ];
      }),
    ],
    [variants],
  );

  return (
    <Formik initialValues={variants} onSubmit={() => {}} enableReinitialize>
      {({ values }) => (
        <Form name={`variants-${productId}`}>
          <AppDataTable
            columnContentTypes={[
              'text',
              'text',
              'numeric',
              'numeric',
              'numeric',
              'numeric',
              'numeric',
            ]}
            headings={['Variant', 'Picture', 'Qty', 'Cost', 'SRP', 'Retailer Margin', 'SKU']}
            rows={rows(values)}
          />
        </Form>
      )}
    </Formik>
  );
}
