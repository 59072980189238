import { ReactNode } from 'react';
import { SpinnerReplica } from '../../components/feedback-indicators/spinner/spinner-replica';
import './loading-layout.scss';

type LoadingLayoutProps = { text?: ReactNode };

export const LoadingLayout = ({
  text = 'Crowdship is authenticating your request...',
}: LoadingLayoutProps) => (
  <div className="loading-layout">
    <SpinnerReplica />
    <p className="Polaris-TextStyle--variationCode">{text}</p>
  </div>
);
