import { createSelector } from 'reselect';
import { RootState } from '../../reducers';

export const getSyncResultSelector = createSelector(
  [(state: RootState) => state.productSync],
  (productSyncReducer) => productSyncReducer.syncResult,
);

export const getVendorsForMatchesSelector = createSelector(
  [(state: RootState) => state.productSync],
  (productSyncReducer) => productSyncReducer.vendorsForMatches,
);

export const showSuccessLinkToastSelector = createSelector(
  [(state: RootState) => state.productSync],
  (productSyncReducer) => productSyncReducer.linkToast,
);

export const getSyncResultFetchingConflictsSelector = createSelector(
  [(state: RootState) => state.productSync],
  (productSyncReducer) => productSyncReducer.fetchingConflicts,
);

export const getSyncResultFetchingMatchesSelector = createSelector(
  [(state: RootState) => state.productSync],
  (productSyncReducer) => productSyncReducer.fetchingMatches,
);

export const getSyncResultFetchingDisconnectedProductsSelector = createSelector(
  [(state: RootState) => state.productSync],
  (productSyncReducer) => productSyncReducer.fetchingDisconnectedProducts,
);

export const showSyncLimitExceededBannerSelector = createSelector(
  [(state: RootState) => state.productSync],
  (productSyncReducer) => productSyncReducer.showLimitExceededBanner,
);
