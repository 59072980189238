import { useCallback, useEffect, useState } from 'react';
import { Country, countryApi } from '../../../../../../core/api/country.api';
import { AppButton } from '../../../../../../core/components/button/Button';
import { AppDataTable } from '../../../../../../core/components/data-table/DataTable';
import { AppControlledCheckbox } from '../../../../../../core/components/forms/controlled-checkbox/controlled-checkbox';
import { AppControlledTextField } from '../../../../../../core/components/forms/controlled-text-field/controlled-text-field';
import { AppControlledRadioGroup } from '../../../../../../core/components/forms/radio/controlled-radio-group/controlled-radio-group';
import { AppCard } from '../../../../../../core/components/structure/card/card';
import { AppLayoutAnnotatedSection } from '../../../../../../core/components/structure/layout/layout-annotated-section';
import { AppTextContainer } from '../../../../../../core/components/text-container/text-container';
import { AppTextStyle } from '../../../../../../core/components/text/text-style/TextStyle';
import { carrierServiceConstants } from '../../../../../../core/constants/carrier-service.constants';
import { validation } from '../../../../../../core/helpers/validations.helper';
import {
  PACKING_SLIP_FORMAT,
  postageSetupOptions,
  shippingMappingOptions,
} from '../../../../../constants/preferences.constants';
import {
  AVAILABLE_RATES,
  IPreferences,
  ISupplierShippingTypesMapping,
} from '../../../../../interfaces/IPreferences';
import './shipping-section.scss';
import { CustomAddressForm } from './custom-address';

type ShippingSectionProps = {
  values: IPreferences;
  setFieldValue: (field: string, value: any) => void;
};

export const ShippingSection = ({ values, setFieldValue }: ShippingSectionProps) => {
  const getCarrierServiceLabel = useCallback((key: string | undefined) => {
    if (!key) return '';
    const [carrier, service] = key.split('.');
    return carrier + ' ' + carrierServiceConstants[carrier][service].name;
  }, []);

  const [countries, setCountries] = useState<Country[]>([]);

  const [advancedMappingOptionsExpanded, setAdvancedMappingOptionsExpanded] = useState(false);

  useEffect(() => {
    countryApi.getCountries().then(({ data: result }) => {
      setCountries(result.data);
    });
  }, []);

  const getDefaultDays = useCallback(
    (
      shippingType: ISupplierShippingTypesMapping,
    ): { startDeliveryTime: number; finishDeliveryTime: number } => {
      switch (shippingType.shippingType) {
        case 'Standard Domestic':
          return {
            startDeliveryTime: 5,
            finishDeliveryTime: 9,
          };
        case 'Expedited Domestic':
          return {
            startDeliveryTime: 2,
            finishDeliveryTime: 4,
          };
        case 'Standard International':
          return {
            startDeliveryTime: 7,
            finishDeliveryTime: 21,
          };
        case 'Expedited International':
          return {
            startDeliveryTime: 2,
            finishDeliveryTime: 14,
          };
      }
    },
    [],
  );

  return (
    <>
      <AppLayoutAnnotatedSection title="Shipping Location">
        <AppCard sectioned title="Return address">
          <AppTextStyle>
            The address that shows on your shipping labels as the “return address”.
          </AppTextStyle>
          <br />
          <AppTextStyle variation="subdued">
            Note: only supported by US carriers USPS, UPS, FedEx.
          </AppTextStyle>
          <br />
          <AppControlledRadioGroup
            optionsDesc={[
              {
                value: 'use default',
                label: (
                  <AppTextStyle variation="strong">
                    Use the Ship From location as the return address
                  </AppTextStyle>
                ),
              },
              {
                value: 'use custom',
                label: (
                  <AppTextStyle variation="strong">Specify a custom return address</AppTextStyle>
                ),
              },
            ]}
            name="shipping.useCustomWarehouseAddress"
          />
          {values.shipping.useCustomWarehouseAddress === 'use custom' && (
            <CustomAddressForm
              values={values}
              countries={countries}
              setFieldValue={setFieldValue}
              addressType="warehouseAddress"
              disableCountrySelect
            />
          )}
        </AppCard>
        <AppCard sectioned title="Fallback address">
          <AppTextStyle>
            The address will be used if the location set to a product's inventory does not have an
            address.
          </AppTextStyle>
          <AppControlledRadioGroup
            optionsDesc={[
              {
                value: 'use default',
                label: (
                  <AppTextStyle variation="strong">
                    Use my billing address as the origin address
                  </AppTextStyle>
                ),
              },
              {
                value: 'use custom',
                label: (
                  <AppTextStyle variation="strong">Specify a custom fallback address</AppTextStyle>
                ),
              },
            ]}
            name="shipping.useCustomFallbackAddress"
          />
          {values.shipping.useCustomFallbackAddress === 'use custom' && (
            <CustomAddressForm
              values={values}
              countries={countries}
              setFieldValue={setFieldValue}
              addressType="fallbackAddress"
            />
          )}
        </AppCard>
      </AppLayoutAnnotatedSection>
      <AppLayoutAnnotatedSection title="Shipping Cost">
        <AppCard sectioned title="Postage setup">
          <AppControlledRadioGroup optionsDesc={postageSetupOptions} name="shipping.postageSetup" />
        </AppCard>
        <AppCard sectioned title="Shipping mapping">
          <AppTextContainer>
            Select which shipping service levels you are able to offer
          </AppTextContainer>
          <br />
          {values.shipping.shippingTypesMapping.map((sTM, index) => (
            <div className="shipping-mapping">
              <div className="shipping-mapping-radio">
                <AppTextStyle variation="strong">{sTM.shippingType}</AppTextStyle>
                <AppControlledRadioGroup
                  onChange={(value) => {
                    if (value === AVAILABLE_RATES.FREE) {
                      if (!sTM.startDeliveryTime) {
                        setFieldValue(
                          `shipping.shippingTypesMapping[${index}].startDeliveryTime`,
                          getDefaultDays(sTM).startDeliveryTime.toString(),
                        );
                      }
                      if (!sTM.finishDeliveryTime) {
                        setFieldValue(
                          `shipping.shippingTypesMapping[${index}].finishDeliveryTime`,
                          getDefaultDays(sTM).finishDeliveryTime.toString(),
                        );
                      }
                    }
                  }}
                  optionsDesc={shippingMappingOptions.map((sMO) => {
                    switch (sMO.value) {
                      case AVAILABLE_RATES.FREE:
                        return {
                          ...sMO,
                          label: (
                            <AppTextStyle variation="strong">{`Free ${
                              sTM.shippingType.split(' ')[0]
                            } shipping`}</AppTextStyle>
                          ),
                          ...(sTM.availableRates === AVAILABLE_RATES.FREE && {
                            helpText: (
                              <div className="free-shipping-options">
                                <AppControlledTextField
                                  name={`shipping.shippingTypesMapping[${index}].startDeliveryTime`}
                                  placeholder={`${getDefaultDays(sTM).startDeliveryTime}`}
                                  validate={validation.isPositiveNumber}
                                  label="Fastest"
                                  suffix="d"
                                />
                                <div className="dash"></div>
                                <AppControlledTextField
                                  name={`shipping.shippingTypesMapping[${index}].finishDeliveryTime`}
                                  placeholder={`${getDefaultDays(sTM).finishDeliveryTime}`}
                                  validate={validation.isPositiveNumber}
                                  label="Slowest"
                                  suffix="d"
                                />
                                <AppControlledTextField
                                  name={`shipping.shippingTypesMapping[${index}].customShippingTypeName`}
                                  placeholder={`Free ${sTM.shippingType} shipping`}
                                  label="Custom name"
                                />
                              </div>
                            ),
                          }),
                        };
                      case AVAILABLE_RATES.FLAT_RATE:
                        return {
                          ...sMO,
                          ...(sTM.availableRates === AVAILABLE_RATES.FLAT_RATE && {
                            helpText: (
                              <div
                                className="narrow-text-field"
                                onClick={(e) => e.stopPropagation()}
                              >
                                <AppControlledTextField
                                  name={`shipping.shippingTypesMapping[${index}].flatRateCost`}
                                  prefix={'$'}
                                  placeholder={'0'}
                                  disabled={sTM.availableRates !== AVAILABLE_RATES.FLAT_RATE}
                                  validate={validation.isDecimal}
                                />
                              </div>
                            ),
                          }),
                        };
                      default:
                        return sMO;
                    }
                  })}
                  name={`shipping.shippingTypesMapping[${index}].availableRates`}
                />
              </div>
            </div>
          ))}
        </AppCard>
        <AppCard
          sectioned
          title={advancedMappingOptionsExpanded ? 'Advanced Mapping Settings' : ''}
        >
          <AppButton
            onClick={() => setAdvancedMappingOptionsExpanded(!advancedMappingOptionsExpanded)}
          >
            {advancedMappingOptionsExpanded ? 'Hide' : 'Show'} advanced Mapping Settings
          </AppButton>

          {advancedMappingOptionsExpanded && (
            <AppDataTable
              columnContentTypes={['text', 'text']}
              headings={['Crowdship carrier service', 'Custom service name']}
              rows={values.shipping.mapping.map((service, index) => [
                <AppControlledCheckbox
                  name={`shipping.mapping[${index}].selected`}
                  label={getCarrierServiceLabel(service.crowdshipCarrierServiceKey)}
                />,
                <AppControlledTextField
                  name={`shipping.mapping[${index}].customCarrierServiceName`}
                  placeholder={getCarrierServiceLabel(service.crowdshipCarrierServiceKey)}
                  label=""
                />,
              ])}
            />
          )}
        </AppCard>
      </AppLayoutAnnotatedSection>
      <AppLayoutAnnotatedSection title="Packing Slip Format">
        <AppCard sectioned title="Packing slip format">
          <AppTextContainer>
            Select the format and dimension you want to use for packing slips (this should match
            your packing slip printer’s ideal print size)
          </AppTextContainer>
          <AppControlledRadioGroup
            optionsDesc={[
              {
                value: PACKING_SLIP_FORMAT.A4,
                label: <AppTextStyle variation="strong">8.5 x 11 in.</AppTextStyle>,
              },
              {
                value: PACKING_SLIP_FORMAT['4x6'],
                label: <AppTextStyle variation="strong">4 x 6 in.</AppTextStyle>,
              },
            ]}
            name="shipping.packingSlipFormat"
          />
        </AppCard>
      </AppLayoutAnnotatedSection>
      <AppLayoutAnnotatedSection title="Handling Fee">
        <AppCard sectioned title="Handling Fee">
          <AppTextContainer>Add a "per order" fee to each Purchase Order</AppTextContainer>
          <AppControlledTextField
            name="shipping.handlingFee"
            prefix="$"
            validate={validation.isDecimal}
          />
          <AppTextContainer>
            <AppTextStyle variation="subdued">
              Note: if enabled, this will create a product in your Shopify store to include the fee
              cost in your future Shopify orders.
            </AppTextStyle>
          </AppTextContainer>
        </AppCard>
      </AppLayoutAnnotatedSection>
    </>
  );
};
