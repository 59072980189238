import { AppBanner } from '../../feedback-indicators/banner/banner';
import './account-not-synced-banner.scss';

export const AccountNotSyncedBanner = () => (
  <div className="account-not-synced-banner">
    <AppBanner status="info" title="Account info is not yet synced">
      We're fetching your account info from your Shopify store to pre-fill it. Try reloading this
      page to see if synchronization was completed.
    </AppBanner>
  </div>
);
