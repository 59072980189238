import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { useParams } from 'react-router-dom';
import { AppSkeletonBodyText } from '../../../../core/components/feedback-indicators/skeleton/skeleton-body-text/skeleton-body-text';
import { AppSkeletonPage } from '../../../../core/components/feedback-indicators/skeleton/skeleton-page/skeleton-page';
import { AppToast } from '../../../../core/components/feedback-indicators/toast/toast';
import { DateTooltipUTC } from '../../../../core/components/overlays/date-tooltip-utc/date-tooltip-utc';
import { AppCard } from '../../../../core/components/structure/card/card';
import { AppPage } from '../../../../core/components/structure/page/page';
import { getFullFormattedDate, getUTCDate } from '../../../../core/helpers/date.helper';
import { getShopSelector } from '../../../../core/redux/modules/auth/auth.selectors';
import { ordersApi } from '../../../api/orders.api';
import { IRetailerPurchaseOrderDetails } from '../../../interfaces/IOrder';
import { OrderStatus } from '../../containers/order/common/order-status/order-status';
import { PurchaseOrderDetails } from '../../containers/order/purchase-order-details/purchase-order-details';
import './purchase-order-details-layout.scss';

export const PurchaseOrderDetailsLayout = () => {
  const history = useHistory();
  const shop = useSelector(getShopSelector);
  const { id: purchaseOrderId } = useParams<{ id: string }>();
  const [fetching, setFetching] = useState(false);
  const [notFound, setNotFound] = useState(false);
  const [details, setDetails] = useState<IRetailerPurchaseOrderDetails | null>(null);

  useEffect(() => {
    setFetching(true);
    ordersApi
      .getPurchaseOrderDetails(purchaseOrderId)
      .then(({ data }) => {
        setDetails(data);
      })
      .catch((error) => {
        console.error(error);
        if (error.response.status === 404) {
          setNotFound(true);
          setTimeout(() => history.push('/orders'), 1000);
        }
      })
      .finally(() => {
        setFetching(false);
      });
  }, [history, purchaseOrderId]);

  if (!details || fetching)
    return (
      <AppSkeletonPage>
        <AppCard sectioned>
          <AppSkeletonBodyText />
        </AppCard>
        <AppCard sectioned>
          <AppSkeletonBodyText />
        </AppCard>
        {notFound && (
          <AppToast error onDismiss={() => setNotFound(false)} content="Purchase order not found" />
        )}
      </AppSkeletonPage>
    );

  return (
    <div className="purchase-order-details-layout">
      <AppPage
        title={`PO ${details.name}`}
        breadcrumbs={[{ content: 'Purchase orders', url: '/orders?tab=purchase-orders' }]}
        titleMetadata={<OrderStatus status={details.status} />}
        secondaryActions={[
          ...(details.salesOrderShopifyId
            ? [
                {
                  content: 'View on Shopify',
                  onAction: () => {
                    window.open(`https://${shop}/admin/orders/${details.salesOrderShopifyId}`);
                  },
                },
              ]
            : []),
          {
            content: 'View Sales Order',
            onAction: () => history.push(`/orders/${details.salesOrderId}`),
          },
        ]}
      >
        <DateTooltipUTC
          date={details.createdAt}
          className="order-date"
          dateFormatter={getFullFormattedDate}
          tooltipFormatter={getUTCDate}
        />
        <PurchaseOrderDetails details={details} />
      </AppPage>
    </div>
  );
};
