import { createAction } from 'typesafe-actions';

import { IDashboardSalesSummary, IDashboardOrdersSummary } from '../../../interfaces/IDashboard';
import { ACTIONS_CONSTANTS } from '../../actions-constants';

export const getSalesSummaryAction = createAction(ACTIONS_CONSTANTS.GET_SALES_SUMMARY)();
export const getOrdersSummaryAction = createAction(
  ACTIONS_CONSTANTS.DASHBOARD_GET_ORDERS_SUMMARY,
)();
export const setFetchingAction = createAction(
  ACTIONS_CONSTANTS.SET_DASHBOARD_FETCHING,
  (fetchingStatus: boolean) => fetchingStatus,
)();
export const setSalesSummaryAction = createAction(
  ACTIONS_CONSTANTS.SET_SALES_SUMMARY,
  (salesSummary: IDashboardSalesSummary) => salesSummary,
)();
export const setOrdersSummaryAction = createAction(
  ACTIONS_CONSTANTS.DASHBOARD_SET_ORDERS_SUMMARY,
  (ordersSummary: IDashboardOrdersSummary) => ordersSummary,
)();
