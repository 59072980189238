import { EditIcon } from '@shopify/polaris-icons';
import { Field } from 'formik';
import { useCallback, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppDataTable } from '../../../../core/components/data-table/DataTable';
import { BasicFormInput } from '../../../../core/components/forms/basic-form-input/basic-form-input';
import { AppIcon } from '../../../../core/components/icon/icon';
import { AppThumbnail } from '../../../../core/components/image-containers/thumbnail/Thumbnail';
import { AppTextStyle } from '../../../../core/components/text/text-style/TextStyle';
import { TrackedQuantityLabelForVariant } from '../../../../core/components/tracked-quantity-label-for-variant/tracked-quantity-label-for-variant';
import { calculateMargin } from '../../../../core/helpers/calculate-margin.helper';
import { withCurrency } from '../../../../core/helpers/currency.helper';
import { validation } from '../../../../core/helpers/validations.helper';
import { ISupplierVariant } from '../../../interfaces/IProduct';
import {
  changeVariantsMSRPAction,
  changeVariantsPriceForRetailersAction,
} from '../../../redux/modules/products/products.actions';
import { getProductVariantsSelector } from '../../../redux/modules/products/products.selectors';
import { getStatusSelector } from '../../../redux/modules/status/status.selectors';
import { RootState } from '../../../redux/reducers';
import './variant-list.scss';

type IVariantListProps = {
  productId: string;
  manualMSRP: boolean;
  manualPriceForRetailers: boolean;
  values: ISupplierVariant[];
};

export function VariantList({
  productId,
  manualMSRP,
  manualPriceForRetailers,
  values,
}: IVariantListProps) {
  const dispatch = useDispatch();
  const { updatingPriceMappingPreference } = useSelector(getStatusSelector);
  const variants = useSelector((state: RootState) =>
    getProductVariantsSelector(state as any, productId),
  );

  const [displayMSRPInputAt, setDisplayMSRPInputAt] = useState<number[] | 'all'>(
    manualMSRP ? 'all' : [],
  );
  const [displayPriceForRetailersInputAt, setDisplayPriceForRetailersInputAt] = useState<
    number[] | 'all'
  >(manualPriceForRetailers ? 'all' : []);

  const calcMargin = useCallback(calculateMargin, []);

  const addIndexToDisplayMSRPInput = useCallback((index: number) => {
    setDisplayMSRPInputAt((at) => (at !== 'all' ? [...at, index] : at));
  }, []);
  const removeIndexFromDisplayMSRPInput = useCallback(
    (index: number) =>
      setDisplayMSRPInputAt((at) => (at !== 'all' ? at.filter((ind) => ind !== index) : at)),
    [],
  );
  const addIndexToDisplayPriceForRetailersInput = useCallback(
    (index: number) =>
      setDisplayPriceForRetailersInputAt((at) => (at !== 'all' ? [...at, index] : at)),
    [],
  );
  const removeIndexFromDisplayPriceForRetailersInput = useCallback(
    (index: number) =>
      setDisplayPriceForRetailersInputAt((at) =>
        at !== 'all' ? at.filter((ind) => ind !== index) : at,
      ),
    [],
  );

  const renderInput = useCallback(
    (fieldName: string, autoFocus: boolean, handleBlur: (value: string) => void) => (
      <Field name={fieldName} validate={validation.isPositiveNumber}>
        {(meta) => (
          <BasicFormInput
            name={fieldName}
            onBlur={(value) => {
              if (!meta.meta.error && value) {
                handleBlur(value);
              }
            }}
            controlled
            autoFocus={autoFocus}
          />
        )}
      </Field>
    ),
    [],
  );

  const updatePriceForRetailers = useCallback(
    (variantId: string, value: string) => {
      const variantToUpdate = variants.find((v) => v.id === variantId);
      if (variantToUpdate?.priceForRetailers === parseFloat(value)) {
        return;
      }
      dispatch(changeVariantsPriceForRetailersAction(productId, variantId, parseFloat(value)));
    },
    [dispatch, productId, variants],
  );

  const updateMSRP = useCallback(
    (variantId: string, value: string) => {
      const variantToUpdate = variants.find((v) => v.id === variantId);
      if (variantToUpdate?.MSRP === parseFloat(value)) {
        return;
      }

      dispatch(changeVariantsMSRPAction(productId, variantId, parseFloat(value)));
    },
    [dispatch, productId, variants],
  );

  const renderPriceForRetailers = useCallback(
    (
      id: string,
      priceForRetailers: number,
      index: number,
      manuallySetPriceForRetailers?: boolean,
    ) => {
      const fieldName = `${productId}[${index}].priceForRetailers`;
      const unavailable = !manuallySetPriceForRetailers && updatingPriceMappingPreference;
      const showInput =
        displayPriceForRetailersInputAt === 'all' ||
        displayPriceForRetailersInputAt.includes(index);
      if (showInput && !updatingPriceMappingPreference)
        return renderInput(fieldName, displayPriceForRetailersInputAt !== 'all', (value) => {
          updatePriceForRetailers(id, value);
          removeIndexFromDisplayPriceForRetailersInput(index);
        });

      return (
        <div className="flex-block">
          <span>
            {unavailable ? (
              <AppTextStyle variation="subdued">N/A</AppTextStyle>
            ) : (
              withCurrency(priceForRetailers)
            )}
          </span>
          {!updatingPriceMappingPreference && (
            <span
              className={`edit-icon${manuallySetPriceForRetailers ? ' custom-price' : ''}`}
              onClick={() => addIndexToDisplayPriceForRetailersInput(index)}
            >
              <AppIcon source={EditIcon} />
            </span>
          )}
        </div>
      );
    },
    [
      productId,
      updatingPriceMappingPreference,
      renderInput,
      displayPriceForRetailersInputAt,
      updatePriceForRetailers,
      removeIndexFromDisplayPriceForRetailersInput,
      addIndexToDisplayPriceForRetailersInput,
    ],
  );

  const renderMSRP = useCallback(
    (id: string, MSRP: number, index: number, manuallySetMSRP?: boolean) => {
      const fieldName = `${productId}[${index}].MSRP`;
      const unavailable = !manuallySetMSRP && updatingPriceMappingPreference;
      const showInput = displayMSRPInputAt === 'all' || displayMSRPInputAt.includes(index);

      if (showInput && !updatingPriceMappingPreference)
        return renderInput(fieldName, displayMSRPInputAt !== 'all', (value) => {
          updateMSRP(id, value);
          removeIndexFromDisplayMSRPInput(index);
        });

      return (
        <div className="flex-block">
          <span>
            {unavailable ? (
              <AppTextStyle variation="subdued">N/A</AppTextStyle>
            ) : (
              withCurrency(MSRP)
            )}
          </span>
          {!updatingPriceMappingPreference && (
            <span
              className={`edit-icon${manuallySetMSRP ? ' custom-price' : ''}`}
              onClick={() => addIndexToDisplayMSRPInput(index)}
            >
              <AppIcon source={EditIcon} />
            </span>
          )}
        </div>
      );
    },
    [
      productId,
      updatingPriceMappingPreference,
      displayMSRPInputAt,
      renderInput,
      updateMSRP,
      removeIndexFromDisplayMSRPInput,
      addIndexToDisplayMSRPInput,
    ],
  );

  const getVariation = useCallback(
    (margin: number | null, manuallySetMSRP?: boolean, manuallySetPriceForRetailers?: boolean) => {
      const positiveValidMargin = margin && margin > 0;

      if (
        !positiveValidMargin ||
        (updatingPriceMappingPreference && !(manuallySetMSRP && manuallySetPriceForRetailers))
      ) {
        return 'negative';
      }
      return 'positive';
    },
    [updatingPriceMappingPreference],
  );

  const renderRetailerMargin = useCallback(
    (margin: number | null, manuallySetMSRP?: boolean, manuallySetPriceForRetailers?: boolean) => {
      const isValidMargin = margin || margin === 0;
      if (
        !isValidMargin ||
        (updatingPriceMappingPreference && !(manuallySetMSRP && manuallySetPriceForRetailers))
      ) {
        return '--%';
      }
      return `${margin}%`;
    },
    [updatingPriceMappingPreference],
  );

  const rows = useMemo(
    () => [
      ...variants.map((v, i) => {
        const valid = !!(
          values &&
          values[i].MSRP &&
          values[i].priceForRetailers &&
          validation.isPositiveNumber(values[i].MSRP.toString()) === undefined &&
          validation.isPositiveNumber(values[i].priceForRetailers.toString()) === undefined
        );

        const updatedPriceForRetailers = valid ? values[i].priceForRetailers : v.priceForRetailers;
        const updatedMSRP = valid ? values[i].MSRP : v.MSRP;
        const margin = valid ? calcMargin(updatedPriceForRetailers, updatedMSRP) : v.margin;
        return [
          <div className="variant-title">{v.title}</div>,
          <AppThumbnail alt={v.title} source={v.image} />,
          <div className="variant-qty">
            <TrackedQuantityLabelForVariant
              quantity={v.qty}
              inventoryPolicy={v.inventoryPolicy}
              tracked={v.tracked}
            />
          </div>,
          <div className="variant-supplier-price" onClick={(e) => e.stopPropagation()}>
            {renderMSRP(v.id, updatedMSRP, i, v.manuallySetMSRP)}
          </div>,
          <div className="variant-retailer-price" onClick={(e) => e.stopPropagation()}>
            {renderPriceForRetailers(
              v.id,
              updatedPriceForRetailers,
              i,
              v.manuallySetPriceForRetailers,
            )}
          </div>,
          <AppTextStyle
            variation={getVariation(margin, v.manuallySetMSRP, v.manuallySetPriceForRetailers)}
          >
            {renderRetailerMargin(margin, v.manuallySetMSRP, v.manuallySetPriceForRetailers)}
          </AppTextStyle>,
          <div className="variant-sku">{v.sku}</div>,
        ];
      }),
    ],
    [
      variants,
      values,
      calcMargin,
      renderMSRP,
      renderPriceForRetailers,
      getVariation,
      renderRetailerMargin,
    ],
  );

  return (
    <AppDataTable
      columnContentTypes={['text', 'text', 'numeric', 'numeric', 'numeric', 'numeric', 'numeric']}
      headings={[
        'Variant',
        'Picture',
        'In stock',
        'SRP',
        'Price for retailers',
        'Retailer margin',
        'SKU',
      ]}
      rows={rows}
    />
  );
}
