import React from 'react';
import { AppBanner } from '../../../../core/components/feedback-indicators/banner/banner';
import { AppLink } from '../../../../core/components/link/link';
import './insufficient-info-banner.scss';

export function InsufficientInfoBanner() {
  return (
    <div className="banner">
      <AppBanner status="critical" title="Insufficient info">
        <p>
          At the moment, you cannot change your plan since you've entered insufficient data. Please{' '}
          <AppLink url="/account">provide account info and credit card</AppLink> to change your plan
        </p>
      </AppBanner>
    </div>
  );
}
