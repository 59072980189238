import { createAction } from 'typesafe-actions';
import { IShopViewDetails } from '../../../interfaces/IAccount';
import { CORE_ACTIONS } from '../../action-types';

export const getViewAsClientSettingAction = createAction(CORE_ACTIONS.GET_VIEW_AS_CLIENT_SETTING)();

export const setViewAsClientSettingAction = createAction(
  CORE_ACTIONS.SET_VIEW_AS_CLIENT_SETTING,
  (viewAsClient?: IShopViewDetails) => ({ viewAsClient }),
)();

export const updateViewAsClientSettingAction = createAction(
  CORE_ACTIONS.UPDATE_VIEW_AS_CLIENT_SETTING,
  (clientId: string) => clientId,
)();

export const showAdminSettingsUpdatedToastAction = createAction(
  CORE_ACTIONS.SHOW_ADMIN_SETTINGS_UPDATED_TOAST,
)();
export const hideAdminSettingsUpdatedToastAction = createAction(
  CORE_ACTIONS.HIDE_ADMIN_SETTINGS_UPDATED_TOAST,
)();
