import { IRadioButtonDescription } from '../../core/components/forms/radio/controlled-radio-group/controlled-radio-group';
import {
  carrierServiceConstants,
  unspecifiedCarrierServiceConstants,
} from '../../core/constants/carrier-service.constants';
import { NOTIFICATION_FREQUENCY } from '../../core/interfaces/INotificationSources';
import {
  COMPARE_AT_PRICE_OPTION,
  IMPORTED_OR_CONNECTED_PRODUCT_STATUS_OPTION,
  INotificationsPreferences,
  LINE_ITEMS_CANCELLATION_OPTION,
  PRODUCT_DISCONTINUATION_OPTION,
  PRODUCT_REMOVE_FROM_CROWDSHIP_OPTION,
  PRODUCT_UPDATE_OPTION,
  SALE_PRICE_OPTION,
} from '../interfaces/IPreferences';

type PreferencesOption =
  | IMPORTED_OR_CONNECTED_PRODUCT_STATUS_OPTION
  | PRODUCT_DISCONTINUATION_OPTION
  | PRODUCT_UPDATE_OPTION
  | PRODUCT_REMOVE_FROM_CROWDSHIP_OPTION
  | COMPARE_AT_PRICE_OPTION
  | SALE_PRICE_OPTION
  | LINE_ITEMS_CANCELLATION_OPTION
  | NOTIFICATION_FREQUENCY;

interface IPreferencesConstants extends IRadioButtonDescription {
  value: PreferencesOption;
}

export const importedOrConnectedProductStatusOptionsConstants: IPreferencesConstants[] = [
  {
    value: IMPORTED_OR_CONNECTED_PRODUCT_STATUS_OPTION.ACTIVE,
    label: 'Product should be imported or connected with status "Active"',
    helpText: 'default',
  },
  {
    value: IMPORTED_OR_CONNECTED_PRODUCT_STATUS_OPTION.DRAFT,
    label: 'Product should be imported or connected with status "Draft"',
  },
];

export const productDiscontinuationOptionsConstants: IPreferencesConstants[] = [
  {
    value: PRODUCT_DISCONTINUATION_OPTION.UNPUBLISH,
    label: 'Set the product to Draft (recommended)',
    helpText:
      'The product will be un-published, marked as Draft and the quantities of variants in stock will be set to 0',
  },
  {
    value: PRODUCT_DISCONTINUATION_OPTION.DELETE,
    label: 'Automatically delete the product from my store',
    helpText: 'The product will be automatically removed from your store, deleting all the data',
  },
  {
    value: PRODUCT_DISCONTINUATION_OPTION.IGNORE,
    label: 'Do nothing',
    helpText: `The product will be left in your store, but no longer connected to Crowdship, 
      and orders will not auto-process until you manually re-connect it (not recommended)`,
  },
];

export const productUpdateOptionsConstants: IPreferencesConstants[] = [
  {
    value: PRODUCT_UPDATE_OPTION.IGNORE,
    label: 'Disable product listing syncing',
    helpText: 'default',
  },
  {
    value: PRODUCT_UPDATE_OPTION.UPDATE,
    label: 'Enable product listing sync',
  },
];

export const productCompareAtPriceOptionsConstants: IPreferencesConstants[] = [
  {
    value: COMPARE_AT_PRICE_OPTION.NOT_SET,
    label: "Don't set compare at price",
    helpText: 'default',
  },
  {
    value: COMPARE_AT_PRICE_OPTION.MSRP,
    label: 'Use SRP as my compare at price',
  },
  {
    value: COMPARE_AT_PRICE_OPTION.MARK_UP_FROM_SALE_PRICE,
    label: 'Calculate my compare at price using my price and markup below',
  },
];

export const productSalePriceOptionsConstants: IPreferencesConstants[] = [
  {
    value: SALE_PRICE_OPTION.MSRP,
    label: 'Set my retail price to the SRP (recommended)',
  },
  {
    value: SALE_PRICE_OPTION.DROP_SHIP_COST_WITH_MARKUP,
    label: 'Set my retail price by applying a markup to my cost',
  },
];

export const lineItemCancellationOptionsConstants: IPreferencesConstants[] = [
  {
    value: LINE_ITEMS_CANCELLATION_OPTION.REFUND,
    label:
      'Automatically cancel the line items in my customer sales order and issue refunds for the cancelled items.',
    helpText: 'default',
  },
  {
    value: LINE_ITEMS_CANCELLATION_OPTION.REMOVE,
    label: 'Automatically cancel the line items in my customer sales order, but do not refund.',
  },
  {
    value: LINE_ITEMS_CANCELLATION_OPTION.IGNORE,
    label: 'Do not automatically refund or cancel line items.',
    helpText: 'WARNING: This option is not recommended.',
  },
];

interface INotificationConstant {
  key: keyof INotificationsPreferences;
  title: string;
}

export const notificationEventsConstants: INotificationConstant[] = [
  { key: 'sellProduct', title: 'When I sell a Crowdship product' },
  {
    key: 'dropShipCostChanges',
    title: 'When a Crowdship product dropship cost changes (up or down)',
  },
  {
    key: 'orderFulfillment',
    title: 'When a Crowdship order, or item on an order, is fulfilled',
  },
  // { key: 'newBrand', title: 'When a new brand launches on Crowdship' },
];

// if key has a dot - it's a specified service
export const estimateOptions = Object.entries(carrierServiceConstants)
  .map(([carrier, services]) =>
    Object.entries(services).map(([service, mapping]) => ({
      value: `${carrier}.${service}`,
      label: `${carrier} ${mapping.name} (${mapping.estimate})`,
    })),
  )
  .reduce((options, carrier) => [...options, ...carrier], [])
  .concat(
    Object.entries(unspecifiedCarrierServiceConstants).map(([type, mapping]) =>
      mapping.name === 'Cheapest' // cheapest label should not have a time estimate description
        ? { value: type, label: `${mapping.name}` }
        : {
            value: type,
            label: `${mapping.name} (${mapping.estimate})`,
          },
    ),
  );

export enum USE_CONTACT_PHONE {
  CUSTOMER = 'customer',
  RETAILER = 'retailer',
  CROWDSHIP = 'crowdship',
}
