import React from 'react';
import { AppSkeletonPage } from '../../../../core/components/feedback-indicators/skeleton/skeleton-page/skeleton-page';
import { AppSkeletonBodyText } from '../../../../core/components/feedback-indicators/skeleton/skeleton-body-text/skeleton-body-text';
import { AppLayout } from '../../../../core/components/structure/layout/layout';
import { AppLayoutAnnotatedSection } from '../../../../core/components/structure/layout/layout-annotated-section';
import { AppCard } from '../../../../core/components/structure/card/card';
import { AppSkeletonDisplayText } from '../../../../core/components/feedback-indicators/skeleton/skeleton-display-text/skeleton-display-text';
import { AppTabs } from '../../../../core/components/tabs/tabs';

export function PreferencesListSkeleton(props: {}) {
  return (
    <AppSkeletonPage title="Settings">
      <AppTabs
        tabs={Array.from({ length: 7 }, (_, i) => ({
          id: `display-tab-${i + 1}`,
          panelID: `display-panel-${i + 1}`,
          content: (
            <div style={{ width: '5em' }}>
              <AppSkeletonDisplayText />
            </div>
          ),
        }))}
        selected={0}
        onSelect={() => {}}
      >
        <div style={{ marginTop: '2em' }}>
          <AppLayout>
            {Array.from({ length: 3 }).map((el, i) => (
              <AppLayoutAnnotatedSection title={<AppSkeletonDisplayText />} key={i}>
                <AppCard sectioned>
                  <AppSkeletonBodyText />
                </AppCard>
              </AppLayoutAnnotatedSection>
            ))}
          </AppLayout>
        </div>
      </AppTabs>
    </AppSkeletonPage>
  );
}
