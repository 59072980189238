import React from 'react';
import { Layout } from '@shopify/polaris';

type ILayoutSectionProps = {
  children?: React.ReactNode;
  secondary?: boolean;
  fullWidth?: boolean;
  oneHalf?: boolean;
  oneThird?: boolean;
};

export function AppLayoutSection(props: ILayoutSectionProps) {
  return <Layout.Section {...props} />;
}
