import { AppChoiceList } from '../../../../choice-list/ChoiceList';
import { FilterElementProps } from './filter-elements.interface';

export const SHIPS_FROM_FILTER_KEY = 'shipsForm';
const SHIPS_FROM_FILTER_LABEL = 'Ships from';
const SHIPS_FROM_FILTER_TITLE = 'Ships from';

function Filter({ onChange, options, value }: FilterElementProps<string[]>) {
  return (
    <AppChoiceList
      title={SHIPS_FROM_FILTER_TITLE}
      titleHidden
      choices={options}
      selected={value}
      onChange={onChange}
      allowMultiple
    />
  );
}

export const ShipsFromFilter = ({ value, options, onChange }: FilterElementProps<string[]>) => ({
  key: SHIPS_FROM_FILTER_KEY,
  label: SHIPS_FROM_FILTER_LABEL,
  shortcut: true,
  filter: <Filter onChange={onChange} options={options} value={value} />,
});
