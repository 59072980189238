import React, { Fragment, useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { AppToast } from '../../../../core/components/feedback-indicators/toast/toast';
import { AppPage } from '../../../../core/components/structure/page/page';
import { ViewAsClientSetting } from '../../../../core/components/view-as-client-setting/view-as-client-setting';
import { IShopViewDetails } from '../../../../core/interfaces/IAccount';
import { hideAdminSettingsUpdatedToastAction } from '../../../../core/redux/modules/admin/admin.actions';
import { getAdminSettingsUpdatedSelector } from '../../../../core/redux/modules/admin/admin.selector';
import { ACCOUNT_ROLE } from '../../../../core/redux/modules/auth/auth.reducer';
import { getRolesSelector } from '../../../../core/redux/modules/auth/auth.selectors';
import { retailerApi } from '../../../api/retailer.api';
import { getStatusAction } from '../../../redux/modules/status/status.actions';

export const AdminToolsLayout = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [retailers, setRetailers] = useState<IShopViewDetails[]>([]);
  const roles = useSelector(getRolesSelector);
  const updated = useSelector(getAdminSettingsUpdatedSelector);
  const hideToast = useCallback(() => dispatch(hideAdminSettingsUpdatedToastAction()), [dispatch]);

  if (!roles?.includes(ACCOUNT_ROLE.ADMIN)) {
    history.push('/');
  }

  useEffect(() => {
    setLoading(true);
    retailerApi
      .getRetailers()
      .then(({ data }) => setRetailers(data))
      .catch(console.error)
      .finally(() => setLoading(false));
  }, []);

  const onViewAsClientUpdate = useCallback(() => {
    dispatch(getStatusAction());
  }, [dispatch]);

  return (
    <Fragment>
      <AppPage title="Admin tools">
        <ViewAsClientSetting items={retailers} loading={loading} onChange={onViewAsClientUpdate} />
      </AppPage>
      {updated && <AppToast onDismiss={hideToast} content="Changes saved" />}
    </Fragment>
  );
};
