import { createAction } from 'typesafe-actions';
import { IVariantMatch } from '../../../../core/interfaces/IProduct';
import { IVariantSync } from '../../../interfaces/IProductsSync';
import { ACTIONS_CONSTANTS } from '../../actions-constants';

export const getSyncStatusAction = createAction(ACTIONS_CONSTANTS.GET_SYNC_INVENTORY_STATUS)();

export const setSyncStatusAction = createAction(
  ACTIONS_CONSTANTS.SET_SYNC_INVENTORY_STATUS,
  (result: Pick<IVariantSync, 'syncInProgress'>) => result,
)();

export const getSyncMatchesAction = createAction(
  ACTIONS_CONSTANTS.GET_SYNC_INVENTORY_MATCHES,
  (page: number) => page,
)();

export const setSyncMatchesAction = createAction(
  ACTIONS_CONSTANTS.SET_SYNC_INVENTORY_MATCHES,
  (result: { matches: IVariantMatch[]; total: number }) => result,
)();

export const setFetchingMatchesAction = createAction(
  ACTIONS_CONSTANTS.SET_FETCHING_MATCHES,
  (fetching: boolean) => fetching,
)();

export const startInventorySyncAction = createAction(ACTIONS_CONSTANTS.START_INVENTORY_SYNC)();

export const linkMatchesAction = createAction(
  ACTIONS_CONSTANTS.LINK_INVENTORY_MATCHES,
  (matchesIds: IdsForMatchLinking[]) => matchesIds,
)();

export const linkAllMatchesAction = createAction(
  ACTIONS_CONSTANTS.LINK_ALL_INVENTORY_MATCHES,
  (ids: string[]) => ids,
)();

export const showLinkToastAction = createAction(
  ACTIONS_CONSTANTS.SHOW_VARIANT_LINK_TOAST,
  (result: { success: boolean; notFound?: boolean }) => result,
)();

export const hideLinkToastAction = createAction(ACTIONS_CONSTANTS.HIDE_VARIANT_LINK_TOAST)();

type IdsForMatchLinking = {
  retailerProductId: string;
  supplierProductId: string;
};
