import { createReducer } from 'typesafe-actions';
import { IVariantSync } from '../../../interfaces/IProductsSync';
import { RootActions } from '../../root.actions';
import {
  getSyncMatchesAction,
  hideLinkToastAction,
  linkMatchesAction,
  setFetchingMatchesAction,
  setSyncMatchesAction,
  setSyncStatusAction,
  showLinkToastAction,
} from './variant-sync.actions';

interface IVariantSyncReducer extends IVariantSync {
  fetching: boolean;
  linkStatus: {
    showToast: boolean;
    success?: boolean;
    notFound?: boolean;
  };
}

const initialState: IVariantSyncReducer = {
  syncInProgress: false,
  matches: [],
  total: 0,
  fetching: false,
  linkStatus: { showToast: false },
};

export const variantSyncReducer = createReducer<IVariantSyncReducer, RootActions>(initialState)
  .handleAction(getSyncMatchesAction, (state) => ({ ...state, fetching: true }))
  .handleAction(setSyncStatusAction, (state, { payload }) => ({ ...state, ...payload }))
  .handleAction(setSyncMatchesAction, (state, { payload }) => ({
    ...state,
    ...payload,
    fetching: false,
  }))
  .handleAction(linkMatchesAction, (state, { payload }) => ({
    ...state,
    matches: state.matches.filter(
      (pm) => !payload.some((linked) => linked.retailerProductId === pm.retailerProduct.product),
    ),
  }))
  .handleAction(showLinkToastAction, (state, { payload }) => ({
    ...state,
    linkStatus: { showToast: true, ...payload },
  }))
  .handleAction(hideLinkToastAction, (state) => ({
    ...state,
    linkStatus: { showToast: false },
  }))
  .handleAction(setFetchingMatchesAction, (state, { payload }) => ({
    ...state,
    fetching: payload,
  }));
