import { all, takeLatest, put } from 'redux-saga/effects';
import { ActionType } from 'typesafe-actions';
import { dashboardApi } from '../../../api/dashboard.api';
import {
  getDashboardOrdersSummaryAction,
  getDashboardProductsSummaryAction,
  getDashboardSalesSummaryAction,
  setDashboardOrdersSummaryAction,
  setDashboardProductsSummaryAction,
  setDashboardSalesSummaryAction,
} from './dashboard.actions';

function* getDashboardOrdersSummarySaga(
  action: ActionType<typeof getDashboardOrdersSummaryAction>,
) {
  try {
    const res = yield dashboardApi.getOrdersSummary();
    yield put(setDashboardOrdersSummaryAction(res.data));
  } catch (e) {
    console.error(e);
  }
}

function* getDashboardProductsSummarySaga(
  action: ActionType<typeof getDashboardProductsSummaryAction>,
) {
  try {
    const res = yield dashboardApi.getProductsSummary();
    yield put(setDashboardProductsSummaryAction(res.data));
  } catch (e) {
    console.error(e);
  }
}

function* getDashboardSalesSummarySaga(action: ActionType<typeof getDashboardSalesSummaryAction>) {
  try {
    const res = yield dashboardApi.getSalesSummary();
    yield put(setDashboardSalesSummaryAction(res.data));
  } catch (e) {
    console.error(e);
  }
}

export function* dashboardSaga() {
  yield all([
    takeLatest(getDashboardOrdersSummaryAction, getDashboardOrdersSummarySaga),
    takeLatest(getDashboardProductsSummaryAction, getDashboardProductsSummarySaga),
    takeLatest(getDashboardSalesSummaryAction, getDashboardSalesSummarySaga),
  ]);
}
