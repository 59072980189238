import { useCallback, useState } from 'react';
import { AppButton } from '../../../../../core/components/button/Button';
import { AppDropZone } from '../../../../../core/components/drop-zone/drop-zone';
import { AppControlledSelect } from '../../../../../core/components/forms/controlled-select/controlled-select';
import { AppControlledTextField } from '../../../../../core/components/forms/controlled-text-field/controlled-text-field';
import {
  IPayoutFile,
  IPreferences,
  PAYOUT_ACCOUNT_TYPE,
} from '../../../../interfaces/IPreferences';
import { AppControlledCheckbox } from '../../../../../core/components/forms/controlled-checkbox/controlled-checkbox';
import { MAX_FILE_SIZE } from '../../../../../core/constants/files.constants';

type AchDetailsFormProps = {
  values: IPreferences;
  setFieldValue: (field: string, value: any) => void;
};

export const AchDetailsForm = ({ values, setFieldValue }: AchDetailsFormProps) => {
  const { achDetails } = values.payout;

  const handleUpload = useCallback(
    (fieldName: string, file: string | File) => {
      if (typeof file === 'object') {
        setFieldValue(`${fieldName}.src`, file);
        setFieldValue(`${fieldName}.size`, file.size);
        setFieldValue(`${fieldName}.name`, file.name);
        return;
      }
      setFieldValue(fieldName, undefined);
    },
    [setFieldValue],
  );

  return (
    <div className="payout-details">
      <AppControlledTextField
        label="Routing number"
        placeholder="123456789"
        name="payout.achDetails.routing"
      />
      <AppControlledTextField
        label="Account number"
        placeholder="0123456789"
        name="payout.achDetails.account"
      />
      <AppControlledTextField
        label="Confirm Account number"
        placeholder="0123456789"
        name="payout.achDetails.confirmAccount"
        validate={(val) =>
          val !== values.payout?.achDetails?.account ? 'Account numbers must match' : ''
        }
      />
      <AppControlledTextField
        label="SWIFT code"
        placeholder="AAAA-BB-CC-123"
        name="payout.achDetails.swiftCode"
      />
      <AppControlledTextField
        label="Account Owner Name"
        name="payout.achDetails.accountOwnerName"
      />
      <AppControlledTextField
        label="Bank Account Address"
        name="payout.achDetails.accountAddress"
      />
      <AppControlledSelect
        label="Account Type"
        options={Object.values(PAYOUT_ACCOUNT_TYPE).map((type) => ({ label: type, value: type }))}
        name="payout.achDetails.accountType"
      />
      <div className="files_wrapper">
        <div className="w9_from file_wrapper">
          <PayoutFile
            uploadText="Select W9 form"
            fieldName="payout.achDetails.w9Form"
            handleUpload={handleUpload}
            file={achDetails?.w9Form}
            link={
              <a href="https://www.irs.gov/pub/irs-pdf/fw9.pdf" rel="noreferrer" target="_blank">
                W9 template
              </a>
            }
          />
        </div>
        <div className="file_wrapper">
          <PayoutFile
            uploadText="Select Void bank check"
            fieldName="payout.achDetails.voidBankCheck"
            handleUpload={handleUpload}
            file={achDetails?.voidBankCheck}
          />
        </div>
        <AppControlledCheckbox
          label={
            <>
              I have read and agree to Crowdship’s Autopay{' '}
              <a href="https://crowdship.io/terms-conditions/" rel="noreferrer" target="_blank">
                Terms of Services and Use
              </a>
            </>
          }
          name={'payout.achDetails.readTermsOfService'}
          error={
            !values?.payout?.achDetails?.readTermsOfService &&
            'You need to accept our Terms of Service and Use to be able to proceed.'
          }
        />
      </div>
    </div>
  );
};

type PayoutFileProps = {
  uploadText: string;
  handleUpload: (fieldName: string, file: File | string) => void;
  fieldName: string;
  file?: IPayoutFile;
  link?: React.ReactNode;
};

const PayoutFile = ({ uploadText, handleUpload, fieldName, file, link }: PayoutFileProps) => {
  const [error, setError] = useState('');

  const handleOnDrop = useCallback(
    (_: File[], acceptedFiles: File[]) => {
      const file = acceptedFiles?.[0];
      if (!file) return;
      const { size, name, type } = file;

      if (size > MAX_FILE_SIZE) return setError('File size is more than 5 MB');
      if (!['image/jpeg', 'image/png', 'application/pdf'].includes(type))
        return setError('Not valid file');
      if (!/^[a-zA-Z0-9_\-.]+$/.test(name))
        return setError('The file name contains invalid characters.');

      setError('');
      handleUpload(fieldName, file);
    },
    [fieldName, handleUpload],
  );

  const handleRemoveFile = useCallback(() => {
    setError('');
    handleUpload(fieldName, '');
  }, [fieldName, handleUpload]);

  return (
    <div className="drop-zone__wrapper">
      <div className="drop-zone_with-link">
        <AppDropZone
          accept=".png, .jpeg, .pdf"
          type="image"
          onDrop={handleOnDrop}
          outline={false}
          variableHeight
          allowMultiple={false}
          disabled={!!file}
        >
          <AppButton disabled={!!file}>{uploadText}</AppButton>
        </AppDropZone>
        {link && link}
      </div>
      {file ? (
        <div style={{ display: 'flex', gap: '10px' }}>
          <a
            href={file.src}
            target="_blank"
            rel="noreferrer"
            // just now uploaded file
            className={`${typeof file?.src !== 'string' && 'disabled_link'}`}
          >
            {file.name}
          </a>
          <p className="error delete_file" onClick={handleRemoveFile}>
            Delete
          </p>
        </div>
      ) : (
        <p className={error && 'error'}>{error || 'Max Total size 5 MB'}</p>
      )}
    </div>
  );
};
