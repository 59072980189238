import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { LoadingLayout } from '../../../../core/layouts/loading-layout/loading-layout';
import { plansApi } from '../../../api/plans.api';

export const PlanChangeConfirmationLayout = () => {
  const history = useHistory();
  const [text, setText] = useState<string>('Checking if charge is confirmed...');

  useEffect(() => {
    plansApi
      .checkPlanChangeConfirmation()
      .then(({ data }) => {
        if ('confirmationUrl' in data && data.confirmationUrl) {
          setText('Charge not confirmed');
        } else {
          setText('Charge confirmed');
        }
      })
      .catch((e) => {
        setText('Error when confirming charge');
        console.error(e);
      })
      .finally(() => {
        setTimeout(
          () => history.push({ pathname: '/settings', search: '?tab=subscription-plan' }),
          1000,
        );
      });
  }, [history]);

  return <LoadingLayout text={text} />;
};
