import { AppTooltip } from '../tooltip/tooltip';
import './date-tooltip-utc.scss';

interface DateTooltipProps {
  date: number | string;
  tooltipFormatter: (_: number | string) => string;
  dateFormatter: (_: number | string) => string;
  className: string;
}

export const DateTooltipUTC = ({
  date,
  tooltipFormatter,
  dateFormatter,
  className,
}: DateTooltipProps) => {
  return (
    <div className="tooltip-wrapper">
      <AppTooltip
        content={`${tooltipFormatter(date)} - UTC`}
        preferredPosition="above"
        dismissOnMouseOut
      >
        <div className={className}> {dateFormatter(date)}</div>
      </AppTooltip>
    </div>
  );
};
