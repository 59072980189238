import moment from 'moment';

const formatDate = {
  sameDay: '[Today,] MMMM D, YYYY [at] h:mm a',
  lastDay: '[Yesterday,] MMMM D, YYYY [at] h:mm a',
  lastWeek: 'dddd, MMMM D, YYYY [at] h:mm a',
  sameElse: 'dddd, MMMM D, YYYY [at] h:mm a',
};

export const getFormattedDate = (date: string | number) =>
  moment(date).calendar({
    sameDay: '[Today at] h:mm a',
    lastDay: '[Yesterday at] h:mm a',
    lastWeek: 'dddd [at] h:mm a',
    sameElse: 'MMMM D [at] h:mm a',
  });

export const getFullDateFormat = (date: string | number) =>
  moment(date).format('dddd, MMMM D, YYYY h:mm A');

export const getFullFormattedDate = (date: string | number) =>
  moment(date).calendar({
    ...formatDate,
  });

export const getUTCDate = (date: string | number) =>
  moment(date)
    .utc()
    .calendar({
      ...formatDate,
    });
