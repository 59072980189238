import { AppProvider } from '@shopify/polaris';
import enTranslations from '@shopify/polaris/locales/en.json';
import { AppFooterHelp } from '../core/components/footer-help/footer-help';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { setAxiosAuthorization } from '../core/api/base.api';
import { AppToast } from '../core/components/feedback-indicators/toast/toast';
import { AppFrame } from '../core/components/structure/frame/frame';
import CrowdshipLogo from '../core/images/crowdship-logo-v3-2.png';
import { LoadingLayout } from '../core/layouts/loading-layout/loading-layout';
import { getStatusAction } from '../shopify-retailer/redux/modules/status/status.actions';
import { Navbar } from './components/containers/navbar/navbar';
import { Sidebar } from './components/containers/sidebar/sidebar';
import { setAuthenticatedAction } from './redux/modules/auth/auth.actions';
import { isAuthenticatedSelector } from './redux/modules/auth/auth.selectors';
import { Router } from './router/Router';
import { AppLink } from '../core/components/link/link';
import { AppBanners } from '../shopify-retailer/components/banners/app-banners/app-banners';

export function App() {
  const dispatch = useDispatch();
  const [mobileNavigationActive, setMobileNavigationActive] = useState(false);
  const [authenticating, setAuthenticating] = useState(true);
  const [error, setError] = useState('');
  const [theme, setTheme] = useState<'dark' | 'light'>(
    (localStorage.getItem('theme') as 'dark' | 'light') || 'light',
  );
  const [isFrame, setIsFrame] = useState(false);

  const logo = useMemo(
    () => ({
      width: 124,
      topBarSource: CrowdshipLogo,
      url: '#',
      accessibilityLabel: 'Crowdship',
    }),
    [],
  );

  const authenticated = useSelector(isAuthenticatedSelector);

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      setAxiosAuthorization(token);
      dispatch(setAuthenticatedAction(token));
    }
    setAuthenticating(false);
  }, [dispatch]);

  useEffect(() => {
    if (!authenticating && authenticated) {
      // TODO: dispatch standalone status action
      dispatch(getStatusAction());
    }
  }, [authenticating, authenticated, dispatch]);

  useEffect(() => {
    if (window.self !== window.top) {
      setIsFrame(true);
      window.open(process.env.REACT_APP_URL, '_blank');
    }
  }, []);

  const handleThemeChange = useCallback((t: 'dark' | 'light') => {
    setTheme(t);
    localStorage.setItem('theme', t);
  }, []);

  const toggleMobileNavigationActive = useCallback(
    () => setMobileNavigationActive((active) => !active),
    [],
  );

  return (
    <AppProvider
      colorScheme={theme}
      i18n={enTranslations}
      linkComponent={({ url, ref, ...rest }) => <Link to={url} {...rest} />}
    >
      <AppFrame
        navigation={authenticated && <Sidebar />}
        topBar={
          <Navbar
            theme={theme}
            onThemeChange={handleThemeChange}
            onNavigationToggle={toggleMobileNavigationActive}
          />
        }
        showMobileNavigation={mobileNavigationActive}
        onNavigationDismiss={toggleMobileNavigationActive}
        logo={logo}
      >
        {isFrame ? (
          <LoadingLayout
            text={
              <span>
                If you have not been redirected, click{' '}
                <AppLink onClick={() => window.open(process.env.REACT_APP_URL, '_blank')}>
                  here
                </AppLink>{' '}
              </span>
            }
          />
        ) : (
          <>
            {authenticated && (
              <>
                <AppBanners />
              </>
            )}
            {authenticating && <LoadingLayout />}
            {!authenticating && <Router />}
            {error && <AppToast content={error} onDismiss={() => setError('')} error />}
            <AppFooterHelp>
              Learn more about{' '}
              <AppLink onClick={() => window.open('https://help.crowdship.io/', '_blank')} external>
                selling on Crowdship
              </AppLink>{' '}
              at the Crowdship Help Center.
            </AppFooterHelp>
          </>
        )}
      </AppFrame>
    </AppProvider>
  );
}
