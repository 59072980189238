import React, { useCallback, useState } from 'react';
import { Editor } from '@tinymce/tinymce-react';
import { Card } from '@shopify/polaris';

interface ProductDescriptionTabProps {
  description: string;
  setFieldValue: (field: string, value: any) => void;
}

export const ProductDescriptionTab = ({
  description: initDescription,
  setFieldValue,
}: ProductDescriptionTabProps) => {
  const [initializedEditor, setInitializatedEditor] = useState(false);

  const handleEditorChange = useCallback(
    (newContent: string, editor: any) => {
      if (initializedEditor) setFieldValue('description', newContent);
    },
    [setFieldValue, initializedEditor],
  );

  return (
    <Card.Section>
      <Editor
        initialValue={initDescription}
        init={{
          init_instance_callback: () => {
            setInitializatedEditor(true);
          },
          height: 400,
          menubar: false,
          plugins: [
            'advlist',
            'autolink',
            'lists',
            'link',
            'image',
            'charmap',
            'preview',
            'anchor',
            'searchreplace',
            'visualblocks',
            'code',
            'fullscreen',
            'insertdatetime',
            'media',
            'table',
            'code',
            'help',
            'wordcount',
          ],
          fontsize_formats: '8pt 9pt 10pt 11pt 12pt 14pt 18pt 24pt 30pt 36pt 48pt 60pt 72pt 96pt',
          toolbar:
            'undo redo | blocks | ' +
            'bold italic forecolor fontsizeselect | alignleft aligncenter ' +
            'alignright alignjustify | bullist numlist outdent indent | ' +
            'removeformat | help',
          content_style: 'body { font-family:Helvetica,Arial,sans-serif; }',
          entity_encoding: 'raw',
        }}
        onEditorChange={handleEditorChange}
        scriptLoading={{ defer: true }}
      />
    </Card.Section>
  );
};
