import React from 'react';
import { AppBanner } from '../../feedback-indicators/banner/banner';
import { AppSpinner } from '../../feedback-indicators/spinner/spinner';
import './fetching-banner.scss';

interface IFetchingBannerProps {
  bodyText: string;
}

export function FetchingBanner({ bodyText }: IFetchingBannerProps) {
  return (
    <div className="fetching-banner">
      <AppBanner status="info">
        <span className="spinner-wrapper">
          <AppSpinner size="small" />
        </span>
        {bodyText}
      </AppBanner>
    </div>
  );
}
