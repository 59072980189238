import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getOrdersSummaryAction } from '../../../redux/modules/orders/orders.actions';
import {
  getOrdersSummarySelector,
  getOrdersSummaryFetchingSelector,
} from '../../../redux/modules/orders/orders.selectors';
import { List } from '@shopify/polaris';
import { getLocaleQuantity } from '../../../../core/helpers/quantity.helper';
import { withCurrency } from '../../../../core/helpers/currency.helper';

interface IOrdersSummaryComponent {}

export const OrdersSummaryComponent: React.FunctionComponent<IOrdersSummaryComponent> = () => {
  const dispatch = useDispatch();

  const summary = useSelector(getOrdersSummarySelector);

  const fetching = useSelector(getOrdersSummaryFetchingSelector);

  React.useEffect(() => {
    dispatch(getOrdersSummaryAction());
  }, [dispatch]);

  if (fetching) return <div>fetching</div>;

  return (
    <div>
      <List type="bullet">
        <List.Item>{`Total: ${getLocaleQuantity(summary.totalOrders)}`}</List.Item>

        <List.Item>{`Unfulfilled: ${getLocaleQuantity(summary.unfulfilledOrders)}`}</List.Item>

        <List.Item>{`Total Earned: ${withCurrency(summary.totalEarned)}`}</List.Item>
      </List>
    </div>
  );
};
