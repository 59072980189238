import { Card } from '@shopify/polaris';
import React from 'react';
import { AppIndexTable } from '../../../../../core/components/index-table/index-table';
import { retailerImportListHeadings } from '../../../../../core/components/product-list/product-list.constants';
import { IVariantFromImportList } from '../../../../interfaces/IProduct';
import { ProductVariant } from './product-variant/product-variant';

interface IProductVariantsTabProps {
  variants: IVariantFromImportList[];
  loading: boolean;
  variantsLength: number;
  setFieldValue: (field: string, value: any) => void;
}

export const ProductVariantsTab = ({
  variants,
  variantsLength,
  loading,
  setFieldValue,
}: IProductVariantsTabProps) => {
  return (
    <Card.Section>
      <AppIndexTable
        resourceName={{ singular: 'product', plural: 'products' }}
        headings={retailerImportListHeadings}
        itemCount={variantsLength}
        selectable={false}
        loading={loading}
      >
        {variants.map((variant: IVariantFromImportList, index: number) => (
          <ProductVariant
            variant={variant}
            key={variant.id}
            index={index}
            setFieldValue={setFieldValue}
          />
        ))}
      </AppIndexTable>
    </Card.Section>
  );
};
