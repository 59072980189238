import { createAction } from 'typesafe-actions';
import { IVariantWithProductId } from '../../../api/products.api';
import { Paginated, PickOptions } from '../../../../core/helpers/generic.helper';
import { IProductList, ISupplierProduct } from '../../../../core/interfaces/IProduct';
import { ISupplierProductsListFilter } from '../../../../core/interfaces/IProductFilter';
import { ISupplierVariant } from '../../../interfaces/IProduct';
import { ACTIONS_CONSTANTS } from '../../actions-constants';
import { IProductsMoveOp as IProductsAvailabilityUpdate } from './products.reducer';

export const getProductsAction = createAction(ACTIONS_CONSTANTS.GET_PRODUCTS)();

export const setProductsAction = createAction(
  ACTIONS_CONSTANTS.SET_PRODUCTS,
  (params: IProductList<ISupplierProduct>) => params,
)();

export const getVariantsAction = createAction(
  ACTIONS_CONSTANTS.GET_PRODUCT_VARIANTS,
  (productId) => productId,
)();

export const setVariantsAction = createAction(
  ACTIONS_CONSTANTS.SET_PRODUCTS_VARIANTS,
  (productId: string, variants: ISupplierVariant[]) => ({ productId, variants }),
)();

export const getFilterOptionsAction = createAction(ACTIONS_CONSTANTS.GET_PRODUCT_FILTER_OPTIONS)();

export const setFilterOptionsAction = createAction(
  ACTIONS_CONSTANTS.SET_PRODUCT_FILTER_OPTIONS,
  (options: PickOptions<ISupplierProductsListFilter>) => options,
)();

export const changeAvailableGroupsAction = createAction(
  ACTIONS_CONSTANTS.CHANGE_AVAILABLE_GROUPS,
  (params: IChangeAvailableGroupsActionPayload) => params,
)();

export const changeAvailableGroupsForAllProductsAction = createAction(
  ACTIONS_CONSTANTS.CHANGE_AVAILABLE_GROUPS_FOR_ALL_PRODUCTS,
  (retailerGroups: string[]) => retailerGroups,
)();

export const addProductsToMarketplaceAction = createAction(
  ACTIONS_CONSTANTS.ADD_PRODUCTS_TO_MARKETPLACE,
  (productIds: string[]) => productIds,
)();

export const addAllProductsToMarketplaceAction = createAction(
  ACTIONS_CONSTANTS.ADD_ALL_PRODUCTS_TO_MARKETPLACE,
)();

export const changeVariantsPriceForRetailersAction = createAction(
  ACTIONS_CONSTANTS.UPDATE_VARIANTS_PRICE_FOR_RETAILERS,
  (productId: string, variantId: string, priceForRetailers: number) => ({
    productId,
    variantId,
    priceForRetailers,
  }),
)();

export const changeVariantsMSRPAction = createAction(
  ACTIONS_CONSTANTS.UPDATE_VARIANTS_MSRP,
  (productId: string, variantId: string, MSRP: number) => ({
    productId,
    variantId,
    MSRP,
  }),
)();

export const updateVariantAction = createAction(
  ACTIONS_CONSTANTS.UPDATE_VARIANT,
  (variant: IVariantWithProductId) => variant,
)();

export const showSuccessVariantPriceUpdateToastAction = createAction(
  ACTIONS_CONSTANTS.SHOW_SUCCESS_VARIANT_PRICE_UPDATE_TOAST,
)();

export const hideSuccessVariantPriceUpdateToastAction = createAction(
  ACTIONS_CONSTANTS.HIDE_SUCCESS_VARIANT_PRICE_UPDATE_TOAST,
)();

// info about last add/remove products operation for the banner
export const setLastAvailabilityUpdateAction = createAction(
  ACTIONS_CONSTANTS.SET_LAST_PRODUCTS_AVAILABILITY_UPDATE,
  (result: IProductsAvailabilityUpdate) => result,
)();

export const removeLastAvailabilityUpdateAction = createAction(
  ACTIONS_CONSTANTS.REMOVE_LAST_PRODUCTS_AVAILABILITY_UPDATE,
)();

export const setProductsFetching = createAction(
  ACTIONS_CONSTANTS.SET_PRODUCTS_FETCHING,
  (fetching: boolean) => fetching,
)();

export const setProductFiltersAction = createAction(
  ACTIONS_CONSTANTS.SET_PRODUCT_FILTERS,
  (filters: ISupplierProductsListFilter) => filters,
)();

export const resetProductFiltersAction = createAction(ACTIONS_CONSTANTS.RESET_PRODUCT_FILTERS)();

export const setQueryFilterAction = createAction(
  ACTIONS_CONSTANTS.SET_PRODUCT_QUERY_FILTER,
  (filters: Pick<ISupplierProductsListFilter, 'query'>) => filters,
)();

export const setVendorFilterAction = createAction(
  ACTIONS_CONSTANTS.SET_PRODUCT_VENDOR_FILTER,
  (filters: Pick<ISupplierProductsListFilter, 'vendors'>) => filters,
)();

export const setShopifyStatusFilterAction = createAction(
  ACTIONS_CONSTANTS.SET_SHOPIFY_STATUS_FILTER,
  (filters: Pick<ISupplierProductsListFilter, 'shopifyStatus'>) => filters,
)();

export const setTypeFilterAction = createAction(
  ACTIONS_CONSTANTS.SET_PRODUCT_TYPE_FILTER,
  (filters: Pick<ISupplierProductsListFilter, 'types'>) => filters,
)();

export const setStockFilterAction = createAction(
  ACTIONS_CONSTANTS.SET_PRODUCT_STOCK_FILTER,
  (filters: Pick<ISupplierProductsListFilter, 'hasStock'>) => filters,
)();

export const setAvailableGroupsFilterAction = createAction(
  ACTIONS_CONSTANTS.SET_PRODUCT_GROUPS_FILTER,
  (filters: Pick<ISupplierProductsListFilter, 'groups'>) => filters,
)();

export const setPageFilterAction = createAction(
  ACTIONS_CONSTANTS.SET_PRODUCT_PAGE_FILTER,
  (filters: Pick<Paginated<ISupplierProductsListFilter>, 'page'>) => filters,
)();

export const setLimitFilterAction = createAction(
  ACTIONS_CONSTANTS.SET_PRODUCT_LIMIT_FILTER,
  (filters: Pick<Paginated<ISupplierProductsListFilter>, 'limit'>) => filters,
)();

export const setInventoryFilterAction = createAction(
  ACTIONS_CONSTANTS.SET_PRODUCT_INVENTORY_FILTER,
  (filters: Pick<Paginated<ISupplierProductsListFilter>, 'productInventory'>) => filters,
)();

export const setUpdatingAllProductsAction = createAction(
  ACTIONS_CONSTANTS.SET_UPDATING_ALL_PRODUCTS,
  (payload: boolean) => payload,
)();

export const getCSVInventoryAction = createAction(
  ACTIONS_CONSTANTS.GET_CSV_INVENTORY,
  (productIds: string[]) => productIds,
)();

export const getAllCSVInventoryAction = createAction(ACTIONS_CONSTANTS.GET_ALL_CSV_INVENTORY)();

export const setIsExportingCSVAction = createAction(
  ACTIONS_CONSTANTS.SET_IS_EXPORTING_CSV,
  (isExporting: boolean) => isExporting,
)();

interface IChangeAvailableGroupsActionPayload {
  productIds: string[];
  retailerGroups: string[];
}
