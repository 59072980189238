import { axiosInstance } from '../../core/api/base.api';

class RetailerApi {
  private backend = process.env.REACT_APP_BACK_END_URL as string;
  private rootPath = '/api/retailer';

  async filterRetailers(q?: string) {
    return await axiosInstance.get<{ _id: string; name: string }[]>(
      `${this.backend}${this.rootPath}/search`,
      {
        params: { q },
      },
    );
  }
}

export const retailerApi = new RetailerApi();
