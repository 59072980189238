import { withCurrency } from '../../helpers/currency.helper';
import { ISupplierVariantMatch } from '../../interfaces/IProduct';
import { AppThumbnail } from '../image-containers/thumbnail/Thumbnail';
import { AppTextStyle } from '../text/text-style/TextStyle';
import { TrackedQuantityLabelForVariant } from '../tracked-quantity-label-for-variant/tracked-quantity-label-for-variant';

type SupplierSyncComponentProps = {
  supplierProduct: ISupplierVariantMatch;
};

export const SupplierSyncProduct = ({ supplierProduct }: SupplierSyncComponentProps) => {
  return (
    <div className="sync-product-wrapper">
      <div className="product-image">
        <AppThumbnail source={supplierProduct.image} alt={supplierProduct.title} />
      </div>
      <div className="sync-product-description">
        <AppTextStyle variation="subdued">{supplierProduct.vendor}</AppTextStyle>
        <AppTextStyle variation="strong">{supplierProduct.productGroupTitle}</AppTextStyle>
        <AppTextStyle variation="strong">
          Variant Name: <span className="fields-values">{supplierProduct.title}</span>
        </AppTextStyle>
        <AppTextStyle variation="strong">
          SKU: <span className="fields-values">{supplierProduct.sku}</span>
        </AppTextStyle>
        <AppTextStyle variation="strong">
          Supplier qty:{' '}
          <span className="fields-values qty">
            <TrackedQuantityLabelForVariant
              quantity={supplierProduct.qty}
              tracked={supplierProduct.tracked}
              inventoryPolicy={supplierProduct.inventoryPolicy}
            />
          </span>
        </AppTextStyle>
        <AppTextStyle variation="strong">
          <div className="cost-msrp">
            <span>
              Cost Price:{' '}
              <span className="fields-values">
                {withCurrency(supplierProduct.priceForRetailers)}
              </span>
            </span>
            <span className="msrp-value">
              SRP: <span className="fields-values">{withCurrency(supplierProduct.MSRP)}</span>
            </span>
          </div>
        </AppTextStyle>
        <AppTextStyle variation="strong">
          Fulfilled by: <span className="fields-values">{supplierProduct.supplier}</span>
        </AppTextStyle>
      </div>
    </div>
  );
};
