import { createReducer } from 'typesafe-actions';
import { IAccount } from '../../../../core/interfaces/IAccount';
import { CoreActions } from '../../actions';
import {
  getAccountInfoAction,
  setAccountInfoAction,
  setAccountInfoFromApiAction,
  postAccountInfoAction,
  getManagePaymentSourcesURLAction,
  setManagePaymentSourcesURLAction,
  showSuccessUpdateToastAction,
  hideSuccessUpdateToastAction,
  connectAccountAction,
  disconnectAccountAction,
} from './account.actions';

export interface IAccountReducer {
  accountInfo: IAccount;
  managePaymentSourcesURL: string;
  fetching: boolean;
  successUpdateToast: boolean;
}

const initialState: IAccountReducer = {
  accountInfo: {
    connected: false,
    synced: true,
    line1: '',
    line2: '',
    city: '',
    state: '',
    zip: '',
    country: '',
    businessName: '',
    taxId: '',
    contactName: '',
    email: '',
    customerEmail: '',
    accountingEmail: '',
    phone: '',
    companyName: '',
  },
  managePaymentSourcesURL: '',
  fetching: false,
  successUpdateToast: false,
};

export const accountReducer = createReducer<IAccountReducer, CoreActions>(initialState)
  .handleAction(connectAccountAction, (state) => ({ ...state }))
  .handleAction(disconnectAccountAction, (state) => ({ ...state }))
  .handleAction(getAccountInfoAction, (state) => ({ ...state, fetching: true }))
  .handleAction(setAccountInfoFromApiAction, (state, { payload }) => ({
    ...state,
    fetching: false,
    accountInfo: {
      line1: payload.billingAddress?.line1 || '',
      line2: payload.billingAddress?.line2 || '',
      city: payload.billingAddress?.city || '',
      state: payload.billingAddress?.state || '',
      zip: payload.billingAddress?.zip || '',
      country: payload.billingAddress?.country || '',

      connected: payload.connected || false,
      companyName: payload.companyName || '',
      synced: payload.synced === undefined ? true : payload.synced,
      businessName: payload.businessName || '',
      taxId: payload.taxId || '',
      contactName: payload.contactName || '',
      email: payload.email || '',
      customerEmail: payload.customerEmail || '',
      accountingEmail: payload.accountingEmail || '',
      phone: payload.phone || '',
    },
  }))
  .handleAction(setAccountInfoAction, (state, { payload }) => ({
    ...state,
    accountInfo: payload,
  }))
  .handleAction(postAccountInfoAction, (state) => ({ ...state }))
  .handleAction(getManagePaymentSourcesURLAction, (state) => ({ ...state }))
  .handleAction(setManagePaymentSourcesURLAction, (state, { payload }) => ({
    ...state,
    managePaymentSourcesURL: payload,
  }))
  .handleAction(showSuccessUpdateToastAction, (state) => ({
    ...state,
    successUpdateToast: true,
  }))
  .handleAction(hideSuccessUpdateToastAction, (state) => ({
    ...state,
    successUpdateToast: false,
  }));
