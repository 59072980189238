import { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { AppBanner } from '../../../../core/components/feedback-indicators/banner/banner';
import { AppLink } from '../../../../core/components/link/link';
import { getStatusSelector } from '../../../redux/modules/status/status.selectors';

export const PaymentPendingConfirmationBanner = () => {
  const { pathname } = useLocation();
  const [show, setShow] = useState<boolean>(true);
  const { payoutPendingPayoutsCount } = useSelector(getStatusSelector);

  const pageLinkOrName = useMemo(
    () =>
      pathname.includes('accounting') ? (
        <span>Invoice History page</span>
      ) : (
        <AppLink url="/accounting?tab=invoice-history&status=payment-pending">
          Invoice History page
        </AppLink>
      ),
    [pathname],
  );

  if (!payoutPendingPayoutsCount || !show) return null;
  return (
    <div className="banner">
      <AppBanner
        status="warning"
        title="Payout pending confirmation"
        onDismiss={() => setShow(false)}
      >
        <p>
          You have {payoutPendingPayoutsCount} payout{payoutPendingPayoutsCount > 1 && 's'} pending
          confirmation. Please, review the payouts on the {pageLinkOrName}.
        </p>
      </AppBanner>
    </div>
  );
};
