import { IAddress } from '../../../../shopify-supplier/interfaces/IOrder';

type ShippingAddressInfoProps = { shippingAddress: IAddress };

export const ShippingAddressInfo = ({ shippingAddress }: ShippingAddressInfoProps) => {
  return (
    <>
      <div>
        {shippingAddress.address1} {shippingAddress.address2}
      </div>
      <div>{shippingAddress.address2}</div>
      <div>
        {shippingAddress.city}, {shippingAddress.provinceCode} {shippingAddress.zip}
      </div>
      <div>{shippingAddress.country}</div>
    </>
  );
};
