import { createSelector } from 'reselect';
import { RootState } from '../../reducers';

export const getMarketplaceFilterOptionsSelector = createSelector(
  [(state: RootState) => state.marketplace.options],
  (options) => options,
);

export const getMarketplaceProductsSelector = createSelector(
  [(state: RootState) => state.marketplace.productView.productList.productGroups],
  (products) => products,
);

export const getTotalMarketplaceProductsCountSelector = createSelector(
  [(state: RootState) => state.marketplace.productView.productList.total],
  (total) => total,
);

export const getMarketplaceProductVariantsSelector = createSelector(
  [(state: RootState, productId: string) => state.marketplace.productView.variants[productId]],
  (variants) => variants || [],
);

export const fetchingMarketplaceProductViewSelector = createSelector(
  [(state: RootState) => state.marketplace.productView.fetching],
  (fetching) => fetching,
);

export const getMarketplaceProductViewFiltersSelector = createSelector(
  [(state: RootState) => state.marketplace.productView.filter],
  (filters) => filters,
);

export const getMarketplaceVariantsSelector = createSelector(
  [(state: RootState) => state.marketplace.variantView.variantList.products],
  (products) => products,
);

export const getMarketplaceConnectedProductsSelector = createSelector(
  [(state: RootState) => state.marketplace.variantView.variantList.connectedProducts],
  (products) => products,
);

export const getTotalMarketplaceVariantsCountSelector = createSelector(
  [(state: RootState) => state.marketplace.variantView.variantList.total],
  (total) => total,
);

export const fetchingMarketplaceVariantViewSelector = createSelector(
  [(state: RootState) => state.marketplace.variantView.fetching],
  (fetching) => fetching,
);

export const getMarketplaceVariantViewFiltersSelector = createSelector(
  [(state: RootState) => state.marketplace.variantView.filter],
  (filters) => filters,
);

export const getMarketplaceConnectionFiltersSelector = createSelector(
  [(state: RootState) => state.marketplace.variantView.retailerProductsFilters],
  (filters) => filters,
);

export const showProductsAddedToImportListToastSelector = createSelector(
  [(state: RootState) => state.marketplace.areProductsAddedToImportList],
  (added) => added,
);
