const type = process.env.REACT_APP_APP_TYPE as string;

enum APP_TYPE {
  SHOPIFY_RETAILER = 'SHOPIFY_RETAILER',
  SHOPIFY_SUPPLIER = 'SHOPIFY_SUPPLIER',
  STANDALONE_RETAILER = 'STANDALONE_RETAILER',
}

if (type === APP_TYPE.SHOPIFY_RETAILER) {
  const { renderShopifyRetailerApp } = require('./shopify-retailer');
  renderShopifyRetailerApp();
} else if (type === APP_TYPE.SHOPIFY_SUPPLIER) {
  const { renderShopifySupplierApp } = require('./shopify-supplier');
  renderShopifySupplierApp();
} else if (type === APP_TYPE.STANDALONE_RETAILER) {
  const { renderStandaloneRetailerApp } = require('./standalone-retailer');
  renderStandaloneRetailerApp();
} else {
  throw new Error('invalid app type');
}

// hack to deal with ts warning
export const hack = () => {};
