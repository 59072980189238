import { createSelector } from 'reselect';
import { RootState } from '../../reducers';

export const getDashboardOrdersSummarySelector = createSelector(
  [(state: RootState) => state.dashboard],
  (dashboardReducer) => dashboardReducer.ordersSummary,
);

export const getDashboardProductsSummarySelector = createSelector(
  [(state: RootState) => state.dashboard],
  (dashboardReducer) => dashboardReducer.productsSummary,
);

export const getDashboardSalesSummarySelector = createSelector(
  [(state: RootState) => state.dashboard],
  (dashboardReducer) => dashboardReducer.salesSummary,
);
