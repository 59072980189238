import React from 'react';
import { useSelector } from 'react-redux';
import { PaymentDetailsFrame } from '../../../../../core/components/account-details/payment-details-frame';
import { AppList } from '../../../../../core/components/list/list';
import { AppLayoutAnnotatedSection } from '../../../../../core/components/structure/layout/layout-annotated-section';
import { getManagePaymentSourcesURLSelector } from '../../../../../core/redux/modules/account/account.selectors';

export const PaymentsSection = () => {
  const managePaymentSourcesURL = useSelector(getManagePaymentSourcesURLSelector);

  const crowdshipWalletDetails = [
    'Your Crowdship wallet securely stores multiple credit cards to for fast, simple, automated payments.',
    'Your wallet can be used to pay for Purchase Orders, Shipping Labels, and Crowdship Plan fees.',
    'To process orders and pay outside of Crowdship, your Supplier will need to enable "Net Terms" for your account in their Crowdship dashboard.',
  ];

  return (
    <AppLayoutAnnotatedSection
      title="Crowdship Wallet"
      description={<AppList>{crowdshipWalletDetails}</AppList>}
    >
      <PaymentDetailsFrame src={managePaymentSourcesURL} />
    </AppLayoutAnnotatedSection>
  );
};
