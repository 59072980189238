import React from 'react';
import { useSelector } from 'react-redux';
import { EmptyState } from '@shopify/polaris';
import { DashboardStatistic } from '../../containers/dashboard-statistic/dashboard-statistic';
import { getInactiveStoreSelector } from '../../../../core/redux/modules/auth/auth.selectors';
import errorImage from '../../../../core/images/Shopify/Empty States/404.svg';

import './dashboard-layout.scss';

interface IDashboardLayout {}

export const DashboardLayout: React.FunctionComponent<IDashboardLayout> = () => {
  const inactiveStore = useSelector(getInactiveStoreSelector);

  if (inactiveStore) {
    return (
      <EmptyState
        heading="Inactive store plan"
        image={'../../../../core/images/Shopify/Empty States/404.svg'}
      >
        <img src={errorImage} alt="" />
        <p>
          You have an inactive Shopify plan. To continue interacting with Crowdship, please update
          your plan.
        </p>
      </EmptyState>
    );
  }

  return <DashboardStatistic />;
};
