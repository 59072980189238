import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { setBreadcrumbsAction } from '../../../redux/modules/breadcrumbs/breadcrumbs.actions';
import { AppTextStyle } from '../../text/text-style/TextStyle';

type LinkToDetailsProps = {
  id: string;
  title: string;
  visible: boolean;
  page?: 'marketplace' | 'my-products';
};

export const LinkToDetails = ({ id, title, visible, page = 'my-products' }: LinkToDetailsProps) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const navigateToDetails = (id: string, title: string) => {
    history.push(`/${page}/${id}`);
    dispatch(setBreadcrumbsAction({ [`${id}`]: title }));
  };

  const className = `link-to-platform${visible ? ' visible' : ''}`;

  return (
    <>
      {' '}
      {page === 'my-products' && <span className={className}>&nbsp;|&nbsp;</span>}
      <span
        className={className}
        onClick={(e) => {
          e.stopPropagation();
          navigateToDetails(id, title);
        }}
      >
        <AppTextStyle variation="subdued">View Details</AppTextStyle>
      </span>
    </>
  );
};
