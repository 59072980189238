import { StatusActiveIcon } from '@shopify/polaris-icons';
import React, { ReactNode, useCallback, useMemo } from 'react';
import { AppButton } from '../../../../core/components/button/Button';
import { AppIcon } from '../../../../core/components/icon/icon';
import { AppLink } from '../../../../core/components/link/link';
import { AppCard } from '../../../../core/components/structure/card/card';
import { AppTextStyle } from '../../../../core/components/text/text-style/TextStyle';
import { withCurrency } from '../../../../core/helpers/currency.helper';
import { getLocaleQuantity } from '../../../../core/helpers/quantity.helper';
import { IPlan } from '../../../interfaces/IPlan';
import './plan-list.scss';
import { useHistory } from 'react-router';

export interface IPlanCard {
  id: string;
  displayName: string;
  position: number;
  description: React.ReactNode;
  limits: React.ReactNode;
  switchTo: React.ReactNode;
}

export const enterprisePlanCard: IPlanCard = {
  id: 'plan-8',
  displayName: 'Plan 6',
  position: 8,
  description: (
    <>
      <p>Price: Bespoke ($1k+)</p>
      <p>Transaction fee: Bespoke (4% - 10%)</p>
    </>
  ),
  limits: <p>No limits</p>,
  switchTo: (
    <div className="change-plan-section">
      <AppButton onClick={() => window.open('https://crowdship.io/contact/#content', '_blank')}>
        Contact us
      </AppButton>
    </div>
  ),
};

type IPlanListProps = {
  plans: IPlan[];
  disable: boolean;
  productsAdded: number | undefined;
  requestPlanChange: (planId: string) => void;
  requestingChange: boolean;
  setSelectedTab: React.Dispatch<React.SetStateAction<number>>;
};

export function PlanList({
  plans,
  disable,
  productsAdded,
  requestPlanChange,
  requestingChange,
  setSelectedTab,
}: IPlanListProps) {
  const history = useHistory();
  const limitExceededMessage = useCallback(
    (productLimit) => (
      <div className="change-plan-section">
        <div className="unavailability-reason">
          <AppTextStyle variation="subdued">
            You must have less than {productLimit} products to switch to this plan (you currently
            have {productsAdded})
          </AppTextStyle>
        </div>
      </div>
    ),
    [productsAdded],
  );

  const noCreditCardMessage = useMemo(
    () => (
      <div className="change-plan-section">
        <AppTextStyle variation="subdued">
          You must have a payment method bound to your account. Please add one at{' '}
          <AppLink
            onClick={() => {
              history.push({ search: '?tab=payments' });
              setSelectedTab(5);
            }}
          >
            your account info page
          </AppLink>
        </AppTextStyle>
      </div>
    ),
    [history, setSelectedTab],
  );

  const renderLimits = (
    orderLimit: number | undefined,
    orderLimitMonthly: boolean | undefined,
    productLimit: number | undefined,
  ) => {
    if (orderLimit === undefined && productLimit === undefined) return <p>No limits</p>;
    let order: ReactNode, product: ReactNode;
    if (orderLimit === undefined) {
      order = <p>Unlimited orders</p>;
    } else if (orderLimit === 0) {
      order = <p>No orders will be processed</p>;
    } else {
      order = (
        <p>
          {orderLimit} orders{orderLimitMonthly ? '/month' : ' total'}
        </p>
      );
    }
    if (productLimit === undefined) {
      product = <p>Unlimited products</p>;
    } else {
      product = <p>{getLocaleQuantity(productLimit)} SKUs</p>;
    }
    return (
      <>
        {order}
        {product}
      </>
    );
  };

  const renderChangePlanButton = useCallback(
    (planId: string) => {
      return (
        <div className="change-plan-section">
          <AppButton onClick={() => requestPlanChange(planId)} loading={requestingChange} primary>
            Change plan
          </AppButton>
        </div>
      );
    },
    [requestPlanChange, requestingChange],
  );

  const renderConfirmChargeButton = useCallback(
    (confirmationUrl: string) => {
      return (
        <div className="change-plan-section">
          <AppButton
            onClick={() => window.open(confirmationUrl, '_blank')}
            loading={requestingChange}
            primary
          >
            Confirm plan change
          </AppButton>
        </div>
      );
    },
    [requestingChange],
  );

  const renderSubscribedLabel = useMemo(() => {
    return (
      <div className="subscribed">
        <AppIcon source={StatusActiveIcon} color="primary" />
        <AppTextStyle variation="positive">Subscribed</AppTextStyle>
      </div>
    );
  }, []);

  const renderChangePlanSection = useCallback(
    (plan: IPlan) => {
      const {
        id: planId,
        isCurrentPlan: isCurrent,
        productLimit,
        isPendingPlan,
        confirmationUrl,
      } = plan;

      if (isPendingPlan && confirmationUrl) return renderConfirmChargeButton(confirmationUrl);
      if (isCurrent) return renderSubscribedLabel;
      // TODO (01.02.24): Always on button with logic to disconnect products
      // if (!position) return renderChangePlanButton(planId);
      if (disable) return noCreditCardMessage;

      const unavailable =
        productLimit !== undefined && productsAdded !== undefined && productLimit < productsAdded;

      if (unavailable) return limitExceededMessage(productLimit);

      return renderChangePlanButton(planId);
    },
    [
      productsAdded,
      renderChangePlanButton,
      renderConfirmChargeButton,
      renderSubscribedLabel,
      disable,
      noCreditCardMessage,
      limitExceededMessage,
    ],
  );

  const mappedPlans: IPlanCard[] = useMemo(() => {
    const mapped: IPlanCard[] = plans.map((p) => ({
      id: p.id,
      displayName: p.displayName,
      position: p.position,
      description: (
        <>
          <p>
            Price: {p.price ? ` ${withCurrency(p.price)}${p.priceMonthly && '/month'}` : ' Free'}
          </p>
          {!!p.txnFee && <p>Transaction fee: {p.txnFee}%</p>}
        </>
      ),
      limits: renderLimits(p.orderLimit, p.orderLimitMonthly, p.productLimit),
      switchTo: renderChangePlanSection(p),
    }));
    if (!mapped.find((m) => m.position === enterprisePlanCard.position)) {
      return mapped.concat(enterprisePlanCard);
    } else return mapped;
  }, [plans, renderChangePlanSection]);

  const renderPlan = (plan: IPlanCard) => (
    <div className="plan-card" key={plan.id}>
      <AppCard
        title={plan.displayName}
        sections={[
          {
            content: plan.description,
          },
          {
            title: 'Limits',
            content: plan.limits,
          },
          {
            content: plan.switchTo,
          },
        ]}
      />
    </div>
  );

  return (
    <div className="cards-container">
      {mappedPlans.sort((a, b) => a.position - b.position).map((p) => renderPlan(p))}
    </div>
  );
}
