export const PRODUCTS_PER_PAGE = 20;
export const IMPORT_LIST_PRODUCTS_PER_PAGE = 5;
export const RETAILER_PRODUCTS_PER_PAGE = 5;
export const FIRST_PAGE = 0;

export type ProductSyncTabId = 'found-matches' | 'unresolved-conflicts' | 'disconnected-products';
export type ProductSyncTab = {
  id: ProductSyncTabId;
  content: React.ReactNode;
  accessibilityLabel: string;
  panelID: string;
};

const matchesTab: ProductSyncTab = {
  id: 'found-matches',
  content: 'Found matches',
  accessibilityLabel: 'Found matches',
  panelID: 'found-matches-content',
};
const conflictsTab: ProductSyncTab = {
  id: 'unresolved-conflicts',
  content: 'Unresolved conflicts',
  accessibilityLabel: 'Unresolved conflicts',
  panelID: 'unresolved-conflicts-content',
};

export const productSyncTabs: ProductSyncTab[] = [matchesTab, conflictsTab];

export enum TIME_INTERVAL {
  NONE = 'All',
  WEEK = 'Last week',
  MONTH = 'Last month',
  SIX_MONTHS = 'Last six months',
  CUSTOM = 'Custom',
}

export enum PRODUCT_CATEGORY {
  NONE = 'NONE',
  CANNABIS = 'Cannabis',
  CANNABIS_ACCESSORIES = 'Cannabis Accessories',
  JEWELRY_AND_WATCHES = 'Jewelry & Watches',
  TECH_AND_ELECTRONICS = 'Tech & Electronics',
  PET_ACCESSORIES = 'Pet Accessories',
  CBD = 'CBD',
  HOME_DECOR = 'Home Decor',
  APPAREL = 'Apparel',
  TOYS_AND_GAMES = 'Toys & Games',
  SEASONAL = 'Seasonal',
}

export enum PRODUCT_VIEW {
  LIST = 'list',
  GRID = 'grid',
  BUILD = 'build',
  SUPPLIERS = 'suppliers',
}

export enum PRODUCT_PROPERTIES {
  HIDE_WITHOUT_PHOTOS = 'Hide without photos',
  HIDE_WITHOUT_DESCRIPTIONS = 'Hide without descriptions',
}

export enum PRODUCT_INVENTORY {
  SHOW_ALL = 'Show all',
  SHOW_IN_STOCK_ONLY = 'Show in stock only',
  SHOW_OUT_OF_STOCK_ONLY = 'Show out of stock only',
}
