import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import '@shopify/polaris/build/esm/styles.css';
import { IntercomProvider } from 'react-use-intercom';
import { App } from './App';
import * as serviceWorker from './serviceWorker';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { store } from './redux/store';

export const renderShopifyRetailerApp = () => {
  ReactDOM.render(
    <React.StrictMode>
      <Provider store={store}>
        <IntercomProvider appId={process.env.REACT_APP_INTERCOM_APP_ID as string}>
          <BrowserRouter>
            <App />
          </BrowserRouter>
        </IntercomProvider>
      </Provider>
    </React.StrictMode>,
    document.getElementById('root'),
  );

  // If you want your app to work offline and load faster, you can change
  // unregister() to register() below. Note this comes with some pitfalls.
  // Learn more about service workers: https://bit.ly/CRA-PWA
  serviceWorker.unregister();
};
