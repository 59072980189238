import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { AppPagination } from './pagination';
import './page-aware-pagination.scss';
import { useLocation } from 'react-router';

type IPageAwarePagination = {
  totalPageCount: number;
  onPageChange: (page: number) => void;
};

export function PageAwarePagination({ totalPageCount, onPageChange }: IPageAwarePagination) {
  const firstPage = 0;
  const [currentPage, setCurrentPage] = useState(firstPage);
  const { search: query } = useLocation();
  const parsedSearchQuery = new URLSearchParams(query);
  const page = parsedSearchQuery.get('page') as string;

  useEffect(() => {
    setCurrentPage(+page || firstPage);
  }, [page, totalPageCount]);

  const hasPrevious = useMemo(() => currentPage !== firstPage, [currentPage]);
  const hasNext = useMemo(
    () => !!totalPageCount && currentPage !== totalPageCount + firstPage - 1,
    [currentPage, totalPageCount],
  );

  const onNext = useCallback(() => {
    const nextPage = currentPage + 1;
    onPageChange(nextPage);
    setCurrentPage(nextPage);
  }, [onPageChange, currentPage]);

  const onPrevious = useCallback(() => {
    const previousPage = currentPage - 1;
    onPageChange(previousPage);
    setCurrentPage(previousPage);
  }, [onPageChange, currentPage]);

  return (
    <div className="pagination-section">
      <AppPagination
        hasNext={hasNext}
        hasPrevious={hasPrevious}
        onNext={onNext}
        onPrevious={onPrevious}
      />
    </div>
  );
}
