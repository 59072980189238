import React from 'react';
import { List, ListProps } from '@shopify/polaris';

type IListProps = ListProps & { children: React.ReactNode[] };

export function AppList(props: IListProps) {
  return (
    <List {...props}>
      {props.children.map((item, i) => (
        <List.Item key={i}>{item}</List.Item>
      ))}
    </List>
  );
}
