import { AxiosResponse } from 'axios';
import { all, put, select, takeLatest } from 'redux-saga/effects';
import { ActionType } from 'typesafe-actions';
import { Paginated } from '../../../../core/helpers/generic.helper';
import { IVariantMatch } from '../../../../core/interfaces/IProduct';
import { IInventoryFilter, IProductListFilter } from '../../../../core/interfaces/IProductFilter';
import { inventoryApi } from '../../../api/inventory.api';
import { IVariantSync } from '../../../interfaces/IProductsSync';
import {
  setInventoryFiltersAction,
  setInventoryQueryFilterAction,
  setInventorySupplierFilterAction,
  setInventoryTypeFilterAction,
  setInventoryVendorFilterAction,
  setInventoryStockFilterAction,
  setInventoryConnectionFilterAction,
  resetInventoryFiltersAction,
} from '../inventory/inventory.actions';
import { getInventoryFiltersSelector } from '../inventory/inventory.selectors';
import {
  getSyncMatchesAction,
  getSyncStatusAction,
  linkAllMatchesAction,
  linkMatchesAction,
  setFetchingMatchesAction,
  setSyncMatchesAction,
  setSyncStatusAction,
  showLinkToastAction,
  startInventorySyncAction,
} from './variant-sync.actions';

function* getSyncStatusSaga(action: ActionType<typeof getSyncStatusAction>) {
  try {
    const { data }: AxiosResponse<Pick<IVariantSync, 'syncInProgress'>> =
      yield inventoryApi.getInventorySyncStatus();
    yield put(setSyncStatusAction(data));
    if (data.syncInProgress) {
      yield put(getSyncMatchesAction(0));
    }
  } catch (e) {
    console.error(e);
  }
}

function* getSyncMatchesSaga(action: ActionType<typeof getSyncMatchesAction>) {
  try {
    const filters: Paginated<IInventoryFilter> = yield select(getInventoryFiltersSelector);
    const res: AxiosResponse<{
      matches: IVariantMatch[];
      total: number;
    }> = yield inventoryApi.getMatches({ ...filters, page: action.payload });
    yield put(setSyncMatchesAction(res.data));
  } catch (e) {
    console.error(e);
  }
}

function* getFilteredSyncMatchesSaga() {
  yield put(setFetchingMatchesAction(true));

  try {
    const filters: Paginated<IInventoryFilter> = yield select(getInventoryFiltersSelector);

    const res: AxiosResponse<{
      matches: IVariantMatch[];
      total: number;
    }> = yield inventoryApi.getMatches({ ...filters, page: 0 });

    yield put(setSyncMatchesAction(res.data));
  } catch (e) {
    console.error(e);

    yield put(setFetchingMatchesAction(false));
  }
}

function* startProductSyncSaga(action: ActionType<typeof startInventorySyncAction>) {
  try {
    yield inventoryApi.startInventorySync();
    yield put(getSyncStatusAction());
  } catch (e) {
    console.error(e);
  }
}

function* linkMatchesSaga(action: ActionType<typeof linkMatchesAction>) {
  try {
    const { data }: AxiosResponse<{ success: true } | { notFound: true }> =
      yield inventoryApi.connectProducts(action.payload);
    if ('success' in data) {
      yield put(
        showLinkToastAction({
          success: data.success,
        }),
      );
    } else {
      yield put(
        showLinkToastAction({
          success: false,
          notFound: data.notFound,
        }),
      );
    }
    yield put(getSyncMatchesAction(0));
  } catch (e) {
    console.error(e);
  }
}

function* linkAllMatchesSaga(action: ActionType<typeof linkAllMatchesAction>) {
  try {
    const filters: IProductListFilter = yield select(getInventoryFiltersSelector);
    const { data }: AxiosResponse<{ success: true } | { notFound: true }> =
      yield inventoryApi.connectProductsAll({ ...filters, ids: action.payload });
    if ('success' in data) {
      yield put(
        showLinkToastAction({
          success: data.success,
        }),
      );
    } else {
      yield put(
        showLinkToastAction({
          success: false,
          notFound: data.notFound,
        }),
      );
    }
  } catch (e) {
    console.error(e);
  } finally {
    yield put(getSyncMatchesAction(0));
  }
}

export function* variantSyncSaga() {
  yield all([
    takeLatest(getSyncStatusAction, getSyncStatusSaga),
    takeLatest(getSyncMatchesAction, getSyncMatchesSaga),
    takeLatest(
      [
        setInventoryFiltersAction,
        setInventoryQueryFilterAction,
        setInventorySupplierFilterAction,
        setInventoryTypeFilterAction,
        setInventoryVendorFilterAction,
        setInventoryStockFilterAction,
        setInventoryConnectionFilterAction,
        resetInventoryFiltersAction,
      ],
      getFilteredSyncMatchesSaga,
    ),
    takeLatest(startInventorySyncAction, startProductSyncSaga),
    takeLatest(linkMatchesAction, linkMatchesSaga),
    takeLatest(linkAllMatchesAction, linkAllMatchesSaga),
  ]);
}
