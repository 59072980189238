import { EmptySearchResult } from '@shopify/polaris';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppButton } from '../../../../core/components/button/Button';
import { AppCheckbox } from '../../../../core/components/checkbox/checkbox';
import { EmptyLayout } from '../../../../core/components/empty-layout/empty-layout';
import { AppPage } from '../../../../core/components/structure/page/page';
import { AppStack } from '../../../../core/components/structure/stack/Stack';
import { IImportListProductGroup } from '../../../api/import-list.api';
import { IRetailerStatus } from '../../../interfaces/IPlan';
import {
  addAllImportListProductToInventoryAction,
  addImportListProductToInventoryAction,
  hideLimitExceededModalAction,
} from '../../../redux/modules/import-list/import-list.actions';
import {
  getTotalImportListProductsCountSelector,
  showImportListFetchingErrorToastSelector as showImportListFetchingErrorSelector,
  showLimitExceededModalSelector,
} from '../../../redux/modules/import-list/import-list.selectors';
import { ProductView } from '../../containers/product-view/product-view';
import { LimitExceededModal } from '../../modals/import-list/limit-exceeded-modal/limit-exceeded-modal';
import { PushingProductModal } from '../../modals/import-list/pushing-product-modal/pushing-product-modal';
import { QtyConfirmationModal } from '../../modals/import-list/qty-confirmation-modal/qty-confirmation-modal';
import { SelectedProduct } from './import-list-layout';

import './import-list.scss';

interface ImportListProps {
  products: IImportListProductGroup[];
  fetching: boolean;
  status: IRetailerStatus;
  pendingProducts: SelectedProduct[];
  totalPageCount: number;
  setPendingProducts: (products: SelectedProduct[]) => void;
}

export function ImportList({
  products,
  fetching,
  status,
  pendingProducts,
  totalPageCount,
  setPendingProducts,
}: ImportListProps) {
  const dispatch = useDispatch();

  const total = useSelector(getTotalImportListProductsCountSelector);
  const showLimitExceededModal = useSelector(showLimitExceededModalSelector);
  const importListFetchingError = useSelector(showImportListFetchingErrorSelector);

  const [openQtyConfirmationModal, setOpenQtyConfirmationModal] = useState(false);
  const [openPushingModal, setOpenPushingModal] = useState(false);
  const [selectedProducts, setSelectedProducts] = useState<SelectedProduct[]>([]);
  const [isAllSelected, setIsAllSelected] = useState<boolean>(false);
  const [quantityConfirmed, setQuantityConfirmed] = useState<boolean>(false);

  const handleCloseLimitModal = useCallback(() => {
    dispatch(hideLimitExceededModalAction());
  }, [dispatch]);

  useEffect(() => {
    setSelectedProducts([]);
  }, [fetching]);

  const handleSelectedProducts = useCallback(
    (product: SelectedProduct, isSelectAll?: boolean) =>
      setSelectedProducts((selected) => {
        if (isSelectAll) return [...selected.filter((p) => p.id !== product.id), product];

        if (isAllSelected) setIsAllSelected(false);

        if (selected.some((p) => p.id === product.id && p.dirty === product.dirty)) {
          return selected.filter((p) => p.id !== product.id);
        }

        if (selected.some((p) => p.id === product.id && p.dirty !== product.dirty)) {
          return [...selected.filter((p) => p.id !== product.id), product];
        }
        return [...selected, product];
      }),
    [isAllSelected],
  );

  const calculatePlanLimit = useCallback(
    (skuCount: number) => status.productsCount + skuCount > (status.productsLimit as number),
    [status.productsCount, status.productsLimit],
  );

  const handleAddToInventory = useCallback(
    (products: SelectedProduct[], importMax?: boolean) => {
      setPendingProducts(products);
      if (products.some((p) => p.dirty)) {
        setSelectedProducts(products);
        setOpenPushingModal(true);
        return;
      }

      if (isAllSelected && !quantityConfirmed) {
        setOpenQtyConfirmationModal(true);
        return;
      }

      dispatch(
        addImportListProductToInventoryAction(
          products.map((p) => p.id),
          !!importMax,
        ),
      );
    },
    [setPendingProducts, isAllSelected, quantityConfirmed, dispatch],
  );

  const pushAllProductsToStore = useCallback(
    (importMax?: boolean) => {
      dispatch(addAllImportListProductToInventoryAction(!!importMax));
    },
    [dispatch],
  );

  const handlePushingModalClose = useCallback(() => {
    setOpenPushingModal(false);
  }, []);

  const toggleSelectAll = useCallback(() => {
    setIsAllSelected((prev) => !prev);
  }, []);

  const selectText = useMemo(() => {
    if (isAllSelected && totalPageCount > 1)
      return `${
        products.length === selectedProducts.length ? products.length : selectedProducts.length
      }+ Selected`;

    return `${selectedProducts.length} Selected`;
  }, [totalPageCount, isAllSelected, selectedProducts.length, products.length]);

  return (
    <AppPage title="Import list">
      <div className="import-list__selected">
        {selectedProducts.length !== 0 && (
          <AppStack alignment="center">
            <AppCheckbox
              label={selectText}
              checked={!!selectedProducts.length}
              onChange={() => setSelectedProducts([])}
            />
            <AppButton onClick={() => handleAddToInventory(selectedProducts)}>
              Add to Inventory
            </AppButton>
            <AppButton plain onClick={toggleSelectAll}>
              {!isAllSelected ? 'Select all' : 'Undo'}
            </AppButton>
          </AppStack>
        )}
      </div>
      <EmptyLayout
        empty={!products.length}
        emptyComponent={
          <div className="import-list__no-results">
            <EmptySearchResult
              title={
                importListFetchingError
                  ? 'Issues when fetching import list. Please try again later'
                  : 'No Products found'
              }
              withIllustration
            />
          </div>
        }
      >
        {products.map((product) => (
          <ProductView
            key={product.id}
            product={product}
            disableAdding={calculatePlanLimit(product.variationsNumber)}
            selectAll={isAllSelected}
            selectedProducts={selectedProducts}
            onSelectionHandler={handleSelectedProducts}
            handleAddToInventory={handleAddToInventory}
          />
        ))}
        <QtyConfirmationModal
          open={openQtyConfirmationModal}
          qty={total}
          onClose={() => setOpenQtyConfirmationModal(false)}
          onConfirm={() => {
            setQuantityConfirmed(true);
            setOpenQtyConfirmationModal(false);
            pushAllProductsToStore();
          }}
        />
        <PushingProductModal
          openPushingModal={openPushingModal}
          selectedProducts={selectedProducts}
          handleClose={handlePushingModalClose}
          handleAddToInventory={handleAddToInventory}
        />
        <LimitExceededModal
          open={showLimitExceededModal}
          selectedProducts={pendingProducts}
          handleClose={handleCloseLimitModal}
          handleAddToInventory={handleAddToInventory}
        />
      </EmptyLayout>
    </AppPage>
  );
}
