import { useCallback } from 'react';
import { PRODUCT_CATEGORY } from '../../../../../constants/product.constants';
import { AppChoiceList } from '../../../../choice-list/ChoiceList';
import { FilterElementProps } from './filter-elements.interface';

export const CATEGORY_FILTER_KEY = 'selectedCategory';
const CATEGORY_FILTER_LABEL = 'Category';
const CATEGORY_FILTER_TITLE = 'Category list';

const categoryOptions = Object.values(PRODUCT_CATEGORY)
  .filter((c) => c !== PRODUCT_CATEGORY.NONE)
  .map((c) => ({ label: c, value: c }));

const Filter = ({ value, onChange }: FilterElementProps<string>) => {
  const handleChange = useCallback(
    ([updated]: PRODUCT_CATEGORY[]) => {
      onChange(updated);
    },
    [onChange],
  );

  return (
    <AppChoiceList
      title={CATEGORY_FILTER_TITLE}
      titleHidden
      choices={categoryOptions}
      selected={[value]}
      onChange={handleChange}
    />
  );
};

export const CategoryFilter = ({ value, onChange }: FilterElementProps<string>) => ({
  key: CATEGORY_FILTER_KEY,
  label: CATEGORY_FILTER_LABEL,
  shortcut: true,
  hideClearButton: true,
  filter: <Filter onChange={onChange} value={value} />,
});
