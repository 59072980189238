import React from 'react';
import { ResourceItem, Avatar, ResourceItemProps, DisableableAction } from '@shopify/polaris';

enum IMAGE_SIZE {
  SMALL = 'small',
  MEDUIM = 'medium',
  LARGE = 'large',
}

type IResourceItemProps = ResourceItemProps & {
  _key?: string | number;
  noMedia?: boolean; // don't set default avatar
  avatarSize?: IMAGE_SIZE;
  avatarCustomer?: boolean; // makes default image gray
  avatarSource?: string;
  name?: string;
};

export type IAction = DisableableAction;

export function AppResourceItem(props: IResourceItemProps) {
  const { _key, noMedia, avatarCustomer, avatarSize, avatarSource, name } = props;

  const media = noMedia ? undefined : (
    <Avatar customer={avatarCustomer} size={avatarSize} name={name} source={avatarSource} />
  );

  return <ResourceItem {...props} media={media} key={_key} />;
}
