import { createReducer } from 'typesafe-actions';
import { IRetailerOrdersWithCount } from '../../../api/orders.api';
import {
  IOrderDetails,
  IRetailerOrdersSummary,
  RETAILER_ORDER_PROCESSING_ERROR,
} from '../../../interfaces/IOrder';
import { RootActions } from '../../root.actions';
import {
  acceptCrowdshipCancellationAction,
  cancelOrderAction,
  chargeForOrderAction,
  getOrdersSummaryAction,
  hideAddressValidationFailedToastAction,
  hideAddressValidationSuccessToastAction,
  hidePaymentFailedToastAction,
  hideUpdatingOrderStatusToastAction,
  requestCancellationAction,
  revokeCancellationAction,
  setAddressValidationAction,
  setOrderDetailsAction,
  setOrderDetailsFetchingAction,
  setOrderListFetchingAction,
  setOrdersAction,
  setOrdersSummaryAction,
  setProcessingAction,
  setShippingAddressAction,
  showAddressValidationFailedToastAction,
  showAddressValidationSuccessToastAction,
  showPaymentFailedToastAction,
} from './orders.actions';

export interface IOrdersReducer {
  ordersSummary: IRetailerOrdersSummary;
  orderList: IRetailerOrdersWithCount;
  orderDetails: {
    [orderId: string]: IOrderDetails;
  };
  fetchingOrderList: boolean;
  requestingCancellation: boolean;
  showPaymentFailedToast: boolean;
  showUpdatingOrderStatusToast: boolean;
  showAddressValidationFailedToast: boolean;
  showAddressValidationSuccessToast: boolean;
  validatingAddress: boolean;
  processing: boolean;
  fetchingOrderDetails: boolean;
}

const initialState: IOrdersReducer = {
  ordersSummary: {
    totalOrdersCount: 0,
    unfulfilledOrdersCount: 0,
    totalEarned: 0.0,
  },
  fetchingOrderList: false,
  orderList: { total: 0, orders: [] },
  orderDetails: {},
  requestingCancellation: false,
  showPaymentFailedToast: false,
  showUpdatingOrderStatusToast: false,
  showAddressValidationFailedToast: false,
  showAddressValidationSuccessToast: false,
  validatingAddress: false,
  processing: false,
  fetchingOrderDetails: false,
};

export const orderReducer = createReducer<IOrdersReducer, RootActions>(initialState)
  .handleAction(setOrderListFetchingAction, (state, { payload }) => ({
    ...state,
    fetchingOrderList: payload,
  }))
  .handleAction(setOrderDetailsFetchingAction, (state, { payload }) => ({
    ...state,
    fetchingOrderDetails: payload,
  }))
  .handleAction(setOrdersAction, (state, { payload }) => ({
    ...state,
    orderList: payload,
  }))
  .handleAction(getOrdersSummaryAction, (state) => ({
    ...state,
  }))
  .handleAction(setOrdersSummaryAction, (state, { payload }) => ({
    ...state,
    ordersSummary: payload,
  }))
  .handleAction(requestCancellationAction, (state) => ({
    ...state,
    requestingCancellation: true,
  }))
  .handleAction(revokeCancellationAction, (state) => ({
    ...state,
    requestingCancellation: true,
  }))
  .handleAction(showPaymentFailedToastAction, (state) => ({
    ...state,
    showPaymentFailedToast: true,
  }))
  .handleAction(hidePaymentFailedToastAction, (state) => ({
    ...state,
    showPaymentFailedToast: false,
  }))
  .handleAction(showAddressValidationFailedToastAction, (state) => ({
    ...state,
    showAddressValidationFailedToast: true,
    validatingAddress: false,
  }))
  .handleAction(hideAddressValidationFailedToastAction, (state) => ({
    ...state,
    showAddressValidationFailedToast: false,
  }))
  .handleAction(showAddressValidationSuccessToastAction, (state) => ({
    ...state,
    showAddressValidationSuccessToast: true,
    validatingAddress: false,
  }))
  .handleAction(hideAddressValidationSuccessToastAction, (state) => ({
    ...state,
    showAddressValidationSuccessToast: false,
  }))
  .handleAction(chargeForOrderAction, (state) => ({
    ...state,
    showUpdatingOrderStatusToast: true,
  }))
  .handleAction(acceptCrowdshipCancellationAction, (state) => ({
    ...state,
    showUpdatingOrderStatusToast: true,
  }))
  .handleAction(cancelOrderAction, (state) => ({
    ...state,
    showUpdatingOrderStatusToast: true,
  }))
  .handleAction(hideUpdatingOrderStatusToastAction, (state) => ({
    ...state,
    showUpdatingOrderStatusToast: false,
  }))
  .handleAction(setOrderDetailsAction, (state, { payload: { details, orderId } }) => ({
    ...state,
    orderDetails: {
      ...state.orderDetails,
      [orderId]: details,
    },
    requestingCancellation: false,
  }))
  .handleAction(setProcessingAction, (state, { payload }) => ({
    ...state,
    processing: payload,
  }))
  .handleAction(setAddressValidationAction, (state, { payload }) => ({
    ...state,
    validatingAddress: payload,
  }))
  .handleAction(setShippingAddressAction, (state, { payload: { orderId, address } }) => ({
    ...state,
    validatingAddress: false,
    orderDetails: {
      ...state.orderDetails,
      [orderId]: {
        ...state.orderDetails[orderId],
        error: {
          type: RETAILER_ORDER_PROCESSING_ERROR.PENDING_REPROCESSING,
          message: '',
        },
        shippingAddress: {
          ...state.orderDetails[orderId].shippingAddress,
          ...address,
        },
      },
    },
  }));
