import { AppTextStyle } from '../../text/text-style/TextStyle';

type AverageMarginProps = {
  margin: number | null;
};

export const AverageMargin = ({ margin }: AverageMarginProps) => (
  <AppTextStyle variation={margin && margin > 0 ? 'positive' : 'negative'}>
    {(margin !== null ? margin : '--') + '%'}
  </AppTextStyle>
);
