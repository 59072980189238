import { useCallback, useEffect, useMemo, useState } from 'react';
import { Field, useFormikContext } from 'formik';
import { Autocomplete } from '@shopify/polaris';
import { OptionDescriptor } from '@shopify/polaris/build/ts/latest/src/types';

type AutocompleteTextField = {
  name: string;
  options: OptionDescriptor[];
  label?: string;
  validate?: (value: string) => string | undefined;
  onChange?: (value: string) => void;
  disabled?: boolean;
};

export const AppControlledAutocompleteTextField = ({
  name,
  validate,
  options,
  label = '',
  onChange,
  disabled,
}: AutocompleteTextField) => {
  const {
    registerField,
    unregisterField,
    getFieldProps,
    getFieldMeta,
    setFieldValue,
    setFieldError,
  } = useFormikContext();

  useEffect(() => {
    if (name) registerField(name, { validate });
    return () => {
      if (name) unregisterField(name);
    };
  }, [name, validate, registerField, unregisterField]);

  const field = getFieldProps({ name });
  const meta = getFieldMeta(name);

  const [selectedOptions, setSelectedOptions] = useState<string[]>([]);
  const [autocompleteOptions, setAutocompleteOptions] = useState<OptionDescriptor[]>(options);

  const handleChange = useCallback(
    (value) => {
      setFieldValue(name, value, !disabled);
      if (onChange) onChange(value);

      if (value === '') {
        setAutocompleteOptions(options);
        return;
      }

      const filterRegex = new RegExp(value, 'i');
      const resultOptions = options.filter((option) => option.value.match(filterRegex));
      setAutocompleteOptions(resultOptions);
    },
    [options, name, onChange, setFieldValue, disabled],
  );

  const updateSelection = useCallback(
    (selected: string[]) => {
      const [selectedValue] = selected.map((selectedItem) => {
        const matchedOption = options.find((option) => option.value === selectedItem);
        return matchedOption && matchedOption.label;
      });

      setSelectedOptions(selected);
      if (selectedValue) setFieldValue(name, selectedValue, !disabled);
    },
    [options, name, setFieldValue, disabled],
  );

  const handleBlur = useCallback(() => {
    field.onBlur({ target: { name } });
  }, [field, name]);

  const handleFocus = useCallback(() => {
    setFieldError(name, '');
  }, [name, setFieldError]);

  const error = useMemo(() => {
    if (meta.error && meta.touched) {
      return meta.error;
    }
  }, [meta.error, meta.touched]);

  return (
    <Field name={name} validate={validate}>
      {() => (
        <Autocomplete
          options={autocompleteOptions}
          selected={selectedOptions}
          onSelect={updateSelection}
          textField={
            <Autocomplete.TextField
              disabled={disabled}
              label={label}
              id={name}
              value={field.value}
              onChange={handleChange}
              onBlur={handleBlur}
              onFocus={handleFocus}
              error={error}
              autoComplete="off"
            />
          }
        />
      )}
    </Field>
  );
};
