import { createSelector } from 'reselect';
import { RootState } from '../../../../shopify-supplier/redux/reducers';

export const getViewAsClientSettingSelector = createSelector(
  [(state: RootState) => state.admin],
  (adminReducer) => adminReducer.settings.viewAsClient,
);

export const getAdminSettingsUpdatedSelector = createSelector(
  [(state: RootState) => state.admin],
  (adminReducer) => adminReducer.settingsUpdated,
);
