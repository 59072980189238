import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { ordersApi } from '../../../shopify-supplier/api/orders.api';
import { OrderStatus } from '../../../shopify-supplier/components/containers/order-status/order-status';
import { withCurrency } from '../../helpers/currency.helper';
import { getFormattedDate } from '../../helpers/date.helper';
import { ICurrency } from '../../interfaces/ICurrency';
import { IRefundDetails } from '../../interfaces/IRefundDetails';
import { AppSkeletonBodyText } from '../feedback-indicators/skeleton/skeleton-body-text/skeleton-body-text';
import { AppSkeletonPage } from '../feedback-indicators/skeleton/skeleton-page/skeleton-page';
import { ShippingAddress } from '../order-details/shipping-address/ShippingAddress';
import { AppCard } from '../structure/card/card';
import { CardSubsection } from '../structure/card/card-subsection';
import { AppPage } from '../structure/page/page';
import '../../../shopify-supplier/components/containers/order-details/order-details.scss';

type IRefundsDetailsPage = {
  currency?: ICurrency;
  refundId?: string;
};

export function RefundsDetailsPage({
  refundId,
  currency = { isoCode: 'USD', symbol: '$' },
}: IRefundsDetailsPage) {
  const { invoiceItemId } = useParams<{ invoiceItemId: string }>();
  const [details, setDetails] = useState<IRefundDetails | null>(null);
  useEffect(() => {
    ordersApi
      .getRefundDetails(refundId || invoiceItemId)
      .then(({ data }) => setDetails(data))
      .catch(console.error);
  }, [invoiceItemId, refundId]);

  const skeletonPage = useMemo(
    () => (
      <AppSkeletonPage>
        <AppCard sectioned>
          <AppSkeletonBodyText />
        </AppCard>
        <AppCard sectioned>
          <AppSkeletonBodyText />
        </AppCard>
      </AppSkeletonPage>
    ),
    [],
  );
  const renderTotalsDesc = useCallback(() => {
    return details ? (
      <CardSubsection>
        <div className="totals-desc-list">
          <div className="totals-desc-row bold">
            <div className="title">Refund</div>
            <div className="spacer" />
            <div className="cost">-{withCurrency(details.amount, currency)}</div>
          </div>

          <div className="totals-desc-row bold">
            <div className="title">Total</div>
            <div className="spacer" />
            <div className="cost">-{withCurrency(details.amount, currency)}</div>
          </div>
        </div>
      </CardSubsection>
    ) : null;
  }, [details, currency]);

  return !details ? (
    skeletonPage
  ) : (
    <AppPage
      title={`Order ${details.name}`}
      titleMetadata={<OrderStatus status={details.payoutStatus || details.status} />}
      subtitle={getFormattedDate(details.createdAt)}
    >
      <div className="supplier-order-details">
        <div className="main-info">
          <div className="shipping">
            <AppCard sectioned>
              <div>Retailer: {details.retailer}</div>
              <div>Retailer order name: {details.retailerOrderName}</div>
              {details.reason && <div>Reason: {details.reason}</div>}
            </AppCard>
          </div>
          <ShippingAddress shippingAddress={details.shippingAddress} />
        </div>

        <AppCard title={'Totals'} sections={[{ content: renderTotalsDesc() }]} />
      </div>
    </AppPage>
  );
}
