import React from 'react';
import { AppBanner } from '../../feedback-indicators/banner/banner';
import { AppLink } from '../../link/link';

import './api-updates-banner.scss';

interface IApiUpdatesBannerProps {
  permissionUrl: string;
}

export function ApiUpdatesBanner({ permissionUrl }: IApiUpdatesBannerProps) {
  return (
    <div className="api-update-banner">
      <AppBanner status="info" title="Shopify Update">
        Crowdship has been upgraded to meet Shopify’s latest requirements. Fulfillments will stop
        working unless updated. Please{' '}
        <AppLink onClick={() => window.open(permissionUrl, '_blank')} external>
          click here
        </AppLink>
        to update.
      </AppBanner>
    </div>
  );
}
