type AvailableCarrier = 'USPS' | 'FedEx' | 'DHL Express' | 'Canada Post';

export enum DELIVERY_TIME {
  OVERNIGHT = 'overnight',
  ONE_DAY = 'one day',
  UP_TO_3 = 'up to 3 days',
  UP_TO_7 = 'up to 7 days',
  UP_TO_14 = 'up to 14 days',
  UP_TO_21 = 'up to 21 days',
  NONE = '',
}

interface ICarrierService {
  name: string;
  estimate: DELIVERY_TIME;
}

export type UnspecifiedServices =
  | 'cheapest'
  | 'fastest'
  | 'oneDay'
  | 'upTo3Days'
  | 'upTo7Days'
  | 'upTo14Days'
  | 'upTo21Days';

export const unspecifiedCarrierServiceConstants: {
  [service in UnspecifiedServices]: ICarrierService;
} = {
  cheapest: { name: 'Cheapest', estimate: DELIVERY_TIME.UP_TO_14 },
  fastest: { name: 'Fastest', estimate: DELIVERY_TIME.OVERNIGHT },
  oneDay: { name: 'General (one day delivery)', estimate: DELIVERY_TIME.ONE_DAY },
  upTo3Days: {
    name: 'General (up to 3 days delivery)',
    estimate: DELIVERY_TIME.UP_TO_3,
  },
  upTo7Days: {
    name: 'General (up to 7 days delivery)',
    estimate: DELIVERY_TIME.UP_TO_7,
  },
  upTo14Days: {
    name: 'General (up to 14 days delivery)',
    estimate: DELIVERY_TIME.UP_TO_14,
  },
  upTo21Days: {
    name: 'General (up to 21 day delivery)',
    estimate: DELIVERY_TIME.UP_TO_21,
  },
};

export const carrierServiceConstants: {
  [carrier in AvailableCarrier]: { [service: string]: ICarrierService };
} = {
  USPS: {
    usps_priority: { name: 'Priority Mail', estimate: DELIVERY_TIME.UP_TO_3 },
    usps_priority_express: {
      name: 'Priority Mail Express',
      estimate: DELIVERY_TIME.OVERNIGHT,
    },

    usps_ground_advantage: { name: 'Ground Advantage', estimate: DELIVERY_TIME.UP_TO_7 }, // 2-5

    // international

    usps_priority_mail_international: {
      name: 'Priority Mail International',
      estimate: DELIVERY_TIME.UP_TO_14,
    }, // 6-10
    usps_priority_mail_express_international: {
      name: 'Priority Mail Express International',
      estimate: DELIVERY_TIME.UP_TO_7,
    }, // 3-5
    usps_first_class_package_international_service: {
      name: 'First Class Package International',
      estimate: DELIVERY_TIME.UP_TO_21,
    }, // 7-21
  },
  FedEx: {
    fedex_ground: { name: 'Ground®', estimate: DELIVERY_TIME.UP_TO_7 },
    fedex_ground_economy: {
      name: 'Ground® Economy',
      estimate: DELIVERY_TIME.UP_TO_21,
    },
    fedex_home_delivery: { name: 'Home Delivery®', estimate: DELIVERY_TIME.UP_TO_7 },
    fedex_smart_post: { name: 'SmartPost®', estimate: DELIVERY_TIME.UP_TO_7 },
    fedex_2_day: { name: '2Day®', estimate: DELIVERY_TIME.UP_TO_3 }, // two days
    fedex_2_day_am: { name: '2Day® A.M.', estimate: DELIVERY_TIME.UP_TO_3 }, // two days
    fedex_express_saver: { name: 'Express Saver®', estimate: DELIVERY_TIME.UP_TO_3 }, // three days
    fedex_standard_overnight: {
      name: 'Standard Overnight®',
      estimate: DELIVERY_TIME.OVERNIGHT,
    },
    fedex_priority_overnight: {
      name: 'Priority Overnight®',
      estimate: DELIVERY_TIME.OVERNIGHT,
    },
    fedex_first_overnight: { name: 'First Overnight®', estimate: DELIVERY_TIME.OVERNIGHT },
    fedex_international_priority_express: {
      name: 'International Priority® Express',
      estimate: DELIVERY_TIME.UP_TO_3,
    },

    // international

    fedex_international_economy: {
      name: 'International Economy®',
      estimate: DELIVERY_TIME.UP_TO_7,
    }, // 4-6
    fedex_international_priority: {
      name: 'International Priority®',
      estimate: DELIVERY_TIME.UP_TO_3,
    },
    fedex_international_first: {
      name: 'International First®',
      estimate: DELIVERY_TIME.UP_TO_3,
    },
  },
  'DHL Express': {
    dhl_express_worldwide: { name: 'Worldwide', estimate: DELIVERY_TIME.UP_TO_7 },
  },
  'Canada Post': {
    canada_post_xpresspost: {
      name: 'Xpresspost',
      estimate: DELIVERY_TIME.UP_TO_3,
    },
    canada_post_regular_parcel: {
      name: 'Regular Parcel',
      estimate: DELIVERY_TIME.UP_TO_7,
    },
    canada_post_priority: {
      name: 'Priority',
      estimate: DELIVERY_TIME.UP_TO_3,
    },
    canada_post_expedited_parcel: {
      name: 'Expedited Parcel',
      estimate: DELIVERY_TIME.UP_TO_3,
    },
  },
};

export const USA_COUNTRY_CODE = '1';
