import { createAction } from 'typesafe-actions';
import {
  ICancelOrderLineItemsBody,
  IGetOrdersQueryParams,
  IGetTotalForFilteredInvoiceItemsResponse,
  IRefundOrderBody,
  IRetailersForUnpaidOrders,
} from '../../../api/orders.api';
import {
  IOrderDetails,
  IOrderList,
  IOrderListFilter,
  IOrdersSummary,
  IPayoutRequest,
  IUnpaidOrdersSummary,
} from '../../../interfaces/IOrder';
import { IPayoutList } from '../../../interfaces/IPayout';
import { ACTIONS_CONSTANTS } from '../../actions-constants';

export const getOrdersSummaryAction = createAction(ACTIONS_CONSTANTS.ORDERS_GET_ORDERS_SUMMARY)();

export const getUnpaidOrdersSummaryAction = createAction(
  ACTIONS_CONSTANTS.GET_UNPAID_ORDERS_SUMMARY,
)();

export const setOrdersSummaryAction = createAction(
  ACTIONS_CONSTANTS.ORDERS_SET_ORDERS_SUMMARY,
  (summary: IOrdersSummary) => summary,
)();

export const setUnpaidOrdersSummaryAction = createAction(
  ACTIONS_CONSTANTS.SET_UNPAID_ORDERS_SUMMARY,
  (summary: IUnpaidOrdersSummary) => summary,
)();

export const getOrdersListAction = createAction(
  ACTIONS_CONSTANTS.GET_ORDERS_LIST,
  (params: IGetOrdersQueryParams) => params,
)();

export const getUnpaidOrdersListAction = createAction(
  ACTIONS_CONSTANTS.GET_UNPAID_ORDERS_LIST,
  (page: number) => page,
)();

export const getRetailersForUnpaidOrdersAction = createAction(
  ACTIONS_CONSTANTS.GET_RETAILERS_FOR_UNPAID_ORDERS,
)();

export const setRetailersForUnpaidOrdersAction = createAction(
  ACTIONS_CONSTANTS.SET_RETAILERS_FOR_UNPAID_ORDERS,
  (reatilers: IRetailersForUnpaidOrders[]) => reatilers,
)();

export const getPayoutsAction = createAction(
  ACTIONS_CONSTANTS.GET_PAYOUTS,
  (page: number, status?: string, query?: string) => ({ page, status, query }),
)();

export const setPayoutListAction = createAction(
  ACTIONS_CONSTANTS.SET_PAYOUT_LIST,
  (payoutList: IPayoutList) => payoutList,
)();

export const createNewPayoutAction = createAction(
  ACTIONS_CONSTANTS.CREATE_NEW_PAYOUT,
  (payoutDetails: IPayoutRequest & { retailerId: string; ids: string[] }) => payoutDetails,
)();

export const updatePayoutAction = createAction(
  ACTIONS_CONSTANTS.UPDATE_PAYOUT,
  (payoutId: string, payoutDetails: IPayoutRequest, page: number) => ({
    payoutId,
    payoutDetails,
    page,
  }),
)();

export const setPayoutFetchingAction = createAction(
  ACTIONS_CONSTANTS.SET_PAYOUT_FETCHING,
  (status: boolean) => status,
)();

export const getUnpaidOrdersTotalsAllAction = createAction(
  ACTIONS_CONSTANTS.GET_UNPAID_ORDERS_TOTALS_ALL,
  (
    retailerId?: string,
    dateRange?: {
      start: number;
      end: number;
    },
  ) => ({
    retailerId,
    dateRange,
  }),
)();

export const setOrdersListAction = createAction(
  ACTIONS_CONSTANTS.SET_ORDERS_LIST,
  (orders: IOrderList) => orders,
)();

export const setUnpaidOrdersListAction = createAction(
  ACTIONS_CONSTANTS.SET_UNPAID_ORDERS_LIST,
  (orders: IOrderList) => orders,
)();

export const setOrdersListFetchingAction = createAction(
  ACTIONS_CONSTANTS.SET_ORDERS_LIST_FETCHING,
  (status: boolean) => status,
)();

export const setUnpaidOrdersListFetchingAction = createAction(
  ACTIONS_CONSTANTS.SET_UNPAID_ORDERS_LIST_FETCHING,
  (status: boolean) => status,
)();

export const setOrdersSummaryFetchingAction = createAction(
  ACTIONS_CONSTANTS.SET_ORDERS_SUMMARY_FETCHING,
  (status: boolean) => status,
)();

export const setUnpaidOrdersTotalDetailsAction = createAction(
  ACTIONS_CONSTANTS.SET_UNPAID_ORDERS_TOTALS_DETAILS,
  (totalDetails: IGetTotalForFilteredInvoiceItemsResponse) => totalDetails,
)();

export const getOrderDetailsAction = createAction(
  ACTIONS_CONSTANTS.GET_ORDER_DETAILS,
  (orderId: string) => orderId,
)();

export const setOrderDetailsAction = createAction(
  ACTIONS_CONSTANTS.SET_ORDER_DETAILS,
  (orderId: string, details: IOrderDetails) => ({ orderId, details }),
)();

export const orderListFilterAction = createAction(
  ACTIONS_CONSTANTS.ORDER_LIST_FILTERS,
  (filters: IOrderListFilter) => ({ ...filters }),
)();

export const setOrderListFilterAction = createAction(
  ACTIONS_CONSTANTS.SET_ORDER_LIST_FILTERS,
  (filters: IOrderListFilter) => ({ ...filters }),
)();

export const acceptCancellationRequestAction = createAction(
  ACTIONS_CONSTANTS.ACCEPT_CANCELLATION_REQUEST,
  (orderId: string) => orderId,
)();

export const finishAcceptCancellationRequestAction = createAction(
  ACTIONS_CONSTANTS.FINISH_ACCEPT_CANCELLATION_REQUEST,
)();

export const declineCancellationRequestAction = createAction(
  ACTIONS_CONSTANTS.DECLINE_CANCELLATION_REQUEST,
  (orderId: string) => orderId,
)();

export const refundOrderAction = createAction(
  ACTIONS_CONSTANTS.REFUND_ORDER,
  (orderId: string, body: IRefundOrderBody) => ({ orderId, body }),
)();

export const hideAcceptingCancellationToastAction = createAction(
  ACTIONS_CONSTANTS.HIDE_ACCEPTING_CANCELLATION_TOAST,
)();

export const hideDecliningCancellationToastAction = createAction(
  ACTIONS_CONSTANTS.HIDE_DECLINING_CANCELLATION_TOAST,
)();

export const cancelLineItemsAction = createAction(
  ACTIONS_CONSTANTS.CANCEL_LINE_ITEMS,
  (orderId: string, body: ICancelOrderLineItemsBody) => ({
    orderId: orderId,
    body,
  }),
)();
