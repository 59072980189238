import { OptionList, useIndexResourceState } from '@shopify/polaris';
import queryString from 'query-string';
import { SelectionType } from '@shopify/polaris/build/ts/latest/src/utilities/index-provider';
import fileDownload from 'js-file-download';
import moment from 'moment';
import React, { useCallback, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router';
import { useDebouncedCallback } from 'use-debounce';
import { AppFilters, IAppliedFilter } from '../../../../core/components/filters/filters';
import { ICurrency } from '../../../../core/interfaces/ICurrency';
import { IGetOrdersQueryParams, ordersApi } from '../../../api/orders.api';
import { ISupplierOrder } from '../../../interfaces/IOrder';
import { setOrdersListFetchingAction } from '../../../redux/modules/orders/orders.actions';
import { OrderList } from './order-list';
import './order-list.scss';

interface IOrderListWithTabsProps {
  fetching: boolean;
  orders: ISupplierOrder[];
  totalPageCount: number;
  filterParams: IGetOrdersQueryParams;
  onFilterChange: (params: IGetOrdersQueryParams) => void;
  currency: ICurrency;
  handlePrintLabels: (allOrdersSelected: boolean, selectedOrders: string[]) => void;
}

export const OrderListWithTabs: React.FunctionComponent<IOrderListWithTabsProps> = ({
  fetching,
  orders,
  totalPageCount,
  onFilterChange,
  filterParams,
  currency,
  handlePrintLabels,
}) => {
  const { search: query } = useLocation();
  const history = useHistory();

  const [searchString, setSearchString] = useState(query);
  const [selectedOrders, setSelectedOrders] = useState<string[]>([]);
  const [allOrdersSelected, setAllOrdersSelected] = useState<boolean>(false);

  const dispatch = useDispatch();

  const { statuses: URLStatuses, query: searchQueryString } = queryString.parse(query, {
    arrayFormat: 'separator',
    arrayFormatSeparator: '.',
  });

  const changeUrlParams = useCallback(
    (params: IGetOrdersQueryParams) => {
      const search = `?${
        params
          ? Object.keys(params)
              .map((p) => {
                const value = params[`${p}`];
                if (!value) return '';
                if (p === 'dateRange') return '';
                if (Array.isArray(value))
                  return value.length
                    ? `${queryString.stringify(
                        { [p]: value.map((v) => v.replace(' ', '-')) },
                        { arrayFormat: 'separator', arrayFormatSeparator: '.' },
                      )}`
                    : '';
                return `&${p}=${value}`;
              })
              .join('&')
          : ''
      }`;
      history.push({
        search,
      });
      setSearchString(search);
    },
    [history],
  );

  const formatOrders = useMemo(
    () =>
      orders.map((o) => ({
        ...o,
        id: `${o._id}`,
      })),
    [orders],
  );

  const { selectedResources, allResourcesSelected, handleSelectionChange } =
    useIndexResourceState(formatOrders);

  const deselectAll = useCallback(() => {
    handleSelectionChange('page' as SelectionType, false);
  }, [handleSelectionChange]);

  const selectionChanged = (
    selectionType: SelectionType,
    isSelecting: boolean,
    selection?: any,
  ) => {
    // when selecting a single item, ignore if limit is reached (disableAdding) or if the item is processing
    if (selectionType === 'all') setAllOrdersSelected(true);
    // if all items are selected, any other selection operation will toggle this flag
    else if (allResourcesSelected) setAllOrdersSelected(false);
    handleSelectionChange(selectionType, isSelecting, selection);
  };

  const [selectedStatuses, setSelectedStatuses] = useState<string[]>(
    !!URLStatuses
      ? Array.isArray(URLStatuses) && URLStatuses.length
        ? (URLStatuses as string[]).map((s) => s.replace('-', ' '))
        : [(URLStatuses as string).replace('-', ' ')]
      : [],
  );
  const [queryValue, setQueryValue] = useState<string>((searchQueryString as string) || '');
  const statuses = useMemo(
    () => [
      { label: 'Fulfilled', value: 'fulfilled' },
      { label: 'Unfulfilled', value: 'unfulfilled' },
      { label: 'Partially fulfilled', value: 'partially fulfilled' },
      { label: 'Partially unfulfilled', value: 'partially unfulfilled' },
      { label: 'Cancelled', value: 'cancelled' },
    ],
    [],
  );
  const payoutStatuses = useMemo(
    () => [
      { label: 'Payout pending', value: 'payout pending' },
      { label: 'Paid', value: 'paid' },
    ],
    [],
  );

  const cancellationStatuses = useMemo(
    () => [{ label: 'Cancellation requested', value: 'cancellation requested' }],
    [],
  );

  const debouncedOnFilterChange = useDebouncedCallback(() => {
    onFilterChange({
      limit: filterParams.limit,
      statuses: selectedStatuses,
      page: 0,
      query: queryValue,
    });
    changeUrlParams({
      limit: filterParams.limit,
      statuses: selectedStatuses,
      page: 0,
      query: queryValue,
    });
  }, 500);

  const onQueryChange = (value: string) => {
    setQueryValue(value);
    debouncedOnFilterChange();
  };

  const onPageChange = useCallback(
    (page: number) => {
      onFilterChange({
        limit: filterParams.limit,
        statuses: selectedStatuses,
        query: queryValue,
        page,
      });
      changeUrlParams({
        limit: filterParams.limit,
        statuses: selectedStatuses,
        query: queryValue,
        page,
      });
      deselectAll();
      setSelectedOrders([]);
    },

    [
      onFilterChange,
      filterParams.limit,
      selectedStatuses,
      queryValue,
      changeUrlParams,
      deselectAll,
    ],
  );

  const handleSelectedStatusesChange = useCallback(
    (value: string[]) => {
      setSelectedStatuses(value);
      onFilterChange({
        limit: filterParams.limit,
        statuses: value,
        query: queryValue,
        page: 0,
      });
      changeUrlParams({
        limit: filterParams.limit,
        statuses: value,
        query: queryValue,
        page: 0,
      });
    },
    [onFilterChange, filterParams.limit, queryValue, changeUrlParams],
  );

  const handleSelectedStatusesRemove = useCallback(() => {
    setSelectedStatuses([]);
    onFilterChange({ limit: filterParams.limit, statuses: [], query: queryValue, page: 0 });
    changeUrlParams({ limit: filterParams.limit, statuses: [], query: queryValue, page: 0 });
  }, [onFilterChange, filterParams.limit, queryValue, changeUrlParams]);

  const handleQueryValueRemove = useCallback(
    (bulk?: boolean) => {
      setQueryValue('');
      if (!bulk) {
        onFilterChange({
          limit: filterParams.limit,
          statuses: selectedStatuses,
          page: 0,
          query: '',
        });
        changeUrlParams({
          limit: filterParams.limit,
          statuses: selectedStatuses,
          page: 0,
          query: '',
        });
      }
    },
    [onFilterChange, filterParams.limit, selectedStatuses, changeUrlParams],
  );

  const handleFiltersClearAll = useCallback(() => {
    setSelectedStatuses([]);
    handleQueryValueRemove(true);
    onFilterChange({
      limit: filterParams.limit,
      statuses: [],
      page: 0,
      query: '',
    });
    changeUrlParams({
      limit: filterParams.limit,
      statuses: [],
      page: 0,
      query: '',
    });
  }, [changeUrlParams, filterParams.limit, handleQueryValueRemove, onFilterChange]);

  const appliedFilters: IAppliedFilter[] = useMemo(() => {
    const appliedStatuses = {
      key: 'selectedStatuses',
      label: `Statuses: ${selectedStatuses.join(', ')}`,
      onRemove: handleSelectedStatusesRemove,
    };

    const choosenStatuses = selectedStatuses.length ? [appliedStatuses] : [];

    return choosenStatuses;
  }, [selectedStatuses, handleSelectedStatusesRemove]);

  const handleExportCSV = useCallback(
    (request: { orderIds: string[] }) => {
      dispatch(setOrdersListFetchingAction(true));
      ordersApi
        .exportOrdersCSV(request)
        .then(({ data }) => {
          const now = moment().format('MMDDYYYY');
          fileDownload(data, `Orders-${now}.csv`);
          dispatch(setOrdersListFetchingAction(false));
          setSelectedOrders([]);
          setAllOrdersSelected(false);
          deselectAll();
        })
        .catch(console.error);
    },
    [deselectAll, dispatch],
  );

  const handleExportCSVAll = useCallback(
    (request: Omit<IGetOrdersQueryParams, 'limit' | 'page'>) => {
      dispatch(setOrdersListFetchingAction(true));
      ordersApi
        .exportAllOrders(request)
        .then(({ data }) => {
          const now = moment().format('MMDDYYYY');
          fileDownload(data, `Orders-${now}.csv`);
          dispatch(setOrdersListFetchingAction(false));
          setSelectedOrders([]);
          setAllOrdersSelected(false);
          deselectAll();
        })
        .catch(console.error);
    },
    [deselectAll, dispatch],
  );

  return (
    <OrderList
      orders={orders || []}
      onSelectionChange={setSelectedOrders}
      allResourcesSelected={allResourcesSelected}
      selectedResources={selectedResources}
      handleSelectionChange={selectionChanged}
      fetching={fetching}
      totalPageCount={totalPageCount}
      onPageChange={onPageChange}
      searchString={searchString}
      appFilters={
        <AppFilters
          filters={[
            {
              key: 'selectedStatuses',
              label: 'Status',
              shortcut: true,
              filter: (
                <OptionList
                  sections={[
                    { title: 'Fulfillment statuses', options: [...statuses] },
                    {
                      title: 'Payout statuses',
                      options: [...payoutStatuses],
                    },
                    {
                      title: 'Cancellation statuses',
                      options: [...cancellationStatuses],
                    },
                  ]}
                  selected={selectedStatuses}
                  onChange={handleSelectedStatusesChange}
                  allowMultiple
                />
              ),
            },
          ]}
          appliedFilters={appliedFilters}
          onQueryChange={onQueryChange}
          onQueryClear={() => handleQueryValueRemove()}
          onClearAll={handleFiltersClearAll}
          queryValue={queryValue}
        />
      }
      currency={currency}
      selectable
      listActions={[
        {
          content: 'Export by SKU',
          onAction: () => {
            if (allOrdersSelected) {
              handleExportCSVAll(filterParams);
            } else {
              handleExportCSV({ orderIds: selectedOrders });
            }
          },
        },
        ...(!allOrdersSelected
          ? [
              {
                content: 'Print Shipping Labels',
                onAction: () => handlePrintLabels(allOrdersSelected, selectedOrders),
              },
            ]
          : []),
      ]}
    />
  );
};
