import React, { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import Flag from 'react-flagkit';
import { CartSaleIcon } from '@shopify/polaris-icons';

import { PRODUCT_CATEGORY, PRODUCT_VIEW } from '../../../../../core/constants/product.constants';
import { AppButton } from '../../../../../core/components/button/Button';
import { AppIcon } from '../../../../../core/components/icon/icon';
import { ReactComponent as CannabisIcon } from '../../../../../core/images/cannabis.svg';
import { AppThumbnail } from '../../../../../core/components/image-containers/thumbnail/Thumbnail';
import { AppCard } from '../../../../../core/components/structure/card/card';
import { AppDisplayText } from '../../../../../core/components/text/display-text/display-text';
import { AppTextStyle } from '../../../../../core/components/text/text-style/TextStyle';

import './supplier-card.scss';
import { BUSINESS_TYPE } from '../../../../../shopify-supplier/interfaces/IPreferences';
import {
  setMarketplaceProductListPropertyFilterAction,
  setMarketplaceProductListSupplierFilterAction,
} from '../../../../redux/modules/marketplace/marketplace.actions';

interface ISupplierCardProps {
  name: string;
  logo: string;
  bio: string;
  category: string;
  businessType: BUSINESS_TYPE;
  state: string;
  country: string;
  handleProductViewChange: (view: PRODUCT_VIEW) => void;
}

export const SupplierCard = ({
  name,
  logo,
  bio,
  category,
  businessType,
  state,
  country,
  handleProductViewChange,
}: ISupplierCardProps) => {
  const dispatch = useDispatch();
  const [isFullText, setIsFullText] = useState(false);

  const handleViewProducts = useCallback(
    (name) => {
      dispatch(setMarketplaceProductListPropertyFilterAction({ properties: [] }));
      dispatch(setMarketplaceProductListSupplierFilterAction({ fulfilledBy: [name] }));
      handleProductViewChange(PRODUCT_VIEW.GRID);
    },
    [dispatch, handleProductViewChange],
  );

  return (
    <AppCard sectioned>
      <div className="supplier">
        <div className="supplier__logo">
          <AppThumbnail
            size="large"
            alt={'logo'}
            source={
              logo ||
              'https://crowdship-cdn.nyc3.digitaloceanspaces.com/crowdship/logo-crowdship-navy.png'
            }
          />
          <AppButton plain onClick={() => handleViewProducts(name)}>
            View Products
          </AppButton>
        </div>
        <div className="supplier__info">
          <AppDisplayText size="small">
            <AppTextStyle variation="strong">{name}</AppTextStyle>
          </AppDisplayText>

          <div className="supplier__info__option">
            <Flag country={country} size={20} />
            <AppTextStyle variation="strong">
              Ships from {state}, {country}
            </AppTextStyle>
          </div>
          {businessType !== BUSINESS_TYPE.NONE && (
            <div className="supplier__info__option">
              <AppIcon source={() => <CannabisIcon />} />
              <AppTextStyle variation="strong">Licensed {businessType}</AppTextStyle>
            </div>
          )}
          {category !== PRODUCT_CATEGORY.CANNABIS && (
            <div className="supplier__info__option">
              <AppIcon source={CartSaleIcon} color="highlight" />
              <AppTextStyle variation="strong">Drop Shipping</AppTextStyle>
            </div>
          )}
        </div>
        <div className={`supplier__bio ${isFullText ? 'not_hidden' : ''}`}>
          <AppDisplayText size="small">
            <AppTextStyle variation="strong">Bio</AppTextStyle>
          </AppDisplayText>
          <AppTextStyle variation="subdued">
            {bio || 'This supplier hasn’t added a bio yet.'}
          </AppTextStyle>
          {bio.length > 348 && (
            <AppButton plain onClick={() => setIsFullText(!isFullText)}>
              {isFullText ? 'View Less' : 'View More'}
            </AppButton>
          )}
        </div>
      </div>
    </AppCard>
  );
};
