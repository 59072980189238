import { Decimal } from 'decimal.js';
import React, { useCallback, useMemo } from 'react';
import { AppDataTable } from '../../../../core/components/data-table/DataTable';
import { AppControlledTextField } from '../../../../core/components/forms/controlled-text-field/controlled-text-field';
import { AppThumbnail } from '../../../../core/components/image-containers/thumbnail/Thumbnail';
import { validation } from '../../../../core/helpers/validations.helper';
import { ICancelOrderLineItem } from '../../../api/orders.api';
import { IOrderDetailsProduct } from '../../../interfaces/IOrder';

import './cancellation-product-list.scss';

interface CancellationProductListProps {
  products: IOrderDetailsProduct[];
  inputValues: ICancelOrderLineItem[];
}

export function CancellationProductList({ products, inputValues }: CancellationProductListProps) {
  const renderInput = useCallback((index: number, p: IOrderDetailsProduct) => {
    const max =
      p.totalQty - p.cancellations.reduce((q, c) => q + c.quantity.accepted, 0) - p.fulfilledQty;
    return (
      <AppControlledTextField
        name={`[${index}].quantity`}
        type="number"
        max={max}
        min={0}
        suffix={`/ ${max}`}
        validate={(val) => validation.isNumber(val, { min: 0, max })}
      />
    );
  }, []);

  const calculateTotalVariantPrice = useCallback((price: number, quantity: string) => {
    if (validation.isNumber(quantity) !== undefined) return 0;
    return new Decimal(price).mul(quantity).toFixed(2);
  }, []);

  const rows = useMemo(
    () =>
      products.map((p, index) => [
        <div className="image">
          <AppThumbnail alt={p.title} source={p.image} />
        </div>,
        <div className="item">
          <div className="title">{p.title}</div>
          <div className="variant">{p.variantTitle}</div>
          <div className="sku">SKU: {p.sku}</div>
        </div>,
        <div className="price">${p.price}</div>,
        <div className="input quantity">{renderInput(index, p)}</div>,
        <div className="total price">
          ${calculateTotalVariantPrice(p.price, inputValues[index].quantity)}
        </div>,
      ]),
    [products, inputValues, renderInput, calculateTotalVariantPrice],
  );

  const calculateTotalPrice = useMemo(
    () =>
      products
        .reduce(
          (total, product, index) =>
            total.plus(new Decimal(product.price).mul(inputValues[index].quantity || 0)),
          new Decimal(0),
        )
        .toFixed(2),
    [products, inputValues],
  );

  return (
    <div className="cancellation-list-wrapper">
      <AppDataTable
        columnContentTypes={['text', 'text', 'numeric', 'numeric', 'numeric']}
        headings={['Image', 'Title', 'Price', 'Quantity', 'Total']}
        rows={rows}
        totals={['', '', '', 'Total:', `$${calculateTotalPrice}`]}
        showTotalsInFooter
        totalsName={{ plural: '', singular: '' }}
      />
    </div>
  );
}
