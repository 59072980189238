import React, { useCallback } from 'react';
import { Card, EmptySearchResult } from '@shopify/polaris';
import { AppThumbnail } from '../../../../../core/components/image-containers/thumbnail/Thumbnail';
import { IProductImage } from '../../../../interfaces/IProductDetails';
import { AppCheckbox } from '../../../../../core/components/checkbox/checkbox';

interface ProductImagesTabProps {
  images: IProductImage[];
  setFieldValue: (field: string, value: any) => void;
}

export const ProductImagesTab = ({ images, setFieldValue }: ProductImagesTabProps) => {
  const handleChange = useCallback(
    (newChecked, index) => setFieldValue(`images[${index}].selected`, newChecked),
    [setFieldValue],
  );

  return (
    <Card.Section>
      {!!images.length ? (
        <div className="product-images">
          {images.map((image, index) => (
            <div
              className="product-images__layout"
              onClick={() => handleChange(!image.selected, index)}
              key={index}
            >
              <AppCheckbox label="Basic checkbox" labelHidden checked={image.selected} />
              <AppThumbnail source={image.source} size="large" alt="Product image" />
            </div>
          ))}
        </div>
      ) : (
        <EmptySearchResult title={'No images found'} withIllustration />
      )}
    </Card.Section>
  );
};
