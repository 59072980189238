import React from 'react';
import { AppControlledRadioGroup } from '../../../../../core/components/forms/radio/controlled-radio-group/controlled-radio-group';
import { AppCard } from '../../../../../core/components/structure/card/card';
import { AppLayoutAnnotatedSection } from '../../../../../core/components/structure/layout/layout-annotated-section';
import { AppTextContainer } from '../../../../../core/components/text-container/text-container';
import { AppTextStyle } from '../../../../../core/components/text/text-style/TextStyle';
import {
  USE_CONTACT_PHONE,
  lineItemCancellationOptionsConstants,
} from '../../../../constants/preferences.constants';
import { getApiStatusSelector } from '../../../../redux/modules/status/status.selectors';
import { useSelector } from 'react-redux';
import { AppLink } from '../../../../../core/components/link/link';

export const OrdersSection = () => {
  const apiStatus = useSelector(getApiStatusSelector);

  return (
    <AppLayoutAnnotatedSection title="Orders">
      <AppCard sectioned title="When an order cancellation request is approved">
        <AppControlledRadioGroup
          optionsDesc={lineItemCancellationOptionsConstants}
          name={'preferences.order.requestLineItemsCancellationOption'}
        />
      </AppCard>
      <AppCard sectioned title="Shipping address telephone number">
        <AppTextContainer>
          <p className="subtitle">
            <AppTextStyle variation="subdued">
              Sometimes Suppliers need a telephone number in order to be able to print a label for
              an order. The customer’s telephone number is preferred, but you can choose to use your
              shop’s contact number, or Crowdship’s contact number instead. If the carrier that is
              making the delivery needs to contact the deliveree, they will call this number. Which
              number should Crowdship share with the Supplier?
            </AppTextStyle>
          </p>
        </AppTextContainer>
        <AppControlledRadioGroup
          optionsDesc={[
            {
              value: USE_CONTACT_PHONE.CUSTOMER,
              label: 'Customer’s phone number.',
            },
            {
              value: USE_CONTACT_PHONE.RETAILER,
              label: 'My shop’s contact phone number.',
            },
            {
              value: USE_CONTACT_PHONE.CROWDSHIP,
              label: 'Crowdship’s phone number.',
            },
          ]}
          name={'preferences.order.useContactPhone'}
        />
      </AppCard>
      <AppCard sectioned title="When a Supplier cancels an order">
        <AppControlledRadioGroup
          optionsDesc={lineItemCancellationOptionsConstants}
          name={'preferences.order.lineItemsCancellationOption'}
        />
      </AppCard>
      <AppCard sectioned title="Shipping notifications">
        <AppTextContainer>
          <p className="subtitle">
            <AppTextStyle variation="subdued">
              Choose whether you want Shopify to send shipping notifications to your customers once
              an order is fulfilled and a tracking number is added to the order.
            </AppTextStyle>
          </p>
        </AppTextContainer>
        <AppControlledRadioGroup
          optionsDesc={[
            {
              value: 'true',
              label: 'Allow Shopify to send shipping notifications',
              helpText:
                "This will indicate to Shopify to send shipping updates to your customer, according to Shopify's protocol of when to send such update notifications",
            },
            {
              value: 'false',
              label: 'Do not allow Shopify to send shipping notifications',
              helpText:
                'This will prevent Shopify from sending any shipping notifications to your customer',
            },
          ]}
          name={'preferences.order.notifyCustomer'}
        />
      </AppCard>
      <AppCard sectioned title="Order address validation">
        <AppControlledRadioGroup
          optionsDesc={[
            {
              value: 'false',
              label: 'Skip address validation for all orders.',
              helpText: 'default',
            },
            {
              value: 'true',
              label: `Attempt to validate all shipping addresses. Addresses which fail validation will generate an error,
               and will not be sent to suppliers until validated manually.`,
            },
          ]}
          name={'preferences.order.addressValidation'}
        />
      </AppCard>
      <AppCard sectioned title="Order processing methods">
        <AppControlledRadioGroup
          optionsDesc={[
            {
              value: 'onPaid',
              label: 'Order “Paid”',
              helpText: `Orders will be fulfilled as soon as they are marked Paid, and so, 
              Fulfillments requests will have no effect. 
              As Suppliers will immediately begin processing orders, cancellations can be requested but are not guaranteed.`,
            },
            {
              value: 'onFulfillmentRequest',
              label: (
                <p>
                  On Fulfillment Request {!apiStatus.updatedApi && '(Disabled - '}
                  <>
                    {!apiStatus.updatedApi && (
                      <AppLink
                        onClick={() => window.open(apiStatus.permissionUrl, '_blank')}
                        external
                      >
                        please update your API)
                      </AppLink>
                    )}
                  </>
                </p>
              ),
              helpText: `Orders will only be sent to Suppliers for fulfillment when a Fulfillment request is issued. 
               Orders which do not have a Fulfillment request will not be processed.`,
              disabled: !apiStatus.updatedApi,
            },
          ]}
          name={'preferences.order.orderProcessing'}
        />
      </AppCard>
    </AppLayoutAnnotatedSection>
  );
};
