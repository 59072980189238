import { useFormikContext } from 'formik';
import { useEffect } from 'react';
import { AppControlledTextField } from '../../../../../core/components/forms/controlled-text-field/controlled-text-field';
import { AppFormLayoutGroup } from '../../../../../core/components/forms/form-layout-group/form-layout-group';
import { AppFormLayout } from '../../../../../core/components/forms/form-layout/form-layout';
import { validation } from '../../../../../core/helpers/validations.helper';
import { convertWeightFromGramsToPoundsAndOunces } from '../../../../../core/helpers/weight.helper';
import { LabelCandidateProductGroup } from '../label-candidate';
import './order-details-inputs.scss';

export const WeightInputGroup = () => {
  const { values, setFieldValue } = useFormikContext<LabelCandidateProductGroup>();

  useEffect(() => {
    if (values.products.every((p) => !Number.isNaN(p.unfulfilledQty))) {
      const totalGrams = values.products.reduce(
        (total, p) => total + p.grams * +p.unfulfilledQty,
        0,
      );
      const { lbs, oz } = convertWeightFromGramsToPoundsAndOunces(totalGrams);

      setFieldValue('weight.lbs', lbs.toString());
      setFieldValue('weight.oz', oz.toString());
    }
  }, [setFieldValue, values.products]);

  return (
    <div className="weight-input-group">
      <AppFormLayout>
        <AppFormLayoutGroup condensed>
          <AppControlledTextField
            label="Weight"
            name={'weight.lbs'}
            placeholder="0"
            type="number"
            validate={(value) => validation.isNumber(value, { noSymbols: true })}
            suffix="lbs"
            connectedRight={
              <AppControlledTextField
                label=""
                name={'weight.oz'}
                placeholder="0"
                type="number"
                validate={(value) => validation.isNumber(value, { noSymbols: true })}
                suffix="oz"
              />
            }
          />
        </AppFormLayoutGroup>
      </AppFormLayout>
    </div>
  );
};
