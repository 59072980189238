import { createAction } from 'typesafe-actions';
import { IVariantList, IConnectedProducts } from '../../../../interfaces/IProduct';
import { ACTIONS_CONSTANTS } from '../../../actions-constants';

export const getMarketplaceVariantListAction = createAction(
  ACTIONS_CONSTANTS.GET_MARKETPLACE_VARIANT_LIST,
)();

export const setMarketplaceVariantListAction = createAction(
  ACTIONS_CONSTANTS.SET_MARKETPLACE_VARIANT_LIST,
  (list: IVariantList) => list,
)();

export const getMarketplaceConnectedProductsAction = createAction(
  ACTIONS_CONSTANTS.GET_MARKETPLACE_CONNECTED_PRODUCTS,
  (variantId: string) => variantId,
)();

export const setMarketplaceConnectedProductsAction = createAction(
  ACTIONS_CONSTANTS.SET_MARKETPLACE_CONNECTED_PRODUCTS,
  (products: IConnectedProducts[]) => products,
)();

export const disconnectMarketplaceConnectedProductsAction = createAction(
  ACTIONS_CONSTANTS.DISCONNECT_MARKETPLACE_CONNECTED_PRODUCTS,
  (id: string, variantId: string) => ({ id, variantId }),
)();

export const decrementVariantConnectedProductsAction = createAction(
  ACTIONS_CONSTANTS.DECREMENT_VARIANT_CONNECTED_PRODUCTS,
  (variantId: string) => variantId,
)();

export const removeDisconnectedProductsAction = createAction(
  ACTIONS_CONSTANTS.REMOVE_DISCONNECTED_PRODUCTS,
  (id: string, variantId: string) => ({ id, variantId }),
)();

export const setMarketplaceVariantListFetchingAction = createAction(
  ACTIONS_CONSTANTS.SET_MARKETPLACE_VARIANT_LIST_FETCHING,
  (fetching: boolean) => fetching,
)();
