import React from 'react';
import { getLocaleQuantity } from '../../helpers/quantity.helper';
import { ReactComponent as InfinitySymbol } from '../../images/infinity.svg';

interface ITrackedQuantityLabelForProductProps<T> {
  quantity: number;
  variants: T[];
}

export const TrackedQuantityLabelForProduct = <
  T extends { tracked?: boolean; inventoryPolicy?: 'deny' | 'continue'; qty: number },
>({
  quantity,
  variants,
}: ITrackedQuantityLabelForProductProps<T>) => {
  const isInfinityQty = variants.some(
    (v) => v.tracked === false || v.inventoryPolicy === 'continue',
  );

  if (isInfinityQty)
    return (
      <div>
        <div>
          {!variants.every((p) => p.tracked === false || p.qty <= 0) && getLocaleQuantity(quantity)}
        </div>
        {isInfinityQty && <InfinitySymbol />}
      </div>
    );

  return <>{getLocaleQuantity(quantity)}</>;
};
