import React, { useCallback, useEffect, useMemo } from 'react';
import { Field, useFormikContext } from 'formik';
import { Select, SelectProps } from '@shopify/polaris';

type ISelectProps = Omit<SelectProps, 'value' | 'label'> & {
  name: string;
  label?: string;
  validate?: (value: string) => string | undefined | Promise<string | undefined>;
};

export function AppControlledSelect(props: ISelectProps) {
  const { name, label = '', validate, onChange } = props;
  const {
    registerField,
    getFieldProps,
    unregisterField,
    setFieldValue,
    setFieldError,
    getFieldMeta,
  } = useFormikContext();

  const field = getFieldProps({ name });
  const meta = getFieldMeta(name);

  useEffect(() => {
    if (name) registerField(name, { validate });
    return () => {
      if (name) unregisterField(name);
    };
  }, [name, validate, registerField, unregisterField]);

  const handleChange = useCallback(
    (value: string, id: string) => {
      setFieldValue(name, value, false);
      onChange && onChange(value, id);
    },
    [setFieldValue, name, onChange],
  );

  const handleBlur = useCallback(() => {
    field.onBlur({ target: { name } });
  }, [field, name]);

  const handleFocus = useCallback(() => {
    setFieldError(name, '');
  }, [name, setFieldError]);

  const error = useMemo(() => {
    if (meta.error && meta.touched) {
      return meta.error;
    }
  }, [meta.error, meta.touched]);

  return (
    <Field name={name} validate={validate}>
      {() => (
        <Select
          {...props}
          label={label}
          name={name}
          onChange={handleChange}
          value={field.value}
          onBlur={handleBlur}
          onFocus={handleFocus}
          error={error}
        />
      )}
    </Field>
  );
}
