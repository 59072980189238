import React from 'react';
import { AppDataTable } from '../../../../../core/components/data-table/DataTable';
import { AppControlledChoiceList } from '../../../../../core/components/forms/controlled-choice-list/controlled-choice-list';
import { AppPopoverWithSection } from '../../../../../core/components/overlays/popover/popover-with-section';
import { AppCard } from '../../../../../core/components/structure/card/card';
import { AppLayoutAnnotatedSection } from '../../../../../core/components/structure/layout/layout-annotated-section';
import { notificationFrequencyOptions } from '../../../../../core/constants/notification-frequency.constants';
import { notificationEventsConstants } from '../../../../constants/preferences.constants';
import { INotificationsPreferences } from '../../../../interfaces/IPreferences';

type NotificationsSectionProps = {
  unsavedPreferences: INotificationsPreferences;
};

export const NotificationsSection = ({ unsavedPreferences }: NotificationsSectionProps) => (
  <AppLayoutAnnotatedSection title="Notifications">
    <AppCard sectioned title="Get notified when following events occur">
      <AppDataTable
        headings={['Event', 'E-Mail']}
        columnContentTypes={['text', 'text']}
        rows={notificationEventsConstants.map((event) => [
          <div className="title">{event.title}</div>,
          <div className="email-select">
            <AppPopoverWithSection
              title={unsavedPreferences[event.key].email.length ? 'Notify' : "Don't notify"}
              fixed
            >
              <AppControlledChoiceList
                name={`preferences.notifications.${event.key}.email`}
                title="Frequency options"
                titleHidden
                choices={notificationFrequencyOptions}
                allowMultiple
              />
            </AppPopoverWithSection>
          </div>,
        ])}
      />
    </AppCard>
  </AppLayoutAnnotatedSection>
);
