import React from 'react';
import { AppModal } from '../../../../core/components/overlays/modal/modal';
import { AppTextContainer } from '../../../../core/components/text-container/text-container';
import { AppTextStyle } from '../../../../core/components/text/text-style/TextStyle';

type DuplicateProductsModalProps = {
  open: boolean;
  onClose: () => void;
  postDuplicatesProductsToImportList: (skip: boolean) => void;
};

export const DuplicateProductsModal = ({
  open,
  onClose,
  postDuplicatesProductsToImportList,
}: DuplicateProductsModalProps) => {
  return (
    <AppModal
      open={open}
      title={<AppTextStyle variation="warning">WARNING</AppTextStyle>}
      onClose={onClose}
      primaryAction={{
        content: 'Skip duplicate(s)',
        onAction: () => postDuplicatesProductsToImportList(true),
      }}
      secondaryActions={[
        {
          content: 'Add product(s)',
          onAction: () => postDuplicatesProductsToImportList(false),
        },
      ]}
    >
      <AppTextContainer>
        <p>
          One or more selected products are already in your Import List. How would you like to
          proceed?
        </p>
      </AppTextContainer>
    </AppModal>
  );
};
