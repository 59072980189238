import React, { useEffect } from 'react';
import queryString from 'query-string';
import { useDispatch, useSelector } from 'react-redux';
import {
  getOrdersAction,
  getOrdersSummaryAction,
} from '../../../redux/modules/orders/orders.actions';
import {
  getRetailerOrdersCountSelector,
  getRetailerOrderListFetchingSelector,
  getRetailerOrdersSelector,
  getRetailerOrdersSummarySelector,
} from '../../../redux/modules/orders/orders.selectors';
import { IGetRetailerOrdersQueryParams } from '../../../../shopify-supplier/api/orders.api';
import { ORDERS_PER_PAGE } from '../../../../core/constants/orders';
import { AppCard } from '../../../../core/components/structure/card/card';
import { OrdersSummary } from '../../containers/order/orders-summary/orders-summary';
import { OrderList } from '../../containers/order/order-list/order-list';
import { useLocation } from 'react-router';

type OrderLisrLayoutProps = {
  searchString: string;
  selectedTab: number;
  changeUrlParams: (tabNumber: any, params?: IGetRetailerOrdersQueryParams) => void;
};

export const OrderListLayout = ({
  changeUrlParams,
  searchString,
  selectedTab,
}: OrderLisrLayoutProps) => {
  const dispatch = useDispatch();
  const orders = useSelector(getRetailerOrdersSelector);
  const ordersSummary = useSelector(getRetailerOrdersSummarySelector);
  const fetching = useSelector(getRetailerOrderListFetchingSelector);
  const ordersNumber = useSelector(getRetailerOrdersCountSelector);
  const { search: query } = useLocation();
  const {
    statuses,
    page,
    limit,
    query: searchQueryString,
  } = queryString.parse(query, { arrayFormat: 'separator', arrayFormatSeparator: '.' });

  const [filterParams, setFilterParams] = React.useState<IGetRetailerOrdersQueryParams>({
    page: +(page as string) || 0,
    limit: +(limit as string) || ORDERS_PER_PAGE,
    query: (searchQueryString as string) || '',
    statuses: !!statuses
      ? Array.isArray(statuses) && statuses.length
        ? (statuses as string[]).map((s) => s.replace('-', ' ').replace('needs attention', 'error'))
        : [(statuses as string).replace('-', ' ').replace('needs attention', 'error')]
      : [],
  });

  useEffect(() => {
    dispatch(getOrdersSummaryAction());
  }, [dispatch]);

  useEffect(() => {
    dispatch(getOrdersAction(filterParams));
  }, [dispatch, filterParams]);

  return (
    <>
      <AppCard title="Summary" sections={[{ content: <OrdersSummary {...ordersSummary} /> }]} />
      <AppCard
        title="Orders"
        sections={[
          {
            content: (
              <OrderList
                orders={orders || []}
                fetching={fetching}
                ordersNumber={ordersNumber}
                ordersPerPage={ORDERS_PER_PAGE}
                filterParams={filterParams}
                onFilterChange={setFilterParams}
                searchString={searchString}
                changeUrlParams={changeUrlParams}
                selectedTab={selectedTab}
              />
            ),
          },
        ]}
      />
    </>
  );
};
