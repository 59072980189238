import { EmptySearchResult, useIndexResourceState } from '@shopify/polaris';
import { SelectionType } from '@shopify/polaris/build/ts/latest/src/utilities/index-provider/types';
import { Form, Formik } from 'formik';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import emptyStateImage from '../../../core/images/Shopify/Empty States/404.svg';
import {
  getSyncMatchesAction,
  linkAllMatchesAction,
  linkMatchesAction,
  startInventorySyncAction,
} from '../../../shopify-retailer/redux/modules/variant-sync/variant-sync.actions';
import {
  getVariantSyncFetchingSelector,
  getVariantSyncInProgressSelector,
  getVariantSyncMatchesSelector,
  getVariantSyncMatchesTotalSelector,
} from '../../../shopify-retailer/redux/modules/variant-sync/variant-sync.selectors';
import { getNumberOfPages } from '../../helpers/item-list.helper';
import { AppButton } from '../button/Button';
import { AppControlledRadioGroup } from '../forms/radio/controlled-radio-group/controlled-radio-group';
import { AppIndexTable } from '../index-table/index-table';
import { AppIndexTableCell } from '../index-table/index-table-cell';
import { AppIndexTableRow } from '../index-table/index-table-row';
import { PageAwarePagination } from '../pagination/page-aware-pagination';
import { AppEmptyState } from '../structure/empty-state/empty-state';
import './product-list.scss';
import './product-sync.scss';
import { RetailerSyncProduct } from './retailer-product-sync';
import { SupplierSyncProduct } from './supplier-product-sync';

const SEPARATOR = '/';
const INDEX_OF_MATCHING_PRODUCTS = 1;
const INDEX_OF_SUPPLIER_PRODUCT = 0;

export function ProductSync() {
  const dispatch = useDispatch();
  const syncInProgress = useSelector(getVariantSyncInProgressSelector);
  const matches = useSelector(getVariantSyncMatchesSelector);
  const total = useSelector(getVariantSyncMatchesTotalSelector);
  const fetching = useSelector(getVariantSyncFetchingSelector);

  const [selectedSupplierProduct, setSelectedSupplierProduct] = useState<
    {
      retailerProductId: string;
      selectedProductId: string;
    }[]
  >([]);
  const { selectedResources, allResourcesSelected, handleSelectionChange } =
    useIndexResourceState(matches);

  const linkMatches = useCallback(
    (
      matchesIds: {
        retailerProductId: string;
        supplierProductId: string;
      }[],
    ) => {
      dispatch(linkMatchesAction(matchesIds));
    },
    [dispatch],
  );

  const handleAllMatchesLinking = useCallback(
    (ids: string[]) => dispatch(linkAllMatchesAction(ids)),
    [dispatch],
  );

  // handleSelectionChange in deps cause side effect
  useEffect(() => {
    deselectAll();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetching]);

  useEffect(() => {
    setSelectedSupplierProduct(
      matches.map((match) => {
        const selectedProductId =
          selectedSupplierProduct.find((s) => s.retailerProductId === match.retailerProduct.product)
            ?.selectedProductId || match.supplierProducts[0].product;

        return {
          retailerProductId: match.retailerProduct.product,
          selectedProductId: `${selectedProductId}${SEPARATOR}${match.id}`,
        };
      }),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [matches]);

  const deselectAll = useCallback(
    () => handleSelectionChange('page' as SelectionType, false),
    [handleSelectionChange],
  );

  const handlePageChange = useCallback(
    (page: number) => {
      dispatch(getSyncMatchesAction(page));
      deselectAll();
    },
    [deselectAll, dispatch],
  );

  const handleSyncStart = useCallback(() => {
    dispatch(startInventorySyncAction());
  }, [dispatch]);

  const submitMatches = () => {
    if (allResourcesSelected) {
      handleAllMatchesLinking(
        matches.map(
          (match) =>
            selectedSupplierProduct
              .find((ssp) => ssp.retailerProductId === match.retailerProduct.product)
              ?.selectedProductId.split(SEPARATOR)[INDEX_OF_SUPPLIER_PRODUCT] ||
            match.supplierProducts[0].product,
        ),
      );
      deselectAll();
      return;
    }

    linkMatches(
      matches
        .filter((m) => selectedResources.some((id) => id === m.id))
        .map((match) => ({
          retailerProductId: match.retailerProduct.product,
          supplierProductId:
            selectedSupplierProduct
              .find((ssp) => ssp.retailerProductId === match.retailerProduct.product)
              ?.selectedProductId.split(SEPARATOR)[INDEX_OF_SUPPLIER_PRODUCT] ||
            match.supplierProducts[0].product,
        })),
    );

    deselectAll();
  };

  const listActions = [{ content: 'Link Matches', onAction: submitMatches }];

  const handleChangeMatches = useCallback(
    (value: string) => {
      const supplierProduct = value.split(SEPARATOR)[INDEX_OF_SUPPLIER_PRODUCT];
      const matchingId = value.split(SEPARATOR)[INDEX_OF_MATCHING_PRODUCTS];
      if (supplierProduct && matchingId) {
        const indexOfChangedProduct = matches.findIndex((m) =>
          m.supplierProducts.find((s) => s.product === supplierProduct && m.id === matchingId),
        );

        if (indexOfChangedProduct !== -1) {
          setSelectedSupplierProduct((prevSupplierProducts) => {
            const updatedProducts = prevSupplierProducts.map((product, index) => {
              if (index === indexOfChangedProduct) {
                return {
                  ...product,
                  selectedProductId: value,
                };
              }
              return product;
            });

            return updatedProducts;
          });
        }
      }
    },
    [matches],
  );

  const productsRows = useMemo(
    () =>
      matches.map(({ retailerProduct, supplierProducts, id }, index) => (
        <AppIndexTableRow
          id={id.toString()}
          key={id}
          position={index}
          selected={selectedResources.includes(`${id}`)}
        >
          <AppIndexTableCell>
            <RetailerSyncProduct retailerProduct={retailerProduct} />
          </AppIndexTableCell>
          <AppIndexTableCell>
            {supplierProducts.length > 1 ? (
              <Formik
                initialValues={{
                  supplierProduct: `${supplierProducts[0].product}${SEPARATOR}${id}`,
                }}
                onSubmit={() => {}}
              >
                {() => (
                  <Form>
                    <AppControlledRadioGroup
                      optionsDesc={supplierProducts.map((sP) => ({
                        value: `${sP.product}/${id}`,
                        label: <SupplierSyncProduct supplierProduct={sP} />,
                      }))}
                      name={'supplierProduct'}
                      onChange={handleChangeMatches}
                    />
                  </Form>
                )}
              </Formik>
            ) : (
              <SupplierSyncProduct supplierProduct={supplierProducts[0]} />
            )}
          </AppIndexTableCell>
        </AppIndexTableRow>
      )),
    [handleChangeMatches, matches, selectedResources],
  );

  const syncingInProgressPage = useMemo(
    () => (
      <AppEmptyState heading="Products synchronization is in progress" image={emptyStateImage}>
        <p>Please, visit this page later to see found matches</p>
      </AppEmptyState>
    ),
    [],
  );

  if (syncInProgress) return syncingInProgressPage;

  return (
    <div className="sync-wrapper">
      {matches.length ? (
        <AppIndexTable
          resourceName={{ singular: 'match', plural: 'matches' }}
          headings={[{ title: 'Your Product' }, { title: 'Suggested Matches' }]}
          itemCount={matches.length}
          onSelectionChange={handleSelectionChange}
          selectedItemsCount={allResourcesSelected ? 'All' : selectedResources.length}
          promotedBulkActions={listActions}
          hasMoreItems={getNumberOfPages(total) > 1}
          loading={fetching}
        >
          {productsRows}
        </AppIndexTable>
      ) : (
        <div className="empty-sync-with-button">
          <EmptySearchResult
            title="No matches found"
            description="If you're using search or a filter, try changing it"
            withIllustration
          />
          <AppButton onClick={handleSyncStart} primary>
            Search for new matches
          </AppButton>
        </div>
      )}

      <PageAwarePagination
        totalPageCount={getNumberOfPages(total)}
        onPageChange={handlePageChange}
      />
    </div>
  );
}
