import { FAILURE_REASON } from '../api/products.api';

export const getErrorReason = (reasons: FAILURE_REASON[]) => {
  if (!reasons.length) return '';
  const lackOf = reasons.map((reason) => {
    switch (reason) {
      case FAILURE_REASON.NO_SKU:
        return 'SKU';
      case FAILURE_REASON.NO_MSRP:
        return 'MSRP';
      case FAILURE_REASON.NO_PRICE_FOR_RETAILERS:
        return 'price for retailers';
      default:
        return '';
    }
  });
  const missingAttributes = lackOf.join(', ');
  return `due to missing ${missingAttributes} in one or multiple variants. Please add ${missingAttributes} to the variants`;
};
