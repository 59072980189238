import { createReducer } from 'typesafe-actions';
import { RootActions } from '../../root.actions';
import {
  COMPARE_AT_PRICE_OPTION,
  IMPORTED_OR_CONNECTED_PRODUCT_STATUS_OPTION,
  IPreferences,
  LINE_ITEMS_CANCELLATION_OPTION,
  ORDER_PROCESSING_OPTION,
  PRODUCT_DISCONTINUATION_OPTION,
  PRODUCT_REMOVE_FROM_CROWDSHIP_OPTION,
  PRODUCT_UPDATE_OPTION,
  SALE_PRICE_OPTION,
} from '../../../interfaces/IPreferences';
import {
  getPreferencesAction,
  setPreferencesAction,
  setCarrierServicesAction,
  calcPriceChangesAction,
  setPriceChangesAction,
  setPreferencesFromApiAction,
  setProductPreferencesFromApiAction,
  getProductPreferencesAction,
} from './preferences.actions';
import { IProductChangesToCompare } from '../../../api/preferences.api';

export interface IPreferencesReducer {
  preferences: IPreferences;
  priceChanges: IProductChangesToCompare[];
  fetching: boolean;
  carrierServiceAutocompleteOptions: string[];
}

const initialNotifications = {
  sellProduct: {
    inApp: false,
    sms: false,
    email: [],
  },
  dropShipCostChanges: {
    inApp: false,
    sms: false,
    email: [],
  },
  orderFulfillment: {
    inApp: false,
    sms: false,
    email: [],
  },
  newBrand: {
    inApp: false,
    sms: false,
    email: [],
  },
};

const initialState: IPreferencesReducer = {
  preferences: {
    profile: {},
    shipping: {
      internationalShipping: false,
      takeIntoAccountEndClientServiceChoice: true,
      shippingCostThreshold: '0',
      mapping: [
        {
          customCarrierServiceName: 'Standard',
          crowdshipCarrierServiceKey: 'cheapest',
          specified: false,
          custom: false,
        },
        {
          customCarrierServiceName: 'Expedited',
          crowdshipCarrierServiceKey: 'fastest',
          specified: false,
          custom: false,
        },
      ],
      customsDeclaration: {
        certifySigner: '',
        eelPfc: 'NOEEI_30_37_a',
        incoterm: 'DDU',
        nonDelivery: 'RETURN',
      },
    },
    product: {
      importedOrConnectedProductStatusOption: IMPORTED_OR_CONNECTED_PRODUCT_STATUS_OPTION.ACTIVE,
      defaultMarkup: '100',
      discontinuationOption: PRODUCT_DISCONTINUATION_OPTION.DELETE,
      removeFromCrowdshipOption: PRODUCT_REMOVE_FROM_CROWDSHIP_OPTION.DELETE,
      updateOption: PRODUCT_UPDATE_OPTION.IGNORE,
      updateFields: {
        all: false,
        barcode: false,
        description: false,
        image: false,
        images: false,
        productType: false,
        sku: false,
        tags: false,
        title: false,
        variants: false,
        vendor: false,
        weight: false,
      },
      compareAtPriceOption: COMPARE_AT_PRICE_OPTION.NOT_SET,
      salePriceOption: SALE_PRICE_OPTION.MSRP,
      compareAtPriceMarkup: '50',
      addTag: true,
      customTag: '',
    },
    order: {
      notifyCustomer: 'true',
      lineItemsCancellationOption: LINE_ITEMS_CANCELLATION_OPTION.REFUND,
      requestLineItemsCancellationOption: LINE_ITEMS_CANCELLATION_OPTION.REFUND,
      addressValidation: 'true',
      orderProcessing: ORDER_PROCESSING_OPTION.ON_PAID,
    },
    notifications: initialNotifications,
  },
  priceChanges: [],
  carrierServiceAutocompleteOptions: [],
  fetching: false,
};

export const preferencesReducer = createReducer<IPreferencesReducer, RootActions>(initialState)
  .handleAction(getPreferencesAction, (state) => ({ ...state, fetching: true }))
  .handleAction(getProductPreferencesAction, (state) => ({ ...state, fetching: true }))
  .handleAction(setPreferencesFromApiAction, (state, { payload }) => {
    return {
      ...state,
      fetching: false,
      preferences: {
        ...payload,
        product: {
          ...payload.product,
          defaultMarkup: payload.product.defaultMarkup.toString(),
          compareAtPriceMarkup: payload.product.compareAtPriceMarkup?.toString(),
          customTag: payload.product.customTag || '',
        },
        shipping: {
          ...payload.shipping,
          shippingCostThreshold:
            payload.shipping.shippingCostThreshold &&
            payload.shipping.shippingCostThreshold.toString(),
          customsDeclaration: {
            ...payload.shipping.customsDeclaration,
            aesItn: payload.shipping.customsDeclaration.aesItn || '',
            customItemName: payload.shipping.customsDeclaration.customItemName || '',
          },
        },
        order: {
          ...payload.order,
          notifyCustomer: payload.order.notifyCustomer.toString() as 'true' | 'false',
          addressValidation: payload.order.addressValidation.toString() as 'true' | 'false',
        },
      },
    };
  })
  .handleAction(setCarrierServicesAction, (state, { payload }) => ({
    ...state,
    carrierServiceAutocompleteOptions: payload,
  }))
  .handleAction(calcPriceChangesAction, (state) => ({ ...state }))
  .handleAction(setPreferencesAction, (state, { payload }) => ({
    ...state,
    preferences: {
      ...payload,
    },
  }))
  .handleAction(setPriceChangesAction, (state, { payload }) => ({
    ...state,
    priceChanges: payload,
  }))
  .handleAction(setProductPreferencesFromApiAction, (state, { payload }) => ({
    ...state,
    fetching: false,
    preferences: {
      ...state.preferences,
      product: {
        ...payload,
      },
    },
  }));
