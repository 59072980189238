import { createReducer } from 'typesafe-actions';
import { IRetailerStatus } from '../../../interfaces/IPlan';
import { RootActions } from '../../root.actions';
import {
  decrementProductsCountAction,
  disablePromotionBannerAction,
  getStatusAction,
  markAccountInfoAsFilledAction,
  setStatusAction,
  updateProductsLimitStatusAction,
  updateStatusOnAccountLoadedAction,
  updateStatusOnPreferencesLoadedAction,
} from './status.actions';

interface IStatusReducer {
  status: IRetailerStatus | null;
  fetching: boolean;
}

const initialState: IStatusReducer = {
  status: null,
  fetching: false,
};

export const statusReducer = createReducer<IStatusReducer, RootActions>(initialState)
  .handleAction(getStatusAction, (state) => ({
    ...state,
    fetching: true,
  }))
  .handleAction(setStatusAction, (state, { payload }) => ({
    status: payload,
    fetching: false,
  }))
  .handleAction(updateProductsLimitStatusAction, (state, { payload }) => ({
    ...state,
    status: state.status ? { ...state.status, ...payload } : null,
  }))
  .handleAction(decrementProductsCountAction, (state) => ({
    ...state,
    status: state.status
      ? { ...state.status, productsCount: state.status.productsCount - 1 }
      : null,
  }))
  .handleAction(updateStatusOnAccountLoadedAction, (state) => ({
    ...state,
    status: state.status
      ? {
          ...state.status,
          installationSteps: { ...state.status.installationSteps, accountInfoSetUp: true },
        }
      : null,
  }))
  .handleAction(updateStatusOnPreferencesLoadedAction, (state) => ({
    ...state,
    status: state.status
      ? {
          ...state.status,
          installationSteps: {
            ...state.status.installationSteps,
            preferencesReviewed: true,
          },
        }
      : null,
  }))
  .handleAction(markAccountInfoAsFilledAction, (state) => ({
    ...state,
    status: state.status
      ? {
          ...state.status,
          installationSteps: { ...state.status.installationSteps, accountInfoSetUp: true },
        }
      : null,
  }))
  .handleAction(disablePromotionBannerAction, (state) => ({
    ...state,
    status: state.status ? { ...state.status, showPromotionBanner: false } : null,
  }));
