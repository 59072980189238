import { AxiosResponse } from 'axios';
import { all, put, takeLatest } from 'redux-saga/effects';
import { ActionType } from 'typesafe-actions';
import {
  adminApi,
  GetViewAsClientReturnResult,
  UpdateViewAsClientReturnResult,
} from '../../../../core/api/admin.api';
import { setAxiosViewAsClient } from '../../../api/base.api';
import {
  getViewAsClientSettingAction,
  setViewAsClientSettingAction,
  showAdminSettingsUpdatedToastAction,
  updateViewAsClientSettingAction,
} from './admin.actions';

function* getViewAsClientSettingSaga(action: ActionType<typeof getViewAsClientSettingAction>) {
  try {
    const {
      data,
    }: AxiosResponse<GetViewAsClientReturnResult> = yield adminApi.getViewAsClientSetting();
    yield put(setViewAsClientSettingAction(data.viewAsClient));
    setAxiosViewAsClient(data.viewAsClient?._id);
  } catch (e) {
    console.error(e);
  }
}

function* updateViewAsClientSettingSaga(
  action: ActionType<typeof updateViewAsClientSettingAction>,
) {
  try {
    const {
      data,
    }: AxiosResponse<UpdateViewAsClientReturnResult> = yield adminApi.updateViewAsClientSetting(
      action.payload,
    );
    if ('notFound' in data && data.notFound) {
      console.error('Account not found when updating viewAsClient setting');
    } else if ('viewAsClient' in data) {
      setAxiosViewAsClient(data.viewAsClient?._id);
      yield put(setViewAsClientSettingAction(data.viewAsClient));
      yield put(showAdminSettingsUpdatedToastAction());
    }
  } catch (e) {
    console.error(e);
  }
}

export function* adminSaga() {
  yield all([takeLatest(getViewAsClientSettingAction, getViewAsClientSettingSaga)]);
  yield all([takeLatest(updateViewAsClientSettingAction, updateViewAsClientSettingSaga)]);
}
