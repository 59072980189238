import { AxiosResponse } from 'axios';
import { all, put, takeLatest } from 'redux-saga/effects';
import { ActionType } from 'typesafe-actions';
import { plansApi } from '../../../api/plans.api';
import { IPlan } from '../../../interfaces/IPlan';
import { getStatusAction } from '../status/status.actions';
import { getPlansAction, setPlansAction } from './plans.actions';

function* getPlansSaga(action: ActionType<typeof getPlansAction>) {
  try {
    const res: AxiosResponse<IPlan[]> = yield plansApi.getPlans();
    yield put(setPlansAction(res.data));
    yield put(getStatusAction());
  } catch (e) {
    console.error(e);
  }
}

export function* plansSaga() {
  yield all([takeLatest(getPlansAction, getPlansSaga)]);
}
