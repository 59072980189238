import { AppControlledTextField } from '../../../../../core/components/forms/controlled-text-field/controlled-text-field';
import { AppFormLayoutGroup } from '../../../../../core/components/forms/form-layout-group/form-layout-group';
import { AppFormLayout } from '../../../../../core/components/forms/form-layout/form-layout';
import { validation } from '../../../../../core/helpers/validations.helper';
import './order-details-inputs.scss';

export const DimensionsInputGroup = () => {
  return (
    <div className="dimensions-input-group">
      <AppFormLayout>
        <AppFormLayoutGroup condensed>
          <AppControlledTextField
            label="Dimensions (L x W x H)"
            name={'dimensions.width'}
            placeholder="W"
            type="number"
            validate={(value) => validation.isNumber(value, { noSymbols: true })}
            suffix="in"
            connectedLeft={
              <AppControlledTextField
                name={'dimensions.length'}
                placeholder="L"
                type="number"
                validate={(value) => validation.isNumber(value, { noSymbols: true })}
                suffix="in"
              />
            }
            connectedRight={
              <AppControlledTextField
                name={'dimensions.height'}
                placeholder="H"
                type="number"
                validate={(value) => validation.isNumber(value, { noSymbols: true })}
                suffix="in"
              />
            }
          />
        </AppFormLayoutGroup>
      </AppFormLayout>
    </div>
  );
};
