import { createAction } from 'typesafe-actions';
import { IGetProductSyncMatchesFilter } from '../../../api/sync.api';
import {
  IProductMatch,
  IProductSync,
  IProductSyncMatchFilter,
} from '../../../interfaces/IProductsSync';
import { ACTIONS_CONSTANTS } from '../../actions-constants';

export const getSyncStatusAction = createAction(ACTIONS_CONSTANTS.GET_SYNC_STATUS)();

export const setSyncStatusAction = createAction(
  ACTIONS_CONSTANTS.SET_SYNC_STATUS,
  (result: Pick<IProductSync, 'syncInProgress' | 'syncStart' | 'syncEnd'>) => result,
)();

export const getSyncMatchesAction = createAction(
  ACTIONS_CONSTANTS.GET_SYNC_MATCHES,
  (filter: IGetProductSyncMatchesFilter) => filter,
)();

export const setSyncMatchesAction = createAction(
  ACTIONS_CONSTANTS.SET_SYNC_MATCHES,
  (result: { matches: IProductMatch[]; total: number }) => result,
)();

export const getVendorsForMatchesAction = createAction(ACTIONS_CONSTANTS.GET_VENDORS_FOR_MATCHES)();

export const setVendorsForMatchesAction = createAction(
  ACTIONS_CONSTANTS.SET_VENDORS_FOR_MATCHES,
  (vendors: string[]) => vendors,
)();

export const getSyncConflictsAction = createAction(ACTIONS_CONSTANTS.GET_SYNC_CONFLICTS)();

export const setSyncConflictsAction = createAction(
  ACTIONS_CONSTANTS.SET_SYNC_CONFLICTS,
  (result: Pick<IProductSync, 'conflicts'>) => result,
)();

export const startProductSyncAction = createAction(ACTIONS_CONSTANTS.START_PRODUCT_SYNC)();

export const linkMatchesAction = createAction(
  ACTIONS_CONSTANTS.LINK_SYNC_MATCHES,
  (matches: IProductMatch[]) => matches,
)();

export const linkAllMatchesAction = createAction(
  ACTIONS_CONSTANTS.LINK_ALL_SYNC_MATCHES,
  (filter: IProductSyncMatchFilter) => filter,
)();

export const resolveConflictAction = createAction(
  ACTIONS_CONSTANTS.RESOLVE_SYNC_CONFLICT,
  (conflict: { conflictId: string; match: IProductMatch }) => conflict,
)();

export const showLinkToastAction = createAction(
  ACTIONS_CONSTANTS.SHOW_PRODUCT_LINK_TOAST,
  (result: { success: boolean; failed?: number }) => result,
)();

export const hideLinkToastAction = createAction(ACTIONS_CONSTANTS.HIDE_PRODUCT_LINK_TOAST)();

export const showLimitExceededBannerAction = createAction(
  ACTIONS_CONSTANTS.SHOW_SYNC_PRODUCT_LIMIT_EXCEEDED_BANNER,
)();

export const hideLimitExceededBannerAction = createAction(
  ACTIONS_CONSTANTS.HIDE_SYNC_PRODUCT_LIMIT_EXCEEDED_BANNER,
)();
