import React from 'react';
import { useSelector } from 'react-redux';
import { AppBanner } from '../../../../core/components/feedback-indicators/banner/banner';
import { AppLink } from '../../../../core/components/link/link';
import { getStatusSelector } from '../../../redux/modules/status/status.selectors';
import './cancellation-requested.banner.scss';

export function CancellationRequestedBanner() {
  const { cancellationRequests: requestsCount } = useSelector(getStatusSelector);

  if (!requestsCount) return null;
  return (
    <div className="banner">
      <AppBanner status="critical" title="Order cancellation requested">
        <p>
          You have {requestsCount} cancellation request{requestsCount > 1 && 's'}. Please consider
          responding to them on the <AppLink url={'/orders'}>orders page</AppLink> shortly, before
          they will be auto-accepted.
        </p>
      </AppBanner>
    </div>
  );
}
