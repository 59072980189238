import { AppsIcon, MenuIcon, ProfileIcon, SettingsFilledIcon } from '@shopify/polaris-icons';
import React, { ReactNode, useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { AppButtonGroup } from '../../../../core/components/button-group/button-group';
import { AppButton } from '../../../../core/components/button/Button';
import { AppToast } from '../../../../core/components/feedback-indicators/toast/toast';
import { AppIcon } from '../../../../core/components/icon/icon';
import { AppPage } from '../../../../core/components/structure/page/page';
import { AppTextContainer } from '../../../../core/components/text-container/text-container';
import { PRODUCTS_PER_PAGE, PRODUCT_VIEW } from '../../../../core/constants/product.constants';
import { getStatusAction } from '../../../redux/modules/status/status.actions';
import { getStatusSelector } from '../../../redux/modules/status/status.selectors';
import { ProductsLimitErrorBanner } from '../../banners/products-limit-error-banner/products-limit-error-banner';
import { MarketplaceSuppliersListLayout } from '../marketplace-suppliers-layout/marketplace-suppliers-layout';
import { MarketplaceProductGridLayout } from './marketplace-product-grid-layout';
import { MarketplaceProductListLayout } from './marketplace-product-list-layout';
import { MarketplaceVariantViewLayout } from './marketplace-variant-view-layout';

import { hideLimitExceededBannerAction } from '../../../redux/modules/import-list/import-list.actions';
import { showLimitExceededBannerSelector } from '../../../redux/modules/import-list/import-list.selectors';
import {
  getMarketplaceFilterOptionsAction,
  hideImportListProductsAddedToastAction,
  setMarketplaceProductListLimitFilterAction,
} from '../../../redux/modules/marketplace/marketplace.actions';
import { showProductsAddedToImportListToastSelector } from '../../../redux/modules/marketplace/marketplace.selectors';
import './marketplace-layout.scss';

export function MarketplaceLayout() {
  const history = useHistory();
  const dispatch = useDispatch();
  const status = useSelector(getStatusSelector);
  const showErrorBanner = useSelector(showLimitExceededBannerSelector);
  const productsAdded = useSelector(showProductsAddedToImportListToastSelector);

  const [productsView, setProductsView] = useState<PRODUCT_VIEW>(PRODUCT_VIEW.GRID);

  useEffect(() => {
    dispatch(getStatusAction());
    dispatch(getMarketplaceFilterOptionsAction());
    dispatch(setMarketplaceProductListLimitFilterAction({ limit: PRODUCTS_PER_PAGE }));
  }, [dispatch]);

  const hideErrorBanner = useCallback(() => {
    dispatch(hideLimitExceededBannerAction());
  }, [dispatch]);

  const hideProductsAddedToast = useCallback(() => {
    dispatch(hideImportListProductsAddedToastAction());
  }, [dispatch]);

  const handleProductViewChange = useCallback((view: PRODUCT_VIEW) => {
    setProductsView(view);
  }, []);

  const tabContent: Record<PRODUCT_VIEW, ReactNode> = useMemo(
    () => ({
      [PRODUCT_VIEW.GRID]: <MarketplaceProductGridLayout />,
      [PRODUCT_VIEW.LIST]: <MarketplaceProductListLayout />,
      [PRODUCT_VIEW.BUILD]: <MarketplaceVariantViewLayout />,
      [PRODUCT_VIEW.SUPPLIERS]: (
        <MarketplaceSuppliersListLayout handleProductViewChange={handleProductViewChange} />
      ),
    }),
    [handleProductViewChange],
  );

  return (
    <div className="marketplace-wrapper">
      {status && status.productsLimit && showErrorBanner ? (
        <ProductsLimitErrorBanner
          limit={status.productsLimit}
          added={status.productsCount}
          onDismiss={hideErrorBanner}
          error={showErrorBanner}
        />
      ) : null}
      <AppPage title="Marketplace">
        {productsView === PRODUCT_VIEW.BUILD && (
          <AppTextContainer>
            <p style={{ padding: '1rem 0' }}>
              Manually connect Drop Ship products with your Shopify listings. Select a Marketplace
              Product and Click "Connect" to assign a Shopify store SKU. Crowdship will only sync
              updates to pricing and inventory for manually connected products.
            </p>
          </AppTextContainer>
        )}
        <div className="marketplace-wrapper__product_view">
          <AppButtonGroup segmented>
            <AppButton
              pressed={productsView === PRODUCT_VIEW.GRID}
              onClick={() => setProductsView(PRODUCT_VIEW.GRID)}
              icon={<AppIcon source={AppsIcon} color="base" />}
            />
            <AppButton
              pressed={productsView === PRODUCT_VIEW.LIST}
              onClick={() => setProductsView(PRODUCT_VIEW.LIST)}
              icon={<AppIcon source={MenuIcon} color="base" />}
            />
            <AppButton
              pressed={productsView === PRODUCT_VIEW.BUILD}
              onClick={() => setProductsView(PRODUCT_VIEW.BUILD)}
              icon={<AppIcon source={SettingsFilledIcon} color="base" />}
            />
            <AppButton
              pressed={productsView === PRODUCT_VIEW.SUPPLIERS}
              onClick={() => setProductsView(PRODUCT_VIEW.SUPPLIERS)}
              icon={<AppIcon source={ProfileIcon} color="base" />}
            />
          </AppButtonGroup>
        </div>
        {tabContent[productsView]}
      </AppPage>
      {productsAdded && (
        <AppToast
          onDismiss={hideProductsAddedToast}
          content="Products added"
          action={{
            accessibilityLabel: 'Go to Import List',
            content: 'Go to Import List',
            onAction: () => history.push('/import-list'),
          }}
        />
      )}
    </div>
  );
}
