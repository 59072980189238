import React from 'react';
import { AppBadge } from '../../../../core/components/badge/badge';
import { AppDataTable } from '../../../../core/components/data-table/DataTable';
import { DateTooltip } from '../../../../core/components/date-tooltip/date-tooltip';
import { withCurrency } from '../../../../core/helpers/currency.helper';
import { IRetailerPurchaseOrderWithUnpaidFeeForList } from '../../../interfaces/IOrder';

type PendingFeeListProps = {
  orders: IRetailerPurchaseOrderWithUnpaidFeeForList[];
};

export const PendingFeeList = ({ orders }: PendingFeeListProps) => (
  <AppDataTable
    columnContentTypes={['text', 'text', 'text', 'numeric', 'numeric', 'text']}
    headings={['Order', 'Created at', 'Supplier', 'Total', 'Fee', 'Status']}
    rows={orders.map((i) => [
      i.name,
      <DateTooltip date={i.createdAt} />,
      i.supplierName,
      withCurrency(i.total),
      withCurrency(i.fee),
      <AppBadge progress="complete">Unpaid</AppBadge>,
    ])}
  />
);
