import React from 'react';
import { AppSkeletonPage } from '../../../../core/components/feedback-indicators/skeleton/skeleton-page/skeleton-page';
import { AppSkeletonBodyText } from '../../../../core/components/feedback-indicators/skeleton/skeleton-body-text/skeleton-body-text';
import { AppSkeletonDisplayText } from '../../../../core/components/feedback-indicators/skeleton/skeleton-display-text/skeleton-display-text';
import { AppLayout } from '../../../../core/components/structure/layout/layout';
import { AppLayoutSection } from '../../../../core/components/structure/layout/layout-section';
import { AppCard } from '../../../../core/components/structure/card/card';
import { AppTextContainer } from '../../../../core/components/text-container/text-container';

export function PlanListSkeleton() {
  return (
    <AppSkeletonPage title="Subscription management">
      <AppLayout>
        <AppLayoutSection oneThird>
          <AppCard
            sections={[
              {
                content: (
                  <AppTextContainer>
                    <AppSkeletonDisplayText size="small" />
                    <AppSkeletonBodyText />
                  </AppTextContainer>
                ),
              },
              { content: <AppSkeletonBodyText /> },
            ]}
          />
        </AppLayoutSection>
        <AppLayoutSection oneThird>
          <AppCard
            sections={[
              {
                content: (
                  <AppTextContainer>
                    <AppSkeletonDisplayText size="small" />
                    <AppSkeletonBodyText />
                  </AppTextContainer>
                ),
              },
              { content: <AppSkeletonBodyText /> },
            ]}
          />
        </AppLayoutSection>
        <AppLayoutSection oneThird>
          <AppCard
            sections={[
              {
                content: (
                  <AppTextContainer>
                    <AppSkeletonDisplayText size="small" />
                    <AppSkeletonBodyText />
                  </AppTextContainer>
                ),
              },
              { content: <AppSkeletonBodyText /> },
            ]}
          />
        </AppLayoutSection>
      </AppLayout>
    </AppSkeletonPage>
  );
}
