import { axiosInstance } from '../../core/api/base.api';
import { IVariantFromImportList } from '../interfaces/IProduct';
import { IProductImage } from '../interfaces/IProductDetails';

class ImportListApi {
  private backend = process.env.REACT_APP_BACK_END_URL as string;
  private retailerPath = '/api/retailer/products/import-list';

  async getImportListProducts(filters: IGetImportListProductGroupsFilterOptions) {
    const { page, limit } = filters;

    return axiosInstance.get<IImportListProductGroupsWithCount>(
      `${this.backend}${this.retailerPath}/`,
      {
        params: {
          limit,
          page,
        },
      },
    );
  }

  async getImportListVariants(productId: string) {
    return axiosInstance.get<IVariantFromImportList[]>(
      `${this.backend}${this.retailerPath}/variants`,
      {
        params: {
          productId,
        },
      },
    );
  }

  async addImportListProductsToInventory(productIds: string[], importMax: boolean) {
    return axiosInstance.post<IPostProductsResponse>(`${this.backend}${this.retailerPath}/`, {
      productIds,
      importMax,
    });
  }

  async addAllImportListProductsToInventory(importMax: boolean) {
    return axiosInstance.post<IPostProductsResponse>(`${this.backend}${this.retailerPath}/all`, {
      importMax,
    });
  }

  async updateProductDetails(
    productGroupId: string,
    details: { title: string; productType: string; tags: string; vendor: string },
  ) {
    const { title, productType, tags, vendor } = details;

    return axiosInstance.put<void>(`${this.backend}${this.retailerPath}/details`, {
      productGroupId,
      title,
      productType,
      tags,
      vendor,
    });
  }

  async updateProductDescription(productGroupId: string, description: string) {
    return axiosInstance.put<void>(`${this.backend}${this.retailerPath}/description`, {
      productGroupId,
      description,
    });
  }

  async updateProductVariants(variants: IUpdateVariant[]) {
    return axiosInstance.put<void>(`${this.backend}${this.retailerPath}/variants`, {
      variants,
    });
  }

  async updateProductImages(productGroupId: string, images: IProductImage[]) {
    return axiosInstance.put<void>(`${this.backend}${this.retailerPath}/images`, {
      productGroupId,
      images,
    });
  }

  async removeProduct(productGroupIds: string[]) {
    return axiosInstance.delete<void>(`${this.backend}${this.retailerPath}/`, {
      data: { productGroupIds },
    });
  }
}

export const importListApi = new ImportListApi();

export type IPostProductsResponse = SuccessAddProductsResponse | NotFoundResponse;

type NotFoundResponse = { notFound: true };

export type SuccessAddProductsResponse = {
  success: true;
  added: number;
};

interface IGetImportListProductGroupsFilterOptions {
  limit: number;
  page: number;
}

export interface IImportListProductGroupsWithCount {
  productGroups: IImportListProductGroup[];
  total: number;
}

export interface IUpdateVariant {
  id: string;
  price: number;
  compareAtPrice: number | null;
  manuallySetPrice: boolean;
}

export interface IImportListProductGroup {
  id: string;
  crowdshipProductGroup: string;
  title: string;
  tags: string;
  vendor: string;
  productType: string;
  image: string;
  images: IProductImage[];
  products: string[];
  variationsNumber: number;
  description: string;
  variants?: IVariantFromImportList[];
}
