import React from 'react';
import { AppBanner } from '../../../../core/components/feedback-indicators/banner/banner';
import { AppLink } from '../../../../core/components/link/link';
import { IRetailerStatus } from '../../../interfaces/IPlan';

interface IChargeConfirmationBannerProps {
  status: IRetailerStatus | null;
}

export const ChargeConfirmationBanner = ({ status }: IChargeConfirmationBannerProps) => {
  if (!status?.chargeConfirmation) return null;

  return (
    <div className="banner">
      <AppBanner title="Accept your application charge" status="warning">
        You've selected a new plan to subscribe to but have not confirmed the payment yet. Please,{' '}
        <AppLink
          onClick={() =>
            status.chargeConfirmation && window.open(status.chargeConfirmation.url, '_blank')
          }
          external
        >
          go to the charge confirmation page
        </AppLink>{' '}
        and accept the charge to change the plan{' '}
        {status.chargeConfirmation.planName && `to ${status.chargeConfirmation.planName}`}.
      </AppBanner>
    </div>
  );
};
