import fileDownload from 'js-file-download';
import moment from 'moment';
import queryString from 'query-string';
import React, { useCallback, useEffect, useState, useMemo } from 'react';
import { AppCard } from '../../../../core/components/structure/card/card';
import { ORDERS_PER_PAGE } from '../../../../core/constants/orders';
import { IGetPurchaseOrders, ordersApi } from '../../../api/orders.api';
import { IOption } from '../../../../core/interfaces/IOption';
import { IRetailerPurchaseOrderForList } from '../../../interfaces/IOrder';
import { PurchaseOrderList } from '../../containers/order/purchase-order-list/purchase-order-list';
import { useLocation } from 'react-router';
import { TIME_INTERVAL } from '../../../../core/constants/product.constants';

type PurchaseOrderListLayoutProps = {
  selectedTab: number;
  searchString: string;
  changeUrlParams: (
    tabNumber: any,
    params?: Omit<IGetPurchaseOrders, 'dateRange'> & {
      period?: TIME_INTERVAL;
      start?: string;
      end?: string;
    },
  ) => void;
};

export const PurchaseOrderListLayout = ({
  selectedTab,
  changeUrlParams,
  searchString,
}: PurchaseOrderListLayoutProps) => {
  const { search: query } = useLocation();

  const {
    fulfillmentStatus: URLStatuses,
    paymentStatus: URLPaymentStatus,
    suppliers: URLSuppliers,
    start: URLStartDate,
    end: URLEndDate,
    page,
    limit,
    period: URLPeriod,
    query: searchQueryString,
  } = queryString.parse(query, { arrayFormat: 'separator', arrayFormatSeparator: '.' });

  const [fetching, setFetching] = useState(false);
  const [orders, setOrders] = useState<IRetailerPurchaseOrderForList[]>([]);
  const [total, setTotal] = useState(0);
  const [selectedPaymentStatus, setSelectedPaymentStatus] = useState<string[]>(
    !!URLPaymentStatus
      ? Array.isArray(URLPaymentStatus) && URLPaymentStatus.length
        ? (URLPaymentStatus as string[])
        : ([URLPaymentStatus] as string[])
      : [],
  );
  const [selectedSuppliers, setSelectedSuppliers] = useState<string[]>(
    !!URLSuppliers
      ? Array.isArray(URLSuppliers) && URLSuppliers.length
        ? (URLSuppliers as string[])
        : ([URLSuppliers] as string[])
      : [],
  );
  const [suppliers, setSuppliers] = useState<IOption[]>([]);
  const [paymentStatus] = useState<IOption[]>([
    { label: 'Paid', value: 'paid' },
    { label: 'Unpaid', value: 'unpaid' },
  ]);
  const [selectedFulfillmentStatus, setSelectedFulfillmentStatus] = useState<string[]>(
    !!URLStatuses
      ? Array.isArray(URLStatuses) && URLStatuses.length
        ? (URLStatuses as string[]).map((s) => s.replace('-', ' '))
        : [(URLStatuses as string).replace('-', ' ')]
      : [],
  );
  const [fulfillmentStatus] = useState<IOption[]>([
    { label: 'Fulfilled', value: 'fulfilled' },
    { label: 'Partially fulfilled', value: 'partially fulfilled' },
    { label: 'Partially unfulfilled', value: 'partially unfulfilled' },
    { label: 'Cancelled', value: 'cancelled' },
    { label: 'Unfulfilled', value: 'unfulfilled' },
    { label: 'Payment failed', value: 'payment failed' },
    { label: 'Account suspended', value: 'account suspended' },
    { label: 'Needs Attention', value: 'needs attention' },
  ]);
  const [filterParams, setFilterParams] = useState<IGetPurchaseOrders>({
    page: +(page as string) || 0,
    limit: +(limit as string) || ORDERS_PER_PAGE,
    query: searchQueryString as string,
    paymentStatus: URLPaymentStatus ? (URLPaymentStatus as string) : undefined,
    fulfillmentStatus: !!URLStatuses
      ? Array.isArray(URLStatuses) && URLStatuses.length
        ? (URLStatuses as string[]).map((s) =>
            s.replace('-', ' ').replace('needs attention', 'error'),
          )
        : [(URLStatuses as string).replace('-', ' ').replace('needs attention', 'error')]
      : undefined,
    suppliers: !!URLSuppliers
      ? Array.isArray(URLSuppliers) && URLSuppliers.length
        ? (URLSuppliers as string[])
        : ([URLSuppliers] as string[])
      : undefined,
    dateRange:
      URLStartDate && URLEndDate
        ? { start: URLStartDate as string, end: URLEndDate as string }
        : undefined,
  });

  const [period, setPeriod] = useState<TIME_INTERVAL[]>(
    URLPeriod ? [(URLPeriod as string).replace('-', ' ') as TIME_INTERVAL] : [TIME_INTERVAL.NONE],
  );

  const onPageChange = useCallback(
    (page: number) => {
      setFilterParams({ ...filterParams, page });
      changeUrlParams(selectedTab, {
        ...filterParams,
        ...(!!filterParams.dateRange?.start &&
          !!filterParams.dateRange?.end && {
            start: moment(filterParams.dateRange?.start).format('MM-DD-YYYY'),
            end: moment(filterParams.dateRange?.end).format('MM-DD-YYYY'),
          }),
        period: (period[0] as string).replace(' ', '-') as TIME_INTERVAL,
        page,
      });
    },
    [changeUrlParams, filterParams, period, selectedTab],
  );

  const totalPageCount = useMemo(() => Math.ceil(total / ORDERS_PER_PAGE), [total]);

  const handleExportCSVAll = useCallback((request: Omit<IGetPurchaseOrders, 'limit' | 'page'>) => {
    setFetching(true);
    ordersApi
      .exportAllPurchaseOrders(request)
      .then(({ data }) => {
        const now = moment().format('MMDDYYYY');
        fileDownload(data, `Purchase orders-${now}.csv`);
        setFetching(false);
      })
      .catch(console.error)
      .finally(() => {
        setFetching(false);
      });
  }, []);

  useEffect(() => {
    setFetching(true);
    ordersApi
      .getPurchaseOrders(filterParams)
      .then(({ data }) => {
        setOrders(data.orders);
        setTotal(data.total);
      })
      .catch(console.error)
      .finally(() => {
        setFetching(false);
      });
  }, [filterParams]);

  useEffect(() => {
    ordersApi
      .getSuppliersForPurchaseOrders()
      .then(({ data }) => {
        setSuppliers(data);
      })
      .catch(console.error);
  }, []);

  return (
    <AppCard title="Purchase Orders" sectioned>
      <PurchaseOrderList
        orders={orders}
        fetching={fetching}
        onPageChange={onPageChange}
        setFilterParams={setFilterParams}
        suppliers={suppliers}
        setSelectedSuppliers={setSelectedSuppliers}
        selectedSuppliers={selectedSuppliers}
        totalPageCount={totalPageCount}
        filterParams={filterParams}
        paymentStatus={paymentStatus}
        selectedPaymentStatus={selectedPaymentStatus}
        setSelectedPaymentStatus={setSelectedPaymentStatus}
        fulfillmentStatus={fulfillmentStatus}
        selectedFulfillmentStatus={selectedFulfillmentStatus}
        setSelectedFulfillmentStatus={setSelectedFulfillmentStatus}
        handleExportCSVAll={handleExportCSVAll}
        searchString={searchString}
        changeUrlParams={changeUrlParams}
        selectedTab={selectedTab}
        period={period}
        setPeriod={setPeriod}
      />
    </AppCard>
  );
};
