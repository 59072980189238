import { createSelector } from 'reselect';
import { RootState } from '../../reducers';

export const getImportListProductsSelector = createSelector(
  [(state: RootState) => state.importList.productList.productGroups],
  (products) => products,
);

export const getTotalImportListProductsCountSelector = createSelector(
  [(state: RootState) => state.importList.productList.total],
  (total) => total,
);

export const getImportListProductsPageSelector = createSelector(
  [(state: RootState) => state.importList.productList.page],
  (page) => page,
);

export const fetchingImportListProductsSelector = createSelector(
  [(state: RootState) => state.importList.fetching],
  (fetching) => fetching,
);

export const showLimitExceededBannerSelector = createSelector(
  [(state: RootState) => state.importList.showLimitExceededBanner],
  (showBanner) => showBanner,
);

export const showLimitExceededModalSelector = createSelector(
  [(state: RootState) => state.importList.showLimitExceededModal],
  (showModal) => showModal,
);

export const showProductsARemovedToastSelector = createSelector(
  [(state: RootState) => state.importList.areProductRemoved],
  (removed) => removed,
);

export const showProductsAddedToInventoryToastSelector = createSelector(
  [(state: RootState) => state.importList.areProductsAddedToInventory],
  (added) => added,
);

export const showImportListFetchingErrorToastSelector = createSelector(
  [(state: RootState) => state.importList.importListFetchingError],
  (added) => added,
);
