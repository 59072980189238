import { axiosInstance } from '../../core/api/base.api';
import { IPlan } from '../interfaces/IPlan';

class PlansApi {
  private backend = process.env.REACT_APP_BACK_END_URL as string;
  private rootPath = '/api/retailer/plans';

  async getPlans() {
    return await axiosInstance.get<IPlan[]>(`${this.backend}${this.rootPath}`);
  }

  async checkPlanChangeConfirmation() {
    return await axiosInstance.get<CheckPlanChangeConfirmationReturnResult>(
      `${this.backend}${this.rootPath}`,
    );
  }

  async requestPlanChange(planId: string) {
    return await axiosInstance.post<RequestPlanChangeReturnResult>(
      `${this.backend}${this.rootPath}`,
      { planId },
    );
  }
}

export const plansApi = new PlansApi();

export interface IChangePlanLimitExceededErrorResponse {
  limitExceeded: true;
  productsCount: number;
}

export type RequestPlanChangeReturnResult =
  | IChangePlanLimitExceededErrorResponse
  | { changed: true }
  | { requested: true; confirmationUrl: string };

export type CheckPlanChangeConfirmationReturnResult =
  | { confirmed: true }
  | { chargeNotPending: true }
  | { confirmationUrl: string };
