import { DatePicker } from '@shopify/polaris';
import moment from 'moment';
import { ReactNode, useCallback, useState } from 'react';
import { TIME_INTERVAL } from '../../constants/product.constants';
import { AppChoiceList } from '../choice-list/ChoiceList';
import { AppCollapsible } from '../collapsible/Collapsible';
import { AppPopoverWithSection } from '../overlays/popover/popover-with-section';
import { AppCard } from '../structure/card/card';
import { AppCardHeader } from '../structure/card/card-header';

type CardWithDatePickerProps = {
  title: string;
  onChange: (selected?: { start: string; end: string }) => void;
  children: ReactNode;
  hideFilter: boolean;
};

export const CardWithDatePicker = ({
  title,
  onChange,
  children,
  hideFilter,
}: CardWithDatePickerProps) => {
  const [period, setPeriod] = useState<TIME_INTERVAL[]>([TIME_INTERVAL.NONE]);
  const [{ month, year }, setDate] = useState({ month: moment().month(), year: moment().year() });
  const [selectedDates, setSelectedDates] = useState({
    start: moment().subtract(7, 'd').toDate(),
    end: moment().toDate(),
  });

  const handleMonthChange = useCallback((month, year) => setDate({ month, year }), []);

  const getChosenDateRange = useCallback(
    (interval: TIME_INTERVAL): { start: string; end: string } | undefined => {
      const todayTimestamp = moment().toISOString();
      switch (interval) {
        case TIME_INTERVAL.NONE:
          return;
        case TIME_INTERVAL.WEEK:
          return {
            start: moment().subtract(7, 'd').toISOString(),
            end: todayTimestamp,
          };
        case TIME_INTERVAL.MONTH:
          return {
            start: moment().subtract(1, 'M').toISOString(),
            end: todayTimestamp,
          };
        case TIME_INTERVAL.SIX_MONTHS:
          return {
            start: moment().subtract(6, 'M').toISOString(),
            end: todayTimestamp,
          };
        case TIME_INTERVAL.CUSTOM:
          return {
            start: selectedDates.start.toISOString(),
            // we need to grab the end-of-day moment to include invoices created throughout the day
            end: moment(selectedDates.end).endOf('day').toISOString(),
          };
      }
    },
    [selectedDates],
  );

  const handleCustomDateChange = useCallback(
    (date: { start: Date; end: Date }) => {
      setSelectedDates(date);
      onChange({
        start: date.start.toISOString(),
        // we need to grab the end-of-day moment to include invoices created throughout the day
        end: moment(date.end).endOf('day').toISOString(),
      });
    },
    [onChange],
  );

  return (
    <AppCard>
      <AppCardHeader title={title}>
        {(!hideFilter || !period.includes(TIME_INTERVAL.NONE)) && (
          <AppPopoverWithSection title={period[0]} fixed>
            <AppChoiceList
              title="Time interval options list"
              titleHidden
              choices={[
                { label: 'all', value: TIME_INTERVAL.NONE },
                { label: 'last week', value: TIME_INTERVAL.WEEK },
                { label: 'last month', value: TIME_INTERVAL.MONTH },
                { label: 'last six months', value: TIME_INTERVAL.SIX_MONTHS },
                { label: 'custom', value: TIME_INTERVAL.CUSTOM },
              ]}
              selected={period}
              onChange={(val: TIME_INTERVAL[]) => {
                setPeriod(val);
                onChange(getChosenDateRange(val[0]));
              }}
            />
          </AppPopoverWithSection>
        )}
      </AppCardHeader>
      <div className="wrapper">
        <AppCollapsible id="datePicker" open={period.includes(TIME_INTERVAL.CUSTOM)}>
          <DatePicker
            month={month}
            year={year}
            onChange={handleCustomDateChange}
            onMonthChange={handleMonthChange}
            selected={selectedDates}
            multiMonth
            allowRange
          />
        </AppCollapsible>
      </div>
      {children}
    </AppCard>
  );
};
