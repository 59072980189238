import { Fragment, ReactNode, useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { AppToast } from '../../../../core/components/feedback-indicators/toast/toast';
import { AppPage } from '../../../../core/components/structure/page/page';
import { AppTabs } from '../../../../core/components/tabs/tabs';
import { ViewAsClientSetting } from '../../../../core/components/view-as-client-setting/view-as-client-setting';
import { IShopViewDetails } from '../../../../core/interfaces/IAccount';
import { hideAdminSettingsUpdatedToastAction } from '../../../../core/redux/modules/admin/admin.actions';
import { getAdminSettingsUpdatedSelector } from '../../../../core/redux/modules/admin/admin.selector';
import { ACCOUNT_ROLE } from '../../../../core/redux/modules/auth/auth.reducer';
import { getRolesSelector } from '../../../../core/redux/modules/auth/auth.selectors';
import { supplierApi } from '../../../api/supplier.api';
import { getStatusAction } from '../../../redux/modules/status/status.actions';
import { BulkPriceUpdate } from '../../containers/bulk-price-update/bulk-price-update';

export const AdminToolsLayout = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [suppliers, setSuppliers] = useState<IShopViewDetails[]>([]);
  const roles = useSelector(getRolesSelector);
  const updated = useSelector(getAdminSettingsUpdatedSelector);
  const hideToast = useCallback(() => dispatch(hideAdminSettingsUpdatedToastAction()), [dispatch]);

  const [selectedTab, setSelectedTab] = useState<number>(0);

  if (!roles?.includes(ACCOUNT_ROLE.ADMIN)) {
    history.push('/');
  }

  useEffect(() => {
    setLoading(true);
    supplierApi
      .getSuppliers()
      .then(({ data }) => setSuppliers(data))
      .catch(console.error)
      .finally(() => setLoading(false));
  }, []);

  const onViewAsClientUpdate = useCallback(() => dispatch(getStatusAction()), [dispatch]);

  const tabContent: { [key: number]: ReactNode } = useMemo(
    () => ({
      0: (
        <ViewAsClientSetting items={suppliers} loading={loading} onChange={onViewAsClientUpdate} />
      ),
      1: <BulkPriceUpdate />,
    }),
    [onViewAsClientUpdate, loading, suppliers],
  );

  return (
    <Fragment>
      <AppPage title="Admin tools">
        <AppTabs
          tabs={[
            { id: 'view-as-client', content: 'View as client supplier list' },
            { id: 'bulk-update', content: 'Bulk price update via CSV' },
          ]}
          selected={selectedTab}
          onSelect={setSelectedTab}
        >
          {tabContent[selectedTab]}
        </AppTabs>
      </AppPage>
      {updated && <AppToast onDismiss={hideToast} content="Changes saved" />}
    </Fragment>
  );
};
