import React from 'react';
import { AppBadge } from '../../../../core/components/badge/badge';
import { AppButton } from '../../../../core/components/button/Button';
import { AppThumbnail } from '../../../../core/components/image-containers/thumbnail/Thumbnail';
import { AppIndexTable } from '../../../../core/components/index-table/index-table';
import { AppIndexTableCell } from '../../../../core/components/index-table/index-table-cell';
import { AppIndexTableRow } from '../../../../core/components/index-table/index-table-row';
import { PageAwarePagination } from '../../../../core/components/pagination/page-aware-pagination';
import { AppTextStyle } from '../../../../core/components/text/text-style/TextStyle';
import { TrackedQuantityLabelForVariant } from '../../../../core/components/tracked-quantity-label-for-variant/tracked-quantity-label-for-variant';
import { withCurrency } from '../../../../core/helpers/currency.helper';
import { IRetailerVariant } from '../../../interfaces/IProduct';

type IRetailerListProps = {
  variants: IRetailerVariant[];
  fetching: boolean;
  total: number;
  handlePageChange: (page: number) => void;
  handleConnectVariant: (retailerProduct: string) => void;
};

export function RetailerVariantList({
  variants,
  fetching,
  total,
  handlePageChange,
  handleConnectVariant,
}: IRetailerListProps) {
  return (
    <>
      <AppIndexTable
        resourceName={{ singular: 'retailer variant', plural: 'retailer variants' }}
        itemCount={variants.length}
        headings={[
          { title: 'Image', hidden: true },
          { title: 'Title' },
          { title: 'SKU' },
          { title: 'Fulfilled By' },
          { title: 'Price' },
          { title: 'Compare at price' },
          { title: 'Qty' },
          { title: 'Connect', hidden: true },
        ]}
        loading={fetching}
        selectable={false}
      >
        {variants.map((variant: IRetailerVariant, index: number) => (
          <AppIndexTableRow id={variant.id} key={variant.id} selected={false} position={index}>
            <AppIndexTableCell>
              <div className="product-image">
                <AppThumbnail
                  source={variant.image}
                  alt={variant.title || '' + variant.productGroupTitle}
                />
              </div>
            </AppIndexTableCell>
            <AppIndexTableCell>
              <div className="product-title">
                <AppTextStyle variation="subdued">{variant.vendor}</AppTextStyle>
                <AppTextStyle variation="strong">{variant.productGroupTitle}</AppTextStyle>
                {variant.title && <AppBadge>{variant.title}</AppBadge>}
              </div>
            </AppIndexTableCell>
            <AppIndexTableCell>{variant.sku}</AppIndexTableCell>
            <AppIndexTableCell>
              <div className="product-supplier">{variant.supplierName || 'In House'}</div>
            </AppIndexTableCell>
            <AppIndexTableCell>{withCurrency(variant.price)}</AppIndexTableCell>
            <AppIndexTableCell>
              {variant.compareAtPrice && withCurrency(variant.compareAtPrice)}
            </AppIndexTableCell>
            <AppIndexTableCell>
              <TrackedQuantityLabelForVariant
                quantity={variant.qty}
                tracked={variant.tracked}
                inventoryPolicy={variant.inventoryPolicy}
              />
            </AppIndexTableCell>
            <AppIndexTableCell>
              <div className="expand-variants-btn flex-center">
                <AppButton
                  handleClick={(e) => {
                    e.stopPropagation();
                    handleConnectVariant(variant.id);
                  }}
                >
                  Connect
                </AppButton>
                {!!variant.supplierName && (
                  <AppTextStyle variation="positive">connected</AppTextStyle>
                )}
              </div>
            </AppIndexTableCell>
          </AppIndexTableRow>
        ))}
      </AppIndexTable>
      <PageAwarePagination totalPageCount={total} onPageChange={handlePageChange} />
    </>
  );
}
