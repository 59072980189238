import { AppSpinner } from '../../feedback-indicators/spinner/spinner';
import { AppTextStyle } from '../../text/text-style/TextStyle';

export const ProcessingTag = () => (
  <span className="processing-tag">
    <span className="icon">
      <AppSpinner size="small" />
    </span>
    <span className="description">
      <AppTextStyle variation="subdued"> Processing...</AppTextStyle>
    </span>
  </span>
);
