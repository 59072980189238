import { createSelector } from 'reselect';
import { RootState } from '../../reducers';

export const isAuthenticatedSelector = createSelector(
  [(state: RootState) => state.auth.authenticated],
  (authenticated) => authenticated,
);

export const getRetailerNameSelector = createSelector(
  [(state: RootState) => state.auth.retailerName],
  (retailerName) => retailerName,
);

export const getAccountNameSelector = createSelector(
  [(state: RootState) => state.auth.name],
  (name) => name,
);

export const getRolesSelector = createSelector(
  [(state: RootState) => state.auth.roles],
  (roles) => roles || [],
);

export const getIntegrationsSelector = createSelector(
  [(state: RootState) => state.auth.integrations],
  (integrations) => integrations || [],
);
