type NonEmptyArray<T> = [T, ...T[]];
type Heading = {
  title: string;
  new?: boolean;
  hidden?: boolean;
};

export const builderHeadings: NonEmptyArray<Heading> = [
  { title: 'Image', hidden: true },
  { title: 'Title' },
  { title: 'SKU' },
  { title: 'Fulfilled By' },
  { title: 'Cost' },
  { title: 'SRP' },
  { title: 'Your margin' },
  { title: 'Qty' },
  { title: 'Connect', hidden: true },
];

export const marketplaceVariantHeadings: NonEmptyArray<Heading> = [
  { title: 'Image', hidden: true },
  { title: 'Title' },
  { title: 'SKU' },
  { title: 'Fulfilled By' },
  { title: 'Cost' },
  { title: 'SRP' },
  { title: 'Retailer Margin' },
  { title: 'Qty' },
  { title: 'Connect', hidden: true },
];
