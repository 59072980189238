import { Navigation } from '@shopify/polaris';
import {
  PersonFilledIcon,
  HomeFilledIcon,
  OrderFilledIcon,
  ProductFilledIcon,
  ReceiptIcon,
  SettingsFilledIcon,
  StoreFilledIcon,
  TeamIcon,
  WrenchIcon,
} from '@shopify/polaris-icons';
import React from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { ACCOUNT_ROLE } from '../../../../core/redux/modules/auth/auth.reducer';
import { getRolesSelector } from '../../../redux/modules/auth/auth.selectors';
import { ROUTES } from '../../../router/routes';

export const Sidebar = () => {
  const location = useLocation();
  const roles = useSelector(getRolesSelector);

  return (
    <Navigation location={location.pathname}>
      <Navigation.Section
        items={[
          {
            url: ROUTES.ROOT,
            exactMatch: true,
            label: 'Dashboard',
            icon: HomeFilledIcon,
          },
          {
            url: ROUTES.MARKETPLACE,
            label: 'Marketplace',
            icon: StoreFilledIcon,
          },
          {
            url: ROUTES.IMPORT_LIST,
            label: 'Import List',
            icon: StoreFilledIcon,
          },
          {
            url: ROUTES.INVENTORY,
            label: 'My products',
            icon: ProductFilledIcon,
          },
          {
            url: ROUTES.ORDERS,
            label: 'Orders',
            icon: OrderFilledIcon,
          },
          {
            url: ROUTES.ACCOUNTING,
            label: 'Accounting',
            icon: ReceiptIcon,
          },
          {
            url: ROUTES.SETTINGS,
            label: 'Settings',
            icon: SettingsFilledIcon,
          },
          {
            url: ROUTES.ACCOUNT,
            label: 'Account',
            icon: PersonFilledIcon,
          },
          {
            url: ROUTES.INTEGRATIONS,
            label: 'Integrations',
            icon: TeamIcon,
          },
          ...(roles.includes(ACCOUNT_ROLE.ADMIN)
            ? [{ url: ROUTES.ADMIN_TOOLS, label: 'Admin tools', icon: WrenchIcon }]
            : []),
        ]}
      />
    </Navigation>
  );
};
