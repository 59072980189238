import React, { useCallback } from 'react';
import { useField } from 'formik';
import './basic-form-input.scss';

interface IBasicFormInputComponent {
  name: string;
  controlled?: boolean;
  disabled?: boolean;
  onBlur?: (value: string) => void;
  autoFocus?: boolean;
}

export const BasicFormInput: React.FC<IBasicFormInputComponent> = (
  props: IBasicFormInputComponent,
) => {
  const { controlled, onBlur, ...otherProps } = props;

  const [field, meta] = useField(props.name);

  // trigger onBlur when Enter key is pressed
  const handleKeyPress = useCallback((e) => {
    if (e.keyCode === 13) {
      e.target.blur();
    }
  }, []);

  return (
    <div className="form_input_error_wrapper">
      <div className="form_input_wrapper">
        <input
          autoComplete="off"
          className="basic_formik_input"
          type="text"
          {...field}
          onBlur={(e) => {
            field.onBlur(e);
            if (onBlur) onBlur(field.value);
          }}
          value={controlled ? field.value || '' : field.value}
          {...otherProps}
          onKeyDown={handleKeyPress}
        />
        <div className="basic_formik_input_backdrop"></div>
      </div>
      <div className="form_error_wrapper">{meta.error ? <div>{meta.error}</div> : null}</div>
    </div>
  );
};
