import { createSelector } from 'reselect';
import { RootState } from '../../reducers';

export const getRetailerOrdersReducerSelector = createSelector(
  [(state: RootState) => state.orders],
  (ordersReducer) => ordersReducer,
);

export const getRetailerOrdersSelector = createSelector(
  [(state: RootState) => state.orders],
  (ordersReducer) => ordersReducer.orderList.orders,
);

export const getRetailerOrdersCountSelector = createSelector(
  [(state: RootState) => state.orders],
  (ordersReducer) => ordersReducer.orderList.total,
);

export const getRetailerOrderListFetchingSelector = createSelector(
  [(state: RootState) => state.orders],
  (ordersReducer) => ordersReducer.fetchingOrderList,
);

export const getRetailerOrderDetailsFetchingSelector = createSelector(
  [(state: RootState) => state.orders],
  (ordersReducer) => ordersReducer.fetchingOrderDetails,
);

export const getRetailerOrdersSummarySelector = createSelector(
  [(state: RootState) => state.orders],
  (ordersReducer) => ordersReducer.ordersSummary,
);

export const requestingCancellationSelector = createSelector(
  [(state: RootState) => state.orders],
  (ordersReducer) => ordersReducer.requestingCancellation,
);

export const showPaymentFailedToastSelector = createSelector(
  [(state: RootState) => state.orders],
  (ordersReducer) => ordersReducer.showPaymentFailedToast,
);

export const addressValidationToastsSelector = createSelector(
  [(state: RootState) => state.orders],
  (ordersReducer) => ({
    failedValidation: ordersReducer.showAddressValidationFailedToast,
    successValidation: ordersReducer.showAddressValidationSuccessToast,
  }),
);

export const validatingAddressSelector = createSelector(
  [(state: RootState) => state.orders],
  (ordersReducer) => ordersReducer.validatingAddress,
);

export const processingOrderSelector = createSelector(
  [(state: RootState) => state.orders],
  (ordersReducer) => ordersReducer.processing,
);

export const showUpdatingOrderStatusToastSelector = createSelector(
  [(state: RootState) => state.orders],
  (ordersReducer) => ordersReducer.showUpdatingOrderStatusToast,
);

export const getOrderDetailsSelector = createSelector(
  [(state: RootState, orderId: string) => state.orders.orderDetails[orderId]],
  (details) => details || null,
);
