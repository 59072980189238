import { axiosInstance } from '../../core/api/base.api';
import { IProductMatch, IProductSync, IProductSyncMatchFilter } from '../interfaces/IProductsSync';
import { IPostProductsResponse } from './inventory.api';

class SyncProductsApi {
  private backend = process.env.REACT_APP_BACK_END_URL as string;
  private rootPath = '/api/retailer/shopify/sync';

  async getProductSyncStatus() {
    return await axiosInstance.get<Pick<IProductSync, 'syncInProgress' | 'syncStart' | 'syncEnd'>>(
      `${this.backend}${this.rootPath}/status`,
    );
  }

  async getProductSyncMatches(filter: IGetProductSyncMatchesFilter) {
    return await axiosInstance.get<{
      matches: IProductMatch[];
      total: number;
    }>(`${this.backend}${this.rootPath}/matches`, {
      params: { ...filter, vendors: filter.vendors?.join() },
    });
  }

  async getProductSyncConflicts() {
    return await axiosInstance.get<Pick<IProductSync, 'conflicts'>>(
      `${this.backend}${this.rootPath}/conflicts`,
    );
  }

  async startProductSync() {
    return await axiosInstance.post<void>(`${this.backend}${this.rootPath}/start`);
  }

  async resolveConflict(body: { conflictId: string; match: IProductMatch }) {
    return await axiosInstance.post<UpdatedProductsLimitStatusAfterLinking>(
      `${this.backend}${this.rootPath}/conflicts`,
      body,
    );
  }

  async linkMatches(matches: IProductMatch[]) {
    return await axiosInstance.post<UpdatedProductsLimitStatusAfterLinking>(
      `${this.backend}${this.rootPath}/matches`,
      matches,
    );
  }

  async linkAllMatches(filter: IProductSyncMatchFilter) {
    return await axiosInstance.post<UpdatedProductsLimitStatusAfterLinking>(
      `${this.backend}${this.rootPath}/matches/all`,
      filter,
    );
  }

  async getVendorsForMatches() {
    return await axiosInstance.get<string[]>(`${this.backend}${this.rootPath}/matches/vendors`);
  }
}

export const syncProductsApi = new SyncProductsApi();

export interface IGetProductSyncMatchesFilter extends Partial<IProductSyncMatchFilter> {
  limit: number;
  page: number;
}

export type UpdatedProductsLimitStatusAfterLinking =
  | Exclude<IPostProductsResponse, { notFound: true }>
  | {
      success: false;
      limit: number | undefined;
      added: number;
      failed: number;
    };
