import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { AppSkeletonBodyText } from '../../../../core/components/feedback-indicators/skeleton/skeleton-body-text/skeleton-body-text';
import { AppSkeletonPage } from '../../../../core/components/feedback-indicators/skeleton/skeleton-page/skeleton-page';
import { AppCard } from '../../../../core/components/structure/card/card';
import { AppPage } from '../../../../core/components/structure/page/page';
import { setBreadcrumbsAction } from '../../../../core/redux/modules/breadcrumbs/breadcrumbs.actions';
import { marketplaceApi } from '../../../api/marketplace.api';
import { IMarketplaceProductDetails } from '../../../interfaces/IProduct';
import { MarketplaceProductDetails } from '../../containers/marketplace-product-details/marketplace-product-details';
import './marketplace-product-details-layout.scss';
import { getProductPreferencesAction } from '../../../redux/modules/preferences/preferences.actions';

export const MarketplaceProductDetailsLayout = () => {
  const dispatch = useDispatch();
  const { id: productId } = useParams<{ id: string }>();
  const [loading, setLoading] = useState(false); // loading product, show skeleton page
  const [product, setProduct] = useState<IMarketplaceProductDetails | null>(null);

  const getProductDetails = useCallback(() => {
    setLoading(true);
    marketplaceApi
      .getMarketplaceProductDetails(productId)
      .then(({ data }) => {
        setProduct(data);
        dispatch(setBreadcrumbsAction({ [`${productId}`]: data.title }));
        dispatch(getProductPreferencesAction());
      })
      .catch(console.error)
      .finally(() => {
        setLoading(false);
      });
  }, [productId, dispatch]);

  useEffect(() => {
    getProductDetails();
  }, [getProductDetails]);

  const skeletonPage = useMemo(
    () => (
      <AppSkeletonPage primaryAction>
        <AppCard sectioned>
          <AppSkeletonBodyText />
        </AppCard>
      </AppSkeletonPage>
    ),
    [],
  );

  return (
    <div className="product-details-layout">
      {loading || !product ? (
        skeletonPage
      ) : (
        <AppPage
          title={product.title}
          breadcrumbs={[{ content: 'Marketplace', url: '/marketplace' }]}
        >
          <MarketplaceProductDetails product={product} setProduct={setProduct} />
        </AppPage>
      )}
    </div>
  );
};
