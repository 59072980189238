import React from 'react';
import { IRadioButtonDescription } from '../../core/components/forms/radio/controlled-radio-group/controlled-radio-group';
import { AppTextStyle } from '../../core/components/text/text-style/TextStyle';
import { ICurrency } from '../../core/interfaces/ICurrency';
import {
  ADJUSTMENT_TYPE,
  AVAILABLE_RATES,
  INotificationsPreferences,
} from '../interfaces/IPreferences';

export enum ORDER_PREFERENCES_MARKING_RULE {
  // Create orders with status *Payment Pending*
  MANUAL = 'manual',
  // Create orders with status *Paid*
  AUTOMATIC = 'automatic',
}

export enum SUPPLIER_ORDER_NAME_ORIGIN {
  SHOPIFY = 'shopify',
  CROWDSHIP = 'crowdship',
  RETAILER_PO = 'retailer_po',
}

export enum ON_CANCELLATION_ACTION {
  // items should get cancelled in shopify store
  CANCEL = 'cancel',
  // do nothing in shopify store
  LEAVE = 'leave',
}

export enum OUT_OF_STOCK_ORDERS_PROCESSING {
  CANCEL = 'cancel',
  LEAVE = 'leave',
}

export enum DEFAULT_CANCELLATION_ACCEPT {
  ACCEPT = 'accept',
  IGNORE = 'ignore',
}

export enum DROP_SHIP_COST_MAPPING {
  SALE_PRICE = 'price',
  COMPARE_AT_PRICE = 'compare_at_price',
  COST = 'cost',
  MARKDOWN_FROM_MSRP = 'markdown_from_MSRP',
  MANUAL = 'manual',
}

export enum MSRP_MAPPING {
  COMPARE_AT_PRICE = 'compare_at_price',
  SALE_PRICE = 'price',
  MARKUP_FROM_DROP_SHIP_COST = 'markup_from_drop_ship_cost',
  MANUAL = 'manual',
}

export enum POSTAGE_SETUP {
  SUPPLIER = 'supplier',
  CROWDSHIP = 'crowdship',
}

type PreferencesOption =
  | ORDER_PREFERENCES_MARKING_RULE
  | SUPPLIER_ORDER_NAME_ORIGIN
  | ON_CANCELLATION_ACTION
  | OUT_OF_STOCK_ORDERS_PROCESSING
  | DEFAULT_CANCELLATION_ACCEPT
  | AVAILABLE_RATES
  | POSTAGE_SETUP;

interface IPreferencesConstants extends IRadioButtonDescription {
  value: PreferencesOption;
}

export const orderCreationOptionsConstants: IPreferencesConstants[] = [
  {
    value: ORDER_PREFERENCES_MARKING_RULE.AUTOMATIC,
    label: 'Paid',
    helpText: 'Mark order as Paid automatically',
  },
  {
    value: ORDER_PREFERENCES_MARKING_RULE.MANUAL,
    label: 'Unpaid',
    helpText: 'Mark order as Pending, and let me mark it Paid myself, if and when I want',
  },
];

export const orderNameOriginOptionsConstants: IPreferencesConstants[] = [
  {
    value: SUPPLIER_ORDER_NAME_ORIGIN.RETAILER_PO,
    label: 'Retailer Purchase Order Number (default)',
    helpText: 'Use retailers purchase order number as your store order number',
  },
  {
    value: SUPPLIER_ORDER_NAME_ORIGIN.SHOPIFY,
    label: 'Store order number',
    helpText: "Use my store's default order number sequence",
  },
  {
    value: SUPPLIER_ORDER_NAME_ORIGIN.CROWDSHIP,
    label: 'Crowdship order number',
    helpText: 'Crowdship generated order number',
  },
];

export const onCancellationActionOptionsConstants: IPreferencesConstants[] = [
  {
    value: ON_CANCELLATION_ACTION.CANCEL,
    label: 'Cancel in order',
    helpText:
      'If you will accept a cancellation request, the cancelled line items will also be mark as cancelled in your Shopify store',
  },
  {
    value: ON_CANCELLATION_ACTION.LEAVE,
    label: 'Do not modify order',
    helpText:
      'If you will accept a cancellation request your order in Shopify will not be modified',
  },
];

export const outOfStockProcessingConstants: IPreferencesConstants[] = [
  {
    value: OUT_OF_STOCK_ORDERS_PROCESSING.LEAVE,
    label: 'Process the order normally',
  },
  {
    value: OUT_OF_STOCK_ORDERS_PROCESSING.CANCEL,
    label: 'Automatically cancel the out of stock items',
  },
];

export const postageSetupOptions: IPreferencesConstants[] = [
  {
    value: POSTAGE_SETUP.SUPPLIER,
    label: <AppTextStyle variation="strong">Reimburse me for shipping costs</AppTextStyle>,
    helpText:
      'Crowdship will calculate shipping cost and add it to each order created in your store based on the mapping below. Check this box if you not be using Crowdship to print shipping labels.',
  },
  {
    value: POSTAGE_SETUP.CROWDSHIP,
    label: <AppTextStyle variation="strong">Print labels in Crowdship</AppTextStyle>,
    helpText: (
      <>
        <AppTextStyle>
          Print labels in your Crowdship Order Dashboard. You will not be reimbursed for shipping
          costs as the postage is paid for by Crowdship.
        </AppTextStyle>
        <p style={{ marginTop: '5px' }}>
          <em style={{ color: 'rgba(145, 106, 0, 1)' }}>
            Please Note: Crowdship is not able to provide postage for international shipments
          </em>
        </p>
      </>
    ),
  },
];

export const shippingMappingOptions: IPreferencesConstants[] = [
  {
    value: AVAILABLE_RATES.FREE,
    label: <AppTextStyle variation="strong">{AVAILABLE_RATES.FREE}</AppTextStyle>,
  },
  {
    value: AVAILABLE_RATES.CARRIER_RATES,
    label: <AppTextStyle variation="strong">{AVAILABLE_RATES.CARRIER_RATES}</AppTextStyle>,
  },
  {
    value: AVAILABLE_RATES.FLAT_RATE,
    label: <AppTextStyle variation="strong">{AVAILABLE_RATES.FLAT_RATE}</AppTextStyle>,
  },
];

interface INotificationConstant {
  key: keyof Omit<INotificationsPreferences, 'orderUnfulfilled' | 'productPriceUpdateError'>;
  title: string;
}

export const notificationEventsConstants: INotificationConstant[] = [
  { key: 'orderReceived', title: 'When I receive a Crowdship order' },
];

export enum VISIBILITY_MODE {
  ALL = 'all',
  NONE = 'none',
  BLACKLIST = 'blacklist',
  WHITELIST = 'whitelist',
}

export enum PACKING_SLIP_FORMAT {
  A4 = 'A4',
  '4x6' = '4x6',
}

export const visibilityFlavorText: { [key in VISIBILITY_MODE]: React.ReactNode } = {
  [VISIBILITY_MODE.ALL]: (
    <p>
      Your products are visible to <b>all</b> Retailers.
    </p>
  ),
  [VISIBILITY_MODE.NONE]: (
    <p>
      Your products are <b>not</b> visible to any Retailers.
    </p>
  ),
  [VISIBILITY_MODE.BLACKLIST]: (
    <p>
      Your products are visible to <b>all</b> Retailers <b>except:</b>
    </p>
  ),
  [VISIBILITY_MODE.WHITELIST]: (
    <p>
      Your products are <b>not</b> visible to any Retailers <b>except:</b>
    </p>
  ),
};

export const adjustmentTypeOptions = (currency: ICurrency) => [
  { label: '%', value: ADJUSTMENT_TYPE.PERCENT },
  { label: currency.symbol || currency.isoCode, value: ADJUSTMENT_TYPE.FIXED },
];

export const SUPPLIER_ORDERS_TAGS_LIMIT = 40;
