import { ExternalIcon } from '@shopify/polaris-icons';
import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { DateTooltip } from '../../../../core/components/date-tooltip/date-tooltip';
import { AppIcon } from '../../../../core/components/icon/icon';
import { AppIndexTableCell } from '../../../../core/components/index-table/index-table-cell';
import { AppIndexTableRow } from '../../../../core/components/index-table/index-table-row';
import { AppLink } from '../../../../core/components/link/link';
import { AppTextStyle } from '../../../../core/components/text/text-style/TextStyle';
import { withCurrency } from '../../../../core/helpers/currency.helper';
import { ICurrency } from '../../../../core/interfaces/ICurrency';
import { getShopSelector } from '../../../../core/redux/modules/auth/auth.selectors';
import {
  ISupplierOrder,
  SupplierOrderStatus,
  SupplierPayoutStatus,
} from '../../../interfaces/IOrder';
import { OrderStatus } from '../order-status/order-status';

import '../../../../core/styles/original-price.scss';
import './base-order-list-rows.scss';

interface IBaseOrderListRowsProps {
  orders: (ISupplierOrder & { orderId: string; refundId?: string })[];
  billTo?: boolean;
  currency: ICurrency;
  selectedResources?: string[];
  searchString?: string;
}

export const BaseOrderListRows = ({
  orders,
  billTo,
  currency,
  selectedResources,
  searchString,
}: IBaseOrderListRowsProps) => {
  const history = useHistory();
  const shop = useSelector(getShopSelector);

  const renderStatus = useCallback(
    (status: SupplierOrderStatus, payout?: { id: string; status: SupplierPayoutStatus }) => (
      <div className="status">
        {payout ? (
          <>
            <div className="payout-status">
              <OrderStatus status={payout.status} />
            </div>
            <div onClick={(e) => e.stopPropagation()} className="payout-link">
              <AppLink url={`/accounting/${payout.id}`}>View payout</AppLink>
            </div>
          </>
        ) : (
          <OrderStatus status={status} />
        )}
      </div>
    ),
    [],
  );

  return (
    <>
      {orders.map(
        (
          {
            _id,
            orderName,
            date,
            itemsCount,
            retailerShopName,
            paidViaCrowdship,
            total,
            nonRefundedTotal,
            status,
            payout,
            type,
            refundId,
            orderId,
          },
          index,
        ) => (
          <AppIndexTableRow
            id={_id}
            key={_id}
            position={index}
            selected={selectedResources ? selectedResources.includes(_id) : false}
            onNavigation={() =>
              type === 'credit'
                ? history.push(`/refunds/${refundId}`)
                : history.push(`/orders/${orderId}${searchString || ''}`)
            }
          >
            <AppIndexTableCell>
              <div data-primary-link className="order-name">
                <AppTextStyle variation="strong">{orderName}</AppTextStyle>
                <div
                  onClick={(e) => {
                    e.stopPropagation();

                    window.open(
                      `https://${shop}/admin/apps/${process.env.REACT_APP_APP_NAME}/${
                        type === 'credit'
                          ? `refunds/${refundId}`
                          : `orders/${orderId}${searchString || ''}`
                      }`,
                    );
                  }}
                >
                  <AppIcon source={ExternalIcon} color="base" />
                </div>
              </div>
            </AppIndexTableCell>
            <AppIndexTableCell>
              <DateTooltip date={date} />
            </AppIndexTableCell>
            <AppIndexTableCell>{retailerShopName}</AppIndexTableCell>
            {billTo && (
              <AppIndexTableCell>
                {paidViaCrowdship ? 'Crowdship' : retailerShopName}
              </AppIndexTableCell>
            )}
            <AppIndexTableCell>
              {type === 'credit' ? (
                `-${withCurrency(total, currency)}`
              ) : +total === +nonRefundedTotal ? (
                withCurrency(total, currency)
              ) : (
                <>
                  <span className="original-price">{withCurrency(total, currency)}</span>
                  <span>{withCurrency(nonRefundedTotal, currency)}</span>
                </>
              )}
            </AppIndexTableCell>
            <AppIndexTableCell>{itemsCount}</AppIndexTableCell>
            <AppIndexTableCell>{renderStatus(status, payout)}</AppIndexTableCell>
          </AppIndexTableRow>
        ),
      )}
    </>
  );
};
