export enum SORTING_ORDER {
  ASC = 'asc',
  DESC = 'desc',
}

export enum DEFAULT_RETAILER_SORT_BY_FIELDS {
  INVOICE_DETAILS = 'createdAt',
  PAID_INVOICES = 'paidAt',
  UNPAID_BATCHES = 'createdAt',
  ORDERS = 'createdAt',
  PURCHASE_ORDERS = 'createdAt',
}
