import React from 'react';
import { Stack, StackProps } from '@shopify/polaris';

type IStackProps = StackProps & {
  fillFirst?: boolean;
};

export function AppStack(props: IStackProps) {
  const { children, fillFirst } = props;
  if (!fillFirst || !Array.isArray(children)) return <Stack {...props} />;

  return (
    <Stack {...props}>
      {children &&
        children.map((child, i) => (
          <Stack.Item fill={fillFirst && i === 0} key={i}>
            {child}
          </Stack.Item>
        ))}
    </Stack>
  );
}
