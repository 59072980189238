import { AppliedFilterInterface, Filters, FiltersProps } from '@shopify/polaris';
import './filters.scss';

type IFiltersProps = FiltersProps;

export type IAppliedFilter = AppliedFilterInterface;

export const AppFilters = (props: IFiltersProps) => (
  <div className="filter-container">
    <div className="filters">
      <Filters {...props} />
    </div>
  </div>
);
